// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../../Category/common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText/LText'

const shopUrl = '/en/solutions/'

export const accordionList1 = [
    <AccordionCard key={'0'} title="Where is Kesar found?">
        <p>
            <b>Kashmir</b> produces the best quality Kesar while the only other state in India that cultivates this
            exotic spice, is <b>Himachal Pradesh</b>. Globally, countries like Iran, <b>Greece, Morocco, Spain</b>, and
            Italy commercially produces high quality Kesar, among which Iran is considered the chief cultivator, both in
            terms of volume and quality, producing 90 percent of the world’s total saffron. Spain on the other hand is
            regarded as the largest importer of this magical spice.
            <br/>
            <br/>
            Kesar or saffron comes in diverse qualities as well. The ones from Spain are known by brand names like
            Spanish Superior and Creme and are usually less vibrant in colour, essence, and aroma. Italian Kesar is
            relatively more powerful and intense than their Spanish counterparts while Greek saffron cultivated in
            Krokos is of exceptionally high quality, colour and intense flavour. Organically grown, unique Kesar
            products are available from countries like <b>France, Switzerland, England, New Zealand, and the United
            States</b>. Pennsylvania Dutch saffron from the US is famous for its ‘earthy notes’ and for being extremely
            expensive and sold only in very small quantities.
            <br/>
            <br/>
            The intensity of Kesar comes from its 150 hot and aroma-yielding compounds including terpenes, terpene
            alcohol and their esters, while the bitter taste and hay-like smell come from picrocrocin and safranal.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What medical qualities is the plant Crocus sativus replete with?">
        <p>
            Kesar is capable of <b>improving memory and learning skills, and also of enhancing blood circulation in the
            retina and choroid</b>. A glass of Kesar-milk is a famous beverage in Indian households and is believed to
            be a trustworthy drink.
        </p>
        <h3>Some other qualities:</h3>
        <p>
            Kesar is popularly known for its antioxidant, anti-depressant, anti-inflammatory, antihypertensive,
            anticonvulsant and antitussive properties. Moreover, it is also said to possess anti genotoxic and cytotoxic
            effects. Kesar also serves as an aphrodisiac for many and works as a rejuvenator with antinociceptive
            characteristics.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What is the history of Kesar?">
        <p>
            Historically, saffron production and use dates back to more than 3,000 years ago and is spread across
            diverse cultures and civilisations. According to available historical texts, <b>Greece</b> was the country
            which discovered Kesar or saffron and cultivated the plant expansively ever since. Eventually, this highly
            coveted spice travelled across Eurasia as a genetically monomorphic clone, making inroads to the foreign
            soils of America and Oceania. Here, it established itself as a delicate part of the cuisines of each region.
            Some studies indicate that Kesar was a native species of Southwest Asia and was known to Indian cooking
            since antiquity.
            <br/>
            <br/>
            History also suggests that Kesar was much in demand as an exotic spice during the Bronze Age. The oldest
            recorded history of Kesar can be found in the botanical treatise of Ashurbanipal (669 - 631 BC), the fourth
            king of the Sargonid dynasty of the Neo-Assyrian Empire, revered as the last great king of Assyria. He
            dedicated his dissertation to the wonder substance and sung praises of its beneficial qualities.
            <br/>
            <br/>
            Greek mythology makes recurrent references to saffron, one such being the Tragedy of Crocus and Smilax,
            written by Ovid. Old Greek folklore speaks of sailors making expeditions to the distant land of Cilicia in
            hope of acquiring the world’s most exotic saffron.
            <br/>
            <br/>
            History records the first portrayal of saffron cultivation during the 8th century BC to the 3rd century AD
            which is called the Greco-Roman classical age. It is found at palace frescoes of Minoan Crete where young
            girls and monkeys can be seen plucking flowers from saffron plants. Unfortunately, between 1645 and 1500 BC,
            the twin saffron-producing Minoan provinces of Thera and Akrotiri, located in the island of the Aegean Sea,
            were devastated by a massive earthquake and volcanic eruptions.
            <br/>
            <br/>
            In the ancient Mediterranean, the saffron of Soli town was considered the best, and was largely used in
            making perfumes and lotions. In Egypt, the queen <b>Cleopatra</b> is believed to have had a <b>bath</b> in
            water mixed with a quarter-cup of <b>saffron</b>, before her trysts with men. Many surmise that this was
            done <b>for her to have better sexual experiences</b>.
            <br/>
            <br/>
            Ancient Egyptian physicians prescribed a lotion made of saffron grinded with ox fat, coriander, aager-tree
            remnants and myrrh in order to cure numerous gastrointestinal disorders. Urinary tract infections were
            addressed with a topical oil-based suspension made with unripe saffron flowers and roasted beans for men
            while for women, doctors recommended more intricate formulations.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'3'} title="Where does Kesar originate from?">
        <p>
            The word Kesar means, pollen, and originates from the purple crocus of the <b>Crocus sativus plant</b>. The
            plant Crocus sativus, is an autumn-flowering perennial species which is anonymous to the wild. History
            propounds that this plant came from the eastern Mediterranean species called Crocus cartwrightianus, which
            is also an autumn-flowering plant. Also called by names like wild saffron, this plant was found in Crete or
            Central Asia.
            <br/>
            <br/>
            The term saffron has a number of connotations regarding its origin. Some propose that the word originated
            from 12th-century French expression ‘safran’, which was imitated from the Latin word ‘safranum’. Others
            proclaim that the word saffron came from Arabic ‘az-za’faran’,
            <br/>
            while safranum originates from the Persian word, ‘za’faran.’ Interestingly, the word zarparan means gold-
            strung which is believed to indicate the golden stamens of the purple crocus or the golden colour effect
            that Kesar creates when used as a spice.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'4'} title="Where is Kesar produced?">
        <p>
            In India the best quality Kesar is exclusively cultivated in the <b>Kashmir valley</b> and is extremely rare
            and sold at exorbitant prices. It is deep maroon or purple in appearance, characterised by a strong aroma,
            essence, and colour effect. Export bans on this quality of Kesar by the Indian Government have made its
            availability all the more rare around foreign shores.
            <br/>
            <br/>
            The exoticism of Kesar varies from one place to another and the world’s best and premium the quality of
            saffron is now grown in Italy’s Navelli Valley. A Dominican monk, who visited from <b>Spain</b> during the
            Inquisition-era, was the first one to introduce this finest quality saffron in Italy. This saffron can be
            distinguished by rich safranal and crocin content, a distinct thread shape, extraordinarily heady aroma, and
            a strong hue. On the other hand, San Gavino Monreale, Sardinia is the largest saffron producing province
            in <b>Italy</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="What is Kesar made of?">
        <p>
            The constituents of Kesar or saffron are grouped on the basis of their volatility, or the absence of it. The
            constituents include more than 30 volatile and aroma-producing compounds, rich
            in <b>ketones</b> and <b>aldehydes</b>. The chief volatile constituents present in Kesar are <b>terpene,
            terpene alcohol, and terpene esters</b>. Safranal is the most important volatile complex produced from
            picrocrocin due to the interaction of heat and enzymes during the drying period. The key non-volatile
            components present in Kesar are crocin, α-crocin, carotenoids that include lycopene, zeaxanthin, and both
            alpha- and beta- carotenes, crocetin, and picrocrocin.
            <br/>
            <br/>
            In addition to the active agents, crocin and safranal are also responsible for the anti-depressant effects
            of Crocus Sativus. The antinociceptive and anti-inflammatory traits of Kesar are because of the presence of
            flavonoids, tannins, and anthocyanins. Additionally, numerous other flavonoids such as rutin, quercetin,
            luteolin, hesperidin, and bioflavonoids are present. The chemicals picrocrocin and safranal, present in
            Kesar are known to give the spice its characteristic iodoform, or hay-like aroma. However, the unique
            orange-yellow colour comes from the carotenoid pigment, called α-crocin, which comprises over ten percent of
            dry saffron mass. Picrocrocin on the other hand, gives it the distinct bitter taste.
            <br/>
            <br/>
            These chemicals are so potent that a slight amount is enough to produce the desired colour and flavour. Dry
            saffron is extremely responsive to variable pH levels and quickly disintegrates when exposed to light and
            oxidizing agents. This is why it is recommended to store in air-tight containers to avoid exposure to the
            oxygen present in the air, otherwise, Kesar will be oxidised, which in turn, will significantly diminish the
            characteristics of crocin and picrocrocin and degrade the saffron quality.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the benefits of Kesar?">
        <p>
            The health benefits of Kesar are also primarily seen due to the constituent antioxidants of which Safranal
            is most powerful.
        </p>
        <h3>Here are some primary usages of this spice as a natural health enhancer:</h3>
        <p>
            <strong>Antioxidant:</strong> The presence of multiple compounds in Kesar which act as antioxidants like
            crocin, crocetin, safranal, and kaempferol, safeguard the body cells from free radicals. It also provides
            safeguard against oxidative stress.
            <br/>
            <br/>
            <strong>Antidepressant:</strong> The carotenoid pigments present in this natural spice crocin and crocetin
            are compounds possessing antidepressant properties. This has earned Kesar the sobriquet – ‘sunshine spice’.
            They protect brain cells against progressive damage, address inflammation and diminish appetite thus helping
            the body lose weight. Safranal protects the brain cells from oxidative stress. Kaempferol, found in the
            petals of Kesar yielding plant, acts as an antidepressant and can treat mild-to-moderate depression.
            Besides, it also controls inflammation.
            <br/>
            <br/>
            <strong>Premenstrual syndrome:</strong> Premenstrual syndrome or PMS refers to physical, emotional, and
            psychological signs experienced at the beginning of the menstrual cycle. When taken daily by women in the
            age group of around 20–45 years, a dose of 30 mg Kesar is effective in addressing PMS symptoms, like a bad
            temper, headaches, anxiety and abdominal pain. It also reduces levels of cortisol which is also known as the
            stress hormone. Many also believe that PMS can be addressed simply by smelling saffron.
            <br/>
            <br/>
            Conventionally, women who experience intense menstrual cramps along with abdominal pain are advised to
            consume warm food substances. Kesar milk, famous for having anti-inflammatory qualities is a warm and
            comforting concoction, highly effective for women during menstrual cycles. Not only that, Kesar is also
            great for teenagers and helps in balancing their hormonal levels as well as in treating pimples and skin
            blemishes
            <br/>
            <br/>
            <strong>Pregnancy:</strong> The ancient Indian Ayurvedic school of medicine also suggests the consumption of
            saffron from about the 5th month of pregnancy. This is said to aid the baby’s movements, and the degree of
            putting the baby at risk due to premature contractions in the mother’s body, is minimised.
            <br/>
            <br/>
            <strong>Aphrodisiac:</strong> Aphrodisiacs are libido boosters. According to research results, Kesar can act
            as an aphrodisiac especially for those taking antidepressant medications. A dose of 30 mg Kesar consumed
            regularly for nearly four weeks can significantly improve conditions like erectile dysfunction in men while
            enhancing libido, and overall sexual satisfaction.
            <br/>
            <br/>
            In women this dose can lower pain during intercourse by increasing lubrication, and also enhance the desire
            for sex.
            <br/>
            <br/>
            <strong>Heart health:</strong> A little Kesar on a daily basis is also enough for heart health as it has the
            potential to encourage blood circulation and prevent atherosclerosis or the hardening of arteries. It is the
            workings of the active compound crocetin present in Kesar, which is capable of reducing the cholesterol
            level in the blood and preventing heart-related disorders to a great extent. Also, the presence of potassium
            greatly helps in preventing accumulation of cholesterol and triglycerides in the blood.
            <br/>
            <br/>
            <strong>Hair loss or balding:</strong> For men with patchy baldness, Kesar extracts mixed in liquorice and
            milk is used as a topical cream which is known to be very effective in triggering hair growth.
            <br/>
            <br/>
            <strong>Help Weight Loss:</strong> Kesar aids in losing body weight, body mass index (BMI), waist
            circumference, and fat mass, all by addressing the habit of snacking which puts one in a potential risk of
            gaining weight. Studies say Kesar can be of help in reducing body weight by curbing appetite and leaving
            back a sense of feeling satiated, which in turn will stop the desire to indulge in snacking.
            <br/>
            <br/>
            <strong>Learning ability and memory retention:</strong> Safranal, another key constituent in Kesar, is
            believed to act as an agent for boosting one’s mood, and enhancing memory and learning prowess. Studies show
            that Kesar extracts, particularly crocin, is valuable in curing age-induced mental impairment. In countries
            like Japan, Kesar extracts are used as capsules and are prescribed for treatment of Parkinson’s disease,
            memory loss as well as inflammation. Kesar can also be of immense help in averting the oxidative stress in
            the hippocampus, thus improving learning and memory retention.
        </p>
        <h3>Other benefits:</h3>
        <p>
            In case of delayed puberty, girls are prescribed a dose of Kesar extract for its
            general <b>stimulant</b> effect. A pinch of crushed Kesar mixed in a table-spoon of milk is known to boost
            hormone secretion and yield desired effects in under-developed girls.
            <br/>
            <br/>
            Kesar also provides <b>protection against common cold</b> as it acts as a nourishing tonic. When mixed with
            lukewarm milk and applied over the forehead, it quickly relieves the symptoms of cold and fever. The
            numerous healing qualities of Kesar, together with its warm nature, are effective in lowering the risks of
            catching a cold and fever. Kesar is an excellent alternative for synthetic food additives and colour.
            Chemical food colouring agents often have harmful ingredients which can lead to allergic reactions. Kesar’s
            brilliant orange-yellow colour is definitely a healthier choice.
            <br/>
            <br/>
            Insomnia is another disorder which can be addressed to a great extent by Kesar extracts, which have strong
            antioxidants like crocin, safranal, and picrocrocin. When missed with tea or milk Kesar is effective in
            treating sleeplessness. The fact that it is rich in manganese, is another factor which enables Kesar to act
            as a sleep inducer. With a calming and soothing effect on the human body, Kesar acts as an effective and 100
            percent natural tranquilizer. A cup of warm Kesar-milk ensures a good night’s sleep. Lastly, kesar-milk is a
            wonder potion effective in assuaging joint pains, asthma and some mild allergies caused by weather or
            climate conditions.
        </p>
        <ImageBreaker image={Images.ingredient.kesarBenefits} to={shopUrl} buttonText={'SHOP'}/>
    </AccordionCard>,
    <AccordionCard key={'2'} title="How to consume Kesar?">
        <p>
            Kesar is used both in <b>cooking</b> as well as in <b>treating diseases</b>. When used for colouring, Kesar
            or saffron powder is generally used. For garnishing a dish on the other hand, the threads are preferred. In
            case of consuming Kesar for health reasons, supplements, powder, pill or solutions are used. Health benefits
            of Kesar are highly valued across the world and in India it has been recommended to pregnant mothers for
            stabilising their erratic hormone levels and mood swings, blood pressure, constipation and flatulence. Kesar
            is highly effective in increasing blood flow to the body’s digestive system. Thus, a glass of Kesar-milk is
            a household solution for the problem of indigestion in India.
            <br/>
            <br/>
            For making Kesar powder, the threads can be pounded with a mortar and pestle by adding a pinch of sugar for
            easy grinding. Powdered Kesar available in the markets are often adulterated, hence it is always better to
            prepare it at home. Liquid saffron can also be made at home by mixing powdered saffron with four to five
            teaspoons of hot water and keeping this aside to let it infuse for eight to ten minutes. This solution can
            be preserved in an air-tight jar for a couple of weeks and consumed as and when necessary or prescribed.
            <br/>
            <br/>
            A saffron-based solution can also be made by replacing water with milk, vinegar, or wine. Kesar is commonly
            used as a health supplement; however one should be careful about the dosage. Consuming small quantities of
            saffron is recommended by most physicians. Usually a pinch of saffron is enough for one time dose and a
            pinch of saffron amounts to nearly 20 medium-sized threads. The commonly recommended dose of Kesar extract,
            as a health supplement, is about 30 mg to 50 mg per day. Small quantity Kesar is often taken orally as a
            pill for nearly 25-30 weeks. The maximum prescribed daily dose is about 400 mg per day. However, doctors
            advise not to take large doses of Kesar orally, as a high dose of 5 grams or more, might lead to poisoning
            while higher doses of 12-20 grams can be fatal.
            <br/>
            <br/>
            An interesting thing about Kesar is that it requires moisture in order to release its quintessential aroma
            and essence. Also, pressing Kesar threads in between aluminium sheets helps remove the bitter after-taste of
            this spice. For preparing Kesar-milk, saffron threads are soaked in a glass of milk for 10 to 15 minutes
            before their consumption.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'3'} title="What are the side effects of Kesar?">
        <p>
            Naturally, there are side effects of Kesar, often occurring when consumed in high amounts. Some of these
            include, <b>anxiety, various allergic reactions, lethargy or laziness, a dry mouth, mood swings and
            increased sweating</b>. Individuals have also reported the feelings of <b>queasiness, vomiting,
            constipation, diarrhea, an inconsistent appetite, hot flushes, headaches and a yellowing of the skin and
            eyes</b>.
        </p>
        <h3>Some more possibility of side effects in particular cases:</h3>
        <p>
            Consuming large amounts of Kesar orally by pregnant and lactating mothers is unsafe as it can contract the
            uterus and lead to a miscarriage. Though there is no scientific data about the side effects of saffron on
            lactating mothers, it is better to avoid it for the safety of the mother and child. Also, if taken in a dose
            of 400 mg daily for a week, by women who are not pregnant, Kesar can lead to abnormal uterine bleeding.
            <br/>
            <br/>
            Those suffering from Bipolar disorder should not take it as Kesar is known to result in mood swings. Taking
            this product can stimulate the excitable cells and cause impulsive and excited behaviour or mania in
            patients afflicted with bipolar disorder.
            <br/>
            <br/>
            Those <b>allergic to Lolium, Olea</b> (includes olive), and <b>Salsola plant</b> species are normally
            allergic to Kesar as well. They must stay away from it. Kesar can increase the heart beats if taken in large
            doses, thus aggravating certain heart disorders. Kesar is also known to reduce blood pressure, and consuming
            it might lower blood pressure to dangerously low levels in people who already have low blood pressure
            conditions. Those undergoing anticoagulant or blood-thinning therapy or have any kind of renal disease, must
            also be careful and take advice before consuming Kesar. If one notices any allergic reactions after
            consuming Kesar, it is extremely critical to seek medical help or if needed, an emergency service.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'4'} title="How long does it take for Kesar to be effective?">
        <p>
            As is the case with all dietary supplements, time taken for Kesar to start showing results depends on the
            reason for which it is consumed, body type, weight, dietary habits and of course, the lifestyle choices of
            the user. Some are known to experience faster impacts than others who take a longer time before their
            systems start responding to the therapy. Additionally, it also depends on the other medicines taken by the
            individual along with the extract. Kesar therapy is in most cases a long-term regimen and within at
            least <b>four to eight weeks</b> of consuming this dietary supplement, one can witness tangible results.
            That said, the general belief is that, consuming Kesar/saffron extract orally, for a period of about 22
            weeks improves the condition of patients afflicted with Alzheimer’s disease. This happens because Kesar
            plays the same role as that of the prescription drug ‘donepezil’ (Aricept). To see impact in people with
            severe depression and anxiety, the course of Kesar supplement must be continued for 8-12 weeks.
            <br/>
            <br/>
            Saffron extracts are also widely used for body-building. However, such extracts cannot prove to be a quick
            fix for those interested in losing weight. A good thing about Kesar is that it shows no withdrawal symptoms.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'5'} title="References">
        <ul>
            <li>
                <a href="https://www.tarladalal.com/glossary-saffron-520i" target="_blank">
                    https://www.tarladalal.com/glossary-saffron-520i
                </a>
            </li>
            <li>
                <a href="https://www.healthline.com/nutrition/saffron" target="_blank">
                    https://www.healthline.com/nutrition/saffron
                </a>
            </li>
            <li>
                <a href="https://www.indiatoday.in/lifestyle/wellness/story/kesar-aka-saffron-zafaran"
                   target="_blank">
                    https://www.indiatoday.in/lifestyle/wellness/story/kesar-aka-saffron-zafaran
                </a>
            </li>
            <li>
                <a href="https://www.kesarisaffron.com/category/blog/info-about-saffron/" target="_blank">
                    https://www.kesarisaffron.com/category/blog/info-about-saffron/
                </a>
            </li>
            <li>
                <a href="https://www.cs.mcgill.ca/~rwest/wikispeedia/wpcd/wp/h/History_of_saffron.htm" target="_blank">
                    https://www.cs.mcgill.ca/~rwest/wikispeedia/wpcd/wp/h/History_of_saffron.htm
                </a>
            </li>
            <li>
                <a href="https://www.sativus.com/en/saffron/components-of-saffron/"
                   target="_blank">
                    https://www.sativus.com/en/saffron/components-of-saffron/

                </a>
            </li>
            <li>
                <a href="https://www.stylecraze.com/articles/amazing-benefits-of-saffron/#gref"
                   target="_blank">
                    https://www.stylecraze.com/articles/amazing-benefits-of-saffron/#gref
                </a>
            </li>
        </ul>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]
