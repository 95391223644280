import React from 'react'
import PropTypes from 'prop-types'

import { Images } from '../../../constants'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText'

export default class Stats extends React.Component {
    static propTypes = {
      to: PropTypes.string,
      quizButtonText: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        percentage: PropTypes.string,
        text: PropTypes.string
      }))
    };

    static defaultProps = {
      to: '/en/quiz/sex/',
      quizButtonText: 'TAKE_THE_SEX_QUIZ'
    };

    render () {
      const { data, to, quizButtonText } = this.props

      return (
            <div className="category-bg-drop"
                 style={{ backgroundImage: `url(${Images.edSection2})` }}>
                <div className="container">
                    <div className="row">
                        {
                            data.map(
                              (obj, index) => (
                                    <div className="col-md-4 col-12 py-5" key={index}>
                                        <div className="category-stats-card">
                                            <h5>{obj.title}</h5>
                                            <h3>{obj.percentage}</h3>
                                            <p>{obj.text}</p>
                                        </div>
                                    </div>
                              )
                            )
                        }
                    </div>
                    <div className="row">
                        <div className="col-12 text-center py-2">
                            <Button to={to} style={{ width: 200 }}><LText k={quizButtonText}/></Button>
                        </div>
                    </div>
                </div>
            </div>
      )
    }
}
