import React, { Component } from 'react'
import PropTypes from 'prop-types'

class AnalysisBMIScoreCard extends Component {
    static propTypes = {
      className: PropTypes.string,
      score: PropTypes.number,
      duration: PropTypes.number,
      delay: PropTypes.number,
      offset: PropTypes.number,
      ayurvedicBMI: PropTypes.bool
    };

    static defaultProps = {
      className: '',
      score: 0,
      duration: 1000,
      delay: 100,
      offset: 200,
      ayurvedicBMI: false
    };

    constructor (props) {
      super(props)

      this.state = {}
    }

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState, snapshot) {

    }

    componentWillUnmount () {
    }

    render () {
      const { className, duration, delay, score, offset, ayurvedicBMI } = this.props

      return (
            <div className={`analysis-bmi-score-card ${className} ${ayurvedicBMI ? 'analysis-ayurvedic-bmi-card' : ''}`}
                 data-aos-offset={offset}
                 data-aos="zoom-in" data-aos-duration={duration} data-aos-delay={delay} data-aos-once="true">
                <h1 className="bmi-score-count">{score}</h1>
                <h5 className="bmi-score-title">
                    BMI
                    <br/>
                    <small>is normal</small>
                </h5>
            </div>
      )
    }
}

export default AnalysisBMIScoreCard
