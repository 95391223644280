import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import ImageBreaker from '../../../components/breakers/ImageBreaker'

class AIPSAndSRQ extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props

    return (
            <Page meta={{
              title: 'Ayurvedic Ingredient Potency Score (AIPS) and Scientific Research Quotient (SRQ) | Misters',
              path: `${match.params.language}/ingredient/aips-and-srq/`,
              description: 'Ayurvedic Ingredient Potency Score (AIPS) and Scientific Research Quotient (SRQ) are novel concepts developed by Misters.in to measure the potency of an ayurvedic product and the scientific relevance of the ingredients used.'
            }}>
                <div className="ingredient-header-img"
                     style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>Ayurvedic Ingredient Potency Score (AIPS) and Scientific Research Quotient (SRQ)</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <p>
                                <i>
                                    Ayurvedic Ingredient Potency Score (AIPS) and Scientific Research Quotient (SRQ) are
                                    novel concepts developed by Misters.in to measure the potency of an ayurvedic
                                    product and the scientific relevance of the ingredients used.
                                </i>
                            </p>
                            <h2>What is AIPS?</h2>
                            <p>
                                AIPS or Ayurvedic Ingredient Potency Score is developed by Misters.in to measure the
                                potency of each ingredient based on the dosage used in a particular product, relative to
                                reputed products in the market. AIPS measures the total potency of an ayurvedic
                                formulation as compared to other products in the market with similar claims.
                            </p>

                            <h2>How is AIPS calculated?</h2>
                            <p>
                                AIPS is calculated for each ingredient in a product by comparing the dosage per serving
                                of in the product, against the highest dosage used by any other reputed product with the
                                same/similar use case and claims. Then, the individual ingredient scores are added and
                                divided by the total number of ingredients in a product to get the total AIPS.
                                <br/>
                                <br/>
                                For example, Himalaya Tentex Royale has 100mg of Gokshura in their product and
                                Misters.in Bold for men has 50mg of it, the score of the potency of Gokshura in
                                Misters.in Bold for men is 0.5, given that Himalaya Tentex Royale has the highest amount
                                of Gokshura amongst all reputed products with the same claim as Misters.in Bold for men.
                                <br/>
                                <br/>
                                The total score of <b>Misters.in Bold for men is 16.05</b>, whereas the total score
                                of <b>Himalaya Tentex Royale is 5.0</b>. This tells us that Misters.in Bold has a higher
                                potency value per serving as compared to Himalaya Tentex Royale.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker isBackgroundWhite image={Images.ingredient.aips_srq} to={shopUrl}
                                          buttonText={'SHOP'}/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What is SRQ?</h2>
                            <p>
                                SRQ or Scientific Research Quotient is developed by Misters.in to validate the
                                scientific relevance of the claims of each of the ingredients used in our products. When
                                an ingredient is chosen to be added to a product, our team validates its scientific
                                relevance by analysing the papers published in reputed journals. A score between 0 to 5
                                is assigned to each ingredient, with zero being the lowest meaning the ingredient has
                                not been studied by scientists and hence there’s no relevance, and five being the
                                highest, meaning it has complete validation from science for the claim being made.
                            </p>

                            <h2>How is SRQ calculated?</h2>
                            <p>
                                SRQ is measured against a limit of 100 which is a value of the function of the multiple
                                of the impact factor(IF) of a journal in which the paper is published and the citation
                                count of each paper Misters.in has referred to.
                                <br/>
                                f(SRQ) = MAX(MIN((Impact Factor * Citation Score)*5/100, 5),0)
                                <br/>
                                <br/>
                                After calculating SRQ for each ingredient, the total SRQ for the product will be
                                determined by adding the individual ingredient score divided by the total number of
                                ingredients.
                                <br/>
                                <br/>
                                E.g.: In case of Immunity Booster, for Amla, we have five references with the following
                                Impact factor of the journal and citation count
                                <br/>
                                <br/>
                                Paper 1 - IF is 5.8, CC is 55,
                                <br/>
                                Paper 2 - IF is 3.69, CC is 111,
                                <br/>
                                Paper 3 - IF is 3.69, CC is 148,
                                <br/>
                                Paper 4 - IF is 1.46, CC is 21,
                                <br/>
                                Paper 5 - IF is 2.24, CC is 57.
                                <br/>
                                <br/>
                                IF is Impact Factor and CC is Citation Count.
                                <br/>
                                <br/>
                                Each of these will get a SRQ score, total SRQ score for Amla will be an average of
                                these. <b>Misters.in Immunity Booster for men has a Scientific Research Quotient (SRQ)
                                of 3.54</b>, which indicates high scientific validation for the claims made by the
                                product.
                            </p>
                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={'SHOP'}/>
            </Page>
    )
  }
}

export default AIPSAndSRQ
