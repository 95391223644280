// External Imports
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import AliceCarousel from 'react-alice-carousel'
// My Imports
import ImageService from '../../../services/ImageService'
import Image from '../../core/Image'
import { getLanguageTranslation } from '../../../utils'
import Button from '../../buttons/Button'
import LText from '../../core/LText'

const Ingredients = ({ match, history, ingredients, showKnowMore }) => {
  const language = match.params.language

  const [ingredientCarouselIndex, setIngredientCarouselIndex] = useState(0)
  const ingredientCarouselItems = ingredients.map(ingredient => (
    <div key={ingredient.id} className="p-2 text-center"
         onClick={() => { if (ingredient.url) { history.push(ingredient.url) } }}
         style={ingredient.url ? { cursor: 'pointer' } : {}}>
      <div className="ingredients-item">
        <div className="d-flex align-items-center mb-2">
          <img src={ingredient.image} alt={ingredient.title}/>
          <h6 className="ml-2">{getLanguageTranslation(ingredient, 'title', language)}</h6>
        </div>
        <p>{ingredient.description}</p>
      </div>
    </div>
  ))

  const onIngredientSlideChanged = (e) => {
    setIngredientCarouselIndex(e.item)
  }

  return (
    <>
      <div className='ingredients pb-2 pt-0 py-md-5'>
        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              <h2 className="home-page-subtitle text-center mb-5">
                <LText k="KEY_INGREDIENTS"/>
              </h2>
              <div className="row">
                <div className="col-md-6 col-12 py-3 pr-md-5 d-flex flex-column justify-content-center">
                  <h3 className="title"><LText k="INGREDIENTS_TITLE"/></h3>
                  <p>
                    Misters does an extensive clinical research and maintains a very high quality.
                  </p>
                  <ul>
                    <li>
                      <img src={ImageService.home.ingredientsIcon1} alt="ProductIngredients Icon 1"/>
                      <LText k="INGREDIENTS_TITLE_1"/></li>
                    <li>
                      <img src={ImageService.home.ingredientsIcon2} alt="ProductIngredients Icon 2"/>
                      GMP certified</li>
                    <li>
                      <img src={ImageService.home.ingredientsIcon3} alt="ProductIngredients Icon 3"/>
                      No Side effects</li>
                  </ul>
                </div>
                <div className="col-md-6 col-12 text-center d-none d-md-block">
                  <Image src={ImageService.home.ingredients} alt="ProductIngredients"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        !!ingredients.length &&
        <div className='ingredients-items py-5'>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <AliceCarousel mouseTrackingEnabled touchTrackingEnabled
                               autoPlayInterval={6000}
                               responsive={{ 0: { items: 1 }, 768: { items: 2 }, 992: { items: 3 } }}
                               autoPlay={true}
                               items={ingredientCarouselItems}
                               startIndex={ingredientCarouselIndex}
                               stopAutoPlayOnHover={true}
                               buttonsDisabled={true}
                               dotsDisabled={false}
                               onSlideChanged={onIngredientSlideChanged}/>
              </div>
            </div>
            {
              !!showKnowMore &&
            <div className="text-center py-3">
              <Button variant="dark" to={`${language}/ayurveda/`}>
                <LText k="KNOW_MORE"/>
              </Button>
            </div>
            }
          </div>
        </div>
      }
    </>
  )
}

Ingredients.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  ingredients: PropTypes.array,
  showKnowMore: PropTypes.bool
}

Ingredients.defaultProps = {
  ingredients: [],
  showKnowMore: false
}
export default withRouter(Ingredients)
