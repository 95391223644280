import React, { PureComponent } from 'react'
import ztable from 'ztable'
import PropTypes from 'prop-types'

import { Images } from '../../../constants'

export default class PDGraph extends PureComponent {
    static propTypes = {
      value: PropTypes.number.isRequired
    };

    render () {
      const { value } = this.props
      const percentile = ztable(value) * 100
      const percentage = Math.floor(percentile * 10) / 10

      let linePosition = (value + 3.49) * 100 / 6.98

      if (linePosition > 100) linePosition = 100
      else if (linePosition < 0) linePosition = 0

      return (
            <div className="pd-graph-container">
                <div className='pd-graph'>
                    <img src={Images.pdGraph} alt=""/>
                    <div className="pd-graph-fill" style={{ width: `${linePosition}%` }}/>
                    {/* <p className='pd-graph-message' style={{left: `${linePosition}%`}}>You are here</p> */}
                    {/* <p className="pd-graph-percentage" style={{left: `${percentile}%`}}> */}
                        {/* {percentage}% */}
                    {/* </p> */}
                </div>
            </div>
      )
    }
}
