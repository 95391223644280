import { Images } from '../../constants'

export const articleList = [
  {
    title: 'Intimacy in isolation',
    image: Images.intimacy.intimacy,
    innerImage: Images.intimacy.intimacy1,
    content: `
        <p>Social distancing has perhaps dethroned sex as the activity (or rather inactivity) people currently think most about. Isolation has put a halt to sexual activity and the community that&rsquo;s been hit hardest are the singles, who&rsquo;ve now replaced the popular phrase &ldquo;not ready&rdquo; with saying, &ldquo;Single, but not allowed to mingle.&rdquo; This lack of freedom, given the circumstances, is welcome. With India&rsquo;s Covid cases seeing a steady rise with each day, keeping oneself isolated is the only solution till scientists working tirelessly in the lab to come up with a much-needed vaccine. As per a recent news report, India seems to be defying the general trend of the virus globally, and most patients affected are millennials. While the overall numbers are still on the lower end, this could well change in the coming weeks with more testing. So isolation really is the mantra in order to stay healthy. It&rsquo;s possible that given the rapidly changing scenario, we might be staring at an extended lockdown deadline. Till then, it&rsquo;s only responsible to seek self-pleasure, unless you&rsquo;re in a live-in or committed relationship. Certainly, we&rsquo;re all facing myriad problems as we stare at an uncertain future, but as the adage goes, life must go on and while we&rsquo;re all out of our regular routines, maintaining a semblance of normalcy is integral to mental health.&nbsp;&nbsp;&nbsp;</p>
        <p>The collective opinion of health professionals is that steady, married couples can freely indulge in sexual activity, as long as they&rsquo;ve been living together (during the entire lockdown period) and aren&rsquo;t exhibiting symptoms. In fact, intercourse itself hasn&rsquo;t been proven to spread the virus, but who are we kidding? Kissing is an integral part of sex, unless you&rsquo;re one of those humanoids in WestWorld. For humans, it&rsquo;s a sure-shot way of transmitting Covid through the ever-reliable carrier &ndash; saliva. But if neither of you are infected, then go on, let the love express itself. This is the time when no one is &ldquo;too busy&rdquo; or &ldquo;too tired&rdquo; so it&rsquo;s an opportunity to bring back any sparks that were beginning to fade.&nbsp;</p>
        <p>As a therapist in his thirties mostly living in others&rsquo; minds and my own, I admit that I&rsquo;m not completely aware of millennial parlance. I recently discovered that &lsquo;Netflix and Chill&rsquo; doesn&rsquo;t actually mean to watch a nice show and relax with your loved one, but is a euphemism for sex. But this also made me think: why not just say &ldquo;have sex!&rdquo;. Why must we still be relying on vague phraseology to express our most primal instincts? Anyway, therapy often takes a direction of its own but I need to be in control of this article so forgive this minor digression.&nbsp; Here are 3 ways in which we can satisfy our urges, without letting them remain all bottled up till the lockdown is lifted.</p>
        <ol>
            <li style="margin-bottom: 10px"><strong>Stroke thyself</strong>: No, there&rsquo;s nothing wrong with it (see our FAQs). No, you won&rsquo;t lose hair. No, you won&rsquo;t lose your sex drive. No, it isn&rsquo;t immoral. No, there isn&rsquo;t a finite amount of semen your body can produce. No, it doesn&rsquo;t make you weaker. Yes, it is the safest way to derive pleasure with no risk whatsoever.&nbsp; Remember how you wrote &lsquo;self-starter&rsquo; in the skill set list on your resume? Well, about time you practiced those skills. This one&rsquo;s for the singles of course. I&rsquo;m sure you&rsquo;re clever enough to understand what I&rsquo;m talking about, but as a therapist, I can&rsquo;t ever compromise on clarity for the sake of clever euphemisms. It&rsquo;s about masturbation.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li style="margin-bottom: 10px"><strong>Listen to my desires glisten: </strong>You&rsquo;re in a relationship but living apart? Get creative. Express your desires and talk naughty. Aren&rsquo;t you done with the mundane work con calls already? Use your free time to connect with your better half. Phone sex can be incredibly satisfying if both partners are upto it, and again, it&rsquo;s safe. Bring on your deep baritone and spread love through that telephone.</li>
            <li style="margin-bottom: 10px"><strong>Be mine over Facetime</strong>: Just voice not cutting it? We hear you. Many people need visual stimulation, and if there&rsquo;s a high degree of comfort in your relationship, sexual intimacy over a video call can be exciting and keep your sex life from going into cold storage. Just ensure you&rsquo;re using reliable, encrypted services such as Skype or FaceTime.&nbsp;&nbsp;</li>
        </ol>
        <p>An important suggestion here: please stay away from watching the news the entire day or consuming content subconsciously through social media by endlessly scrolling through your feed. Yes, be aware of what&rsquo;s happening but try not to get over involved in a situation that is honestly out of your direct control. It&rsquo;s a time to truly, mindfully explore a relationship &ndash; not just with each other but the one with yourself. This lockdown is temporary, but intimacy is eternal. There will be a time, hopefully in&nbsp; the near future, where life will be back to as we&rsquo;ve always known it &ndash; hopefully much better with greater awareness, more empathy, and a general sense of love and regard for one another. There is nothing that&rsquo;s more hardwired in the human psyche than our sense of survival, and with each calamity, we evolve into a better version of ourselves. With all that heavy stuff aside, I&rsquo;m keen to show off my newly learnt millennial terms; I encourage you to &lsquo;Netflix and chill.&rsquo; So cuddle up while watching your favourite sitcom together, and let this entertainment indulgence unfold into your own pleasurable experience. You can also Hotstar and chill, Prime and chill, and whatnot &ndash; it all means the same thing apparently! Silly &lsquo;ol me.</p>
        `
  }
]
