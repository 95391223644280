import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { getLanguageTranslation } from '../../../utils'
import LText from '../../core/LText'

const ProductHowToUse = function ({ match: { params: { language } }, product }) {
  if (!product.how_to_use_image) {
    return null
  }
  return (
    <div className="how-to-use-container">
      <div className="container">
        <h2 className="home-page-subtitle">
          <LText k="HOW_TO_USE"/>
        </h2>
        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <img className="how-to-use-image" src={product.how_to_use_image} alt={product.how_to_use_text}/>
          </div>
        </div>
        <p className="how-to-use-footer">
          {getLanguageTranslation(product, 'how_to_use_text', language)}
        </p>
      </div>
    </div>
  )
}

ProductHowToUse.propTypes = {
  product: PropTypes.object,
  match: PropTypes.object,
  footerText: PropTypes.string
}

ProductHowToUse.defaultProps = {}

export default withRouter(ProductHowToUse)
