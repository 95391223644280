import React from 'react'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { loadableReady } from '@loadable/component'

// import Router from './router';
import store from './store'
import { DEBUG } from './constants'
import App from './App'
import AOS from 'aos'
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

import '../scss/main.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-alice-carousel/lib/alice-carousel.css'
import 'react-toastify/dist/ReactToastify.css'
import 'aos/dist/aos.css'
import 'normalize.css'

if (DEBUG !== 'true') {
  Sentry.init({ dsn: 'https://bdc1371b1ab940969ab0bd0322ea12d4@sentry.io/1778897' })
}
AOS.init()

loadableReady(() => {
  const root = document.querySelector('#app')
  hydrate(
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>,
        root
  )
})
