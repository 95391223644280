import React from 'react'
import PropTypes from 'prop-types'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons/faMoneyBill'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router'

class CartState extends React.Component {
    static propTypes = {
      history: PropTypes.object,
      match: PropTypes.object,
      step: PropTypes.oneOf([1, 2, 3])
    }

    handleCartClick = () => {
      const { step, history, match } = this.props
      if (step >= 2) {
        history.push(`${match.params.language}/checkout/`)
      }
    }

    handleAddressClick = () => {
      const { step, history, match } = this.props
      if (step >= 2) {
        history.push(`${match.params.language}/checkout/address/`)
      }
    }

    handleBackPress = () => {
      const { history } = this.props
      history.goBack()
    }

    render () {
      const { step } = this.props

      return (
            <div className="cart-state">
                <div className={`cart-state-step ${step >= 1 ? 'active' : ''}`}>
                    <button className="cart-state-step-icon">
                        <FontAwesomeIcon icon={faShoppingCart} size="1x"/>
                        <span>Cart</span>
                    </button>
                </div>
                <div className={`cart-state-step ${step >= 2 ? 'active' : ''}`}>
                    <button className="cart-state-step-icon">
                        <FontAwesomeIcon icon={faMapMarkerAlt} size="1x"/>
                        <span>Address</span>
                    </button>
                </div>
                <div className={`cart-state-step ${step === 3 ? 'active' : ''}`}>
                    <button className="cart-state-step-icon">
                        <FontAwesomeIcon icon={faMoneyBill} size="1x"/>
                        <span>Payment</span>
                    </button>
                </div>
                {/* { */}
                {/*    step >= 2 && */}
                {/*    <button className="cart-state-back-button" onClick={this.handleBackPress}> */}
                {/*        <FontAwesomeIcon icon={faChevronLeft} size="1x"/> */}
                {/*    </button> */}
                {/* } */}
            </div>
      )
    }
}

export default withRouter(CartState)
