import api from '../api'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCTS_RESULT = 'FETCH_PRODUCTS_RESULT'
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR'
export const FETCH_PRODUCTS_RETRY = 'FETCH_PRODUCTS_RETRY'

export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const FETCH_PRODUCT_RESULT = 'FETCH_PRODUCT_RESULT'
export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR'
export const FETCH_PRODUCT_RETRY = 'FETCH_PRODUCT_RETRY'

export const fetchProducts = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_PRODUCTS })
    const res = await api.ecommerce.product.list(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_PRODUCTS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_PRODUCTS_ERROR, message: result })
    }
  } catch (e) {
    console.log(e)
    dispatch({ type: FETCH_PRODUCTS_RETRY, message: e.message })
  }
}

// this is a dummy action.
export const fetchProduct = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_PRODUCT })
    const res = await api.ecommerce.product.get(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_PRODUCT_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_PRODUCT_ERROR, message: result })
    }
  } catch (e) {
    console.log(e)
    dispatch({ type: FETCH_PRODUCT_RETRY, payload: [] })
  }
}
