import { Images } from '../../constants'

export const contributors = [
  {
    name: 'Rohan Pasricha',
    image: Images.therapist,
    description: 'Rohan has a postgraduate diploma in Counselling Psychology from the Xavier Institute of Counselling Psychology (XICP), accompanied by an advanced diploma in Integrative Counselling from The Institute of Human Technology, Mumbai.\n' +
            '<br/> <br/>' +
            'He is certified in the Robert Carkhuff model of counselling, Rogerian therapy, Rational Emotive Behaviour Therapy (REBT) and Transactional Analysis (TA).\n' +
            '<br/> <br/>' +
            'Rohan currently runs a private practice in Mumbai, where he successfully assists clients from all walks of life, to cope with myriad concerns.\n' +
            '<br/> <br/>' +
            'In addition, he also counsels at St. Catherine of Siena School and Orphanage, in Bandra, Mumbai mainly working with children and young adults.'
  },
  {
    name: 'Niharika Naik',
    image: Images.team.niharika,
    description: 'Niharika is a Sociology student by choice and restless by force of habit.' +
            '<br/> <br/>' +
            'She has a B.A. and M.A. degree in Sociology from St. Xavier\'s College, Mumbai and Jawaharlal Nehru University, New Delhi respectively. Her main vision for this page is to take what was learned through academia and her individual experiences and throw it open for discussion.'
  }
]

export const rohanArticles = (lang) => [
  {
    title: 'It’s just sex, silly!',
    image: Images.thinkingsex.sex_education,
    content: `
        <p>Covid has sent us into a content consumption abyss that&rsquo;s hard to get out of, unless you become vigilant about what you&rsquo;re watching and why. Thankfully, for the discerning among us, a few gems stand out from the crowd and offer an insightful look into topics that are relatively covert, at least in our country. But then, we&rsquo;re over a billion, and surely we think of it, and do it, a lot. The ongoing assumption is, the more secretive an activity is, the more mystery shrouds its essence.&nbsp; The show Sex Education on Netflix, however, takes this hypocritical idea and slaps it out of its slumber!&nbsp;</p>
        <p>Set in Moordale High, a British educational institution where expectations from life are high, and &lsquo;sexpectations&rsquo; from life even higher, this zany show, spread across 2 seasons so far and 16 episodes. It literally addresses every question you&rsquo;ve had around coitus &ndash; in a manner that is unabashedly refreshing.&nbsp; The plot revolves around the life of teenager Otis, who&rsquo;s got a famous sex therapist for a mother; and she prefers, as most therapists do, for issues to be &lsquo;out in the open&rsquo;. These culminate into hilarious situations for the hapless kid who navigates his adolescent years dealing with his mom on the one hand and being unwillingly crowned the sex-ed guru in his own school with business partner-crush Maeve Wiley for company, on the other. Maeve has issues of her own that work well as a subplot, as do all the other sub-plots revolving around teenagers and adults at Moordale. Their experiences serve as interesting insights into sexual health and wellness. <a href="https://misters.in${lang}/qa" target="_blank" class="ingredient-blog-links">It&rsquo;s essentially like our FAQ section has come alive in video form!</a></p>
        <p>Every episode begins with highlighting a sexual concern, ranging from the ordinary to the esoteric &ndash; the latter I could relate to as a therapist because honestly I&rsquo;ve heard it all. But some of you are sure to go, &ldquo;Really? This happens too?&rdquo; Most importantly though, it takes the stigma out of sex, and normalises the experience to make it relatable to a diverse viewership.&nbsp; For a show to take a little-discussed issue and make it so interesting and so &lsquo;out there&rsquo; is truly commendable.&nbsp;</p>
        <p>It doesn&rsquo;t start and end at sex though. Other deep-rooted issues in society are explored, across a wide spectrum, ranging from homosexuality and fetishes, to bullying and divorce. In a nutshell, this series explores the physical and emotional facets of human personality including all our desirable and undesirable traits in an unapologetic manner and that is the show&rsquo;s true strength. If the stark naked truth about life is what you&rsquo;re looking to uncover, then Sex Education is worth your viewing time.&nbsp;</p>
        <br/>
        <p><b>Misters Rating: ****</b></p>
        `
  },
  {
    title: 'Intimacy in isolation',
    image: Images.intimacy.intimacy,
    content: `
       <p>Social distancing has perhaps dethroned sex as the activity (or rather inactivity) people currently think most about. Isolation has put a halt to sexual activity and the community that&rsquo;s been hit hardest are the singles, who&rsquo;ve now replaced the popular phrase &ldquo;not ready&rdquo; with saying, &ldquo;Single, but not allowed to mingle.&rdquo; This lack of freedom, given the circumstances, is welcome. With India&rsquo;s Covid cases seeing a steady rise with each day, keeping oneself isolated is the only solution till scientists working tirelessly in the lab to come up with a much-needed vaccine. As per a recent news report, India seems to be defying the general trend of the virus globally, and most patients affected are millennials. While the overall numbers are still on the lower end, this could well change in the coming weeks with more testing. So isolation really is the mantra in order to stay healthy. It&rsquo;s possible that given the rapidly changing scenario, we might be staring at an extended lockdown deadline. Till then, it&rsquo;s only responsible to seek self-pleasure, unless you&rsquo;re in a live-in or committed relationship. Certainly, we&rsquo;re all facing myriad problems as we stare at an uncertain future, but as the adage goes, life must go on and while we&rsquo;re all out of our regular routines, maintaining a semblance of normalcy is integral to mental health.&nbsp;&nbsp;&nbsp;</p>
        <p>The collective opinion of health professionals is that steady, married couples can freely indulge in sexual activity, as long as they&rsquo;ve been living together (during the entire lockdown period) and aren&rsquo;t exhibiting symptoms. In fact, intercourse itself hasn&rsquo;t been proven to spread the virus, but who are we kidding? Kissing is an integral part of sex, unless you&rsquo;re one of those humanoids in WestWorld. For humans, it&rsquo;s a sure-shot way of transmitting Covid through the ever-reliable carrier &ndash; saliva. But if neither of you are infected, then go on, let the love express itself. This is the time when no one is &ldquo;too busy&rdquo; or &ldquo;too tired&rdquo; so it&rsquo;s an opportunity to bring back any sparks that were beginning to fade.&nbsp;</p>
        <p>As a therapist in his thirties mostly living in others&rsquo; minds and my own, I admit that I&rsquo;m not completely aware of millennial parlance. I recently discovered that &lsquo;Netflix and Chill&rsquo; doesn&rsquo;t actually mean to watch a nice show and relax with your loved one, but is a euphemism for sex. But this also made me think: why not just say &ldquo;have sex!&rdquo;. Why must we still be relying on vague phraseology to express our most primal instincts? Anyway, therapy often takes a direction of its own but I need to be in control of this article so forgive this minor digression.&nbsp; Here are 3 ways in which we can satisfy our urges, without letting them remain all bottled up till the lockdown is lifted.</p>
        <ol>
            <li style="margin-bottom: 10px"><strong>Stroke thyself</strong>: No, there&rsquo;s nothing wrong with it (see our FAQs). No, you won&rsquo;t lose hair. No, you won&rsquo;t lose your sex drive. No, it isn&rsquo;t immoral. No, there isn&rsquo;t a finite amount of semen your body can produce. No, it doesn&rsquo;t make you weaker. Yes, it is the safest way to derive pleasure with no risk whatsoever.&nbsp; Remember how you wrote &lsquo;self-starter&rsquo; in the skill set list on your resume? Well, about time you practiced those skills. This one&rsquo;s for the singles of course. I&rsquo;m sure you&rsquo;re clever enough to understand what I&rsquo;m talking about, but as a therapist, I can&rsquo;t ever compromise on clarity for the sake of clever euphemisms. It&rsquo;s about masturbation.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li style="margin-bottom: 10px"><strong>Listen to my desires glisten: </strong>You&rsquo;re in a relationship but living apart? Get creative. Express your desires and talk naughty. Aren&rsquo;t you done with the mundane work con calls already? Use your free time to connect with your better half. Phone sex can be incredibly satisfying if both partners are upto it, and again, it&rsquo;s safe. Bring on your deep baritone and spread love through that telephone.</li>
            <li style="margin-bottom: 10px"><strong>Be mine over Facetime</strong>: Just voice not cutting it? We hear you. Many people need visual stimulation, and if there&rsquo;s a high degree of comfort in your relationship, sexual intimacy over a video call can be exciting and keep your sex life from going into cold storage. Just ensure you&rsquo;re using reliable, encrypted services such as Skype or FaceTime.&nbsp;&nbsp;</li>
        </ol>
        <p>An important suggestion here: please stay away from watching the news the entire day or consuming content subconsciously through social media by endlessly scrolling through your feed. Yes, be aware of what&rsquo;s happening but try not to get over involved in a situation that is honestly out of your direct control. It&rsquo;s a time to truly, mindfully explore a relationship &ndash; not just with each other but the one with yourself. This lockdown is temporary, but intimacy is eternal. There will be a time, hopefully in&nbsp; the near future, where life will be back to as we&rsquo;ve always known it &ndash; hopefully much better with greater awareness, more empathy, and a general sense of love and regard for one another. There is nothing that&rsquo;s more hardwired in the human psyche than our sense of survival, and with each calamity, we evolve into a better version of ourselves. With all that heavy stuff aside, I&rsquo;m keen to show off my newly learnt millennial terms; I encourage you to &lsquo;Netflix and chill.&rsquo; So cuddle up while watching your favourite sitcom together, and let this entertainment indulgence unfold into your own pleasurable experience. You can also Hotstar and chill, Prime and chill, and whatnot &ndash; it all means the same thing apparently! Silly &lsquo;ol me.</p>
        `
  },
  {
    title: 'First night fright',
    image: Images.thinkingsex.fright,
    content: `
        <p>Readiness? Check. High motivation? Check. Will to emerge triumphant? Check. I&rsquo;d forgive you for assuming that I&rsquo;m referring to a soldier&rsquo;s preparation for war. But assuredly, you&rsquo;re not that far from the mark. This is about a little soldier, although a euphemistic one, and it also involves a skirmish, albeit one that is confined to the bedroom &ndash; and usually a pleasant one. One that you look forward to. One that you&rsquo;ve heard legendary stories of and seen epic movies on. One that everyone you&rsquo;ve known, know, and will know thinks of and has an opinion on. However, when you&rsquo;re out there in the ravines of love, you&rsquo;re on your own, with only all the plans conceived in your mind for company. But this very thought process begs the question: can you actually prepare for an act that requires the greatest spontaneity? The &lsquo;veterans&rsquo; in this experience are aplenty, and their advice though well-meaning may be exaggerated. So where do the ones who have been recently drafted into the ultimate practicum of life go for some sound advice? How will they ensure that their first major assignment is in sync with their mental expectations of it?&nbsp;</p>
        <p>We live in a world of fake news and confusion so before the above paragraph sends you in a tizzy, let&rsquo;s get one fact out there straightaway - I&rsquo;m talking about sex. But you&rsquo;re a Misters.in reader so it is rather obvious. More specifically, I&rsquo;m referring to a feeling that every man or woman has faced at least once in their lives &ndash; the feeling of &lsquo;doing it&rsquo; for the first time.&nbsp; Or even the first time with a different partner. So when Akash came to therapy and asked rather point blankly, &ldquo;I&rsquo;m due to get married in three months. How do I perform the first night?&rdquo;, his question echoed millions of sentiments out there, hence the need for this piece.&nbsp;</p>
        <p>My foremost clarification to Akash was that my work involves engaging with one&rsquo;s mind, and I am not a doctor who will prescribe a pill, so there&rsquo;s nothing I can do to help boost his performance, except for dealing with his anxiety. So for all those who are reading this, if there&rsquo;s a physical issue, that needs to be tested first. Akash confirmed that nothing is amiss physiologically, and it&rsquo;s simply a case of being nervous. My initial question to him was whether he watches pornography, to which he replied in the affirmative. I then asked if he expects his first time to be like that, to which he excitedly remarked, &ldquo;Of course. Isn&rsquo;t it supposed to?&rdquo; This was the point I realised that there&rsquo;s a lot of work needed ahead.&nbsp;</p>
        <p>Akash isn&rsquo;t alone. All men at some point have had unrealistic expectations around sex. The fact however is that it&rsquo;s never like what gets depicted in adult films, and more importantly, it never lasts that long! Porn stars shoot scenes in small intervals and take performance enhancing supplements and then those multiple small scenes are added together into a several-minutes film, so what you see is quite far removed from reality. But this article isn&rsquo;t about porn. <a href="https://misters.in${lang}/blog/porn-can-cause-sexual-dysfunction" target="_blank" class="ingredient-blog-links">You can read our piece on porn addiction here.</a> Neither was Akash addicted to pornography. He simply wanted his first time to be a specific way, but unlike for him, anxiety isn&rsquo;t always about unrealistic expectations. Sometimes it&rsquo;s just nervousness around a new experience. Akash needed a shift in perspective so that his expectations are more realistic in nature, and this insight itself along with a few other techniques, helped him overcome his anxiety. So while our therapy sessions were in-depth in nature, here&rsquo;s a 3 point basic therapy plan to help you overcome the mental anxiety around that first &lsquo;performance&rsquo;:</p>
        <br/>
        <ol>
            <li style="margin-bottom: 10px;">Revisit the above paragraph and eliminate the word &lsquo;performance&rsquo; from your vocabulary. Love making isn&rsquo;t about scoring points or winning. It&rsquo;s an enjoyable experience that&rsquo;s best indulged in when you&rsquo;re completely at ease. Stress of any kind can and will take a toll on your sexual health, even if it&rsquo;s just minor anxiety. The popular adage, &ldquo;It&rsquo;s all in your head,&rdquo; certainly rings true when it comes to sex. So to get good head, first clear your head. Or the libido goes dead!&nbsp;</li>
            <li style="margin-bottom: 10px;">Don&rsquo;t take advice from your friends. You can tap into your buddy&rsquo;s insight for the best car to buy or where to invest your money safely but not when it comes to the matters of the bedroom. They&rsquo;re most likely to take it lightly and make a joke out of it. Unfortunately, when it comes to these matters, that&rsquo;s what most of us men do. Moreover, they might exaggerate their own supposed &lsquo;prowess&rsquo; in the bedroom, so if they tell you such stories, take it with more than just a pinch of salt.&nbsp;</li>
            <li style="margin-bottom: 10px;">Have an open conversation with your significant other. We don&rsquo;t live in the early 50s and 60s where couples met for the first time at their weddings! This is a culture where everyone knows everyone and before you take your relationship to the sexual level, whether married or not, it&rsquo;s always helpful to have a frank discussion on such matters so there are no surprises. Except for the pleasant ones!&nbsp;</li>
        </ol>
        <p>The third point itself can take care of the other two, since when you openly discuss your feelings with your partner, you&rsquo;re less likely to be swayed by other opinions and you will mentally be at peace as well. Remember, good sex happens in the bedroom but the preparation of it takes place in the mind. Or better still, outside it - if you can get yourself to avoid thinking about it at all! But until you reach that level of Zen mastery, don&rsquo;t dwell on what can go wrong but all the ways it can be right. An oft-looked but integral aspect of foreplay is conversation, and once that&rsquo;s sorted between the couple, the final act that requires no conversation occurs naturally.</p>
        `
  }
]

export const niharikaArticles = (lang) => [
  {
    title: 'Men’s health and infertility- farce or freedom?',
    image: Images.thinkingsex.infertility,
    content: `
        <p>Learning about women&rsquo;s rights, in an age that is fueled by feminist discourse and the liberation of the female sex in every manner possible, is not a new feat. On the other hand, men are known to be the sex with the silver spoon, the privileged lot that majorly maintains a degree of control over the world. And rightly so. So why bother speaking of men&rsquo;s health? In recent times, this issue has gained priority not only on an individual level, but also gained traction in terms of social value. While the final onus of the actions of a man fall upon himself, wider political, social and economic factors definitely come into play. This is simply to say that the concerns of men&rsquo;s health are not just a &lsquo;male&rsquo; problem, but rather a matter of collective importance with repercussions for all of society <em>(White, 2002).</em></p>
        <p>So let&rsquo;s get to the crux of the matter already. We&rsquo;re specifically here to shed light on the topic of male infertility in India, along with all its ramifications. Some discovered and some yet to be found. It's no secret that Indian data regarding male infertility vis-a-vis emotional experiences is scarce and inadequate. It is also safe to say that this condition feels like an emotional &lsquo;rollercoaster&rsquo; for men, filled with angst, fear, distress and doubt <em>(Hanna &amp; Gough, 2016).</em> There is a strong stigma attached to men being sensitive, in Indian society. You all know what we&rsquo;re talking about.&nbsp;</p>
        <p>Just when you think that male infertility and its underackowledgement is specific to India alone, there is a tsunami of global research to suggest otherwise. Read below to know more!&nbsp;</p>
        <ul>
            <li style="margin-bottom: 10px;">A study based in Nigeria attempted to study the impact of infertility on men and women. The conclusion suggested that the Nigerian concept of infertility lay in their mistrust for contraceptive methods and abortion, blaming these measures for barrenness. Nigerian men prioritized the economic aspect&nbsp; of infertility. They spent about 55-100% of their total income to efficiently get to the bottom of their impotence <em>(Nieuwenhuis, Liu, eds., 2009)</em>! The women experienced a deep-rooted psychological impact.&nbsp;</li>
            <li style="margin-bottom: 10px;">Even in the western New York State, researchers sought to seek answers for male and female infertility. Women viewed the condition as an absolute failure on their part, to meet their gendered role in society. Men, on the other hand, confronted the problem as being demoralizing and confusing, but not necessarily an utter tragedy <em>(Porter, Lietko &amp; Greil, 1988)</em></li>
        </ul>
        <p>Budding sociologist Pragya Roy makes a pertinent point too. In her paper, &ldquo;Infertility: Not a Medical Condition&rdquo; she emphasises that Infertility has been socially constructed as a &ldquo;private problem&rdquo; between heterosexual couples. These definitions have only been challenged in contemporary times. What if partners don&rsquo;t wish to be parents? What if they have no desire to bear a child but feel happiest when they are together, irrespective of their genders? In these cases, even if infertility exists, no conflict is seen because the social role of parenthood is not desired.&nbsp;</p>
        <p>In conclusion, the theories of infertility are perceived differently by varied societies. Having said that, however, impotency in a nation like India cannot be taken lightly, given that family structures are etched into the very fabric of it. An alarming 50% of Indian males, above the age of 40 face problems in getting an erection. We at Misters understand your predicament and aim to provide natural ayurvedic solutions for infertility related issues . So take a deep breath, and log on to <a href="http://www.misters.in/en/" target="_blank" class="ingredient-blog-links">www.misters.in/en/</a> because we&rsquo;re here to help and because you are not alone.&nbsp;</p>
        <br/><br/><br/>
        <p><strong>REFERENCES:&nbsp;</strong></p>
        <ul>
            <li style="margin-bottom: 10px;">White, R. (2002). Social and political aspects of men's health. <em>Health, 6</em>(3), 267-285.</li>
            <li style="margin-bottom: 10px;">Hanna, E., &amp; Gough, B. (2016). Emoting infertility online: A qualitative analysis of men&rsquo;s forum posts. <em>Health, 20</em>(4), 363-382.</li>
            <li style="margin-bottom: 10px;">Nieuwenhuis, S., Odukogbe, A., Theobald, S., &amp; Liu, X. (2009). The Impact of Infertility on Infertile Men and Women in Ibadan, Oyo State, Nigeria: A Qualitative Study. <em>African Journal of Reproductive Health / La Revue Africaine De La Sant&eacute; Reproductive, 13</em>(3), 85-98.</li>
            <li style="margin-bottom: 10px;">Greil, A., Leitko, T., &amp; Porter, K. (1988). Infertility: His and Hers. <em> Gender and Society, 2</em>(2), 172-199.</li>
            <li style="margin-bottom: 10px;">Roy, P. (2019). Infertility- Not a Medical Condition If Parenthood Is Not Idealised. <em>Feminism in India.</em></li>
        </ul>
        `
  },
  {
    title: 'Manhood and ‘Mardaani’- knowing the difference',
    image: Images.thinkingsex.mardaani,
    content: `
        <p>Dear man, what could possibly bother you? Look at you, grinning at the top of the social ladder. You have got a job to do, women to woo and a world to conquer. If you have woes, not many would display the willingness to listen, and the ones who do, will probably tell you to laugh it off. So seriously, what forces put the word &lsquo;man&rsquo; in manhood? Is it just something learned through observation? Or is there a deeper plot at play?&nbsp;</p>
        <p>Over time, numerous sociologists and researchers have offered fresh perspectives that transcend the traditional notions of manhood and reveal a unique lens through which gender and sexuality can be perceived. For the longest time, what makes a man has been handed down by social institutions in the form of a perpetual role. A repeated carrying out of activities that must somehow provide a male degree of proof <em>(Bosson &amp; Vandello, 2011).</em></p>
        <p>The problem with gender roles lie not so much in their everyday realization, as much as in their overall structure. They not only have negative consequences for the people who surround these men in daily life, but also upon the men themselves. For example, the constant need to emerge victorious and maintain that victory often leads to problematic behaviours such as unnecessary violence, and extreme aggression in men. Conversely, in African American cultures, navigating masculinity can be a whirlwind of tormenting emotions; where men are observed to be at war with their minds, whilst simultaneously fighting to ascertain an intimate contribution to their larger community <em>(Hunter &amp; Davis, 1992).</em></p>
        <p>If&nbsp; you&rsquo;re reading this, we know you can relate but just to be sure, let&rsquo;s get real personal and shed light on &lsquo;Mardaani&rsquo; or manhood in the Indian context.</p>
        <p>Indulge us, and imagine an Indian King. One that is an ideal son, an ideal ruler, an ideal husband and an overall ideal man. He possesses a paternal love for his subjects, takes decisions for the welfare of his kingdom and when needed, leads his people valiantly into battle. Now consider this. If a king were to abstain from war or flee a battlefield, he would obtain the title of a coward. Yet, history is testament to&nbsp; celebrated kings like Ashoka who were the epitome of non-violence or <em>ahimsa.</em> Ram, despite being an ethical man, driven by mistrust, forced Sita to perform a trial by fire after she was abducted by Ravana. Do you see what&rsquo;s happening here? Kingship is paving the way in how masculinity should be framed. It is inherently contradictory but accepted, nonetheless <em>(Sahgal, 2015).</em></p>
        <p>Studies of rural North India show ideas of masculinity percolating through oral tradition such as myths, folk songs, tales and proverbs that are relatively fluid and serve as a threat to male power. It is interesting to see how this ancient wisdom and the recent, rigid patriarchy interact with one another in this particular region <em>(Chowdhry, 2015).</em></p>
        <p>Research in metropolitan cities reveals the biological side of manhood. Data collected from the cities of Mumbai and Kolkata reported an increasing childlessness among Indian families, even post 5 years of marriage. This infertility is caused by habits such as excessive smoking and asthma that are aggravated by joblessness and poor standards of living <em>(Chattopadhyay &amp; Mukherjee, 2015).</em></p>
        <p>An interesting finding in the Indian state of Kerala suggests that manhood is more complicated than it seems when applied to everyday interactions between men and women. South Indian movies definitely depict hypermasculinity, but there is more than meets the eye. Romance, creativity and the artistic abilities of a man are greatly sought after and leads to younger boys emulating that demeanor <em>(Osella &amp; Osella, 1998).</em></p>
        <p>There is no doubt that &lsquo;mardaani&rsquo; today, is constructed through a heterosexual lens. India being the land of the Kamasutra and historic sites like the Khajuraho temples, doesn&rsquo;t do this narrative justice. The recurring fantasy of men being transformed into women for the pleasure of more powerful men in that very society, is a complex issue, but not a novel one. The references to transexuality in India are as old as time and ought to be studied <em>(Goldman, 1993).</em></p>
        <p>In conclusion, manhood does not exist in a vacuum. Even among themselves, men face discrimination on the basis of their caste, sexuality and socio-economic standings. The literal meaning of the word &lsquo;mardaani&rsquo; is to be mannish. Although, the aim of being male should be- the ability to face rejection, a respect for all sexes and to embrace yourself just as you are <em>(Bunch, 2013). </em>Good luck on your journey!</p>
        <br/><br/><br/>
        <p><strong>REFERENCES:&nbsp;</strong></p>
        <ul>
            <li style="margin-bottom: 10px;">Bosson, J., &amp; Vandello, J. (2011). Precarious Manhood and Its Links to Action and Aggression. <em>Current Directions in Psychological Science, 20</em>(2), 82-86.</li>
            <li style="margin-bottom: 10px;">Hunter, A., &amp; Davis, J. (1992). Constructing Gender: An Exploration of Afro-American Men's Conceptualization of Manhood. <em>Gender and Society, 6(3), 464-479.</em></li>
            <li style="margin-bottom: 10px;">Sahgal, S. (2015). Situating Kingship within an Embryonic Frame of Masculinity in Early India. <em>Social Scientist, 43</em>(11/12), 3-26.</li>
            <li style="margin-bottom: 10px;">Chowdhry, P. (2015). Popular Perceptions of Masculinity in Rural North Indian Oral Traditions. <em>Asian Ethnology, 74</em>(1), 5-36.</li>
            <li style="margin-bottom: 10px;">Chattopadhyay, A., &amp; Mukherjee, R. (2015). Primary Childlessness among Married Men in India. <em>Sociological Bulletin, 64</em>(3), 325-340.</li>
            <li style="margin-bottom: 10px;">Osella, C., &amp; Osella, F. (1998). Friendship and Flirting: Micro-Politics in Kerala, South India. <em>The Journal of the Royal Anthropological Institute, 4</em>(2), 189-206.</li>
            <li style="margin-bottom: 10px;">Goldman, R. (1993). Transsexualism, Gender, and Anxiety in Traditional India. <em>Journal of the American Oriental Society, 113</em> (3), 374-401.</li>
            <li style="margin-bottom: 10px;">Bunch, T. (2013). RETHINKING MANHOOD. <em>Journal of International Affairs, 67</em>(1), 217-223.</li>
        </ul>
        `
  },
  {
    title: 'Thinking, SEX…',
    image: Images.thinkingsex.sex,
    content: `
        <p><em>&ldquo;Some things are better than sex, and some are worse, but there&rsquo;s nothing exactly like it.&rdquo;&nbsp;</em></p>
        <p class="text-right"><em>- &nbsp; W.C. Fields</em></p>
        <p>How many of us have at some point seen a sign board that reads, &ldquo;SEX! Now that I have your attention&hellip;?&rdquo; Whether we like it or not, this 3 letter word makes the world go round. While some religious institutions such as the church sometimes teach abstinence from it, and other social ones like family repress it, the mention or non-mention of it seems omnipresent.&nbsp;</p>
        <p>
        Unfortunately, the zest, taboo and curiosity around this term leads to much misinformation. For example, one of the most common mistakes made is the conflation of the words &lsquo;gender&rsquo; and &lsquo;sex.&rsquo; Please note that they DO NOT have the same meaning. 
        <br />
        Sex refers to a biological category that is assigned mainly at birth (female, male and intersex) and remains constant for most individuals throughout their lives. Having said that, however, surgical interventions do permit the change of sex by people who so desire it. Gender, on the other hand is an entirely socially constructed category. It involves a constant performance, degree of proof and assigned societal roles throughout one&rsquo;s lifespan. It is shocking how much one can transform the narratives of gender discrimination and prejudice, just by acknowledging the difference between these two concepts <em>(Hatemi &amp; McDermot, 2011).</em></p>
        <p><span style="font-weight: 400;">More often than not, sex is used as a means to an end. Let us explain ourselves. When does one have sex? Whilst in a relationship? Casually? While both these scenarios are acceptable, here are 2 basic instances involving the use of sex which are not.&nbsp;</span></p>
        <p><strong>Sex as a substitute for power:</strong> <strong>ALL SEX MUST BE CONSENSUAL.</strong><span style="font-weight: 400;"> We cannot stress this enough. It is okay if you do not comprehend the meaning of the word consent. To elaborate, all parties involved in the act of sexual intercourse must WANT to willingly participate in it. Following this rule is just basic human decency. Non-consensual sex substitutes the act of sex with that of power, giving rise to overtly violent activities such as rape and molestation.&nbsp;</span></p>
        <p><strong>Sex as a substitute for control:</strong><span style="font-weight: 400;"> This refers mainly to the act of using excessive indulgence or complete withdrawal from sex, specifically to maintain hegemony in any kind of relationship.&nbsp;</span></p>
        <p><span style="font-weight: 400;">Moreover, here are some relevant examples of how sex is used as a tool:&nbsp;</span></p>
        <p><strong>When sex was not so important:</strong><span style="font-weight: 400;"> Let us date back to the Harrapan civilization that we now have a much better understanding of through excavation processes. Anthropomorphic terracotta figurines from this period reveal a fluid sense of gender and sex. It can be said that the primary concern of Harappan social organization did not lie in the segregation of the sexes, making it a comparatively more flexible system than the one we now know </span><em><span style="font-weight: 400;">(Clark, 2003).&nbsp;</span></em></p>
        <p><strong>Sex as an occupation:</strong><span style="font-weight: 400;"> We often overlook the fact that hundreds earn their livelihood by performing sex work that includes male, female and intersex individuals. Not only is sex work stigmatized and considered as deviant behaviour, it also involves material consequences like lack of basic resources and unfair treatment </span><em><span style="font-weight: 400;">(Weitzer, 2009).&nbsp;</span></em></p>
        <p><strong>Sex for the purpose of reproduction:</strong><span style="font-weight: 400;"> A study conducted in the Indian state of Manipur sheds light on sexual bias towards male children among married women. This is highlighted through their controlled use of contraceptives, showing that in one household, all male children are preferred but not necessarily all female children. This is a great example of the interaction between sex and gender resulting in social and demographic repercussions </span><em><span style="font-weight: 400;">(Ladusingh, Devi &amp; Singh, 2006).&nbsp;</span></em></p>
        <p><strong>Sex as disease:</strong><span style="font-weight: 400;"> We know you&rsquo;re thinking it too. It's obviously HIV. An interesting article traces the journey of this epidemic in postcolonial India. As the need to treat it became more and more urgent, formerly marginalized sex workers, usually afflicted with this condition, now became stakeholders and partners in important political decisions and legislations passed by the government regarding their own lives </span><em><span style="font-weight: 400;">(Lakkimsetti, 2014).&nbsp;</span></em></p>
        <p><strong>Sex as an educative tool:</strong><span style="font-weight: 400;"> If youv&rsquo;ve stuck with us through the end of this piece, know that we saved the best for last! Adolescence is generally the time when individuals are both most equipped and most impressionable to learn about and be affected by the phenomenon of sex. This trajectory affects all future perceptions and encounters, of and with sex. The conflicting portrayal through glorification and villification of sex in popular culture does not help the cause </span><em><span style="font-weight: 400;">(Mollborn, 2015).&nbsp;</span></em></p>
        <p><span style="font-weight: 400;">In Indian society where family is the primary group of socialization, it is imperative that parents inculcate a healthy attitude towards sex and its safe practice among children. An early orientation to variant genitalia, the onset of menstruation, puberty, erections and masturbation definitely helps. It is only then, once of legal age, that adults may approach sex holistically, irrespective of their religion, socio-economic status, caste, sexual orientation and number of partners </span><em><span style="font-weight: 400;">(</span></em><em><span style="font-weight: 400;">Altalib, AbuSulayman &amp; Altalib, 2013).&nbsp;</span></em></p>
        <br /><br /><br /><br />
        <p><strong>REFERENCES:&nbsp;</strong></p>
        <ul>
            <li style="margin-bottom: 10px;"><span style="font-weight: 400;">McDermott, R., &amp; Hatemi, P. (2011). Distinguishing Sex and Gender. </span><em><span style="font-weight: 400;">PS: Political Science and Politics,</span></em> <em><span style="font-weight: 400;">44</span></em><span style="font-weight: 400;">(1), 89-92.</span></li>
            <li style="margin-bottom: 10px;">Clark, S. (2003). Representing the Indus Body: Sex, Gender, Sexuality, and the Anthropomorphic Terracotta Figurines from Harappa. <em><span style="font-weight: 400;">Asian Perspectives,</span></em> <em><span style="font-weight: 400;">42</span></em><span style="font-weight: 400;">(2), 304-328.&nbsp;</span></li>
            <li style="margin-bottom: 10px;">Weitzer, R. (2009). Sociology of Sex Work. <em><span style="font-weight: 400;">Annual Review of Sociology,</span></em> <em><span style="font-weight: 400;">35</span></em><span style="font-weight: 400;">, 213-234.</span></li>
            <li style="margin-bottom: 10px;">Ladusingh, L., Devi, N., &amp; Singh, K. (2006). Sex Preference and Contraceptive Use in Manipur. <em><span style="font-weight: 400;">Sociological Bulletin,</span></em> <em><span style="font-weight: 400;">55</span></em><span style="font-weight: 400;">(1), 67-77.</span></li>
            <li style="margin-bottom: 10px;">Lakkimsetti, C. (2014). &ldquo;HIV Is Our Friend&rdquo;: Prostitution, Biopower, and the State in Postcolonial India. <em><span style="font-weight: 400;">Signs,</span></em> <em><span style="font-weight: 400;">40</span></em><span style="font-weight: 400;">(1), 201-226.</span></li>
            <li style="margin-bottom: 10px;"><span style="font-weight: 400;">Mollborn, S. (2015). Mixed Messages About Teen Sex. </span><em><span style="font-weight: 400;">Contexts,</span></em> <em><span style="font-weight: 400;">14</span></em><span style="font-weight: 400;">(1), 44-49.</span></li>
            <li style="margin-bottom: 10px;">Altalib, H., AbuSulayman, A., &amp; Altalib, O. (2013). Sex and Sex Education: What Do We Tell Our Children? In <em><span style="font-weight: 400;">Parent-Child Relations: A Guide to Raising Children</span></em><span style="font-weight: 400;"> (pp. 257-304). London; Washington: International Institute of Islamic Thought.</span></li>
        </ul>
        `
  },
  {
    title: 'Michel Foucault- the sexpert of the 20th century',
    image: Images.thinkingsex.michel,
    content: `
        <p><span style="font-weight: 400;">Livid academicians may look upon this piece with disdain and utter surprise owing to the reduction of Foucault as a mere sexpert. This indignation is not misplaced. The vast body of Foucault&rsquo;s work not only shaped but also spoke truth to power in discussions of queer, literary and medicinal theory to name a few. From madness to knowledge, civilization to prison cells, he offered unique, actionable and refined perspectives to these concepts. Also-fair disclaimer&mdash;this is not to claim that Foucault has nothing to do with Misters. If he did, that would be very cool, but no, he doesn&rsquo;t&hellip;&nbsp;&nbsp;</span></p>
        <p><em><span style="font-weight: 400;">&ldquo;Whether in the form of subtle confession in confidence or an authoritarian interrogation, sex- be it refined or rustic- had to be put into words.&rdquo;&nbsp;</span></em></p>
        <p><span>Above is a couplet from Foucault&rsquo;s groundbreaking work The History of Sexuality- Volume 1: An Introduction. He traces the discussion on sex through the 18th century in an exceedingly graded and stratified way. The element of discursive thought was paramount in this period. So what does this really mean? Discursivity can be termed as a process through which dominant realities and ways of knowledge come into existence; the knowledge systems related to sex, in this case. Foucault emphasised on the way this knowledge was moulded to frame sex problems and solutions of the time, in a particular way.&nbsp;</span></p>
        <p><span>The rise of the bourgeoisie class who owned most of the capital and society&rsquo;s wealth formed a vocabulary and discourse around sex that was politically lucrative and &lsquo;economically useful.&rsquo; Intentionally, the indulgence in sex as mere pleasure was sidelined, brought to the forefront only through the attachment of immoral connotations to it. Foucault terms this phenomenon as &lsquo;repressive hypothesis.&rsquo;&nbsp;</span></p>
        <p><span>Outlets of sexual deviance in this era was through&nbsp; fields like psychiatry and prostitution but they continued to breathe within the frameworks sanctioned by larger society. So sexually &lsquo;immoral&rsquo; beings that acted &lsquo;against the law of nature&rsquo; existed, but in ways that were &lsquo;allowed&rsquo; and &lsquo;permitted&rsquo; by the command of others more powerful.&nbsp;</span></p>
        <p><span>One may argue that Foucault theorized in a different century. How can this possibly relate to modern societies? Well, it does. Foucault considered modern societies as distinct, in the sense that they made sure to excessively give voice to sex, in the same way, over and over, while parallelly maintaining it as a secret interaction.&nbsp;</span></p>
        <p><span>It may seem difficult&nbsp; to relate to unless there is an example closer to home. India has been vehemently against the introduction of sex education in schools in the past deeming it &lsquo;against Indian culture.&rsquo; However, the nation is the second most populous in the world.&nbsp;</span></p>
        <p><span>Heterosexual monogamy was left to its own devices, unquestioned, unchallenged and accepted as a norm. Homosexuality, the sexuality of children, the perverse pleasures of a madman, formerly repressed, were brought into the limelight using institutions like law, marriage and a staunch ethic of Christian commandments. The aforementioned groups of people were thrust with the herculean task of having to define themselves. As if any of us truly can.&nbsp;</span></p>
        <p><span>By defining the natural, an unnatural is automatically made and vice-versa. What could this possibly mean? Does the coexistence of individuals such as these in our society indicate intellectual liberation? Or does the singling out of certain individuals comment on the nature of permanent scrutiny and surveillance in modern societies?</span></p>
        <p><span>Sexperts today offer advice and recourse pertaining to sexual health and dysfunctions, much like Misters. Foucault offered insight into how sex was muffled, censored, spoken of, who disemminated sexual information, in what quantity, quality and with what intentional apparatus. He maintained that sexuality be engaged in and open, frank conversations around it be enjoyed for eventual &lsquo;liberation.&rsquo;</span></p>
        <br /><br />
        <p><strong>REFERENCES:&nbsp;</strong></p>
        <ul>
            <li style="margin-bottom: 10px;"><span style="font-weight: 400;">Foucault, M. (1978). </span><em><span style="font-weight: 400;">The History of Sexuality. Volume 1: An Introduction.</span></em><span style="font-weight: 400;"> New York, Pantheon Books.&nbsp;</span></li>
            <li style="margin-bottom: 10px;"><a target="_blank" class="ingredient-blog-links" href="https://qrius.com/sex-education-in-india/">https://qrius.com/sex-education-in-india/</a></li>
            <li style="margin-bottom: 10px;"><a target="_blank" class="ingredient-blog-links" href="http://www.careerizma.com/blog/sex-education-india/"><span style="font-weight: 400;">http://www.careerizma.com/blog/sex-education-india/</span></a></li>
        </ul>
        `
  },
  {
    title: 'Erectile Dysfunction- a brief history and individual consequence',
    image: Images.thinkingsex.ED,
    content: `
        <p>A man leans against the window sill, despondent and utterly defeated, a flurry of wild thoughts of inadequacy rushing through his brain. We&rsquo;re in the year 2020 and he looks thoroughly disappointed with something he has done, or caused or something he feels deep inside his core. Why am I unable to get and maintain an erection? Am I inadequate? Why can&rsquo;t I please my partner? Shall I video call a specialist on Zoom?&nbsp;</p>
        <p><span style="font-weight: 400;">The oldest recorded reference to what we know as Erectile Dysfunction today, lies in the </span><em><span style="font-weight: 400;">Samhita of Sushruta, </span></em><span style="font-weight: 400;">an ancient Ayurvedic text written around 8th Century BC compiled in the Indian subcontinent. Inability to sustain an erection owing to mental origin by indulging in sexual intercourse with a &lsquo;distasteful&rsquo; woman are penned here. Concoctions of clarified butter, the testes of alligators and eggs of sparrows, mice and frogs made into a paste and lapped on the man&rsquo;s foot were said to enable him to visit women with unwavering vigour. Showing that impotence was quite clearly linked to sexual ability and performance in bed.&nbsp;</span></p>
        <p><span style="font-weight: 400;">It&rsquo;s the year 1420 now, the hazy shadow of Thomas Hoccleve is dimly projected onto the wall beside him. Crouching over quill and parchment, assisted by a dimly lit oil lamp, he pens down his five thousand five hundred verse poem, </span><em><span style="font-weight: 400;">De Regimine Principum</span></em><span style="font-weight: 400;"> or The Government of Princes. Deriving from the Latin word </span><em><span style="font-weight: 400;">impotencia, </span></em><span style="font-weight: 400;">he unwittingly uses the phrase &lsquo;impotence&rsquo; for the first time with its basis in a lack of power. Only to be invoked by Thomas Fuller, around 1655, to provide the term with sexual connotation, now denoting it to mean a loss of sexual power in his work </span><em><span style="font-weight: 400;">The Church History of Britain.&nbsp;</span></em></p>
        <p>Apart from the connotations of the word &lsquo;impotence&rsquo; evolving over time, its cultural interrogation began only recently when sociologists started attempting to theorize the seemingly natural activity of sex. To assume that sex is a private act does not hold much value. It sure unfolds in a private space, but its consequences transcend this. Sexual acts are often faced with public shame, condemnation or approval, whether one likes it or not, much like an impotent man&rsquo;s character is autommatically questionable.&nbsp;</p>
        <p>Evolution of the body must be normal. The condition of Erectile Dysfunction has been disassociated from that which is deemed normal and slotted into the category of a dysfunction. There is no escaping the fact that the body of the man against the window who has ED is different, and his cognitive bearings distinguishable from a man with ED.&nbsp;</p>
        <p><span style="font-weight: 400;">It is safe to assume that the&nbsp; man is plagued by his inability to get and maintain an erection coupled with his inability to understand why such events are unfolding in the first place. The catastrophization of failure in bed, a &lsquo;macho&rsquo; male attitude and immense performance pressure can often be reflected in his behaviour. An interplay of the culture of fear, suspicion and general irreverence of and by men towards their health due to stigma and gender roles can be held responsible. The man believes that a non-erect penis is the epitome of weakness and want of power. Due to this, representations of toxic masculinity symbolic of an ever ready attitude of conquering the sexual field are perpetuated.</span></p>
        <p>The man&rsquo;s present consequence is affected by the past of cultural and academic discourse on ED. Testimonies of men faced with ED are entrenched in phobias, leading to a pityingly low level of confidence and self-esteem.&nbsp;</p>
        <p><span style="font-weight: 400;">Treatment options for this condition are many, but very few incorporate the experience and cultural evidence of men faced by it. This is a challenge that is best surpassed through a medical practice that is informed by the personal and individual consequences of men suffering from Erectile Dysfunction.&nbsp;</span></p>
        <br /><br />
        <p><strong>REFERENCES:&nbsp;</strong></p>
        <ul>
            <li style="margin-bottom: 10px;"><span style="font-weight: 400;">Shah, J. (2002). Erectile Dysfunction Through the Ages. </span><em><span style="font-weight: 400;">British Journal of Urology.&nbsp;</span></em></li>
            <li style="margin-bottom: 10px;"><a class="ingredient-blog-links" href="https://ses.library.usyd.edu.au/bitstream/handle/2123/352/adt-NU20010515.13563702Chapter1.pdf?sequence=13&amp;isAllowed=y " target="_blank"><span style="font-weight: 400;">https://ses.library.usyd.edu.au/bitstream/handle/2123/352/adt-NU20010515.13563702Chapter1.pdf?sequence=13&amp;isAllowed=y</span><span style="font-weight: 400;">&nbsp;</span></a></li>
            <li style="margin-bottom: 10px;">Stephenson, K (2020). <a class="ingredient-blog-links" target="_blank" href="https://www.tandfonline.com/doi/abs/10.1080/00224499.2019.1663480"><span style="font-weight: 400;">Exploring the Role of Sexual Avoidance in Male Sexual Dysfunction</span></a><span style="font-weight: 400;">. </span><em><span style="font-weight: 400;">The Journal of Sex Research.</span></em><span style="font-weight: 400;"> 57:4, pages 522-533.</span></li>
            <li style="margin-bottom: 10px;">Fred Fuchs, S. (2004). Male Sexual Assault: Issues of Arousal and Consent. <em><span style="font-weight: 400;">Cleveland State Law Review.&nbsp;</span></em></li>
            <li style="margin-bottom: 10px;"><span style="font-weight: 400;"><a class="ingredient-blog-links" href="https://www.psychologytoday.com/us/blog/women-who-stray/201308/erectile-dysfunction-myth" target="_blank">https://www.psychologytoday.com/us/blog/women-who-stray/201308/erectile-dysfunction-myth</a></span></li>
            <li style="margin-bottom: 10px;">Wentzell, E. (2017). How Did Erectile Dysfunction Become &ldquo;Natural&rdquo;? A Review of the Critical Social Scientific Literature on Medical Treatment for Male Sexual Dysfunction, The Journal of Sex Research, 54:4-5, 486-506.</li>
            <li style="margin-bottom: 10px;">Potts, A. (2000). &ldquo;The Essence of the Hard On&rdquo;: Hegemonic Masculinity and the Cultural Construction of &ldquo;Erectile Dysfunction.&rdquo; <em><span style="font-weight: 400;">Men and Masculinities</span></em><span style="font-weight: 400;">, </span><em><span style="font-weight: 400;">3</span></em><span style="font-weight: 400;">(1), 85&ndash;103.</span></li>
            <li style="margin-bottom: 10px;">East, M., Hofer, H., &amp; Wickler, W. (1993). The Erect 'Penis' Is a Flag of Submission in a Female-Dominated Society: Greetings in Serengeti Spotted Hyenas. <em><span style="font-weight: 400;">Behavioral Ecology and Sociobiology,</span></em> <em><span style="font-weight: 400;">33</span></em><span style="font-weight: 400;">(6), 355-370.&nbsp;</span></li>
            <li style="margin-bottom: 10px;">Innovative practice: Ethnography and Men&rsquo;s Health Research. (2013) <em><span style="font-weight: 400;">The Jorunal of Men&rsquo;s Health and Gender, </span></em><span style="font-weight: 400;">Vol. 3, No. 1. Ethnography and Men&rsquo;s Health Research.&nbsp;</span></li>
        </ul>
        `
  },
  {
    title: 'A social analysis of medical treatments for Erectile Dysfunction',
    image: Images.thinkingsex.stethoscope,
    content: `
        <p><span style="font-weight: 400;">While we find ourselves catapulted into a global pandemic, there is nothing short of mountains of praise the human race lauds on medical professionals, personnel and practitioners coalescing day and night to preserve lives. If there is anything the virus has taught us, it is early diagnosis and proper treatment.&nbsp;</span></p>
        <p>However, when this model is applied to the world of sexual dysfunctions, one is then treading comparatively unmapped territories where just timely treatment and an early diagnosis won&rsquo;t cut it. Though lesser is known about the virus than sexual issues and its fast mutating characteristics are all over the news, it does not bear the burden of hushed vocabularies and personal as well as social incapability. Anyway, a comparison between the two serves just to be an element of relevance and current occurings to warm you up for what&rsquo;s to come.&nbsp;</p>
        <p>The need for a comprehensive, cross-culturally valid and reliable measure of erectile function resulted in successfully narrowing down 5 of its components:&nbsp;</p>
        <ol>
            <li style="margin-bottom: 10px"><span style="font-weight: 400;">Erectile function&nbsp;</span></li>
            <li style="margin-bottom: 10px"><span style="font-weight: 400;">Orgasmic function</span></li>
            <li style="margin-bottom: 10px"><span style="font-weight: 400;">Sexual desire&nbsp;</span></li>
            <li style="margin-bottom: 10px"><span style="font-weight: 400;">Intercourse satisfaction&nbsp;</span></li>
            <li style="margin-bottom: 10px"><span style="font-weight: 400;">Overall satisfaction</span></li>
        </ol>
        <p>Sexual dysfunctions refer to a broad term consisting of myriad symptoms like Erectile Dysfunction, loss of libido or desire and the inability to perform intercourse that almost each and every man faces at different stages throughout their life&rsquo;s course. The upkeep and maintenance of the male reproductive system requires the coordination of the nervous system, cardiovascular system and endocrine system. Phew! Even reproductive system sensitivity is affected by environmental, unhealthy lifestyle, drug toxicity and chronic disease factors.That a condition like Erectile dysfunction is not a single disease is made all the more apparent through this interdependent, multi-system reality. Then the rhetorical question finally arises, to what degree will unidimensional treatment options for ED work?&nbsp;</p>
        <p>In 1998, sildenafil, widely recognized by its trade name Viagra, became the first approved recourse for impotence by competent authorities. Said to be effective 30-60 minutes post administration, it&rsquo;s efficacy reduced by a fatty meal with its dosage variants at 25, 50 and 100mg. The recommended dose was set at 50mg lasting upto 12 hours.&nbsp;</p>
        <p>2003 was the year of Tadalafil, yet another licensed drug for ED treatment that took effect post 30 minutes of administration. With an efficacy of about 36 hours, unaffected by food, it was given the green signal to be administered in 10 and 20mg doses, 10 being the recommended one.&nbsp;</p>
        <p><span style="font-weight: 400;">Continuous research in the allopathic field discovered that the consumption of PDE5 inhibitors like Viagra and Tadalafil significantly improved erection patterns in about 70% of hypertensive males, a great medical respite for all men suffering from ED. You can know more about these inhibitors,</span> <a class="ingredient-blog-links" href="https://www.youtube.com/watch?v=h5iLbmqHCYo" target="_blank">here</a>.<span style="font-weight: 400;"> Allopathic treatment, though fundamental, was to be pointed in the direction of patient safety, invasiveness of procedures and cost effective methods.&nbsp;</span></p>
        <p><span style="font-weight: 400;">Once the acknowledgement of Erectile Dysfunction being a primary organic complication and a grave psychological disorder is made it is easier to widen the lens of possible solutions. Allopathy is just one episteme of medicine, in a heterogenous pool of others</span><span style="font-weight: 400;">. The allopathic approach of onset and timely treatment alone is not sufficient to completely reverse ED. In this regard, alternative systems of medicines are being duly explored. W</span><span style="font-weight: 400;">ith a history of utilization spanning over centuries, little to no side-effects, multi-targeted functions and low cost and toxicity, they hold immense promise. Nothing serves as a better example than the Ayurvedic discipline originating from the Indian subcontinent.&nbsp;</span></p>
        <p>A large number of medicinal plants can be employed to regulate erectile function through phytoconstituents such as flavonoids, terpenes, quinones, alkaloids and tannins to name a few. Moreover, herbs with aphrodisiac properties also do a world of good for ED.&nbsp;</p>
        <p>Still one of the most poorly treated and largely prevalent diseases when addressed by western classical medicine, its comprehension must include sociological, psychological and cultural input percolating into all medicinal treatments, conventional and unconventional, allopathic and alternative such as drug switching, dosage reduction strategies and verbal coaching, akin to individual patient preferences, testimonies and experiences.&nbsp;</p>
        `
  }
]
