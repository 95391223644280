import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Images } from '../../../constants'

class AnalysisErectionScoreCard extends Component {
    static propTypes = {
      className: PropTypes.string,
      score: PropTypes.number,
      stepBreaker: PropTypes.number,
      duration: PropTypes.number,
      percentile: PropTypes.number,
      delay: PropTypes.number,
      offset: PropTypes.number
    };

    static defaultProps = {
      className: '',
      score: 0,
      stepBreaker: 10,
      duration: 1000,
      delay: 100,
      offset: 200
    };

    constructor (props) {
      super(props)

      this.state = {}
    }

    componentDidMount () {

    }

    componentDidUpdate (prevProps, prevState, snapshot) {

    }

    componentWillUnmount () {

    }

    render () {
      const { className, score, duration, delay, stepBreaker, offset, percentile } = this.props

      const steps = []
      for (let i = 1; i <= 10; i++) {
        steps.push(i * stepBreaker)
      }

      return (
            <div className={`analysis-erection-score-card ${className}`} data-aos-offset={offset}
                 data-aos="zoom-in" data-aos-duration={duration} data-aos-delay={delay} data-aos-once="true">
                <div className="erection-score-arrow-container">
                    {steps.map((step, index) => {
                      const active = ((index + 1) * stepBreaker) <= score
                      return (
                                <div className="erection-score-arrow" key={step}>
                                    {active
                                      ? <span className="d-inline-block">
                                        <img src={Images.report.erectionScoreActiveArrow}
                                             alt="erectionScoreActiveArrow"/>
                                    </span>
                                      : <img src={Images.report.erectionScoreArrow} alt="erectionScoreArrow"/>
                                    }
                                </div>
                      )
                    }
                    )}
                </div>
                <h5 className="erection-score-title">Erection score: &nbsp;{score}</h5>
              {
                !!percentile &&
                <p className="report-percentile">Your score is more than {percentile}% of all users who has taken this
                  quiz</p>
              }
            </div>
      )
    }
}

export default AnalysisErectionScoreCard
