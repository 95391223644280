// External Imports
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { WhatsappIcon, WhatsappShareButton, TelegramIcon, TelegramShareButton, EmailShareButton, EmailIcon } from 'react-share'
// My imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt'

const ReferProduct = ({ profile, product, match, history, location }) => {
  const url = `https://misters.in/en/checkout/?pid=${product.product_id}&referrer_id=${profile.detail.id}&cp=REFER10`
  const message = 'Buy Daily Josh - Powered by TesNOR ™ which has herbal ingredients to help you fight male ageing and increase drive. Buy now to get 10% off'
  const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email
  const referSectionRef = useRef(null)

  useEffect(() => {
    // console.warn(location)
    if (location.hash && location.hash === '#refer') {
      referSectionRef.current && referSectionRef.current.scrollIntoView()
    }
  }, [])

  const handleReferFriend = () => {
    history.push(`${match.params.language}/login/?next=${match.url}%23refer`)
  }

  // return (
  //   <div className="product-refer">
  //     <div className="container">
  //       <a id="refer" href="#" ref={referSectionRef}
  //          style={{ display: 'block', position: 'relative', top: -250, visibility: 'hidden' }}/>
  //       <h2>Refer a friend and get exclusive cashback</h2>
  //       {
  //         !isAuthenticated
  //           ? <Button variant="red" onClick={handleReferFriend}>Click here to refer</Button>
  //           : <div>
  //             <WhatsappShareButton title={message} url={url} separator=': ' className="mx-2">
  //               <WhatsappIcon size={44} round/>
  //             </WhatsappShareButton>
  //             <TelegramShareButton title={message} url={url} separator=': ' className="mx-2">
  //               <TelegramIcon size={44} round/>
  //             </TelegramShareButton>
  //             <EmailShareButton body={`${message}: `} url={url} className="mx-2">
  //               <EmailIcon size={44} round/>
  //             </EmailShareButton>
  //           </div>
  //       }
  //     </div>
  //   </div>
  // )
  return (
    <div className="text-left">
      {
        !isAuthenticated
          ? <button className="product-refer-button" onClick={handleReferFriend}><FontAwesomeIcon icon={faShareAlt} size={'sm'}/> share</button>
          : <div>
            <span style={{ fontSize: 12, marginBottom: 15 }}>Share to </span>
            <WhatsappShareButton title={message} url={url} separator=': ' className="mx-2">
              <WhatsappIcon size={32} round/>
            </WhatsappShareButton>
            <TelegramShareButton title={message} url={url} separator=': ' className="mx-2">
              <TelegramIcon size={32} round/>
            </TelegramShareButton>
            <EmailShareButton body={`${message}: `} url={url} className="mx-2">
              <EmailIcon size={32} round/>
            </EmailShareButton>
          </div>
      }
    </div>
  )
}

ReferProduct.propTypes = {
  profile: PropTypes.object,
  product: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
}

const mapStateToProps = (state) => ({
  profile: state.accounts.profile
})

export default connect(mapStateToProps)(withRouter(React.memo(ReferProduct)))
