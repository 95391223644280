import api from '../api'

export const CREATE_REVIEW = 'CREATE_REVIEW'
export const CREATE_REVIEW_RESULT = 'CREATE_REVIEW_RESULT'
export const CREATE_REVIEW_ERROR = 'CREATE_REVIEW_ERROR'
export const CREATE_REVIEW_RETRY = 'CREATE_REVIEW_RETRY'

export const FETCH_REVIEWS = 'FETCH_REVIEWS'
export const FETCH_REVIEWS_RESULT = 'FETCH_REVIEWS_RESULT'
export const FETCH_REVIEWS_ERROR = 'FETCH_REVIEWS_ERROR'
export const FETCH_REVIEWS_RETRY = 'FETCH_REVIEWS_RETRY'

export const createReview = (data) => async dispatch => {
  try {
    dispatch({ type: CREATE_REVIEW })
    const res = await api.ecommerce.reviews.create(data)
    const result = await res.json()

    if (res.status === 201) {
      dispatch({ type: CREATE_REVIEW_RESULT, data: result })
    } else {
      dispatch({ type: CREATE_REVIEW_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: CREATE_REVIEW_RETRY, message: e.message })
  }
}

export const fetchReviews = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_REVIEWS })
    const res = await api.ecommerce.reviews.list(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_REVIEWS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_REVIEWS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_REVIEWS_RETRY, message: e.message })
  }
}
