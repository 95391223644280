import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import * as PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Button from '../../buttons/Button'
import moment from 'moment'
import { withRouter } from 'react-router'

import { getPrice } from '../../../utils'
import { Images } from '../../../constants'
import CartQuantityInput from '../../inputs/CartQuantityInput'
import { createCartItem, deleteCartItem } from '../../../actions/ecommerce/cart'
import LText from '../../core/LText/LText'

class DuplicateOrderModal extends Component {
    static propTypes = {
      show: PropTypes.bool,
      handleClose: PropTypes.func,
      deleteCartItem: PropTypes.func,
      handleOrderCheckout: PropTypes.func,
      createCartItem: PropTypes.func,
      cart: PropTypes.object
    };

    static defaultProps = {
      show: false,
      cart: {}
    };

    constructor (props) {
      super(props)

      this.state = {}
    }

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { cart: { detail: { items } }, history, match, location } = this.props
      const querySearch = location.search

      if (!!items && !items.length) {
        history.push(`${match.params.language}/checkout${querySearch}`)
      }
    }

    render () {
      const { cart: { detail, detail: { previous_order, items } }, show, handleClose, handleOrderCheckout } = this.props

      const order_total = parseFloat(detail.subtotal)
      const total_discount = parseFloat(detail.total_mrp) - parseFloat(detail.subtotal)

      return (
            <Modal className="duplicate-order-modal" show={show} onHide={handleClose} centered>
                <Modal.Body className="p-0">
                    {!!previous_order && !!previous_order.items.length &&
                    <React.Fragment>
                        <p className="duplicate-order-modal-title">
                            <LText k='DUPLICATE_ORDER_TITLE'
                                   replaceKeys={[{ key: 'date', value: moment(previous_order.created_at).format('DD MMM, hh:mm a') }]}/>
                        </p>
                        <div className="duplicate-order-list">
                            {
                                previous_order.items.map((order, index) => (
                                    <div className='item-container' key={order.product_id}>
                                        <div className="item-left">
                                            <span className='item-index'>{index + 1}. </span>
                                            <span className='item-title'>{order.name}</span>
                                        </div>
                                        <span className='item-price'>
                                        x {order.quantity}
                                         </span>
                                    </div>
                                ))}
                        </div>
                        <hr className="duplicate-order-hr"/>
                    </React.Fragment>
                    }
                    <p className="duplicate-order-modal-title"><LText k='DUPLICATE_ORDER_MESSAGE'/></p>
                    <div className="duplicate-order-list">
                        {
                            !!items &&
                            items.map(
                              (item, index) => (
                                    <div className='item-container' key={item.id}>
                                        <div className="item-left">
                                            <span className='item-index'>{index + 1}. </span>
                                            <span className='item-title'>{item.item.title}</span>
                                        </div>
                                        <span className='item-price'>
                                            <CartQuantityInput quantity={item.quantity}
                                                               minValue={0}
                                                               onDecreaseClick={() => this.props.deleteCartItem(item.id)}
                                                               onIncreaseClick={() => this.props.createCartItem({
                                                                 id: item.item.id,
                                                                 quantity: item.quantity + 1
                                                               })}/>

                                             <span>₹{getPrice(item.line_item_total)}</span>
                                        </span>
                                    </div>
                              )
                            )
                        }
                    </div>
                    {
                        (!!total_discount) &&
                        <div className="order-summary-savings">
                            <img src={Images.logo.x1} alt="M"/>
                            Savings of ₹{getPrice(total_discount).toFixed(0)} on the bill
                        </div>
                    }
                    <hr className="order-summary-hr"/>
                    <div className="order-summary-total">
                        <div className="order-summary-total-title">Total</div>
                        <span className='order-summary-total-price'>
                        ₹{getPrice(order_total).toFixed(0)}
                    </span>
                    </div>
                </Modal.Body>
                <Modal.Footer className="duplicate-order-modal-footer">
                    <button className="duplicate-order-cancel-button" onClick={handleClose}>
                        <LText k='CANCEL'/>
                    </button>
                    <Button className="duplicate-order-confirm-button"
                            variant="red" onClick={handleOrderCheckout}><LText k='CONFIRM_ORDER'/></Button>
                </Modal.Footer>
            </Modal>
      )
    }
}

const mapStateToProps = state => ({
  cart: state.ecommerce.cart
})

const matchDispatchToProps = dispatch => bindActionCreators({
  createCartItem,
  deleteCartItem
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(DuplicateOrderModal))
