import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../components/core/Page'
import { Images } from '../../constants'
import { createCartItem } from '../../actions/ecommerce/cart'
import { fetchUserReport } from '../../actions/report/report'

import ProductCard from '../../components/cards/ProductCard'
import ReviewCard from '../../components/cards/ReviewCard'
import NewRedFlagButton from '../../components/buttons/NewRedFlagButton'
import LoginBlurModal from '../../components/modals/LoginBlurModal'
import { getHttpsImageUrl, getLanguageTranslation, getSearchParams } from '../../utils'
import Ripple from '../../components/loadings/Ripple'
import api from '../../actions/api'
import AnalysisTotalScoreCard from '../../components/cards/AnalysisTotalScoreCard'
import AnalysisDesireScoreCard from '../../components/cards/AnalysisDesireScoreCard'
import AnalysisErectionScoreCard from '../../components/cards/AnalysisErectionScoreCard'
import AnalysisBMIScoreCard from '../../components/cards/AnalysisBMIScoreCard'
import AnalysisLifeStyleScoreCard from '../../components/cards/AnalysisLifeStyleScoreCard'
import AnalysisEjaculationScoreCard from '../../components/cards/AnalysisEjaculationScoreCard'
import WhyMisters from '../../components/others/WhyMisters'
import AnalysisCard from '../../components/cards/AnalysisCard'
import SummaryFrame1Card from '../../components/cards/SummaryFrame1Card'
import SummaryFrame2Card from '../../components/cards/SummaryFrame2Card'
import SummaryDoshaCard from '../../components/cards/SummaryDoshaCard'

class Report extends PureComponent {
    static propTypes = {
      report: PropTypes.object,
      profile: PropTypes.object,
      history: PropTypes.object,
      match: PropTypes.object,
      fetchUserReport: PropTypes.func,
      createCartItem: PropTypes.func
    };

    state = {};

    componentDidMount () {
      const { history, profile } = this.props
      const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email
      const params = getSearchParams(history.location.search)

      if (isAuthenticated) {
        this.props.fetchUserReport({ report_id: params.id })
      }
      if (params.step_name) {
        const dataLayerData = {
          event: sessionStorage.getItem('ga_quiz_event'),
          step_name: params.step_name,
          value: ''
        }
        console.warn('data layer - ', dataLayerData)
        // eslint-disable-next-line no-undef
        try { dataLayer.push(dataLayerData) } catch (e) {}
      }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { profile, history } = this.props
      const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email
      const prevIsAuthenticated = !!prevProps.profile.detail.phone_number || !!prevProps.profile.detail.email
      const campaignId = sessionStorage.getItem('campaignId')
      if (!prevIsAuthenticated && isAuthenticated && campaignId) {
        api.campaigns.restaurant.post({ cid: campaignId })
      }
      if (!prevIsAuthenticated && isAuthenticated) {
        const dataLayerData = {
          event: sessionStorage.getItem('ga_quiz_event'),
          step_name: 'phone_number_captured',
          value: profile.detail.phone_number
        }
        console.warn('data layer - ', dataLayerData)
        // eslint-disable-next-line no-undef
        try { dataLayer.push(dataLayerData) } catch (e) {}
        const params = getSearchParams(history.location.search)
        this.props.fetchUserReport({ report_id: params.id })
      }
    }

    handleAddItemToCart = async (product) => {
      const { history, match, createCartItem } = this.props
      await createCartItem({ id: product.id })
      history.push(`${match.params.language}/checkout/`)
    };

    getProduct = (products) => {
      if (products && products.length) {
        return products[0]
      }
      return null
    };

    _renderReport = () => {
      const { report: { detail, isFetching }, match: { params: { language } }, match } = this.props
      const { report_sheet: reportSheet = {} } = detail
      // eslint-disable-next-line camelcase
      const { analysiscards = [], analysisscores = [], redflags = [], products = [] } = reportSheet
      const product = this.getProduct(products)

      if (isFetching) {
        return (
                <Page showNavbar={false} showFooter={false}
                      meta={{
                        title: 'Take the Misters Ayurveda and sex quiz for personalised treatment',
                        path: `${match.params.language}/reports/`,
                        description: 'Products on our website offer action-oriented care and the blog offers reliable expert advice on sexual health plus wellness.The Misters sex and Ayurveda quiz score entrusts us with individual information regarding your erection patterns, allergies, ejaculation timings, hair health, eating habits and behaviours. These records help us provide personalised care for each member with specific information about your lifestyle choices. Based on this information targeted recourse is suggested. We at Misters know that acknowledging the patterns is essential but fathoming them can unearth possibilities of vast treatment options that help us help you better. Go ahead, take the quiz!'
                      }}>
                    <div className="text-center py-5">
                        <Ripple show={true}/>
                    </div>
                </Page>
        )
      }

      return (
            <Page showNavbar={false} showFooter={false}
                  meta={{
                    title: 'Misters Ayurveda and sex quiz',
                    path: `${match.params.language}/reports/`,
                    description: 'Products on our website offer action-oriented care and the blog offers reliable expert advice on sexual health plus wellness.The Misters sex and Ayurveda quiz score entrusts us with individual information regarding your erection patterns, allergies, ejaculation timings, hair health, eating habits and behaviours. These records help us provide personalised care for each member with specific information about your lifestyle choices. Based on this information targeted recourse is suggested. We at Misters know that acknowledging the patterns is essential but fathoming them can unearth possibilities of vast treatment options that help us help you better. Go ahead, take the quiz!'
                  }}>
                <div className="report-nav">
                    <img className='report-nav-logo' src={Images.logo.white} alt="Man!"/>
                </div>
                <div className="report-container">
                    <div className="container">
                        <div className="report-heading-container">
                            <h3 className="new-report-heading-title">YOUR REPORT CARD IS READY</h3>
                            <h4 className="new-report-heading-summary">Profile summary</h4>
                        </div>
                        <div className="row mb-3 mb-md-5 new-red-flag-row">
                            {
                                redflags.map(
                                  (redflag, index) => (
                                        <div key={redflag.id} className="col-4 col-sm-4 col-md-3 py-2 new-red-flag-col">
                                            <NewRedFlagButton data-aos="zoom-in" data-aos-duration={1000}
                                                              data-aos-delay={(index + 1) * 100} data-aos-once="true"
                                                              name={getLanguageTranslation(redflag, 'name', language)}
                                                              value={redflag.value}/>
                                        </div>)
                                )
                            }
                        </div>

                        {/* <div className="row mb-3 mb-md-4">
                            <div className="col-10 offset-1">
                                <h5 className="font-weight-light">Based on your responses, we have identified focus
                                    areas for your sexual health. Let's start with helping you get <b>better
                                        erections</b>.</h5>
                            </div>
                        </div> */}

                        <div className="row mb-3">
                            {
                                analysisscores.filter(o => o.type === 'total_score').map(
                                  analysisscore => {
                                    const name = getLanguageTranslation(analysisscore, 'name', language) || 'Total Score'
                                    const totalScore = parseInt(analysisscore.score * 100 / analysisscore.total_score)
                                    return (
                                            <div key={analysisscore.id} className="col-md-6 col-12 mb-4 d-flex">
                                                <AnalysisTotalScoreCard name={name} score={totalScore} percentile={analysisscore.percentile} offset={50}/>
                                            </div>
                                    )
                                  }
                                )
                            }

                            {
                                analysisscores.filter(o => o.type === 'desire_score').map(
                                  analysisscore => {
                                    const desireScore = parseInt(analysisscore.score * 100 / analysisscore.total_score)
                                    return (
                                            <div key={analysisscore.id} className="col-md-6 col-12 mb-4 d-flex">
                                                <AnalysisDesireScoreCard score={desireScore} percentile={analysisscore.percentile} />
                                            </div>
                                    )
                                  }
                                )
                            }

                            {
                                analysisscores.filter(o => o.type === 'bmi_score').map(
                                  analysisscore => {
                                    return (
                                            <div key={analysisscore.id} className="col-md-6 col-12 mb-4 d-flex">
                                                <AnalysisBMIScoreCard score={analysisscore.score} />
                                            </div>
                                    )
                                  }
                                )
                            }

                            {
                                analysisscores.filter(o => o.type === 'bmi2_score').map(
                                  analysisscore => {
                                    return (
                                            <div key={analysisscore.id} className="col-md-6 col-12 mb-4 d-flex">
                                                <AnalysisBMIScoreCard score={analysisscore.score} ayurvedicBMI={true}/>
                                            </div>
                                    )
                                  }
                                )
                            }

                            {
                                analysisscores.filter(o => o.type === 'ejaculation_score').map(
                                  analysisscore => {
                                    const ejaculationScore = parseInt(analysisscore.score * 100 / analysisscore.total_score)
                                    return (
                                            <div key={analysisscore.id} className="col-md-6 col-12 mb-4 d-flex">
                                                <AnalysisEjaculationScoreCard score={ejaculationScore} percentile={analysisscore.percentile} />
                                            </div>
                                    )
                                  }
                                )
                            }

                            {
                                analysisscores.filter(o => o.type === 'lifestyle_score').map(
                                  analysisscore => {
                                    const lifestyleScore = parseInt(analysisscore.score * 100 / analysisscore.total_score)
                                    return (
                                            <div key={analysisscore.id} className="col-md-6 col-12 mb-4 d-flex">
                                                <AnalysisLifeStyleScoreCard score={lifestyleScore} percentile={analysisscore.percentile} />
                                            </div>
                                    )
                                  }
                                )
                            }

                            {
                                analysisscores.filter(o => o.type === 'erection_score').map(
                                  analysisscore => {
                                    const erectionScore = parseInt(analysisscore.score * 100 / analysisscore.total_score)
                                    return (
                                            <div key={analysisscore.id} className="col-md-6 col-12 mb-4 d-flex">
                                                <AnalysisErectionScoreCard score={erectionScore} percentile={analysisscore.percentile} />
                                            </div>
                                    )
                                  }
                                )
                            }
                        </div>

                        <div className="row mb-3">
                            {
                                !!analysiscards.filter(card => card.type === 'small_group').length &&
                                <div className={`col-12 ${!product ? 'offset-md-2 col-md-8' : 'col-md-6'}`}>
                                    {
                                        analysiscards.filter(card => card.type === 'small_group').map(
                                          analysiscard => {
                                            return (
                                                    <AnalysisCard key={analysiscard.id}
                                                                  text={analysiscard.analysiscardtexts.map(o => getLanguageTranslation(o, 'text', language)).join('. ')}
                                                                  imgSrc={analysiscard.icon ? getHttpsImageUrl(analysiscard.icon) : ''}/>
                                            )
                                          }
                                        )
                                    }
                                </div>
                            }
                            {
                                analysiscards.filter(card => card.type !== 'summary')
                                  .filter(card => card.type !== 'small_group')
                                  .filter(card => card.type !== 'summary_frame_1')
                                  .filter(card => card.type !== 'summary_frame_2')
                                  .filter(card => card.type !== 'summary_dosha')
                                  .map(
                                    analysiscard => {
                                      if (!analysiscard.analysiscardtexts.length) return null
                                      return (
                                                <div className="col-12 col-md-6 p-2" key={analysiscard.id}>
                                                    <AnalysisCard
                                                        text={analysiscard.analysiscardtexts.map(o => getLanguageTranslation(o, 'text', language)).join('. ')}
                                                        title={getLanguageTranslation(analysiscard, 'name', language)}
                                                        type={analysiscard.type}
                                                        imgSrc={analysiscard.icon ? getHttpsImageUrl(analysiscard.icon) : ''}/>
                                                </div>
                                      )
                                    }
                                  )
                            }
                            {
                                analysiscards.filter(card => card.type === 'summary').map(
                                  analysiscard => {
                                    return (
                                            <div className="col-md-6 col-12" key={analysiscard.id}>
                                                <AnalysisCard
                                                    text={analysiscard.analysiscardtexts.map(o => getLanguageTranslation(o, 'text', language)).join('. ')}
                                                    title={getLanguageTranslation(analysiscard, 'name', language)}
                                                    type={'large'}
                                                    imgSrc={analysiscard.icon ? getHttpsImageUrl(analysiscard.icon) : ''}/>
                                            </div>
                                    )
                                  }
                                )
                            }

                            {
                                analysiscards.filter(card => card.type === 'summary_dosha').map(
                                  analysiscard => {
                                    return (
                                            <div className="col-12 col-md-8 offset-md-2 mt-5" key={analysiscard.id}>
                                                <SummaryDoshaCard
                                                    text={analysiscard.analysiscardtexts.map(o => getLanguageTranslation(o, 'text', language)).join('. ')}
                                                    title={getLanguageTranslation(analysiscard, 'name', language)}/>
                                            </div>
                                    )
                                  }
                                )
                            }

                            {
                                analysiscards.filter(card => card.type === 'summary_frame_1').map(
                                  analysiscard => {
                                    return (
                                            <div className="col-12 col-md-8 offset-md-2 mt-5" key={analysiscard.id}>
                                                <SummaryFrame1Card
                                                    text={analysiscard.analysiscardtexts.map(o => getLanguageTranslation(o, 'text', language)).join('. ')}
                                                    title={getLanguageTranslation(analysiscard, 'name', language)}/>
                                            </div>
                                    )
                                  }
                                )
                            }

                            {
                                analysiscards.filter(card => card.type === 'summary_frame_2').map(
                                  analysiscard => {
                                    return (
                                            <div className="col-12 col-md-8 offset-md-2 my-5" key={analysiscard.id}>
                                                <SummaryFrame2Card
                                                    text={analysiscard.analysiscardtexts.map(o => getLanguageTranslation(o, 'text', language)).join('. ')}
                                                    title={getLanguageTranslation(analysiscard, 'name', language)}/>
                                            </div>
                                    )
                                  }
                                )
                            }
                        </div>

                        {!!product &&
                        <ProductCard product={product}
                                     handlePurchaseClick={product => this.handleAddItemToCart(product)}/>
                        }
                    </div>
                </div>
                {!!product && <WhyMisters />}

                {!!product && !!product.reviews.length &&
                <div className="report-container">
                    <div className="container">
                        <h2 className="text-center my-5 font-weight-light">Our happy customers</h2>
                        {
                            product.reviews.map(
                              (review, index) => <ReviewCard key={review.id} review={review}/>
                            )
                        }
                    </div>
                </div>}

            </Page>
      )
    };

    render () {
      const { profile, match } = this.props
      const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email

      if (!isAuthenticated) {
        return (
                <Page showNavbar={false} showFooter={false}
                      meta={{
                        title: 'Take the Misters Ayurveda and sex quiz for personalised treatment',
                        path: `${match.params.language}/reports/`,
                        description: 'Products on our website offer action-oriented care and the blog offers reliable expert advice on sexual health plus wellness.The Misters sex and Ayurveda quiz score entrusts us with individual information regarding your erection patterns, allergies, ejaculation timings, hair health, eating habits and behaviours. These records help us provide personalised care for each member with specific information about your lifestyle choices. Based on this information targeted recourse is suggested. We at Misters know that acknowledging the patterns is essential but fathoming them can unearth possibilities of vast treatment options that help us help you better. Go ahead, take the quiz!'
                      }}>
                    <LoginBlurModal show={true}/>
                </Page>
        )
      }
      return this._renderReport()
    }
}

const mapStateToProps = (state) => ({
  report: state.report.report,
  profile: state.accounts.profile
})

const matchDispatchToProps = dispatch => bindActionCreators({
  createCartItem,
  fetchUserReport
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Report)
