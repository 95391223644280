import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import { NavLink } from 'react-router-dom'

class Amla extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props

    return (
            <Page meta={{
              title: 'Amla, benefits, uses, dosage | Misters',
              path: `${match.params.language}/ingredient/amla/`,
              description: 'Learn about Amla, a high quality Ayurvedic herb, on the Misters Ayurveda page.  Your complete Misters Ayurvedic ingredient guide has collated expert information on Amla’s usage, dosage, benefits, description, alternative names and constituents. Also called Indian Gooseberry, Amla has vast, scientifically proven health benefits. Enhancing libido, improving skin health and boosting immunity are some results of usage. It manages hypertension, diabetes, PCOS and liver function. It is lucent green, rich in vitamins and found in parts of Uttar Pradesh, Tamil Nadu, Rajasthan, Pakistan and many more areas. Know your Ayurvedic ingredients to understand Misters products better.'
            }}>
                <div className="ingredient-header-img" style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>Amla- your mantra for a long-lasting sex life</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What is Amla?</h2>
                            <p>
                                Amla or <b>Indian gooseberry</b> derives its name from the Sanskrit word, ‘Amalaki’
                                which means
                                the nectar of life. In Ayurveda, it also connotes to a whole lot of other meanings like
                                a - mother, nurse, immortality and the taste of something sour. According to Ayurveda,
                                the Amla <b>fruit</b> has qualities or gunas like <b>light or ‘laghu’</b> and <b>dry or
                                ‘ruksha’</b> etc. The <b>post-digestive</b> effect is <b>‘vipaka’</b>
                                , and its <b>energy or ‘virya’</b> has <b>calming effects</b> that are full of ‘shita.’
                                <br/>
                                <br/>
                                A fruit of a deciduous tree of the Phyllanthaceae family, the scientific name of Amla
                                is <b>Phyllanthus emblica</b>.
                            </p>
                            <p className="text-center font-weight-bold">
                                <span>To know more about Amla, </span>
                                <a className="ingredient-blog-links" target="_blank"
                                         href={`https://misters.in${match.params.language}/blog/What-is-Amla`}>click here</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row pt-3">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What are the other names of Amla?</h2>
                            <p>
                                Amla is known by names like <b>emblic, emblic myrobalan, myrobalan and Malacca tree</b>.
                                Other Indian names of Amla are <b>-Dhatri, Amlaka, Adiphala (Sanskrit), Nelli
                                (Malayalam), Amalakkamu, Usirikai (Telugu), Nelli, Malanelli (Tamil), Amla, Amlaki
                                (Bengali), Amla, Amlika, Aonla (Hindi), Amalak, Bettadanelli (Kannada) and Amali or
                                Ambala (Gujarathi)</b>.
                            </p>
                            <br/>
                            <h2>What is the description of an Amla plant?</h2>
                            <p>
                                <b>Lucent green</b> in appearance, the fruit is eight times richer in vitamin C than
                                fruits like orange and is known to have unparalleled medicinal properties. The tree
                                grows to a small or medium <b>height</b> of about <b>1–8 metres</b>. It grows in
                                branchlets which are not glabrous or finely pubescent and are about
                                <b>10–20 cm in length</b>. They have simple, <b>light green leaves</b>,
                                which are closely placed all along the branchlets. <b>Flowers</b> of Indian
                                gooseberry are <b>greenish-yellow</b> in colour, and the fruit is almost round, very
                                smooth and solid in appearance. It also has six vertical stripes.
                                <br/>
                                <br/>
                                Amla is rich in pectin and vitamin and boasts of tremendous antioxidant qualities which
                                are twice the potential of acai berry and nearly 17 times that of pomegranate. This
                                everyday fruit is, in essence, a super-food and is used in the treatment of a plethora
                                of conditions like a common cold, cancer or infertility. It can be eaten raw or made
                                into, churna, candy, pickles or supplements. According to Ayurvedic practitioners, Amla
                                helps in stabilising the three doshas (Kapha, vata and pitta) and expunges the
                                deep-rooted causes of several chronic diseases.
                                <br/>
                                <br/>
                                Amla is also an important element in Ayurveda as is rich in vitamin C, calcium, iron,
                                phosphorous, carotene, vitamin B, protein and fibre. The berry has medicinal value like
                                that of antiscorbutic, diuretic, laxative, antibiotic and anti-dysenteric properties.
                                Ayurveda experts highly advocate taking one Amla every day for increasing immunity,
                                stimulating metabolism and longevity. The five distinct tastes of Amla (sweet, sour,
                                bitter, astringent and pungent) have earned it the title - ‘divyaushadhi’ or a divine
                                medicine which facilitates the functions of body and mind.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker image={Images.ingredient.amlaDescription} to={shopUrl} buttonText={'SHOP'}/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <iframe className="youtubeIframe"
                                        src="https://www.youtube.com/embed/P_IMc2K4QIg?loop=1&playlist=P_IMc2K4QIg&rel=0"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen frameBorder="0"/>
                            </div>
                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

export default Amla
