import api from '../api'

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION_RESULT = 'CREATE_SUBSCRIPTION_RESULT'
export const CREATE_SUBSCRIPTION_ERROR = 'CREATE_SUBSCRIPTION_ERROR'
export const CREATE_SUBSCRIPTION_RETRY = 'CREATE_SUBSCRIPTION_RETRY'

export const createSubscription = (data) => async dispatch => {
  try {
    dispatch({ type: CREATE_SUBSCRIPTION })
    const res = await api.subscriptions.subscriptions.create(data)
    const result = await res.json()
    if (res.status === 201) {
      dispatch({ type: CREATE_SUBSCRIPTION_RESULT, data: result })
    } else {
      dispatch({ type: CREATE_SUBSCRIPTION_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: CREATE_SUBSCRIPTION_RETRY, message: e.message })
  }
}
