import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import PropTypes from 'prop-types'
import getArticleSchema from '../../../services/SchemaService/getArticleSchema'
import getFaqSchema from '../../../services/SchemaService/getFaqSchema'

const faqs = [
  {
    question: 'What is Gokshura made of?',
    answer: `<p>
            The plant <b>Tribulus terrestris</b> contains great quantities of furostanol glycosides, of which
            protodioscin is the primary saponin while spirostanol glycosides are present in much lesser quantities.
            <br/>
            <br/>
            Phytochemical study of Tribulus terrestris shows that the plant contains flavonoids, glycosides, alkaloids,
            and tannins. It also shows that the composition of saponin and the content of saponin in the plant vary from
            one region to another.
            <br/>
            <br/>
            Scientists have further studied that furostanol and spirostanol saponins of tigogenin, neo-tigogenin,
            gitogenin, neogitogenin, hecogenin, neo- hecogenin, diosgenin, chlorogenin, ruscogenin, and sarsasapogenin
            types are often present in the plant. Moreover, four sulfated saponins of the tigogenin and diosgenin type
            were also identified during experiments and it was seen that the amount of chief ﬂavonoids is nearly 1.5
            times that of main saponins.
        </p>`
  },
  {
    question: 'What are the main constituents of Gokshura as a product?',
    answer: `<p>
            The main constituents of Gokshura include, <b>Terrestrosins A, B, C, D and E, desgalactotigonin, F-gitonin,
            desglucolanatigonin, digitonin, hydrolysed products like diosgenin, hecogenin and neo tigogenin</b>. It also
            consists of <b>tribulus amides A and B, Ntrans- feruloyl tyramine, terrestrial ide, N- trans- coumaroyl
            tyramine, ß-sitosterol, steroidal and saponins</b>.
        </p>`
  },
  {
    question: 'What are the ayurvedic characteristics of Gokshura?',
    answer: `<p>
            The taste of Gokshura on the tongue which is characterized by the ayurvedic concept of <b>Rasa, is
            ‘Madhura’</b>, meaning sweet. Pharmacological Action, also referred to as <b>Guna, is ‘Guru’</b> which means
            heavy and <b>‘Snigdha’</b> meaning unctuous. The <b>Virya</b> or action of Gokshura
            is <b>‘Shita’</b> meaning a cool feeling. Lastly, the transformed state after digestion or
            the <b>Vipaka</b> of Gokshura is also <b>‘Madhura.’</b>
        </p>`
  },
  {
    question: 'What are the benefits of Gokshura?',
    answer: `<p>
            It has been clinically established that <b>the plant can enhance energy and vigour</b> and is also <b>effective
            in building muscle strength</b>.
            <br/>
            <br/>
            Moreover, the powder is also known for augmenting sexual drive and <b>improving fertility</b> in both men
            and women.
            <br/>
            <br/>
            It <b>boosts ovulation</b> in women and <b>sperm production</b> in men. The roots are believed to be
            potential stomachic appetizers and fruits on the other hand cure coughs, scabies and anaemia.
            <br/>
            <br/>
            Concentrated liquor produced from heating or boiling Gokshura leaves, is used for gargling, as a <b>cure for
            mouth problems, gum pain</b> and the <b>reduction of swellings</b>. In addition, these leaves also <b>promote
            the menstrual flow</b> and are known to address gonorrhea.
            <br/>
            <br/>
            Its extracts have long been used by people for numerous health benefits including <b>keeping diabetes in
            check</b> and <b>curing obstinate urinary tract infections</b>.
            <br/>
            <br/>
            The cooling and nourishing properties of the herb are known to soothe the membranes of the urinary tract
            helping to <b>normalise the flow of urine</b>.
            <br/>
            <br/>
            Gokshura is a <b>purely vegetarian supplement</b> in its essence and is often mixed with other natural herbs
            like Ashwagandha for best results in treating disorders. Packed with an array of <b>anti-inflammatory and
            diuretic qualities</b>, Gokshura formulations are exceedingly beneficial in treating a host of diseases.
            <br/>
            <br/>
            Interestingly, Gokshura has been historically used in various manners, to cure different ailments in
            different countries. For example, in China it was traditionally used to <b>cure diseases of the liver,
            kidney, cardiovascular and immune systems</b>.
            <br/>
            <br/>
            In Turkey it was popularly used to combat conditions such as hypertension and hypercholesterolemia.
            <br/>
            <br/>
            Iraq on the other hand had the most extensive use of this herbal extract because Iranians used it
            as <b>tonic</b> and an <b>aphrodisiac</b>.
            <br/>
            The Unani school of medicine considers Gokshura highly effective in <b>curing sexual
            disorders</b> considering it a <b>general stimulant</b>.
        </p>`
  },
  {
    question: 'Which disorder treatments is Gokshura now widely used in?',
    answer: `
    <p>
            <strong>Urinary Tract and Kidney Disorders:</strong> Gokshura is extensively prescribed as a cure for
            urinary tract infections which include pain and a burning sensation while urinating. Gokshura is known to
            balance body fluids effectively. It helps to address conditions like kidney stones as well.
            <br/>
            <br/>
            <strong>Sexual Disorders:</strong> Intake of Gokshura boosts libido in men and women, addresses impotency,
            fertility and vitality, and improves sperm quality and quantity in men. This is why Gokshura is widely known
            as an aphrodisiac.
            <br/>
            <br/>
            <strong>Prostate Gland Disorders:</strong> Gokshura is highly effective in curing prostate gland disorders.
            <br/>
            <br/>
            <b>Heart Problems:</b> Gokshura supplements take care of the heart health and are particularly useful for
            treating cardiac disorders. Consumption of Gokshura acts as a diuretic and promotes heart activity. The herb
            also helps in checking blood vessel damage and thwarts the rise of blood cholesterol.
            <br/>
            <br/>
            <b>Bodybuilding:</b> It acts as a strong supplement in bodybuilding activities and builds muscle strength.
            It is also an anti-obesity herb.
            <br/>
            <br/>
            <strong>Polycystic ovary syndrome or PCOS:</strong> PCOS often leads to infertility in women and Gokshura is
            widely suggested by Ayurveda practitioners for the same as it purgates excess water from the body which in
            turn reduces the size of the cyst.
            <br/>
            <br/>
            <strong>Digestion:</strong> Gokshura powder eases abdominal colic pain and helps in better digestion.
            <br/>
            <br/>
            <strong>Hormonal balance:</strong> Gokshura increases levels of the luteinizing hormone (LH) and
            follicle-stimulating hormone (FSH) which are gonadotropin hormones that stimulate gonads in men and women.
            This in turn prompts testosterone production in men and estrogen secretion in women. Moreover, Gokshura is
            an adaptogenic that helps in bringing homeostasis, or equilibrium to the human body.
        </p>
    `
  },
  {
    question: 'Which additional conditions is Gokshura popularly employed to treat?',
    answer: `
    <p>
            Above and above its benefits, Gokshura is employed to treat additional conditions such as <b>low water
            retention, eye related issues or problems, menstruation, stress, headaches, obesity, hairfall conditions,
            rheumatic pain, a weak and crumbling nervous system, bed wetting and piles</b>.
        </p>
`
  },
  {
    question: 'How must one consume Gokshura?',
    answer: `
        <p>
            The demand of this highly effective medicinal herb is increasing day by day and more and more people are
            opting for it as an alternative medical therapy. However, <b>before starting a course of Gokshura
            medication, it is absolutely necessary to take advice from an Ayurvedic doctor</b>. Gokshura is often
            recommended along with Ashwagandha which is a testosterone boosting herb, among others such as Horny Goat
            Weed and Nettle.
            <br/>
            <br/>
            To alleviate the burning sensation during the process of urination, Gokshura powder is mixed and boiled with
            crude potassium carbonate and a dosage of 10-20 ml is consumed in the morning. For melting kidney stones,
            Gokshura powder is mixed with one teaspoon of honey and a cup of sheep’s milk and taken once a day for a
            week. Gokshura powder can also be consumed twice a day with plain water. The intake of lithium or
            antidiabetic medicines is not advised while a dosage of Gokshura is underway.
        </p>
    `
  },
  {
    question: 'How long does it take for Gokshura to take effect?',
    answer: `
    <p>
            Time taken by herbal medicines is not fixed as it depends on a number of factors related to the user
            including body type, weight, food habits and lifestyle choices. It greatly depends on the body’s ability to
            consume the drug. The impact also depends on the dosage and other products and ingredients consumed along
            with it. The best way to take the medicine is for a period prescribed by an Ayurvedic physician or expert.
            However, available data and reports suggest that it normally takes <b>one to two weeks time</b> for Gokshura
            to yield results, though doctors advise to continue the dose for at least sixty days for those seeing early
            results and ninety days for those taking more time to experience the same.
            <br/>
            <br/>
            A dose of 2-3 grams of Gokshura everyday for two months is necessary to get the best results. Also one
            cannot take the entire amount at one go and must take it multiple times a day. The best possible way to
            consume Gokshura is to have a dose of six tablets a day in order to consume the required amount of 2-3
            grams.
            <br/>
            <br/>
            Another interesting fact about this herb is that it gives best results when taken in cycles. Doctors often
            recommend bodybuilders to use Gokshura at the time of their active and heavy workout period when there is a
            need to increase muscle mass. Experts also advise avoiding the consumption of the herbal supplement for the
            rest of the body builder season.
        </p>
    `
  }
]

class Gokshura extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props

    const path = `${match.params.language}/ingredient/gokshura/`
    const scripts = [
      getArticleSchema({
        title: 'Testosterone, Gokshura. Uses, dosage, benefits | Misters',
        description: 'Learn about Gokshura, a high quality Ayurvedic herb, on the Misters Ayurveda page. Your complete Misters Ayurvedic ingredient guide has collated expert information on Gokshura’s usage, dosage, benefits, description, alternative names and constituents. Gokshura has vast, scientifically proven health benefits. A bodybuilding physique, boost in ovulation, sperm production and fertility rates among men are some results of its usage. It normalises flow of urine, reduces swellings, keeps diabetes in check and has anti-inflammatory properties. Rich in vitamins, it is used widely in treatment of sexual disorders. Know your Ayurvedic ingredients to understand Misters products better.',
        images: ['https://misters.in/web-assets/images/ingredients/shilajit-description.jpg'],
        keywords: 'Gokshura, gokshura powder, gokshura men, gokshura benefits, sex desire, sex power',
        url: path
      }),

      getFaqSchema({
        data: faqs,
        url: path
      })
    ]

    return (
      <Page meta={{
        title: 'Testosterone, Gokshura. Uses, dosage, benefits | Misters',
        path: path,
        description: 'Learn about Gokshura, a high quality Ayurvedic herb, on the Misters Ayurveda page. Your complete Misters Ayurvedic ingredient guide has collated expert information on Gokshura’s usage, dosage, benefits, description, alternative names and constituents. Gokshura has vast, scientifically proven health benefits. A bodybuilding physique, boost in ovulation, sperm production and fertility rates among men are some results of its usage. It normalises flow of urine, reduces swellings, keeps diabetes in check and has anti-inflammatory properties. Rich in vitamins, it is used widely in treatment of sexual disorders. Know your Ayurvedic ingredients to understand Misters products better.'
      }} script={scripts}>
        <div className="ingredient-header-img" style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
          <div className="ingredient-header-bg-drop"/>
          <div className="ingredient-header-content">
            <h1>Gokshura- The testosterone booster</h1>
          </div>
        </div>
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>What is Gokshura?</h2>
              <p>
                Gokshura is a very <b>popular health supplement</b> which is actually an extract from
                the roots and fruits of an annual flowering plant found widespread, across India. It is
                found in Madhya Pradesh, Deccan Peninsula, Konkan, and in various other parts of the
                world like Australia, Africa and Southern Asia. Gokshura grows in dry climate areas and
                even in regions with poor soil conditions and desert type climates where most plants
                cannot survive.
                <br/>
                <br/>
                Gokṣura is known by different other names in India as well as in other parts of the
                world. Its common Indian names include, Śvadaṃṣṭrā (Sanskrit) and Gokharū (Hindi). In
                English, Gokṣura is referred to as ‘land-caltrops’, ‘puncture-vine’ or ‘devil’s weed.’
              </p>
              <p className="text-center font-weight-bold">
                <span>To know more about Gokshura, </span>
                <a className="ingredient-blog-links" target="_blank"
                   href={`https://misters.in${match.params.language}/blog/What-is-Gokshura`}>click here</a>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center py-5">
                <iframe className="youtubeIframe"
                        src="https://www.youtube.com/embed/YWd9QPxTxVM?loop=1&playlist=YWd9QPxTxVM&rel=0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen frameBorder="0"/>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row pt-5">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>What is the history of Gokshura?</h2>
              <p>
                Gokshura belongs to the domain of <b>ancient Indian Ayurveda</b>, possibly the world's
                oldest system of natural medicine that originated more than an astonishing 5000 years
                ago. It serves as an excellent alternative for forms of treatment which are known for
                curing diseases without risky side-effects.
                <br/>
                <br/>
                The recurrent mention of Gokshura in <b>ayurvedic literary classics</b> like <b>Chikitsagranthas,
                Charaka Ashtanga Hridaya, Samhita, Harita Samhita, Sushruta Samhita, Sharangadhara
                Samhita, and Nighantus</b> have been found. Special attention must be drawn to the
                reference of Gokshura in the old and historic medical scriptures of Chakradatta. It is
                also held in high regard across medical fraternities of the world.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-md-10 offset-md-1 col-12">
              <ImageBreaker image={Images.ingredient.gokshuraDescription} to={shopUrl}
                            buttonText={'SHOP'}/>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-md-10 offset-md-1 col-12">
              {accordionList1}
            </div>
          </div>
        </div>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center py-5">
                <iframe className="youtubeIframe"
                        src="https://www.youtube.com/embed/t8NeABDvH9s?loop=1&playlist=t8NeABDvH9s&rel=0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen frameBorder="0"/>
              </div>
            </div>
          </div>
        </div>

        <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              {accordionList2}
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

Gokshura.propTypes = {
  match: PropTypes.object
}

export default Gokshura
