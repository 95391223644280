import Home from '../screens/Home'
import { getLanguagePath } from '../utils'
import Login from '../screens/Login'
import Shop from '../screens/Shop'
import Solutions from '../screens/Solutions'
import PartnerProgram from '../screens/Pages/PartnerProgram'
import ExcellenceProgram from '../screens/Pages/ExcellenceProgram'
import Feedback from '../screens/Feedback'
import About from '../screens/About'
import IntimacyTherapy from '../screens/IntimacyTherapy'
import Media from '../screens/Media'
import Ayurveda from '../screens/Ayurveda'
import ErectileDysfunction from '../screens/Category/ErectileDysfunction'
import PrematureEjaculation from '../screens/Category/PrematureEjaculation'
import Hairfall from '../screens/Category/Hairfall'
import Amla from '../screens/Ingredients/Amla'
import Ashwagandha from '../screens/Ingredients/Ashwagandha'
import KaunchBeej from '../screens/Ingredients/KaunchBeej'
import Shilajit from '../screens/Ingredients/Shilajit'
import Gokshura from '../screens/Ingredients/Gokshura'
import Kesar from '../screens/Ingredients/Kesar'
import Shatavari from '../screens/Ingredients/Shatavari'
import SafedMusli from '../screens/Ingredients/SafedMusli'
import Jaiphal from '../screens/Ingredients/Jaiphal'
import Bhringraj from '../screens/Ingredients/Bhringraj'
import HairMask from '../screens/Ingredients/HairMask'
import BoldDetail from '../screens/Ingredients/BoldDetail'
import AIPSAndSRQ from '../screens/Ingredients/AIPSAndSRQ'
import ThinkingSex from '../screens/ThinkingSex'
import ProductPage from '../screens/Pages/ProductPage'
import ClinicLandingPage from '../screens/Pages/ClinicLandingPage'
import HISScore from '../screens/Pages/HISScore'
import ConferenceRegistration from '../screens/Pages/ConferenceRegistration'

export default [
  {
    name: 'Home',
    component: Home,
    path: getLanguagePath('/'),
    exact: true,
    lastmod: '2021-02-18'
  },
  {
    name: 'Login',
    component: Login,
    path: getLanguagePath('/login'),
    exact: true,
    lastmod: '2021-02-18'
  },
  {
    name: 'Shop',
    component: Shop,
    path: getLanguagePath('/shop'),
    exact: true,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/solutions/'),
    name: 'Solutions',
    component: Solutions,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/pages/misters-partnership-program/'),
    name: 'PartnerProgram',
    component: PartnerProgram,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/pages/misters-excellence-program/'),
    name: 'ExcellenceProgram',
    component: ExcellenceProgram,
    lastmod: '2021-03-13'
  },
  {
    name: 'Feedback',
    component: Feedback,
    path: getLanguagePath('/feedback'),
    exact: true,
    lastmod: '2021-02-18'
  },
  {
    name: 'About',
    component: About,
    path: getLanguagePath('/about'),
    exact: true,
    lastmod: '2021-02-18'
  },
  {
    name: 'IntimacyTherapy',
    component: IntimacyTherapy,
    path: getLanguagePath('/covidvirus/intimacy-therapy'),
    exact: true,
    lastmod: '2021-02-18'
  },
  {
    name: 'Media',
    component: Media,
    path: getLanguagePath('/media'),
    exact: true,
    lastmod: '2021-02-18'
  },
  {
    name: 'Ayurveda',
    component: Ayurveda,
    path: getLanguagePath('/ayurveda'),
    exact: true,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/erectile-dysfunction'),
    name: 'ErectileDysfunction',
    component: ErectileDysfunction,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/premature-ejaculation'),
    name: 'PrematureEjaculation',
    component: PrematureEjaculation,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/hairfall'),
    name: 'Hairfall',
    component: Hairfall,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/amla'),
    name: 'Amla',
    component: Amla,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/ashwagandha'),
    name: 'Ashwagandha',
    component: Ashwagandha,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/kaunch-beej'),
    name: 'KaunchBeej',
    component: KaunchBeej,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/shilajit'),
    name: 'Shilajit',
    component: Shilajit,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/gokshura'),
    name: 'Gokshura',
    component: Gokshura,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/kesar'),
    name: 'Kesar',
    component: Kesar,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/shatavari'),
    name: 'Shatavari',
    component: Shatavari,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/safed-musli'),
    name: 'SafedMusli',
    component: SafedMusli,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/jaiphal'),
    name: 'Jaiphal',
    component: Jaiphal,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/bhringraj'),
    name: 'Bhringraj',
    component: Bhringraj,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/hair-bhringraj'),
    name: 'HairMask',
    component: HairMask,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/bold-with-ashwagandha-gokshura-benefits'),
    name: 'BoldDetail',
    component: BoldDetail,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/ingredient/aips-and-srq'),
    name: 'AIPSAndSRQ',
    component: AIPSAndSRQ,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/thinking-sex'),
    name: 'ThinkingSex',
    component: ThinkingSex,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/pages/product-page'),
    name: 'ProductPage',
    component: ProductPage,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/pages/sexologists-sex-clinic-location-gurgaon-ad'),
    name: 'ClinicLandingPage',
    component: ClinicLandingPage,
    lastmod: '2021-02-18'
  },
  {
    exact: true,
    path: getLanguagePath('/pages/herbal-ingredient-stack-score/'),
    name: 'HISScore',
    component: HISScore,
    lastmod: '2021-02-19'
  },
  {
    exact: true,
    path: getLanguagePath('/pages/misters-conference/'),
    name: 'ConferenceRegistration',
    component: ConferenceRegistration,
    lastmod: '2021-07-02'
  }
]
