import api from '../api'
export const FETCH_REPORT = 'FETCH_REPORT'
export const FETCH_REPORT_RESULT = 'FETCH_REPORT_RESULT'
export const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR'
export const FETCH_REPORT_RETRY = 'FETCH_REPORT_RETRY'

export const fetchReport = () => async dispatch => {
  try {
    dispatch({ type: FETCH_REPORT })
    const res = await api.bot.report.get()
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_REPORT_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_REPORT_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_REPORT_RETRY, message: e.message })
  }
}
