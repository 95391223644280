import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { productResponsive } from '../../../constants'
import AliceCarousel from 'react-alice-carousel'
import { getHttpsImageUrl, getLanguageTranslation, getPrice, truncChars } from '../../../utils'
import { faImages } from '@fortawesome/free-solid-svg-icons/faImages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router'
import Button from '../../buttons/Button'

const RenderItem = ({ language, item }) => {
  return (
        <div className="product-card-item">
            <div className="product-card-item-image">
                {
                    item.image
                      ? <img src={getHttpsImageUrl(item.image)} alt={item.title}/>
                      : <FontAwesomeIcon icon={faImages} size={'6x'}/>
                }
            </div>

            <div className="product-card-item-text-container">
                <p className="product-card-item-title">
                    {getLanguageTranslation(item, 'title', language)}
                </p>
                <div className="product-card-item-description">
                  {truncChars(getLanguageTranslation(item, 'short_description', language), 150)}
                </div>
            </div>
        </div>
  )
}

RenderItem.propTypes = {
  language: PropTypes.string,
  item: PropTypes.object
}

class ProductCard extends PureComponent {
    static propTypes = {
      match: PropTypes.object,
      product: PropTypes.object,
      handlePurchaseClick: PropTypes.func
    };

    constructor (props) {
      super(props)

      this.state = {
        productItemConnections: props.product.item_connections
      }
    }

    render () {
      const { product, match: { params: { language } }, handlePurchaseClick } = this.props
      const { productItemConnections } = this.state
      const price = getPrice(product.price)
      const salePrice = product.sale_price ? getPrice(product.sale_price) : null
      const isAutoPlay = product.item_connections.length !== 1

      return (
            <div className='product-card'>
                <h3 className="text-center font-weight-light mb-4">Personalized solution for you</h3>

                <AliceCarousel mouseTrackingEnabled touchTrackingEnabled className="product-card-carousel"
                               autoPlayInterval={3000}
                               autoPlay={isAutoPlay}
                               infinite={isAutoPlay}
                               stopAutoPlayOnHover={true}
                               buttonsDisabled={true}
                               responsive={productResponsive}>
                    {!!productItemConnections &&
                    productItemConnections.map(
                      ({ id, item }) => (
                            <RenderItem key={id} item={item} language={language}/>
                      ))
                    }
                </AliceCarousel>

                <div className="row">
                    <div className="col-12">
                        <div className="product-pricing-report">
                            {
                                !!product.item_connections && !!product.item_connections.length &&
                                <table className="product-pricing-table">
                                    <tbody>
                                    {
                                        product.item_connections.map(
                                          ({ id, item, quantity }, index) => {
                                            return (
                                                    <tr key={id}>
                                                        <td>{item.title}</td>
                                                        <td>x {quantity}</td>
                                                    </tr>
                                            )
                                          })
                                    }
                                    </tbody>
                                </table>
                            }
                            <div className="text-center pb-3">
                              <Button variant="red" style={{ minWidth: 200 }}
                                      to={`${language}/solutions/${product.product_id}`}>
                                Show Details
                              </Button>
                            </div>
                          <div className="text-center">
                            <Button variant="red" style={{ minWidth: 200 }}
                                    onClick={() => handlePurchaseClick(product)}>
                              Add to Cart
                            </Button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
      )
    }
}

export default withRouter(ProductCard)
