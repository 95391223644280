// External Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
// My Imports
import { fieldTypes, EMAIL_REGEX } from '../../../constants'
import Button from '../../buttons/Button'

const { INTEGER, TEXT, EMAIL, FLOAT, DATE } = fieldTypes

export default class SingleInput extends Component {
    static propTypes = {
      placeholder: PropTypes.string,
      inputType: PropTypes.oneOf(Object.values(fieldTypes)),
      handleSendClick: PropTypes.func,
      toggleEdit: PropTypes.func
    };

    static defaultProps = {
      placeholder: '',
      inputType: 'text'
    };

    state = {
      value: '',
      error: '',
      startDate: null
    };

    componentDidMount () {
    };

    onChangeText = (value) => {
      const condition = (
        (this.props.inputType === INTEGER && /^[0-9]*$/.test(value)) ||
            (this.props.inputType === FLOAT && /^[0-9.]*$/.test(value)) ||
            (this.props.inputType === TEXT) || (this.props.inputType === EMAIL)
      )
      // console.warn(value);
      if (condition) {
        this.setState({ value, error: '' })
      }
    };

    onChangeDate = (date) => {
      this.setState({ startDate: date })
      this.setDate(date)
    };

    setDate = (value) => {
      this.setState({ value: moment(value).format('YYYY-MM-DD') })
    };

    handleSend = () => {
      const { inputType } = this.props

      if (this.state.value.length > 0) {
        if (inputType === EMAIL && !EMAIL_REGEX.test(this.state.value)) {
          this.setState({ error: 'Please enter a valid email address' })
        } else {
          this.props.toggleEdit()
          setTimeout(() => this.props.handleSendClick({ text: this.state.value }), 400)
        }
      }
    };

    render () {
      const { placeholder, inputType } = this.props
      let keyboardType = 'text'

      switch (inputType) {
        case EMAIL:
          keyboardType = 'email'
          break
        case INTEGER:
          keyboardType = 'number'
          break
        case FLOAT:
          keyboardType = 'number'
          break
        case DATE:
          keyboardType = 'date'
          break
      }

      return (
            <div className='single-input-card-container'>
                {
                    (inputType === INTEGER || inputType === FLOAT || inputType === TEXT || inputType === EMAIL) &&
                    <div className='single-input'>
                        <input placeholder={placeholder}
                               value={this.state.value}
                               onChange={(e) => this.onChangeText(e.target.value)}
                               type={keyboardType}
                               autoFocus={true}
                               onKeyPress={(event) => {
                                 if (event.key === 'Enter') {
                                   this.handleSend()
                                 }
                               }}/>
                        {!!this.state.error &&
                        <p>
                            <small className="text-danger">{this.state.error}</small>
                        </p>}
                    </div>
                }
                {
                    inputType === DATE &&
                    <DatePicker
                        className='date-input'
                        placeholderText='Select Date'
                        selected={this.state.startDate}
                        onChange={(date) => this.onChangeDate(date)}
                    />
                }
                {
                    this.state.value.length > 0 &&
                    <div className="field-footer">
                        <div className="container d-flex justify-content-center">
                            <Button onClick={this.handleSend}>
                                Next
                            </Button>
                        </div>
                    </div>
                }
            </div>
      )
    }
}
