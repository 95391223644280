// External Imports
import React from 'react'
import PropTypes from 'prop-types'
// My Imports
import { Images } from '../../../constants'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText'

export default class WhyMisters extends React.Component {
    static propTypes = {
      to: PropTypes.string,
      showQuizButton: PropTypes.bool,
      bgLight: PropTypes.bool,
      buttonText: PropTypes.string
    };

    static defaultProps = {
      to: '/en/quiz/sex/',
      showQuizButton: true,
      bgLight: false,
      buttonText: 'TAKE_THE_SEX_QUIZ'
    };

    state = {
      width: 500,
      height: 500
    };

    componentDidMount () {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this.handleResize)
    }

    handleResize = () => {
      this.setState({
        width: Math.max(this.containerRef.clientWidth, 600),
        height: Math.max(this.containerRef.clientHeight, 600)
      })
    };

    render () {
      const { to, bgLight, showQuizButton, buttonText } = this.props

      return (
            <div className={`${bgLight ? 'bg-light' : ''}`}>
                <div className="container position-relative" ref={ref => this.containerRef = ref}>
                    <div className="row pt-5">
                        <div className="col-12">
                            <h2 className="text-center home-page-subtitle">Why Misters?</h2>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-md-4 col-6 py-2 text-center">
                            <div className="why-misters-card-bg">
                                <img className="category-icons lazyload" data-src={Images.icons.scientific}
                                     alt="Judgement free zone"/>
                                <p className="text-center">Scientific products</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 py-2 text-center">
                            <div className="why-misters-card-bg">
                                <img className="category-icons lazyload" data-src={Images.icons.delivery}
                                     alt="Discreet doorstep delivery"/>
                                <p className="text-center">Discreet doorstep delivery</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 py-2 text-center">
                            <div className="why-misters-card-bg">
                                <img className="category-icons lazyload" data-src={Images.icons.shield}
                                     alt="Full privacy guaranteed"/>
                                <p className="text-center">Full privacy guaranteed</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 py-2 text-center">
                            <div className="why-misters-card-bg">
                                <img className="category-icons lazyload" data-src={Images.icons.thumb}
                                     alt="Trusted by thousands of men"/>
                                <p className="text-center">Trusted by over <strong>25,000 Indian Men</strong></p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 py-2 text-center">
                            <div className="why-misters-card-bg">
                                <img className="category-icons lazyload" data-src={Images.icons.flag}
                                     alt="Judgement free zone"/>
                                <p className="text-center">Judgement free zone</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 py-2 text-center">
                            <div className="why-misters-card-bg">
                                <img className="category-icons lazyload" data-src={Images.icons.doctor}
                                     alt="Judgement free zone"/>
                                <p className="text-center">Backed by leading doctors</p>
                            </div>
                        </div>
                    </div>
                    {
                        showQuizButton &&
                        <div className="row pb-5">
                            <div className="col-12 text-center py-2">
                                <Button to={to} style={{ width: 200 }}><LText k={buttonText}/></Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
      )
    }
}
