import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class LText extends React.Component {
    static propTypes = {
      k: PropTypes.string,
      replaceKeys: PropTypes.array
    };

    static defaultProps = {
      replaceKeys: []
    };

    render () {
      const { language, k, replaceKeys } = this.props
      let text = language[k]
      replaceKeys.map(({ key, value }) => { text = text.replace(`{${key}}`, value) })

      return (
            <React.Fragment>
                {text}
            </React.Fragment>
      )
    }
}

const mapStateToProps = (state) => ({
  language: state.languages.language
})

export default connect(mapStateToProps)(LText)
