import { HOST } from '../constants'
import makeRequest from './makeRequest'
import { addFileToForm, getQueryString, jsonToForm } from '../utils'

export { HOST }

export default {
  accounts: {
    otp: data => (makeRequest(
      `${HOST}/api/v1/accounts/phone/otp/`,
      { method: 'post', body: jsonToForm(data) },
      false
    )),
    anonymousLogin: () => (makeRequest(
      `${HOST}/api/v1/accounts/login/anonymous/`,
      { method: 'post' },
      false
    )),
    login: data => (makeRequest(
      `${HOST}/api/v1/accounts/phone/login/`,
      { method: 'post', body: jsonToForm(data) }
    )),
    getUser: data => (makeRequest(
      `${HOST}/api/v1/accounts/profile/`,
      { method: 'get' }
    )),
    patchUser: data => (makeRequest(
      `${HOST}/api/v1/accounts/profile/`,
      { method: 'PATCH', body: jsonToForm(data) }
    )),
    requestOtp: data => (makeRequest(
      `${HOST}/api/v1/accounts/otp/`,
      { method: 'post', body: jsonToForm(data) }
    )),
    tokenVerify: data => (makeRequest(
      `${HOST}/api/v1/accounts/token/verify/`,
      { method: 'post', body: jsonToForm(data) }
    )),
    tokenRefresh: data => (fetch(
      `${HOST}/api/v1/accounts/token/refresh/`,
      { method: 'post', body: jsonToForm(data) }
    )),
    deviceToken: data => (makeRequest(
      `${HOST}/api/v1/accounts/device/token/`,
      { method: 'post', body: jsonToForm(data) }
    ))
  },
  blog: {
    posts: {
      get: ({ slug }) => makeRequest(
        `${HOST}/api/v1/blog/posts/${slug}/`,
        {},
        false
      ),
      list: ({ tag = '', page = 1, search = '' }) => makeRequest(
        `${HOST}/api/v1/blog/posts/?tag=${tag}&page=${page}&q=${search}`,
        {},
        false
      ),
      update: ({ oldSlug, ...data }) => makeRequest(
        `${HOST}/api/v1/blog/posts/${oldSlug}/`,
        { method: 'PATCH', bodyType: 'json', body: JSON.stringify(data) }
      ),
      create: (data) => makeRequest(
        `${HOST}/api/v1/blog/posts/`,
        { method: 'POST', bodyType: 'json', body: JSON.stringify(data) }
      ),
      recent: () => makeRequest(
        `${HOST}/api/v1/blog/posts/recent/`, {}, false
      ),
      ghostrecentposts: data => (fetch(
        `https://misters.in/ghost/api/v3/content/posts/?key=dea5c9f1bfb7227aa66fefb3dc&include=authors,primary_author,primary_tag&limit=4&filter=tag:hash-post%2Btag:hash-${data.lang}`,
        { method: 'get' }
      )),
      ghostrecentfaqs: data => (fetch(
        `https://misters.in/ghost/api/v3/content/posts/?key=dea5c9f1bfb7227aa66fefb3dc&include=authors,primary_author,primary_tag&limit=10&filter=tag:ayurveda%2Btag:hash-faq%2Btag:hash-${data.lang}`,
        { method: 'get' }
      ))
    },
    qa: {
      get: ({ slug }) => makeRequest(`${HOST}/api/v1/blog/qa/${slug}/`, {}, false),
      list: ({ tag = '', page = 1, search }) => makeRequest(
        `${HOST}/api/v1/blog/qa/?tag=${tag}&page=${page}&q=${search}`,
        {}, false)
    },
    tags: {
      list: ({ type }) => makeRequest(`${HOST}/api/v1/blog/tags/?type=${type}`, {}, false)
    },
    authors: {
      get: ({ username }) => makeRequest(`${HOST}/api/v1/blog/authors/${username}/`, {}, false)
    },
    videos: {
      list: () => makeRequest(`${HOST}/api/v1/blog/videos/`, { method: 'GET' }, false)
    }
  },
  bot: {
    getBot: data => (makeRequest(
      `${HOST}/api/v1/bot/`,
      { method: 'get' }
    )),
    getBotFormList: data => (makeRequest(
      `${HOST}/api/v1/bot/all-quiz/`,
      { method: 'get' }
    )),
    getBotBack: data => (makeRequest(
      `${HOST}/api/v1/bot/back/`,
      { method: 'get' }
    )),
    patchBot: ({ id, ...data }) => (makeRequest(
      `${HOST}/api/v1/bot/bot_field_response/${id}/`,
      { method: 'PATCH', body: jsonToForm(data) }
    )),
    postReassignBot: data => (makeRequest(
      `${HOST}/api/v1/bot/reassign/`,
      { method: 'post' }
    )),
    postAssignBot: data => makeRequest(
      `${HOST}/api/v1/bot/assign/`,
      { method: 'POST', body: jsonToForm(data) }
    ),
    postMetaDataBot: data => makeRequest(
      `${HOST}/api/v1/bot/meta/`,
      { method: 'POST', body: jsonToForm(data) }
    ),
    getQuizTakersCount: () => makeRequest(`${HOST}/api/v1/bot/quiz-takers-count/`),
    report: {
      get: data => (makeRequest(`${HOST}/api/v1/bot/report/`))
    }
  },
  ecommerce: {
    cart: {
      empty: () => makeRequest(`${HOST}/api/v1/ecommerce/cart/empty/`, { method: 'post' }),
      get: (search = '') => (makeRequest(`${HOST}/api/v1/ecommerce/cart/${search}`)),
      config: () => makeRequest(`${HOST}/api/v1/ecommerce/config/`),
      coupon: (data) => (makeRequest(`${HOST}/api/v1/ecommerce/cart/coupon/`, {
        method: 'POST',
        body: JSON.stringify(data),
        bodyType: 'json'
      })),
      paymentMethod: (data) => (makeRequest(`${HOST}/api/v1/ecommerce/cart/payment-method/`, {
        method: 'POST',
        body: JSON.stringify(data),
        bodyType: 'json'
      }))
    },
    wishlist: {
      get: () => (makeRequest(`${HOST}/api/v1/ecommerce/wishlist/`)),
      update: (data) => (makeRequest(`${HOST}/api/v1/ecommerce/wishlist/`,
        { method: 'PATCH', body: JSON.stringify(data), bodyType: 'json' }))
    },
    cartItem: {
      remove: (itemId) => makeRequest(`${HOST}/api/v1/ecommerce/cart/item/${itemId}/delete/`, { method: 'DELETE' }),
      create: (data) => makeRequest(`${HOST}/api/v1/ecommerce/cart/item/${data.id}/create/`,
        { method: 'POST', body: jsonToForm(data) }
      ),
      update: (data) => makeRequest(`${HOST}/api/v1/ecommerce/cart/update/`,
        { method: 'POST', body: JSON.stringify(data), bodyType: 'json' }
      )
    },
    address: {
      list: () => makeRequest(`${HOST}/api/v1/ecommerce/address/`),
      create: (data) => makeRequest(`${HOST}/api/v1/ecommerce/address/`, {
        method: 'POST',
        body: jsonToForm(data)
      }),
      update: (data) => makeRequest(`${HOST}/api/v1/ecommerce/address/${data.id}/`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        bodyType: 'json'
      }),
      remove: ({ id }) => makeRequest(`${HOST}/api/v1/ecommerce/address/${id}/`, { method: 'DELETE' }),
      pincode: (data) => makeRequest(
        `${HOST}/api/v1/ecommerce/address/pincode/`,
        { method: 'POST', bodyType: 'json', body: JSON.stringify(data) },
        false
      )
    },
    orders: {
      list: () => makeRequest(`${HOST}/api/v1/ecommerce/orders/`),
      get: ({ cid }) => makeRequest(`${HOST}/api/v1/ecommerce/orders/${cid}/`, {}, false),
      create: (data) => makeRequest(
        `${HOST}/api/v1/ecommerce/orders/`,
        { method: 'POST', body: jsonToForm(data) }
      ),
      updateProduct: ({ id, ...data }) => makeRequest(
        `${HOST}/api/v1/ecommerce/orders/${id}/product-pending/`,
        { method: 'PATCH', body: JSON.stringify(data), bodyType: 'json' }
      )
    },
    product: {
      list: (data) => makeRequest(`${HOST}/api/v1/ecommerce/products/?${getQueryString(data)}`, {}, false),
      get: ({ product_id }) => makeRequest(`${HOST}/api/v1/ecommerce/products/${product_id}/`, {}, false)
    },
    addressavailablemethods: {
      get: (data) => makeRequest(
        `${HOST}/api/v1/ecommerce/cart/available-methods/`,
        { method: 'post', body: JSON.stringify(data), bodyType: 'json' },
        false
      )
    },
    reviews: {
      list: (data) => makeRequest(`${HOST}/api/v1/ecommerce/reviews/?${getQueryString(data)}`, {}, false),
      create: (data) => makeRequest(
        `${HOST}/api/v1/ecommerce/reviews/`,
        { method: 'POST', body: jsonToForm(data) }
      )
    },
    coupons: {
      list: (data) => makeRequest(`${HOST}/api/v1/ecommerce/coupons/`, {}, false)
    },
    offers: {
      list: (data) => makeRequest(`${HOST}/api/v1/ecommerce/offers/`, {}, false)
    }
  },
  subscriptions: {
    subscriptions: {
      create: (data) => makeRequest(`${HOST}/api/v1/subscriptions/subscriptions/`,
        { method: 'POST', body: JSON.stringify(data), bodyType: 'json' })
    }
  },
  report: {
    report: {
      get: ({ report_id }) => makeRequest(`${HOST}/api/v1/report/${report_id}/`),
      list: () => makeRequest(`${HOST}/api/v1/report/`)
    },
    feedback: {
      get: ({ feedback_id }) => makeRequest(`${HOST}/api/v1/report/feedback/${feedback_id}/`)
    }
  },
  campaigns: {
    restaurant: {
      post: ({ cid }) => makeRequest(`${HOST}/api/v1/campaigns/restaurant_sms/${cid}/`,
        { method: 'post' })
    },
    conferenceRegistration: ({ contribution_file, ...data }) => {
      const form = jsonToForm(data)
      let body = form
      if (contribution_file) {
        body = addFileToForm({ form: body, key: 'contribution_file', file: contribution_file })
      }
      return makeRequest(`${HOST}/api/v1/campaigns/register/`, {
        method: 'post',
        body: body
      })
    }
  },
  support: {
    orderquery: (data) => makeRequest(`${HOST}/api/v1/support/orders/query/`,
      { method: 'post', body: JSON.stringify(data), bodyType: 'json' })
  },
  library: {
    courses: () => makeRequest(`${HOST}/api/v1/library/courses/`),
    purchase: (data) => makeRequest(
      `${HOST}/api/v1/library/purchase/`,
      { method: 'post', body: JSON.stringify(data), bodyType: 'json' }
    )
  },
  clinic: {
    lead: (data) => makeRequest(
      `${HOST}/api/v1/clinic/lead/`,
      { method: 'post', body: JSON.stringify(data), bodyType: 'json' }
    ),
    clinic: (data) => makeRequest(
      `${HOST}/api/v1/clinic/${data.slug}/`
    )
  },
  community: {
    posts: {
      list: () => makeRequest(`${HOST}/api/v1/community/posts/`, {}, false),
      get: ({ slug }) => makeRequest(`${HOST}/api/v1/community/posts/${slug}/`, {}, false)
    },
    doctors: {
      list: () => makeRequest(`${HOST}/api/v1/community/doctors/`, {}, false),
      get: ({ slug }) => makeRequest(`${HOST}/api/v1/community/doctors/${slug}/`, {}, false)
    },
    comments: {
      like: ({ comment_id }) => makeRequest(`${HOST}/api/v1/community/comments/${comment_id}/like/`, { method: 'patch' })
    }
  }
}
