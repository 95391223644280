import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Images } from '../../../constants'

class AnalysisTotalScoreCard extends Component {
    static propTypes = {
      className: PropTypes.string,
      name: PropTypes.string,
      score: PropTypes.number,
      barScore: PropTypes.number,
      stepBreaker: PropTypes.number,
      percentile: PropTypes.number,
      duration: PropTypes.number,
      delay: PropTypes.number,
      offset: PropTypes.number
    };

    static defaultProps = {
      name: 'Total Score',
      className: '',
      score: 0,
      stepBreaker: 10,
      duration: 1000,
      delay: 100,
      offset: 200
    };

    constructor (props) {
      super(props)

      this.state = {}
    }

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState, snapshot) {

    }

    componentWillUnmount () {
    }

    render () {
      const { className, score, duration, delay, stepBreaker, offset, percentile, name } = this.props

      const steps = []
      for (let i = 1; i <= 10; i++) {
        steps.push(i * stepBreaker)
      }

      return (
            <div className={`analysis-total-score-card ${className}`} data-aos-offset={offset}
                 data-aos="zoom-in" data-aos-duration={duration} data-aos-delay={delay} data-aos-once="true">
                <div className="analysis-total-score-bar">
                    <img className="total-score-plus-minus" src={Images.report.totalScoreMinus} alt="totalScoreMinus"/>
                    <div className="total-score-bar-container">
                        {steps.map(step => <span key={step}>{step}</span>)}
                        <img className="total-score-button" src={Images.report.totalScoreButton}
                             alt="totalScoreButton" style={{ left: `${score}%` }}/>
                        <img className="total-score-bar" src={Images.report.totalScoreBar} alt="totalScoreBar"/>
                    </div>
                    <img className="total-score-plus-minus" src={Images.report.totalScorePlus} alt="totalScorePlus"/>
                </div>
                <h1 className="text-center">{score}</h1>
                <h4 className="text-center text-white font-weight-light">{name}</h4>
              {
                !!percentile && <p className="report-percentile">Your score is more than {percentile}% of all users who have taken this quiz</p>
              }
            </div>
      )
    }
}

export default AnalysisTotalScoreCard
