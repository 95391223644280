export default ({ data, url }) => {
  const schemaTagInnerHTML = ({
    '@context': 'http://schema.org',
    '@type': 'FAQPage',
    mainEntity: data.map(
      faq => ({
        '@type': 'Question',
        name: faq.question,
        dateCreated: '',
        answerCount: 1,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.answer,
          dateCreated: ''
        }
      })
    ),
    provider: {
      '@type': 'Organization',
      name: ' ',
      url: url
    }
  })

  return {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(schemaTagInnerHTML)
  }
}
