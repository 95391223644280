// External Imports
import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// My ImportsSelectSearch
import { Images } from '../../constants'
import Page from '../../components/core/Page'
import WhyMisters from '../../components/others/WhyMisters'
import { fetchQuizTakersCount } from '../../actions/bot/bot'
import script from './script'
import { fetchProducts } from '../../actions/ecommerce/products'
import { fetchRecentPosts } from '../../actions/blog/posts'
import { fetchRecentFaqs } from '../../actions/blog/faqs'
import { createCartItem } from '../../actions/ecommerce/cart'
import { languageToShortName, languageUrls } from '../../languages'
import Ecommerce from '../../services/Ecommerce'
import Ingredients from '../../components/others/Ingredients'
import Section1 from './Section1'
import Section2 from './Section2'
import Section4 from './Section4'
import Section5 from './Section5'
import Section7 from './Section7'
import Section8 from './Section8'
import MistersConfidenceFormula from '../../components/others/MistersConfidenceFormula'
import TakeQuiz from '../../components/others/TakeQuiz'
import Section3 from './Section3'
import AllQuiz from './AllQuiz'

class Home extends PureComponent {
  static propTypes = {
    fetchQuizTakersCount: PropTypes.func,
    products: PropTypes.object,
    cart: PropTypes.object,
    posts: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    quizTakersCount: PropTypes.number,
    fetchProducts: PropTypes.func,
    fetchRecentPosts: PropTypes.func,
    fetchRecentFaqs: PropTypes.func,
    createCartItem: PropTypes.func
  };

  static fetching ({ dispatch }) {
    return [dispatch(fetchProducts())]
  };

  constructor (props) {
    super(props)

    this.state = {
      quizImageIndex: 0,
      quizImages: [Images.home1, Images.home2]
    }
  }

  componentDidMount () {
    const { match } = this.props
    const langParam = match.params.language || '/en'
    const lang = languageToShortName[languageUrls[langParam]]
    this.handleRefreshQuizTakersCount()
    this.props.fetchProducts()
    this.props.fetchRecentFaqs({ lang })
    this.props.fetchRecentPosts({ lang })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { cart, match, history } = this.props
    const { language } = match.params

    if (!cart.isCreatingCartItem && prevProps.cart.isCreatingCartItem && !cart.error && !cart.retry) {
      history.push(`${language}/checkout`)
    }
  }

  componentWillUnmount () {
  }

  handleRefreshQuizTakersCount = () => {
    this.props.fetchQuizTakersCount()
  }

  handleAddItemToCart = async (product) => {
    Ecommerce.addToCart({ products: [product] })
    this.props.createCartItem({ id: product.id, quantity: 1 })
  };

  handleOpenProductDetails = (product) => {
    const { match, history } = this.props
    Ecommerce.productClick({
      product,
      eventCallback: () => {
        if (product.is_single_product) {
          history.push(`${match.params.language}/therapy/${product.product_id}`)
        } else {
          history.push(`${match.params.language}/solutions/${product.product_id}`)
        }
      }
    })
  };

  render () {
    const { match } = this.props
    const language = match.params.language

    const ingredients = [
      {
        url: `${language}/ingredient/safed-musli`,
        image: 'https://healthfactorial.s3.amazonaws.com/media/public/ingredients/safed_description.jpg',
        title: 'Safed Musli',
        description: 'Safed Musli, an aphrodisiac, has been proven to increase desire in men, especially among middle-aged and older men.'
      },
      {
        url: `${language}/ingredient/shilajit`,
        image: 'https://healthfactorial.s3.amazonaws.com/media/public/ingredients/shilajit.jpg',
        title: 'Shilajit',
        description: 'Shilajit restores the energy of the body. Fulvic acid, one of its major ingredients, is responsible for boosting power, energy and stamina.'
      },
      {
        url: `${language}/ingredient/gokshura`,
        image: 'https://healthfactorial.s3.amazonaws.com/media/public/ingredients/gokshura.jpg',
        title: 'Gokshura',
        description: 'Gokshura increases the testosterone levels, strengthens penile tissue and helps in sustaining erections. It also relieves psychological and physiological stress in men.'
      },
      {
        url: `${language}/ingredient/ashwagandha`,
        image: 'https://healthfactorial.s3.amazonaws.com/media/public/ingredients/ashwagandha_3OrMK1Y.jpg',
        title: 'Ashwagandha',
        description: 'Ashwagandha improves tissue vitality, physical and mental endurance, neuromuscular strength and oxygen uptake for peak performance.'
      }
    ]

    return (
      <Page meta={{
        path: `${match.params.language}`,
        title: 'Misters.in - Men\'s sexual health',
        description: 'Misters is a Gurgaon-based health and confidence start-up catering to men’s sexual health and wellness using ayurvedic, allopathic and plant-based methods. In collaboration with medical experts, reliable information on erections, ejaculation, sexual desire and hair care awaits you here. Simple, personalized and result-oriented care amalgamating traditional knowledge and the best science has to offer is Misters’ forte. More than a website and company, Misters is a community. Let your journey commence, bon voyage!'
      }} showBanner={true} script={script}>
        <div>
          <Section1/>
          <AllQuiz/>
          <Section3/>
          <Ingredients ingredients={ingredients}/>
          <TakeQuiz/>
          <Section5/>
          <WhyMisters/>
          <Section7/>
          <Section8/>
        </div>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  quizTakersCount: state.bot.bot.quizTakersCount,
  products: state.ecommerce.products,
  cart: state.ecommerce.cart,
  posts: state.blog.posts
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchQuizTakersCount,
  fetchProducts,
  fetchRecentFaqs,
  fetchRecentPosts,
  createCartItem
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Home)
