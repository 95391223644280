// External Imports
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
// My Imports
import Navbar from '../../navbars/Navbar'
import Footer from '../../footers/Footer'
import { LANGUAGE, Meta, PHONE_NUMBER } from '../../../constants'
import { isRouteEqual } from '../../../routes'
import { getHttpsWebsiteUrl, getStorageItem, scrollToTop } from '../../../utils'
import { changeLanguage } from '../../../actions/languages'
import { languageUrls } from '../../../languages'
import CheckoutFooter from '../../footers/CheckoutFooter'

// Meta format -
// {
//     title: PropTypes.string,
//     description: PropTypes.string,
//     image: PropTypes.string,
//     url: PropTypes.string,
//     path: PropTypes.string,
//     site_name: PropTypes.string,
//     site: PropTypes.string,
//     username: PropTypes.string,
// }

class Page extends React.Component {
    static propTypes = {
      meta: PropTypes.object,
      match: PropTypes.object,
      history: PropTypes.object,
      profile: PropTypes.object,
      location: PropTypes.object,
      handleLogoClick: PropTypes.func,
      showNavbar: PropTypes.bool,
      showFooter: PropTypes.bool,
      isScrollToTop: PropTypes.bool,
      showNotification: PropTypes.bool,
      showBanner: PropTypes.bool,
      changeLanguage: PropTypes.func,
      footerVariant: PropTypes.oneOf(['primary', 'checkout']),
      script: PropTypes.array
    };

    static defaultProps = {
      meta: {},
      handleLogoClick: () => {},
      showNavbar: true,
      showFooter: true,
      isScrollToTop: true,
      showNotification: false,
      showBanner: false,
      footerVariant: 'primary',
      script: []
    };

    componentDidMount () {
      const { profile, history, match, isScrollToTop } = this.props
      const querySearch = this.props.location.search
      const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email

      if (isScrollToTop) {
        scrollToTop(0)
      }

      // console.warn(match.path, history, isRouteEqual(match.path, 'Cart'), isAuthenticated);
      const language = getStorageItem(LANGUAGE, null)
      if (language) {
        if (match.params.language && languageUrls[match.params.language] !== language &&
                match.params.language.length > 0) {
          changeLanguage({ language: languageUrls[match.params.language] })
        }
      } else {

      }
      if (!isAuthenticated) {
        if (
          isRouteEqual(match.path, 'Profile') ||
          isRouteEqual(match.path, 'Orders') ||
          // isRouteEqual(match.path, 'Order') ||
          isRouteEqual(match.path, 'Addresses') ||
          isRouteEqual(match.path, 'Reports') ||
          isRouteEqual(match.path, 'Wishlist')
        ) {
          history.replace(`${match.params.language}/login/?next=${match.url}${querySearch}`)
        }
      } else {
        if (isRouteEqual(match.path, 'Login')) {
          history.replace(`${match.params.language}`)
        }
      }
    }

    renderFooterVariant = () => {
      const { footerVariant } = this.props

      if (footerVariant === 'primary') {
        return <Footer/>
      } else if (footerVariant === 'checkout') {
        return <CheckoutFooter/>
      }
    };

    render () {
      let {
        children, meta, staticContext, handleLogoClick, showNavbar, showFooter, showBanner,
        showNotification, footerVariant, changeLanguage, isScrollToTop, script, ...props
      } = this.props
      meta = { ...Meta, ...meta }
      if (meta.path) {
        meta.url = getHttpsWebsiteUrl(meta.path)
      }
      let { match: { params: { language } } } = this.props
      if (language) {
        language = language.slice(1)
      } else {
        language = 'en'
      }

      const helmetMeta = [
        { name: 'viewport', content: 'width=device-width,initial-scale=1,maximum-scale=5,minimum-scale=1,shrink-to-fit=no' },

        { name: 'title', content: meta.title },
        { name: 'description', content: meta.description },

        { property: 'og:title', content: meta.title },
        { property: 'og:description', content: meta.description },
        { property: 'og:image', content: meta.image },
        { property: 'og:image:type', content: meta.image_type },
        { property: 'og:url', content: meta.url },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: meta.site_name },

        { name: 'twitter:card', content: 'product' },
        { name: 'twitter:title', content: meta.title },
        { name: 'twitter:description', content: meta.description },
        { name: 'twitter:image', content: meta.image },
        { name: 'twitter:site', content: meta.site },
        { name: 'twitter:creator', content: meta.username }
      ]

      return (
            <div>
                <Helmet htmlAttributes={{ lang: language }}>
                    <title>{meta.title}</title>
                    {
                        helmetMeta.map(
                          (metaTag, index) => <meta key={index} {...metaTag}/>
                        )
                    }
                    <link rel="canonical" href={meta.url}/>
                    {
                        script.map(
                          (scriptTag, index) => <script key={index} type={scriptTag.type}>{scriptTag.innerHTML}</script>
                        )
                    }
                </Helmet>
                {showNavbar && <Navbar handleLogoClick={handleLogoClick}/>}
                {children}
                {showFooter && this.renderFooterVariant()}
                {
                    showBanner &&
                    <a href={`tel:${PHONE_NUMBER}`} className="misters-sdk-banner-bottom">
                        <div>
                            <div className="misters-sdk-banner-bottom-btn">Call now</div>
                            <div className="misters-sdk-banner-bottom-number">{PHONE_NUMBER}</div>
                        </div>
                        <div className="misters-sdk-banner-bottom-text-container">
                          Any question about sex, answered by those who should have the answers. Absolutely free!
                        </div>
                    </a>
                }
            </div>
      )
    }
}

const mapStateToProps = state => ({
  profile: state.accounts.profile,
  cart: state.ecommerce.cart
})

const matchDispatchToProps = dispatch => bindActionCreators({
  changeLanguage
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Page))
