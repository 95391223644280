import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../../components/core/Page'
import CrossFadeImage from '../../../components/others/CrossFadeImage'
import { fetchCourses, purchaseLibrary } from '../../../actions/library/courses'
import { Images, RAZORPAY_APP_KEY } from '../../../constants'
import { fetchUserProfile } from '../../../actions/accounts/profile'

class Purchase extends Component {
    static propTypes = {
      purchaseLibrary: PropTypes.func,
      fetchCourses: PropTypes.func
    }

    componentDidMount () {
      const { match, user } = this.props
      if (user && user.is_premium_library_member) {
        this.props.history.push(`${match.params.language}/library`)
      }
    }

    handlePurchase = () => {
      const razorpayPaymentOptions = {
        key: RAZORPAY_APP_KEY, // Enter the Key ID generated from the Dashboard
        amount: '2500', // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: 'INR',
        name: 'Misters.in',
        description: 'Audio Library',
        image: `https://misters.in${Images.logo.x2}`,
        handler: (response) => {
          // alert(response.razorpay_payment_id);
          this.props.purchaseLibrary({ payment_id: response.razorpay_payment_id })
        },
        prefill: {
          name: '',
          email: '',
          contact: ''
        },
        notes: {
        },
        theme: {
          color: '#FF4B53'
        }
      }
      const rzp1 = new Razorpay(razorpayPaymentOptions)
      rzp1.open()
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { courses, match } = this.props

      if (!courses.isPurchasing && prevProps.courses.isPurchasing && !courses.error && !courses.retry) {
        this.props.fetchUserProfile()
        this.props.history.push(`${match.params.language}/library`)
      }
    }

    render () {
      const { match, user } = this.props
      return (
            <Page meta={{ path: `${match.params.language}/library/purchase` }}>
                <div className="course-page-section1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 py-5">
                                <CrossFadeImage
                                    src={Images.library}
                                    alt="Purchase Library"
                                    duration={700}
                                    timingFunction={'ease-out'}
                                />
                            </div>
                            <div
                                className="col-12 col-md-6 d-flex flex-column justify-content-center py-5 text-center text-md-left">
                                <h2 className="library-page-subtitle text-white">
                                    Unlock Everything!
                                </h2>
                                <p className="library-page-description text-white">
                                    Unlock full library!
                                </p>
                                <div className="library-page-plan" onClick={this.handlePurchase}>
                                    <h4 className="library-page-plan-tag">BEST VALUE</h4>
                                    <h4 className="library-page-plan-title">Lifetime Plan</h4>
                                    <p className="library-page-plan-price">₹25 <small>pay once</small></p>
                                </div>
                                <button className="btn-m btn-m-red library-page-plan-continue"
                                        onClick={this.handlePurchase}>
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = (state) => ({
  user: state.accounts.profile.detail,
  courses: state.library.courses
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchCourses,
  fetchUserProfile,
  purchaseLibrary
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Purchase)
