// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../../Category/common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText/LText'
import { openLinkOnNewTab } from '../../../utils'

const shopUrl = '/en/solutions/'

export const accordionList1 = [
    <AccordionCard key={'0'} title="What are some other names for Safed Musli?">
        <p>
            Although Safed Musli is well known across India by the same name, it is also recognised
            by alternative names in varied languages. Its botanical name is <b>Chlorophytum
            arundinaceum</b> and its synonyms are <b>Chlorophytum borivilianum</b> and <b>Asparagus
            adscendens</b>. It is known as <b>Shweta Musli in Sanskrit; Taniravi Thang in Tamil;
            Khiruva in Hindi (especially in the state of Uttar Pradesh); Safed Musli in both Hindi
            and Marathi; Dholi Musli in Gujrati; Tella Nela Tadi Gaddalu in Telugu and Shedheveli in
            Malayalam</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What is the description of a Safed Musli plant?">
        <p>
            Safed Musli refers to the annual herb that grows in both tropical and sub – tropical climates at an altitude
            of about 1500 meters. It can grow upto a <b>maximum height of about 1.5 feet</b> with its tubers having
            a <b>depth of about 10 inches</b>.
            <br/>
            <br/>
            Its <b>roots are pale brown or white in color</b>, with a rather characteristic odor and are tasteless.
            Its <b>root tubers are fleshy and cylindrical in shape</b> with a thickness of about 0.9 cm and a length of
            about 8 cm. The number of tubers Safed Musli has varies from plant to plant but on an average, around 5-30
            tubers can be observed on every plant.
            <br/>
            <br/>
            It has <b>6 – 13 radical leaves which are spirally imbricated, slightly narrow at the base and are sessile
            in nature</b>. The <b>leaves are about 30 cm long</b>. They are spread horizontally with a smooth surface,
            wavy margins and a parallel venation.
            <br/>
            <br/>
            <b>Flowers</b> of the Safed Musli plant are <b>small and white, bracteates, pedicellate, and generally
            arranged beautifully in a cluster of 3</b>. These flower clusters are dense on the upper part. Bracts are
            linear, papery <b>and purplish in color</b> and are about <b>1 – 10.5 cm long. Pedicles</b> are whitish and
            are about <b>6 – 10 mm long</b>. The plant <b>carries a green or yellow colored fruit</b> which is almost
            equal in length and breadth. Its seeds are tiny and black in color and are enclosed in a hole. One of these
            holes is said to contain about <b>10 – 12 seeds</b> in it.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="What are the Ayurvedic properties of Safed Musli?">
        <p>
            The ayurvedic properties of Safed Musli make it efficient in the management of health problems. Safed Musli
            is <b>Guru (heavy)</b> and <b>Snigdha (oily)</b> in <b>nature</b>. This property makes it a complete food
            which provides the fullness in the body. It is <b>Madhura (sweet)</b> in <b>taste</b>. It is <b>Sheeta
            (cold)</b> in its <b>potency</b>. It has the ability to balance the Vata and Pitta Dosha, due to which it is
            used for the treatment of disorders that occur because of an imbalance in these two Doshas.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the benefits of Safed Musli?">
        <p>
            Safed Musli is considered as one of the important herbs in the Indian Ayurvedic system of medicine. It is
            known for <b>improving the overall immunity</b> of an individual. In addition, it has a plethora of benefits
            in the field of sexual health. The <b>Vajikarana (aphrodisiac) property</b> of Safed Musli has proven to
            be <b>beneficial for</b> various problems like <b>erectile dysfunction, impotence and low potency among
            males</b>.
            <br/>
            <br/>
            Since almost all sexual disorders occur due to an imbalance of the Vata Dosha, Safed Musli proves to be very
            beneficial because of its Vata balancing property. The Vajikarana (aphrodisiac) property of Safed Musli has
            provided results when it comes to solving sexual problems like erectile dysfunction, premature ejaculation
            or loss of libido. The <b>Shukrala (spermatogenic) property</b> of Safed Musli helps to manage conditions
            like impotency or low potency of males, thus giving rise to a <b>well-balanced sexual life</b>.
            <br/>
            <br/>
            Safed Musli is also <b>helpful in male problems like urinary tract related issues like urine retention,
            burning in micturition, or experiencing pain while urination</b>. All these conditions occur due to the
            formation and accumulation of toxins in the body. Safed Musli increases urine production in the body which
            helps to detoxify it and consequently reduces symptoms and provides relief.
            <br/>
            <br/>
            Conditions like <b>arthritis</b> where people generally suffer <b>joint pain or inflammation</b> in or
            around joints are <b>relieved</b> by incorporating Safed Musli in their regular lifestyle.
            <br/>
            <br/>
            Surprisingly, Safed Musli is a kind of herb which, in spite of being Madhur (sweet) in taste, serves as <b>beneficial
            among those affected by diabetes</b>. Diabetes is a condition which occurs due to formation and accumulation
            of toxins in the body which disturbs the function of insulin in the body. Safed Musli does not contain
            starch, the excess of which is dangerous for diabetes patients. Safed Musli helps with diabetes due to
            its <b>Mural (diuretic) property</b> as well. This helps increase urine production in the body which helps
            to detoxify it, thus providing relief by reducing symptoms of this condition.
            <br/>
            <br/>
            Safed Musli is also known to aid the <b>treatment of conditions like diarrhea, dysentery or general
            debility</b>.
            <br/>
            <br/>
            Safed Musli benefits those individuals who are thin, lean and are willing to build their body as well as
            their stamina. It <b>provides strength to the bones and muscles</b> which facilitates a healthy and good
            looking body.
            <br/>
            <br/>
            Safed Musli also works as a great <b>Rasayana (rejuvenator)</b>.This helps to maintain the overall good
            health and immunity of an individual.
            <br/>
            <br/>
            Needless to say, safed Musli is a kind of herb which is <b>loaded with multiple health benefits</b> and
            properties that helps those who consume it, lead a well-rounded life.
        </p>

        <ImageBreaker image={Images.ingredient.safedBenefits} to={shopUrl} buttonText={'SHOP'}/>

        <br/>
        <br/>

        <p className="text-center font-weight-bold">
            <span>To know more about the benefits of Safed Musli, </span>
            <span className="ingredient-blog-links"
                  onClick={() => openLinkOnNewTab('/blog/What-are-the-benefits-of-Safed-Musli')}>click here</span>
        </p>
    </AccordionCard>
]

export const accordionList3 = [
    <AccordionCard key={'0'} title="How does Safed Musli help in bodybuilding?">
        <p>
            Bodybuilding is an extremely coveted practice. All over the world, individuals perform this activity for
            their mental and physical well being, and also for competitive sport. This practice is not only limited to
            one’s personality development, but also aids individuals to lead a disease free life.
            <br/>
            <br/>
            By <b>improving one’s diet, practising regular yoga, exercising and taking help of Ayurvedic herbs like
            Safed Musli</b>, one can naturally improve their personal health.
            <br/>
            <br/>
            Men often think, why not take the help of supplements that are used by quite a few fellow body builders? The
            catch here is that <b>chemical supplements are not always healthy</b> and can have detrimental effects on
            the body. Many of them also have artificial chemicals mixed into it. <b>Safed Musli</b> on the other hand,
            can definitely act as a supplement, but is entirely <b>natural in its nature</b>.
            <br/>
            <br/>
            Now, how does one distinguish between a supplement with artificial additives and a natural one? Consider
            this. Any supplement provided to you by a trusted and certified Ayurvedic practitioner will always remain
            safe and beneficial for your health. Besides this, notice and study the ingredients of any particular
            supplement to see if it contains all naturally extracted herbs.
            <br/>
            <br/>
            In case an individual’s physical make up is thin and lean, it is required for them to obtain proper
            nourishment via food or food supplements. In this regard, Safed Musli can help provide nourishment and
            strength due to its <b>Balya (strength-providing) property</b>.
            <br/>
            <br/>
            This aids the <b>enhancement of the muscular system</b> leading to a healthy muscle build-up and an
            attractive physique. This process of providing internal strength and nourishment to various body parts
            through Safed Musli is completely healthy and also prevents accumulation of any superficial fat, which can
            arise due to the consumption of mixed chemical supplements.
            <br/>
            <br/>
            Since Safed Musli is efficient in facilitating urine production, it helps in the removal of excessive waste
            material from the body that can eventually become an obstruction in body building practices.
            <br/>
            <br/>
            Overall, <b>Safed Musli can help individuals to realise their body building aspirations</b> and aid them to
            gain a <b>strong physique</b> and an <b>aesthetic body</b>. Its health benefits and rare chances of side
            effects have made it a much coveted herb.
        </p>
        <p className="text-center font-weight-bold">
            <span>To know more about how Safed Musli helps bodybuilders, </span>
            <span className="ingredient-blog-links"
                  onClick={() => openLinkOnNewTab('/blog/How-does-Safed-Musli-help-in-body-building')}>click here</span>
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="References">
        <ul>
            <li>
                <a href="http://phcogrev.com/sites/default/files/PhcogRev-3-5-154.pdf" target="_blank">
                    http://phcogrev.com/sites/default/files/PhcogRev-3-5-154.pdf
                </a>
            </li>
            <li>
                <a href="https://www.researchgate.net/publication/329712149_Safed_musli_chlorophytum_borivilianum"
                   target="_blank">
                    https://www.researchgate.net/publication/329712149_Safed_musli_chlorophytum_borivilianum
                </a>
            </li>
            <li>
                <a href="http://www.ijpmr.org/pdf/1-Chlorophytum-borivilianum-Safed-musli-A-Vital-Herbal-Drug.pdf"
                   target="_blank">
                    http://www.ijpmr.org/pdf/1-Chlorophytum-borivilianum-Safed-musli-A-Vital-Herbal-Drug.pdf
                </a>
            </li>
            <li>
                Padma Shri Prof.Chunekar K.C.(com),Dr. G.S. Pandey(ed). Bhavprakash Nighantu.2008: 377-378
            </li>
            <li>
                Prof. Sharma P.V. Dravyaguna Vigyana, (vol 2). 2019:559-560
            </li>
        </ul>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]
