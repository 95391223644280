// External Imports
import React from 'react'
import PropTypes from 'prop-types'
// My Imports
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText'

export default class CategoryBreaker extends React.Component {
    static propTypes = {
      image: PropTypes.string,
      text: PropTypes.string,
      to: PropTypes.string,
      buttonText: PropTypes.string
    };

    static defaultProps = {
      buttonText: 'TAKE_QUIZ'
    };

    render () {
      const { image, text, to, buttonText, ...props } = this.props

      return (
            <div className="category-img-breaker">
                <img src={image}
                     alt={text}/>
                <h2>{text}</h2>
                <Button variant={'secondary'} to={to} className="category-img-breaker-btn">
                    <LText k={buttonText}/></Button>
            </div>
      )
    }
}
