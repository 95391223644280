// eslint-disable-next-line no-unused-vars
/* global require */
export const DEBUG = process.env.DEBUG
export const HOST = process.env.HOST
export const RAZORPAY_APP_KEY = process.env.RAZORPAY_APP_KEY
export const CONFIG = process.env.CONFIG || 'web'
export const NODE_ENV = process.env.NODE_ENV

export const ACCESS = 'access'
export const REFRESH = 'refresh'
export const ACCESS_EXPIRE = 'access_expire'
export const ACCESS_EXPIRE_TIME = { value: 1, unit: 'days' }
export const USER = 'user'
export const LANGUAGE = 'language'
export const WEBSITE_HOST = 'https://misters.in'
export const NOTIFICATION_ENABLED = 'notification_enabled'
export const NOTIFICATION_REQUEST_TIME = {
  name: 'notification_request_time',
  value: 1,
  unit: 'days'
}

export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export const PRODUCTS = {
  AYURJOSH: 'ayurjosh',
  AYURPERFORM: 'ayurperform',
  AYURBOLD: 'ayurbold',
  AYURIMMUNITY: 'ayurimmunity',
  AYURHAIRPACK: 'ayurhairpack'
}

export const Meta = {
  title: 'Misters',
  description: "Misters.in is a men's confidence company.",
  image: 'https://misters.in/web-assets/images/favicon.png',
  image_type: 'image/png',
  url: 'https://misters.in',
  path: '',
  site_name: 'misters.in',
  site: 'misters.in',
  username: 'misters.in'
}

export const fieldTypes = {
  TEXT: 'text',
  EMAIL: 'email',
  INTEGER: 'integer',
  FLOAT: 'float',
  BOOL: 'bool',
  FILE: 'file',
  CHOICE: 'choice',
  MULTIPLE_CHOICE: 'multiple_choice',
  READ_ONLY_TEXT: 'read_only_text',
  DATE: 'date',
  LOADING: 'loading',
  HEIGHT: 'height',
  STAR: 'star',
  RATING: 'rating'
}

export const documentTypes = {
  REPORT: 'report',
  PRESCRIPTION: 'prescription',
  VITAL: 'vital',
  NOTE: 'note'
}

export const Images = {
  pages: {
    clinic: '/web-assets/images/pages/clinic.jpg',
    rajpurClinic: '/web-assets/images/pages/rajput-clinic.jpg'
  },
  home1: '/web-assets/images/home/quiz1.svg',
  home2: '/web-assets/images/home/quiz2.png',
  home3: '/web-assets/images/home/blog.png',
  home4: '/web-assets/images/home/josh.png',
  home5: '/web-assets/images/home/hairfall.png',
  home6: '/web-assets/images/home/pe.png',
  home7: '/web-assets/images/home/questions.png',
  home8: '/web-assets/images/home/doctor-consultation.svg',
  quiz1: '/web-assets/images/home/quiz3.png',
  homeRatingIcon: '/web-assets/images/home/ratings.svg',
  about: '/web-assets/images/home/about_us.png',
  library: '/web-assets/images/home/library.png',
  thankyou: '/web-assets/images/home/thankyou.png',
  community: '/web-assets/images/home/community.jpg',

  money: '/web-assets/images/checkout/money.png',
  logo: {
    x1: '/web-assets/images/logo/1x.png',
    x2: '/web-assets/images/logo/2x.png',
    x3: '/web-assets/images/logo/3x.png',
    big1x: '/web-assets/images/logo/big.svg',
    big2x: '/web-assets/images/logo/big.svg',
    big3x: '/web-assets/images/logo/big.svg',
    whitebig1x: '/web-assets/images/logo/whitebig1x.png',
    white: '/web-assets/images/logo/white.svg',
    mlogoWhite: '/web-assets/images/logo/mlogo-white.svg',
    facebook: '/web-assets/images/logo/facebook.png',
    linkedin: '/web-assets/images/logo/linkedin.png',
    youtube: '/web-assets/images/logo/youtube.png'
  },
  icons: {
    aips: '/web-assets/images/icons/aips.png',
    srq: '/web-assets/images/icons/srq.png',
    legacy: '/web-assets/images/icons/legacy.png',
    delivery: '/web-assets/images/icons/delivery.png',
    thumb: '/web-assets/images/icons/thumb.png',
    shield: '/web-assets/images/icons/shield.png',
    flag: '/web-assets/images/icons/flag.png',
    doctor: '/web-assets/images/icons/doctor.png',
    scientific: '/web-assets/images/icons/scientific.png',
    cart: '/web-assets/images/icons/cart.svg',
    wishlist: '/web-assets/images/icons/wishlist.svg',
    user: '/web-assets/images/icons/user.png',
    iconBody: '/web-assets/images/icons/icon-body.svg',
    iconHand: '/web-assets/images/icons/icon-hand.svg',
    iconApple: '/web-assets/images/icons/icon-apple.svg',
    iconFace: '/web-assets/images/icons/icon-face.svg',
    iconBrain: '/web-assets/images/icons/icon-brain.svg',
    iconArm: '/web-assets/images/icons/icon-arm.svg',
    iconHeart: '/web-assets/images/icons/icon-heart.svg',
    iconPipe: '/web-assets/images/icons/icon-pipe.svg',
    iconLight: '/web-assets/images/icons/icon-light.svg',
    hairOne: '/web-assets/images/icons/hair-one.png',
    hairTwo: '/web-assets/images/icons/hair-two.png',
    hairThree: '/web-assets/images/icons/hair-three.png',
    amazon: '/web-assets/images/icons/amazon.svg',
    flipkart: '/web-assets/images/icons/flipkart.svg',
    oneMg: '/web-assets/images/icons/1mg.svg'
  },
  logo2: '/web-assets/images/logo2.png',
  amazonLogo: '/web-assets/images/amazon-logo.jpg',
  logoSmall: '/web-assets/images/favicon.png',
  logoTransparent: '/web-assets/images/logo_transparent.png',
  logoTransparentSmall: '/web-assets/images/logo_transparent_small.png',
  logoTransparentSmallCropped: '/web-assets/images/logo_transparent_small_cropped.png',
  phone: '/web-assets/images/phone.png',

  emptyCart: '/web-assets/images/empty_cart.png',
  pdGraph: '/web-assets/images/pd.png',
  main1: '/web-assets/images/main5.png',
  whatsApp: '/web-assets/images/whats-app.png',
  paymentMethods: '/web-assets/images/methods.png',
  ayurveda: '/web-assets/images/ayurveda.jpg',

  analysis1: '/web-assets/images/analysis-1.png',
  analysis2: '/web-assets/images/analysis-2.png',
  analysis3: '/web-assets/images/analysis-3.png',
  analysis4: '/web-assets/images/analysis-4.png',

  error404: '/web-assets/images/error404.png',
  aboutMob: '/web-assets/images/about-us1x1.png',
  aboutWeb: '/web-assets/images/about-us4x1.png',

  cgPme1: '/web-assets/images/pme/b1.jpg',
  cgPme2: '/web-assets/images/pme/b2.jpg',
  cgPme3: '/web-assets/images/pme/b3.jpg',
  cgPme4: '/web-assets/images/pme/b4.jpg',
  cgPme5: '/web-assets/images/pme/b5.jpg',
  cgEd1: '/web-assets/images/ed/b1.jpg',
  cgEd2: '/web-assets/images/ed/b2.jpg',
  cgEd3: '/web-assets/images/ed/b3.jpg',
  cgEd4: '/web-assets/images/ed/b4.jpg',

  edHeader: '/web-assets/images/ed-header.jpg',
  edSection2: '/web-assets/images/ed-section-2.jpg',
  therapist: '/web-assets/images/therapist.png',

  review_profile: '/web-assets/images/review_profile.jpg',
  like: '/web-assets/images/like.png',

  categories: {
    patternBaldness: '/web-assets/images/categories/pattern-baldness.jpg',
    stressedMan: '/web-assets/images/categories/stressed-man.jpg',
    nasyaTherapy: '/web-assets/images/categories/nasya-therapy.jpg',
    minoxidil: '/web-assets/images/categories/minoxidil.jpg',
    finasteride: '/web-assets/images/categories/finasteride.png'
  },
  testimonial: {
    actor1: '/web-assets/images/testimonial/actor1.png',
    actor2: '/web-assets/images/testimonial/actor2.png',
    actor3: '/web-assets/images/testimonial/actor3.png'
  },
  orders: {
    order: '/web-assets/images/orders/order.png',
    report: '/web-assets/images/orders/report.png',
    address: '/web-assets/images/orders/address.png'
  },
  orderTracking: {
    created: '/web-assets/images/orders/created.png',
    confirm: '/web-assets/images/orders/confirm.png',
    packed: '/web-assets/images/orders/packed.png',
    shipped: '/web-assets/images/orders/shipped.png',
    delivered: '/web-assets/images/orders/delivered.png'
  },
  team: {
    suhas: '/web-assets/images/team/500x500/suhas.png',
    gaurav: '/web-assets/images/team/500x500/gaurav.png',
    saurabh: '/web-assets/images/team/500x500/saurabh.png',
    aditya: '/web-assets/images/team/500x500/aditya.png',
    ashima: '/web-assets/images/team/500x500/ashima.png',
    pavan: '/web-assets/images/team/500x500/pavan.png',
    sabena: '/web-assets/images/team/500x500/sabena.png',

    ashish: '/web-assets/images/team/500x500/ashish.png',
    mukul: '/web-assets/images/team/500x500/mukul.png',
    raghuvansh: '/web-assets/images/team/500x500/raghuvansh.png',
    rajiv: '/web-assets/images/team/500x500/rajiv.png',
    rishabh: '/web-assets/images/team/500x500/rishabh.png',
    krishna: '/web-assets/images/team/500x500/krishna.jpg'
  },
  ingredient: {
    ingredientsHead: '/web-assets/images/ingredients/ingredients-head.jpg',

    amlaDescription: '/web-assets/images/ingredients/amla-description.jpg',
    amlaBenefits: '/web-assets/images/ingredients/amla-benefits.jpg',

    ashwagandhaDescription: '/web-assets/images/ingredients/ashwagandha-description.jpg',
    ashwagandhaBenefits: '/web-assets/images/ingredients/ashwagandha-benefits.jpg',

    kaunchBeejDescription: '/web-assets/images/ingredients/kaunch-beej-description.jpg',
    kaunchBeejBenefits: '/web-assets/images/ingredients/kaunch-beej-benefits.jpg',

    shilajitDescription: '/web-assets/images/ingredients/shilajit-description.jpg',
    shilajitBenefits: '/web-assets/images/ingredients/shilajit-benefits.jpg',

    gokshuraDescription: '/web-assets/images/ingredients/gokshura-description.jpg',
    gokshuraBenefits: '/web-assets/images/ingredients/gokshura-benefits.jpg',

    kesarDescription: '/web-assets/images/ingredients/kesar-description.jpg',
    kesarBenefits: '/web-assets/images/ingredients/kesar-benefits.jpg',

    shatavariDescription: '/web-assets/images/ingredients/shatavari-description.jpg',

    safedDescription: '/web-assets/images/ingredients/safed_description.jpg',
    safedBenefits: '/web-assets/images/ingredients/safed_benefits.jpg',

    jaiphalDescription: '/web-assets/images/ingredients/jaiphal_description.jpg',
    jaiphalBenefits: '/web-assets/images/ingredients/jaiphal_benefits.jpg',

    bhringrajDescription: '/web-assets/images/ingredients/bhringraj_description.jpg',
    bhringrajBenefits: '/web-assets/images/ingredients/bhringraj_benefits.jpg',

    hair_mask: '/web-assets/images/ingredients/hair_mask.png',
    hair_growth_cycle: '/web-assets/images/ingredients/hair_growth_cycle.png',
    hair_growth_cycle_2: '/web-assets/images/ingredients/hair_growth_cycle_2.png',
    hair_mask_anti: '/web-assets/images/ingredients/hair_mask_anti.jpg',
    hair_mask_antioxidant: '/web-assets/images/ingredients/hair_mask_antioxidant.jpg',
    hair_mask_arnica: '/web-assets/images/ingredients/hair_mask_arnica.jpg',

    aips_srq: '/web-assets/images/ingredients/aips_srq.png',

    joshIngredients: '/web-assets/images/ingredients/josh-ingredients.png',
    boldIngredients: '/web-assets/images/ingredients/bold-ingredients.png',
    performIngredients: '/web-assets/images/ingredients/perform-ingredients.png',
    hairPackIngredients: '/web-assets/images/ingredients/hairpack-ingredients.png',
    immunityIngredients: '/web-assets/images/ingredients/immunity-ingredients.png',
    shilajitproIngredients: '/web-assets/images/ingredients/shilajitpro-ingredients.jpg',
    ashwagandha: '/web-assets/images/ingredients/ashwagandha.jpg',
    kesar: '/web-assets/images/ingredients/kesar.jpg',
    shilajit: '/web-assets/images/ingredients/shilajit.png',
    gokshura: '/web-assets/images/ingredients/gokshura.jpg',
    kaunchbeej: '/web-assets/images/ingredients/kaunchbeej.jpg',
    neem: '/web-assets/images/ingredients/neem.jpg',
    bhringaraj: '/web-assets/images/ingredients/bhringaraj.png',
    amla: '/web-assets/images/ingredients/amla.png',
    giloy: '/web-assets/images/ingredients/giloy.jpg'
  },

  bold: {
    bold: '/web-assets/images/bold/bold.jpg',
    bold1: '/web-assets/images/bold/bold1.jpg',
    physiology_erection: '/web-assets/images/bold/physiology_erection.png',
    ashwagandha_mechanism1: '/web-assets/images/bold/ashwagandha_mechanism1.png',
    ashwagandha_mechanism2: '/web-assets/images/bold/ashwagandha_mechanism2.png',
    ashwagandha_mechanism3: '/web-assets/images/bold/ashwagandha_mechanism3.png',
    ashwagandha_mechanism4: '/web-assets/images/bold/ashwagandha_mechanism4.png',
    gokshura_mechanism1: '/web-assets/images/bold/gokshura_mechanism1.png',
    shilajit_mechanism1: '/web-assets/images/bold/shilajit_mechanism1.png',
    shatavari: '/web-assets/images/bold/shatavari.png',
    mastaki_mechanism1: '/web-assets/images/bold/mastaki_mechanism1.png',
    kesar: '/web-assets/images/bold/kesar.png',
    jaiphal: '/web-assets/images/bold/jaiphal.png',
    vidarikand: '/web-assets/images/bold/vidarikand.png',
    akalkara: '/web-assets/images/bold/akalkara.png',
    lavang: '/web-assets/images/bold/lavang.png',
    taj: '/web-assets/images/bold/taj.png'
  },

  media: {
    media: '/web-assets/images/media/media.jpg',
    businessLine: '/web-assets/images/media/business-line.jpg',
    inc42: '/web-assets/images/media/inc42.png',
    outlook: '/web-assets/images/media/outlook.png',
    rajasthanPatrika: '/web-assets/images/media/rajasthan-patrika.png',
    rediff: '/web-assets/images/media/rediff.jpg',
    theEconomicTimes: '/web-assets/images/media/the-economic-times.png',
    tnie: '/web-assets/images/media/newspaper.png',
    morningStandard: '/web-assets/images/media/the-morning-standard.png',
    onlymyhealth: '/web-assets/images/media/onlymyhealth.png',
    nuffood: '/web-assets/images/media/nuffood.jpeg',
    healthvision: '/web-assets/images/media/healthvision.png',
    blogger: '/web-assets/images/media/blogger.png',
    digitalmedia: '/web-assets/images/media/digitalmedia.png',
    mediabulletins: '/web-assets/images/media/mediabulletins.png',
    businessnews: '/web-assets/images/media/businessnews.png',
    healthwire: '/web-assets/images/media/healthwire.jpeg',
    gurgaonKesari: '/web-assets/images/media/gurgaon_kesari.png'
  },

  intimacy: {
    intimacy: '/web-assets/images/intimacy/intimacy.jpg',
    intimacy1: '/web-assets/images/intimacy/intimacy1.jpg',
    intimacy_main: '/web-assets/images/intimacy/intimacy_main.png'
  },
  checkout: {
    delivery: '/web-assets/images/checkout/delivery.png',
    shipment: '/web-assets/images/checkout/shipment.png',
    packaging: '/web-assets/images/checkout/packaging.png',
    shipment_process: '/web-assets/images/checkout/shipment_process.png',
    ayurvedic_analysis: '/web-assets/images/checkout/ayurvedic_analysis.png',
    address_bar: '/web-assets/images/checkout/address_bar.png',

    cart: '/web-assets/images/checkout/cart.svg',
    confetti: '/web-assets/images/checkout/confetti.svg',
    fill_19: '/web-assets/images/checkout/fill_19.svg',
    success_icon: '/web-assets/images/checkout/success_icon.svg',
    thinking_man: '/web-assets/images/checkout/thinking_man.jpg'
  },
  report: {
    totalScoreBackground: '/web-assets/images/report/total-score-background.png',
    totalScoreBar: '/web-assets/images/report/total-score-bar.svg',
    totalScoreButton: '/web-assets/images/report/total-score-button.svg',
    totalScoreMinus: '/web-assets/images/report/total-score-minus.svg',
    totalScorePlus: '/web-assets/images/report/total-score-plus.svg',

    desireScoreFlame: '/web-assets/images/report/desire-score-flame.svg',
    desireScoreSemiFlame: '/web-assets/images/report/desire-score-semi-flame.svg',
    desireScoreActiveFlame: '/web-assets/images/report/desire-score-active-flame.svg',

    erectionScoreArrow: '/web-assets/images/report/erection-score-arrow.svg',
    erectionScoreActiveArrow: '/web-assets/images/report/erection-score-active-arrow.svg',

    bmi_left: '/web-assets/images/report/bmi_left.png',
    bmi_right: '/web-assets/images/report/bmi_right.png',
    ayur_bmi_left: '/web-assets/images/report/ayur_bmi_left.png',
    ayur_bmi_right: '/web-assets/images/report/ayur_bmi_right.png',

    lifeStyleScoreBackground: '/web-assets/images/report/life-style-score-background.png',

    ejaculationScoreTimer: '/web-assets/images/report/ejaculation-score-timer.svg',

    productRibbon: '/web-assets/images/report/product-ribbon.svg',

    apple: '/web-assets/images/report/apple.svg',
    body: '/web-assets/images/report/body.svg',
    caret: '/web-assets/images/report/caret.svg',
    cycle: '/web-assets/images/report/cycle.svg',
    dumbbell: '/web-assets/images/report/dumbbell.svg',
    orange: '/web-assets/images/report/orange.svg',
    watch: '/web-assets/images/report/watch.svg',
    wineGlass: '/web-assets/images/report/wine-glass.svg',

    doshaSummaryCardImage: '/web-assets/images/report/dosha-summary-card-image.svg'

  },

  feedback: {
    feedbackStart: '/web-assets/images/feedback/feedback_start.png',
    feedbackEnd: '/web-assets/images/feedback/feedback_end.png'
  },

  thinkingsex: {
    thinkingSexBanner: '/web-assets/images/thinkingsex/thinking_sex_banner.jpg',
    thinkingSexHead: '/web-assets/images/thinkingsex/thinking_sex_head.png',
    fright: '/web-assets/images/thinkingsex/fright.jpg',
    infertility: '/web-assets/images/thinkingsex/infertility.jpg',
    mardaani: '/web-assets/images/thinkingsex/mardaani.jpg',
    sex_education: '/web-assets/images/thinkingsex/sex_education.jpg',
    sex: '/web-assets/images/thinkingsex/sex.jpg',
    michel: '/web-assets/images/thinkingsex/michel.jpg',
    ED: '/web-assets/images/thinkingsex/ED.jpg',
    stethoscope: '/web-assets/images/thinkingsex/stethoscope.jpg'
  },

  therapy: {
    joshBanner: '/web-assets/images/therapy/josh-banner.png',
    boldBanner: '/web-assets/images/therapy/bold-banner.png',
    performBanner: '/web-assets/images/therapy/perform-banner.png',
    hairPackBanner: '/web-assets/images/therapy/haripack-banner.png',
    immunityBanner: '/web-assets/images/therapy/immunity-banner.png',
    shilajitproBanner: '/web-assets/images/therapy/shilajitpro1.png',
    enhanceBanner: '/web-assets/images/therapy/enhance-front-with-box.jpeg',

    tablet: '/web-assets/images/therapy/tablet.png',
    night: '/web-assets/images/therapy/night.png',
    dinner: '/web-assets/images/therapy/dinner.png',
    spoon: '/web-assets/images/therapy/spoon.png',
    bowl: '/web-assets/images/therapy/bowl.png',
    watch: '/web-assets/images/therapy/watch.png',
    drop: '/web-assets/images/therapy/drop.svg',

    ayurvedaWork: '/web-assets/images/therapy/ayurveda-work.png'
  }
}

export const otherAssets = {
  investorRelations: '/web-assets/pages/investors-relations.html',
  refundAndCancellationPolicy: '/web-assets/pages/refund-and-cancellation-policy.html',
  privacyPolicy: '/web-assets/pages/privacy-policy.html',
  termsAndCondition: '/web-assets/pages/terms-and-conditions.html'
}

export const PRIMARY_COLOR = 'rgb(246,92,98)'

export const getRatingColor = (rating) => {
  rating = Math.ceil(rating)
  if (rating < 1) rating = 1
  if (rating > 10) rating = 10

  const ratingColors = [
    '#FF0000',
    '#FF6600',
    '#FF9900',
    '#dfbe00',
    '#d8d400',
    '#d4d400',
    '#C3DE02',
    '#6EAF05',
    '#4A9B07',
    '#0E7909'
  ]

  return ratingColors[rating - 1]
}

export const ORDER_STATUS = {
  FAILED: { color: '#FF0000', status: 'failed' },
  PENDING: { color: '#FF6600', status: 'pending' },
  CREATED: { color: '#FF9900', status: 'created' },
  CONSULTATION_PENDING: { color: '#dfbe00', status: 'consultation_pending' },
  CANCELED: { color: '#FF0000', status: 'canceled' },
  CONFIRM: { color: '#d8d400', status: 'confirm' },
  PACKED: { color: '#C3DE02', status: 'packed' },
  SHIPPED: { color: '#6EAF05', status: 'shipped' },
  DELIVERED: { color: '#0E7909', status: 'delivered' },
  RETURN_INITIATED: { color: '#6EAF05', status: 'return_initiated' },
  RETURN_IN_TRANSIT: { color: '#4A9B07', status: 'return_in_transit' },
  RETURN_RECEIVED: { color: '#4A9B07', status: 'return_received' },
  REFUNDED: { color: '#4A9B07', status: 'refunded' }
}

// export const PHONE_NUMBER = '18001219555'
export const PHONE_NUMBER = '9513392590'

export const SEX_QUIZ_BOT_FORM_ID = 'sex_quiz_ver28v9'
export const SEX_QUIZ_FIELD_ID = 'age'

export const PORN_QUIZ_BOT_FORM_ID = 'porn_quiz'
export const PORN_QUIZ_FIELD_ID = 'age_check'

export const HAIRFALL_FIELD_ID = 'hairfall:1c'
export const ED_FIELD_ID = 'ed_opening_age:3c'
export const PME_FIELD_ID = 'pe_opening:3c'
export const FEEDBACK_FIELD_ID = 'start'
export const FEEDBACK_BOT_FORM_ID = 'feedback'

export const zTableX = [-3.4, -3.3, -3.2, -3.1, -3, -2.9, -2.8, -2.7, -2.6, -2.5, -2.4, -2.3, -2.2, -2.1, -2, -1.9, -1.8, -1.7, -1.6, -1.5, -1.4, -1.3, -1.2, -1.1, -1, -0.9, -0.8, -0.7, -0.6, -0.5, -0.4, -0.3, -0.2, -0.1, 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3, 3.1, 3.2, 3.3, 3.4]

export const responsive = {
  0: {
    items: 1
  },
  767: {
    items: 2
  },
  967: {
    items: 3
  }
}

export const productResponsive = {
  0: {
    items: 1
  },
  767: {
    items: 2
  },
  991: {
    items: 3
  }
}

export const productBgColor = ['#FF0000', '#FC4B53', '#FF878D']

export const subscriptionPeriod = {
  yearly: { label: 'YEARLY', months: 12 },
  monthly: { label: 'MONTHLY', months: 1 },
  weekly: { label: 'WEEKLY', months: 0.25 },
  daily: { label: 'DAILY', months: 0.34 }
}

export const states = [
  'Andra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Orissa',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telagana',
  'Tripura',
  'Uttaranchal',
  'Uttar Pradesh',
  'West Bengal'
]

export const languageOptions = [
  { label: 'English', value: 'english', code: '', db_label: '' },
  { label: 'Hindi', value: 'hindi', code: 'hi', db_label: '_hi' },
  { label: 'Telugu', value: 'telugu', code: 'te', db_label: '_te' },
  { label: 'Kannada', value: 'kannada', code: 'kn', db_label: '_kn' },
  { label: 'Bengali', value: 'bengali', code: 'be', db_label: '_be' },
  { label: 'Marathi', value: 'marathi', code: 'ma', db_label: '_ma' },
  { label: 'Tamil', value: 'tamil', code: 'ta', db_label: '_ta' },
  { label: 'Gujarati', value: 'gujarati', code: 'gu', db_label: '_gu' }
]

export const trackPurchase = (order) => {
  if (NODE_ENV === 'production') {
    try {
      // eslint-disable-next-line no-undef
      dataLayer.push({
        event: 'ecomm_event',
        transactionId: `${order.cid}`, // Transaction ID - this is normally generated by your system.
        transactionAffiliation: '', // Affiliation or store name
        transactionTotal: order.order_total / 100, // Grand Total
        transactionTax: '0', // Tax.
        transactionShipping: '0', // Shipping cost
        transactionProducts: order.order_items.map(obj => ({
          sku: obj.product_id,
          name: obj.name,
          category: '',
          price: obj.total_price / obj.quantity,
          quantity: obj.quantity.toString()
        }))
      })
      // eslint-disable-next-line no-undef
      fbq(
        'track',
        'Purchase',
        {
          currency: 'INR',
          value: order.order_total / 100,
          contents: order.order_items.map(obj => ({
            id: obj.product_id,
            quantity: obj.quantity.toString()
          })),
          content_type: 'product'
        }
      )
    } catch (e) {
      console.warn(e.message)
    }
  }
}

export const paymentTypes = {
  online: 'online',
  cod: 'cod'
}

export const paymentMethods = {
  card: 'card',
  upi: 'upi',
  wallet: 'wallet',
  netbanking: 'netbanking',
  emi: 'emi'
  // paylater: 'paylater',
  // cardless_emi: 'cardless_emi',
}

export const EMI_COEFFICIENT = 20.62
