import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2, accordionList3 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import getArticleSchema from '../../../services/SchemaService/getArticleSchema'
import getFaqSchema from '../../../services/SchemaService/getFaqSchema'

const faqs = [
  {
    question: 'What is Shilajit?',
    answer: 'Shilajit is an age-old ingredient (rasayana compound) of Ayurvedic medicine. Shilajit gets its name from the Sanskrit word ‘Silajitu’, where ‘sila’ means endowed with the qualities of a mountain or rock, and ‘jitu’ means gum, lac, or other tar-like materials. This compound is formed by the decay of some particular plant matters by microorganisms, present in the soil, in mountainous terrains. It often takes more than hundreds of years for the substance to be formed and during the process, plant matter along with minerals break down giving rise to a blackish-brown, tar-like, sticky substance. This is also why researchers call it a millenary product of nature. Shilajit also contains a number of vitamins and amino acids.'
  },
  {
    question: 'What are some other names for Shilajit?',
    answer: 'The colour of this natural resource can vary from yellowish-brown to pitch-black, depending on its place of origin and also on the ratio of its compositions. However, in Indian Ayurvedic medicine, the black variety is believed to be the most powerful and effective. The fact that it seeps out of the cracks formed in the mountains due to the heat generated from the sun, has given the substance names like mineral oil, stone oil, Mountain Blood and rock sweat. Shilajit is also known as mineral pitch, mineral wax or black asphaltum in English. In local languages, it is known by hundreds of different names in different countries e.g. Asphaltum punjabianum, mimie, mummiyo, badha-naghay, shargai, dorobi, barahshin, tasmayi, chao-tong, wu ling zhi etc. The substance found by indigenous people living in the Altai Mountains which closely resembles Shilajit, is called by names like Mumijo, Mumiyo, Mumie, momio, myemu or Mummy. This is a play on the Greek word ‘mumie’, meaning ’to preserve the body’.'
  }
  ]

class Shilajit extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props
    const path = `${match.params.language}/ingredient/shilajit/`

    const scripts = [
      getArticleSchema({
        title: 'Stamina, sex, Shilajit, bodybuilding. Misters usage, dosage, benefits.',
        description: 'Learn about Shilajit, a high quality Ayurvedic herb, on the Misters Ayurveda page. Your complete Misters Ayurvedic ingredient guide has collated expert information on Shilajit’s usage, dosage, benefits, description, alternative names and constituents. Shilajit has vast, scientifically proven benefits. Aging process control, increase in testosterone levels, treatment of Anemia and improved heart health are some results of its usage. It boosts sperm production, promotes cognitive functions and energises the body. Rich in vitamins, it is found in the Himalayas, Tibet, Afghanistan and many more areas. Know your Ayurvedic ingredients to understand Misters products better.',
        images: ['https://misters.in/web-assets/images/ingredients/safed_benefits.jpg'],
        keywords: 'musli, safed musli, safed musli, chlorophytum borivilianum,natural medicine, safed musli benefits',
        url: path
      }),
      getFaqSchema({
        data: faqs,
        url: path
      })
    ]

    return (
      <Page meta={{
        title: 'Stamina, sex, Shilajit, bodybuilding. Misters usage, dosage, benefits.',
        path: path,
        description: 'Learn about Shilajit, a high quality Ayurvedic herb, on the Misters Ayurveda page. Your complete Misters Ayurvedic ingredient guide has collated expert information on Shilajit’s usage, dosage, benefits, description, alternative names and constituents. Shilajit has vast, scientifically proven benefits. Aging process control, increase in testosterone levels, treatment of Anemia and improved heart health are some results of its usage. It boosts sperm production, promotes cognitive functions and energises the body. Rich in vitamins, it is found in the Himalayas, Tibet, Afghanistan and many more areas. Know your Ayurvedic ingredients to understand Misters products better.',
        image: 'https://misters.in/web-assets/images/ingredients/shilajit-description.jpg'
      }} script={scripts}>
        <div className="ingredient-header-img" style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
          <div className="ingredient-header-bg-drop"/>
          <div className="ingredient-header-content">
            <h1>‘S’ for sex, stamina and Shilajit</h1>
          </div>
        </div>
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>What is Shilajit?</h2>
              <p>
                Shilajit is an age-old ingredient (rasayana compound) of <b>Ayurvedic medicine</b>.
                Shilajit gets its name from the Sanskrit word ‘Silajitu’, where <b>‘sila’ means
                endowed</b> with the qualities of a mountain or rock, and <b>‘jitu’ means gum,
                lac,</b> or other tar-like materials.
                <br/>
                <br/>
                This compound is formed by the decay of some particular plant matters by microorganisms,
                present in the soil, in mountainous terrains. It often takes more than hundreds of years
                for the substance to be formed and during the process, plant matter along with minerals
                break down giving rise to a blackish-brown, tar-like, sticky substance. This is also why
                researchers call it a millenary product of nature. Shilajit also contains a number of
                vitamins and amino acids.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center py-5">
                <iframe className="youtubeIframe"
                        src="https://www.youtube.com/embed/YDrVHRkzX64?loop=1&playlist=YDrVHRkzX64&rel=0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen frameBorder="0"/>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row pt-5">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>What are some other names for Shilajit?</h2>
              <p>
                The colour of this natural resource can vary from yellowish-brown to pitch-black,
                depending on its place of origin and also on the ratio of its compositions. However, in
                Indian Ayurvedic medicine, the black variety is believed to be the most powerful and
                effective. The fact that it seeps out of the cracks formed in the mountains due to the
                heat generated from the sun, has given the substance names like <b>mineral oil, stone
                oil, Mountain Blood and rock sweat</b>.
                <br/>
                <br/>
                Shilajit is also known as <b>mineral pitch, mineral wax or black asphaltum</b> in
                English. In local languages, it is known by hundreds of different names in different
                countries e.g. <b>Asphaltum punjabianum, mimie, mummiyo, badha-naghay, shargai, dorobi,
                barahshin, tasmayi, chao-tong, wu ling zhi etc</b>.
                <br/>
                <br/>
                The substance found by indigenous people living in the Altai Mountains which closely
                resembles Shilajit, is called by names like Mumijo, Mumiyo, Mumie, momio, myemu or
                Mummy. This is a play on the Greek word ‘mumie’, meaning ’to preserve the body’.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-md-10 offset-md-1 col-12">
              {accordionList1}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-md-10 offset-md-1 col-12">
              <ImageBreaker image={Images.ingredient.shilajitDescription} to={shopUrl}
                            buttonText={'SHOP'}/>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              {accordionList2}
            </div>
          </div>
        </div>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center py-5">
                <iframe className="youtubeIframe"
                        src="https://www.youtube.com/embed/95TmfzSC5Og?loop=1&playlist=95TmfzSC5Og&rel=0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen frameBorder="0"/>
              </div>
            </div>
          </div>
        </div>

        <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              {accordionList3}
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

export default Shilajit
