// External Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '../../buttons/Button'
import StarRatings from 'react-star-ratings'

export default class StarInput extends Component {
    static propTypes = {
      handleSendClick: PropTypes.func.isRequired,
      hideNextButton: PropTypes.bool
    };

    static defaultProps = {
      hideNextButton: false
    };

    state = { star: 4 };

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
    }

    handleSend = (star) => {
      this.setState({ star: star })
      this.props.handleSendClick({ text: star })
    };

    render () {
      const { star } = this.state

      return (
            <div className='choice-item-container'>
                <small className="text-center">(select a rating)</small>
                <div className="choice-item-content">
                    <StarRatings
                        rating={star}
                        starDimension="35px"
                        starSpacing="10px"
                        starRatedColor="#FF0000"
                        isSelectable={true}
                        changeRating={this.handleSend}
                        numberOfStars={5}
                        name='rating'
                    />
                </div>
                {!this.props.hideNextButton && <div className="field-footer">
                    <div className="container d-flex justify-content-center">
                        <Button onClick={() => this.handleSend(star)}>Next</Button>
                    </div>
                </div>}
            </div>
      )
    }
}
