import React from 'react'

const language = {
  LANGUAGE_NAME: 'English',
  HOME: 'Home',
  CART: 'Cart',
  WISHLIST: 'Wishlist',
  SHOP: 'Shop',
  LEARN: 'Learn',
  LOGIN: 'Login',
  ACCOUNT: 'Account',
  PROFILE: 'Profile',
  ORDERS: 'Orders',
  LOGOUT: 'Logout',
  BLOG: 'Blog',
  LIBRARY: 'Library',
  QA: 'FAQs',
  ABOUT: 'About',
  EDIT: 'Edit',
  SAVE_ADDRESS: 'Save Address',
  SAVE_AND_CONTINUE: 'Continue to pay',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  DELIVERY_ADDRESS: 'Delivery Address',
  SUMMARY: 'Summary',
  CONFIRM_ORDER: 'Confirm Order',
  PAYMENT_OPTIONS: 'Payment Method',
  MAKE_PAYMENT: 'Make Payment',
  CREDIT_CARD: 'Credit Card',
  DEBIT_CARD: 'Debit Card',
  E_WALLETS: 'e-Wallets',
  CONTACT_US: 'CONTACT US',
  UPDATES: 'Updates',
  INFORMATION: 'INFORMATION',
  PURPOSE: 'Purpose',
  PHILOSOPHY: 'Philosophy',
  PREMATURE_EJACULATION: 'Premature Ejaculation',
  ERECTILE_DYSFUNCTION: 'Erectile Dysfunction',
  SIZE: 'Size',
  HAIRFALL: 'Hairfall',
  FOOD_SUPPLEMENTS: 'Food Supplements',
  CART_PRICE_INCLUDES_GST: 'This price includes GST',
  CART_FOOTER_LINE_ONE: 'No questions asked. 15 day return policy',
  CART_FOOTER_LINE_TWO: 'A doctor will confirm your medical history before shipping',
  CART_FOOTER_LINE_THREE: 'You can contact us at support@hfactorial.com or 09513392590 (Mon-Sat 10am-7pm IST)',
  CART_IS_EMPTY: 'Your cart is empty!',
  DUPLICATE_ORDER_TITLE: 'Looks like you have already ordered these items on {date} 👇',
  DUPLICATE_ORDER_MESSAGE: 'Do you want to edit your cart before confirming?',
  OOPS_YOU_HAVE_NOTHING_HERE: 'oops! you have nothing here',
  ORDER_PLACED_SUCCESSFULLY: 'Order placed successfully!',
  INPUT_NAME: 'Please enter your name',
  NAME: 'Name',
  DATE_OF_BIRTH: 'Date of birth',
  CONTINUE: 'Continue',
  LOGIN_SUCCESSFUL: 'Logged in successfully',
  WELCOME_BACK: 'Welcome Back!',
  PLEASE_LOGIN: 'Please login to your account',
  PLEASE_VERIFY_PHONE: 'Please verify your phone number',
  PHONE_NUMBER: 'Phone Number',
  ENTER_OTP: 'Enter OTP',
  RESEND_OTP: 'Resend OTP',
  GET_OTP_ON_CALL: 'Get OTP on call',
  SEND_OTP: 'SEND OTP',
  RESEND_OTP_TEXT: 'Wait for {time} seconds to resend OTP.',
  SUB_TOTAL: 'Sub Total',
  GST: 'GST',
  TOTAL: 'Total',
  NEXT: 'Next',
  PLACE_ORDER: 'Place Order',
  SHIPPING_ADDRESS: 'Shipping Address',
  ADDRESS: 'Address',
  CITY: 'City',
  STATE: 'State',
  ZIPCODE: 'Zipcode',
  INSTRUCTIONS: 'Instructions (dont ring door bell / right time to call)',
  PROCEED_TO_BUY: 'Enter address',
  YOUR_10_DIGIT_MOBILE_NUMBER: 'Your 10-digit mobile number',
  MY_PHONE_NUMBER_IS: 'My phone number is...',
  YOUR_OTP: 'Your OTP',
  MY_OTP_IS: 'My OTP is...',
  DELIVER_TO_THIS_ADDRESS: 'Deliver here',
  ADD_NEW_ADDRESS: 'Add new address',
  PLEASE_COMPLETE_YOUR_PROFILE: 'Please complete your profile',
  INCORRECT_OTP: 'Incorrect OTP',
  ADDRESS_LINE_ONE: 'Your Address and Mobile number will never be shared with anyone and will be used only for delivery purpose.',
  ADDRESS_LINE_TWO: 'The packaging will be discreet and will not have any description of the product.',
  ADDRESS_LINE_THREE: 'All your personal details will be encrypted on our server.',
  SELECT_ADDRESS: 'Select Address',
  CASH_ON_DELIVERY: 'Cash on Delivery',
  PAY_ONLINE: 'Net Banking / Credit Card / Debit Card / Wallet',
  MEDICAL_CONDITIONS: 'Medical Conditions',
  THE_MAN_GUARANTEE: 'The Misters Guarantee',
  GUARANTEE_TEXT: `Misters therapies have been handcrafted by highly trained experts, and each ingredient that goes
    in our products has been procured from the finest sources in the world, which is why we can
    confidently give you 100% quality assurance, and can back it with 100% refund* if you do not
    like our products.`,
  MONEY_BACK_GUARANTEE: '15 Days money back guarantee',
  MONEY_BACK_GUARANTEE_TEXT: 'No questions asked',
  MEDICALLY_VERIFIED: 'Medically Verified',
  MEDICALLY_VERIFIED_TEXT: 'Designed by top sexologist',
  FREE_DELIVERY: 'Free Delivery',
  FREE_DELIVERY_TEXT: 'Across India within 7 days',
  DISCREET_PACKAGING: 'Discreet packaging',
  DISCREET_PACKAGING_TEXT: 'We value your privacy',
  CONSTITUENTS: 'Our product',
  CONSTITUENTS_TEXT: `When you buy a Misters product, you can be sure that you are buying a product designed 
    by specialists, and that has been put together using the highest quality ingredients in the world.`,
  BUY_NOW: 'BUY NOW',
  GO_BACK: 'GO BACK',
  ITEM_IS_OUT_OF_STOCK: 'Sorry, this item is out of stock right now, please check again after a couple of days.',
  ADDRESS_SKIP_TEXT: `Please click here in case you want delivery at an address different from your home or office.
    We will call you and figure it out.`,
  THERAPY_FOOTER_TEXT: 'For any queries, please call 18001219555',
  THERAPY_FOOTER_TEXT_SMALL: 'Call us',
  ERECTILE_DYSFUNCTION_TITLE: 'Erectile Dysfunction',
  ERECTILE_DYSFUNCTION_TEXT: `Erections are important, obviously! And
    in case one doesn’t happen when we need it, it’s rather frustrating. Well, no more. Safe,
    scientific solutions. No innuendo. So, it’s all cool. Really.`,
  ERECTILE_DYSFUNCTION_BUTTON: 'Let\'s figure out my erection issue',
  PREMATURE_EJACULATION_TITLE: 'Premature Ejaculation',
  PREMATURE_EJACULATION_TEXT: `Coming before your partner does? Often? Well, first things first, it’s not as if 
    something is wrong with you. Happens to everyone, and only the smarter ones among us seek a solution. And given that 
    you are here, it is clear that you are seeking a solution, and are therefore one of the smarter ones. 
    Congratulations! And yes, just click here to figure out a simple, 
    scientific solution specifically designed for you.`,
  PREMATURE_EJACULATION_BUTTON: 'Let\'s figure out my premature ejaculation',
  SEX: 'Sex!',
  SEX_TEXT: `Well, sex is good. And it should be. And fascinates us. And we love reading about it. And, and…
    We don’t say that this is the greatest blog there ever was, but that’s because we are humble.
    Everyone else seems to think so! Find out for yourself (and while you are at it, you will find 
    something interesting, we feel.)`,
  SEX_BUTTON: 'The blog about sex!',
  QUESTION: 'Have a question?',
  QUESTION_TEXT: `Well, it could be about relationship troubles, something amiss in bed, or something you
    aren’t feeling great about. Just write it down here, and the right expert—doctor, therapist,
    pharmacist, any kind of expert your question leads to—will answer it soon. Yes, you can ask
    it anonymously!`,
  QUESTION_BUTTON: 'Ask us',
  QA_FOOTER_TEXT: 'Asked by {name} from {city}',
  ORDER_THANKS: 'Thank you. Your order is confirmed!',
  WE_CARE_ABOUT_YOUR_SAFETY: 'We care about your safety.',
  COD_ORDER_DISPATCHED_AFTER_CONFIRMATION_CALL: 'COD orders will be dispatched only after a confirmation call from our team. Please answer the call so that we can ship your order.',
  GET_A_FREE_AYURVEDIC_ANALYSIS: 'Get a free ayurvedic analysis <br/> of your health',
  MANDATORY_SANITIZATION: 'Mandatory sanitization',
  SAFE_SHIPPING: 'Safe shipping',
  CONTACTLESS_DELIVERY: 'Contactless delivery',
  ORDER_FAILED: 'Your transaction could not be processed',
  CONTINUE_FROM_WHERE_YOU_LEFT: 'Continue from where you left?',
  YES: 'Yes',
  NO: 'No',
  END_REACHED: 'Looks like you have reached the end.',
  START_AGAIN: 'Start Again',
  KNOW_MORE: 'Know more',
  CALL_US: 'Call us',
  TAKE_QUIZ: 'Take quiz',
  TAKE_THE_SEX_QUIZ: 'Take the sex quiz',
  TAKE_THE_MAN_QUIZ: 'Take the man quiz',
  TAKE_THE_AYURVEDIC_QUIZ: 'Take the ayurvedic quiz',
  ERECTION_ISSUES: 'Erection issues',
  START: 'Start',
  HELLO_MAN: 'Hello, man!',
  HELLO_MAN_TEXT: '“You tamer of wild horses, the finest specimen of your species”',
  HELLO_MAN_COUNT: 'users have taken quiz with misters',
  ADD_NEW_ADDRESS_TO_CONTINUE: 'Please add a new address to continue',
  CURRENTLY_NOT_SERVING_ON_SELECTED_AREA: 'Sorry, we are unable to service this area currently.',
  ADD_TO_CART: 'ADD TO CART',
  SUBSCRIPTION: 'SUBSCRIPTION',
  SUBSCRIBE: 'SUBSCRIBE',
  PLEASE_ENTER_THE_OTP_RECEIVED_ON_YOUR_MOBILE_TO_COMPLETE_THIS_ORDER: 'Please enter the OTP received on your mobile to complete this order',
  AYURVEDA: 'Ayurveda',
  MEDIA: 'Media',
  WATCH_US_ON_YOUTUBE: 'WATCH US ON YOUTUBE',
  SHOP_NOW: 'Shop Now',
  COMING_SOON: 'Coming Soon',
  CONTINUE_SHOPPING: 'Continue Shopping',
  LEARN_MORE: 'LEARN MORE',
  SHOW_DETAILS: 'SHOW DETAILS',
  FIND_OUT_YOUR_SEX_SCORE: 'Find out your sex score in just five minutes!',
  ONLINE_PAYMENT_ONLY_MESSAGE: 'COD is currently not available for this location. To thank you for your understanding, we are giving you an exclusive ₹200 off on all other modes of payment.',
  READ_MORE: 'Read more',
  HOW_DOES_AYURVEDA_WORK: 'how does ayurveda work',
  KEY_INGREDIENTS: 'Ingredients',
  HOW_TO_USE: 'How to use',
  INGREDIENTS_TITLE: 'Natural & Effective Ingredients',
  INGREDIENTS_TITLE_1: 'Scientific and natural',
  CONFIDENCE_1: 'Click call now button to consult our doctor for free',
  CONFIDENCE_2: 'Doctor recommends a solution after free consultation',
  CONFIDENCE_3: 'Place order for the recommended solution',
  CONFIDENCE_4: 'Get the product delivered in discreet packaging',
  CONFIDENCE_5: 'Feedback after 20 days & corrective course recommended if required'
}

export default language
