import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { otherAssets, PHONE_NUMBER } from '../../../constants'
import LText from '../../core/LText'
import PostSmallCard from '../../cards/PostSmallCard'
import { fetchRecentPosts } from '../../../actions/blog/posts'
import { languageToShortName, languageUrls } from '../../../languages'
import TakeQuiz from '../../others/TakeQuiz'
import ImageService from '../../../services/ImageService'

class Footer extends PureComponent {
    static propTypes = {
      fetchRecentPosts: PropTypes.func
    };

    componentDidMount () {
      const { match, posts: { ghostRecentList: { posts = [] } } } = this.props
      const langParam = match.params.language || '/en'
      const lang = languageToShortName[languageUrls[langParam]]
      if (!posts.length) {
        this.props.fetchRecentPosts({ lang })
      }
    }

    render () {
      const { match, posts: { ghostRecentList } } = this.props
      const langParam = match.params.language || '/en'

      return (
            <footer className="page-footer font-small indigo">
                <TakeQuiz />
                <div className="container pr-3 py-5 align-items-center text-center text-sm-left">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12 py-3">
                            <img className="lazyload footer-logo" data-src={ImageService.logos.brandLight} alt="Man!"/>
                            <p>We are a men's confidence company. </p>
                            <div className="footer-social-links">
                                <a target="_blank" title="facebook" rel="noreferrer"
                                   href="https://www.facebook.com/mistersdotin/">
                                    <FontAwesomeIcon icon={faFacebookF}/>
                                </a>
                                <a target="_blank" title="linkedin" rel="noreferrer"
                                   href="https://www.linkedin.com/company/misters/">
                                    <FontAwesomeIcon icon={faLinkedinIn}/>
                                </a>
                                <a target="_blank" title="instagram" rel="noreferrer"
                                   href="https://www.instagram.com/mistersdotin/">
                                    <FontAwesomeIcon icon={faInstagram}/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 py-3">
                            <h4 className="mb-4">
                                <a href={`https://misters.in${langParam}/blog/`}><LText k="BLOG"/></a>
                            </h4>
                            {!!ghostRecentList.posts && ghostRecentList.posts.slice(0, 2).map((post) => (
                                <PostSmallCard key={post.id} {...post}/>
                            ))}
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 py-3">
                            <h4 className="mb-4"><LText k="INFORMATION"/></h4>
                            <ul className="list-unstyled w-100">
                                <li>
                                    <Link to={`${langParam}/`}>
                                        <LText k="HOME"/>
                                    </Link>
                                </li>
                                <li>
                                    <a href={`https://misters.in${langParam}/blog/`}>
                                        <LText k="BLOG"/>
                                    </a>
                                </li>
                                <li>
                                    <a href={`https://misters.in${langParam}/qa/`}>
                                        <LText k="QA"/>
                                    </a>
                                </li>
                                <li>
                                    <Link to={`${langParam}/media/`}>
                                        <LText k="MEDIA"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${langParam}/about/`}>
                                        <LText k="ABOUT"/>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 py-3">
                            <h4 className="mb-4"><LText k="CONTACT_US"/></h4>
                            <div className="footer-contact">
                                <div className="footer-address">
                                    <FontAwesomeIcon icon={faMapMarkedAlt}/>
                                    <p>Misters, G-4/8, DLF Phase 1, Sector 26, Gurugram, 122002</p>
                                </div>
                                <div className="footer-phone">
                                    <FontAwesomeIcon icon={faPhone}/> <p>{PHONE_NUMBER}</p>
                                </div>
                                <div className="footer-email">
                                    <FontAwesomeIcon icon={faEnvelope}/> <p>m@misters.in</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="footer-copyright p-3 clearfix text-center">
                        <span>Copyright © 2020 Misters, All rights reserved</span>
                        &nbsp;|&nbsp;
                        <a href={otherAssets.termsAndCondition} target="_blank">Terms & Conditions</a>
                        &nbsp;|&nbsp;
                        <a href={otherAssets.privacyPolicy} target="_blank">Privacy policy</a>
                        &nbsp;|&nbsp;
                        <a href={otherAssets.refundAndCancellationPolicy} target="_blank">Refund &
                            Cancellation policy</a>
                        &nbsp;|&nbsp;
                        <a href={otherAssets.investorRelations} target="_blank">Investors Relations</a>
                        <br/>
                    </div>
                </div>
            </footer>
      )
    }
}

const mapStateToProps = state => ({
  posts: state.blog.posts
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchRecentPosts
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Footer))
