import {
  FETCH_POSTS,
  FETCH_POSTS_RESULT,
  FETCH_POSTS_ERROR,
  FETCH_POSTS_RETRY,

  FETCH_POST,
  FETCH_POST_RESULT,
  FETCH_POST_ERROR,
  FETCH_POST_RETRY,

  LIKE_COMMENT,
  LIKE_COMMENT_RESULT,
  LIKE_COMMENT_ERROR,
  LIKE_COMMENT_RETRY
} from '../../actions/community/posts'
import { mergeNestedComments } from '../../utils'

const initialState = {
  detail: {},
  list: {},
  isFetching: false,
  isFetchingList: false,
  isCreatingLike: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSTS:
      return { ...state, isFetchingList: true, error: false, message: null, retry: false }
    case FETCH_POSTS_RESULT:
      return { ...state, isFetchingList: false, list: action.data }
    case FETCH_POSTS_ERROR:
      return { ...state, isFetchingList: false, error: true, message: action.message }
    case FETCH_POSTS_RETRY:
      return { ...state, isFetchingList: false, retry: true, message: action.message }

    case FETCH_POST:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_POST_RESULT:
      return { ...state, isFetching: false, detail: action.data }
    case FETCH_POST_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_POST_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case LIKE_COMMENT:
      return { ...state, isCreatingLike: true, error: false, message: null, retry: false }
    case LIKE_COMMENT_RESULT:
      const results = mergeNestedComments({ comments: state.detail.results, comment: action.data })
      return { ...state, isCreatingLike: false, detail: { ...state.detail, results } }
    case LIKE_COMMENT_ERROR:
      return { ...state, isCreatingLike: false, error: true, message: action.message }
    case LIKE_COMMENT_RETRY:
      return { ...state, isCreatingLike: false, retry: true, message: action.message }

    default:
      return state
  }
}

export default reducer
