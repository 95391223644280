import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import * as queryString from 'query-string'

import FormInput from '../../components/inputs/FormInput'
import Button from '../../components/buttons/Button'
import { userLogin, userLoginOtp } from '../../actions/accounts/profile'
import { getPhoneNumberWithCountryCode } from '../../utils'
import Page from '../../components/core/Page'
import LText from '../../components/core/LText'
import { getLanguage } from '../../store_utils'
import Consent from '../../components/others/Consent'

class Login extends Component {
    static propTypes = {
      login: PropTypes.object,
      match: PropTypes.object,
      profile: PropTypes.object,
      history: PropTypes.object,
      userLoginOtp: PropTypes.func,
      userLogin: PropTypes.func
    };

    OTP_TIMEOUT = 30;

    state = {
      phone_number: '',
      otp: '',
      message: null,
      showOtpField: false,
      otpTimer: null
    };

    componentDidMount () {
    }

    componentWillUnmount () {
      clearInterval(this.intervalId)
    }

    otpTimerStart = () => {
      clearInterval(this.intervalId)
      this.setState({ otpTimer: this.OTP_TIMEOUT })
      this.intervalId = setInterval(() => {
        this.setState(
          prevState => ({ otpTimer: prevState.otpTimer - 1 }),
          () => {
            if (this.state.otpTimer <= 0) {
              clearInterval(this.intervalId)
            }
          }
        )
      }, 1000)
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { profile, history, history: { location: { search } }, match } = this.props

      if (!profile.isFetchingLogin && prevProps.profile.isFetchingLogin &&
            profile.error && !profile.retry && profile.message) {
        this.setState({ message: profile.message })
      }
      if (!profile.isFetchingLogin && prevProps.profile.isFetchingLogin && !profile.error && !profile.retry) {
        if (search.length) {
          const q = queryString.parse(search)
          if (q.next) {
            history.push(q.next)
          } else {
            history.push(`${match.params.language}`)
          }
        } else {
          history.push(`${match.params.language}`)
        }
      }
      if (!profile.isFetchingLoginOTP &&
            prevProps.profile.isFetchingLoginOTP &&
            profile.error &&
            !profile.retry &&
            profile.message) {
        this.setState({ message: profile.message })
      }
      if (!profile.isFetchingLoginOTP &&
            prevProps.profile.isFetchingLoginOTP &&
            !profile.error &&
            !profile.retry) {
        this.setState({ message: null, showOtpField: true })
        this.otpTimerStart()
      }
    }

    sendOTP = () => {
      const { history: { location: { search } } } = this.props
      const q = queryString.parse(search)
      const data = {
        phone_number: getPhoneNumberWithCountryCode(this.state.phone_number)
      }
      if (q.referrer_id) {
        data.referrer_id = q.referrer_id
      }
      this.props.userLoginOtp(data)
    };

    sendOTPOnCall = () => {
      const { history: { location: { search } } } = this.props
      const q = queryString.parse(search)
      const data = {
        phone_number: getPhoneNumberWithCountryCode(this.state.phone_number),
        is_otp_on_call: true
      }
      if (q.referrer_id) {
        data.referrer_id = q.referrer_id
      }
      this.props.userLoginOtp(data)
    };

    login = () => {
      const { phone_number, otp } = this.state
      this.setState({ message: null })
      this.props.userLogin({ phone_number: getPhoneNumberWithCountryCode(phone_number), otp })
    };

    render () {
      const { phone_number, otp, message, showOtpField, otpTimer } = this.state
      const { match } = this.props

      return (
            <Page meta={{
              path: `${match.params.language}/login`,
              title: 'Misters.in - Men\'s sexual health',
              description: 'Misters is a Gurgaon-based health and confidence start-up catering to men’s sexual health and wellness using ayurvedic, allopathic and plant-based methods. In collaboration with medical experts, reliable information on erections, ejaculation, sexual desire and hair care awaits you here. Simple, personalized and result-oriented care amalgamating traditional knowledge and the best science has to offer is Misters’ forte. More than a website and company, Misters is a community. Let your journey commence, bon voyage!'
            }}>
                <div className="login">
                    <div className="py-5"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 col-12 offset-lg-4 offset-md-3 offset-sm-2 py-5">
                                <h3 className="text-center pb-4">
                                    <LText k="LOGIN"/>
                                </h3>
                                <div className="pb-3">
                                    <FormInput type="number"
                                               placeholder={getLanguage('YOUR_10_DIGIT_MOBILE_NUMBER')}
                                               maxLength="10"
                                               autoFocus={true}
                                               error={message ? message.phone_number : ''}
                                               value={phone_number}
                                               onChange={(e) => this.setState({ phone_number: e.target.value })}
                                               onKeyPress={event => {
                                                 if (event.key === 'Enter') {
                                                   this.sendOTP()
                                                 }
                                               }}/>
                                </div>
                                {
                                    showOtpField &&
                                    <div className="pb-3">
                                        <FormInput type="password"
                                                   placeholder={getLanguage('YOUR_OTP')}
                                                   value={otp}
                                                   autoFocus={true}
                                                   error={message ? message.otp : ''}
                                                   onChange={(e) => this.setState({ otp: e.target.value })}
                                                   onKeyPress={event => {
                                                     if (event.key === 'Enter' && !!otp) {
                                                       this.login()
                                                     }
                                                   }}/>
                                    </div>
                                }
                                {!!message && !!message.message && <div className="error-text">{message.message}</div>}
                                {
                                    showOtpField && !!otpTimer &&
                                    <div className="otp-resend-text">
                                        <LText k="RESEND_OTP_TEXT"
                                               replaceKeys={[{ key: 'time', value: otpTimer }]}/>
                                    </div>
                                }
                                <Consent/>
                                {
                                    !otp &&
                                    !showOtpField &&
                                    <Button block onClick={this.sendOTP}
                                            style={{ marginRight: 20, marginBottom: 20 }}>
                                        <LText k="SEND_OTP"/>
                                    </Button>
                                }
                                {
                                    !!otp &&
                                    <Button block onClick={this.login} style={{ marginRight: 20, marginBottom: 20 }}>
                                        <LText k="LOGIN"/>
                                    </Button>
                                }
                                {
                                    showOtpField && !otpTimer &&
                                    <div className="d-flex flex-column">
                                        <Button onClick={this.sendOTP} block
                                                style={{ marginBottom: 15 }}>
                                            <LText k="RESEND_OTP"/>
                                        </Button>
                                        <Button variant="secondary" block
                                                className="btn-get-otp-on-call"
                                                onClick={this.sendOTPOnCall}>
                                            <LText k="GET_OTP_ON_CALL"/>
                                        </Button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = (state) => ({
  profile: state.accounts.profile
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  userLogin,
  userLoginOtp
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Login)
