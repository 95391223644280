import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../../components/core/Page'
import LText from '../../../components/core/LText/LText'

class OrderFailed extends PureComponent {
    static propTypes = {
      profile: PropTypes.object,
      newOrder: PropTypes.object,
      updateNewOrder: PropTypes.func
    };

    componentDidMount () {
      // let {orders, history, match} = this.props;
      // if (!Object.keys(orders.detail).length) {
      //     history.push(`${match.params.language}/`);
      // }
    }

    render () {
      const { match } = this.props

      return (
            <Page meta={{
              title: 'Shop quality Misters health products',
              path: `${match.params.language}/checkout/failed/`,
              description: 'This space houses tried, trusted and tested Misters products formulated by experts and specialists of the field. Ideal recourse to boost immunity, manage hair loss, maintain erections and control ejaculation can be found here. Discover scientifically enriching, natural, ancient Ayurvedic herbs like Ashwagandha, Shilajit, Gokshura, Shatavari, Kaunch Beej Kesar and more, moulded for modern-day usage. Some among their vast range of benefits include an enhancement of libido, improved performance in bed, strong penile tissue and healthy testosterone levels.'
            }}>
                <div className="container">
                    <div style={{ height: 150 }}/>
                    <div className="payment">
                        <h1 className="order-failed">
                            <LText k="ORDER_FAILED"/>
                        </h1>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = state => ({
  orders: state.ecommerce.orders
})

const matchDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(OrderFailed)
