import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { fetchProducts } from '../../actions/ecommerce/products'
import { scrollToTop } from '../../utils'
import Page from '../../components/core/Page'
import ShopProductCard from '../../components/cards/ShopProductCard'
import WhyMisters from '../../components/others/WhyMisters'
import Ecommerce from '../../services/Ecommerce'
import { createCartItem } from '../../actions/ecommerce/cart'
import AuthenticatedContainer from '../../components/containers/AuthenticatedContainer'

class Solutions extends Component {
  static fetching ({ dispatch }) {
    return [dispatch(fetchProducts())]
  };

    static propTypes = {
      cart: PropTypes.object,
      history: PropTypes.object,
      match: PropTypes.object,
      products: PropTypes.object,
      fetchProducts: PropTypes.func,
      createCartItem: PropTypes.func
    };

    constructor (props) {
      super(props)
      this.state = {
        sort_by: 'popularity'
      }
    }

    componentDidMount () {
      this.props.fetchProducts()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { products, cart, match, history } = this.props
      const { language } = match.params
      const prevLanguage = prevProps.match.params.language

      if (!this.props.products.isFetchingList &&
            prevProps.products.isFetchingList &&
            !this.props.products.error &&
            !this.props.products.retry) {
        scrollToTop()
        Ecommerce.shopPageView({ products: products.list })
      }

      if (!cart.isCreatingCartItem && prevProps.cart.isCreatingCartItem && !cart.error && !cart.retry) {
        history.push(`${language}/checkout`)
      }

      if (language !== prevLanguage) {
        this.props.fetchProducts()
      }
    }

    handleAddItemToCart = async (product) => {
      Ecommerce.addToCart({ products: [product] })
      let productId

      if (product.available_packs.length > 0) {
        productId = product.available_packs[product.available_packs.length - 1].product.id
      } else {
        productId = product.id
      }
      this.props.createCartItem({ id: productId, quantity: 1 })
    };

    handleOpenProductDetails = (product) => {
      const { match, history } = this.props
      let url = `${match.params.language}/solutions/${product.product_id}`
      if (product.is_single_product) {
        url = `${match.params.language}/therapy/${product.product_id}`
      }
      Ecommerce.productClick({
        product,
        eventCallback: () => {
          history.push(url)
        }
      })
    };

    handleSortByChange = (e) => {
      const sort_by = e.target.value
      this.setState({ sort_by })
      this.props.fetchProducts({ sort_by })
    }

    render () {
      const { products, match } = this.props

      return (
            <AuthenticatedContainer>
                <Page meta={{
                  title: 'Shop quality Misters health products',
                  path: `${match.params.language}/solutions/`,
                  description: 'This space houses tried, trusted and tested Misters products formulated by experts and specialists of the field. Ideal recourse to boost immunity, manage hair loss, happiness.'
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <h1 className="product-title">Make the right move</h1>
                                <p>The greatest portfolio ever assembled for the happiness of men</p>
                              <div>
                                <label htmlFor="sortBy" style={{ marginRight: 5, fontSize: 13 }}>{'Sort By '}</label>
                                <select name="" id="sortBy" value={this.state.sort_by}
                                        onChange={this.handleSortByChange}>
                                  <option value="popularity">Popularity</option>
                                  <option value="newest">Newest</option>
                                  <option value="price">Price</option>
                                </select>
                              </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                (products.list || []).map(product => (
                                    <div key={product.id} className="col-lg-4 col-md-6 col-12 d-flex flex-column">
                                        <ShopProductCard product={product}
                                                         handleAddToCart={() => this.handleAddItemToCart(product)}
                                                         handleOpenProductDetails={() => this.handleOpenProductDetails(product)}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <WhyMisters />
                    <div className="text-center p-5">Give a missed call to our Doctors at 011-41169222 for a Free Ayurvedic Consultation</div>
                </Page>
            </AuthenticatedContainer>
      )
    }
}

const mapStateToProps = (state) => ({
  products: state.ecommerce.products,
  cart: state.ecommerce.cart
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  fetchProducts,
  createCartItem
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Solutions)
