// External Imports
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Carousel from 'react-bootstrap/Carousel'
// My Imports
import Button from '../../components/buttons/Button'
import ImageService from '../../services/ImageService'
import LText from '../../components/core/LText'
import Image from '../../components/core/Image'

const Section1 = ({ match, quizTakersCount }) => {
  const language = match.params.language
  const [direction, setDirection] = useState(null)
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    if (e) setDirection(e.direction)
    setIndex(selectedIndex)
  }

  return (
    <div className='home-page-section'>
      <div className="container">
        <div className="row py-md-5 pb-4">
          <div
            className="col-md-6 col-12 d-flex align-items-center justify-content-center">
            <div className='text-center text-md-left pr-3'>
              {/* <p className="home-page-title-quote">“The trick is growing up without growing old”</p> */}
              <h1 className="home-page-title">
                <LText k="HELLO_MAN"/>
              </h1>
              <p className="home-page-title-meta">
                <LText k="HELLO_MAN_TEXT"/>
              </p>
              <div className="solution-page-section-trust-marker mb-2">
                <img src={ImageService.home.badge} alt="Badge"/> Trusted by <strong>25,000+ Indian Men</strong>
              </div>
              <Button className='mb-3 mr-md-3 ml-md-0 mx-2'
                      variant="dark"
                      style={{ width: 250 }}
                      to={`${language}/solutions/`}>
                <LText k="SHOP_NOW"/>
              </Button>
              <Button className='mb-3 mr-md-3 ml-md-0 mx-2'
                      variant="dark"
                      style={{ width: 250 }}
                      to={`${language}/quiz/sex/`}>
                <LText k="TAKE_THE_MAN_QUIZ"/>
              </Button>
              <br/>
            </div>
          </div>
        </div>
      </div>
      <div className="home-page-section-bg-image" style={{ backgroundColor: index === 0 ? '#12151d' : '#ffffff', transition: '500ms all ease' }}>
        <Carousel
          activeIndex={index}
          direction={direction}
          onSelect={handleSelect}
          interval={8000}
          controls={false}
          indicators={true}
          fade={true}
        >
          <Carousel.Item>
            <div className="solution-page-carousel-image">
              <Image src={ImageService.home.image1} alt="Man facing front"/>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="solution-page-carousel-image">
              <Image src={ImageService.home.image2} alt="Man facing front"/>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  )
}

Section1.propTypes = {
  match: PropTypes.object,
  quizTakersCount: PropTypes.number
}

const mapStateToProps = state => ({
  quizTakersCount: state.bot.bot.quizTakersCount
})

const matchDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Section1))
