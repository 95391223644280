// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../../Category/common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText/LText'
import { openLinkOnNewTab } from '../../../utils'

const shopUrl = '/en/solutions/'

export const accordionList1 = [
    <AccordionCard key={'0'} title="What are some other names for Shatavari?">
        <p>
            Shatavari is well known by many, for its various alternative names like <b>Shatveerya, Bahusuta and
            Shatmuli</b>. Its <b>botanical name is Asparagus racemosus</b>. Shatavari is also referred to, as <b>Shatawar
            in Hindi, Wild Asparagus in English, Shatamuli in Bengali and Marathi, Toala - gaddalu or Pilli – gaddalu in
            Telugu, Shimaishadavari or Inli - chedi in Tamil, Chatavali in Malyalam, Majjigegadde or Aheruballi in
            Kannada, Narbodh or Atmooli in Madhya Pradesh, Kairuwa in Kumaon and Norkanto in Rajasthan</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="Where is Shatavari found?">
        <p>
            Shatavari can be easily found all over <b>India</b>, especially in the nation’s <b>Northern region</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What is the description of the Shatavari plant?">
        <p>
            The <b>stem</b> of Shatavari consists of multiple horns. Its <b>branches</b> are somewhat <b>triangular in
            shape</b> and are often <b>sticky and oily to the touch</b>. Its <b>leaves are around the length of half or
            one inch</b> and are generally found in a bunch of about 2-6 leaves. The <b>flower</b> of the Shatavari
            plant is exceedingly beautiful. It is <b>white in color</b> and filled with a <b>pleasant fragrance</b>. The
            plant also <b>bears small, round, seeded fruit</b> that turn red in colour, once they have ripened.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="What are the Ayurvedic properties of Shatavari?">
        <p>
            The ayurvedic properties of Shatavari make it efficient in managing health problems. It is considered as <b>Guru
            (heavy)</b> and <b>Snigdha (oily)</b> in <b>nature</b>. These characteristics make it a complete food that
            provides fullness in the body helping in its internal or external dryness. Shatavari is <b>Tikta
            (bitter)</b> in <b>taste, Madhura (sweet) in its aftertaste</b> and <b>Sheeta (cold)</b> in
            its <b>potency</b>. It also helps to manage all the three Doshas, especially Vata and Pitta Dosha.
            <br/>
            <br/>
            Overall, Shatavari is one of the essential herbs in Ayurvedic medicine that functions both as a rejuvenator
            and as an aphrodisiac. Hence, Shatavari helps manage the overall health or restore the lost health of an
            individual and also <b>boosts sexual well being</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the benefits of Shatavari?">
        <p>
            Shatavari is commonly used for <b>female reproductive health</b> and assists women in situations like <b>conceiving,
            preventing a miscarriage and improving their libido</b>. In Sanskrit, the name Shatavari means both, “having
            one hundred roots” as well as “having one hundred husbands”.
            <br/>
            <br/>
            The name Shatavari itself is suggestive of a <b>rejuvenating tonic</b>, which helps to develop and maintain
            the good health and general well-being of any individual.
            <br/>
            <br/>
            The traditional use of Shatavari had already been mentioned in the ancient texts of Ayurveda like the
            “Charak Samhita.” This text describes the herb as being employed in the <b>treatment of epilepsy, serving as
            a brain tonic, effectively managing Vata disorders</b> and keeping cardiac disorders and hypertension in
            check.
            <br/>
            <br/>
            <b>Male sexual problems like impotency, poor or low sperm count, erectile dysfunction, oligospermia,
                spermatogenic irregularities and other male problems like painful micturition</b> are becoming
            increasingly common. These aforementioned sexual health issues are all governed by the Vata Dosha. Any kind
            of imbalance in Vata Dosha might lead to the prevalence of these sexual disorders. Shatavari, being Vrishya
            (aphrodisiac) in nature, helps it to balance the Vata Dosha and consequently balances sexual health issues
            as well.
            <br/>
            <br/>
            The most probable reasons for urinary problems like incomplete micturition, the painful or burning sensation
            in one’s urine or the presence of stones, are often poor eating habits or the lack of adequate hydration of
            the body. These reasons lead to an imbalanced set of Doshas (Vata, Pitta and Kapha) because of which an
            individual tends to face bodily issues and discomfort.
            <br/>
            <br/>
            The consumption of Shatavari might prove to be very
            helpful in all these conditions due to its <b>Mutral (diuretic) property</b>. This property efficiently <b>increases
            urine production</b> in the body which then helps to <b>reduce the symptoms of urine related problems</b> in
            the future.
            <br/>
            <br/>
            Shatavari is also considered to provide good health to an individual due to its <b>Balya
            (strength-providing) property</b>. This property fills the muscles and tissues of the body with strength,
            which in turn helps an individual to gain and maintain a <b>healthy physique</b>.
            <br/>
            <br/>
            The intake of Shatavari, in many cases, aids the proper functioning of the nervous system by providing
            nourishment to the nerves enriched with <b>Medhya (brain tonic) properties</b>. It also contributes to a
            solid nervous system which can put up a fight against nerve disorders in the future.
            <br/>
            <br/>
            Shatavari <b>facilitates healthy digestion</b> by improving the Agni (digestive fire) in the body. Its <b>Agni
            Deepak (digestive fire enhancer) property</b>, improves a poor appetite and leads to healthy digestion.
            This, in turn, leads to a <b>fast metabolism</b> which is effective in the <b>prevention of metabolic
            diseases</b> such as a high cholesterol.
            <br/>
            <br/>
            In conclusion, Shatavari is considered as one of the essential herbs in Ayurveda, and includes multiple
            health benefits. Among them, <b>Rasayana (rejuvenation)</b> and <b>Vajikarana (aphrodisiac)</b> are very
            important benefits which lead to a disease free body inside out, and helps maintain sexual performance as
            well.
        </p>

        <p className="text-center font-weight-bold">
            <span>To know more about the benefits of Shatavari, </span>
            <span className="ingredient-blog-links"
                  onClick={() => openLinkOnNewTab('/blog/What-are-Shatavari-benefits')}>click here</span>
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]

export const accordionList3 = [
    <AccordionCard key={'0'} title="How does Shatavari help in body building?">
        <p>
            Bodybuilding is an activity performed, both competitively, as well as for personal satisfaction. Men dream
            of looking physically attractive and maintaining good overall health. It is important to note that <b>bodybuilding
            is not only restricted to enhancing one’s personality, but has myriad health benefits as well</b>.
            <br/>
            <br/>
            Now the question arises, how can a man attempt to achieve all these characteristics? The answer is simple.
            One must improve their dietary habits, practice yoga on a more or less regular basis and take the help of
            ayurvedic herbs such as Shatavari. One will see a natural All these methods will naturally induce good
            health.
            <br/>
            <br/>
            Men often think, why not take the help of supplements that are used by quite a few fellow body builders? The
            catch here is that <b>chemical supplements are not always healthy and can have detrimental effects on the
            body</b>. Many of them also have artificial chemicals mixed into it. <b>Shatavari on the other hand, can
            definitely act as a supplement, but is entirely natural in its nature</b>.
            <br/>
            <br/>
            Now, how does one distinguish between a supplement with artificial additives and a natural one? Consider
            this. Any supplement provided to you by a trusted and certified Ayurvedic practitioner will always remain
            safe and beneficial for your health. Besides this, notice and study the ingredients of any particular
            supplement to see if it contains all naturally extracted herbs.
            <br/>
            <br/>
            In case an individual’s physical make up is thin and lean, it is required for them to obtain proper
            nourishment via food or food supplements. In this regard, <b>Shatavari</b> can help provide nourishment and
            strength due to its <b>Balya (strength-providing) property</b>.
            <br/>
            <br/>
            This aids the <b>enhancement of the muscular system</b> leading to a healthy muscle build-up and an
            attractive physique. This process of providing internal strength and nourishment to various body parts
            through Shatavari is completely healthy and also prevents accumulation of any superficial fat, which can
            arise due to the consumption of mixed chemical supplements.
            <br/>
            <br/>
            Since Shatavari is efficient in facilitating urine production, it helps in the removal of excessive waste
            material from the body that can eventually become an obstruction in body building practices.
            <br/>
            <br/>
            Overall, <b>Shatavari can help individuals to realise their body building aspirations</b> and aid them to
            gain a <b>strong physique</b> and an <b>aesthetic body</b>. Its health benefits and rare chances of side
            effects have made it a much coveted herb.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="References">
        <ul>
            <li>
                <a href="http://www.iamj.in/posts/images/upload/4188_4192.pdf" target="_blank">
                    http://www.iamj.in/posts/images/upload/4188_4192.pdf
                </a>
            </li>
            <li>
                <a href="https://www.researchgate.net/publication/260427675_SHATAVARI_ASPARAGUS_RACEMOSUS_WILD_A_REVIEW_ON_ITS_CULTIVATION_MORPHOLOGY_PHYTOCHEMISTRY_AND_PHARMACOLOGICAL_IMPORTANCE"
                   target="_blank">
                    https://www.researchgate.net/publication/260427675_SHATAVARI_ASPARAGUS_RACEMOSUS_WILD_A_REVIEW_ON_ITS_CULTIVATION_MORPHOLOGY_PHYTOCHEMISTRY_AND_PHARMACOLOGICAL_IMPORTANCE
                </a>
            </li>
            <li>
                <a href="https://www.researchgate.net/publication/262910868_Asparagus_racemosus_Shatavari_An_Overview"
                   target="_blank">
                    https://www.researchgate.net/publication/262910868_Asparagus_racemosus_Shatavari_An_Overview
                </a>
            </li>
            <li>
                <a href="http://www.iamj.in/posts/images/upload/4188_4192.pdf" target="_blank">
                    http://www.iamj.in/posts/images/upload/4188_4192.pdf
                </a>
            </li>
            <li>
                <a href="https://www.researchgate.net/publication/260427675_SHATAVARI_ASPARAGUS_RACEMOSUS_WILD_A_REVIEW_ON_ITS_CULTIVATION_MORPHOLOGY_PHYTOCHEMISTRY_AND_PHARMACOLOGICAL_IMPORTANCE"
                   target="_blank">
                    https://www.researchgate.net/publication/260427675_SHATAVARI_ASPARAGUS_RACEMOSUS_WILD_A_REVIEW_ON_ITS_CULTIVATION_MORPHOLOGY_PHYTOCHEMISTRY_AND_PHARMACOLOGICAL_IMPORTANCE
                </a>
            </li>

            <li>
                <a href="https://www.researchgate.net/publication/262910868_Asparagus_racemosus_Shatavari_An_Overview"
                   target="_blank">
                    https://www.researchgate.net/publication/262910868_Asparagus_racemosus_Shatavari_An_Overview
                </a>
            </li>
            <li>
                <a href="http://www.iamj.in/posts/images/upload/4188_4192.pdf"
                   target="_blank">
                    http://www.iamj.in/posts/images/upload/4188_4192.pdf
                </a>
            </li>
            <li>
                <a href="https://www.researchgate.net/publication/260427675_SHATAVARI_ASPARAGUS_RACEMOSUS_WILD_A_REVIEW_ON_ITS_CULTIVATION_MORPHOLOGY_PHYTOCHEMISTRY_AND_PHARMACOLOGICAL_IMPORTANCE"
                   target="_blank">
                    https://www.researchgate.net/publication/260427675_SHATAVARI_ASPARAGUS_RACEMOSUS_WILD_A_REVIEW_ON_ITS_CULTIVATION_MORPHOLOGY_PHYTOCHEMISTRY_AND_PHARMACOLOGICAL_IMPORTANCE
                </a>
            </li>
            <li>
                <a href="https://www.researchgate.net/publication/262910868_Asparagus_racemosus_Shatavari_An_Overview"
                   target="_blank">
                    https://www.researchgate.net/publication/262910868_Asparagus_racemosus_Shatavari_An_Overview
                </a>
            </li>
            <li>
                Padmshri Prof.Chunekar K.C.(com),Dr. G.S. Pandey(ed). Bhavprakash Nighantu.2008: 378-379
            </li>
            <li>
                Prof. Sharma P.V. Dravyaguna Vigyana, (vol 2). 2019:562-564
            </li>
        </ul>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]
