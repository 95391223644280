import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Page from '../../components/core/Page'
import { Images } from '../../constants'

class Media extends Component {
    static propTypes = {
      match: PropTypes.object
    };

    constructor (props) {
      super(props)

      this.state = {}
    }

    componentDidMount () {

    }

    componentDidUpdate (prevProps, prevState, snapshot) {

    }

    mediaList = [
      {
        content: '"The aim is to take away stigma & address self-doubt issues in men"',
        to: 'https://www.thehindubusinessline.com/specials/pulse/opening-up-on-those-uncomfortable-conversations-on-what-men-want/article31067768.ece#',
        img: Images.media.businessLine
      },
      {
        content: '"30 startups to watch"',
        to: 'https://inc42.com/features/30-startups-to-watch-the-startups-that-caught-our-eye-in-february-2020/',
        img: Images.media.inc42
      },
      {
        content: '"37 per cent men express low confidence on getting erections"',
        to: 'https://www.outlookindia.com/newsscroll/37-per-cent-men-express-low-confidence-on-getting-erections/1772757',
        img: Images.media.outlook
      },
      {
        content: '"भारतीय पुरुषों के यौन-स्वास्थ्य पर पहली रिपोर्ट पेश"',
        to: 'https://www.patrika.com/jaipur-news/sexual-health-health-news-sms-5881542/',
        img: Images.media.rajasthanPatrika
      },
      {
        content: '"10 things Indian men should know about sex"',
        to: 'https://www.rediff.com/getahead/report/relationships-10-things-indian-men-should-know-about-sex/20191226.htm',
        img: Images.media.rediff
      },
      {
        content: '"With a very highly specialised and experienced team along with a set of advisors, misters.in is placed well to create relevant proprietary products and market them in an intelligent manner"',
        to: 'https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/misters-in-raises-rs-3-5-cr-led-by-sauce-vc-rainforest-ventures-others/articleshow/72053327.cms',
        img: Images.media.theEconomicTimes
      },
      {
        content: '"On Man Handling"',
        to: 'https://www.newindianexpress.com/cities/delhi/2021/apr/03/on-man-handling-2285051.html',
        img: Images.media.tnie
      },
      {
        content: 'The Morning Standard',
        to: '/web-assets/images/media/morning-standard-misters.pdf',
        img: Images.media.morningStandard
      },
      {
        content: 'Men\'s Health Special',
        to: 'https://www.onlymyhealth.com/all-about-transient-erectile-dysfunction-1617700563',
        img: Images.media.onlymyhealth
      },
      {
        content: 'Misters launches herbal testosterone booster Daily Josh',
        to: 'https://www.nuffoodsspectrum.in/news/26/8170/misters-launches-herbal-testosterone-booster-daily-josh-.html',
        img: Images.media.nuffood
      },
      {
        content: 'Boosts testosterone levels',
        to: 'https://healthvision.in/boost-testosterone-levels-misters-launched-daily-josh-with-tesnortm/',
        img: Images.media.healthvision
      },
      {
        content: 'Misters launched Daily Josh with Tesnor(TM) to boost testosterone levels',
        to: 'https://mumbainewsnetworks.blogspot.com/2021/04/misters-launched-daily-josh-with.html',
        img: Images.media.blogger
      },
      {
        content: 'Misters launched Daily Josh with Tesnor(TM) to boost testosterone levels',
        to: 'https://digitalmedia9.com/health/misters-launched-daily-josh-with-tesnortm-to-boost-testosterone-levels/',
        img: Images.media.digitalmedia
      },
      {
        content: 'Misters launched Daily Josh with Tesnor(TM) to boost testosterone levels',
        to: 'http://mediabulletins.com/health/misters-launched-daily-josh-with-tesnortm-to-boost-testosterone-levels/',
        img: Images.media.mediabulletins
      },
      {
        content: 'Misters launched Daily Josh with Tesnor(TM) to boost testosterone levels',
        to: 'http://businessnewsthisweek.com/health/misters-launched-daily-josh-with-tesnortm-to-boost-testosterone-levels/',
        img: Images.media.businessnews
      },
      {
        content: 'Role Of Ayurveda In Male Sexual Health and How To Boost It Using Ayurvedic Herbs',
        to: 'https://www.onlymyhealth.com/role-of-ayurveda-in-male-sexual-health-and-how-to-boost-it-using-ayurvedic-herbs-1620713756',
        img: Images.media.onlymyhealth
      },
      {
        content: 'Exclusive Interaction With Mr. Suhas Misra On Impact Of Toxic Masculinity In The Society',
        to: 'https://www.healthwire.co/exclusive-interaction-with-mr-suhas-misra-on-impact-of-toxic-masculinity-in-the-society/',
        img: Images.media.healthwire
      },
      {
        content: 'Gurgaon Kesari',
        to: '/web-assets/images/media/gurgaon_kesari.pdf',
        img: Images.media.gurgaonKesari
      }
    ];

    render () {
      const { match } = this.props

      return (
            <Page meta={{
              title: 'Misters buzz in popular media',
              path: `${match.params.language}/media/`,
              description: 'Misters has been featured in prestigious media publications, the likes of The Hindu, Outlook, Rajasthan Patrika and The Economic Times. Moreover, Inc 42 has included Misters in the list of 30 major start-up ventures to watch out for. Conversation around sexual health, erections, ejacultion, Alopecia and immunity are scant. Mention of men’s confidence issues, even moreso. Our co-founder, Gaurav Gupta authored an article published on Rediff.com - 10 things Indian men should know about sex. Social media and digital content avenues help bridge long overdue gaps in the acceptance of natural conditions and we at Misters, are committed to cause.'
            }}>
                <div className="page-header-img"
                     style={{ backgroundImage: `url('${Images.media.media}')` }}>
                    <div className="page-header-bg-drop"/>
                    <div className="page-header-content">
                        <h1>As featured in</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {
                                    this.mediaList.map((data, index) => (
                                        <div key={index} className="col-lg-4 col-sm-6 col-12 d-flex">
                                            <a className="post-card flex-fill" href={data.to} target="_blank">
                                                {
                                                    data.img &&
                                                    <div className="post-card-image-logo">
                                                        <img src={data.img} alt=""/>
                                                    </div>
                                                }
                                                <div className="post-card-content">
                                                    <div className="post-card-head">
                                                        <p className="post-card-text">{data.content}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-5"/>
            </Page>
      )
    }
}

const mapStateToProps = (state) => ({})

const matchDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Media)
