import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../common/WhyMisters'
import Stats from '../common/Stats'
import { accordionList1, accordionList2, accordionList3 } from './accordions'
import Testimonials from '../common/Testimonials'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText'

class ErectileDysfunction extends Component {
  componentDidMount () {

  }

  render () {
    const { match } = this.props
    const shopUrl = `${match.params.language}/shop`
    const statsData = [
      { title: 'Nearly', percentage: '25%', text: 'of ED case in India below 30 years age' },
      { title: 'Annually', percentage: '10', text: 'million estimated ED cases in India' },
      { title: 'Over', percentage: '50%', text: 'Indian males above 40 have erection issues' }
    ]
    const testimonialsData = [
      {
        image: Images.testimonial.actor1,
        text: 'The best thing about misters.in is the complete privacy that is maintained in delivering medicines and therapy to my doorstep. My therapy box is delivered discretely.',
        comment: 'A satisfied repeat buyer of Misters ED therapy'
      },
      {
        image: Images.testimonial.actor2,
        text: 'My therapy included tadalafil which is a prescription based drug. Misters.in arranged online consultation with a doctor for me. The doctor asked me some additional questions and then prescribed me tadalafil. This was very reassuring.',
        comment: 'A first-time buyer of Misters.in ED therapy'
      },
      {
        image: Images.testimonial.actor3,
        text: 'I had earlier approached a local sexologist who made me feel scared and ashamed about my erection problem. Then I got to know about misters.in, where I answered a few questions that helped me get a personalised therapy to manage my erection problems. No one judged me!',
        comment: 'A Misters.in customer who frequented local sex clinics earlier'
      }
    ]

    return (
            <Page meta={{
              title: 'ED, causes, symptoms, treatments by Misters',
              path: `${match.params.language}/erectile-dysfunction/`,
              description: 'Over 50% of Indian males, above age 40 face erection issues. Erectile dysfunction is the inability to achieve and maintain an erection needed for satisfactory sexual activity. View curated causes, symptoms and treatments for this condition in the Misters Erectile Dysfunction guide. Ancient Ayurvedic herbs like Ashwagandha, Gokshura and Shilajit have proved beneficial in the past to manage ED. Potential risks, preventive measures, quick tips and natural remedies for impotence among men can be found on this page. Be silent no longer, Mister. Speak up, learn, treat and share.'
            }}>
                <div className="page-header-img" style={{ backgroundImage: `url('${Images.edHeader}')` }}>
                    <div className="page-header-bg-drop"/>
                    <div className="page-header-content">
                        <h1>Your guide to Erectile Dysfunction (ED)</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <p>
                                Erectile Dysfunction (ED), also known as impotence, is the inability to develop and
                                maintain
                                erection during sexual activity. Frequently asked questions (FAQs) on erectile
                                dysfunction
                                include ED treatment, ED causes, symptoms and cure.
                                <br/> <br/>
                                It is estimated that more than ten million cases of ED occur in India annually, but only
                                a
                                small percentage of these cases are referred for medical treatment. Outlook magazine
                                quotes
                                Dr Sudhakar Krishnamurti, a renowned sexual medicine expert, in saying <em>“India is the
                                impotence capital of the world”.</em>
                            </p>
                            <h2>What is Erectile Dysfunction (ED) or impotence?</h2>
                            <p>
                                Erectile dysfunction (ED), also known as impotence, is the inability to achieve and
                                maintain an erection that can enable a man to have satisfactory sexual activity. An
                                erection must be rigid enough to allow penetration into the vagina, mouth or anus.
                                <br/><br/>
                                However, it is important to note that anal penetration requires a more rigid erection
                                because it must pass through the powerful muscles of the anal sphincter.
                                <br/><br/>
                                Erectile dysfunction is a lasting decrease in the quality of erections. More frequent
                                from the age of 50, erectile dysfunction is linked to the physical and psychological
                                health of the man concerned.
                                <br/><br/>
                                Erectile dysfunction is a problem that is not sexually transmitted and increases with
                                age. It is believed to affect less than 5% of men in their forties and up to 60% of men
                                who are over 70 years old.
                                <br/><br/>
                                This does not mean that younger men are not prone. Erectile dysfunction is now common
                                among men between the age of 30-40 years.
                                <br/><br/>
                                When the man has erectile dysfunction, many couples no longer find balance in their
                                intimate life and often lock themselves away in silence, fraught with consequences.
                            </p>
                        </div>
                    </div>
                </div>

                <Stats to={shopUrl} data={statsData} quizButtonText={'SHOP'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <iframe className="youtubeIframe"
                                        src="https://www.youtube.com/embed/bdetAzCBgiw?loop=1&playlist=bdetAzCBgiw&rel=0"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen frameBorder="0"/>
                            </div>
                            <div className="col-12 text-center pb-5">
                                <Button to={shopUrl} style={{ width: 200 }}><LText k="SHOP"/></Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList3}
                        </div>
                    </div>
                </div>

                <Testimonials className="py-5" data={testimonialsData}/>
            </Page>
    )
  }
}

export default ErectileDysfunction
