import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../../components/core/Page'
import { fetchOrder } from '../../../actions/ecommerce/orders'
import moment from 'moment'
import { getHttpsImageUrl, getPhoneNumberWithCountryCode, getSearchParams } from '../../../utils'
import OrderDetailCard from '../../../components/cards/OrderDetailCard'
import OrderTrackingCard from '../../../components/cards/OrderTrackingCard'
import { Images, ORDER_STATUS, RAZORPAY_APP_KEY, trackPurchase } from '../../../constants'
import OrderItemCard from '../../../components/cards/OrderItemCard'
import Ecommerce from '../../../services/Ecommerce'

class Order extends PureComponent {
    static propTypes = {
      orders: PropTypes.object,
      fetchOrder: PropTypes.func
    };

    static defaultProps = {
      orders: {}
    };

    constructor (props) {
      super(props)
    }

    componentDidMount () {
      const { cid } = this.props.match.params
      if (cid) {
        this.props.fetchOrder({ cid })
      }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { orders, orders: { detail, detail: { checkout, status } }, history } = this.props
      const params = getSearchParams(history.location.search)

      if (!orders.isFetching && prevProps.orders.isFetching && !orders.error && !orders.retry) {
        if (checkout && checkout.razorpay_order_id && status === 'pending' && params.payment) {
          this.handleRazorpayPayment({ order: detail, method: params.method, wallet: params.wallet })
        }
      }
    }

    handleRazorpayPayment = ({ order, method, wallet }) => {
      const { profile, history, match, orders } = this.props
      const { cid } = this.props.match.params
      const options = {
        key_id: RAZORPAY_APP_KEY,
        order_id: order.checkout.razorpay_order_id,
        name: 'Misters.in',
        description: '',
        image: `https://misters.in${Images.logo.x2}`,
        handler: (response) => {
          Ecommerce.purchase({ order: orders.detail })
          trackPurchase(orders.detail)
          history.push(`${match.params.language}/checkout/success/?cid=${cid}`)
        },
        prefill: {
          name: order.shipping_address.name || '',
          contact: getPhoneNumberWithCountryCode(order.shipping_address.phone),
          email: order.shipping_address.email || '',
          method, // card, netbanking, wallet, emi, upi
          wallet // freecharge, payzapp, phonepe, airtelmoney, mobikwik, jiomoney
        },
        notes: {},
        theme: {
          color: '#FF4B53'
        }
      }
      const rzp1 = new Razorpay(options)
      rzp1.open()
    };

    render () {
      const { orders: { detail }, match: { params: { cid, language } }, history } = this.props

      return (
            <Page meta={{ path: `${language}/profile/orders/${cid}` }}>
                <div className='profile-container'>
                    <div className="py-3"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="pt-4 pb-1 text-center">Order Details</h3>
                                <p className="text-center text-muted">
                                    Ordered
                                    on {moment(detail.created_at).format('DD MMMM YYYY')} |&nbsp;
                                    {!!detail.status &&
                                    <span style={{ color: ORDER_STATUS[(detail.status).toUpperCase()].color }}>
                                        {detail.status.toUpperCase()}</span>} |
                                    #{detail.cid}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 offset-sm-1 col-sm-10 offset-lg-3 col-lg-6">
                                {!!Object.keys(detail).length &&
                                <OrderDetailCard order={detail} handleRazorpayPayment={this.handleRazorpayPayment}/>
                                }
                            </div>
                            <div className="col-12 py-4"/>
                            <div className="col-12 offset-sm-1 col-sm-10 offset-lg-3 col-lg-6">
                                {
                                    !!Object.keys(detail).length &&
                                    detail.cart.items.map(
                                      ({ id, item, quantity }, index) => (
                                            <OrderItemCard key={item.id} item={item}/>
                                      )
                                    )
                                }
                            </div>
                            <div className="col-12 py-4"/>
                            <div className="col-12 offset-sm-1 col-sm-10 offset-lg-3 col-lg-6">
                                {!!Object.keys(detail).length &&
                                <OrderTrackingCard order={detail}/>
                                }
                            </div>
                            <div className="col-12 py-4"/>
                        </div>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = state => ({
  orders: state.ecommerce.orders,
  profile: state.accounts.profile
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchOrder
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Order)
