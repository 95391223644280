
export default {
  product: {
    international_shipment: '/web-assets/images/global-distribution.png'
  },
  products: {
    ayurjosh: '/web-assets/images/products/josh.jpg',
    ayurbold: '/web-assets/images/products/bold.jpg',
    ayurperform: '/web-assets/images/products/perform.jpg',
    ayurhairpack: '/web-assets/images/products/hairpack.jpg',
    ayurimmunity: '/web-assets/images/products/immunity.jpeg',
    ayurshilajitpro: '/web-assets/images/products/shilajit.jpg',
    others: {
      baidyanath: '/web-assets/images/products/others/baidyanath.jpg',
      dabur: '/web-assets/images/products/others/dabur.jpg',
      zandu: '/web-assets/images/products/others/zandu.jpg'
    }
  },
  home: {
    man: '/web-assets/images/home/man-facing-front.jpg',
    image1: '/web-assets/images/home/image-1.jpg',
    image2: '/web-assets/images/home/image-2.jpg',
    badge: '/web-assets/images/home/badge.svg',
    porn_quiz: '/web-assets/images/home/quiz4.gif',
    confidence1: '/web-assets/images/home/misters-confidence-formula-1.svg',
    confidence2: '/web-assets/images/home/misters-confidence-formula-2.svg',
    confidence3: '/web-assets/images/home/misters-confidence-formula-3.svg',
    confidence4: '/web-assets/images/home/misters-confidence-formula-4.svg',
    confidence5: '/web-assets/images/home/misters-confidence-formula-5.svg',
    doctor1: '/web-assets/images/home/dr-abhishek-yadav-pic-1.jpg',
    doctor2: '/web-assets/images/home/dr-aditya-mohan-avasthi.jpg',
    doctor3: '/web-assets/images/home/dr-nikita-chaudhary.jpg',
    doctor4: '/web-assets/images/home/dr-ravneet.jpg',
    doctor5: '/web-assets/images/home/dr-simran-khalsa.jpg',
    ingredients: '/web-assets/images/home/ingredients.jpg',
    ingredientsIcon1: '/web-assets/images/home/ingredients-icon-1.svg',
    ingredientsIcon2: '/web-assets/images/home/ingredients-icon-2.svg',
    ingredientsIcon3: '/web-assets/images/home/ingredients-icon-3.svg',
    services1: '/web-assets/images/home/services-1.jpg',
    services2: '/web-assets/images/home/services-2.jpg',
    services3: '/web-assets/images/home/services-3.jpg'
  },
  logos: {
    favicon: '/web-assets/logos/Misters-logo-5.png',
    brand: '/web-assets/logos/Misters-logo-1.svg',
    brandLight: '/web-assets/logos/Misters-logo-6.svg',
    brandSmall: '/web-assets/logos/Misters-logo-4.svg',
    brandSmallLight: '/web-assets/logos/Misters-logo-7.svg',
    sulekha: '/web-assets/images/logo/sulekha.png',
    justdial: '/web-assets/images/logo/justdial.png'
  },
  pages: {
    hisScoreFigure1: '/web-assets/images/pages/his-score-figure.png',
    hisScoreFigure2: '/web-assets/images/pages/his-score-figure-2.png',
    hisScoreFigure3: '/web-assets/images/pages/his-score-figure-3.png'
  },
  whyMistersIcons: {
    icon1: '/web-assets/images/home/why-misters-icon-1.svg',
    icon2: '/web-assets/images/home/why-misters-icon-2.svg',
    icon3: '/web-assets/images/home/why-misters-icon-3.svg',
    icon4: '/web-assets/images/home/why-misters-icon-4.svg',
    icon5: '/web-assets/images/home/why-misters-icon-5.svg'
  }
}
