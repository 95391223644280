import {
  FETCH_COUPONS,
  FETCH_COUPONS_RESULT,
  FETCH_COUPONS_ERROR,
  FETCH_COUPONS_RETRY
} from '../../actions/ecommerce/coupons'

const initialState = {
  detail: {},
  list: [],
  isFetching: false,
  isFetchingList: false,
  isUpdating: false,
  isCreating: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUPONS:
      return { ...state, isFetchingList: true, error: false, message: null, retry: false }
    case FETCH_COUPONS_RESULT:
      return { ...state, isFetchingList: false, list: action.data }
    case FETCH_COUPONS_ERROR:
      return { ...state, isFetchingList: false, error: true, message: action.message }
    case FETCH_COUPONS_RETRY:
      return { ...state, isFetchingList: false, retry: true, message: action.message }

    default:
      return { ...state }
  }
}

export default reducer
