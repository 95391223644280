import React from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'
import { Images } from '../../../constants'

const OrderTrackingCard = function ({ order }) {
  const list = [
    {
      title: 'Order Placed',
      description: 'We have received your order',
      img: Images.orderTracking.created,
      action: ['created', 'confirm', 'packed', 'shipped', 'delivered'].includes(order.status)
    },
    {
      title: 'Order Confirmed',
      description: 'Your order has been confirmed',
      img: Images.orderTracking.confirm,
      action: ['confirm', 'packed', 'shipped', 'delivered'].includes(order.status)
    },
    {
      title: 'Packed and ready to pickup',
      description: 'Your order is ready to pick up',
      img: Images.orderTracking.packed,
      action: ['packed', 'shipped', 'delivered'].includes(order.status)
    },
    {
      title: 'Shipped',
      description: 'Your order has been shipped',
      img: Images.orderTracking.shipped,
      action: ['shipped', 'delivered'].includes(order.status)
    },
    {
      title: 'Delivered',
      description: 'Your order has been delivered',
      img: Images.orderTracking.delivered,
      action: ['delivered'].includes(order.status)
    }
  ]

  return (
        <div className="order-tracking-card">
            <ul className="list-group">
                {list.map((card, index) => {
                  return (
                        <li key={card.title} className={`list-group-item order-tracking-card-list ${card.action ? 'active' : ''}`}
                            style={{ zIndex: list.length - index }}>
                            <div className="order-tracking-card-icon">
                                <img src={card.img} alt="created"/>
                            </div>
                            <div className="order-tracking-card-content">
                                <h5>{card.title}</h5>
                                <p>{card.description}</p>
                            </div>
                        </li>
                  )
                })}
            </ul>
        </div>
  )
}

OrderTrackingCard.propTypes = {
  order: PropTypes.object
}

OrderTrackingCard.defaultProps = {
  order: {}
}

export default OrderTrackingCard
