import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../common/WhyMisters'
import Stats from '../common/Stats'
import Testimonials from '../common/Testimonials'
import { accordionList1, accordionList2 } from './accordions'

class PrematureEjaculation extends Component {
  componentDidMount () {

  }

  render () {
    const { match } = this.props
    const shopUrl = `${match.params.language}/shop`
    const statsData = [
      { title: 'Nearly', percentage: '40%', text: 'of married couples in India struggle with PE' },
      { title: 'Annually', percentage: '70%', text: 'of Premature Ejaculation cases are anxiety linked' },
      { title: 'Over', percentage: '60%', text: 'of sexually active Indian men complain of PE' }
    ]
    const testimonialsData = [
      {
        image: Images.testimonial.actor1,
        text: 'I got to know of misters.in while searching for solution to premature ejaculation. I then took the quiz and ordered the PE therapy kit, which was delivered to my home. It was a reassuring and discreet process, something which I liked, as I did not want to discuss such personal issues with too many people.',
        comment: 'A satisfied repeat buyer of Misters PE therapy'
      },
      {
        image: Images.testimonial.actor2,
        text: 'I did not like the experience of visiting the local sex clinic. I did not feel comfortable there and the discussion with the ‘expert’ was innuendo ridden. The quiz on misters.in is very straight forward and helped me get the best solution for my PE problem. Without beating around the bush!',
        comment: 'A first-time buyer of Misters.in PE therapy'
      },
      {
        image: Images.testimonial.actor3,
        text: 'The detailed information on premature ejaculation that I got on misters.in helped me tremendously. I then decided to take the quiz and a PE therapy box was recommended basis my answers in the quiz. I think I will go for regular repeat orders now!',
        comment: 'A Misters.in customer'
      }
    ]

    return (
            <Page meta={{
              title: 'PE, symptoms, treatments, causes by Misters',
              path: `${match.params.language}/premature-ejaculation/`,
              description: 'Research shows about 70% of Premature Ejaculation cases link to anxiety issues. Herein, a man ejaculates, releasing semen earlier than desired. PE can occur immediately upon penetration or in initial stages of sexual intercourse. View curated causes, symptoms and treatments for this condition in the Misters Premature Ejaculation guide. Ayurvedic herbs like Ashwagandha, Gokshura and Shilajit have proved beneficial in the past to manage PE. Potential risks, preventive measures, quick tips and natural remedies for impotence among men can be found on this page. Be silent no longer, Mister. Speak up, learn, treat and share.'
            }}>
                <div className="page-header-img" style={{ backgroundImage: `url('${Images.edHeader}')` }}>
                    <div className="page-header-bg-drop"/>
                    <div className="page-header-content">
                        <h1>Your guide to Premature Ejaculation (PE)</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <p>
                                In a survey done by ORG-IMS among Indian men, premature ejaculation or PE was found to
                                be the most common sexual complaint. In India, premature ejaculation is also known as
                                <em>‘dhat’</em>. This term was coined by Dr NN Wig in the 1960s. The origin of this term
                                is from
                                Sanskrit where <em>‘dhatus’</em> refers to bodily fluids.
                                <br/> <br/>
                                The International Society for Sexual Medicine defines premature ejaculation asz
                                <em>
                                    ‘Premature ejaculation is a male sexual dysfunction characterized by ejaculation
                                    which
                                    always or nearly always occurs prior to or within about one minute of vaginal
                                    penetration; and, inability to delay ejaculation on all or nearly all vaginal
                                    penetrations; and, negative personal consequences, such as distress, bother,
                                    frustration, and/or the avoidance of sexual intimacy.’
                                </em>
                            </p>
                            <h2>What is Premature Ejaculation (PE)?</h2>
                            <p>
                                Premature Ejaculation (PE) is a common sexual dysfunction, with one out of three men in
                                the age group of 18 to 59 years facing it at some point in time. In this condition, a
                                man ejaculates, or releases semen earlier than desired, i.e. either before or
                                immediately after penetration at the time of intercourse. Once sexually stimulated, the
                                brain sends signals to the man’s reproductive organ for releasing semen through the
                                penis. PE occurs when this timing of sending signals get disrupted.
                                <br/><br/>
                                Though there is no established time limit for defining ejaculation as premature, many
                                doctors consider ejaculation within a minute of arousal as Premature Ejaculation (PE).
                                However, it also depends on an individual’s perspective of the term ‘premature’.
                                <br/><br/>
                                Premature Ejaculation (PE) can happen as a temporary affliction which gets cured on its
                                own. When infrequent, Premature Ejaculation (PE) is often termed as rapid ejaculation,
                                premature climax or early ejaculation. However, if it recurs continuously for three
                                months, happens every time and becomes a cause of distress and leads to a lack of
                                interest in sex, one must seek medical help.
                                <br/><br/>
                                Earlier Premature Ejaculation (PE) was considered to have solely psychological causes.
                                However, today medical science has established that factors pertaining to both the body
                                and the mind can cause Premature Ejaculation (PE) and it is important to talk to an
                                expert in order to find the root cause. Social taboo and stigma often hold Indian men
                                back from taking medical help, even though it is a very common affliction and is a
                                completely treatable one.
                                <br/><br/>
                                A range of remedies exists for Premature Ejaculation (PE) cure, including, medicines,
                                sexual methods and psychological therapy. Treatment could be single pronged or combined,
                                as deemed fit by physiologists.
                                <br/><br/>
                                Treatment and therapy cure Premature Ejaculation (PE) in the majority of men. According
                                to doctors, the pivotal point in the remedial process is learning to relax. In some
                                cases, a couple of sessions with the doctor or the therapist is enough for improving the
                                condition.
                                <br/><br/>
                                Disclosure of facts like Premature Ejaculation (PE) being a common dysfunction among
                                men, and also that average time for ejaculation is just about five minutes – help in
                                reducing concerns and building confidence.
                            </p>
                        </div>
                    </div>
                </div>

                <Stats to={shopUrl} data={statsData} quizButtonText={'SHOP'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>
                {/* <div className="bg-light"> */}
                {/*    <div className="container"> */}
                {/*        <div className="row"> */}
                {/*            <div className="col-12 text-center py-5"> */}
                {/*                <img className="img-fluid" */}
                {/*                     src="https://dummyimage.com/600x400/000/fff" alt="tutorial"/> */}
                {/*            </div> */}
                {/*            <div className="col-12 text-center pb-5"> */}
                {/*                <Button>Take Quiz</Button> */}
                {/*            </div> */}
                {/*        </div> */}
                {/*    </div> */}
                {/* </div> */}
                <Testimonials className="py-5" data={testimonialsData}/>
            </Page>
    )
  }
}

export default PrematureEjaculation
