import React from 'react'
import PropTypes from 'prop-types'

class Image extends React.Component {
    static propTypes = {
      src: PropTypes.string,
      className: PropTypes.string,
      alt: PropTypes.string,
      isAsync: PropTypes.bool
    };

    static defaultProps = {
      isAsync: true,
      className: ''
    };

    render () {
      const { src, isAsync, className, alt } = this.props
      if (isAsync) {
        return (
          <img data-src={src} alt={alt} className={`lazyload ${className}`}/>
        )
      }
      return (
        <img src={src} alt={alt} className={className}/>
      )
    }
}

export default Image
