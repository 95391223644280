import {
  CREATE_LEAD,
  CREATE_LEAD_RESULT,
  CREATE_LEAD_ERROR,
  CREATE_LEAD_RETRY

} from '../../actions/clinic/lead'

const initialState = {
  detail: {},
  isCreating: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_LEAD:
      return { ...state, isCreating: true, error: false, retry: false, message: null }
    case CREATE_LEAD_RESULT:
      return { ...state, isCreating: false, detail: action.data }
    case CREATE_LEAD_ERROR:
      return { ...state, isCreating: false, error: true, message: action.message }
    case CREATE_LEAD_RETRY:
      return { ...state, isCreating: false, retry: true, message: action.message }

    default:
      return { ...state }
  }
}

export default reducer
