import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../components/core/Page'
import Button from '../../components/buttons/Button'
import { updateUserProfile } from '../../actions/accounts/profile'
import { ACCESS, Images, USER } from '../../constants'
import { removeStorageItem } from '../../utils'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import LText from '../../components/core/LText'

class Profile extends PureComponent {
    static propTypes = {
      profile: PropTypes.object,
      updateUserProfile: PropTypes.func
    };

    constructor (props) {
      super(props)

      this.state = {
        profile: props.profile.detail
      }
    }

    componentDidMount () {
    }

    handleProfileChange = (profile) => {
      this.setState({ profile })
    };

    handleProfileUpdate = () => {
      this.props.updateUserProfile({ name: this.state.profile.name })
    };

    handleLogoutClick = () => {
      const { match } = this.props
      removeStorageItem(USER)
      removeStorageItem(ACCESS)
      window.location.replace(match.params.language)
    };

    render () {
      const { match } = this.props
      const { profile } = this.state

      return (
            <Page meta={{
              path: `${match.params.language}/profile/`,
              title: 'Misters Ayurveda and sex quiz',
              description: 'Products on our website offer action-oriented care and the blog offers reliable expert advice on sexual health plus wellness.The Misters sex and Ayurveda quiz score entrusts us with individual information regarding your erection patterns, allergies, ejaculation timings, hair health, eating habits and behaviours. These records help us provide personalised care for each member with specific information about your lifestyle choices. Based on this information targeted recourse is suggested. We at Misters know that acknowledging the patterns is essential but fathoming them can unearth possibilities of vast treatment options that help us help you better. Go ahead, take the quiz!'
            }}>
                <div className='profile-container'>
                    <div className="py-3"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-right">
                                <Button onClick={this.handleLogoutClick}><LText k="LOGOUT"/></Button>
                            </div>
                            <div className="col-12">
                                <div className="profile-card">
                                    <img src={Images.testimonial.actor1}
                                         className="image-fluid"
                                         alt="Profile picture"/>
                                    <div className="profile-card-content">
                                        <h3>{profile.name || 'Mister'}</h3>
                                        <p className="profile-card-content-phone">{profile.phone_number}</p>
                                        <p className="profile-card-content-date">Joined on {moment(profile.date_joined).format('DD MMM YYYY')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-5">
                                <NavLink to={`${match.params.language}/profile/orders`}
                                         className="profile-list-item-card">
                                    <div className="profile-list-item-card-icon">
                                        <img src={Images.orders.order} alt="Orders"/>
                                    </div>
                                    <div className="profile-list-item-card-content">
                                        <h4>Your Orders</h4>
                                        <p>Track, return or buy again</p>
                                    </div>
                                </NavLink>
                                <NavLink to={`${match.params.language}/profile/addresses`} className="profile-list-item-card">
                                    <div className="profile-list-item-card-icon">
                                        <img src={Images.orders.address} alt="Addresses"/>
                                    </div>
                                    <div className="profile-list-item-card-content">
                                        <h4>Your Addresses</h4>
                                        <p>Add, Edit or remove your addresses</p>
                                    </div>
                                </NavLink>
                                <NavLink to={`${match.params.language}/profile/reports/`} className="profile-list-item-card">
                                    <div className="profile-list-item-card-icon">
                                        <img src={Images.orders.report} alt="Resports"/>
                                    </div>
                                    <div className="profile-list-item-card-content">
                                        <h4>Your Reports</h4>
                                        <p>Take a look at your past reports</p>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = state => ({
  profile: state.accounts.profile
})

const matchDispatchToProps = dispatch => bindActionCreators({
  updateUserProfile
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Profile)
