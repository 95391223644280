import api from '../api'
import { ACCESS, ACCESS_EXPIRE, ACCESS_EXPIRE_TIME, REFRESH, USER } from '../../constants'
import { setStorageItem, getMomentObject } from '../../utils'

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const UPDATE_USER_PROFILE_RESULT = 'UPDATE_USER_PROFILE_RESULT'
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR'
export const UPDATE_USER_PROFILE_RETRY = 'UPDATE_USER_PROFILE_RETRY'

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE'
export const FETCH_USER_PROFILE_RESULT = 'FETCH_USER_PROFILE_RESULT'
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR'
export const FETCH_USER_PROFILE_RETRY = 'FETCH_USER_PROFILE_RETRY'

export const USER_LOGIN_OTP = 'USER_LOGIN_OTP'
export const USER_LOGIN_OTP_RESULT = 'USER_LOGIN_OTP_RESULT'
export const USER_LOGIN_OTP_ERROR = 'USER_LOGIN_OTP_ERROR'
export const USER_LOGIN_OTP_RETRY = 'USER_LOGIN_OTP_RETRY'

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_RESULT = 'USER_LOGIN_RESULT'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'
export const USER_LOGIN_RETRY = 'USER_LOGIN_RETRY'

export const ANONYMOUS_USER_LOGIN = 'ANONYMOUS_USER_LOGIN'
export const ANONYMOUS_USER_LOGIN_RESULT = 'ANONYMOUS_USER_LOGIN_RESULT'
export const ANONYMOUS_USER_LOGIN_ERROR = 'ANONYMOUS_USER_LOGIN_ERROR'
export const ANONYMOUS_USER_LOGIN_RETRY = 'ANONYMOUS_USER_LOGIN_RETRY'

export const SET_USER_PROFILE = 'SET_USER_PROFILE'

export const updateUserProfile = (data) => async dispatch => {
  try {
    dispatch({ type: UPDATE_USER_PROFILE })
    const res = await api.accounts.patchUser(data)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: UPDATE_USER_PROFILE_RESULT, data: result })
    } else {
      dispatch({ type: UPDATE_USER_PROFILE_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: UPDATE_USER_PROFILE_RETRY, message: e.message })
  }
}

export const fetchUserProfile = () => async dispatch => {
  try {
    dispatch({ type: FETCH_USER_PROFILE })
    const res = await api.accounts.getUser()
    const result = await res.json()
    if (res.status === 200) {
      setStorageItem(USER, JSON.stringify(result))
      dispatch({ type: FETCH_USER_PROFILE_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_USER_PROFILE_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_USER_PROFILE_RETRY, message: e.message })
  }
}

export const userLoginOtp = (data) => async dispatch => {
  try {
    dispatch({ type: USER_LOGIN_OTP })
    const res = await api.accounts.otp(data)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: USER_LOGIN_OTP_RESULT, data: result })
    } else {
      dispatch({ type: USER_LOGIN_OTP_ERROR, message: result })
    }
  } catch (e) {
    console.log(e)
    dispatch({ type: USER_LOGIN_OTP_RETRY, message: e.message })
  }
}

export const userLogin = ({ email, ...data }) => async dispatch => {
  try {
    dispatch({ type: USER_LOGIN })
    const res = await api.accounts.login(data)
    const result = await res.json()
    if (res.status === 200) {
      setStorageItem(USER, JSON.stringify(result.user))
      setStorageItem(ACCESS, result.token.access)
      setStorageItem(REFRESH, result.token.refresh)
      setStorageItem(ACCESS_EXPIRE, getMomentObject().add(ACCESS_EXPIRE_TIME.value, ACCESS_EXPIRE_TIME.unit).toISOString())
      dispatch({ type: USER_LOGIN_RESULT, data: result })
    } else {
      dispatch({ type: USER_LOGIN_ERROR, message: result })
    }
  } catch (e) {
    console.log(e)
    dispatch({ type: USER_LOGIN_RETRY, message: e.message })
  }
}

export const anonymousLogin = () => async dispatch => {
  try {
    dispatch({ type: ANONYMOUS_USER_LOGIN })
    const res = await api.accounts.anonymousLogin()
    const result = await res.json()
    if (res.status === 200) {
      // dispatch(setCookie(ACCESS, result.token.access));
      setStorageItem(USER, JSON.stringify(result.user))
      setStorageItem(ACCESS, result.token.access)
      setStorageItem(REFRESH, result.token.refresh)
      setStorageItem(ACCESS_EXPIRE, getMomentObject().add(ACCESS_EXPIRE_TIME.value, ACCESS_EXPIRE_TIME.unit))
      dispatch({ type: ANONYMOUS_USER_LOGIN_RESULT, data: result })
    } else {
      dispatch({ type: ANONYMOUS_USER_LOGIN_ERROR, message: result })
    }
  } catch (e) {
    console.log(e)
    dispatch({ type: ANONYMOUS_USER_LOGIN_RETRY, message: e.message })
  }
}

export const setUserProfile = (data) => ({
  type: SET_USER_PROFILE,
  data
})
