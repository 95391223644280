import React from 'react'
import { Images } from '../../constants'
import Page from '../../components/core/Page'

export default class NotFound extends React.Component {
  render () {
    const { match } = this.props
    return (
            <Page meta={{ path: `${match.params.language}` }}
                  handleLogoClick={() => this.setState({ showContinueMessage: true })}>
                <div className="py-5"/>
                <div className="py-5 text-center">
                    <img src={Images.error404}
                         style={{ width: '100%', maxWidth: 800 }}
                         alt="404"/>
                </div>
            </Page>
    )
  }
}
