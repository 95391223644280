// External Imports
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import moment from 'moment'
// My Imports
import Image from '../../core/Image'
import StarRatings from 'react-star-ratings'
import AliceCarousel from 'react-alice-carousel'
import { Images } from '../../../constants'

const ProductReviews = ({ reviews }) => {
  const [reviewCarouselIndex, setReviewCarouselIndex] = useState(0)
  const reviewCarouselItems = reviews.map(review => (
    <div key={review.id} className="px-2 text-center">
      <div className="homepage-review-card">
        <div className="homepage-review-card-header">
          <div className="homepage-review-card-image">
            <Image src={Images.testimonial.actor1} alt={review.name}/>
          </div>
          <div className="homepage-review-card-content">
            <div className="homepage-review-card-title">
              {review.name}
            </div>
            {/* <div className="homepage-review-card-subtitle"> */}
            {/*  {review.occupation} | {review.city} | {review.age} years */}
            {/* </div> */}
            <div className="homepage-review-card-timestamp">
              {`Using since ${moment(review.created_at).format('MMM, YYYY')}`}
            </div>
            <StarRatings
              rating={review.star}
              starDimension="15px"
              starSpacing="3px"
              starRatedColor="#FF7200"
              isSelectable={false}
              numberOfStars={5}
              name='rating'
            />
          </div>
        </div>
        <div className="homepage-review-card-description">
          {review.text}
        </div>
      </div>
    </div>
  ))

  const onReviewSlideChanged = (e) => {
    setReviewCarouselIndex(e.item)
  }

  if (!reviews.length) {
    return null
  }

  return (
    <div className="bg-light">
      <div className="container pb-5">
        <div className="row pt-5">
          <div className="col-12">
            <h2 className="home-page-subtitle text-center">Customer Reviews</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <AliceCarousel mouseTrackingEnabled touchTrackingEnabled
                           autoPlayInterval={6000}
                           responsive={{ 0: { items: 1 }, 768: { items: 2 }, 992: { items: 3 } }}
                           autoPlay={true}
                           items={reviewCarouselItems}
                           startIndex={reviewCarouselIndex}
                           stopAutoPlayOnHover={true}
                           buttonsDisabled={true}
                           dotsDisabled={false}
                           onSlideChanged={onReviewSlideChanged}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ProductReviews.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  reviews: PropTypes.array
}

ProductReviews.defaultProps = {
  reviews: []
}
export default withRouter(ProductReviews)
