// External Imports
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
// My Imports
import LText from '../../core/LText'
import { Images } from '../../../constants'
import { getLanguageTranslation } from '../../../utils'

const HowAyurvedaWorks = ({ match }) => {
  const language = match.params.language
  const data = {
    guideline1: 'Ayurvedic science works with a holistic understanding of the body and the mind.',
    guideline1_hi: 'आयुर्वेद में शरीर और मन दोनों को समग्रता से समझकर ही बीमारी या समस्या का इलाज खोजा जाता है।',
    guideline1_kn: 'ಆಯುರ್ವೇದೀಯ ವಿಜ್ಞಾನವು ದೇಹ ಮತ್ತು ಮನಸ್ಸಿನ ಸಮಗ್ರ ಅರ್ಥವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ. ',
    guideline1_te: 'ఆయుర్వేద విజ్ఞానం శరీరం మరియు మనస్సు యొక్క పూర్తి అవగాహనతో పనిచేస్తుంది.',

    guideline2: 'It works by prevention and treatment of the root cause, rather than responding to symptoms of the issue.',
    guideline2_hi: 'आयुर्वेद सिर्फ लक्षणों पर नहीं, बल्कि रोग को जड़ से समाप्त करता है।',
    guideline2_kn: 'ಇದು ಸಮಸ್ಯೆಯ ಲಕ್ಷಣಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುವ ಬದಲಾಗಿ ಮೂಲ ಕಾರಣವನ್ನು ತಡೆಗಟ್ಟುವ ಮೂಲಕ ಮತ್ತು ಗುಣಪಡಿಸುವ ಮೂಲಕ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ.',
    guideline2_te: 'ఇది సమస్య యొక్క లక్షణాలు తగ్గించటానికి స్పందించటం కన్నా, సమస్య యొక్క మూల కారణానికి వెళ్ళి అక్కడ నివారణకి, చికిత్సకి ప్రయత్నిస్తుంది.',

    guideline3: 'We recommend 45-60 days of continued ayurvedic treatment combined with healthy eating and physical exercise for best results.',
    guideline3_hi: 'हमारा सुझाव है की 45 से 60 दिन तक लगातार इस आयुर्वेदिक सप्लीमेंट्स का प्रयोग करें साथ ही अच्छे खानपान और थोड़ी शारीरिक एक्सरसाइज पर ध्यान दें। समस्या को जड़ से मिटाने के लिए आगे भी सेवन करते रहें।',
    guideline3_kn: 'ಆರೋಗ್ಯಕರ ಆಹಾರ ಸೇವನೆ ಮತ್ತು ದೈಹಿಕ ವ್ಯಾಯಾಮಗಳೊಂದಿಗೆ 45-60 ದಿನಗಳ ನಿರಂತರ ಆಯುರ್ವೇದೀಯ ಚಿಕಿತ್ಸೆಯು ನಿಮಗೆ ಉತ್ತಮ ಫಲಿತಾಂಶಗಳನ್ನು ನೀಡಬಲ್ಲದು',
    guideline3_te: 'ఉత్తమ ఫలితాల కోసం ఆరోగ్యకరమైన ఆహారం మరియు శారీరక వ్యాయామంతో కలిపి 45-60 రోజుల నిరంతర ఆయుర్వేద చికిత్సను మేము సిఫార్సు చేస్తున్నాము.'
  }
  return (
    <div className="ayurveda-work">
      <div className="container">
        <div className="row col-12 py-5 justify-content-center">
          <h2 className="home-page-subtitle">
            <LText k="HOW_DOES_AYURVEDA_WORK"/>
          </h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <img className="w-100" src={Images.therapy.ayurvedaWork}
                 alt="how does ayurveda work"/>
          </div>
          <div className="col-12 col-md-6">
            <ul className="list-group ayurveda-work-list-container">
              <li className="list-group-item ayurveda-work-list">
                <div className="ayurveda-work-content">
                  <img className="ayurveda-work-icon" src={Images.icons.iconBody}
                       alt="icon body"/>
                  <p>{getLanguageTranslation(data, 'guideline1', language)}</p>
                </div>
              </li>

              <li className="list-group-item ayurveda-work-list">
                <div className="ayurveda-work-content">
                  <img className="ayurveda-work-icon" src={Images.icons.iconHand}
                       alt="icon hand"/>
                  <p>{getLanguageTranslation(data, 'guideline2', language)}</p>
                </div>
              </li>

              <li className="list-group-item ayurveda-work-list pb-0">
                <div className="ayurveda-work-content">
                  <img className="ayurveda-work-icon" src={Images.icons.iconApple}
                       alt="icon apple"/>
                  <p>{getLanguageTranslation(data, 'guideline3', language)}</p>
                </div>
                <div className="ayurveda-work-time-container">
                  <iframe className="ayurveda-work-youtube mt-3 mb-5"
                          src="https://www.youtube.com/embed/Lfk4ZiI0k0A?loop=1&playlist=Lfk4ZiI0k0A&rel=0"
                          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen frameBorder="0"/>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

HowAyurvedaWorks.propTypes = {
  match: PropTypes.object
}

export default withRouter(HowAyurvedaWorks)
