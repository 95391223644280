// External Imports
import React, { PureComponent, Component } from 'react'
import PropTypes from 'prop-types'

import Ripple from '../loadings/Ripple'

export default class LoadingCard extends Component {
    static propTypes = {
      text: PropTypes.string,
      language: PropTypes.any
    };

    static defaultProps = {
    };

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      // if (this.props.choices)
    }

    render () {
      const { text } = this.props

      return (
            <div className='loading-card'>
                <Ripple show={true}/>
                <p>{text}</p>
            </div>
      )
    }
}
