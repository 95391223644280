import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2, accordionList3 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import { Link, NavLink } from 'react-router-dom'

class Bhringraj extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props

    return (
            <Page meta={{
              title: 'Be bold in bed with Bhringraj | Misters',
              path: `${match.params.language}/ingredient/bhringraj/`,
              description: 'Conventional medicines play a vital role in maintaining the good health of any individual. According to recent studies, about three quarters of the world\'s population is dependent upon different parts of medicinal plants for their physical and mental well-being. In India, one such well known plant is Bhringraj that is also referred to as the “King of Hair”. Bhringraj is widely famous by the name ‘false daisy’ as well. The frequently asked questions (FAQ’s) around Bhringraj are its uses, benefits, alternative names, description, where it can be found, its ayurvedic properties and the like. At Misters, we aim to answer all this and more…'
            }}>
                <div className="ingredient-header-img"
                     style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>Be bold in bed with Bhringraj</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What is Bhringraj?</h2>
                            <p>
                                Bhringraj is an <b>essential herb</b> used in the traditional Indian system of ayurvedic
                                medicine. The science of Ayurveda has provided individuals with <b>multiple health
                                benefits</b> in the past and continues to do so. The leaf extract of Bhringraj is
                                considered as a <b>powerful liver tonic</b>, a <b>rejuvenator</b> and one of the best
                                herbs for <b>hair health</b>. There has also been data to support that Bhringraj is
                                capable of providing symptomatic relief when it comes to certain conditions like gastric
                                pain, nausea and vomiting.
                            </p>
                            <p className="text-center font-weight-bold">
                                <span>To know more about Bhringraj, </span>
                                <a className="ingredient-blog-links" target="_blank"
                                         href={`https://misters.in${match.params.language}/blog/What-is-Bhringraj`}>click here</a>
                            </p>
                            <br/>
                            <h2>Where is Bhringraj found?</h2>
                            <p>
                                Bhringraj grows as a weed that is found in <b>tropical and sub – tropical regions all
                                around the globe</b>.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker image={Images.ingredient.bhringrajDescription} to={shopUrl}
                                          buttonText={'SHOP'}/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList3}
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

export default Bhringraj
