// External Imports
import { combineReducers } from 'redux'
// My Imports
import bot from './bot'
import report from './report'

export default combineReducers({
  bot,
  report
})
