import api from '../api'

export const FETCH_CLINIC = 'FETCH_CLINIC'
export const FETCH_CLINIC_RESULT = 'FETCH_CLINIC_RESULT'
export const FETCH_CLINIC_ERROR = 'FETCH_CLINIC_ERROR'
export const FETCH_CLINIC_RETRY = 'FETCH_CLINIC_RETRY'

export const fetchClinic = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_CLINIC })
    const res = await api.clinic.clinic(data)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_CLINIC_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_CLINIC_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_CLINIC_RETRY, message: e.message })
  }
}
