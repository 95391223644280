// External Imports
import { combineReducers } from 'redux'
// My Imports
import posts from './posts'
import doctors from './doctors'

export default combineReducers({
  posts,
  doctors
})
