import api from '../api'

export const FETCH_OFFERS = 'FETCH_OFFERS'
export const FETCH_OFFERS_RESULT = 'FETCH_OFFERS_RESULT'
export const FETCH_OFFERS_ERROR = 'FETCH_OFFERS_ERROR'
export const FETCH_OFFERS_RETRY = 'FETCH_OFFERS_RETRY'

export const fetchOffers = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_OFFERS })
    const res = await api.ecommerce.offers.list(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_OFFERS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_OFFERS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_OFFERS_RETRY, message: e.message })
  }
}
