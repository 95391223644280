import React, { PureComponent } from 'react'
import { Images } from '../../../constants'

export default class CheckoutFooter extends PureComponent {
    static propTypes = {
    };

    componentDidMount () {

    }

    render () {
      return (
            <footer className="checkout-footer">
                <div className="container py-2 text-center">
                    <div className="footer-payment-images">
                        <div className="footer-payment-cod" style={{ backgroundImage: `url('${Images.paymentMethods}')` }}/>
                        <div className="footer-payment-nb" style={{ backgroundImage: `url('${Images.paymentMethods}')` }}/>
                        <div className="footer-payment-ssl" style={{ backgroundImage: `url('${Images.paymentMethods}')` }}/>
                        <div className="footer-payment-visa" style={{ backgroundImage: `url('${Images.paymentMethods}')` }}/>
                        <div className="footer-payment-rupay" style={{ backgroundImage: `url('${Images.paymentMethods}')` }}/>
                        <div className="footer-payment-mc" style={{ backgroundImage: `url('${Images.paymentMethods}')` }}/>
                        <div className="footer-payment-bhim" style={{ backgroundImage: `url('${Images.paymentMethods}')` }}/>
                    </div>
                </div>
            </footer>
      )
    }
}
