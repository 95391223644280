import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'

class HairMask extends Component {
  componentDidMount () {

  }

  render () {
    const { match } = this.props
    const shopUrl = `${match.params.language}/therapy/ayurhairpack`
    const buttonText = 'BUY_NOW'

    return (
            <Page meta={{
              title: 'Hair care, the Ayurvedic way | Misters',
              path: `${match.params.language}/ingredient/hair-bhringraj`,
              description: 'An individual sheds and regrows hair cyclically, throughout their lives. Although, hair care can be challenging given conditions like Androgenic Alopecia or Male Pattern Baldness that are now becoming increasingly common among men across all ages. The Misters.in Hair Growth Pack for Men paves a way for natural, long-lasting hair health achieved through a proprietary medicinal blend of 17 plants such as Neem, Bhringraj, Shilajit, Amla, Lemongrass and more, each having scientifically proven benefits for your scalp, roots, follicles and overall hair. The frequently asked questions (FAQ’s) pertaining to this topic are mentioned below. Stay informed about your health by reading further Mister!'
            }}>
                <div className="ingredient-header-img"
                     style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>Hair care, the Ayurvedic way</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What is the Misters.in Hair Growth Pack for Men?</h2>
                            <p>
                                Mister.in hair growth pack is the first ayurvedic hair mask formulated for men suffering
                                from hair loss and androgenic alopecia (Male Pattern Baldness), with an amazing blend of
                                17 medicinal plants having recorded therapeutic effects for these conditions. For many
                                years, Ayurveda or conventional system of medicine has been preferred over allopathy
                                when dealing with chronic conditions like androgenic alopecia, as allopathic medicines
                                used for treating these conditions have side effects and lower incidence of efficacy.
                            </p>
                            <br/>
                            <h2>How does the hair cycle function?</h2>
                            <p>
                                Hair is lost and replaced cyclically. Follicles undergo corresponding cyclic phases of
                                growth, involution, quiescence and regeneration. The growth phase (anagen) lasts for 3-5
                                years. As hair elongation is relatively constant at 1 cm per month, the duration of the
                                growth phase is the primary determinant of final hair length. At the end of anagen, the
                                involutional phase (catagen) lasts for a few weeks. The period of hair follicle
                                quiescence (telogen) that follows lasts approximately 3 months. Hair follicle
                                regeneration occurs in approximately the first week of anagen and once regenerated, the
                                anagen phase continues until the hair reaches its final (possibly predetermined) length.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker image={Images.ingredient.hair_growth_cycle} to={shopUrl} buttonText={buttonText}/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <iframe className="youtubeIframe"
                                        src="https://www.youtube.com/embed/YkjefnmjQRQ?loop=1&list=PLylNn-FPIdEm58qwHU5QfYIrO3XRS--Tw&rel=0"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen frameBorder="0"/>
                            </div>
                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={buttonText}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

export default HairMask
