import {
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_RESULT,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_RETRY,

  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_RESULT,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_RETRY,

  USER_LOGIN,
  USER_LOGIN_RESULT,
  USER_LOGIN_ERROR,
  USER_LOGIN_RETRY,

  USER_LOGIN_OTP,
  USER_LOGIN_OTP_RESULT,
  USER_LOGIN_OTP_ERROR,
  USER_LOGIN_OTP_RETRY,

  ANONYMOUS_USER_LOGIN,
  ANONYMOUS_USER_LOGIN_RESULT,
  ANONYMOUS_USER_LOGIN_ERROR,
  ANONYMOUS_USER_LOGIN_RETRY,

  SET_USER_PROFILE
} from '../../actions/accounts/profile'
import { getStorageItem } from '../../utils'
import { USER } from '../../constants'

let detail = {}
try {
  detail = JSON.parse(getStorageItem(USER, '{}'))
} catch (e) {
  detail = {}
}

const initialState = {
  detail,
  token: {},
  isFetching: false,
  isFetchingLoginOTP: false,
  isFetchingLogin: false,
  isFetchingAnonymous: false,
  isUpdating: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_PROFILE:
      return { ...state, isUpdating: true, error: false, message: null, retry: false }
    case UPDATE_USER_PROFILE_RESULT:
      return { ...state, isUpdating: false, detail: action.data }
    case UPDATE_USER_PROFILE_ERROR:
      return { ...state, isUpdating: false, error: true, message: action.message }
    case UPDATE_USER_PROFILE_RETRY:
      return { ...state, isUpdating: false, retry: true, message: action.message }

    case FETCH_USER_PROFILE:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_USER_PROFILE_RESULT:
      return { ...state, isFetching: false, detail: action.data }
    case FETCH_USER_PROFILE_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_USER_PROFILE_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case USER_LOGIN_OTP:
      return { ...state, isFetchingLoginOTP: true, error: false, retry: false, message: null }
    case USER_LOGIN_OTP_RESULT:
      return { ...state, isFetchingLoginOTP: false }
    case USER_LOGIN_OTP_ERROR:
      return { ...state, isFetchingLoginOTP: false, error: true, message: action.message }
    case USER_LOGIN_OTP_RETRY:
      return { ...state, isFetchingLoginOTP: false, retry: true, message: action.message }

    case USER_LOGIN:
      return { ...state, isFetchingLogin: true, error: false, retry: false, message: null }
    case USER_LOGIN_RESULT:
      return { ...state, isFetchingLogin: false, detail: action.data.user, token: action.data.token }
    case USER_LOGIN_ERROR:
      return { ...state, isFetchingLogin: false, error: true, message: action.message }
    case USER_LOGIN_RETRY:
      return { ...state, isFetchingLogin: false, retry: true, message: action.message }

    case ANONYMOUS_USER_LOGIN:
      return { ...state, isFetchingAnonymous: true, error: false, message: null, retry: false }
    case ANONYMOUS_USER_LOGIN_RESULT:
      return { ...state, isFetchingAnonymous: false, detail: action.data.user, token: action.data.token }
    case ANONYMOUS_USER_LOGIN_ERROR:
      return { ...state, isFetchingAnonymous: false, error: true, message: action.message }
    case ANONYMOUS_USER_LOGIN_RETRY:
      return { ...state, isFetchingAnonymous: false, retry: true, message: action.message }

    case SET_USER_PROFILE:
      return { ...state, detail: action.data }
    default:
      return { ...state }
  }
}

export default reducer
