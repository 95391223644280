import { Images } from '../../constants'

export default [
  {
    type: 'application/ld+json',
    innerHTML: `
        {
            "@context": "http://www.schema.org",
            "@type": "Corporation",
            "name": "Misters",
            "url": "https://misters.in/",
            "logo": "https://misters.in${Images.logo.big1x}",
            "image": "https://misters.in{Images.homepage}",
            "description": "Misters.in is a men's confidence company.",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Misters, G-4/8, DLF Phase 1",
                "postOfficeBoxNumber": "Sector 26",
                "addressLocality": "Gurugram",
                "addressRegion": "Haryana",
                "postalCode": "122002",
                "addressCountry": "India"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "18001219555",
                "contactType": "General"
            },
            "sameAs": [
                "https://misters.in/en/",
                "https://misters.in/hi/",
                "https://www.facebook.com/mistersdotin/",
                "https://www.linkedin.com/company/misters/",
                "https://www.instagram.com/mistersdotin/"
            ]
        } 
        `
  },
  {
    type: 'application/ld+json',
    innerHTML: `
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Misters",
          "url": "https://misters.in/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://misters.in/en/blog?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }
        `
  }
]
