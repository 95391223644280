import React from 'react'
import * as PropTypes from 'prop-types'

export default class TeamCard extends React.Component {
    static propTypes = {
      className: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string
    };

    static defaultProps = {
      className: '',
      image: '',
      name: '',
      description: ''
    };

    componentWillUnmount () {
    }

    render () {
      const { className, image, name, description, ...props } = this.props

      return (
            <div className={`team-member-container ${className}`} {...props}>
                <div className="team-member">
                    <img src={image} alt=""/>
                    <div className="team-member-description">
                        {!!name && <h5>{name}</h5>}
                    </div>
                </div>
                <div className="team-member-body">
                    {!!description && <p dangerouslySetInnerHTML={{ __html: description }}/>}
                </div>
            </div>

      )
    }
}
