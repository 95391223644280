import React from 'react'
import PropTypes from 'prop-types'
import StarRatings from 'react-star-ratings'

export default class TherapyRating extends React.PureComponent {
    static propTypes = {
      rating: PropTypes.number.isRequired,
      ratingCount: PropTypes.number,
      showCount: PropTypes.bool,
      starDimension: PropTypes.string
    };

    static defaultProps = {
      showCount: false,
      starDimension: '18px'
    }

    render () {
      const { rating, ratingCount, showCount, starDimension } = this.props
      return (
            <div className="therapy-rating">
                <div className="d-flex align-items-center">
                    {showCount && <span className="rating-number">{rating.toFixed(1)}</span>}
                    <StarRatings
                        rating={rating || 0}
                        starDimension={starDimension}
                        starSpacing="3px"
                        starRatedColor="#E30A17"
                        isSelectable={false}
                        numberOfStars={5}
                        name='rating'
                    />
                </div>
                <p>Based on {ratingCount} reviews</p>
            </div>
      )
    }
}
