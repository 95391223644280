import moment from 'moment'
import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import Page from '../../../components/core/Page'
import { NavLink } from 'react-router-dom'
import { fetchUserReports } from '../../../actions/report/report'
import { Images } from '../../../constants'

class Reports extends PureComponent {
    static propTypes = {
      reports: PropTypes.object,
      fetchUserReports: PropTypes.func
    };

    constructor (props) {
      super(props)
    }

    componentDidMount () {
      this.props.fetchUserReports()
    }

    render () {
      const { report, match } = this.props

      return (
            <Page meta={{ path: `${match.params.language}/profile/reports/` }}>
                <div className='profile-container'>
                    <div className="py-3"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="py-4 text-center">Your Reports</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                {
                                    report.list.map(
                                      obj => (
                                            <NavLink to={`${match.params.language}/reports/?id=${obj.report_id}`}
                                                     className="profile-list-item-card" key={obj.id}>
                                                <div className="profile-list-item-card-icon">
                                                    <img src={Images.orders.report} alt="Report"/>
                                                </div>
                                                <div className="profile-list-item-card-content">
                                                    <h4>{moment(obj.created_at).format('DD MMM YYYY')}</h4>
                                                    <p>{obj.name}</p>
                                                    <p>#{obj.report_id}</p>
                                                </div>
                                            </NavLink>
                                      )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = state => ({
  report: state.report.report
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchUserReports
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Reports)
