import React from 'react'
import StarRatings from 'react-star-ratings'
import FormInput from '../../inputs/FormInput'
import Button from '../../buttons/Button'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as PropTypes from 'prop-types'

import { createReview } from '../../../actions/ecommerce/reviews'

class ReviewForm extends React.Component {
    static propTypes = {
      reviews: PropTypes.object,
      product: PropTypes.any,
      createReview: PropTypes.func,
      handleClose: PropTypes.func
    };

    static defaultProps = {
      reviews: {}
    };

    constructor (props) {
      super(props)

      this.state = {
        review: {
          product_id: props.product,
          star: 5,
          name: '',
          text: ''
        },
        message: undefined
      }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { reviews: { isCreating, error, retry, message }, handleClose } = this.props

      if (!isCreating && prevProps.reviews.isCreating && !error && !retry) {
        handleClose()
      }

      if (!isCreating && prevProps.reviews.isCreating && error && !retry) {
        this.setState({ message })
      }
    }

    handleReviewChange = (review) => {
      this.setState({ review })
    };

    handleReviewSave = () => {
      const { review } = this.state
      this.props.createReview(review)
    };

    render () {
      const { review, message } = this.state

      return (
            <div className="row mt-3">
                <div className="col-12 pb-3">
                    <h5 className="text-center">Write a Review</h5>
                </div>
                <div className="col-12 pb-3">
                    <FormInput placeholder="Name"
                               onChange={(e) => this.handleReviewChange({ ...review, name: e.target.value })}
                               error={!!message && message.name}
                               value={review.name}/>
                </div>
                <div className="col-12 pb-3">
                    <FormInput placeholder="Text"
                               as={'textarea'}
                               onChange={(e) => this.handleReviewChange({ ...review, text: e.target.value })}
                               error={!!message && message.text}
                               value={review.text}/>
                </div>
                <div className="col-12 pb-3">
                    <StarRatings
                        rating={review.star}
                        starDimension="20px"
                        starSpacing="5px"
                        starRatedColor="#FF0000"
                        isSelectable={true}
                        numberOfStars={5}
                        name='rating'
                        changeRating={(star) => this.handleReviewChange({ ...review, star })}
                    />
                </div>
                <div className="col-12">
                    <Button variant="secondary-outline" style={{ minWidth: '150px' }}
                            onClick={this.handleReviewSave}>Submit</Button>
                </div>
            </div>
      )
    }
}

const mapStateToProps = state => ({
  reviews: state.ecommerce.reviews
})

const matchDispatchToProps = dispatch => bindActionCreators({
  createReview
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(ReviewForm)
