import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import Button from '../../../components/buttons/Button'
import WhyMisters from '../common/WhyMisters'
import Stats from '../common/Stats'
import { accordionList1, accordionList2, accordionList3, accordionList4, accordionList5 } from './accordions'
import LText from '../../../components/core/LText'
import ImageBreaker from '../../../components/breakers/ImageBreaker'

class Hairfall extends Component {
  componentDidMount () {

  }

  render () {
    const { match } = this.props
    const buyNowUrl = `${match.params.language}/therapy/ayurhairpack/`

    const statsData = [
      { title: 'More than', percentage: '60%', text: 'of indian men suffer from hair loss' },
      { title: 'Nearly', percentage: '17%', text: 'of indian men, have dandruff related problems' },
      { title: 'Over', percentage: '50%', text: 'of Indian males are affected by hair balding issues' }
    ]

    return (
            <Page meta={{
              title: 'Misters hairfall guide',
              path: `${match.params.language}/hairfall/`,
              description: 'Androgenetic Alopecia or male pattern baldness accounts for more than 95% of hair loss among men. Male pattern baldness refers to hormonal alterations in men causing hair loss in the scalp region. Potential risks, preventive measures, quick tips and natural ayurvedic remedies for hair loss among men can be found on this page. High levels of stress, anxiety and hereditary causes are contributors. Loss of confidence and self-esteem in Misters are some after effects. View curated causes, symptoms and treatments for these conditions in the complete Misters hairfall guide. Be silent no longer, Mister. Speak up, learn, treat and share.'
            }}>
                <div className="page-header-img" style={{ backgroundImage: `url('${Images.edHeader}')` }}>
                    <div className="page-header-bg-drop"/>
                    <div className="page-header-content">
                        <h1>The Misters’ guide to your hair problems</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What is male pattern baldness?</h2>
                            <p>
                                Male pattern baldness, also known as androgenic alopecia, is a hormonal and genetic
                                condition in men due to which men start losing hair in a patterned manner after a
                                certain age. Although it is mostly seen to affect middle-aged and older men, the
                                occurrence of male pattern baldness is possible at any age. Some of the early signs of
                                this problem, like diffuse thinning or a receding hairline is seen in men of the age
                                group of the 20s and 30s as well. Interestingly, the factor that decides the type of
                                hair loss one experiences is mostly genetic, as studies have shown that twins lose their
                                hair at the same age, over the same period of time and in the same pattern.
                                <br/>
                                <br/>
                                Men who have seen male pattern baldness in their family or among close relatives are at
                                a higher risk of adopting genetics, especially when the balding members of the family
                                are on the maternal side. With genetics as a cause to baldness, there are certain
                                patterns, especially in male patients that have been observed over time.
                                <br/>
                                <br/>
                                <strong>Receding Hairline:</strong> Receding hairline which is also termed as the
                                bitemporal recession is characterised by the triangular portion of hair at the front of
                                the scalp, that a person slowly loses over each temple. This starts with the anterior
                                hairline and moves backwards creating a ‘M’ shaped pattern. This shape of a receding
                                hairline is the most familiar among men who have started showing symptoms of male
                                pattern baldness.
                                <br/>
                                <br/>
                                This can precede other forms of hair loss for many years. In fact, this is the most
                                common hair loss pattern seen in boys as they hit puberty. However, at this age, hair
                                loss is usually quite mild and, in a few years, approximately 5% of men grow back the
                                straight anterior hairline that they had in their boyhood.
                                <br/>
                                <br/>
                                <strong>Bald Patch:</strong> Another form of hair loss is the growth of a bald patch
                                over the crown of the head. This patch spreads out circularly in all directions creating
                                a visible circle of baldness, in most cases. In some cases, it is also seen that the
                                area below the bald patch remains intact, creating a sort of hoof shape.
                                <br/>
                                <br/>
                                Studies have shown that when hair close to an increasingly balding area is transplanted
                                to another part of the body, the follicles continue to weaken and shrink, and to
                                eventually fall out as though they were still on the head.
                                <br/>
                                <br/>
                                <strong>Hair Thinning:</strong> Thinning is the third common form of hair loss, which
                                generally occurs at the top of the head and affects more than 85% of men as they age.
                                This form of hair loss is also common in women, affecting over 75% of the female
                                population.
                                <br/>
                                <br/>
                                Thinning can cause hair loss in up to 50% of the overall head hair even before noticing
                                any balding. While women mostly realise hair thinning when their ponytail looks less
                                full, for men it becomes hard to remain camouflaged as their hair is short in length.
                                Hence an actual bald patch could be the first sign that men notice.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker image={Images.categories.patternBaldness} to={buyNowUrl}
                                          buttonText="BUY_NOW"/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <Stats to={buyNowUrl} data={statsData} quizButtonText={'BUY_NOW'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <iframe className="youtubeIframe"
                                        src="https://www.youtube.com/embed/FHmwU3astNI?loop=1&playlist=FHmwU3astNI&rel=0"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen frameBorder="0"/>
                            </div>
                            <div className="col-12 text-center pb-5">
                                <Button to={buyNowUrl} style={{ width: 200 }}><LText k="BUY_NOW"/></Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList3}
                        </div>
                    </div>
                </div>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <iframe className="youtubeIframe"
                                        src="https://www.youtube.com/embed/GCYVeKB5uzQ?loop=1&playlist=GCYVeKB5uzQ&rel=0"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen frameBorder="0"/>
                            </div>
                            <div className="col-12 text-center pb-5">
                                <Button to={buyNowUrl} style={{ width: 200 }}><LText k="BUY_NOW"/></Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList4}
                        </div>
                    </div>
                </div>

                <WhyMisters to={buyNowUrl} buttonText={'BUY_NOW'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList5}
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

export default Hairfall
