import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { Images } from '../../../constants'

const SummaryDoshaCard = function ({ title, text }) {
  return (
        <div className="summary-dosha-card-container">
            <div className="summary-dosha-card-top-image">
                <img src={Images.report.doshaSummaryCardImage} alt=""/>
            </div>
            <h4 className="my-4 font-weight-light text-center">
                Dosha summary
            </h4>
            <div className="summary-dosha-card">
                <div dangerouslySetInnerHTML={{ __html: text }}/>
            </div>
        </div>
  )
}

SummaryDoshaCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
}

SummaryDoshaCard.defaultProps = {
  title: '',
  text: ''
}

export default withRouter(SummaryDoshaCard)
