import api from '../api'

export const FETCH_ADDRESS = 'FETCH_ADDRESS'
export const FETCH_ADDRESS_RESULT = 'FETCH_ADDRESS_RESULT'
export const FETCH_ADDRESS_ERROR = 'FETCH_ADDRESS_ERROR'
export const FETCH_ADDRESS_RETRY = 'FETCH_ADDRESS_RETRY'

export const CREATE_ADDRESS = 'CREATE_ADDRESS'
export const CREATE_ADDRESS_RESULT = 'CREATE_ADDRESS_RESULT'
export const CREATE_ADDRESS_ERROR = 'CREATE_ADDRESS_ERROR'
export const CREATE_ADDRESS_RETRY = 'CREATE_ADDRESS_RETRY'

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const UPDATE_ADDRESS_RESULT = 'UPDATE_ADDRESS_RESULT'
export const UPDATE_ADDRESS_ERROR = 'UPDATE_ADDRESS_ERROR'
export const UPDATE_ADDRESS_RETRY = 'UPDATE_ADDRESS_RETRY'

export const DELETE_ADDRESS = 'DELETE_ADDRESS'
export const DELETE_ADDRESS_RESULT = 'DELETE_ADDRESS_RESULT'
export const DELETE_ADDRESS_ERROR = 'DELETE_ADDRESS_ERROR'
export const DELETE_ADDRESS_RETRY = 'DELETE_ADDRESS_RETRY'

export const fetchAddress = () => async dispatch => {
  try {
    dispatch({ type: FETCH_ADDRESS })
    const res = await api.ecommerce.address.list()
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_ADDRESS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_ADDRESS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_ADDRESS_RETRY, message: e.message })
  }
}

export const createAddress = (data) => async dispatch => {
  try {
    dispatch({ type: CREATE_ADDRESS })
    const res = await api.ecommerce.address.create(data)
    const result = await res.json()
    if (res.status === 201) {
      dispatch({ type: CREATE_ADDRESS_RESULT, data: result })
      await dispatch(fetchAddress())
    } else {
      dispatch({ type: CREATE_ADDRESS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: CREATE_ADDRESS_RETRY, message: e.message })
  }
}

export const updateAddress = (data) => async dispatch => {
  try {
    dispatch({ type: UPDATE_ADDRESS })
    const res = await api.ecommerce.address.update(data)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: UPDATE_ADDRESS_RESULT, data: result })
      await dispatch(fetchAddress())
    } else {
      dispatch({ type: UPDATE_ADDRESS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: UPDATE_ADDRESS_RETRY, message: e.message })
  }
}

export const deleteAddress = (data) => async dispatch => {
  try {
    dispatch({ type: DELETE_ADDRESS })
    const res = await api.ecommerce.address.remove(data)

    if (res.status === 204) {
      dispatch({ type: DELETE_ADDRESS_RESULT })
      await dispatch(fetchAddress())
    } else {
      dispatch({ type: DELETE_ADDRESS_ERROR })
    }
  } catch (e) {
    dispatch({ type: DELETE_ADDRESS_RETRY, message: e.message })
  }
}
