import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2, accordionList3 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText/LText'

class BoldDetail extends Component {
  componentDidMount () {

  }

  render () {
    const { match } = this.props
    const boldUrl = `${match.params.language}/therapy/ayurbold`

    return (
            <Page meta={{
              title: 'All you need to know about living the Bold life | Misters',
              path: `${match.params.language}/ingredient/bold-with-ashwagandha-gokshura-benefits`,
              description: 'Bold is a Misters proprietary blend capsule of 100% natural herbal ingredients containing 18 Ayurvedic herbs consisting of Gokshura, Shilajit, Ashwagandha and more that have a positive influence on male function for a more satisfying love life proving helpful for conditions like Erectile Dysfunction (ED). Frequently asked questions (FAQ’s) regarding this capsule such as its effects, how it functions, it’s constituents and why you should choose it are answered below.'
            }}>
                <div className="ingredient-header-img"
                     style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>All you need to know about living the Bold life</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <p>
                                Bold is a Misters proprietary blend capsule of 100% natural herbal ingredients
                                containing 18 Ayurvedic herbs consisting of Gokshura, Shilajit, Ashwagandha and more
                                that have a positive influence on male function for a more satisfying love life proving
                                helpful for conditions like Erectile Dysfunction (ED). Frequently asked questions
                                (FAQ’s) regarding this capsule such as its effects, how it functions, it’s constituents
                                and why you should choose it are answered below.
                            </p>
                            <br/>
                            <h2>What is Erectile Dysfunction / ED?</h2>
                            <p>
                                Due to reduced blood supply to the penis, vasoconstriction or narrowing of blood vessels
                                occurs, further causing decreased blood supply which then leads to erectile dysfunction
                                (ED). It refers to an inability to obtain and maintain penile erection.
                            </p>
                            <br/>
                            <h2>What are some common causes of ED?</h2>
                            <p>
                                It is usually the result of androgen deficiency in aging men, atherosclerosis, diabetes
                                mellitus, spinal cord injury, high level of cholesterol, hypertension, prostate surgery,
                                prostate and heart disease, penis anatomical deformity, social and psychological
                                conditions like unhappy marital relationships, depression and stress.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker isBackgroundWhite image={Images.bold.physiology_erection}/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>Why choose Bold for Erectile Dysfunction?</h2>
                            <p>
                                The use of allopathic drugs have various side-effects that limit their usage; hence,
                                Misters considers it more prudent to turn towards alternative options utilising
                                traditional systems of medicine that include medicinal plants with fewer or no recorded
                                side-effects, employing herbal medications to manage sexual dysfunctions. Bold consists
                                of ingredients which work synergistically, enhancing and elevating male sexual potency.
                            </p>
                            <br/>
                            <h2>What are Bold’s primary ingredients and their mechanisms?</h2>
                            <p>
                                The formulation of Bold is a proprietary blend of 18 herbal ingredients that include
                                Ashwagandha, Shilajit, Gokshura, Shatavari, Mastaki, Kesar, Jaiphal, Vidarikand,
                                Akalkara, Tejpatra and more… Learn about them below:
                            </p>
                            <div className="text-center">
                                <Button style={{ minWidth: '200px' }} to={boldUrl}><LText k={'BUY_NOW'}/></Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker image={Images.bold.bold1} to={boldUrl} buttonText={'BUY_NOW'}/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>

                <WhyMisters to={boldUrl} buttonText={'BUY_NOW'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList3}
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

export default BoldDetail
