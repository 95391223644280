// External Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import AliceCarousel from 'react-alice-carousel'
// My Imports
import AuthenticatedContainer from '../../components/containers/AuthenticatedContainer'
import { getBotFormList } from '../../actions/bot/bot'
import Image from '../../components/core/Image'
import { NavLink } from 'react-router-dom'

const AllQuiz = ({ match, history, bot, getBotFormList }) => {
  const [quizCarouselItems, setQuizCarouselItems] = useState([])
  const [quizCarouselIndex, setQuizCarouselIndex] = useState(0)
  const language = match.params.language

  const handleOpenBotForm = (botform) => {
    history.push(`${language}/quiz/custom/?bot_form_id=${botform.bot_form_id}&field_id=${botform.bot_field_id}`)
  }

  const getQuizCarouselItems = () => {
    return (bot.list || []).map(botform => (
        <div key={botform.id} className="px-3">
          <div className="quiz-card">
            {
              <div className="quiz-card-image" onClick={handleOpenBotForm}>
                <Image src={botform.image} alt={botform.name}/>
              </div>
            }
            <div className="quiz-card-content">
              <div className="quiz-card-head" onClick={handleOpenBotForm}>
                <h4 className="quiz-card-title">{botform.meta_title}</h4>
                <p className="quiz-card-text pb-3">
                  {botform.meta_description}
                </p>
              </div>
            </div>
            <div className="d-flex">
              <NavLink className="btn-m quiz-card-button quiz-card-detail-button"
                       to={`${language}/quiz/custom/?bot_form_id=${botform.bot_form_id}&field_id=${botform.bot_field_id}`}>
                Take Quiz
              </NavLink>
            </div>
          </div>
        </div>
    ))
  }

  useEffect(() => {
    getBotFormList()
  }, [])

  useEffect(() => {
    setQuizCarouselItems(getQuizCarouselItems())
  }, [bot])

  const onProductSlideChanged = (e) => {
    setQuizCarouselIndex(e.item)
  }

  if (!quizCarouselItems.length) return null

  return (
    <div className="home-page-section2">
      <div className="container">
        <div className="row py-5">
          <div className="col-12">
            <h2 className="home-page-subtitle text-center cursor-pointer"
                onClick={() => history.push(`${match.params.language}/solutions/`)}>Self Assessments</h2>
          </div>
          <div className="col-12">
            <AuthenticatedContainer>
              <AliceCarousel mouseTrackingEnabled touchTrackingEnabled
                             autoPlayInterval={6000}
                             responsive={{ 0: { items: 1 }, 768: { items: 2 }, 992: { items: 3 } }}
                             autoPlay={true}
                             items={quizCarouselItems}
                             startIndex={quizCarouselIndex}
                             stopAutoPlayOnHover={true}
                             buttonsDisabled={true}
                             dotsDisabled={false}
                             onSlideChanged={onProductSlideChanged}
              />
            </AuthenticatedContainer>
          </div>
        </div>
      </div>
    </div>
  )
}

AllQuiz.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  bot: PropTypes.object,
  getBotFormList: PropTypes.func
}

const mapStateToProps = state => ({
  bot: state.bot.bot
})

const matchDispatchToProps = dispatch => bindActionCreators({
  getBotFormList
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(AllQuiz))
