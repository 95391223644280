import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class FormInput extends PureComponent {
    static propTypes = {
      error: PropTypes.any,
      containerClassName: PropTypes.string,
      as: PropTypes.oneOf(['textarea', 'input'])
    };

    static defaultProps = {
      as: 'input'
    };

    state = {
      active: false
    };

    render () {
      const { placeholder, containerClassName, error, as, ...props } = this.props
      const extraClassNames = (this.state.active || this.props.value.toString().length) ? 'active' : ''

      if (as === 'input') {
        return (
                <Fragment>
                    <div className={`form-input ${containerClassName} ${extraClassNames} ${error ? 'error' : ''}`}
                         onFocus={() => this.setState({ active: true })}
                         onBlur={() => this.setState({ active: false })}>
                        <label htmlFor={placeholder}>{placeholder}</label>
                        <input id={placeholder} {...props}/>
                    </div>
                    {
                        error &&
                        error.map(
                          (msg, index) => <div key={index} className='form-input-error-message'>{msg}</div>
                        )
                    }
                </Fragment>
        )
      }
      return (
            <Fragment>
                <div className={`form-input ${extraClassNames} ${error ? 'error' : ''}`}
                     onFocus={() => this.setState({ active: true })}
                     onBlur={() => this.setState({ active: false })}>
                    <label htmlFor={placeholder}>{placeholder}</label>
                    <textarea id={placeholder} {...props}/>
                </div>
                {
                    error &&
                    error.map(
                      (msg, index) => <div key={index} className='form-input-error-message'>{msg}</div>
                    )
                }
            </Fragment>
      )
    }
}
