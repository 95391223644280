import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Pagination from 'react-bootstrap/Pagination'

export default class PaginationComponent extends PureComponent {
    static propTypes = {
      onClick: PropTypes.func,
      page: PropTypes.number,
      perPage: PropTypes.number,
      count: PropTypes.number,
      className: PropTypes.string
    };

    static defaultProps = {
      page: 1,
      perPage: 10,
      count: 1,
      className: ''
    };

    render () {
      const { page, perPage, count, onClick, className } = this.props
      const totalPage = Math.ceil(count / perPage)
      let startIndex = Math.max(page - 5, 1)
      const endIndex = Math.min(startIndex + 10, totalPage)
      startIndex = Math.min(Math.max(endIndex - 10, 1), startIndex)

      const pageArray = []
      for (let i = startIndex; i <= endIndex; i++) {
        pageArray.push(i)
      }

      return (
            <Pagination className={className}>
                <Pagination.Item className={page === 1 ? 'disabled' : ''}
                                 onClick={() => onClick(1)}>First</Pagination.Item>
                {
                    pageArray.map(
                      pageNumber => (
                            <Pagination.Item key={pageNumber} active={page === pageNumber}
                                             onClick={() => onClick(pageNumber)}>
                                {pageNumber}
                            </Pagination.Item>
                      )
                    )
                }
                <Pagination.Item className={page >= totalPage ? 'disabled' : ''}
                                 onClick={() => onClick(totalPage)}>Last</Pagination.Item>
            </Pagination>
      )
    }
}
