import { Images } from '../../../constants'
import ImageService from '../../../services/ImageService'

export const data = {
  ayurbold: {
    amazonRating: 4.7,
    filpkartRating: 4.6,
    featured_image: Images.therapy.boldBanner,
    featured_video_url: 'https://www.youtube.com/embed/EFhcJMm1iTU',
    short_description: 'Misters.in Bold for Men is a natural ayurvedic supplement that supports healthy testosterone levels, improves penile tissue strength and alleviates stress. Made from a proprietary blend of 100% natural herbal ingredients containing 18 Ayurvedic herbs, such as Gokshura, Shilajit and Ashwagandha. The formulation has a positive influence on male function for a more satisfying and fulfilling love life.',
    short_description_hi: 'बोल्ड फॉर मेन एक प्राकृतिक आयुर्वेदिक सप्लीमेंट है, जो टेस्टोस्टेरॉन लेवल बढ़ाता है, नसों को मजबूत बनाता है और तनाव कम करता है। ये 100% प्राकृतिक जड़ी-बूटियों के मिश्रण से बना है।',
    short_description_te: 'Misters.in వారి బోల్డ్ ఫర్ మెన్ ఒక ప్రకృతి సహజమైన ఆయుర్వేద సప్లిమెంట్. ఇది ఆరోగ్యకరమైన టెస్టోస్టిరాన్ హార్మోన్ స్థాయిని ప్రోత్సహిస్తుంది,  పురుషాంగం యొక్క కణజాల పొరలని మెరుగుపరుస్తుంది మరియు మానసిక ఒత్తిడి తగ్గేలా చేస్తుంది. 100 శాతం సహజ మూలికలతో తయారైన ఈ మిశ్రమంలో Gokshura, Shilajit మరియు Ashwagandha వంటి 18 ఆయుర్వేద మూలికలు ఉంటాయి. ఈ మిశ్రమం పురుషుల లైంగిక ప్రదర్శనపై సానుకూల ప్రభావాన్ని చూపిస్తూ, మరింత సంతృప్తికరమైన జీవితాన్ని అందిస్తుంది.',
    short_description_kn: ' Misters.in ಪುರುಷರಿಗಾಗಿ ಬೋಲ್ಡ್ ಒಂದು ನೈಸರ್ಗಿಕ ಆಯುರ್ವೇದೀಯ ಪೂರಕವಾಗಿದ್ದು, ಇದು ಆರೋಗ್ಯಕರ ಟೆಸ್ಟೋಸ್ಟೆರೋನ್ ಮಟ್ಟಗಳನ್ನು ಬೆಂಬಲಿಸುತ್ತದೆ, ಶಿಶ್ನದ ಅಂಗಾಂಶವನ್ನು ಬೆಂಬಲಿಸುತ್ತದೆ ಮತ್ತು ಒತ್ತಡವನ್ನು ಕಡಿಮೆ ಮಾಡುತ್ತದೆ. Gokshura,shilajit ಮತ್ತು ashwagandha ರೀತಿಯಂತಹ 18 ಆಯುರ್ವೇದೀಯ ಗಿಡಮೂಲಿಕೆಗಳನ್ನು ಒಳಗೊಂಡಿರುವ 100% ನೈಸರ್ಗಿಕ ಹರ್ಬಲ್ ಉತ್ಪನ್ನಗಳ ಸ್ವಾಮ್ಯದ ಮಿಶ್ರಣದಿಂದ ಸಿದ್ಧಪಡಿಸಲಾಗಿದೆ. ಈ ಸೂತ್ರೀಕರಣವು ತೃಪ್ತಿಕರ ಮತ್ತು ಸಂತುಷ್ಟಕರ ಲೈಂಗಿಕ ಜೀವನಕ್ಕಾಗಿ ಪುರುಷರ ಕಾರ್ಯಕ್ಷಮತೆಯ ಮೇಲೆ ಧನಾತ್ಮಕ ಪ್ರಭಾವವನ್ನು ಬೀರಿದೆ. ',

    guideline1: 'Ayurvedic science works with a holistic understanding of the body and the mind.',
    guideline1_hi: 'आयुर्वेद में शरीर और मन दोनों को समग्रता से समझकर ही बीमारी या समस्या का इलाज खोजा जाता है।',
    guideline1_kn: 'ಆಯುರ್ವೇದೀಯ ವಿಜ್ಞಾನವು ದೇಹ ಮತ್ತು ಮನಸ್ಸಿನ ಸಮಗ್ರ ಅರ್ಥವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ. ',
    guideline1_te: 'ఆయుర్వేద విజ్ఞానం శరీరం మరియు మనస్సు యొక్క పూర్తి అవగాహనతో పనిచేస్తుంది.',

    guideline2: 'It works by prevention and treatment of the root cause, rather than responding to symptoms of the issue.',
    guideline2_hi: 'आयुर्वेद सिर्फ लक्षणों पर नहीं, बल्कि रोग को जड़ से समाप्त करता है।',
    guideline2_kn: 'ಇದು ಸಮಸ್ಯೆಯ ಲಕ್ಷಣಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುವ ಬದಲಾಗಿ ಮೂಲ ಕಾರಣವನ್ನು ತಡೆಗಟ್ಟುವ ಮೂಲಕ ಮತ್ತು ಗುಣಪಡಿಸುವ ಮೂಲಕ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ.',
    guideline2_te: 'ఇది సమస్య యొక్క లక్షణాలు తగ్గించటానికి స్పందించటం కన్నా, సమస్య యొక్క మూల కారణానికి వెళ్ళి అక్కడ నివారణకి, చికిత్సకి ప్రయత్నిస్తుంది.',

    guideline3: 'We recommend 45-60 days of continued ayurvedic treatment combined with healthy eating and physical exercise for best results.',
    guideline3_hi: 'हमारा सुझाव है की 45 से 60 दिन तक लगातार इस आयुर्वेदिक सप्लीमेंट्स का प्रयोग करें साथ ही अच्छे खानपान और थोड़ी शारीरिक एक्सरसाइज पर ध्यान दें। समस्या को जड़ से मिटाने के लिए आगे भी सेवन करते रहें।',
    guideline3_kn: 'ಆರೋಗ್ಯಕರ ಆಹಾರ ಸೇವನೆ ಮತ್ತು ದೈಹಿಕ ವ್ಯಾಯಾಮಗಳೊಂದಿಗೆ 45-60 ದಿನಗಳ ನಿರಂತರ ಆಯುರ್ವೇದೀಯ ಚಿಕಿತ್ಸೆಯು ನಿಮಗೆ ಉತ್ತಮ ಫಲಿತಾಂಶಗಳನ್ನು ನೀಡಬಲ್ಲದು',
    guideline3_te: 'ఉత్తమ ఫలితాల కోసం ఆరోగ్యకరమైన ఆహారం మరియు శారీరక వ్యాయామంతో కలిపి 45-60 రోజుల నిరంతర ఆయుర్వేద చికిత్సను మేము సిఫార్సు చేస్తున్నాము.',

    guideline4: '1 pack of Bold has 30 capsules. The pack of 2 will help you seamlessly ease into the natural ayurvedic way of life and onward to the best of health.',
    guideline4_hi: 'बोल्ड के एक पैक में 30 कैप्सूल्स हैं। कम से कम 2 पैक इस्तेमाल करने पर आपको इस आयुर्वेदिक प्रोडक्ट का लाभ मिलेगा और सेहत पर इसका असर नजर आने लगेगा।',
    months: [
      {
        time: '1 month',
        time_hi: 'पहले महीने में',
        description: 'You’ll experience better blood flow in muscles that help in lasting erections.',
        description_hi: 'आपके शरीर में रक्त का प्रवाह बढ़ेगा, जिससे आपका लिंग ज्यादा समय तक उत्तेजित यानी खड़ा रहेगा।',
        icon: Images.icons.iconPipe
      },
      {
        time: '2 months',
        time_hi: 'दूसरे महीने में',
        description: 'Will make your plasma hormone levels optimum, giving you strength, stamina and endurance.',
        description_hi: 'आपका प्लाज्मा हार्मोन लेवल सुधरेगा, जिससे आपके शरीर में स्टैमिना, ताकत और देर तक टिकने की क्षमता बढ़ेगी।',
        icon: Images.icons.iconArm
      },
      {
        time: '3 months',
        time_hi: 'तीसरे महीने में',
        description: 'After 3-4 months of daily use, you’ll find yourself psychologically healthy with increased strength and stamina that results in better erections and satisfying performance.',
        description_hi: '3-4 महीने लगातार इस्तेमाल के बाद आप पाएंगे कि आप मनोवैज्ञानिक रूप से ज्यादा स्वस्थ, जोशीला और शक्तिशाली महसूस कर रहे हैं, जिससे आपका लिंग देर तक उत्तेजित रहेगा और आपकी सेक्स परफॉर्मेंस में सुधार आएगा।',
        icon: Images.icons.iconHeart
      }
    ],
    keyClaims: [
      'Gokshura improves the production of Nitric oxide (NO) along with improving testosterone levels. NO is important in the erection process.',
      'Stacked with shilajit, an abundant source of fulvic acid. It improves stress and stamina.',
      'Along with Gokshura and Shilajit, BOLD features 16 more ayurvedic herbs that have been scientifically tested to sustain better erections and improve sexual wellness.'
    ],
    faqs: [
      {
        title: 'I don\'t have erectile dysfunction issues, can I still consume Misters.in Bold for Men?',
        description: 'Yes! Although Misters.in Bold for Men is designed to help manage erectile dysfunction, it is a holistic product that promotes a satisfying love life.'
      },
      {
        title: 'Is Misters.in Bold for Men safe to consume?',
        description: 'Absolutely! Misters.in Bold for Men is made with the highest quality ingredients at a GMP certified facility.'
      },
      {
        title: 'Are there any side effects associated with Misters.in Bold for Men?',
        description: 'None, there are no known side effects with Misters.in Bold for Men.'
      },
      {
        title: 'Can Misters.in Bold for Men be used with other products?',
        description: 'Yes! Misters.in Bold for Men works well with Misters.in Josh for Men that is designed to increase libido.'
      }
    ],
    how_to_use: {
      stepOne: {
        image: Images.therapy.tablet,
        text: 'Take 1 capsule',
        text_hi: 'एक कैप्सूल',
        text_kn: 'ಒಂದು ಕ್ಯಾಪ್ಸುಲ್ ತೆಗೆದುಕೊಳ್ಳಿ'
      },
      stepTwo: {
        image: Images.therapy.night,
        text: 'every night',
        text_hi: 'हर रात',
        text_kn: 'ಪ್ರತಿ ರಾತ್ರಿ'
      },
      stepThree: {
        image: Images.therapy.dinner,
        text: 'after dinner',
        text_hi: 'खाने के बाद',
        text_kn: 'ಊಟದ ನಂತರ'
      }
    },
    ingredients: {
      image: Images.ingredient.boldIngredients,
      data: [
        {
          image: Images.ingredient.shilajit,
          description: 'Shilajit restores the energy of the body. Fulvic acid, one of its major ingredients, is responsible for boosting power, energy and stamina.',
          description_hi: 'शिलाजीत शरीर में एनर्जी लाता है। इसमें मौजूद फुल्विक एसिड ऊर्जा, शक्ति और जोश बढ़ाता है।',
          description_te: 'Shilajit శరీరం యొక్క శక్తిని తిరిగివచ్చేలా చేస్తుంది. దాని ప్రధాన పదార్థమైన ఫుల్విక్ యాసిడ్, బలం, శక్తి, సామర్థ్యం పెరగటానికి కారణం.',
          description_kn: 'Shilajit ದೇಹದ ಶಕ್ತಿಯನ್ನು ಮರುಸ್ಥಾಪಿಸುತ್ತದೆ. ಅದರ ಪ್ರಮುಖ ಅಂಶಗಳಲ್ಲಿ ಒಂದಾಗಿರುವ ಫುಲ್ವಿಕ್ ಆಸಿಡ್ ಶಕ್ತಿ, ಚೈತನ್ಯ ಮತ್ತು ಉಲ್ಲಾಸವನ್ನು ಹೆಚ್ಚಿಸಲು ಕಾರಣವಾಗಿದೆ.',
          url: '/ingredient/shilajit'
        },
        {
          image: Images.ingredient.gokshura,
          description: 'Gokshura increases the testosterone levels, strengthens penile tissue and helps in sustaining erections. It also relieves psychological and physiological stress in men.',
          description_hi: 'गोक्षुर टेस्टोस्टेरॉन लेवल को बढ़ाता है, लिंग की नसों की ताकत बढ़ाता है, जिससे लिंग देर तक उत्तेजित रहता है। ये मनोवैज्ञानिक तनाव को कम करता है और आपको शारीरिक रूप से स्वस्थ रखता है।',
          url: '/ingredient/gokshura'
        },
        {
          image: Images.ingredient.ashwagandha,
          description: 'Ashwagandha improves tissue vitality, physical and mental endurance, neuromuscular strength and oxygen uptake for peak performance.',
          description_hi: 'अश्वगंधा टिशूज में रक्त प्रवाह बढ़ाता है, जिससे लिंग में उत्तेजना और शक्ति आती है साथ ही आपका परफॉर्मेंस बेहतर होता है।',
          description_te: 'Ashwagandha కణజాల శక్తిని మెరుగుపరుస్తుంది, శారీరక మరియు మానసిక ఓర్పుని పెంచుతుంది, నరాలు మరియు కండరాల బలాన్ని, అత్యధ్భుత లైంగిక ప్రదర్శనకి ఆక్సిజన్ ని ఎక్కువగా తీసుకోటాన్ని ప్రేరేపిస్తుంది.',
          description_kn: 'Ashwagandha ಅಂಗಾಂಶದ ಶಕ್ತಿ, ಮಾನಸಿಕ ಮತ್ತು ದೈಹಿಕ ಸ್ಥಿರತೆ, ನ್ಯೂರೋಮಸ್ಕುಲರ್ ಶಕ್ತಿ ಸುಧಾರಿಸುತ್ತದೆ ಮತ್ತು ಲೈಂಗಿಕ ಕಾರ್ಯಕ್ಷಮತೆಗಾಗಿ ಆಮ್ಲಜನಕವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ ',
          url: '/ingredient/ashwagandha'
        }
      ]
    }
  },
  ayurjosh: {
    amazonRating: 4,
    filpkartRating: 5,
    featured_image: Images.therapy.joshBanner,
    featured_video_url: 'https://www.youtube.com/embed/r6dwWH665PQ',
    short_description: 'Your drive can reduce for a number of lifestyle reasons. This proprietary blend helps you recharge  in multiple ways. According to ayurveda, ashwagandha and kesar have potent aphrodisiac qualities, while the fulvic acid in shilajit is known to support testosterone production. The blend also helps reduce stress, which we know is often the reason for a weakening of drive.',
    short_description_hi: 'सैक्स करने की चाह, जिसको लिबीडो या सैक्स ड्राइव कहते हैं, कई कारण से कम हो सकती है। इस आयुर्वेदिक औषधि से इनमें से कई कारण दूर होते हैं। आयुर्वेद के अनुसार अश्वगंधा और केसर सैक्स ड्राइव बड़ाते हैं। शिलाजित में एक तत्व (फुल्विक ऐसिड) ख़ून में टेस्टोस्टिरोन बड़ाने में मदद करता है। हाँ, एक और बात, लिबीडो या सैक्स ड्राइव घटाने में स्ट्रेस (टेन्शन) का कई बार हाथ होता है, तो यह औषधि आपका स्ट्रेस भी घटाने में सहायक है।',
    short_description_kn: 'ಹಲವಾರು ಜೀವನಶೈಲಿ ಕಾರಣಗಳಿಂದ ನಿಮ್ಮ ಕಾಮಾಸಕ್ತಿಯು ಕುಗ್ಗಬಹುದು. ಈ ಸ್ವಾಮ್ಯದ ಮಿಶ್ರಣವು ನಿಮಗೆ ಹಲವು ವಿಧಾನಗಳಲ್ಲಿ ರಿಚಾರ್ಜ್ ಆಗಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಆಯುರ್ವೇದದ ಪ್ರಕಾರ, ashwagandha ಮತ್ತು ಕೇಸರಿಯು ಸಮರ್ಥ ಕಾಮೋತ್ತೇಜಕ ಗುಣಗಳನ್ನು ಹೊಂದಿದೆ, ಅದರಲ್ಲೂ shilajit ನಲ್ಲಿರುವ ಫುಲ್ವಿಕ್ ಆಸಿಡ್ ಟೆಸ್ಟೋಸ್ಟೆರೋನ್ ಉತ್ಪಾದನೆಯನ್ನು ಬೆಂಬಲಿಸುತ್ತದೆ. ನಮಗೆ ತಿಳಿದಿರುವಂತೆ ಕಾಮಾಸಕ್ತಿಯನ್ನು ಕಡಿಮೆ ಮಾಡುವುದರಲ್ಲಿ ಕಾರಣವಾಗಿರುವ ಒತ್ತಡವನ್ನೂ ಸಹ ಕಡಿಮೆ ಮಾಡಲು ಈ ಮಿಶ್ರಣ ಸಹಾಯ ಮಾಡುತ್ತದೆ.',
    short_description_te: 'మీకు సెక్స్ పట్ల ఇష్టం మరియు లైంగిక సామర్థ్యం అనేక జీవనశైలి కారణాల వలన తగ్గవచ్చు. మా ఈ ఉత్పత్తి మిశ్రమం మీకు తిరిగి ఆ సామర్థ్యం వచ్చేలా చాలారకాలుగా పనిచేస్తుంది. ఆయుర్వేదం ప్రకారం, ashwagandha మరియు కుంకుమపువ్వులో లైంగిక ఆసక్తిని పెంచే లక్షణాలు ఉంటాయి, shilajit లోని ఫుల్విక్ ఆమ్లం టెస్టోస్టిరాన్ హార్మోన్ ఉత్పత్తిని ప్రోత్సహిస్తుంది. ఈ మిశ్రమం లైంగిక ఆసక్తి, సామర్థ్యం తగ్గటానికి తరచుగా కారణమయ్యే మానసిక ఒత్తిడిని కూడా తగ్గించటంలో సహాయపడుతుంది.',

    guideline1: 'Ayurvedic science works with a holistic understanding of the body and the mind.',
    guideline1_hi: 'आयुर्वेद में शरीर और मन दोनों को समग्रता से समझकर ही बीमारी या समस्या का इलाज खोजा जाता है।',
    guideline1_kn: 'ಆಯುರ್ವೇದೀಯ ವಿಜ್ಞಾನವು ದೇಹ ಮತ್ತು ಮನಸ್ಸಿನ ಸಮಗ್ರ ಅರ್ಥವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ. ',
    guideline1_te: 'ఆయుర్వేద విజ్ఞానం శరీరం మరియు మనస్సు యొక్క పూర్తి అవగాహనతో పనిచేస్తుంది.',

    guideline2: 'It works by prevention and treatment of the root cause, rather than responding to symptoms of the issue.',
    guideline2_hi: 'आयुर्वेद सिर्फ लक्षणों पर नहीं, बल्कि रोग को जड़ से समाप्त करता है।',
    guideline2_kn: 'ಇದು ಸಮಸ್ಯೆಯ ಲಕ್ಷಣಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುವ ಬದಲಾಗಿ ಮೂಲ ಕಾರಣವನ್ನು ತಡೆಗಟ್ಟುವ ಮೂಲಕ ಮತ್ತು ಗುಣಪಡಿಸುವ ಮೂಲಕ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ.',
    guideline2_te: 'ఇది సమస్య యొక్క లక్షణాలు తగ్గించటానికి స్పందించటం కన్నా, సమస్య యొక్క మూల కారణానికి వెళ్ళి అక్కడ నివారణకి, చికిత్సకి ప్రయత్నిస్తుంది.',

    guideline3: 'We recommend 45-60 days of continued ayurvedic treatment combined with healthy eating and physical exercise for best results.',
    guideline3_hi: 'हमारा सुझाव है की 45 से 60 दिन तक लगातार इस आयुर्वेदिक सप्लीमेंट्स का प्रयोग करें साथ ही अच्छे खानपान और थोड़ी शारीरिक एक्सरसाइज पर ध्यान दें। समस्या को जड़ से मिटाने के लिए आगे भी सेवन करते रहें।',
    guideline3_kn: 'ಆರೋಗ್ಯಕರ ಆಹಾರ ಸೇವನೆ ಮತ್ತು ದೈಹಿಕ ವ್ಯಾಯಾಮಗಳೊಂದಿಗೆ 45-60 ದಿನಗಳ ನಿರಂತರ ಆಯುರ್ವೇದೀಯ ಚಿಕಿತ್ಸೆಯು ನಿಮಗೆ ಉತ್ತಮ ಫಲಿತಾಂಶಗಳನ್ನು ನೀಡಬಲ್ಲದು',
    guideline3_te: 'ఉత్తమ ఫలితాల కోసం ఆరోగ్యకరమైన ఆహారం మరియు శారీరక వ్యాయామంతో కలిపి 45-60 రోజుల నిరంతర ఆయుర్వేద చికిత్సను మేము సిఫార్సు చేస్తున్నాము.',

    guideline4: '1 pack of Josh has 30 capsules. The pack of 2 will help you seamlessly ease into the natural ayurvedic way of life and onward to the best of health.',
    guideline4_hi: 'जोश के एक पैक में 30 कैप्सूल्स हैं। कम से कम 2 पैक इस्तेमाल करने पर आपको इस आयुर्वेदिक प्रोडक्ट का लाभ मिलेगा और सेहत पर इसका असर नजर आने लगेगा।',
    guideline4_kn: 'ಒಂದು ಪ್ಯಾಕ್ ಜೋಶ್ ನಲ್ಲಿ 30 ಕ್ಯಾಪ್ಸುಲುಗಳು ಇರುತ್ತವೆ. ಎರಡರ ಪ್ಯಾಕ್ ನಿಮಗೆ ನೈಸರ್ಗಿಕ ಆಯುರ್ವೇದೀಯ ಜೀವನವನ್ನು ನಡೆಸಲು ಮತ್ತು ಅತ್ಯುತ್ತಮ ಆರೋಗ್ಯವನ್ನು ಕಾಪಾಡಲು ಸಹಾಯಮಾಡುತ್ತದೆ.',
    guideline4_te: '1 జోష్ ప్యాక్ లో 30 క్యాప్స్యూల్స్ ఉంటాయి. 2 ప్యాకెట్లు సహజ ఆయుర్వేద జీవనశైలిలోకి మిమ్మల్ని సులభంగా మారేలా చేస్తాయి మరియు ఇక మెరుగైన ఆరోగ్యం వచ్చే దారిపై పట్టు సాధిస్తారు.',

    months: [
      {
        time: '1 month',
        time_hi: 'पहले महीने में',
        description: 'Significant improvement in your stress and anxiety levels.',
        description_hi: 'आपके तनाव और चिंता की समस्या कम होने लगेगी।',
        description_kn: 'ಒತ್ತಡ ಮತ್ತು ಉದ್ವೇಗ ಮಟ್ಟಗಳಲ್ಲಿ ಗಮನಾರ್ಹ ಸುಧಾರಣೆ ',
        description_te: 'మీ మానసిక ఒత్తిడి మరియు ఆందోళన స్థాయిలని తగ్గించటంలో గణనీయమైన మెరుగుదలని చూపిస్తుంది.',
        icon: Images.icons.iconBrain
      },
      {
        time: '2 months',
        time_hi: 'दूसरे महीने में',
        description: 'Improvement in your overall testosterone levels and feel more energetic.',
        description_hi: 'आपके शरीर में टेस्टोस्टेरॉन लेवल बढ़ेगा और आप ऊर्जा से भरपूर महसूस करेंगे',
        description_kn: 'ನಿಮ್ಮ ಒಟ್ಟಾರೆ ಟೆಸ್ಟೋಸ್ಟೆರೋನ್ ಮಟ್ಟಗಳನ್ನು ಸುಧಾರಿಸಿ ನಿಮಗೆ ಹೆಚ್ಚು ಶಕ್ತಿಯುತವನ್ನಾಗಿಸುತ್ತದೆ.',
        description_te: 'మీ మొత్తం టెస్టోస్టిరాన్ స్థాయిలని మెరుగుపర్చి, మీరు మరింత  శక్తివంతంగా ఫీలయ్యేలా చేస్తుంది.',
        icon: Images.icons.iconArm
      },
      {
        time: '3 months',
        time_hi: 'तीसरे महीने में',
        description: 'Unlock the maximum benefits. Your overall stamina and strength will increase, boosting your appetite for intimacy.',
        description_hi: 'आपको सभी फायदे मिलने लगेंगे। स्टैमिना और शक्ति बढ़ेगी साथ ही कामुकता और जोश भी बढ़ेगा।',
        description_kn: 'ಗರಿಷ್ಟ ಲಾಭಗಳನ್ನು ಪಡೆದುಕೊಳ್ಳಿ. ಸರಸಕ್ಕಾಗಿ ನಿಮ್ಮ ಆಸಕ್ತಿಯನ್ನು ಹೆಚ್ಚಿಸುವ ಮೂಲಕ ನಿಮ್ಮ ಒಟ್ಟಾರೆ ಶಕ್ತಿ ಮತ್ತು ಚೈತನ್ಯವನ್ನು ವೃದ್ಧಿಸುತ್ತದೆ.',
        description_te: 'గరిష్ట లాభాలన్నిటినీ పొందుతారు. మీ పూర్తి బలం మరియు సామర్థ్యం మెరుగుపడతాయి, లైంగిక సాన్నిహిత్యం కోసం మీ మనస్సు ఎక్కువగా కోరుకుంటుంది.',
        icon: Images.icons.iconHeart
      }
    ],
    how_to_use: {
      stepOne: {
        image: Images.therapy.tablet,
        text: 'Take 1 capsule',
        text_hi: 'एक कैप्सूल',
        text_kn: 'ಒಂದು ಕ್ಯಾಪ್ಸುಲ್ ತೆಗೆದುಕೊಳ್ಳಿ'
      },
      stepTwo: {
        image: Images.therapy.night,
        text: 'every night',
        text_hi: 'हर रात',
        text_kn: 'ಪ್ರತಿ ರಾತ್ರಿ'
      },
      stepThree: {
        image: Images.therapy.dinner,
        text: 'after dinner',
        text_hi: 'खाने के बाद',
        text_kn: 'ಊಟದ ನಂತರ'
      }
    },
    ingredients: {
      image: Images.ingredient.joshIngredients,
      data: [
        {
          image: Images.ingredient.shilajit,
          description: 'Shilajit restores the energy of the body. Fulvic acid, one of its major ingredients, is responsible for boosting power, energy and stamina.',
          description_hi: 'शिलाजीत शरीर में एनर्जी लाता है। इसमें मौजूद फुल्विक एसिड ऊर्जा, शक्ति और जोश बढ़ाता है।',
          description_te: 'Shilajit శరీరం యొక్క శక్తిని తిరిగివచ్చేలా చేస్తుంది. దాని ప్రధాన పదార్థమైన ఫుల్విక్ యాసిడ్, బలం, శక్తి, సామర్థ్యం పెరగటానికి కారణం.',
          description_kn: 'Shilajit ದೇಹದ ಶಕ್ತಿಯನ್ನು ಮರುಸ್ಥಾಪಿಸುತ್ತದೆ. ಅದರ ಪ್ರಮುಖ ಅಂಶಗಳಲ್ಲಿ ಒಂದಾಗಿರುವ ಫುಲ್ವಿಕ್ ಆಸಿಡ್ ಶಕ್ತಿ, ಚೈತನ್ಯ ಮತ್ತು ಉಲ್ಲಾಸವನ್ನು ಹೆಚ್ಚಿಸಲು ಕಾರಣವಾಗಿದೆ.',
          url: '/ingredient/shilajit'
        },
        {
          image: Images.ingredient.ashwagandha,
          description: 'Ashwagandha improves tissue vitality, physical and mental endurance, neuromuscular strength and oxygen uptake for peak performance.',
          description_hi: 'अश्वगंधा टिशूज में रक्त प्रवाह बढ़ाता है, जिससे लिंग में उत्तेजना और शक्ति आती है साथ ही आपका परफॉर्मेंस बेहतर होता है।',
          description_te: 'Ashwagandha కణజాల శక్తిని మెరుగుపరుస్తుంది, శారీరక మరియు మానసిక ఓర్పుని పెంచుతుంది, నరాలు మరియు కండరాల బలాన్ని, అత్యధ్భుత లైంగిక ప్రదర్శనకి ఆక్సిజన్ ని ఎక్కువగా తీసుకోటాన్ని ప్రేరేపిస్తుంది.',
          description_kn: 'Ashwagandha ಅಂಗಾಂಶದ ಶಕ್ತಿ, ಮಾನಸಿಕ ಮತ್ತು ದೈಹಿಕ ಸ್ಥಿರತೆ, ನ್ಯೂರೋಮಸ್ಕುಲರ್ ಶಕ್ತಿ ಸುಧಾರಿಸುತ್ತದೆ ಮತ್ತು ಲೈಂಗಿಕ ಕಾರ್ಯಕ್ಷಮತೆಗಾಗಿ ಆಮ್ಲಜನಕವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ ',
          url: '/ingredient/ashwagandha'
        },
        {
          image: Images.ingredient.kesar,
          description: 'Kesar stimulates blood flow to reproductive organs and builds stamina. It also improves mood and reduces anxiety which is essential for pleasure.',
          description_hi: 'केसर प्रजनन अंग में रक्त प्रवाह बढ़ाता है, जिससे स्टैमिना बढ़ता है। ये आपके मूड को भी सही करता है और तनाव कम करता है, जिससे आप आनंद को मन से महसूस कर पाते हैं।',
          description_te: 'కుంకుమపువ్వు అవయవాలకి రక్తప్రసరణ పెంచి, సామర్థ్యాన్ని పెంచుతుంది. ఇది మూడ్ కూడా బాగుచేసి, లైంగిక ఆనందం కోసం ఆందోళనని తగ్గిస్తుంది.',
          description_kn: 'ಕೇಸರಿ ಸಂತಾನೋತ್ಪತ್ತಿ ಅಂಗಗಳಿಗೆ ರಕ್ತದ ಹರಿವನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ ಮತ್ತು ರೋಗನಿರೋಧಕ ಶಕ್ತಿಯನ್ನು ನಿರ್ಮಿಸುತ್ತದೆ. ಜೊತೆಗೆ ಇದು ಸುಖಕ್ಕಾಗಿ ಅಗತ್ಯವಿರುವ ಉದ್ವೇಗವನ್ನು ಕಡಿಮೆ ಮಾಡುತ್ತದೆ ಮತ್ತು ಮನಸ್ಸನ್ನು ಸುಧಾರಿಸುತ್ತದೆ.',
          url: '/ingredient/kesar'
        }
      ]
    },
    keyClaims: [
      'Shilajit boosts testosterone levels, improving strength and stamina. ',
      'Stacked with Ashwagandha, a strong anxiolytic, alleviates stress and anxiety to improve desire.',
      'Besides Shilajit and Ashwagandha, JOSH has 14 other ayurvedic herbs that have been scientifically studied for improving sexual desire and wellness.'
    ],
    faqs: [
      {
        title: 'I don\'t have libido issues, can I still consume Misters.in Josh for Men?',
        description: 'Yes! Although Misters.in Josh for Men is designed to help increase libido, it is a holistic product that promotes a satisfying love life.'
      },
      {
        title: 'Is Misters.in Josh for Men safe to consume?',
        description: 'Absolutely! Misters.in Josh for Men is made with the highest quality ingredients at a GMP certified facility.'
      },
      {
        title: 'Are there any side effects associated with Misters.in Josh for Men?',
        description: 'None, there are no known side effects with Misters.in Josh for Men.'
      },
      {
        title: 'Can Misters.in Josh for Men be used with other products?',
        description: 'Yes! Misters.in Josh for Men works well with Misters.in Bold for Men and Misters.in Perform that is designed to increase erections and support premature ejaculation respectively.'
      }
    ]
  },
  ayurperform: {
    amazonRating: 5,
    filpkartRating: 4.1,
    featured_image: Images.therapy.performBanner,
    featured_video_url: 'https://www.youtube.com/embed/xwKTRMjigdk',
    short_description: 'Misters.in Perform is a natural ayurvedic supplement that strengthens penile tissue, supports healthy testosterone levels and has a vasodilation effect that improves blood flow, promotes vigour, vitality and endurance. Made from a proprietary blend of 100% natural herbal ingredients containing 15 Ayurvedic herbs, such as Kesar, Kaunch Beej and Ashwagandha. The formulation has a positive influence on male function for a more satisfying and fulfilling life. ',
    short_description_hi: 'Misters.in परफ़ॉर्म एक अनोखी आयुर्वेदिक औषधि है जो आपके क्लाइमैक्स की अवधि को बढ़ाने में मदद करती है। इसमें 15 आयुर्वेदिक जड़ी बूटियाँ हैं—जैसे कि केसर, कौंच बीज और अश्वगंधा—जो आपके खून के प्रवाह को बेहतर करती हैं और उन मांसपेशियों को शक्तिशाली बनाती हैं जिनसे आप जल्दी क्लाइमैक्स होने को रोक सकें। इसके नियमित उपयोग से आपकी सैक्स लाइफ़ ज़्यादा आनन्दमय बनती है।',
    short_description_te: 'Misters.in పెర్ఫామ్ ఒక ప్రకృతి సహజమైన ఆయుర్వేద సప్లిమెంట్. ఇది పురుషాంగం యొక్క కణజాల పొరలని బలోపేతం చేస్తుంది, ఆరోగ్యకరమైన టెస్టోస్టిరాన్ హార్మోన్ స్థాయిని మరియు రక్తప్రసరణని మెరుగుపరుస్తుంది. అలాగే శక్తిని, ఉత్తేజాన్ని మరియు ఎక్కువ సమయం నిలిచి వుండేలా ప్రోత్సహిస్తుంది. 100 శాతం సహజ మూలికా పదార్థాలతో ఈ మిశ్రమం తయారుకాబడింది. ఇందులో కుంకుమ పువ్వు, కౌంచ్ బీజ్ మరియు Ashwagandha వంటి 15 ఆయుర్వేద మూలికలు ఉన్నాయి. ఈ మిశ్రమం పురుషుల లైంగిక ప్రదర్శనపై సానుకూల ప్రభావాన్ని చూపిస్తూ, మరింత సంతృప్తికరమైన జీవితాన్ని అందిస్తుంది.',
    short_description_kn: 'Misters.in ಪರ್ಫಾರ್ಮ್ ಒಂದು ನೈಸರ್ಗಿಕ ಆಯುರ್ವೇದೀಯ ಪೂರಕವಾಗಿದ್ದು ಇದು ಶಿಶ್ನದ ಅಂಗಾಂಶವನ್ನು ಬಲಗೊಳಿಸುತ್ತದೆ, ಆರೋಗ್ಯಕರ ಟೆಸ್ಟೋಸ್ಟೆರೋನ್ ಮಟ್ಟವನ್ನು ಬೆಂಬಲಿಸುತ್ತದೆ ಮತ್ತು ರಕ್ತ ಪರಿಚಲನೆಯನ್ನು ಸುಧಾರಿಸುವ ವೆಸೋಡೈಲೇಷನ್ ಪರಿಣಾಮವನ್ನು ಹೊಂದಿದ್ದು, ಚೈತನ್ಯ, ಹುಮ್ಮಸ್ಸು ಮತ್ತು ಸಹಿಷ್ಣುತೆಯನ್ನು ಸಹ ಉತ್ತೇಜಿಸುತ್ತದೆ. 100% ನೈಸರ್ಗಿಕ ಗಿಡಮೂಲಿಕೆಯ ಪದಾರ್ಥಗಳ ಸ್ವಾಮ್ಯದ ಮಿಶ್ರಣದಿಂದ ತಯಾರಾಗಿದ್ದು, ಇದು ಕೇಸರಿ, ಕುಂಚಬೀಜ ಮತ್ತು ashwagandha ರೀತಿಯಂತಹ 15 ಆಯುರ್ವೇದೀಯ ಮೂಲಿಕೆಗಳನ್ನು ಒಳಗೊಂಡಿದೆ. ಈ ಸೂತ್ರೀಕರಣವು ತೃಪ್ತಿಕರ ಮತ್ತು ಸಂತುಷ್ಟಕರ ಲೈಂಗಿಕ ಜೀವನಕ್ಕಾಗಿ ಪುರುಷರ ಕಾರ್ಯಕ್ಷಮತೆಯ ಮೇಲೆ ಧನಾತ್ಮಕ ಪ್ರಭಾವವನ್ನು ಬೀರಿದೆ. ',

    guideline1: 'Ayurvedic science works with a holistic understanding of the body and the mind.',
    guideline1_hi: 'आयुर्वेद में शरीर और मन दोनों को समग्रता से समझकर ही बीमारी या समस्या का इलाज खोजा जाता है।',
    guideline1_kn: 'ಆಯುರ್ವೇದೀಯ ವಿಜ್ಞಾನವು ದೇಹ ಮತ್ತು ಮನಸ್ಸಿನ ಸಮಗ್ರ ಅರ್ಥವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ. ',
    guideline1_te: 'ఆయుర్వేద విజ్ఞానం శరీరం మరియు మనస్సు యొక్క పూర్తి అవగాహనతో పనిచేస్తుంది.',

    guideline2: 'It works by prevention and treatment of the root cause, rather than responding to symptoms of the issue.',
    guideline2_hi: 'आयुर्वेद सिर्फ लक्षणों पर नहीं, बल्कि रोग को जड़ से समाप्त करता है।',
    guideline2_kn: 'ಇದು ಸಮಸ್ಯೆಯ ಲಕ್ಷಣಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುವ ಬದಲಾಗಿ ಮೂಲ ಕಾರಣವನ್ನು ತಡೆಗಟ್ಟುವ ಮೂಲಕ ಮತ್ತು ಗುಣಪಡಿಸುವ ಮೂಲಕ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ.',
    guideline2_te: 'ఇది సమస్య యొక్క లక్షణాలు తగ్గించటానికి స్పందించటం కన్నా, సమస్య యొక్క మూల కారణానికి వెళ్ళి అక్కడ నివారణకి, చికిత్సకి ప్రయత్నిస్తుంది.',

    guideline3: 'We recommend 45-60 days of continued ayurvedic treatment combined with healthy eating and physical exercise for best results.',
    guideline3_hi: 'हमारा सुझाव है की 45 से 60 दिन तक लगातार इस आयुर्वेदिक सप्लीमेंट्स का प्रयोग करें साथ ही अच्छे खानपान और थोड़ी शारीरिक एक्सरसाइज पर ध्यान दें। समस्या को जड़ से मिटाने के लिए आगे भी सेवन करते रहें।',
    guideline3_kn: 'ಆರೋಗ್ಯಕರ ಆಹಾರ ಸೇವನೆ ಮತ್ತು ದೈಹಿಕ ವ್ಯಾಯಾಮಗಳೊಂದಿಗೆ 45-60 ದಿನಗಳ ನಿರಂತರ ಆಯುರ್ವೇದೀಯ ಚಿಕಿತ್ಸೆಯು ನಿಮಗೆ ಉತ್ತಮ ಫಲಿತಾಂಶಗಳನ್ನು ನೀಡಬಲ್ಲದು',
    guideline3_te: 'ఉత్తమ ఫలితాల కోసం ఆరోగ్యకరమైన ఆహారం మరియు శారీరక వ్యాయామంతో కలిపి 45-60 రోజుల నిరంతర ఆయుర్వేద చికిత్సను మేము సిఫార్సు చేస్తున్నాము.',

    guideline4: '1 pack of Perform has 30 capsules. The pack of 2 will help you seamlessly ease into the natural ayurvedic way of life and onward to the best of health.',
    guideline4_hi: 'परफॉर्म के एक पैक में 30 कैप्सूल्स हैं। कम से कम 2 पैक इस्तेमाल करने पर आपको इस आयुर्वेदिक प्रोडक्ट का लाभ मिलेगा और सेहत पर इसका असर नजर आने लगेगा।',

    months: [
      {
        time: '1 month',
        time_hi: 'पहले महीने में',
        description: 'It will make you feel more energetic and increase your strength and stamina.',
        description_hi: 'आपकी स्टैमिना और ताकत में सुधार होगा और आप ऊर्जावान महसूस करेंगे।',
        icon: Images.icons.iconLight
      },
      {
        time: '2 months',
        time_hi: 'दूसरे महीने में',
        description: 'Boosts testosterone and serotonin levels, as a result of which you will feel more confident in your performance.',
        description_hi: 'टेस्टोस्टेरॉन और सेरोटोनिन हार्मोन्स का लेवल बढ़ेगा, जिससे आप की परफॉर्मेंस में सुधार आएगा और आपका आत्मविश्वास बढ़ेगा।',
        icon: Images.icons.iconArm
      },
      {
        time: '3 months',
        time_hi: 'तीसरे महीने में',
        description: 'Your overall stamina and strength will improve along with your psychological health. You will feel more confident in bed and last longer.',
        description_hi: 'मनोवैज्ञानिक रूप से आप ज्यादा स्थिर रहेंगे, जिससे आपका स्टैमिना बढ़ेगा और आप बिस्तर पर ज्यादा लंबे समय तक टिक पाएंगे, शीघ्रपतन में फ़ायदा पाएंगे।',
        icon: Images.icons.iconHeart
      }
    ],
    faqs: [
      {
        title: 'I don\'t have premature ejaculation issues, can I still consume Misters.in Perform?',
        description: 'Yes! Although Misters.in Perform is designed to help manage Premature Ejaculation, it is a holistic product that promotes a satisfying love life.'
      },
      {
        title: 'Is Misters.in Perform safe to consume?',
        description: 'Absolutely! Misters.in Perform is made with the highest quality ingredients at a GMP certified facility.'
      },
      {
        title: 'Are there any side effects associated with Misters.in Perform?',
        description: 'None, there are no known side effects with Misters.in Perform.'
      },
      {
        title: 'Can Misters.in Perform be used with other products?',
        description: 'Yes! Misters.in Perform works well with Misters.in Josh for Men that is designed to increase libido.'
      }
    ],
    keyClaims: [
      'Kesar improves sexual desire and prevents early orgasm, helpful in improving performance.',
      'Stacked with Kaunchbee, it improves sperm health and aid in delaying ejaculatory time.',
      'Besides Kesar and Ashwagandha, PERFORM features 12 more ayurvedic herbs that have scientifically tested to improve performance and sexual wellness.'
    ],
    how_to_use: {
      stepOne: {
        image: Images.therapy.tablet,
        text: 'Take 1 capsule',
        text_hi: 'एक कैप्सूल',
        text_kn: 'ಒಂದು ಕ್ಯಾಪ್ಸುಲ್ ತೆಗೆದುಕೊಳ್ಳಿ'
      },
      stepTwo: {
        image: Images.therapy.night,
        text: 'every night',
        text_hi: 'हर रात',
        text_kn: 'ಪ್ರತಿ ರಾತ್ರಿ'
      },
      stepThree: {
        image: Images.therapy.dinner,
        text: 'after dinner',
        text_hi: 'खाने के बाद',
        text_kn: 'ಊಟದ ನಂತರ'
      }
    },
    ingredients: {
      image: Images.ingredient.performIngredients,
      data: [
        {
          image: Images.ingredient.kesar,
          description: 'Kesar stimulates blood flow to reproductive organs and builds stamina. It also improves mood and reduces anxiety which is essential for pleasure.',
          description_hi: 'केसर प्रजनन अंग में रक्त प्रवाह बढ़ाता है, जिससे स्टैमिना बढ़ता है। ये आपके मूड को भी सही करता है और तनाव कम करता है, जो आनंद के लिए महत्वपूर्ण है।',
          description_kn: 'ಕೇಸರಿ ಸಂತಾನೋತ್ಪತ್ತಿ ಅಂಗಗಳಿಗೆ ರಕ್ತದ ಹರಿವನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ ಮತ್ತು ರೋಗನಿರೋಧಕ ಶಕ್ತಿಯನ್ನು ನಿರ್ಮಿಸುತ್ತದೆ. ಜೊತೆಗೆ ಇದು ಸುಖಕ್ಕಾಗಿ ಅಗತ್ಯವಿರುವ ಉದ್ವೇಗವನ್ನು ಕಡಿಮೆ ಮಾಡುತ್ತದೆ ಮತ್ತು ಮನಸ್ಸನ್ನು ಸುಧಾರಿಸುತ್ತದೆ.',
          description_te: 'కుంకుమపువ్వు అవయవాలకి రక్తప్రసరణ పెంచి, సామర్థ్యాన్ని పెంచుతుంది. ఇది మూడ్ కూడా బాగుచేసి, లైంగిక ఆనందం కోసం ఆందోళనని తగ్గిస్తుంది.',
          url: '/ingredient/kesar'
        },
        {
          image: Images.ingredient.kaunchbeej,
          description: 'Kaunchbeej improves circulation to genital organs, improves testosterone levels and serves as an aphrodisiac. It decreases stress, anxiety and elevates mood.',
          description_hi: 'कौंच बीज आपके प्राइवेट पार्ट में ब्लड सर्कुलेशन तेज कर देता है, टेस्टोस्टेरॉन हार्मोन का लेवल बढ़ाता है और कामुकता बढ़ाता है। ये तनाव और चिंता दूर करके आपका मूड सही करता है, जिससे आप यौन क्रिया का आनंद ले पाते हैं।',
          url: '/ingredient/kaunch-beej'
        },
        {
          image: Images.ingredient.ashwagandha,
          description: 'Ashwagandha improves tissue vitality, physical and mental endurance, neuromuscular strength and oxygen uptake for peak performance.',
          description_hi: 'अश्वगंधा टिशूज में रक्त प्रवाह बढ़ाता है, जिससे लिंग में उत्तेजना और शक्ति आती है साथ ही आपका परफॉर्मेंस बेहतर होता है।',
          description_kn: 'Ashwagandha ಅಂಗಾಂಶದ ಶಕ್ತಿ, ಮಾನಸಿಕ ಮತ್ತು ದೈಹಿಕ ಸ್ಥಿರತೆ, ನ್ಯೂರೋಮಸ್ಕುಲರ್ ಶಕ್ತಿ ಸುಧಾರಿಸುತ್ತದೆ ಮತ್ತು ಲೈಂಗಿಕ ಕಾರ್ಯಕ್ಷಮತೆಗಾಗಿ ಆಮ್ಲಜನಕವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ',
          description_te: 'Ashwagandha కణజాల శక్తిని మెరుగుపరుస్తుంది, శారీరక మరియు మానసిక ఓర్పుని పెంచుతుంది, నరాలు మరియు కండరాల బలాన్ని, అత్యధ్భుత లైంగిక ప్రదర్శనకి ఆక్సిజన్ ని ఎక్కువగా తీసుకోటాన్ని ప్రేరేపిస్తుంది.',
          url: '/ingredient/ashwagandha'
        }
      ]
    }
  },
  ayurimmunity: {
    amazonRating: 5,
    featured_image: Images.therapy.immunityBanner,
    featured_video_url: 'https://www.youtube.com/embed/_g2AxUt13qI',
    short_description: 'Research suggests that men have a weaker immune system compared to women. Strengthening your immune system enables your body to defend against bacterial and viral infections. \n\nMisters.in immunity booster for men is designed by ayurvedic experts to give a boost to your immune system to help you stay strong and healthy.',
    short_description_hi: 'रिसर्च बताती हैं कि पुरुषों की इम्यूनिटी महिलाओं से कमजोर होती है। अच्छा इम्यून सिस्टम आपको बीमारियों और इंफेक्शन से बचाता है। मिस्टर्स का इम्यूनिटी बूस्टर खास पुरुषों की इम्यूनिटी को मजबूती देने के लिए बनाया गया है।',
    months: [],

    guideline1: 'Ayurvedic science works with a holistic understanding of the body and the mind.',
    guideline1_hi: 'आयुर्वेद में शरीर और मन दोनों को समग्रता से समझकर ही बीमारी या समस्या का इलाज खोजा जाता है।',
    guideline1_kn: 'ಆಯುರ್ವೇದೀಯ ವಿಜ್ಞಾನವು ದೇಹ ಮತ್ತು ಮನಸ್ಸಿನ ಸಮಗ್ರ ಅರ್ಥವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ. ',
    guideline1_te: 'ఆయుర్వేద విజ్ఞానం శరీరం మరియు మనస్సు యొక్క పూర్తి అవగాహనతో పనిచేస్తుంది.',

    guideline2: 'It works by prevention and treatment of the root cause, rather than responding to symptoms of the issue.',
    guideline2_hi: 'आयुर्वेद सिर्फ लक्षणों पर नहीं, बल्कि रोग को जड़ से समाप्त करता है।',
    guideline2_kn: 'ಇದು ಸಮಸ್ಯೆಯ ಲಕ್ಷಣಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುವ ಬದಲಾಗಿ ಮೂಲ ಕಾರಣವನ್ನು ತಡೆಗಟ್ಟುವ ಮೂಲಕ ಮತ್ತು ಗುಣಪಡಿಸುವ ಮೂಲಕ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ.',
    guideline2_te: 'ఇది సమస్య యొక్క లక్షణాలు తగ్గించటానికి స్పందించటం కన్నా, సమస్య యొక్క మూల కారణానికి వెళ్ళి అక్కడ నివారణకి, చికిత్సకి ప్రయత్నిస్తుంది.',

    guideline3: 'We recommend 45-60 days of continued ayurvedic treatment combined with healthy eating and physical exercise for best results.',
    guideline3_hi: 'हमारा सुझाव है की 45 से 60 दिन तक लगातार इस आयुर्वेदिक सप्लीमेंट्स का प्रयोग करें साथ ही अच्छे खानपान और थोड़ी शारीरिक एक्सरसाइज पर ध्यान दें। समस्या को जड़ से मिटाने के लिए आगे भी सेवन करते रहें।',
    guideline3_kn: 'ಆರೋಗ್ಯಕರ ಆಹಾರ ಸೇವನೆ ಮತ್ತು ದೈಹಿಕ ವ್ಯಾಯಾಮಗಳೊಂದಿಗೆ 45-60 ದಿನಗಳ ನಿರಂತರ ಆಯುರ್ವೇದೀಯ ಚಿಕಿತ್ಸೆಯು ನಿಮಗೆ ಉತ್ತಮ ಫಲಿತಾಂಶಗಳನ್ನು ನೀಡಬಲ್ಲದು',
    guideline3_te: 'ఉత్తమ ఫలితాల కోసం ఆరోగ్యకరమైన ఆహారం మరియు శారీరక వ్యాయామంతో కలిపి 45-60 రోజుల నిరంతర ఆయుర్వేద చికిత్సను మేము సిఫార్సు చేస్తున్నాము.',

    guideline4: '1 pack of Immunity booster has 30 capsules. The pack of 2 will help you seamlessly ease into the natural ayurvedic way of life and onward to the best of health.',
    guideline4_hi: 'इम्युनिटी बूस्टर के एक पैक में 30 कैप्सूल्स हैं। कम से कम 2 पैक इस्तेमाल करने पर आपको इस आयुर्वेदिक प्रोडक्ट का लाभ मिलेगा और सेहत पर इसका असर नजर आने लगेगा।',
    faqs: [
      {
        title: 'How does Misters.in Immunity Booster for men help strengthen the immune system?',
        description: 'Misters.in Immunity Booster for men is made with Amla which is rich in Vitamin C, Neem and Giloy which have anti-microbial and immune stimulatory properties and Ashwagandha which is known to activate lymphocytes, the main type of white blood cells in the body.'
      },
      {
        title: 'Are there any side effects of this product?',
        description: 'There are no known side effects of this product, it is a 100% ayurvedic product.'
      },
      {
        title: 'Can Misters.in Immunity Booster for men be used with other routine medications like diabetes and heart disease tablets?',
        description: 'Misters.in Immunity Booster for men can be used with other medication safely, but if you have any major health condition, we recommend you to consult your doctor before purchasing.'
      },
      {
        title: 'Can women take this product?',
        description: 'Misters.in Immunity Booster for men is designed for men, however women can also benefit from it. '
      }
    ],
    how_to_use: {
      stepOne: {
        image: Images.therapy.tablet,
        text: 'Take 1 capsule',
        text_hi: 'एक कैप्सूल',
        text_kn: 'ಒಂದು ಕ್ಯಾಪ್ಸುಲ್ ತೆಗೆದುಕೊಳ್ಳಿ'
      },
      stepTwo: {
        image: Images.therapy.night,
        text: 'every night',
        text_hi: 'हर रात',
        text_kn: 'ಪ್ರತಿ ರಾತ್ರಿ'
      },
      stepThree: {
        image: Images.therapy.dinner,
        text: 'after dinner',
        text_hi: 'खाने के बाद',
        text_kn: 'ಊಟದ ನಂತರ'
      }
    },
    ingredients: {
      image: Images.ingredient.immunityIngredients,
      data: [
        {
          image: Images.ingredient.ashwagandha,
          description: 'Ashwagandha, or Indian ginseng activates the production of lymphocytes, a key white blood cell that helps fight infections. Ashwagandha increases the body\'s response to flu, lumps and other common infections.',
          url: '/ingredient/ashwagandha'
        },
        {
          image: Images.ingredient.giloy,
          description: 'Giloy is a powerhouse of antioxidants and anti-inflammatory actives like Cordifolioside A that remove free radicals. It improves digestion and fights infection-causing microbes.',
          url: ''
        },
        {
          image: Images.ingredient.amla,
          description: 'Amla, or Indian gooseberry contains 20x more Vitamin C than orange. Vitamin C helps boost the production of white blood cells. It prevents common cold and cough and has antioxidant, analgesic, antimicrobial, antipyretic and gastroprotective properties.',
          url: '/ingredient/amla'
        }
      ]
    }
  },
  ayurhairpack: {
    amazonRating: 4.4,
    featured_image: Images.therapy.hairPackBanner,
    featured_video_url: 'https://www.youtube.com/embed/Ed3Bb7Wnzew',
    short_description: 'Misters.in Hairgrowth pack for men is India’s first ayurvedic hair growth pack designed for men. Developed with ancient knowledge of Ayurveda and validated by modern science. \n\nMade with 17 ayurvedic ingredients including bringharaj, amla, hibiscus, neem, shilajit and others, it is designed to work synergistically to promote hair growth and improve key markers of hair health.',
    short_description_hi: 'मिस्टर्स का हेयर ग्रोथ पैक फॉर मेन भारत का पहला आयुर्वेदिक प्रोडक्ट है, जो खास पुरुषों के झड़ते बालों की समस्या रोकने के लिए डिजाइन किया गया है। इसे आयुर्वेद में हजारों वर्षों से प्रयोग होने वाली ऐसी 17 जड़ी बूटियों से बनाया गया है, जिनके फायदों पर विज्ञान भी अपनी मुहर लगा चुका है।',

    guideline1: 'Ayurvedic science works with a holistic understanding of the body and the mind.',
    guideline1_hi: 'आयुर्वेद में शरीर और मन दोनों को समग्रता से समझकर ही बीमारी या समस्या का इलाज खोजा जाता है।',
    guideline1_kn: 'ಆಯುರ್ವೇದೀಯ ವಿಜ್ಞಾನವು ದೇಹ ಮತ್ತು ಮನಸ್ಸಿನ ಸಮಗ್ರ ಅರ್ಥವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ. ',
    guideline1_te: 'ఆయుర్వేద విజ్ఞానం శరీరం మరియు మనస్సు యొక్క పూర్తి అవగాహనతో పనిచేస్తుంది.',

    guideline2: 'It works by prevention and treatment of the root cause, rather than responding to symptoms of the issue.',
    guideline2_hi: 'आयुर्वेद सिर्फ लक्षणों पर नहीं, बल्कि रोग को जड़ से समाप्त करता है।',
    guideline2_kn: 'ಇದು ಸಮಸ್ಯೆಯ ಲಕ್ಷಣಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುವ ಬದಲಾಗಿ ಮೂಲ ಕಾರಣವನ್ನು ತಡೆಗಟ್ಟುವ ಮೂಲಕ ಮತ್ತು ಗುಣಪಡಿಸುವ ಮೂಲಕ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ.',
    guideline2_te: 'ఇది సమస్య యొక్క లక్షణాలు తగ్గించటానికి స్పందించటం కన్నా, సమస్య యొక్క మూల కారణానికి వెళ్ళి అక్కడ నివారణకి, చికిత్సకి ప్రయత్నిస్తుంది.',

    guideline3: 'We recommend 45-60 days of continued ayurvedic treatment combined with healthy eating and physical exercise for best results.',
    guideline3_hi: 'हमारा सुझाव है की 45 से 60 दिन तक लगातार इस आयुर्वेदिक सप्लीमेंट्स का प्रयोग करें साथ ही अच्छे खानपान और थोड़ी शारीरिक एक्सरसाइज पर ध्यान दें। समस्या को जड़ से मिटाने के लिए आगे भी सेवन करते रहें।',
    guideline3_kn: 'ಆರೋಗ್ಯಕರ ಆಹಾರ ಸೇವನೆ ಮತ್ತು ದೈಹಿಕ ವ್ಯಾಯಾಮಗಳೊಂದಿಗೆ 45-60 ದಿನಗಳ ನಿರಂತರ ಆಯುರ್ವೇದೀಯ ಚಿಕಿತ್ಸೆಯು ನಿಮಗೆ ಉತ್ತಮ ಫಲಿತಾಂಶಗಳನ್ನು ನೀಡಬಲ್ಲದು',
    guideline3_te: 'ఉత్తమ ఫలితాల కోసం ఆరోగ్యకరమైన ఆహారం మరియు శారీరక వ్యాయామంతో కలిపి 45-60 రోజుల నిరంతర ఆయుర్వేద చికిత్సను మేము సిఫార్సు చేస్తున్నాము.',

    months: [
      {
        time: '1 month',
        time_hi: 'पहले महीने में',
        description: 'you’ll notice a decline in hair fall. Your scalp will be nourished and cleansed.',
        description_hi: 'आपके बालों का झड़ना कम होने लगेगा। आपके स्कैल्प को पोषण मिलेगा और वो साफ़ भी रहेगी।',
        icon: Images.icons.hairOne
      },
      {
        time: '2 months',
        time_hi: 'दूसरे महीने में',
        description: 'you’ll notice new hair growth on the scalp.',
        description_hi: 'आप देखेंगे कि आपके सिर पर नए बाल उगना शुरू हो गए हैं।',
        icon: Images.icons.hairTwo
      },
      {
        time: '3 months',
        time_hi: 'तीसरे महीने में',
        description: 'New hair follicles will grow and hair loss will completely stop. Continue this treatment for better results.',
        description_hi: 'नए हेयर फॉलिकल्स उगेंगे और बालों का झड़ना पूरी तरह बंद हो जाएगा। अच्छे परिणाम के लिए इसका प्रयोग जारी रखें।',
        icon: Images.icons.hairThree
      }
    ],
    faqs: [
      {
        title: 'How long do I need to use Misters.in Hairgrowth pack for men to get new hair?',
        description: 'It is recommended to use Misters.in Hairgrowth pack for men for atleast 2 months, while you may start seeing the difference in 3-4 weeks, we recommend using it consistently for two months.'
      },
      {
        title: 'What shampoo to use with Misters.in Hairgrowth pack for men?',
        description: 'Any mild shampoo can be used with Misters.in Hairgrowth pack for men.'
      },
      {
        title: 'Can women use this product?',
        description: 'While this product is designed for men, women can also use it.'
      },
      {
        title: 'Does this product colour my hair or leave colour stains?',
        description: 'No, Misters.in Hairgrowth pack for men does not colour or leave stains on your hair.'
      },
      {
        title: 'Can I use this product more than 3 times a week?',
        description: 'Yes, it can be used more than 3 times a week. For best results, it is recommended to be used thrice a week.'
      }
    ],
    how_to_use: {
      stepOne: {
        image: Images.therapy.spoon,
        text: 'Mix 2.5 spoons of powder',
        text_hi: 'ढाई चम्मच पाउडर'
      },
      stepTwo: {
        image: Images.therapy.bowl,
        text: 'in a bowl of water',
        text_hi: 'एक कटोरी पानी में मिक्स करें'
      },
      stepThree: {
        image: Images.therapy.watch,
        text: 'apply and keep for 45 minutes before washing',
        text_hi: 'पेस्ट को सिर पर 45 मिनट के लिए लगाएं'
      },
      footerText: 'Wash with a mild shampoo. Use twice a week.',
      footerText_hi: 'माइल्ड शैंपू से सिर को धो लें। सप्ताह में 2 बार प्रयोग करें।'
    },
    ingredients: {
      image: Images.ingredient.hairPackIngredients,
      data: [
        {
          image: Images.ingredient.bhringaraj,
          description: 'Bhringraj, also known as the king of hair, helps in regrowth of hair. It keeps the scalp clean due to its antibacterial and antifungal properties.',
          url: '/ingredient/bhringraj'
        },
        {
          image: Images.ingredient.shilajit,
          description: 'Shilajit has antimicrobial and antioxidant properties which keep the scalp healthy and prevent hair damage from free radicals.',
          url: '/ingredient/shilajit'
        },
        {
          image: Images.ingredient.neem,
          description: 'Neem has fatty acids, calcium, antioxidants, and vitamin E. It has a cleansing action on the scalp and prevents dandruff.',
          url: ''
        }
      ]
    }
  },
  ayurshilajitpro: {
    featured_image: Images.therapy.shilajitproBanner,
    featured_video_url: 'https://www.youtube.com/embed/8ec9S9mdfLI',
    short_description: 'In this modern age, lifestyle, stress, lack of sleep, poor diet and lack of exercise can have a negative impact on men’s drive, strength and stamina. Shilajit Pro is a medical blend created with the help of doctors for peak efficacy with ideal dosage of pure Himalayan Shilajit (400 mg) and medicinal Ashwagandha (150 mg). Both Shilajit and Ashwagandha enhance the effect of one another to significantly improve strength and stamina in men.',

    guideline1: 'Ayurvedic science works with a holistic understanding of the body and the mind.',
    guideline1_hi: 'आयुर्वेद में शरीर और मन दोनों को समग्रता से समझकर ही बीमारी या समस्या का इलाज खोजा जाता है।',
    guideline1_kn: 'ಆಯುರ್ವೇದೀಯ ವಿಜ್ಞಾನವು ದೇಹ ಮತ್ತು ಮನಸ್ಸಿನ ಸಮಗ್ರ ಅರ್ಥವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ. ',
    guideline1_te: 'ఆయుర్వేద విజ్ఞానం శరీరం మరియు మనస్సు యొక్క పూర్తి అవగాహనతో పనిచేస్తుంది.',

    guideline2: 'It works by prevention and treatment of the root cause, rather than responding to symptoms of the issue.',
    guideline2_hi: 'आयुर्वेद सिर्फ लक्षणों पर नहीं, बल्कि रोग को जड़ से समाप्त करता है।',
    guideline2_kn: 'ಇದು ಸಮಸ್ಯೆಯ ಲಕ್ಷಣಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುವ ಬದಲಾಗಿ ಮೂಲ ಕಾರಣವನ್ನು ತಡೆಗಟ್ಟುವ ಮೂಲಕ ಮತ್ತು ಗುಣಪಡಿಸುವ ಮೂಲಕ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ.',
    guideline2_te: 'ఇది సమస్య యొక్క లక్షణాలు తగ్గించటానికి స్పందించటం కన్నా, సమస్య యొక్క మూల కారణానికి వెళ్ళి అక్కడ నివారణకి, చికిత్సకి ప్రయత్నిస్తుంది.',

    guideline3: 'We recommend 45-60 days of continued ayurvedic treatment combined with healthy eating and physical exercise for best results.',
    guideline3_hi: 'हमारा सुझाव है की 45 से 60 दिन तक लगातार इस आयुर्वेदिक सप्लीमेंट्स का प्रयोग करें साथ ही अच्छे खानपान और थोड़ी शारीरिक एक्सरसाइज पर ध्यान दें। समस्या को जड़ से मिटाने के लिए आगे भी सेवन करते रहें।',
    guideline3_kn: 'ಆರೋಗ್ಯಕರ ಆಹಾರ ಸೇವನೆ ಮತ್ತು ದೈಹಿಕ ವ್ಯಾಯಾಮಗಳೊಂದಿಗೆ 45-60 ದಿನಗಳ ನಿರಂತರ ಆಯುರ್ವೇದೀಯ ಚಿಕಿತ್ಸೆಯು ನಿಮಗೆ ಉತ್ತಮ ಫಲಿತಾಂಶಗಳನ್ನು ನೀಡಬಲ್ಲದು',
    guideline3_te: 'ఉత్తమ ఫలితాల కోసం ఆరోగ్యకరమైన ఆహారం మరియు శారీరక వ్యాయామంతో కలిపి 45-60 రోజుల నిరంతర ఆయుర్వేద చికిత్సను మేము సిఫార్సు చేస్తున్నాము.',

    guideline4: '1 pack of Josh has 30 capsules. The pack of 2 will help you seamlessly ease into the natural ayurvedic way of life and onward to the best of health.',
    guideline4_hi: 'जोश के एक पैक में 30 कैप्सूल्स हैं। कम से कम 2 पैक इस्तेमाल करने पर आपको इस आयुर्वेदिक प्रोडक्ट का लाभ मिलेगा और सेहत पर इसका असर नजर आने लगेगा।',
    guideline4_kn: 'ಒಂದು ಪ್ಯಾಕ್ ಜೋಶ್ ನಲ್ಲಿ 30 ಕ್ಯಾಪ್ಸುಲುಗಳು ಇರುತ್ತವೆ. ಎರಡರ ಪ್ಯಾಕ್ ನಿಮಗೆ ನೈಸರ್ಗಿಕ ಆಯುರ್ವೇದೀಯ ಜೀವನವನ್ನು ನಡೆಸಲು ಮತ್ತು ಅತ್ಯುತ್ತಮ ಆರೋಗ್ಯವನ್ನು ಕಾಪಾಡಲು ಸಹಾಯಮಾಡುತ್ತದೆ.',
    guideline4_te: '1 జోష్ ప్యాక్ లో 30 క్యాప్స్యూల్స్ ఉంటాయి. 2 ప్యాకెట్లు సహజ ఆయుర్వేద జీవనశైలిలోకి మిమ్మల్ని సులభంగా మారేలా చేస్తాయి మరియు ఇక మెరుగైన ఆరోగ్యం వచ్చే దారిపై పట్టు సాధిస్తారు.',
    months: [
      {
        time: '1 month',
        time_hi: 'पहले महीने में',
        description: 'It will improve overall testosterone levels and help you feel more energetic.',
        icon: Images.icons.iconBrain
      },
      {
        time: '2 months',
        time_hi: 'दूसरे महीने में',
        description: 'You should feel more relaxed and notice an improved quality of sleep.',
        icon: Images.icons.iconArm
      },
      {
        time: '3 months',
        time_hi: 'तीसरे महीने में',
        description: 'Unlock the maximum benefits. Your overall stamina and strength will increase, boosting your appetite for intimacy.',
        icon: Images.icons.iconHeart
      }
    ],
    ingredients: {
      image: Images.ingredient.shilajitproIngredients,
      data: [
        {
          image: Images.ingredient.shilajit,
          description: 'Shilajit in its purest form has a high concentration of fulvic acid that helps in boosting strength and overall vitality in men. 400 mg of pure Himalayan Shilajit is scientifically verified to be the strongest, highly efficacious dosage that delivers the benefits quicker than most Shilajit products on the market.',
          url: '/ingredient/shilajit'
        },
        {
          image: Images.ingredient.ashwagandha,
          description: 'Ashwagandha has scientifically validated the ability to reduce cortisol levels in the body. It improves tissue vitality, physical and mental endurance, neuromuscular strength and oxygen uptake for peak performance. Misters.in Shilajit Pro for Men has 150mg of Ashwagandha standardised to 5% withanolides, the active molecule in Ashwagandha, for increased efficacy and improved synergy with Shilajit.',
          url: '/ingredient/ashwagandha'
        }
      ]
    },
    faqs: [
      {
        title: 'What does Misters Shilajit Pro for Men do?',
        description: 'Misters Shilajit Pro for Men is designed to significantly improve your strength and stamina, made with the purest Himalayan Shilajit and standardised Ashwagandha, it is designed for high efficacy and value for money.'
      },
      {
        title: 'Is it safe to consume Misters Shilajit Pro for Men with other medicines?',
        description: 'While Misters Shilajit Pro for Men is safe to consume and isn’t known to interact with other medicines,If you have any medical conditions like diabetes, cardiovascular illness, blood pressure issue and such, please consult your doctor before consuming this product.'
      },
      {
        title: 'Can women take Misters Shilajit Pro for Men?',
        description: 'Misters Shilajit Pro for Men is designed to improve strength and stamina in men, however, it is absolutely safe for women to consume it as well. If you are pregnant or have medical issues, please consult your doctor before consuming this product.'
      },
      {
        title: 'I don\'t have any sexual issues, can I still take Misters Shilajit Pro for Men?',
        description: 'Yes, Misters Shilajit Pro for Men is not designed to treat any sexual issue. It enhances strength and stamina in men which also helps enhance erections and male performance, but is not limited to it. '
      },
      {
        title: 'Are there any side effects with Misters Shilajit Pro for Men?',
        description: 'No. Misters Shilajit Pro for Men is 100% Ayurvedic and there are no known side effects with Misters Shilajit Pro for Men.'
      },
      {
        title: 'What happens if I take more than 2 capsules of Misters Shilajit Pro for Men?',
        description: 'The dosage of Misters Shilajit Pro for Men has been scientifically verified to work with optimal efficacy. Do not consume more than the recommended intake suggested, unless approved by a registered medical practitioner.'
      },
      {
        title: 'What is Herbal Ingredient Stack Score?',
        description: 'Herbal ingredient stack score (HISS) is a novel concept developed by Misters.in to measure the efficacy of a product in terms of the ingredients and its dosage. This score also takes into consideration the stacking strategy and how the ingredients present in a product works together, in synergy, to give maximum benefit. HISS is a tool to compare the product’s efficacy, with other products in the market targeting a similar use case. It is designed to help customers choose the best amongst the available products for maximum benefit.'
      }
    ],
    how_to_use: {
      stepOne: {
        image: Images.therapy.tablet,
        text: 'Take 1 capsule',
        text_hi: 'एक कैप्सूल',
        text_kn: 'ಒಂದು ಕ್ಯಾಪ್ಸುಲ್ ತೆಗೆದುಕೊಳ್ಳಿ'
      },
      stepTwo: {
        image: Images.therapy.night,
        text: 'every night',
        text_hi: 'हर रात',
        text_kn: 'ಪ್ರತಿ ರಾತ್ರಿ'
      },
      stepThree: {
        image: Images.therapy.dinner,
        text: 'after dinner',
        text_hi: 'खाने के बाद',
        text_kn: 'ಊಟದ ನಂತರ'
      }
    },
    keyClaims: [
      'Shilajit, an abundant source of fulvic acid, boosts testosterone and improves strength. Shilajit Pro has 400mg of shilajit that is both clinically efficacious and safe to use. ',
      '5% standardised Ashwagandha provide stress and anxiety alleviating effect.',
      'Both Shilajit and Ashwagandha, at clinically safe and efficacious doses, works in synergy to aid in improving overall sexual health.'
    ],
    compareProducts: {
      description: 'Shilajit Pro has a significantly higher Herbal Ingredient Stack (HIS) Score compared to other products in the market.',
      products: [
        {
          image: Images.therapy.shilajitproBanner,
          title: 'Misters.in Shilajit Pro for men',
          hisScore: 32
        },
        {
          image: ImageService.products.others.dabur,
          title: 'Dabur Shilajit Gold Capsule',
          hisScore: 17.27
        },
        {
          image: ImageService.products.others.zandu,
          title: 'Zandu Shilajit Capsule',
          hisScore: 10
        },
        {
          image: ImageService.products.others.baidyanath,
          title: 'Baidyanath Vita Ex Gold capsules',
          hisScore: 30.6
        }
      ]
    }
  },
  cosenhance: {
    featured_image: Images.therapy.enhanceBanner,
    featured_video_url: 'https://www.youtube.com/embed/Woxj5XbNlyI',
    short_description: 'Misters Enhance for Men is an intimate moisturising cream that is formulated with herbs and amino acids for improving sexual health. It is to be applied topically before self-stimulation.',
    faqs: [
      {
        title: 'Does Misters Enhance for Men cause any side effects?',
        description: 'No, there are no known side effects caused by Misters Enhance for Men.'
      },
      {
        title: 'Does Misters Enhance for Men create a burning sensation on sensitive skin?',
        description: 'No, Misters Enhance for Men creates a warm and pleasant feeling. It is carefully designed and tested to not cause any adverse reactions. However, if you experience any allergic effects, stop using the product immediately and consult a doctor. We recommend that you do a patch test before applying the product on your sensitive areas. To do that, please use a small amount on your forearm and wait 30 minutes before proceeding to use the product.'
      },
      {
        title: 'Is it ok to consume Misters Enhance for Men?',
        description: 'Misters Enhance for Men is not designed for oral consumption. Please do not consume the product. If the product is accidentally consumed or comes in contact with your eye, please see a doctor immediately.'
      },
      {
        title: 'Can I use Misters Enhance for Men as a regular moisturiser?',
        description: 'Yes, you can use Misters Enhance for Men regularly for increasing your endurance and for its antibacterial effect.'
      },
      {
        title: 'Can women use Misters Enhance for Men?',
        description: 'While Misters Enhance for Men is designed for men, it can be used by women safely as an intimate moisturiser.'
      }
    ],
    keyClaims: [
      'L-arginine, an important amino acid plays an essential role in the erection process.',
      'Clove oil has anaesthetic properties for extended pleasure.',
      'Aloe vera, Vitamin E, and Coconut oil provide moisturization and anti-inflammatory properties.'
    ]
  },
  ayurashwagandhapro: {
    featured_image: Images.therapy.shilajitproBanner,
    featured_video_url: 'https://www.youtube.com/embed/8ec9S9mdfLI',
    short_description: 'In this modern age, lifestyle, stress, lack of sleep, poor diet and lack of exercise can have a negative impact on men’s drive, strength and stamina. Shilajit Pro is a medical blend created with the help of doctors for peak efficacy with ideal dosage of pure Himalayan Shilajit (400 mg) and medicinal Ashwagandha (150 mg). Both Shilajit and Ashwagandha enhance the effect of one another to significantly improve strength and stamina in men.',

    guideline1: 'Ayurvedic science works with a holistic understanding of the body and the mind.',
    guideline1_hi: 'आयुर्वेद में शरीर और मन दोनों को समग्रता से समझकर ही बीमारी या समस्या का इलाज खोजा जाता है।',
    guideline1_kn: 'ಆಯುರ್ವೇದೀಯ ವಿಜ್ಞಾನವು ದೇಹ ಮತ್ತು ಮನಸ್ಸಿನ ಸಮಗ್ರ ಅರ್ಥವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ. ',
    guideline1_te: 'ఆయుర్వేద విజ్ఞానం శరీరం మరియు మనస్సు యొక్క పూర్తి అవగాహనతో పనిచేస్తుంది.',

    guideline2: 'It works by prevention and treatment of the root cause, rather than responding to symptoms of the issue.',
    guideline2_hi: 'आयुर्वेद सिर्फ लक्षणों पर नहीं, बल्कि रोग को जड़ से समाप्त करता है।',
    guideline2_kn: 'ಇದು ಸಮಸ್ಯೆಯ ಲಕ್ಷಣಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುವ ಬದಲಾಗಿ ಮೂಲ ಕಾರಣವನ್ನು ತಡೆಗಟ್ಟುವ ಮೂಲಕ ಮತ್ತು ಗುಣಪಡಿಸುವ ಮೂಲಕ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ.',
    guideline2_te: 'ఇది సమస్య యొక్క లక్షణాలు తగ్గించటానికి స్పందించటం కన్నా, సమస్య యొక్క మూల కారణానికి వెళ్ళి అక్కడ నివారణకి, చికిత్సకి ప్రయత్నిస్తుంది.',

    guideline3: 'We recommend 45-60 days of continued ayurvedic treatment combined with healthy eating and physical exercise for best results.',
    guideline3_hi: 'हमारा सुझाव है की 45 से 60 दिन तक लगातार इस आयुर्वेदिक सप्लीमेंट्स का प्रयोग करें साथ ही अच्छे खानपान और थोड़ी शारीरिक एक्सरसाइज पर ध्यान दें। समस्या को जड़ से मिटाने के लिए आगे भी सेवन करते रहें।',
    guideline3_kn: 'ಆರೋಗ್ಯಕರ ಆಹಾರ ಸೇವನೆ ಮತ್ತು ದೈಹಿಕ ವ್ಯಾಯಾಮಗಳೊಂದಿಗೆ 45-60 ದಿನಗಳ ನಿರಂತರ ಆಯುರ್ವೇದೀಯ ಚಿಕಿತ್ಸೆಯು ನಿಮಗೆ ಉತ್ತಮ ಫಲಿತಾಂಶಗಳನ್ನು ನೀಡಬಲ್ಲದು',
    guideline3_te: 'ఉత్తమ ఫలితాల కోసం ఆరోగ్యకరమైన ఆహారం మరియు శారీరక వ్యాయామంతో కలిపి 45-60 రోజుల నిరంతర ఆయుర్వేద చికిత్సను మేము సిఫార్సు చేస్తున్నాము.',

    guideline4: '1 pack of Josh has 30 capsules. The pack of 2 will help you seamlessly ease into the natural ayurvedic way of life and onward to the best of health.',
    guideline4_hi: 'जोश के एक पैक में 30 कैप्सूल्स हैं। कम से कम 2 पैक इस्तेमाल करने पर आपको इस आयुर्वेदिक प्रोडक्ट का लाभ मिलेगा और सेहत पर इसका असर नजर आने लगेगा।',
    guideline4_kn: 'ಒಂದು ಪ್ಯಾಕ್ ಜೋಶ್ ನಲ್ಲಿ 30 ಕ್ಯಾಪ್ಸುಲುಗಳು ಇರುತ್ತವೆ. ಎರಡರ ಪ್ಯಾಕ್ ನಿಮಗೆ ನೈಸರ್ಗಿಕ ಆಯುರ್ವೇದೀಯ ಜೀವನವನ್ನು ನಡೆಸಲು ಮತ್ತು ಅತ್ಯುತ್ತಮ ಆರೋಗ್ಯವನ್ನು ಕಾಪಾಡಲು ಸಹಾಯಮಾಡುತ್ತದೆ.',
    guideline4_te: '1 జోష్ ప్యాక్ లో 30 క్యాప్స్యూల్స్ ఉంటాయి. 2 ప్యాకెట్లు సహజ ఆయుర్వేద జీవనశైలిలోకి మిమ్మల్ని సులభంగా మారేలా చేస్తాయి మరియు ఇక మెరుగైన ఆరోగ్యం వచ్చే దారిపై పట్టు సాధిస్తారు.',
    months: [
      {
        time: '1 month',
        time_hi: 'पहले महीने में',
        description: 'It will improve overall testosterone levels and help you feel more energetic.',
        icon: Images.icons.iconBrain
      },
      {
        time: '2 months',
        time_hi: 'दूसरे महीने में',
        description: 'You should feel more relaxed and notice an improved quality of sleep.',
        icon: Images.icons.iconArm
      },
      {
        time: '3 months',
        time_hi: 'तीसरे महीने में',
        description: 'Unlock the maximum benefits. Your overall stamina and strength will increase, boosting your appetite for intimacy.',
        icon: Images.icons.iconHeart
      }
    ],
    ingredients: {
      image: Images.ingredient.shilajitproIngredients,
      data: [
        {
          image: Images.ingredient.shilajit,
          description: 'Shilajit in its purest form has a high concentration of fulvic acid that helps in boosting strength and overall vitality in men. 400 mg of pure Himalayan Shilajit is scientifically verified to be the strongest, highly efficacious dosage that delivers the benefits quicker than most Shilajit products on the market.',
          url: '/ingredient/shilajit'
        },
        {
          image: Images.ingredient.ashwagandha,
          description: 'Ashwagandha has scientifically validated the ability to reduce cortisol levels in the body. It improves tissue vitality, physical and mental endurance, neuromuscular strength and oxygen uptake for peak performance. Misters.in Shilajit Pro for Men has 150mg of Ashwagandha standardised to 5% withanolides, the active molecule in Ashwagandha, for increased efficacy and improved synergy with Shilajit.',
          url: '/ingredient/ashwagandha'
        }
      ]
    },
    faqs: [
      {
        title: 'What does Misters Shilajit Pro for Men do?',
        description: 'Misters Shilajit Pro for Men is designed to significantly improve your strength and stamina, made with the purest Himalayan Shilajit and standardised Ashwagandha, it is designed for high efficacy and value for money.'
      },
      {
        title: 'Is it safe to consume Misters Shilajit Pro for Men with other medicines?',
        description: 'While Misters Shilajit Pro for Men is safe to consume and isn’t known to interact with other medicines,If you have any medical conditions like diabetes, cardiovascular illness, blood pressure issue and such, please consult your doctor before consuming this product.'
      },
      {
        title: 'Can women take Misters Shilajit Pro for Men?',
        description: 'Misters Shilajit Pro for Men is designed to improve strength and stamina in men, however, it is absolutely safe for women to consume it as well. If you are pregnant or have medical issues, please consult your doctor before consuming this product.'
      },
      {
        title: 'I don\'t have any sexual issues, can I still take Misters Shilajit Pro for Men?',
        description: 'Yes, Misters Shilajit Pro for Men is not designed to treat any sexual issue. It enhances strength and stamina in men which also helps enhance erections and male performance, but is not limited to it. '
      },
      {
        title: 'Are there any side effects with Misters Shilajit Pro for Men?',
        description: 'No. Misters Shilajit Pro for Men is 100% Ayurvedic and there are no known side effects with Misters Shilajit Pro for Men.'
      },
      {
        title: 'What happens if I take more than 2 capsules of Misters Shilajit Pro for Men?',
        description: 'The dosage of Misters Shilajit Pro for Men has been scientifically verified to work with optimal efficacy. Do not consume more than the recommended intake suggested, unless approved by a registered medical practitioner.'
      },
      {
        title: 'What is Herbal Ingredient Stack Score?',
        description: 'Herbal ingredient stack score (HISS) is a novel concept developed by Misters.in to measure the efficacy of a product in terms of the ingredients and its dosage. This score also takes into consideration the stacking strategy and how the ingredients present in a product works together, in synergy, to give maximum benefit. HISS is a tool to compare the product’s efficacy, with other products in the market targeting a similar use case. It is designed to help customers choose the best amongst the available products for maximum benefit.'
      }
    ],
    how_to_use: {
      stepOne: {
        image: Images.therapy.tablet,
        text: 'Take 1 capsule',
        text_hi: 'एक कैप्सूल',
        text_kn: 'ಒಂದು ಕ್ಯಾಪ್ಸುಲ್ ತೆಗೆದುಕೊಳ್ಳಿ'
      },
      stepTwo: {
        image: Images.therapy.night,
        text: 'every night',
        text_hi: 'हर रात',
        text_kn: 'ಪ್ರತಿ ರಾತ್ರಿ'
      },
      stepThree: {
        image: Images.therapy.dinner,
        text: 'after dinner',
        text_hi: 'खाने के बाद',
        text_kn: 'ಊಟದ ನಂತರ'
      }
    },
    keyClaims: [
      'Shilajit, an abundant source of fulvic acid, boosts testosterone and improves strength. Shilajit Pro has 400mg of shilajit that is both clinically efficacious and safe to use. ',
      '5% standardised Ashwagandha provide stress and anxiety alleviating effect.',
      'Both Shilajit and Ashwagandha, at clinically safe and efficacious doses, works in synergy to aid in improving overall sexual health.'
    ],
    compareProducts: {
      description: 'Shilajit Pro has a significantly higher Herbal Ingredient Stack (HIS) Score compared to other products in the market.',
      products: [
        {
          image: Images.therapy.shilajitproBanner,
          title: 'Misters.in Shilajit Pro for men',
          hisScore: 32
        },
        {
          image: ImageService.products.others.dabur,
          title: 'Dabur Shilajit Gold Capsule',
          hisScore: 17.27
        },
        {
          image: ImageService.products.others.zandu,
          title: 'Zandu Shilajit Capsule',
          hisScore: 10
        },
        {
          image: ImageService.products.others.baidyanath,
          title: 'Baidyanath Vita Ex Gold capsules',
          hisScore: 30.6
        }
      ]
    }
  }
}
