import api from '../api'

export const CREATE_LEAD = 'CREATE_LEAD'
export const CREATE_LEAD_RESULT = 'CREATE_LEAD_RESULT'
export const CREATE_LEAD_ERROR = 'CREATE_LEAD_ERROR'
export const CREATE_LEAD_RETRY = 'CREATE_LEAD_RETRY'

export const createLead = (data) => async dispatch => {
  try {
    dispatch({ type: CREATE_LEAD })
    const res = await api.clinic.lead(data)
    const result = await res.json()
    if (res.status === 201) {
      dispatch({ type: CREATE_LEAD_RESULT, data: result })
    } else {
      dispatch({ type: CREATE_LEAD_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: CREATE_LEAD_RETRY, message: e.message })
  }
}
