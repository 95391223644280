// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../../Category/common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText/LText'
import { openLinkOnNewTab } from '../../../utils'

const shopUrl = '/en/solutions/'

export const accordionList1 = [
  <AccordionCard key={'0'} title="Where is Ashwagandha found?">
    <p>
      Considered one of the most vital herbs of Ayurveda, Ashwagandha is also known by a plethora of other names
      including Ajagandha, Amangura, Amukkirag, Asan, Asana, Asgand, Cerise Hiver, Haya Vaya, Kanaje Hindi,
      Kuthmithi, Orovale, Payette, Samm Al Ferakh, Sogade-Beru, Strychnos, Turangi-Ghanda and Vajigandha. This
      shrub also grows in profusion in countries like <b>Nepal, China, Middle East, Africa, and Yemen</b>. Today,
      the increase demand for this herb has led to its cultivation in milder climates of countries like that of
      the <b>United States</b>.
      <br/>
      <br/>
      A part of the nightshade species, Ashwagandha grows well in dry, rocky soil and needs sun as well as partial
      shade. This plant is well suited for the Indian climate and grows well across the country and also in the
      famous forest reserves including the <b>Sariska Tiger Reserve, Ranthambore National Park and Eravikulam
      National Park</b>. Indian families also grow this herb in their kitchen garden.
    </p>
    <div className="text-center">
      <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
    </div>
  </AccordionCard>,
  <AccordionCard key={'1'} title="What is the description of an Ashwagandha plant?">
    <p>
      The plant is <b>stout, short and tender</b>, and grows up to <b>35–75 cm in height</b>. For cultivation, the
      seeds of the plant can be sown at the beginning of spring; it can also be grown from greenwood, and its
      cuttings planted towards the end of the spring season. The plant has oval-shaped leaves which are dull green
      in colour and oval in shape, and <b>10–12 cm in length</b>. Flowers are <b>yellow-coloured, small and
      bell-shaped</b> while the fruits are red or orange and are of the size of raisins. For the preparation of
      the supplements, extracts are typically obtained from the roots of the plant which is known for multiple
      medicinal qualities like an aphrodisiac, anthelmintic, astringent, diuretic, narcotic, thermogenic and
      stimulant. However, the berries are also used for the preparation of pills or powder.
    </p>
    <div className="text-center">
      <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
    </div>
  </AccordionCard>
]

export const accordionList2 = [
  <AccordionCard key={'0'} title="What is the history of Ashwagandha?">
    <p>
      Ashwagandha is a <b>classical Ayurvedic medical herb</b> which has been in <b>use for more than 5,000 years
      in India</b>. Since long this herb has been used to treat conditions like infertility, physical weakness,
      rheumatism, constipation, sleeplessness, nerve-related issues, stress, thyroid, inflammation, parasite
      attack and hormone imbalances, to name a few conditions.
      <br/>
      <br/>
      Historically, the medical practitioners of India used Ashwagandha root extracts and made them into powder or
      paste to be applied topically to treat ulcers, wounds and other skin infections. According to Charak
      Samhita, ever since the advent of Ayurveda, Ashwagandha has been called <b>‘Sattvic Kapha Rasayana’</b>.
      Rasayana, which is a Sanskrit term coined in the early ayurvedic medical dialect. ‘āyana’ means path and
      ‘rasa’ means essence. It connotes the knowledge of expanding one’s lifespan. Eventually, during the
      8th-century, the term rasayana often referred to Indian alchemy.
      <br/>
      <br/>
      Numerous references to Ashwagandha can be found in <b>ancient, historical, modern Ayurvedic and Unani
      medicinal texts</b>. Over the years this medicinal herb has been recognised and lauded all over the world
      and across cultural spectrums. The first reference of the word Ashwagandha can be traced to the sacred
      Ayurvedic texts of the Charaka and the Sushruta Samhitas, where it is mentioned as one of the most powerful
      medical herbs, particularly known for its restorative and recuperative qualities.
      <br/>
      <br/>
      The name Ashwagandha dates back to its ancient and historical use for promoting a vigorous sex drive, as
      ‘ashwa’ in Ashwagandha means horse which is a very energetic animal. Besides, the powdered extracts of the
      herb are known to have a smell similar to that of a horse. That said, the herb has also been used since ages
      for a calming and restoring effect, especially after illness or surgery. Ashwagandha has been extensively
      used in Indian Ayurveda since the prehistoric era. History is replete with references of Ashwagandha
      prescribed by ancient medical practitioners for its wound healing capacity.
      <br/>
      <br/>
      Conventionally, the freshly plucked leaves of the plant were applied topically to cure joint pains, skin
      wounds and swellings.
    </p>
    <div className="text-center">
      <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
    </div>
  </AccordionCard>,
  <AccordionCard key={'1'} title="What are some famous stories surrounding the Ashwagandha herb?">
    <p>
      The plant has found mention in the teachings and dictates of one of the most revered Hindu sages, <b>Punarvasu
      Atreya</b>. He was appointed as the principal physician of the ruling king of his time.
      <br/>
      <br/>
      This renowned Rishi’s teachings, later formed the basis of the Charaka Samhita. Legend has it that <b>Alexander
      the Great</b> along with his huge army used to make wine and concoct it with the Ashwagandha plant. This
      made them more energised and valiant during the war.
      <br/>
      <br/>
      Another allegory goes that <b>Apollo the Greek god</b> of healing, medicine and archery and also one of the
      Twelve Olympians, spotted this herb and handed it over to his son Asclepius. Interestingly, Asclepius is
      known as the most illustrious champion of healing, truth, and prophecy in Greek mythology.
    </p>
    <div className="text-center">
      <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
    </div>
  </AccordionCard>,
  <AccordionCard key={'2'} title="What is Ashwagandha made of?">
    <p>
      The active Constituents of Ashwagandha are what makes it such an effective herbal
      medicine. It is rich in <b>steroidal compounds like lactones, Withaferin A, and carbon-27-glycowithanolides,
      which are together grouped as Withanolides</b>. The plant also has a substantial
      quantity of <b>alkaloids; tropine, pseudotropine isopelletrine, anaferine, and saponins</b>. These compounds
      promote antioxidant, anti-inflammatory, and immunomodulatory activities and are administered in the human
      body for treating and preventing conditions like rheumatism, amnesia, stress, depression, impotence and
      neurodegenerative diseases.
      <br/>
      <br/>
      Research findings further revealed other characteristics of Ashwagandha, like that of
      A cardioprotective, anti-microbial, anti-tumour and anti-diabetic herb. These qualities are derived from the
      plant’s potential in reducing reactive oxygen species and inflammation, regulating apoptosis, modulating
      mitochondrial function, and promoting endothelial functions.
      <br/>
      <br/>
      Some of the key phytochemical constituents of Ashwagandha are <b>Withanolides</b> that is the <b>triterpene
      lactones, Withanolides, Withaferin A, Alkaloids, Steroidal lactones, Tropine and Cuscohygrine</b>.
      <br/>
      <br/>
      Researchers have been successful in isolating about <b>40 withanolides, 12 alkaloids, and numerous
      sitoindosides</b> from an Ashwagandha sample. Since the withanolides have structural similarity to the
      ginsenosides of Panax ginseng, the name Indian ginseng was coined for Ashwagandha or W. somnifera.
      <br/>
      <br/>
      Moreover, the chemical ingredients that are biologically active in Ashwagandha are Alkaloids like
      isopelletierine, anaferine, cuscohygrine, anahygrine, Steroidal lactones like withanolides and withaferins)
      and Saponins.
      <br/>
      <br/>
      It is the presence of constituents like <b>Sitoindosides and acyl steryl glucosides</b> which
      makesAshwagandha an anti-stress agent and substance. <b>Sitoindosides VII-X</b> and <b>Withaferin-A</b> are
      particularly responsible for relieving stress. Some of the other constituents of Ashwagandha have the
      potential to promote immunomodulatory actions. The parts of the plant that stay above the ground have
      ingredients like <b>5-dihydroxy, withanolide-R and withasomniferin-A</b>.
      <br/>
      <br/>
      Ashwagandha is a well-known adaptogen and helps in controlling stress that is both physical and mental.
      These qualities have made Ashwagandha an extremely potential rejuvenating herbal medicine which is effective
      in developing cognisance, relieving joint pain and irritation, and controlling the aging process. The plant
      extracts are also used for curing hormone imbalance and treating many other chronic health conditions like
      restlessness and exhaustion.
    </p>
    <div className="text-center">
      <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
    </div>
  </AccordionCard>,
  <AccordionCard key={'3'} title="What are the benefits of Ashwagandha?">
    <p>
      Ayurveda calls the exotic herb, Ashwagandha, ‘balya.’‘Bal’ in Sanskrit means <b>strength</b>. This is
      because Ashwagandha is known to <b>boost energy levels and build overall stamina</b> and <b>staying
      power</b>. The plant is gaining recognition as a gifted alternative method of treatment for an array of
      diseases.
      <br/>
      <br/>
      <strong>Growth of nerve cells:</strong> Researchers have found that the plant extracts have special
      properties to treat mental degenerative diseases by promoting the growth of nerve cells. Ashwagandha is
      known to shield the brain cells from damaging environmental effects.
      <br/>
      <br/>
      <strong>Giving strength:</strong> Since antiquity, Ashwagandha, a natural adaptogen, has been administered
      by physicians as an all-purpose tonic for endowing strength and promoting wellness. Chyawanprash, the widely
      popular mixture present in every Indian household, which contains Ashwagandha. Studies further indicate that
      the herb might enhance swimming performance in humans by preventing and reducing adrenal cortisol and
      ascorbic acid levels resulting from swimming-related body stress.
      <br/>
      <br/>
      <strong>Sexual health:</strong> Another very significant contribution of Ashwagandha is its effectiveness as
      an aphrodisiac and a herb promoting sexual well-being. It also addresses male infertility by improving sperm
      quality in men. However, it is said that this herb does not increase sperm count. However, Ashwagandha
      supplements, when taken regularly for about eight to nine weeks together with counselling sessions, can
      increase sexual desire and satisfaction in women having sexual dysfunctions.
      <br/>
      <br/>
      <strong>Hormonal balance:</strong> Ashwagandha promotes hormone balance and thus influences the endocrine
      system. Studies also prove that the plant can address symptoms like hot flashes and mood swings at the time
      of menopause.
      <br/>
      <br/>
      <strong>Anti-ulcerogenic:</strong> Ashwagandha supplements are effective in checking ulcers of the
      gastrointestinal tract, which are mainly caused by stress. Research results show that the plant can provide
      efficient protection against immobilization and aspirin induced gastric ulcers.
      <br/>
      <br/>
      <strong>Stress relief:</strong> Ashwagandha can significantly bring down stress levels by controlling the
      rise of cortisol levels. Presently, the herb is used in tranquilizers and antidepressants, as it is
      instrumental in alleviating stress that is both physical and mental.
      <br/>
      <br/>
      <strong>Sleep inducing:</strong> The rejuvenating traits of the herb make it highly useful in insomnia
      treatment. Ashwagandha extracts soothe the nerves, relieves stress and addresses
      sleeplessness. Customarily, Ashwagandha powder has been mixed with raw honey and warm goat-milk for relaxing
      the vata dosha and moderating the sleeping and waking process.
      <br/>
      <br/>
      <strong>Beauty, hair and skincare:</strong> Ashwagandha supplements greatly enliven dry, wrinkled and
      sagging skin. Being a strong antioxidant substance, the herbal extracts help defend the skin from free
      radical damage and control the ageing process among adults. This leads to one’s skin looking firm and
      youthful. Ashwagandha fuels-up DHEA, which in turn boosts production of testosterone and estrogen as well as
      the secretion of natural oils. The secretion of vital compounds and proteins like hyaluronan for hydration
      of the skin, elastin for keeping it supple and collagen for providing the skin with strength are also
      promoted by Ashwagandha, when used along with lemon and sun-dried ginger.
      <br/>
      <br/>
      Ashwagandha is liberally used in shampoos as it is known to enhance scalp circulation and give strength to
      the hair while doing away with dandruff. Besides, it also encourages melanin secretion. Melanin being the
      pigment that gives colour to the hair. The use of Ashwagandha products can effectively slow down the greying
      process of one’s hair. Additionally, the herb can also be effective in addressing hair loss.
    </p>
    <h3>Some other benefits:</h3>
    <p>
      Ashwagandha alleviates side effects of antipsychotics medications which are prescribed in schizophrenia
      treatment but are known to raise fat and sugar levels in
      the blood. Ashwagandha extracts taken for a month or so can <b>decrease the fat and
      sugar levels in the blood</b> of people undergoing antipsychotics medication treatment.
      <br/>
      <br/>
      Herbal products that made use of Ashwagandha are known to <b>improve the attention span</b> and <b>impulse
      control</b> in children afflicted with Attention deficit-hyperactivity disorder (ADHD).
      <br/>
      <br/>
      Ashwagandha supplements, when taken for eight to ten weeks, can <b>improve brain functions</b> in people
      with bipolar disorder. Such a supplement can also address conditions like cerebellar ataxia by <b>improving
      bodily balance</b>.
      <br/>
      <br/>
      Those with <b>hypothyroidism</b>, in other words, those with high levels of thyroid stimulating hormones
      (TSH) in their blood and low levels of thyroid hormone can be made healthier with the help of Ashwagandha.
      <br/>
      <br/>
      <b>Arthritis</b> can also be treated by using Ashwagandha together with the zinc complex,
      guggul, and raw turmeric. Rheumatoid arthritis is also addressed by ashwagandha powder when taken for about
      three weeks. This dosage is generally followed by four weeks of consuming the mixture of gold, mercury, and
      sulphur – popularly called sidh makardhwaj in India.
      <br/>
      <br/>
      <b>Obsessive-compulsive disorder or OCD</b> is also addressed by root extracts of Ashwagandha if used in
      prescribed quantities for nearly six weeks along with other Medications.
      <br/>
      <br/>
      In addition consuming Ashwagandha extracts while undergoing chemotherapy treatment can also reduce the
      resultant fatigue experienced by the procedure.
    </p>

    <ImageBreaker image={Images.ingredient.ashwagandhaBenefits} to={shopUrl} buttonText={'SHOP'}/>

    <br/>
    <br/>

    <p className="text-center font-weight-bold">
      <span>To know more about the benefits of Ashwagandha, </span>
      <span className="ingredient-blog-links"
            onClick={() => openLinkOnNewTab('/blog/What-are-the-benefits-of-Ashwagandha')}>click here</span>
    </p>
  </AccordionCard>
]

export const accordionList3 = [
  <AccordionCard key={'0'} title="What is the dosage of Ashwagandha?">
    <p>
      Ayurveda says that the human body is made from the food it receives, the nutrients which are ingested and
      metabolised, to form energy. In addition, the body also builds cells, tissues, and organs. The body requires
      essential vitamins, minerals, and other nutrients on a regular basis to sustain the delicate balance of its
      systems. Herbal supplements like Ashwagandha provide the body with these necessary nutrients.
      <br/>
      <br/>
      Like all other natural supplements, the accurate dose of Ashwagandha varies according to the user’s age,
      health condition, and lifestyle choices and such other factors. Till now no such standardised scientific
      rule exists to decide the correct proportions of Ashwagandha doses. In spite of the fact that organic and
      herbal products are more or less safe and devoid of serious side effects, overdose can lead to health
      hazards. Hence it is extremely vital to read and follow the appropriate directions written on the labels of
      the products and also to talk to a doctor, pharmacist or such other healthcare experts before consuming
      Ashwagandha.
      <br/>
      <br/>
      The dose also depends on the type of health disorder or condition that needs to be treated. In general,
      Ashwagandha can be taken as a <b>powder in a dose of 450 mg to 2 g every day</b>.
      <br/>
      <br/>
      <b>Capsules and liquid varieties</b> of Ashwagandha extracts are also readily available at stores selling
      health-food and herbal-supplements. Ashwagandha supplements are available in a finely powdered form which
      has to be <b>mixed and consumed with honey or ghee (clear butter)</b>.
      <br/>
      <br/>
      People belonging to Western cultures prefer Ashwagandha capsules.
    </p>
    <h3>Here is some available information about Ashwagandha dosages for certain health conditions:</h3>
    <p>
      For treating <b>stress and anxiety, 300-500 mg</b> of Ashwagandha root extract can be consumed twice, daily,
      after meals. If the dose is taken once it has to be taken after breakfast. This dose must be continued for
      two months in order to experience results like reduced stress and an augmented impact of anxiolytic agents.
      <br/>
      <br/>
      For <b>insomnia</b>, the tentative dosage is Ashwagandha <b>powder</b>, consumed <b>with a cup of hot goat
      milk before bedtime</b>. One can also opt for an Ashwagandha capsule.
      <br/>
      <br/>
      The impact of Ashwagandha products on stress and anxiety constantly increases for a period of two months
      after one starts consuming the supplement daily. Moreover, studies say that keeping the dose amount and
      timing the same, also helps in achieving better results.
      <br/>
      <br/>
      It is not yet known if stopping the dose of Ashwagandha or changing it from every day to alternate days,
      yields the same results. The implications of administering Ashwagandha directly to the skin are also
      unknown.
    </p>
    <div className="text-center">
      <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
    </div>
  </AccordionCard>,
  <AccordionCard key={'1'} title="What are the side effects of Ashwagandha?">
    <p>
      Herbal supplements are known to be generally safe and do not have serious health
      ramifications. That said they can cause certain health threats if not taken in prescribed quantities or at
      times, even without any known cause.
      <br/>
      <br/>
      Ashwagandha is known to lower blood sugar levels and hence can tamper with diabetes treatment and result
      in <b>dangerously low blood sugar</b>.
      <br/>
      <br/>
      Ashwagandha can also <b>lower blood pressure</b> and for people taking medication for low blood pressure,
      this could be dangerous. Besides, it can also tamper with high blood pressure treatments.
      <br/>
      <br/>
      According to health experts, Ashwagandha is likely to <b>aggravate the gastrointestinal tract infection</b>;
      hence those suffering from stomach ulcers of those having a history of the same, must avoid it.
      <br/>
      <br/>
      Increase in testosterone levels is also a side effect. Hence patients with hormone-sensitive prostate cancer
      must stay away from Ashwagandha products.
      <br/>
      <br/>
      Those on medications like benzodiazepines, anticonvulsants, or barbiturates must also avoid Ashwagandha as
      it can cause sedative and GABAergic effects.
      <br/>
      <br/>
      Intake of Ashwagandha products by pregnant women can lead to <b>abortion</b>. Impact of consuming
      Ashwagandha by lactating mothers is still unknown. However, it is better to stay safe and avoid it while
      breastfeeding.
      <br/>
      <br/>
      <b>Fake Ashwagandha capsules</b> are known to cause Hemolytic anaemia and <b>abdominal pain</b>.
      <br/>
      <br/>
      As Ashwagandha creates a drug-like effect on neurotransmission, unintended side-
      effects in the form of <b>loss of potency</b>, is a possibility, especially if taken over a longer duration
      of time.
      <br/>
      <br/>
      Consuming Ashwagandha capsules for chronic fatigue can lead to an <b>overactive thyroid</b>.
      <br/>
      <br/>
      <b>Burning, irregular heartbeat, dizziness, itching, a rise of liver enzymes, skin rash, fatigue, fever,
        oedema, diarrhoea and discolouration of skin/mucous membranes</b> are some other commonly reported side
      effects.
      <br/>
      <br/>
      Ashwagandha can hyper-activate the immune system, thus increasing symptoms of auto-immune diseases like <b>multiple
      sclerosis (MS) and lupus, rheumatoid arthritis</b>, or other conditions. People afflicted with these
      disorders must avoid using this herbal supplement.
      <br/>
      <br/>
      Ashwagandha has the potential to <b>slow down the central nervous system</b>. According to doctors,
      anaesthesia and such other medications administered during and after any surgical procedure can augment this
      effect. It is important to stop the ashwagandha dose at least two to three weeks before a surgery is
      scheduled.
      <br/>
      <br/>
      <b>Mild sleepiness and sedation, nausea, headache, stomach irritation, upper GI discomfort, loose
        stools</b> in others are also common side effects.
    </p>
    <div className="text-center">
      <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
    </div>
  </AccordionCard>,
  <AccordionCard key={'2'} title="How long does it take for the Ashwagandha to work?">
    <p>
      The time taken for Ashwagandha to work also varies from one individual to another. However, the standard
      rule is to <b>wait for about a month</b> to see the best results. Also, according to Ayurveda, it takes a
      year for any herbal medicine to yield tangible results. The time taken for Ashwagandha to work and show
      results also depends on the type of condition it is taken for.
      <br/>
      <br/>
      While acting as an anti-ageing herb, Ashwagandha is prescribed in lower doses, which is recommended to
      continue for a couple of months. Ashwagandha will take some months to bring about the desired action, and it
      will happen gradually. Again, as an antidepressant, <b>anti-stress</b> and sleep aid herb, Ashwagandha is
      known to yield tangible results in just about <b>5 - 7 days</b>. As an <b>energy-boosting</b> herb, it takes
      nearly <b>3 - 5 days</b> to show results and in <b>stimulating sexual drive</b>, the herbal medicine can
      take nearly a <b>week</b> to show the best results.
    </p>
    <div className="text-center">
      <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
    </div>
  </AccordionCard>,
  <AccordionCard key={'3'} title="References">
    <ul>
      <li>
        <a href="https://www.webmd.com/vitamins/ai/ingredientmono-953/ashwagandha" target="_blank">
          https://www.webmd.com/vitamins/ai/ingredientmono-953/ashwagandha
        </a>
      </li>
      <li>
        <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3252722/" target="_blank">
          https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3252722/
        </a>
      </li>
      <li>
        <a href="https://food.ndtv.com/ayurveda/ashwagandha-the-powerful-health-benefits-and-beauty-benefits-you-need-to-know-1220328"
           target="_blank">
          https://food.ndtv.com/ayurveda/ashwagandha-the-powerful-health-benefits-and-beauty-benefits-you-need-to-know-1220328
        </a>
      </li>
      <li>
        <a href="https://examine.com/supplements/ashwagandha/"
           target="_blank">
          https://examine.com/supplements/ashwagandha/
        </a>
      </li>
      <li>
        <a href="https://www.medicalnewstoday.com/articles/318407" target="_blank">
          https://www.medicalnewstoday.com/articles/318407
        </a>
      </li>
      <li>
        <a href="https://www.mskcc.org/cancer-care/integrative-medicine/herbs/ashwagandha" target="_blank">
          https://www.mskcc.org/cancer-care/integrative-medicine/herbs/ashwagandha
        </a>
      </li>
      <li>
        <a href="https://www.herb-pharm.com/pharm-journal/pharm-education-ashwagandha/" target="_blank">
          https://www.herb-pharm.com/pharm-journal/pharm-education-ashwagandha/
        </a>
      </li>
      <li>
        <a href="https://www.sciencedirect.com/topics/agricultural-and-biological-sciences/withania-somnifera"
           target="_blank">
          https://www.sciencedirect.com/topics/agricultural-and-biological-sciences/withania-somnifera
        </a>
      </li>
      <li>
        <a href="https://www.researchgate.net/publication/311806429_AN_AYURVEDIC_REVIEW_OF_ASHWAGANDHA_FROM_SAMHITHA_AND_NIGHANTUS"
           target="_blank">
          https://www.researchgate.net/publication/311806429_AN_AYURVEDIC_REVIEW_OF_ASHWAGANDHA_FROM_SAMHITHA_AND_NIGHANTUS
        </a>
      </li>
      <li>
        <a href="https://academic.oup.com/ajcn/article/72/2/624S/4729720" target="_blank">
          https://academic.oup.com/ajcn/article/72/2/624S/4729720
        </a>
      </li>
      <li>
        <a href="http://anaturalhealingcenter.com/documents/Thorne/articles/Ashwagandha.pdf" target="_blank">
          http://anaturalhealingcenter.com/documents/Thorne/articles/Ashwagandha.pdf
        </a>
      </li>
      <li>
        <a href="https://www.ingentaconnect.com/content/ben/cnsamc/2010/00000010/00000003/art0000"
           target="_blank">
          https://www.ingentaconnect.com/content/ben/cnsamc/2010/00000010/00000003/art0000
        </a>
      </li>
    </ul>
    <div className="text-center">
      <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
    </div>
  </AccordionCard>
]
