// External Imports
import { combineReducers } from 'redux'
// My Imports
import lead from './lead'
import clinic from './clinic'

export default combineReducers({
  lead,
  clinic
})
