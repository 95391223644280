import React from 'react'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PHONE_NUMBER } from '../../../constants'

export default class CheckoutHelp extends React.Component {
  render () {
    const style = { color: '#ccc', fontSize: '0.8em' }
    return (
            <div className="d-flex justify-content-center bg-white">
                <span className="pr-3" style={style}>
                    <FontAwesomeIcon icon={faPhone} size="1x"/>
                    <span className="pl-1">{PHONE_NUMBER}</span>
                </span>
                <span style={style}>
                    <FontAwesomeIcon icon={faEnvelopeOpen} size="1x"/>
                    <span className="pl-1">m@misters.in</span>
                </span>
            </div>
    )
  }
}
