import {
  FETCH_DOCTORS,
  FETCH_DOCTORS_RESULT,
  FETCH_DOCTORS_ERROR,
  FETCH_DOCTORS_RETRY,

  FETCH_DOCTOR,
  FETCH_DOCTOR_RESULT,
  FETCH_DOCTOR_ERROR,
  FETCH_DOCTOR_RETRY
} from '../../actions/community/doctors'

const initialState = {
  detail: {},
  list: {},
  isFetchingList: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCTORS:
      return { ...state, isFetchingList: true, error: false, message: null, retry: false }
    case FETCH_DOCTORS_RESULT:
      return { ...state, isFetchingList: false, list: action.data }
    case FETCH_DOCTORS_ERROR:
      return { ...state, isFetchingList: false, error: true, message: action.message }
    case FETCH_DOCTORS_RETRY:
      return { ...state, isFetchingList: false, retry: true, message: action.message }

    case FETCH_DOCTOR:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_DOCTOR_RESULT:
      return { ...state, isFetching: false, detail: action.data }
    case FETCH_DOCTOR_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_DOCTOR_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    default:
      return state
  }
}

export default reducer
