export default ({ items }) => {
  const schemaTagInnerHTML = ({
    '@type': 'ItemList',
    '@context': 'https://schema.org',
    name: 'Products',
    numberOfItems: items.length,
    itemListElement: items.map(
      (item, index) => ({
        image: item.image,
        url: item.url,
        '@type': 'ListItem',
        name: item.title,
        position: index + 1
      })
    )
  })

  return {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(schemaTagInnerHTML)
  }
}
