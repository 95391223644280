import {
  FETCH_CART,
  FETCH_CART_RESULT,
  FETCH_CART_ERROR,
  FETCH_CART_RETRY,

  UPDATE_CART,
  UPDATE_CART_RESULT,
  UPDATE_CART_ERROR,
  UPDATE_CART_RETRY,

  FETCH_CONFIG,
  FETCH_CONFIG_RESULT,
  FETCH_CONFIG_ERROR,
  FETCH_CONFIG_RETRY,

  UPDATE_CART_COUPON,
  UPDATE_CART_COUPON_RESULT,
  UPDATE_CART_COUPON_ERROR,
  UPDATE_CART_COUPON_RETRY,

  CREATE_CART_ITEM,
  CREATE_CART_ITEM_RESULT,
  CREATE_CART_ITEM_ERROR,
  CREATE_CART_ITEM_RETRY,

  DELETE_CART_ITEM,
  DELETE_CART_ITEM_RESULT,
  DELETE_CART_ITEM_ERROR,
  DELETE_CART_ITEM_RETRY,

  UPDATE_CART_PAYMENT_METHOD,
  UPDATE_CART_PAYMENT_METHOD_RESULT,
  UPDATE_CART_PAYMENT_METHOD_ERROR,
  UPDATE_CART_PAYMENT_METHOD_RETRY
} from '../../actions/ecommerce/cart'

const initialState = {
  detail: {},
  config: {},
  isFetching: false,
  isConfigFetching: false,
  isUpdating: false,
  isCouponUpdating: false,
  isMethodUpdating: false,
  isCreatingCartItem: false,
  isDeletingCartItem: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CART:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_CART_RESULT:
      return { ...state, isFetching: false, detail: action.data }
    case FETCH_CART_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_CART_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case UPDATE_CART:
      return { ...state, isUpdatingCart: true, error: false, retry: false, message: null }
    case UPDATE_CART_RESULT:
      return { ...state, isUpdatingCart: false, detail: action.data }
    case UPDATE_CART_ERROR:
      return { ...state, isUpdatingCart: false, error: true, message: action.message }
    case UPDATE_CART_RETRY:
      return { ...state, isUpdatingCart: false, retry: true, message: action.message }

    case FETCH_CONFIG:
      return { ...state, isConfigFetching: true, error: false, message: null, retry: false }
    case FETCH_CONFIG_RESULT:
      return { ...state, isConfigFetching: false, config: action.data }
    case FETCH_CONFIG_ERROR:
      return { ...state, isConfigFetching: false, error: true, message: action.message }
    case FETCH_CONFIG_RETRY:
      return { ...state, isConfigFetching: false, retry: true, message: action.message }

    case UPDATE_CART_COUPON:
      return { ...state, isCouponUpdating: true, error: false, retry: false, message: null }
    case UPDATE_CART_COUPON_RESULT:
      return { ...state, isCouponUpdating: false, detail: action.data }
    case UPDATE_CART_COUPON_ERROR:
      return { ...state, isCouponUpdating: false, error: true, message: action.message }
    case UPDATE_CART_COUPON_RETRY:
      return { ...state, isCouponUpdating: false, retry: true, message: action.message }

    case CREATE_CART_ITEM:
      return { ...state, isCreatingCartItem: true, error: false, retry: false, message: null }
    case CREATE_CART_ITEM_RESULT:
      return { ...state, isCreatingCartItem: false, detail: action.data }
    case CREATE_CART_ITEM_ERROR:
      return { ...state, isCreatingCartItem: false, error: true, message: action.message }
    case CREATE_CART_ITEM_RETRY:
      return { ...state, isCreatingCartItem: false, retry: true, message: action.message }

    case DELETE_CART_ITEM:
      return { ...state, isDeletingCartItem: true, error: false, retry: false, message: null }
    case DELETE_CART_ITEM_RESULT:
      return { ...state, isDeletingCartItem: false, detail: action.data }
    case DELETE_CART_ITEM_ERROR:
      return { ...state, isDeletingCartItem: false, error: true, message: action.message }
    case DELETE_CART_ITEM_RETRY:
      return { ...state, isDeletingCartItem: false, retry: true, message: action.message }

    case UPDATE_CART_PAYMENT_METHOD:
      return { ...state, isMethodUpdating: true, error: false, retry: false, message: null }
    case UPDATE_CART_PAYMENT_METHOD_RESULT:
      return { ...state, isMethodUpdating: false, detail: action.data }
    case UPDATE_CART_PAYMENT_METHOD_ERROR:
      return { ...state, isMethodUpdating: false, error: true, message: action.message }
    case UPDATE_CART_PAYMENT_METHOD_RETRY:
      return { ...state, isMethodUpdating: false, retry: true, message: action.message }

    default:
      return { ...state }
  }
}

export default reducer
