// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../../Category/common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText/LText'
import { openLinkOnNewTab } from '../../../utils'

const shopUrl = '/en/solutions/'

export const accordionList1 = [
    <AccordionCard key={'0'} title="What are some alternative names for Bhringraj?">
        <p>
            Also this herb is popularly known as Bhringraj itself, it is also referred to as ‘false daisy’ or Keshraja.
            The <b>botanical name</b> for Bhringraj is <b>Eclipta alba</b>. It is known as <b>Trailing Eclipta in
            English; Bhangra, Mochakand, Babri and Bhangra in Hindi; Bheemraja, Kesuriya, Kesari, Kesuti and Keshwri in
            Bengali; Bhangra, Kaluganthi, Dodhak and Kalobhangro in Gujarati; Garagada and Soppu in Kannada; Kannunni
            and Kayyonni in Malayalam and Maka and Bhringuraja in Marathi</b>.
            <br/>
            <br/>
            In addition to these names, it is also known as <b>Kaikesi, Garuga and Kayanthakara in Tamil; Guntakalagara
            and Guntagalagara in Telugu; Kadim – el – bint in Arabi; Bhrngaraja in Assam; Kesara and Kesarda in Oriya;
            Lal Kesari in Santhal; Tik in Sindh; Kikirindi in Sing; Kesaraja, Tekaraja, Bhrnga, Markava and Bhrngaja in
            Sanskrit; Bhangra in Punjabi and Bhangra in Urdu</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What is the description of a Bhringraj plant?">
        <p>
            The <b>bushes</b> of the Bhringraj plant are about <b>10 – 12 fingers high</b> and are spread over the
            ground. The <b>trunk</b> of the Bhringraj tree is <b>blackish grey</b> in color and is full of branches.
            Its <b>leaves</b> are about <b>1 – 4 inches long and about ½ - 1 inches broad</b>. The leaves are sharp and
            somewhat similar to either an oval or rectangular shape. Its stem is small, with white flowers. These
            flowers generally bloom during the rainy season. The <b>fruits</b> of the Bhringraj plant are about <b>1
            inch long</b> with two visible lines running across them. These fruits generally ripen and grow around the
            winter season. Bhringraj seeds are small in size and somewhat similar to that of purple fleabane (kali
            jiri).
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="What are the ayurvedic properties of Bhringraj?">
        <p>
            The varied Ayurvedic properties of Bhringraj make it efficient to benefit an individual’s health on multiple
            levels. Bhringraj is <b>Laghu (light)</b> and <b>Rooksha (dry)</b> in <b>nature</b>. This makes it easy to
            digest and extracts excessive oil from the body which could cause skin problems like pimples or acne. It
            is <b>Tikta (bitter) in taste</b> and <b>Katu (pungent)</b> in its <b>aftertaste and post digestion</b>. It
            is <b>Ushna (hot) in potency</b>. Bhringraj is beneficial in managing health disorders that occur due to an
            imbalance in the <b>Vata and Kapha Doshas</b>. This is because Bhringraj has the <b>ability to balance both
            these Doshas</b> efficiently, aiding individuals to live a disease free life.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the benefits of Bhringraj?">
        <p>
            Bhringraj is an <b>essential herb</b> used in the traditional Indian system of ayurvedic medicine. The
            science of Ayurveda has provided individuals with <b>multiple health benefits</b> in the past and continues
            to do so. The leaf extract of Bhringraj is considered as a <b>powerful liver tonic</b>,
            a <b>rejuvenator</b> and one of the best herbs for <b>hair health</b>.
            <br/>
            <br/>
            There has also been data to support that Bhringraj is capable of providing symptomatic relief when it comes
            to certain conditions like gastric pain, nausea and vomiting.
            <br/>
            <br/>
            Many men face specific hair problems like hair fall, dry hair, split ends, premature greying or any kind of
            scalp itching and irritation. The reasons for these conditions could be various internal or external
            factors. External factors can include but are not limited to excessive exposure to dirt or pollution.
            Internal factors may include but are not limited to wrong eating habits, weak or poor digestion and stress.
            One or more of these factors can lead to a lack of nutritional value reaching the hair. This may cause hair
            strands to lose their strength and shine. The <b>Keshya (hair tonic) property</b> in Bhringraj helps to <b>bring
            back lost shine and strength to the hair</b> and <b>prevent premature greying</b>. Bhringraj also manages
            the factors which might cause hair problems in the future with its <b>Deepan and Pachan (appetizer and
            digestion) properties</b>. This facilitates a <b>healthy digestive system</b>.
            <br/>
            <br/>
            The Deepan (appetizer) or Rochak (taste enhancer) properties of Bhringraj are used in the treatment of
            various metabolic disorders like indigestion and anorexia. This ensures that an individual’s appetite is
            enhanced, preventing formation and accumulation of harmful toxins in the body.
            <br/>
            <br/>
            Diabetes occurs mainly due to an imbalance in the Vata and Kapha Doshas, according to Ayurveda. <b>Brhringraj’s
            Katu (pungent) and Tikta (bitter) tastes</b>, along with its <b>ability to balance the Vata and Kapha
            Doshas</b>, effectively <b>treats diabetic patients</b>.
            <br/>
            <br/>
            The <b>Mutral (diuretic) property</b> of Bhringraj makes it indispensable in the treatment of urinary tract
            related problems such as <b>retention in urine, incomplete urination or a burning sensation whilst passing
            urine</b>. Bhringraj helps to <b>increase the urine production in the body</b> which helps to reduce painful
            urinary tract related symptoms, thus providing relief. It is beneficial in the treatment and <b>passage of
            renal stones</b> as well.
            <br/>
            <br/>
            Bhringraj is great for those who seek to consume natural supplements which provide them with strength and
            stamina. Its <b>Balya (strength provider) property</b>, specifically caters to this need. It helps
            individuals to <b>build muscles and obtain a good looking physique</b> that can be maintained over a long
            duration of time.
            <br/>
            <br/>
            The seeds of Bhringraj are known to possess <b>Vajikarana (aphrodisiac)</b> properties which help in the <b>improvement
            of sexual health</b> and can efficiently <b>treat sexual disorders</b>.
            <br/>
            <br/>
            In addition, Bhringraj has a <b>Rasayana (rejuvenator) property</b> which helps to <b>improve an
            individual’s overall internal and external health</b>.
        </p>

        <ImageBreaker image={Images.ingredient.bhringrajBenefits} to={shopUrl} buttonText={'SHOP'}/>

        <br/>
        <br/>

        <p className="text-center font-weight-bold">
            <span>To know more about the benefits of Bhringraj, </span>
            <span className="ingredient-blog-links"
                  onClick={() => openLinkOnNewTab('/blog/benefits-of-Bhringraj')}>click here</span>
        </p>
    </AccordionCard>
]

export const accordionList3 = [
    <AccordionCard key={'0'} title="References">
        <ul>
            <li>
                <a href="https://www.researchgate.net/publication/304172770_Eclipta_alba_L_AN_OVERVIEW"
                   target="_blank">
                    https://www.researchgate.net/publication/304172770_Eclipta_alba_L_AN_OVERVIEW
                </a>
            </li>
            <li>
                <a href="http://jprsolutions.info/files/final-file-56b22a3be51076.56809167.pdf"
                   target="_blank">
                    http://jprsolutions.info/files/final-file-56b22a3be51076.56809167.pdf
                </a>
            </li>
            <li>
                Padma Shri Prof.Chunekar K.C.(com),Dr. G.S. Pandey(ed). Bhavprakash Nighantu.2008: 414-415
            </li>
            <li>
                Prof. Sharma P.V. Dravyaguna Vigyana, (vol 2). 2019:123-126
            </li>
        </ul>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]
