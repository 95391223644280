// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../../Category/common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText/LText'
import { openLinkOnNewTab } from '../../../utils'

const shopUrl = '/en/solutions/'

export const accordionList1 = [
    <AccordionCard key={'0'} title="Where is Jaiphal found?">
        <p>
            Jaiphal is mainly found in the <b>Indian subcontinent</b> and also in <b>Indonesia</b> and <b>Sri Lanka</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are some alternative names for Jaiphal?">
        <p>
            Jaiphal is famously known as nutmeg all over India and other countries. Although, it is also recognised by
            different names in different regions. Its botanical name is <b>Myristica fragrans</b>. It is known as
            Jaiphal and <b>Malti phal in Hindi; Jauzbuwa and Jaiphal in Urdu; Jows Buwwa, Jouza Atteeb (due to its
            smell), Josat attib, Zanza – ba Wawa in Arabic; Nutmeg in English; Djus Hendi, Jouz Hendi and Jozbaweh in
            Persian; Fuqlaj and Moschokarido in Unani; Jaatiphala, Jaatishasya and Maalatiphala in Ayurveda</b>.
            <br/>
            <br/>
            Moreover, it is called Jaiphal, <b>Kanivish and Jaiphala in Assamese; Jayaphala and Jaepatri in Bengali;
            Zadi – phu in Burma; Nootmuskaat and Muskaatnoot in Dutch; Muscadier and Musque in French; Achter
            Muscatnussbaum in German; Jaiphala and Javantri in Gujarati; Egos Muskat and Egoz Muscat in Hebrew; Pala in
            Indonesian; Jadikai, Jaikar and Jajakai in Kannada; Jafal nad Zafal in Kashmiri; Neotumek, Notumek and
            Yuktugu in Korean; Muscata in Latin; Jatika and Bush in Malayalam; Jaiphal in Marathi; Jaiphal in Nepal;
            Jaipholo in Oriya; Jaiphal in Punjabi; Opex Muskatnyj Orekh in Russian; Jati – Phalam and Malathi – Phalam
            in Sanskrit</b>.
            <br/>
            <br/>
            It is referred to as <b>Jafar and Jadika in Sindhi; Moscada and Neuz Moscada in Spanish; Sathkhai, Jathikai,
            Jadikkay, Jadhikai, Jadhikkai and Jathikai in Tamil; Jajikaya in Telugu; Meshgengouz, Mshkenkoyz and
            Mekenkouz in Armenian; Moschokarido in Greek; Noce Moscata in Italy, Nozmoscada in Portugese; Nucsoara in
            Romanian; Hindistan Cevizi in Turkish; Muscat in Uzbek and M. fragrans Houtt in Latin</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="What is the description of the Jaiphal plant?">
        <p>
            The Jaiphal tree is evergreen, <b>bushy</b> and that which can grow until about <b>10 – 20 meters in
            height</b>. Its description is somewhat similar to that of an apple tree, which is beautiful and green.
            Its <b>leaves</b> are about <b>5 – 12 cm long</b>, about <b>3 cm broad</b> and either oval or rectangular in
            shape. The leaves are light in weight and have either a yellow or brown colour. Its <b>flowers</b> are
            small, somewhat <b>round in shape and are white in color</b>. The <b>fruit</b> of the Jaiphal plant are
            round or oval in shape and are about <b>3 – 5 cm long</b>. They are <b>reddish or yellowish in color</b>.
            Inside these fruits lie solid, protected, <b>seeds</b> which are a little <b>sticky to the touch</b> and
            are <b>brown in color</b>. The seeds are covered with a layer known as javitri which gets separated once the
            seed dries up.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the Ayurvedic properties of Jaiphal?">
        <p>
            The specific ayurvedic properties of Jaiphal make it crucial in providing individuals with multiple health
            benefits. Jaiphal is <b>Laghu (light)</b> and <b>Tikshna (sharp)</b> in <b>nature</b>. These qualities make
            sure that it is easily digested. It is <b>Tikta (bitter)</b> in <b>taste</b> and <b>Katu (pungent)</b> in
            its <b>after taste</b>. It is <b>Ushna (hot)</b> in <b>potency</b>. It is especially known to possess <b>Vata
            and Kapha Dosha balancing properties</b> in the body. Hence, Jaiphal helps to manage and treat most health
            related problems that occur because of an imbalance of Vata and Kapha Doshas.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]

export const accordionList3 = [
    <AccordionCard key={'0'} title="What are the benefits of Jaiphal?">
        <p>
            Jaiphal, widely recognised as nutmeg is considered to be one of the essential herbs used in Ayurvedic
            medicine. It is a rather effective spice found in the Indian kitchen as well. <b>The best nutmeg or Jaiphal
            is the one which is fragrant and has a strong aromatic odor. Another way to recognise good quality nutmeg is
            through its compactness, shallow coverings and easily breakable characteristics</b>.
            <br/>
            <br/>
            Sexual problems like premature ejaculation or erectile dysfunction are some conditions which pose great
            challenges for men, in recent times. The major cause behind these conditions is often a poor diet and heavy
            stress. Jaiphal is an efficient <b>Vajikara (aphrodisiac)</b> and is a naturally collected herb. It helps to
            reduce the symptoms of these aforementioned conditions and provide <b>steady sexual health</b> to an
            individual.
            <br/>
            <br/>
            Jaiphal is exceedingly <b>beneficial for female problems like irregular menstruation or painful menstruation
            as well</b>.
        </p>
        <p className="text-center font-weight-bold">
            <span>To know more about why Jaiphal is called ‘women’s viagra,’ </span>
            <span className="ingredient-blog-links"
                  onClick={() => openLinkOnNewTab('/blog/nutmeg-for-women')}>click here</span>
        </p>
        <p>
            Metabolic disorders like indigestion or loss of appetite are becoming increasingly common in households
            today. These conditions may consequently lead to issues such as gas, acidity, pain in the abdomen and
            flatulence. The functioning of the liver could also be affected. The consumption of Jaiphal has displayed
            positive results when it comes to such problems. Its <b>Deepan and Pachan (appetizer and digestion)
            properties</b> help to <b>improve the digestive system</b> of individuals.
            <br/>
            <br/>
            Jaiphal’s <b>Rochak (taste improving) property</b> helps to <b>enhance one’s appetite</b>. Once the appetite
            of any individual has improved, their digestion automatically begins functioning properly. This improved
            digestion then helps to maintain a <b>healthy metabolism rate</b>.
            <br/>
            <br/>
            <b>Diarrhea, vomiting or nausea</b> are few other health issues which are <b>tackled</b> by the consumption
            of Jaiphal. It is also responsible for <b>improved eating habits</b> of individuals and for correcting poor
            digestion patterns. Excessively watery and loose stool passage with gas formation and pain is also managed
            by this herb.
            <br/>
            <br/>
            <b>The Grahi (absorbent) property</b> in Jaiphal helps To to <b>absorb excessively watery stools</b> and
            thus normalises the consistency of an individual’s motion.
            <br/>
            <br/>
            Worm infection which is generally observed in children but is also a worrying factor in adult life, can
            occur due to poor digestion. Jaiphal’s <b>Krimighna (anti – worm) property prevents the occurrence of
            worms</b> and effectively removes them from the body, thereby minimising the pain of this condition.
            <br/>
            <br/>
            Consuming Jaiphal has had positive results in the case of conditions like the <b>inflammation or pain that
            occurs in the joints</b>. These conditions often occur due to an imbalanced Vata Dosha and Jaiphal takes
            care of them with its Vata balancing property. In addition, Jaiphal’s <b>Shothhar (anti – inflammatory)
            property</b> helps to keep inflammation in affected areas in check, thus providing relief.
        </p>

        <ImageBreaker image={Images.ingredient.jaiphalBenefits} to={shopUrl} buttonText={'SHOP'}/>

        <br/>
        <br/>

        <p className="text-center font-weight-bold">
            <span>To know more about the benefits of Jaiphal, </span>
            <span className="ingredient-blog-links"
                  onClick={() => openLinkOnNewTab('/blog/What-are-the-benefits-of-Jaiphal')}>click here</span>
        </p>
    </AccordionCard>,
    <AccordionCard key={'1'} title="References">
        <ul>
            <li>
                Padmshri Prof.Chunekar K.C.(com),Dr. G.S. Pandey(ed). Bhavprakash Nighantu.2008: 206-209
            </li>
            <li>
                Prof. Sharma P.V. Dravyaguna Vigyana, (vol 2). 2019:458-461
            </li>
            <li>
                <a href="https://www.researchgate.net/publication/336825717_Nutmeg_A_review_on_uses_and_biological_properties"
                   target="_blank">
                    https://www.researchgate.net/publication/336825717_Nutmeg_A_review_on_uses_and_biological_properties
                </a>
            </li>
            <li>
                <a href="https://pdfs.semanticscholar.org/0b23/fb31585a2cdcd75e29618bb974e1e9079ea7.pdf"
                   target="_blank">
                    https://pdfs.semanticscholar.org/0b23/fb31585a2cdcd75e29618bb974e1e9079ea7.pdf
                </a>
            </li>
            <li>
                <a href="https://www.researchgate.net/publication/260386780_Biological_Effects_of_Myristica_fragrans"
                   target="_blank">
                    https://www.researchgate.net/publication/260386780_Biological_Effects_of_Myristica_fragrans
                </a>
            </li>
        </ul>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]
