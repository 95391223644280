import React from 'react'
import PropTypes from 'prop-types'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Modal extends React.Component {
    static propTypes = {
      show: PropTypes.bool,
      showModal: PropTypes.bool,
      handleCloseClick: PropTypes.func,
      style: PropTypes.object,
      closeButtonStyle: PropTypes.object,
      closeButtonClassName: PropTypes.string,
      className: PropTypes.string
    };

    static defaultProps = {
      style: {},
      showModal: false,
      closeButtonStyle: {},
      className: '',
      closeButtonClassName: ''
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { show, showModal } = this.props
      if ((show && !prevProps.show) || (showModal && !prevProps.showModal)) {
        document.querySelector('body').classList.add('modal-open')
      }
      if ((!show && prevProps.show) || (!showModal && prevProps.showModal)) {
        document.querySelector('body').classList.remove('modal-open')
      }
    }

    render () {
      const { show, handleCloseClick, style, closeButtonStyle, className, closeButtonClassName, ...props } = this.props

      if (this.props.show) {
        return (
                <div className={`modal-container ${className}`} style={style}>
                    <button onClick={this.props.handleCloseClick} style={closeButtonStyle}
                            className={`modal-close-icon ${closeButtonClassName}`}>
                        <FontAwesomeIcon icon={faTimes} size="lg"/>
                    </button>
                    {this.props.children}
                </div>
        )
      }
      return null
    }
}
