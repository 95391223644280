// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../../Category/common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText/LText'
import { openLinkOnNewTab } from '../../../utils'

const shopUrl = '/en/solutions/'

export const accordionList1 = [
    <AccordionCard key={'0'} title="Where is Kaunch Beej found?">
        <p>
            The plants are native of <b>Africa and tropical Asia</b> where they are widely naturalized and grown since
            ages. Kapikachhu herb is an energetic, leguminous species, <b>originally from southern China and eastern
            India</b>. The plant was initially cultivated only in India and China as a green vegetable crop and has been
            habitually eaten as a food by some particular ethnic groups in these countries. Today, Kaunchbeej is an
            extremely sought after green crop throughout the tropics.
            <br/>
            <br/>
            This well-known medicine is widely grown in tropical and subtropical regions across the world and is a
            practical source of dietary proteins. It is now grown extensively in countries like <b>Asia, Africa,
            America,</b> and also in the <b>Pacific Islands</b>.
            <br/>
            <br/>
            The pods of this plant are popularly eaten as a vegetable while the young leaves are fed to animals as
            fodder. Though Kaunchbeej happens to be the key constituent in an array of indigenous drug formulations
            because of its medicinal properties, what makes it even more interesting is it being a natural source of
            L-Dopa. It triggers the production of dopamine in the brain and in turn enhances mood, sexuality and brain
            and body coordination. Popularly known as ‘the magic velvet bean’, Kaunch beej or Cowhage is best consumed
            as a powder mixed with milk.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What is the history of Kaunch Beej?">
        <p>
            The <b>Ayurvedic school of medicine</b> is an age-old traditional medical science that is practised in India
            since the Vedic ages. It has often used the Kaunchbeej herb for treating a host of medical conditions
            including impotence, sexual debility, infertility, arthritis and neurological disorders. Kaunch had also
            been widely prescribed by ancient Indian medical practitioners as a libido enhancer in men as well as in
            women and as an aphrodisiac. This was done because of its dopamine inducing properties as dopamine is known
            for its positive influence on sexual activities.
            <br/>
            <br/>
            The beans of the plant are believed to have poison absorption potentials when applied as a paste, on a
            scorpion sting. The plant is believed to be native to tropical southern or southeastern Asia. However, since
            the exact native range is undecided, it is considered a native species in a wide range of countries
            including a major part of Africa.
            <br/>
            <br/>
            The species has been naturalized in tropical and subtropical areas across the world in countries like West
            Indies, Mexico and South America. According to botanists in 1920s the United Fruit Company introduced the
            species into Mesoamerica, as a forage crop for its mules living on banana plantations. Mesoamerica is
            actually Middle America or the geographical area extending from central Mexico to Central America. It
            includes countries like Guatemala, Belize, Honduras, and El Salvador.
            <br/>
            <br/>
            According to authors Nathaniel Lord Britton and Charles Frederick Millspaugh, by 1920, the plant was present
            in the Bahamas as well as in Cuba, St. Thomas, Tobago, and Jamaica. According to other documented sources,
            the plant was found in Puerto Rico, Virgin Islands, Lesser Antilles, the US, Mexico, and South America by
            1924, where experts say the plant was not native but was ‘exotic and naturalized’.
            <br/>
            <br/>
            In the 18th and 19th centuries, Kaunchbeej herb was largely grown as a green vegetable in the piedmont
            plateaus of the eastern Himalayas and also in Mauritius. People of both these areas used to boil the unripe
            pods as well the mature beans before eating them. For the last many decades, the locals of Guatemala and
            Mexico, roast and ground Kaunchbeej in order to make a coffee-like substitute. This has given Kaunch Beej
            its local name of Nescafe, the popular coffee brand.
            <br/>
            <br/>
            Historically, this drug has been used in different countries for curing different sets of disorders. For
            example, in Brazil, India and Pakistan, it is known to possess aphrodisiacal and and diuretic properties. It
            is known to keep rheumatism, muscle pain and cholesterol in check, in Germany. In India and Pakistan, it
            helps with abortion, dropsy, debility, delirium, cancer, cholera, cough and diarrhea.
            <br/>
            <br/>
            Other than this the drug was extensively used by different nations for curing conditions like the presence
            of intestinal parasites, nervine, paralysis, mumps, pleuritis, insanity, snakebites, sores, ringworms,
            STD’s, tumors and wind burns.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What is Kaunch Beej made of?">
        <p>
            The key constituent of Kaunch Beej is <b>L-dopa</b> which is present in 4-7 percentages, and this has made
            the herb a commercial substance. L-dopa is a precursor of the neurotransmitter, dopamine and hence has
            anti-diabetic, anti- inflammatory, neuroprotective and antioxidant properties. Other vital constituents
            include <b>hallucinogenic tryptamines, methylated and non-methylated tetrahydroisoquinolines</b>.
            <br/>
            <br/>
            The plant also contains substances which can kill plant-parasitic nematodes. Four alkaloids including - <b>mucunine,
            mucunadine, prurienine, and prurieninine</b> are also found in the plant extracts. The seeds are rich as
            they have about 27 percent protein content and are also known for their high mineral contents. The seeds are
            also believed to contain some compounds which can inhibit the spread of snake poison. As a great source of
            protein and dietary fibre, the seeds are used as supplements for body and muscle building. Sportsmen are
            known to take kaunch beej supplements for building their muscles as well as stamina.
            <br/>
            <br/>
            Medicinal values are present in each and every part of the Kaunch Beej plant and it can be used as an
            anti-diabetic, aphrodisiac, anti-neoplastic, anti-epileptic, neuroprotective, analgesic, anti-inflammatory
            and anti-microbial agent.
            <br/>
            <br/>
            Additionally, the alcoholic characteristics of the extracts of the seeds have potential antioxidant
            qualities. The leaf extracts are known to be antimicrobial, antioxidant and
            supposedly have potential as a topical drug curing redox-driven skin ailments.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'3'} title="What are the benefits of Kaunchbeej?">
        <p>
            Every part of Kaunch Beej is extremely useful as they have medicinal values. This is known as a wonder drug,
            as it can successfully treat a host of medical conditions.
            <br/>
            <br/>
            <strong>Useful nervine tonic:</strong> The potential of this herb as a valuable nervine tonic is now well
            established through numerous studies and experiments. According to scientists, the active alkaloids like
            mucaine, pruridine, tannic acid, resin, lecithin and L-dopa, present in Kaunch Beej help the central nervous
            system in human beings to work at its best possible capacity and also assist in the normal functioning of
            the nerve cells.
            <br/>
            <br/>
            <strong>Boosts human growth hormone:</strong> Kaunch Beej stimulates the production of the human growth
            hormone (HCG) and its extracts are widely known as bodybuilding agents, increasing tissue strength and thus
            augmenting coordination in an inactive individual. Kaunch Beej is consumed as a dietary supplement as it
            helps in increasing muscle strength and is known to help the body produce slender muscle.
            <br/>
            <br/>
            It also works in separating undesirable fat from the body and therefore is very useful for those looking for
            a weight loss regimen. Bodybuilders use this herbal extract extensively.
            <br/>
            <br/>
            <strong>An aphrodisiac:</strong> Kaunch Beej is a potential aphrodisiac widely popular across the world as
            the powder has a tremendous capacity to improve libido or sexual desire as well as strength and abilities.
            <br/>
            <br/>
            Another very effective contribution of the drug is improving the sexual experience by
            delaying the time of ejaculation and thereby making sex more pleasurable. In effect, the constituent
            levodopa, combines with dopamine and limits the secretion of the Prolactin hormone and thus helps in
            treating erectile dysfunction.
            <br/>
            <br/>
            <strong>Cure for sexual disorders:</strong> This herbal extract also plays a role in increasing sperm count
            and also the production and quality of semen, thus improving male fertility. The sperm count increases
            because of the herb’s prophylactic action against oligospermia. This, according to Ayurveda, is because of
            the fact that Kaunchbeej is a ‘Guru’ or heavy substance and has ‘Vrushya’ or aphrodisiacal traits.
            <br/>
            <br/>
            Some studies also state that Kaunchbeej improves the movement of sperms or their motility. Additionally,
            this natural substance is instrumental in reducing mental stress thus helping an individual to relax which
            helps in experiencing better sex. The testosterone hormone is released by Kaunchbeej which also strengthens
            and builds sexual glands and enhances blood flow to the sex organs. In women, Kaunch Beej is known to
            normalise ovulation.
            <br/>
            <br/>
            <strong>Increase testosterone level:</strong> Kaunch Beej can also play a significant role in increasing
            testosterone levels. This is because of its constituent L-dopa which triggers the production of a
            gonadotropic hormone (GnRH). This hormone, in turn, stimulates the pituitary gland and boosts the secretion
            of the FSH (follicle-stimulating hormone) and LH (luteinizing hormone). As a result of this increased level
            of FSH and LH, the synthesis of testosterone is promoted by the Leydig cells present in the testis.
            <br/>
            <br/>
            <strong>Restorative uses of Kaunch Beej:</strong> This hormone also helps in revitalising the increase of
            anabolic/antispasmodic hormones, reduces glucose or blood sugar and high-pressure levels, decreases
            cholesterol levels in the blood, helps improve digestion, stimulates the menstrual cycle, treats swellings
            and inflammation, is a CNS stimulant and addresses diabetes. The d-chiro-inositol present in Kaunch Beej
            plays an important role in the treatment of diabetes. Kaunch Beej (seeds) do this because the
            D-chiro-inositol imitates the properties of insulin, thus assisting glucose metabolism.
            <br/>
            <br/>
            The herb also has antioxidant and anti-inflammatory qualities and this makes it capable of decreasing the
            risks of diabetes-related issues. Besides, Kaunchbeej is also effective in treating diabetes in another way.
            High blood sugar reduces physical strength in those affected with the condition and because of the ‘Balya’
            or strength-enhancing quality of this herb, it has the potential to increase body strength.
            <br/>
            <br/>
            <strong>Work against snake venom poisoning:</strong> Kaunj Beej is also known for its prophylaxis or
            preventive action against poisoning from snake venom, which contains a large number of toxins. Kaunch Beej
            acts by enhancing immune response and promotes antibody production, which binds itself to the proteins in
            the snake venom, thus, inhibiting them.
            <br/>
            <br/>
            <strong>Grows beard:</strong> Interestingly, Kaunch Beej powder is believed to help the growth of the beard
            in men. It does so by converting testosterone to dihydrotestosterone (DHT) by means of an enzyme called
            5-alpha reductase. DHT being the chief hormone behind facial hair, Kaunchbeej hence, helps in facilitating
            the growth of the beard. Additionally, since Kaunchbeej boosts testosterone levels, it triggers the
            conversion of DHT. Finally, the herb activates androgen receptors and makes sure that DHT is used more
            productively.
            <br/>
            <br/>
            <strong>Reduces stress:</strong> Stress triggers the production of an adrenocorticotropic hormone in the
            body which increases the levels of cortisol in the body. The antioxidant characteristics of Kaunch Beej are
            helpful in reducing levels of cortisol that are also the hormones responsible for increasing stress.
            Additionally, Kaunchbeej also energises the body and increases stamina due to its constituent, L-dopa, which
            by turning into dopamine helps raise energy levels.
            <br/>
            <br/>
            <strong>In wound healing:</strong> The powerful antioxidant, anti-inflammatory and antimicrobial traits of
            Kaunch Beej make it a potential therapy in healing wounds. The phytoconstituents present in the herb assist
            in contraction and closure of wounds and cuts and facilitate the formation of collagen and new skin cells.
            Moreover, it checks the risks of infection.
            <br/>
            <br/>
            <strong>Kaunch Beej for Arthritis:</strong> The inflammatory and analgesic properties of this herb help in
            controlling arthritis by decreasing pain and swelling in the joints. Ayurveda says that the bones and joints
            are a location of Vata in the human body and pain results when there is a Vata imbalance. Kaunch Beej has
            the potential to stabilise Vata, and thus address bone and joint pain.
            <br/>
            <br/>
            <strong>Kaunch Beej for High prolactin levels:</strong> The constituent L-dopa in Kaunch Beej checks the
            overproduction of the hormone prolactin, which is necessary to sustain lactation in breastfeeding mothers.
            Excess of the prolactin hormone is believed to increase the risks of breast cancer. By controlling the
            overproduction of prolactin, Kaunch Beej prevents the growth of cancer cells. Besides, it also facilitates
            DNA damage and cell death in the cancer cells.
            <br/>
            <br/>
            <strong>Home remedies:</strong> The roots are useful in treating neuromuscular disorders and are especially
            effective in addressing facial paralysis and Hemiplegic.
        </p>
        <ImageBreaker image={Images.ingredient.kaunchBeejBenefits} to={shopUrl} buttonText={'SHOP'}/>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="What are the doses of Kaunch Beej?">
        <p>
            Kaunch Beej is a great source of protein and its qualities as an aphrodisiac, anti-inflammatory, and
            antimicrobial herb, makes it an indispensable component in household healthcare. However, if not used the
            right way and in the right doses, Kaunch Beej can cause serious side effects. One must start using this
            herbal extract by consulting a doctor. The doses may vary according to age, health condition, gender,
            individual body type, food habits and lifestyle choices.
            <br/>
            <br/>
            Those afflicted with nerve disorders or diabetes must take it after consulting a doctor or a licensed
            Ayurvedic expert.
        </p>
        <h3>Some recommended doses of Kaunch Beej:</h3>
        <p>
            The general recommended dose for consuming Kaunch Beej is to <b>use one fourth to half a teaspoon of Kaunch
            Beej powder</b> along with one teaspoon honey or one cup lukewarm milk and consume it once or twice every
            day. When consumed with milk, <b>one fourth to half a teaspoon</b> of Kaunch Beej powder is mixed with one
            cup of milk and boiled for 3-5 minutes.
            <br/>
            <br/>
            Those having diabetes should use lukewarm water instead of honey. The prescribed timing of the drug is post
            lunch and dinner, particularly in case of treating bone and joint pain.
            <br/>
            <br/>
            For the management of neuromuscular disorders use a decoction of <b>50 ml of Kaunch Beej mixed with 250 ml
            mustard oil</b> and use it for massage purposes. For leucorrhoea, use the powdered Kaunchbeej and mix it
            with honey. One can also use Kaunch Beej powder mixed with honey and ginger juice for the treatment of
            allergic bronchitis.
            <br/>
            <br/>
            For bodybuilding, one can use <b>one fourth to half a teaspoon</b> of Kaunch Beej powder along with milk and
            take once or twice every day. For one’s affected with Parkinson’s disease, the recommended dose is to take
            one fourth to half a teaspoon of Kaunch Beej powder along with one teaspoon of honey or one cup of lukewarm
            milk and consume it post lunch and dinner.
            <br/>
            <br/>
            For treating wounds, one can use <b>Kaunch Beej powder mixed with coconut oil</b> or milk and apply it as a
            paste. To use Kaunch Beej powder effectively, one can take half to one teaspoon of the powder and mix it
            with milk and apply it evenly on the affected area as a paste. Let it stay for five to seven minutes and
            then wash it well with water. This helps in the fast healing of wounds, reduces swellings and restores the
            skin back to its normal state.
        </p>
        <h3>Doses for different forms of Kaunch Beej:</h3>
        <p>
            <b>One fourth to a half teaspoon</b> of Kaunchbeej Churna twice a day, or as advised by the doctor.
            <br/>
            <br/>
            One Kaunchbeej <b>capsule twice a day</b> or as suggested by a doctor or an Ayurvedic practitioner.
            <br/>
            <br/>
            One Kaunchbeej <b>tablet twice a day</b> or as recommended by the doctor, or other experts.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the side effects of Kaunch Beej?">
        <p>
            Kaunchbeej has ‘Ushna’ (hot) potency and can <b>enhance acid production</b>. Hence, for those who have
            peptic ulcers, hyperacidity or gastritis, it is better to take the advice of a doctor before commencing
            Kaunch Beej therapy.
            <br/>
            <br/>
            The intake of hair that grows on the Kaunchbeej pod and seed can cause <b>mucosal irritation</b> and must be
            avoided.
            <br/>
            <br/>
            Also, because of the Ushna (hot) potency, it is good to consult a doctor before applying Kaunch Beej
            extracts directly on the skin as it can cause <b>itching or a burning sensation</b>.
            <br/>
            <br/>
            <b>Pregnant women</b> and breastfeeding mothers must undergo medical consultation before consuming or using
            Kaunch Beej extracts or supplements. Kaunch beej is believed to <b>interact with CNS drugs</b>. Hence, it is
            always recommended to consult a doctor before taking Kaunch Beej along with a CNS drug.
            <br/>
            <br/>
            Patients with diabetes must avoid Kaunchbeej, which has the potential to <b>reduce blood sugar levels</b>.
            It is also advised to check blood sugar levels regularly while using Kaunch Beej. Kaunch beej can also
            reduce blood pressure levels; therefore, it is advised to watch blood pressure levels while consuming Kaunch
            Beej.This is particularly true for people with heart disorders.
        </p>
        <h3>Some common side effects of Kaunch Beej:</h3>
        <p>
            Some other common side effects are headaches, hallucinations, perplexity and irritation.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'2'} title="How long does it take for the Kaunch Beej to work?">
        <p>
            The time taken for Kaunchbeej to start showing results is relative and like all other herbal supplements,
            depends on age, body type, body mass and weight, dietary preferences, and lifestyle choices of the user. It
            also depends on the type of ailments it is used for. The time taken can also vary according to the ability
            of the user to consume it. Many cannot consume higher doses and hence they will require more time to see
            results.
            <br/>
            <br/>
            That said a minimum time period of <b>one month to 12 weeks</b> is necessary to see tangible results of the
            drug. For certain conditions, like that of <b>bodybuilding</b>, the effect will be perceived after <b>several
            months</b>’ use.
            <br/>
            <br/>
            As an <b>inhibitor of poison</b>, though, it works within <b>24 hours</b>.
            <br/>
            <br/>
            For improving sperm count, the dose must be taken for at least three months. Also, Kaunch Beej supplements
            work best when chosen from a reputed seller and the same dose is taken over the period of therapy.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'3'} title="References">
        <ul>
            <li>
                <a href="https://www.planetayurveda.com/library/kaunch-beej-mucuna-pruriens/" target="_blank">
                    https://www.planetayurveda.com/library/kaunch-beej-mucuna-pruriens/
                </a>
            </li>
            <li>
                <a href="http://www.techno-preneur.net/technology/project-profiles/food/kaunch.html" target="_blank">
                    http://www.techno-preneur.net/technology/project-profiles/food/kaunch.html
                </a>
            </li>
            <li>
                <a href="https://ayurmedinfo.com/2013/10/25/kaunch-pak-uses-dose-ingredients-side-effects/"
                   target="_blank">
                    https://ayurmedinfo.com/2013/10/25/kaunch-pak-uses-dose-ingredients-side-effects/
                </a>
            </li>
            <li>
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3942911/" target="_blank">
                    https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3942911/
                </a>
            </li>
            <li>
                <a href="https://www.theihcc.com/mucuna-pruriens-benefits/" target="_blank">
                    https://www.theihcc.com/mucuna-pruriens-benefits/
                </a>
            </li>
        </ul>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]
