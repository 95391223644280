// External Imports
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
// My Imports
import ImageService from '../../services/ImageService'
import { getHttpsImageUrl, getLanguageTranslation } from '../../utils'

const Section3 = ({ match, product }) => {
  if (!product.features || !product.features.length) {
    return null
  }
  return (
    <div className='solution-page-section3 pb-2 pt-0 py-md-5'>
      <div className="container">
        <h2 className="text-center home-page-subtitle mb-4">Benefits</h2>
        <div className="row">
          {
            product.features.map(
              (feature) => (
                <div className="col-12 col-sm-6 col-md-3 solution-page-key-claim" key={feature.id}>
                   {
                    feature.image
                      ? <img src={getHttpsImageUrl(feature.image)} alt={feature.title}/>
                      : <img src={ImageService.whyMistersIcons.icon4} alt="Thumbs Up"/>
                   }
                  <h6>{getLanguageTranslation(feature, 'title', match.params.language)}</h6>
                  <p>{getLanguageTranslation(feature, 'description', match.params.language)}</p>
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  )
}

Section3.propTypes = {
  match: PropTypes.object,
  product: PropTypes.object
}

const mapStateToProps = state => ({})

const matchDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Section3))
