import {
  FETCH_OFFERS,
  FETCH_OFFERS_RESULT,
  FETCH_OFFERS_ERROR,
  FETCH_OFFERS_RETRY
} from '../../actions/ecommerce/offers'

const initialState = {
  detail: {},
  list: [],
  isFetching: false,
  isFetchingList: false,
  isUpdating: false,
  isCreating: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OFFERS:
      return { ...state, isFetchingList: true, error: false, message: null, retry: false }
    case FETCH_OFFERS_RESULT:
      return { ...state, isFetchingList: false, list: action.data }
    case FETCH_OFFERS_ERROR:
      return { ...state, isFetchingList: false, error: true, message: action.message }
    case FETCH_OFFERS_RETRY:
      return { ...state, isFetchingList: false, retry: true, message: action.message }

    default:
      return { ...state }
  }
}

export default reducer
