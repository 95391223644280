import React from 'react'
import PropTypes from 'prop-types'
import { getPrice } from '../../../../utils'

export default class TherapyPrice extends React.PureComponent {
    static propTypes = {
      discount: PropTypes.number,
      salePrice: PropTypes.number,
      price: PropTypes.number
    };

    render () {
      const { discount, salePrice, price } = this.props

      return (
            <div className="therapy-price">
                {
                    !discount
                      ? <span className='currency-price'>{price}</span>
                      : <React.Fragment>
                            <span className='strike-price'>{price}</span>
                            <span className='currency-price'>{salePrice}</span>
                            <span className='discount'>{discount}% off</span>
                        </React.Fragment>
                }
            </div>
      )
    }
}
