// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../../Category/common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText/LText'
import { openLinkOnNewTab } from '../../../utils'

const shopUrl = '/en/solutions/'

export const accordionList1 = [
    <AccordionCard key={'0'} title="What are the origins of Shilajit?">
        <p>
            According to scientists, the plant named <b>Euphorbia royleana</b> and <b>Trifolium repens</b> are the
            potential botanical sources of Shilajit. Other sources include <b>Barbula, Fissidens, Minium, Thuidium and
            species like Asterella, Dumortiera, Marchantia, Pellia, Plagiochasma, and Stephenrencella-Anthoceros</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What is the description of Shilajit?">
        <p>
            When purged of impurities like sand and plant debris, Shilajit appears to be a uniformly <b>brown-black
            material substance</b>, with a <b>paste-like feeling</b> and a <b>lustrous surface</b>. It also has an odd
            odour and is <b>bitter to taste</b>. The density of dry Shilajit varies from <b>1.1 to 1.8 g/cm3</b>. An
            interesting thing about Shilajit is that it <b>solidifies below 20 °C</b> temperature and melts when the
            temperature rises. This gives it its <b>plastic-like</b> attribute. It quickly dissolves in water leaving no
            deposits and turns soft when rubbed between one’s fingers. In order to make Shilajit suitable for human
            consumption, it has to be extracted, purified and dried.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="Where is Shilajit found?">
        <p>
            Shilajit consists of nearly 60-80% humus and is found naturally in the high mountainous regions
            of <b>India</b> and <b>Nepal</b>, particularly in the <b>Himalayas</b>. Additionally, it is also in the <b>Altai
            and Caucasus mountains</b>. Once found solely India and its neighbouring countries of Nepal and <b>Tibet</b>,
            Shilajit is now available in many other parts of the world including <b>Russia, Tibet, Afghanistan</b>, and
            now in the <b>north of Chile</b>, where it is popularly known as Andean Shilajit. A few years back, a
            similar substance was extracted in <b>Antarctica</b> which was later found to have glycerol derivatives and
            medicinal qualities.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the constituents of Shilajit?">
        <p>
            Essentially a nutraceutical substance, the constituents of Shilajit are packed with a slew of valuable
            health benefits, including <b>antidiabetic, anxiolytic, antifungal, antiulcerogenic, anti-inflammatory and
            antioxidant</b> properties. It is also known to be a <b>cognitive enhancer, antiallergic, immunomodulator,
            anti-inflammatory, analgesic</b>, and also possesses the ability to interact positively with other drugs. It
            is considered therapeutic in high altitudes, and as a <b>neuroprotective</b> element for cognitive
            disorders.
            <br/>
            <br/>
            The presence of these highly useful properties makes Shilatij a potential cure for an array of health
            conditions. No wonder that this miraculous material has now crossed its geographical boundaries and is
            gaining fast popularity, globally.
            <br/>
            <br/>
            Fulvic acid, the key component of this naturally occurring material is a Phyto complex, a term recently
            coined by medical scientists. It is a mixture of active and inactive molecules naturally occurring in
            plants. The Phyto-complex is now increasingly used in place of a single molecule because of the medically
            established synergism, principally in terms of bioavailability, among numerous plant components.
            <br/>
            <br/>
            As a strong and extremely safe dietary supplement, Shilajit is particularly beneficial in restoring the
            energetic balance, increasing strength and enhancing general well being. Interestingly, recent studies
            suggest that it is also capable of curing and controlling cognitive disorders connected with ageing and
            cognitive stimulation. In today’s times, scientists increasingly agree that medical science can be
            benefitted from Shilajit in numerous ways. They recommend more and more research on the substance at a basic
            biological level in order to use its hitherto unknown potentials, especially in the field of neurological
            sciences. Advanced experimental trials are also highly advocated by the medical fraternity for grasping the
            workings of the active principles of Shilajit at molecular and cellular levels.
        </p>
        <p className="text-center font-weight-bold">
            <span>To know more about how Shilajit works, </span>
            <span className="ingredient-blog-links"
                  onClick={() => openLinkOnNewTab('/blog/how-does-shilajit-work')}>click here</span>
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What is the history of Shilajit?">
        <p>
            With a recorded <b>history of more than 3000 years</b>, Shilajit has stood the test of time well. Like
            everything ancient, Shilajit too has a mythological origin in the widely popular story of <b>Samudra
            Manthana</b>. It is believed to have formed from the abrasion of heavenly bodies.
            <br/>
            <br/>
            While gods and demons tried to churn the prehistoric ocean so that they can obtain the divine elixir called
            Amrita, their sweat mingled with the heavenly nectar and Shilajit was formed. In the middle ages, this
            substance was known as a nutritive juice which when consumed with certain metals, herbs, or honey, bestowed
            magical powers to man.
            <br/>
            <br/>
            Another legendary and magical ointment or collyrium called Siddhangana is said to have ingredients like
            camphor, shilajit, various psychoactive aromatic plants, together with minerals. Rumour has it that this
            ointment allowed the Ayurveda experts to visualise ‘all the seven worlds of Hell’. Hundreds of legends and
            folk tales exist around the origin, effects and properties of Shilajit. However, many of such stories are
            unfounded and exaggerated.
            <br/>
            <br/>
            Often confusion arises between Shilajit and Ozokerite, which also originates from humus and is very much
            similar to look. However, the similarity ends there as Ozokerite is devoid of any medicinal properties. Its
            methods of preparation and dosages have also been elaborately described in the ancient Hindu scriptures
            like <b>Charaka Samhita and Sushrut Samhita</b> - texts that eventually formed the basis of the Ayurvedic
            episteme in India.
            <br/>
            <br/>
            A substance identical to Shilajit in its nature, Mumiyo, is often regarded as a synonym of Shilajit, even
            though there are variations in their chemical compositions and the latter is generally more powerful, old
            and rare. Even today, the best kind of Shilajit is procured from the Himalayas. Another interesting fact is
            that, just like Shilajit has been venerated in Indian scriptures, Mumiyo has references that appear in
            Russian, Turkish, Sumerian, Egyptian, Persian and Arabic texts.
            <br/>
            <br/>
            Historical documents suggest that ancient and legendary doctors, scientists and philosophers like Avicenna,
            Paracleus and Buruni, were aware of the benefits of Shilajit and since they enjoyed great clout in society,
            the knowledge of this miraculous material spread far and wide through the teachings of these great men.
            <br/>
            <br/>
            With the onset of trade between India and Europe, Shilajit soon found great demand in the West. With its
            rising demand, Shilajit became an expensive item only accessible to the elite of society.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'3'} title="What is Shilajit made of?">
        <p>
            The molecular composition of Shilajit varies from one region to another. However, Shilajit is primarily a
            humic substance whose key component is <b>fulvic acid</b>. In fact, almost 60 to 80 percent of Shilajit is
            made up of this acid. Another major component is <b>selenium</b> which is known for its anti-ageing quality,
            while the <b>dibenzo--pyrones</b> present in Shilajit play the role of the carrier of other constituents.
            <br/>
            <br/>
            It is this high content of fulvic acid which makes Shilajit such a miraculously potent cure for diverse
            human ailments. Being soluble in water and having a low molecular weight, ensures that fulvic acid is easily
            absorbed by the intestine and purged from the body within hours of consumption. Fulvic acid also has
            powerful antioxidant traits and hence endows a systemic effect in the form of a complement activator. It is
            also anti-inflammatory and serves as a memory enhancer. The fulvic acid in Shilajit, with its potential as
            an antioxidant, promotes cognitive health, and in turn reduces inflammation.
        </p>
        <h3>Some other components of Shilajit:</h3>
        <p>
            Some other components of Shilajit include <b>ellagic acid, fatty acids, amino acids, aromatic, carboxylic
            acids, resins, latex, gum, albumins, triterpenes, sterols, benzocoumarin, polyphenols and phenolic
            lipids</b>. Recent studies have also revealed that shilajit comes with certain molecular varieties of
            polysaccharides and lignins.
            <br/>
            <br/>
            The Oxygen Radical Absorbance Capacity test of the variety of Shilajit found in Chile quantified its ‘total
            antioxidant capacity’ (TAC) between 50 and 500 Trolox units/g of material. This is considered much higher
            than other known antioxidants like Noni and blueberries, which makes Shilajit a potential antioxidant <b>Phyto
            complex</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'4'} title="What are the benefits of Shilajit?">
        <p>
            <strong>Controlling the ageing process:</strong> The main component of Shilajit that is fulvic acid is a
            very potent antioxidant and anti-inflammatory element. This acid acts as a shield against free radicals and
            cellular damage which helps in controlling the ageing process.
            <br/>
            <br/>
            <strong>Increasing testosterone levels:</strong> A daily dose of 250 milligrams (mg) Shilajit twice a day is
            very effective in increasing testosterone levels. Reports say that most men experience low testosterone
            levels at some point in time. Testosterone is the key sex hormone in men. The reduction in the hormone’s
            level can affect their health in numerous ways by causing a lack of desire or sex drive, loss of hair and
            muscle mass, low energy and even building up of body fat.
            <br/>
            <br/>
            <strong>Addressing infertility:</strong> Shilajit helps in increasing the ability of sperms to be more
            agile, thus boosting fertility. Male infertility is the cause of dire distress among families, and it
            disrupts the peace. Today, it is believed that a regular intake of Shilajit, can increase the sperm count.
            This herbal supplement also plays a critical role in improving sperm motility, increasing the sperms’
            capacity to move more actively. This is also considered to be an essential prerequisite for fertility.
            <br/>
            <br/>
            <strong>Chronic fatigue syndrome:</strong> Shilajit supplements have the potential to cure chronic fatigue
            syndrome (CFS), a condition in which the mitochondria stops functioning properly. Shilajit is helpful in
            curing the dysfunction and in re-energising the body. CFS occurs if the body cells stop producing energy and
            once affected,individuals face extreme exhaustion or fatigue. This health condition also renders one
            incapable of carrying out their daily activities.
            <br/>
            <br/>
            <strong>High altitude sickness:</strong> Some health issues like hypoxia, pulmonary oedema, exhaustion, body
            ache, dementia and even sleeplessness are associated with higher altitudes where the atmospheric pressure is
            low. Armed with 80 kinds of minerals and fulvic acid, Shilajit is capable of addressing this condition
            effectively.
            <br/>
            <br/>
            Fulvic acid, the main ingredient of Shilajit, has the properties of an antioxidant and is capable of
            promoting immunity and memory. Besides, this acid also plays a very important role as an anti-inflammatory
            energy booster.
            <br/>
            <br/>
            The herbal supplements of Shilajit can also be used as a diuretic for purgation of excess fluid from the
            body, thus addressing the health symptoms related to high altitudes.
            <br/>
            <br/>
            <strong>Iron deficiency:</strong> Iron deficiency or anaemia is a common ailment in women but strikes men
            who have low-iron diet plans, have less iron-absorbing abilities or those who experience blood loss for some
            reason. Iron deficiency leads to symptoms like the complete lack of energy, an uneven heartbeat, headaches
            and cold hands and feet. Today, many health experts believe that the regular use of Shilajit supplements is
            capable of increasing iron levels.
            <br/>
            <br/>
            <strong>Heart health:</strong> As a dietary supplement, Shilajit also promotes cardiac health. However, it
            is not recommended for people with acute heart diseases.
            <br/>
            <br/>
            <strong>Alzheimer’s disease:</strong> Alzheimer’s disease causes damage to memory and thinking ability. This
            type of progressive brain disorder occurs where there is an accumulation of Tau protein which is an
            essential component of the body’s nervous system. High building up of Tau protein damages the brain cells
            leading to Alzheimer’s disease. Ayurvedic practitioners believe that Shilajit with its primary component,
            fulvic acid which is also a strong antioxidant, helps to manage the accumulation of this protein, to some
            extent, thus keeping in check the condition of Alzheimer’s.
        </p>
        <ImageBreaker image={Images.ingredient.shilajitBenefits} to={shopUrl} buttonText={'SHOP'}/>
        <br/>
        <br/>
        <p className="text-center font-weight-bold">
            <span>To know more about the benefits of Shilajit, </span>
            <span className="ingredient-blog-links"
                  onClick={() => openLinkOnNewTab('/blog/benefits-of-shilajit')}>click here</span>
        </p>
    </AccordionCard>
]

export const accordionList3 = [
    <AccordionCard key={'0'} title="How must one consume Shilajit?">
        <p>
            To start with, one has to take the advice of a doctor practising Ayurveda to know about the <b>quantity,
            regularity and strength of the dosage</b>. Next, comes the authenticity of the product. It is essential to
            know if the product is genuine and not an imitation variety. It is also important to procure the highest
            quality Shilajit to get the best results.
            <br/>
            <br/>
            Also, powder varieties of Shilajit are most often fake. Some of the things to keep in mind before consuming
            Shilajit are bodyweight, height as well as body mass index. One should also keep in mind the metabolism or
            the body’s ability to convert food into energy, lifestyle choices and a regular diet.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What is the dosage of Shilajit?">
        <p>
            To be safe, one can start with a dose of <b>100mg</b> or the size of a rice grain and take it once a day for
            a week. After that, the dose can be increased to <b>2-3 times a day</b> while the quantity of each dose
            remains the same as before. Eventually, the size of the Shilajit dose can be increased to that of a large
            pea which will be close to <b>300 mg</b> and can be continued till one achieves the desired results.
            <br/>
            <br/>
            Ayurveda experts always recommend taking Shilajit in combination with ingredients like goat’s milk, ghee,
            raw and unpasteurized honey or sesame oil- to augment the impact of the adaptogen on the body. Ingredients
            like coconut oil, herbal teas, or spring water can also be used by those who have issues with dairy
            products.
            <br/>
            <br/>
            However, using chlorinated water should be strictly avoided. Consuming Shilajit on an empty stomach in the
            morning yields the best results. For those experiencing an immediate rise in energy levels after taking
            Shilajit, should avoid taking it in the evening as it might cause sleeplessness at night.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What are the side effects of Shilajit?">
        <p>
            As a natural and herbal substance, Shilajit is known to be mostly harmless. However, when consumed in its
            untreated or unprocessed state the impurities present, including free radicals, metal ions and fungus that
            might cause some side effects as well as health hazards.
            <br/>
            <br/>
            <b>Intoxication</b> is possible from impurities present in Shilajit, like mycotoxin, heavy metal ions,
            polymeric quinones (oxidant agents), and free radicals. Some commercialized Shilajit products may have high
            levels of lead, mercury, and arsenic.
            <br/>
            <br/>
            Shilajit <b>can cause health disorders</b>. As a herbal product, Shilajit has no administrative control on
            its quality, purity and strength. Low-quality Shilajit can prove to be dangerous for health.
            <br/>
            <br/>
            Impure Shilajit contains <b>high levels of heavy metals</b>. Such varieties of Shilajit might also have
            numerous additives and toxins. Before deciding on opting for this product, one must discuss it with a doctor
            and get a prescribed brand and dosage.
            <br/>
            <br/>
            Before consumption, it is also important to be sure that one is not allergic to Shilajit. <b>Common allergic
            reactions</b> to Shilajit are body rashes, a rapid heartbeat and vertigo. It can also result in
            inflammation, dizziness and queasiness.
            <br/>
            <br/>
            It is still unknown if regular use of Shilajit can lead to any <b>health disorders</b>. Some studies say
            that Shilajit, when taken for a long time, might increase the levels of uric acid in the body and even
            aggravate gout.
            <br/>
            <br/>
            <b>Raw or impure shilajit</b> is highly dangerous. Pregnant women and breastfeeding mothers should stay away
            from any variety of Shilajit.
            <br/>
            <br/>
            Shilajit can also <b>change hormone levels</b> and cause dangerous health hazards in children.
            <br/>
            <br/>
            Those who suffer from <b>high iron in the blood, serious heart / high blood pressure issues, virus
            infections/ temperature fluctuations, chronic allergies, sinusitis, rhinitis, chronic sleep deprivation,
            post-nasal drip, a blocked nose, itchiness in the ears, mild pain, redness in the face and toothaches,
            should advisibly avoid the intake of Shilajit</b>.
            <br/>
            <br/>
            Taking Shilajit in summer might cause health hazards. While buying Shilajit from a store or online, one
            should read the levels or leaflets to be sure that it is processed and is fit for consumption.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'3'} title="How long does it take for the Shilajit to work?">
        <p>
            In Sanskrit, Shilajit connotes to meanings like ‘rock-invincible’, ‘conqueror of mountains’, or ‘destroyer
            of weaknesses’. This potent adaptogen, balances and strengthens the human body and allows its systems and
            organs to perform at their best possible level.
            <br/>
            <br/>
            That said, there is no standard rule about how long Shilajit will take to act on the human body. It differs
            from one person to another and personal experiences with Shilajit supplements, be it immediate reactions or
            the culminated impact, are diverse and varied.
            <br/>
            <br/>
            The experience and the time taken for Shilajit to start working will also vary on account of the other
            lifestyle choices an individual makes while taking Shilajit. The healthier the choices, the better, quicker
            and more long-lasting results.
            <br/>
            <br/>
            Results can also vary. Many get benefits in a matter of <b>a week or two</b>, while others experience an
            immediate impact. For best results, one should continue taking Shilajit <b>daily for six to eight weeks</b>.
            As an adaptive matter, Shilajit can create different experiences and impact in the body, which mostly
            depends on factors like health conditions and food habits.
            <br/>
            <br/>
            In most cases, people consume Shilajit according to the effect and reaction it generates. For those who get
            immediate results, one should stop the dosage earlier than those who take longer to see the impact of this
            health supplement.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'4'} title="References">
        <ul>
            <li>
                <a href="https://www.hindawi.com/journals" target="_blank">
                    https://www.hindawi.com/journals
                </a>
            </li>
            <li>
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3296184/" target="_blank">
                    https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3296184/
                </a>
            </li>
            <li>
                <a href="https://www.medicalnewstoday.com/articles/320318.php"
                   target="_blank">
                    https://www.medicalnewstoday.com/articles/320318.php
                </a>
            </li>
            <li>
                <a href="https://www.purehimalayanshilajit.com/what-is-shilajit/" target="_blank">
                    https://www.purehimalayanshilajit.com/what-is-shilajit/
                </a>
            </li>
            <li>
                <a href="https://selfhacked.com/blog/shilajit/" target="_blank">
                    https://selfhacked.com/blog/shilajit/
                </a>
            </li>

            <li>
                <a href="https://academic.oup.com/ajcn/article/72/2/624S/4729720" target="_blank">
                    https://academic.oup.com/ajcn/article/72/2/624S/4729720
                </a>
            </li>
            <li>
                <a href="https://www.sciencedirect.com/science/article/abs/pii/S037887411100273X?via%3Dihub"
                   target="_blank">
                    https://www.sciencedirect.com/science/article/abs/pii/S037887411100273X?via%3Dihub
                </a>
            </li>
            <li>
                <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/ptr.2100" target="_blank">
                    https://onlinelibrary.wiley.com/doi/abs/10.1002/ptr.2100
                </a>
            </li>
            <li>
                <a href="https://jpharmsci.org/article/S0022-3549(15)40768-3/pdf" target="_blank">
                    https://jpharmsci.org/article/S0022-3549(15)40768-3/pdf
                </a>
            </li>
            <li>
                <a href="http://anaturalhealingcenter.com/documents/Thorne/articles/Ashwagandha.pdf" target="_blank">
                    http://anaturalhealingcenter.com/documents/Thorne/articles/Ashwagandha.pdf
                </a>
            </li>
            <li>
                <a href="https://www.ingentaconnect.com/content/ben/cnsamc/2010/00000010/00000003/art00004"
                   target="_blank">
                    https://www.ingentaconnect.com/content/ben/cnsamc/2010/00000010/00000003/art00004
                </a>
            </li>
            <li>
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3336438/" target="_blank">
                    https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3336438/
                </a>
            </li>
            <li>
                <a href="https://www.researchgate.net/publication/276831443_SHILAJIT_AN_UNIQUE_DRUG_OF_AYURVEDA"
                   target="_blank">
                    https://www.researchgate.net/publication/276831443_SHILAJIT_AN_UNIQUE_DRUG_OF_AYURVEDA
                </a>
            </li>
        </ul>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]
