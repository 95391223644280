// External Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import AliceCarousel from 'react-alice-carousel'
// My Imports
import AuthenticatedContainer from '../../components/containers/AuthenticatedContainer'
import { fetchProducts } from '../../actions/ecommerce/products'
import Ecommerce from '../../services/Ecommerce'
import { createCartItem } from '../../actions/ecommerce/cart'
import FeaturedProductCard from '../../components/cards/FeaturedProductCard'

const Section3 = ({ match, history, products, createCartItem, fetchProducts }) => {
  const [productCarouselItems, setProductCarouselItems] = useState([])
  const [productCarouselIndex, setProductCarouselIndex] = useState(0)

  const handleAddItemToCart = async (product) => {
    Ecommerce.addToCart({ products: [product] })
    createCartItem({ id: product.id, quantity: 1 })
  }

  const handleOpenProductDetails = (product) => {
    Ecommerce.productClick({
      product,
      eventCallback: () => {
        if (product.is_single_product) {
          history.push(`${match.params.language}/therapy/${product.product_id}`)
        } else {
          history.push(`${match.params.language}/solutions/${product.product_id}`)
        }
      }
    })
  }

  const getProductCarouselItems = () => {
    return (products.list || []).filter(product => product.is_featured).map(product => (
        <div key={product.id} className="px-3">
          <FeaturedProductCard product={product}
                               handleAddToCart={() => handleAddItemToCart(product)}
                               autoScrollImages={false}
                               handleOpenProductDetails={() => handleOpenProductDetails(product)} />
        </div>
    ))
  }

  useEffect(() => {
    setProductCarouselItems(getProductCarouselItems())
  }, [products])

  const onProductSlideChanged = (e) => {
    setProductCarouselIndex(e.item)
  }

  return (
    <div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12">
            <h2 className="home-page-subtitle text-center cursor-pointer"
                onClick={() => history.push(`${match.params.language}/solutions/`)}>Products</h2>
          </div>
          <div className="col-12">
            <AuthenticatedContainer>
              <AliceCarousel mouseTrackingEnabled touchTrackingEnabled
                             autoPlayInterval={6000}
                             responsive={{ 0: { items: 1 } }}
                             autoPlay={true}
                             items={productCarouselItems}
                             startIndex={productCarouselIndex}
                             stopAutoPlayOnHover={true}
                             buttonsDisabled={true}
                             dotsDisabled={false}
                             onSlideChanged={onProductSlideChanged}
              />
            </AuthenticatedContainer>
          </div>
        </div>
      </div>
    </div>
  )
}

Section3.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  products: PropTypes.object,
  fetchProducts: PropTypes.func,
  createCartItem: PropTypes.func
}

const mapStateToProps = state => ({
  products: state.ecommerce.products
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchProducts,
  createCartItem
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Section3))
