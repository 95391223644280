// External Imports
import React from 'react'
// My Imports
import Image from '../../core/Image'
import ImageService from '../../../services/ImageService'

const WhyMisters = () => {
  return (
    <div className="why-misters-section">
      <div className="container pb-4">
        <div className="row pt-5 pb-2">
          <div className="col-12">
            <h2 className="home-page-subtitle text-center">Why Misters</h2>
          </div>
        </div>
        <div className="row">
          <div className="offset-md-1 offset-lg-1"/>
          <div className="col-6 col-sm-4 col-md-2 col-lg-2 pb-2 why-misters-section-item">
            <Image src={ImageService.whyMistersIcons.icon1} alt="Scientific products"/>
            <h6>Scientific products</h6>
          </div>
          <div className="col-6 col-sm-4 col-md-2 col-lg-2 pb-2 why-misters-section-item">
            <Image src={ImageService.whyMistersIcons.icon2} alt="Discreet doorstep delivery"/>
            <h6>Discreet doorstep delivery</h6>
          </div>
          <div className="col-6 col-sm-4 col-md-2 col-lg-2 pb-2 why-misters-section-item">
            <Image src={ImageService.whyMistersIcons.icon3} alt="Full privacy guaranteed"/>
            <h6>Full privacy guaranteed</h6>
          </div>
          <div className="col-6 col-sm-4 col-md-2 col-lg-2 pb-2 why-misters-section-item">
            <Image src={ImageService.whyMistersIcons.icon4} alt="Trusted by over 30,000 Indian Men"/>
            <h6>Trusted by over <strong>30,000 Indian Men</strong></h6>
          </div>
          <div className="col-6 col-sm-4 col-md-2 col-lg-2 pb-2 why-misters-section-item">
            <Image src={ImageService.whyMistersIcons.icon5} alt="Backed by leading doctors"/>
            <h6>Backed by leading doctors</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyMisters
