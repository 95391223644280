import Quiz from '../screens/Quiz'
import { getLanguagePath } from '../utils'
import Report3 from '../screens/Report/Report'
import AnonymousReport from '../screens/Report/AnonymousReport'
import Library from '../screens/Library'
import Purchase from '../screens/Library/Purchase'
import Course from '../screens/Library/Course'
import Product from '../screens/Therapy/Product'
import Profile from '../screens/Profile'
import Orders from '../screens/Profile/Orders'
import Order from '../screens/Profile/Order'
import Reports from '../screens/Profile/Reports'
import Addresses from '../screens/Profile/Addresses'
import Cart from '../screens/Checkout/Cart'
import DeliveryAddress from '../screens/Checkout/DeliveryAddress'
import Payment from '../screens/Checkout/Payment'
import OrderPlaced from '../screens/Checkout/OrderPlaced'
import OrderFailed from '../screens/Checkout/OrderFailed'
import CommunityClinicPage from '../screens/CommunityClinicPage/CommunityClinicPage'
import Posts from '../screens/Community/Posts'
import Post from '../screens/Community/Post'
import Doctors from '../screens/Community/Doctors'
import Doctor from '../screens/Community/Doctor'
import Solution from '../screens/Solution'
import NotFound from '../screens/NotFound'
import Wishlist from '../screens/Wishlist'

export default [
  {
    name: 'Quiz',
    component: Quiz,
    path: getLanguagePath('/quiz/:type(sex|porn|custom)'),
    exact: true
  },
  {
    name: 'Report3',
    component: Report3,
    path: getLanguagePath('/reports'),
    exact: true
  },
  {
    name: 'Report3',
    component: Report3,
    path: getLanguagePath('/sex/reports'),
    exact: true
  },
  {
    name: 'AnonymousReport',
    component: AnonymousReport,
    path: getLanguagePath('/quiz/reports'),
    exact: true
  },
  {
    name: 'Library',
    component: Library,
    path: getLanguagePath('/library'),
    exact: true
  },
  {
    name: 'Purchase',
    component: Purchase,
    path: getLanguagePath('/library/purchase'),
    exact: true
  },
  {
    name: 'Course',
    component: Course,
    path: getLanguagePath('/library/courses/:courseId'),
    exact: true
  },
  {
    name: 'Product',
    exact: true,
    path: getLanguagePath('/therapy/:productId'),
    component: Product
  },
  {
    name: 'Product2',
    exact: true,
    path: getLanguagePath('/therapy2/:productId'),
    component: Product
  },
  {
    name: 'Profile',
    component: Profile,
    path: getLanguagePath('/profile'),
    exact: true
  },
  {
    name: 'Orders',
    component: Orders,
    path: getLanguagePath('/profile/orders'),
    exact: true
  },
  {
    name: 'Order',
    component: Order,
    path: getLanguagePath('/profile/orders/:cid'),
    exact: true
  },
  {
    name: 'Reports',
    component: Reports,
    path: getLanguagePath('/profile/reports'),
    exact: true
  },
  {
    name: 'Addresses',
    component: Addresses,
    path: getLanguagePath('/profile/addresses'),
    exact: true
  },
  {
    name: 'Wishlist',
    component: Wishlist,
    exact: true,
    path: getLanguagePath('/wishlist/')
  },
  {
    name: 'Cart',
    component: Cart,
    exact: true,
    path: getLanguagePath('/checkout/')
  },
  {
    name: 'DeliveryAddress',
    component: DeliveryAddress,
    exact: true,
    path: getLanguagePath('/checkout/address')
  },
  {
    name: 'Payment',
    component: Payment,
    exact: true,
    path: getLanguagePath('/checkout/payment')
  },
  {
    name: 'OrderPlaced',
    component: OrderPlaced,
    exact: true,
    path: getLanguagePath('/checkout/success')
  },
  {
    name: 'OrderFailed',
    component: OrderFailed,
    exact: true,
    path: getLanguagePath('/checkout/failed')
  },
  {
    exact: true,
    path: getLanguagePath('/clinic-community/:slug'),
    name: 'CommunityClinicPage',
    component: CommunityClinicPage
  },
  {
    exact: true,
    path: getLanguagePath('/community/'),
    name: 'Posts',
    component: Posts
  },
  {
    exact: true,
    path: getLanguagePath('/community/:slug/'),
    name: 'Post',
    component: Post
  },
  {
    exact: true,
    path: getLanguagePath('/community/doctors/'),
    name: 'Doctors',
    component: Doctors
  },
  {
    exact: true,
    path: getLanguagePath('/community/doctors/:slug/'),
    name: 'Doctor',
    component: Doctor
  },
  {
    exact: true,
    path: getLanguagePath('/solutions/:productId'),
    name: 'Solution',
    component: Solution
  },
  {
    name: 'Solution2',
    exact: true,
    path: getLanguagePath('/solutions2/:productId'),
    component: Solution
  },
  {
    path: '*',
    component: NotFound
  }
]
