// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'

const buyNowUrl = '/en/therapy/ayurhairpack/'

export const accordionList1 = [
    <AccordionCard key={'0'} title="What is the natural cycle of hair growth?">
        <p>
            Hair is made up of two main parts, the hair <b>shaft and the hair follicle</b>. The <b>follicle is further
            constructed with two portions, the dermal papilla and the bulb</b>. The <b>dermal papilla supplies nutrients
            and blood and the bulb is where the cells divide to grow new hair</b>. The hair shaft is made of these cells
            and is pushed out of the follicle, resulting in the new hair growth that appears through the scalp.
        </p>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the phases of the natural hair life cycle?">
        <p>
            <strong>Anagen:</strong> The first phase is the growth phase, also known as anagen. The longer the hair
            follicles continue in this phase the more hair they will produce and longer it will grow. This generally
            lasts from two to six years, which is why the human scalp has such long hair.
            <br/>
            <br/>
            <strong>Regression:</strong> The regression phase lasts up to two weeks. This phase of the hair life cycle
            is a transitional stage called ‘Catagen’,where the hair detaches from the dermal papilla, stops receiving
            blood and nutrient supply and even stops growing further. This is when the follicle starts shrinking and the
            bulb of keratin is formed. This bulb acts as the root to keep the hair stable in the scalp. This is known as
            ‘club hair.’
            <br/>
            <br/>
            <strong>Telogen:</strong> The third and the last phase is telogen which in general terms is the resting
            phase. At this stage, the follicle lies dormant for around three months. There is actually a proceeding
            sub-phase to this, referred to as the ‘Exogen’. This is the phase where hair falls out and the cycle starts
            again. Every hair on the human scalp will be at different points in the cycle at a different time so that
            the scalp remains full of hair all the time. However, during this cycle, an average adult is supposed to
            lose roughly 100 hair every day, which is quite negligible with around 100,000 hair on the head. When the
            number of hair that falls out starts to significantly increase, you are likely to be suffering from
            Androgenetic Alopecia, commonly known as pattern hair loss or pattern baldness.
            <br/>
            <br/>
            The hair follicles start to shrink, the growth phase reduces and the resting phase becomes longer. The now
            smaller hair follicles start producing thinner hair and with each new cycle, the follicle continues to
            shrink further and becomes less and less well anchored to hold the hair to the scalp. Overtime, a reduced
            hair growing phase means the hair cannot grow longer and eventually, the growth phase becomes so short that
            the hair doesn’t get the chance to come to the surface of the scalp. Which in common terms means the person
            is going bald.
            <br/>
            <br/>
            Going bald can appear in the form of big changes in one’s personality. This sometimes even causes anxiety
            and depression. Hence, anybody suffering from low self-esteem due to male pattern baldness should
            immediately seek counselling. Preventing male pattern baldness may not be possible but regrowing lost hair
            is possible with proper medication and a sound lifestyle.
        </p>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What are the causes of male pattern baldness?">
        <p>
            One of the most common ideologies surrounding the causes of male pattern baldness is that having lots of sex
            and overflowing with testosterone can cause baldness. This phenomenon, however, is untrue. It is a myth that
            gained a lot of traction because of its relativity to the sensitivity to testosterone which gets converted
            into a hormone named DHT or dihydrotestosterone, which is the main reason for follicular downsizing. There
            are other hidden causes as well that may trigger hair loss in men.
            <br/>
            <br/>
            <strong>5-alpha-reductase:</strong> 5-alpha-reductase or 5-AR is an enzyme found under the sebaceous glands
            under the scalp. Inside these sebaceous glands is where 5-AR stays still, waiting to convert testosterone
            into DHT. As levels of 5-AR increase, more and more testosterone gets converted into DHT stimulating the
            production of the skin’s natural lubricant, sebum, causing more hair loss.
            <br/>
            <br/>
            <strong>Maternal family history:</strong> One of the largest studies on male hair loss reasons was conducted
            in 2017, by the University of Edinburgh. It conducted the test over 52,000 men and identified 287 kinds of
            genetic signals that link back to hair loss. The findings explain that the theory that men inherit hair loss
            patterns from their mother’s side, often to be from her father, genuinely holds some weight. While most of
            the genetic signals were scattered across the genome, 40 of them were connected to the X chromosome which is
            inherited from the mother. Which means hair loss in men can be an inheritance from the maternal family
            history of baldness.
            <br/>
            <br/>
            <strong>Stress or trauma:</strong> Stress or trauma can disrupt the hair life-cycle causing severe hair
            loss. Stress or trauma may not cause androgenetic alopecia or pattern baldness. Although,it does cause other
            hair problems like telogen effluvium. Telogen Effluvium is a type of hair loss which is reactive in nature
            and can be induced by stress. Disrupted life-cycle of hair by stress can shock a large percentage of hair
            follicles sending them directly to the resting phase. Which means the hairs won’t actually fall out for
            another 3-4 months until they reach the exogen phase. But once they reach the exogen phase they start
            falling out and give an appearance of thinning.
            <br/>
            <br/>
            <strong>Hormones, viruses, toxins and allergies:</strong> Along with severe stress, factors like hormonal
            imbalance, viruses, toxins and allergies play a major part in causing hair problems like autoimmune diseases
            known as alopecia areata, due to which men lose hair in unpredictable circular patches and androgenetic
            alopecia, also known as male pattern baldness. Gradual decline in functional thyroid tissue and endocrine
            disorders give rise to hair follicle invasion by lymphocytes resulting in alopecia.
        </p>

        <ImageBreaker image={Images.categories.stressedMan} to={buyNowUrl} buttonText="BUY_NOW"/>
    </AccordionCard>,
    <AccordionCard key={'3'} title="What are the symptoms of hair loss in men?">
        <p>
            Usually, hair loss is caused by genetics or the hair loss history of men in the family, which usually
            surfaces with the age. The key to detecting hair loss is to regularly check hair health, to prevent possible
            hair problems in time and find the right solutions for each situation. There are a number of unambiguous
            symptoms that reflect that the problem of hair loss may be more serious than it seems.
            <br/>
            <br/>
            <strong>Tuft fall-outs:</strong> The regular presence of hair strands on the pillow when waking up, and hair
            follicles shrinking, resulting in an abnormal amount of hair loss.Tufts may fall out when combing, washing
            or even pulling gently. This type of hair loss usually causes general hair thinning instead of bald patches.
            <br/>
            <br/>
            <strong>Circular or patchy bald spots:</strong> Hair loss or male pattern baldness can also be identified
            with the circular bald patches in some people. These spots at the start are generally of the size of a coin
            which gradually grow into a bigger bald patch. This type of loss is usually seen in the scalp area, however,
            in some cases, it can even spread to the eyebrows and beard.
            <br/>
            <br/>
            <strong>Scalp itching or discomfort:</strong> The presence of dermatitis or fungus on the scalp are the
            pathologies that can lead to severe hair loss. These elements are usually accompanied by intense itching,
            redness of the scalp and discomfort in the hair.
            <br/>
            <br/>
            <strong>Hair with fat or dandruff:</strong> Another most frequent symptom is the presence of dandruff or fat
            in the hair. It is true that dandruff problems are usually common, however, when it is excessive it can be
            related to a hair disorder that can lead to hair loss.
            <br/>
            <br/>
            <strong>Change in hair texture:</strong> This can be perceived as the hair thinning, which makes it weaker.
            It breaks more easily; it cakes easily with the products or it is too thin to cover the scalp.
            <br/>
            <br/>
            <strong>Hair loss along the temple or the front of the scalp:</strong> One of the most common types of hair
            thinning that affects both men and women as they age, is the receding hairline. In men, starting from the
            anterior side of the scalp, hair starts to fall off and moves backwards forming a pattern like the letter
            ‘M’.
        </p>
    </AccordionCard>,
    <AccordionCard key={'4'} title="Does hair loss in men indicate any risk?">
        <p>
            Problems in the scalp, genetic inheritance, improper cosmetic procedures, general diseases, poor diet, are
            just some of the many causes of hair loss. In some cases, it may be something temporary and in others, the
            principle of evolutionary alopecia until baldness. Whether due to genetic, hormonal or external
            physical-chemical aggression, hair loss is a wake-up call, because it can be indicative of a hidden disease
            or disorder of the body.
            <br/>
            <br/>
            Behind the hair loss, there may be symptoms of, Hypothyroidism or hyperthyroidism, Anaemia, diets deprived
            of calories or protein, Zinc, iron, biotin or essential fatty acid deficiencies, systemic diseases or
            psychological stress.
            <br/>
            <br/>
            Alopecia is increasingly considered as indicative of health-related issues. In fact, the results of several
            studies that have been recently published due to the intense research on alopecia, hair loss is an emerging
            predictive factor to consider the risk of developing certain diseases in the future.
            <br/>
            <br/>
            <strong>Risk of developing heart disease:</strong> According to a medical journal published in a British
            magazine, male baldness is related to an increased risk of developing heart disease. Specifically, the
            results of these investigations showed that men with frontal and crown baldness were 69% more likely to
            develop pathology in the coronary arteries than those who did not suffer from alopecia. It was also found
            that those who only suffered hair loss in the upper part of the head are at 52% risk of developing heart
            problems. Frontal alopecia involved the least risk with a 22% rate.
            <br/>
            <br/>
            <strong>Risk of developing prostate cancer:</strong> The results of an investigation conducted by experts
            from the Center for Clinical Epidemiology and Biostatistics of the University of Pennsylvania (United
            States), in African-American men (who have a higher risk of developing this type of cancer and more than
            twice as likely to die because of it than other groups of the North American population), showed that those
            in which the hair loss began at earlier ages, the risk of suffering from prostate cancer was higher.
            <br/>
            <br/>
            The authors of this new research, published in the journal Cancer Epidemiology, Biomarker; Prevention,
            showed that African-American men with frontal baldness were twice as likely to have advanced prostate cancer
            than others without baldness symptoms. This association between alopecia and prostate cancer was most
            evident in those who had been diagnosed with the disease when they were under 60. Also, younger men with
            frontal baldness were more likely to have elevated levels of prostate-specific antigen (an indicator of this
            type of cancer) in the diagnosis.
        </p>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="What is the treatment for hair fall?">
        <p>
            Hair loss before age is one of the primary concerns in a man’s life, as it affects one’s appearance and
            degrades their confidence. Hence, at the very start of baldness, it is essential to opt for some kind of
            medical consultation. There can be multiple factors that cause hair loss or alopecia and to understand them,
            timely diagnosis is essential. It not only helps to receive correct hair loss treatment but also to achieve
            good therapeutic results.
            <br/>
            <br/>
            <strong>Healthy diet:</strong> Eating a healthy and varied diet is helpful in controlling hair loss. Zinc is
            a mineral that participates in the synthesis of keratin. Combining it with vitamin B6 is even more
            beneficial. Certain foods that contain zinc are meat, eggs, tuna, nuts, clams, chickpeas, lentils, beans,
            wheat germ, soy drinks, mushrooms and dairy.
            <br/>
            <br/>
            <strong>Mesotherapy:</strong> Small injections filled with active hair growth inducing substances when
            applied to the scalp is known as mesotherapy. The substances used in these injections are finasteride,
            minoxidil, biotin and other amino acids. These injections directly act on both the hair follicles and scalp.
            For better results, it is applied once a week, commonly up to 8 sessions minimum.
            <br/>
            <br/>
            <strong>Topical Minoxidil:</strong> Minoxidil can be used as a separate lotion for hair growth as well. To
            directly stimulate the hair follicle, apply the lotion over the scalp twice a day. The benefits can only be
            seen after using the lotion on a regular basis, for at least 4 to 6 months. The best outcomes are however
            seen only after a year of application.
            <br/>
            <br/>
            <strong>Finasteride orally:</strong> Finasteride consumed orally is a great way for blocking the harmful
            effects of androgens on hair follicles and inhibiting the transformation of testosterone. The results are
            displayed after continuous use for six months. Although both topical minoxidil and finasteride are approved
            by the FDA, one must consult a doctor before their consumption.
            <br/>
            <br/>
            <strong>Hair micro transplant:</strong> Hair transplants not only transplant the hair but also healthy
            follicles into the bald areas. Invented in the 1950s, hair transplant is a thorough procedure handled quite
            delicately. This option is a combination of art and science which extracts a pinch of the skin or individual
            follicular units using a punch tool and implants them into the slit in the boarding area. With a good
            plastic surgeon, this procedure becomes quite natural and safe. Once the surgery is completed it takes
            approximately 6 to 26 weeks to see the regrowth of hair.
        </p>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are hair loss prevention medicines?">
        <p>
            It is normal to lose between 50 or 100 hair strands every day (in autumn up to 200), as they are naturally
            replenished. It is part of the process of tissue regeneration that occurs throughout the body and, in fact,
            the hair is usually renewed in its entirety every six months. The problem arises when all the hair that
            falls out is not replenished, so that it becomes less dense, gradually exposing the skin of the scalp. Hair
            loss or thinning can be a temporary, but also a continuous or recurring process. And it can happen suddenly
            or occur slowly, almost imperceptibly. In severe cases of hair fall that can lead to baldness there are
            certain trusted medications that specialists prescribe after a thorough physical examination, such as <b>Minoxidil,
            Finasteride and Dutasteride</b>.
        </p>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What are the dietary suggestions for preventing hair loss?">
        <p>
            Stress, use of chemicals, diseases and hormonal changes are some of the most obvious hair fall causes. But
            very few know that a poor diet can also cause hair loss. Improper diet leads to lack of iron and other
            essential amino acids, which eventually leads to hair loss or baldness. Hence, the foods we include in our
            regular diet plays an important role in the growth and strength of hair strands. In addition, they are
            determinants to prevent hair loss.
            <br/>
            <br/>
            <strong>Fruits and vegetables:</strong> Fruits and vegetables are the greatest sources of natural
            antioxidants that we can find. Including them frequently in a diet is key to strengthen the hair and
            stimulate its growth, as they help inhibit the effects of oxidative stress and guarantee the perfect
            absorption of other nutrients. Oranges, kiwi, broccoli, spinach and lemons come loaded with antioxidants
            such as vitamin C, beta-carotene, flavonoids and minerals that help in the blood circulation of the scalp.
            Bananas have vitamins of group A, C, B and E. And contain potassium and magnesium.Grapes are rich in
            flavonoids, linoleic acid, vitamin E and anthocyanidins. They prevent hair loss and promote hair growth.
            <br/>
            <br/>
            <strong>Strawberries and other red fruits:</strong> These are packed with antioxidants, silica, folic acid,
            vitamin B6 and B7. They also contain trace elements such as copper, manganese and magnesium.
            <br/>
            <br/>
            <strong>Selenium:</strong> Selenium is an essential trace element that plays an important role in protecting
            against oxidative damage and hair follicle morphogenesis. It is found in foods such as meat (red), fish,
            green leafy vegetables, Brazil nuts or Amazonian chestnut, pine nuts, seafood, eggs, chicken, liver and
            canned tuna, garlic, mushroom, grains, whole grains, beer yeast, wheat germ, wheat flour and pasta.
            <br/>
            <br/>
            <strong>Zinc:</strong> Consumption of zinc helps control the hair growth cycle. In addition, its deficiency
            is associated with problems such as alopecia. Meat is a rich source of zinc, which also contains iron,
            proteins, B6 vitamins and B12 vitamins, which are associated with the prevention and treatment of hair loss.
            Zinc is also present in other foods such as celery, asparagus. figs, potatoes and eggplant.
            <br/>
            <br/>
            Other food items that prevent hair loss are foods with the presence of biotin, especially eggs and liver,
            foods with folic acid such as beets, seafood such as mussels, clams or cockles, since iron is essential for
            hair well-being.
            <br/>
            <br/>
            One must choose good sources of silicon, such as legumes and seafood. Moreover, drinking water and consuming
            green tea will be the best options for hydration.
        </p>
    </AccordionCard>,
    <AccordionCard key={'3'} title="How can men prevent hair loss?">
        <p>
            Many men live with the fear of hair loss and especially of being totally bald. Hair loss can be both genetic
            or related to hormonal problems. The best way to prevent baldness is to listen to your hair. Entries or hair
            loss on the top of the head are the first visible sign of baldness. When the <b>first entries begin to
            appear, it is best to go to the pharmacy to ask for a treatment that stops hair loss</b>. These treatments
            are long and demanding, but they are a great way to keep hair fall in check.
            <br/>
            <br/>
            Other than that, taking into account things as simple as <b>not touching your head</b> can make a
            difference. It is often heard that the use of <b>caps or hats can worsen and favour hair loss, but when in a
            place where high temperatures are something usual and the burning sun is present, it is best to protect the
            scalp from UV rays</b>, so that the scalp does not sweat or produce excess fat.
            <br/>
            <br/>
            Another tip to keep in mind is that <b>when washing your hair in the shower, do not rinse with hot water</b>,
            because it is a recurring error that will demonstrate its consequences sooner or later. <b>After applying
            the shampoo and conditioner, it is best to rinse with cold water for the tips to close</b>. At the time of
            drying, do not put a lot of pressure on the towel, better <b>let it dry naturally</b>, thus giving the hair
            a chance to breathe. It is <b>not advisable to wash your hair every day</b>, better do it just three times a
            week, in this way natural oils are produced that help nourish your hair and prevent it from falling out. <b>One
            must comb the hair gently every day</b>, to stimulate blood circulation in the scalp.
            <br/>
            <br/>
            As for the diet, it includes <b>ingredients rich in vitamins B, C, D and E,</b> which are available in red
            meat, fish and green leafy vegetables. Speaking of lifestyle, one of the things that should be eliminated
            instantly is worrying, since stress is one of the biggest responsible for baldness.
            <br/>
            <br/>
            Young people between 17 and 20 years old who have oily skin, should be careful since the cleanliness of the
            hair is essential because although the sebum is necessary for hair growth, its excess causes the fall to
            accelerate. Also, avoid touching hair again and again, so excess sebum will also be less likely.
        </p>
    </AccordionCard>,
    <AccordionCard key={'4'} title="How can one live with male pattern baldness?">
        <p>
            Androgenic alopecia or male pattern baldness is one of the main hair loss causes in men.. This is usually
            driven by hereditary reasons like baldness in the maternal family line, which causes damage to the
            production of testosterone and affects its genetic composition. In this way, the hair follicle is affected
            and its usual activity is atrophied, generating a progressive reduction of hair volume until it disappears.
            <br/>
            <br/>
            The most obvious symptoms of baldness begin in the most sensitive areas; namely: the entrances and the
            crown. In male-pattern baldness, there are even seven stages clinically determined by scientists. The first
            corresponds to a normal one, while the seventh refers to a very marked loss.
            <br/>
            <br/>
            To slow down and prevent its progress, there are a number of tips that can be implemented. The first,
            however, will be to <b>detect it as soon as possible</b> through a <b>specific test</b>. Depending on the
            risk and age, <b>treatment with minoxidil or any other drug will be recommended</b>. Beyond this treatment,
            however, there are certain tricks that can help people suffering from male pattern baldness.Things as simple
            as <b>avoiding certain hairstyles (assuming you have it long) such as pigtails</b>. <b>Keeping hair
            clean</b> and moisture free can also be a good idea. Also <b>adopting mild hair products</b> for hair
            wash, <b>using warm water and a conditioner to avoid pulling when combing, is a great trick</b>. Dry hair in
            the natural air, <b>do not use hair dryers and brush it patiently, massage the scalp with essential oils and
            promote hair irrigation</b>. Including proteins, <b>sulfur-rich foods and omega 3 fatty acids in the regular
            diet are also highly recommended actions</b>.
            <br/>
            <br/>
            <b>Staying away from stress and tobacco is a must.</b> In addition to minoxidil, which is a vasodilator, use
            it on and around the scalp area to prevent the production of dihydrotestosterone and allow the hair follicle
            to function naturally. There are also specific inhibitors that prevent miniaturization of hair such as
            Finasteride, antiandrogens, Cyproterone acetate and Spironolactone. You can also <b>opt for hair
            micrografting</b>, the most <b>advanced surgical solution</b> to recover hair.
            <br/>
            <br/>
            Given so many possible solutions, the ideal is to <b>go to the specialist’s office</b>, so that they can
            study the case individually, in a suitable way and establish a personalized treatment to achieve the best
            results.
        </p>
    </AccordionCard>
]

export const accordionList3 = [
    <AccordionCard key={'0'} title="What is the science behind hair growth?">
        <p>
            All the hair we have in the body has a life cycle during which it is renewed, called hair cycles. This
            process affects all people equally and the hair of any part of the body. However, the growth and development
            rhythms are not identical and, depending on the area of ​​the body in question, have one or the other
            duration. For example, the hair phases of the eyebrow hair differs from those of the head.The phases of the
            hair cycle are determined by several factors including genetics, eating habits, or changes in the body
            caused by other reasons, such as stress or pregnancy.
            <br/>
            <br/>
            Each hair evolves independently of the hair process of those around it. Throughout one’s life, men can
            experience 20 different cycles, in which hair is born and falls. The number of follicles each person has
            (between 130,000 and 150,000) decreases over the years. At birth, we have about 1,100 follicles per square
            centimetre, a figure that decreases at 25 years to about 600 and between 30 and 50 years at 250.
            <br/>
            <br/>
            Although each hair has its own characteristics depending on its placement location, the phases are the same
            for everyone. Basically, the hair <b>cycle consists of three stages: the growth or anagen, the transition or
            catagen and the rest or telogen</b>. The growth or anagen phase lasts between 2 and 7 years, during which
            hair is born and grows attached to the papilla. In this phase, the follicle is healthy and its cells have a
            permanent activity and the hair has linear growth.The length of the hair is determined by this phase, the
            longer this stage lasts, the longer the hair will be.
            <br/>
            <br/>
            In the transition or catagen phase, which lasts between 3 and 6 weeks, the hair stops growing and separates
            from the papilla. The activity of the follicle cells causes a growth stop, and subsequent fall, in which the
            follicle changes shape.
            <br/>
            <br/>
            The resting or telogen phase is the one where the follicle rests and the hair begins to fall, lasting
            between 2 and 3 months and throughout the process, the hair root remains in the follicle. When this stage
            ends, the hair cycle begins again with the growth phase.
            <br/>
            <br/>
            Generally, based on a normal situation, the percentage of the above-mentioned phases is distributed as
            follows: 85% Anagen, 1% Catagen and 14% Telogen.The hair cycle ends when the hair stops growing in the
            follicle. It may be the case that affects several follicles at the same time and in the same place, thus
            appearing as the first symptoms of alopecia.
            <br/>
            <br/>
            Local, genetic, endocrine and metabolic factors are involved throughout the hair’s life cycle. The hair
            reacts very sensitively to both external and internal influences, hence certain disorders or situations can
            increase the proportion of hair that is in the telogen phase.
            <br/>
            <br/>
            <strong>WNT pathways, that control hair growth:</strong> Researchers at the University of Pennsylvania
            School of Medicine have just published a new study in the scientific journal Cell Reports, in which they
            have found reasons that lead the human body to generate hair in places like the head, face, arms or legs but
            not in the palms of the hands or feet. They found an inhibitor of natural origin secreted in the development
            of hairless skin that blocks the signalling pathways, known as WNT pathways.
            <br/>
            <br/>
            The skin found in hairless areas naturally produces an inhibitor that prevents WNT pathways from doing their
            job and generating hair in these regions of the body. The inhibitor here is named as Dickkopf 2 (DKK2),
            which is a protein found in embryonic and adult tissues in which it plays a wide variety of roles. DKK2 is
            the reason for hair growth blockage in certain body parts, however, if this inhibitor is removed
            genetically, body parts like palm or feet can have a certain amount of hair growth as WNT is present in
            these areas.This discovery may seem trivial, but according to the authors of the research, their findings
            can be very useful in trying to stop alopecia. Like other hair losses due to other diseases or accidents.
            <br/>
            <br/>
            <b>A curiosity:</b> There is a belief that hair falls more in autumn. It is a half-truth. It is proven that
            the anagen state coincides with winter and that the telogen can be seen in late summer and early fall.
            Therefore, rather than affirming that hair falls more in autumn, it should be said that it simply grows less
            during this time of year.
        </p>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What is the science of hair loss?">
        <p>
            A hair follicle has the ability to generate up to 25 hairs successively. But there are factors that can
            induce the follicle to run out sooner and stop generating new hair, opening the door to alopecia or
            baldness. In general, it is a hormonal issue whose origin is the male hormone called dihydrotestosterone,
            which helps to shorten the life cycle of the hair because when one falls the next that is born from the same
            follicle is weaker and finer until it no longer comes out a new one.
            <br/>
            <br/>
            This is how androgenetic alopecia or male pattern baldness is produced, which has an important hereditary
            component, and which constitutes the origin of 90% of male alopecia. In fact, this situation begins in 25%
            of cases from 25 years of age, between 30 and 50 in 30% of men and 50% in the rest. Although, there are
            other <b>factors that can accelerate hair loss like seborrhea or excess fat. Hair hygiene, pollution,
            stress, situations of high emotional impact or smoking are also involved. All this has a common effect on
            hair growth, the blockage of free radicals and, beginning of an oxidative process that negatively affects
            the blood flow of the hair follicles, subtracting capacity and strength, significantly weakening the
            hair</b>.
            <br/>
            <br/>
            In another study, scientists from the University of Pennsylvania (USA) have identified a key protein known
            as prostaglandin D2 (PGD2), in androgenic alopecia (the most common form of baldness), which affects 80% of
            men before their 70th year of age. Experiments performed in the laboratory with human hair follicles have
            confirmed that PGD2 inhibits hair growth and an analysis of seventeen volunteers with rampant alopecia, has
            shown that baldness progresses in the areas of the scalp affected by an excess of PGD2. Scientists have also
            shown that PGD2 inhibits hair growth because it interacts with a receptor called GPR44.
            <br/>
            <br/>
            The interest of this recipient is that there are already a dozen experimental drugs, developed by different
            pharmaceutical companies,capable of blocking it.These drugs are being investigated primarily with the aim of
            treating asthma. But if applied as a cream on the scalp, they could prevent the PGD2 protein from
            associating with the GPR44 repellent and blocking hair growth. The care of the hair, with good hygiene or
            cosmetic products indicated by dermatologists, could prevent or slow down hair loss, prolonging its life
            cycle, its thickness, its resistance and its density.
        </p>
    </AccordionCard>,
    <AccordionCard key={'2'} title="How does Ayurveda prevent male pattern baldness?">
        <p>
            The growth cycle of a new strand of hair can last up to two to three years. When the growth period ends a
            rest period begins which in normal conditions would last 3-4 months, followed by hair fall and the beginning
            of a new growth cycle. This cycle keeps working until interrupted by any kind of genetic, psychological or
            external factors. Hair loss due to genetic conditions or male baldness family history is known as
            Androgenetic Alopecia. This problem can occur in both men and women with the same frequency, although women
            can hide it much better than men. In this condition, the hair growth period is much smaller than normal, and
            the hair becomes abnormally thin exposing the scalp area.
            <br/>
            <br/>
            This occurs mostly as a result of a hormonal change. Specifically for men, the most affected areas in this
            condition are the crown and the frontal area above the forehead. In many cases, the hairline near the
            temples recedes, creating an M-shaped pattern. Other than hormonal changes male pattern baldness can be an
            outcome of various hidden diseases, which includes hypothyroidism, iron deficiency, diabetes, prostate
            cancer, heart problems, hyperthyroidism and medicinal side effects (such as anticoagulants, anticonvulsants,
            beta-blockers and antidepressants).
            <br/>
            <br/>
            <b>In Ayurveda, it is considered that hair is a byproduct (Mala) of bone tissue (Asthi dhatu), hence
                nourishing bone tissue can help prevent hair loss</b>. Also, people with excess pitta have seen to
            experience hair loss or premature grey colouration. Hair loss according to Ayurveda can also be associated
            with excess shingles (heat) and Vayu (air). <b>To control the above-mentioned factors affecting normal hair
            growth, various therapies are recommended at the beginning of the treatment for purification (such as
            purging). Nasya therapy (application of medicated oil through the nose) and applying the herbal paste to the
            head (including amla, honey and other herbs) is also recommended for people suffering from male pattern
            baldness</b>.
            <br/>
            <br/>
            Another hormone responsible for weak hair follicles and consequent hair loss is cortisol, which can be
            effectively brought under control with the help of ashwagandha. Ayurvedic treatment includes diet
            modification, usage of natural herbs, essential oil massage, meditation, yoga, aromatherapy and other forms
            of exercises. To control pitta and promote hair follicle healing, it is recommended to drink 1/3 cup of aloe
            vera juice daily for at least 3 months.
            <br/>
            <br/>
            According to the constitution and the imbalance of the patient, the head massage can be performed with
            coconut oil or herbs as Brahmi bhringaraj. It is also recommended to massage the soles of the feet before
            bedtime. Hair also can be massaged with vitamin E oil to reduce the rate of hair loss.
            <br/>
            <br/>
            In terms of food, the practitioner may consider recommending the consumption of sesame seeds/sesame. A
            handful of these seeds can provide up to 1200 mg of calcium and magnesium. It may also be useful to consume
            some yoghurt daily (unless one is known to be allergic to dairy).
        </p>

        <ImageBreaker image={Images.categories.nasyaTherapy} to={buyNowUrl} buttonText="BUY_NOW"/>
    </AccordionCard>,
    <AccordionCard key={'3'} title="Does Yoga help in the prevention of balding?">
        <p>
            Hair loss is a very common problem for both men and women. However, men have more than a 50% chance of
            suffering from alopecia in comparison to women. Alopecia is the most common type of male baldness that
            affects around 25 % of men between 25 and 35 years old, and up to 50% of those over 50 years of age. Behind
            this premature hair loss, there are multiple factors, such as stress, improper feeding, hormonal problems,
            skin disorders, the consumption of some medications and genetic factors; stress being the most common due to
            today’s hectic lifestyle. It also happens to be the factor affecting Millennials the most, as well.
            <br/>
            <br/>
            In this situation, the person suffering from baldness at all costs would seek a remedy or treatment that
            slows, blocks or relieves the symptoms of baldness, which begins with hair loss in the most sensitive areas
            that are the entrances and the crown. So, is it possible to prevent this condition?
            <br/>
            <br/>
            <strong>Hair loss prevention with yoga:</strong> Yoga is defined as the union of mind, body and soul. The
            power to control the mind is the ability to eliminate everything that is an obstacle to being happy. All
            muscles are connected and the mind is so strong that it is easier to control it with the help of the other
            muscles. Hence by controlling the body one can control the mind and stay safe from stress.
            <br/>
            <br/>
            Controlling the body is achieved through the practice of asanas (yoga postures) that makes the body and
            mental patterns change and purify and help breathing. Yoga is helping people of nearly all ages, all around
            the world to control the mind and body and stay free from stress. For some yoga is relief from physical
            pain, for others, yoga is to gain flexibility, discharge energy and feel relieved. Correct yoga poses can
            fight stress and its side effects thus preventing hair loss much faster than any medication if done
            correctly.
            <br/>
            <br/>
            <strong>Vajrasana:</strong> This Yoga asana helps stop hair loss, relax the body and mind, and reduce
            stress. It is ideal to increase the blood circulation of the scalp.
            <br/>
            <br/>
            <strong>Uttanasana:</strong> It calms the mind and reduces stress, improves digestion and also helps prevent
            scalp infection.
            <br/>
            <br/>
            <strong>Adho Mukha Svanasana:</strong> It is one of the best yoga asanas to stop hair loss. It provides good
            blood circulation to the head and helps get rid of the problem of insomnia and depression.
            <br/>
            <br/>
            <strong>Uttanpadasana:</strong> This is one of the simplest yoga asanas, which is ideal for increasing blood
            circulation around the body, including the scalp. The right amount of blood circulation keeps the scalp free
            from infection and maintains healthy hair growth.
            <br/>
            <br/>
            <strong>Bhastrika:</strong> It is a Pranayama that helps eliminate excess bile, phlegm and cold air from the
            body. It also purifies the blood and the nervous system. This, in turn, helps promote hair growth.
            <br/>
            <br/>
            <strong>Kapal Bhati:</strong> It is also one of the best pranayamas to stop hair loss. It helps increase
            blood circulation in the body and also reduces stress.
        </p>
    </AccordionCard>,
    <AccordionCard key={'4'} title="What is Minoxidil?">
        <p>
            Minoxidil is a <b>medication available in the form of a solution and foam, that is used to stimulate hair
            growth and stop excessive hair loss due to male pattern baldness or alopecia</b>. However, minoxidil is not
            recommended to be used at the front of the scalp in cases of a receding hairline in men. Initially,
            Minoxidil was produced for oral consumption as a drug to treat hypertension (HT) due to its vasodilator
            qualities. However, in some patients, the appearance of hair was observed, which made it appropriate for use
            in cases of alopecia.
            <br/>
            <br/>
            Minoxidil is approved by the FDA and can be used as a topical solution without the need for a prescription.
            Minoxidil is not recommended for unexplained sudden bald patches.
        </p>
    </AccordionCard>,
    <AccordionCard key={'5'} title="What are some popular brand names that sell Minoxidil?">
        <p>
            Minoxidil is available under different trusted brand names. For better results before using the product read
            the ‘directions to use’ mentioned on the product package. Although you do not need a doctor’s prescription
            to use minoxidil. In case of uncertainty about a piece of information it is better to consult a
            professional. Some of the well-known brands who sell minoxidil in India are <b>Adhair - Unichem, Misters,
            Alopec - Welbe LS, Misters, Amexidil-5, Ranbaxy, Chymotra - Adroit, Coverit - Micro Gratia, Dlminox -
            Steadfast and Gromane - Zydus (Alidac)</b>.
        </p>
    </AccordionCard>,
    <AccordionCard key={'6'} title="Why is minoxidil prescribed?">
        <p>
            Minoxidil is prescribed in two different forms. The first one is <b>minoxidil oral</b> which is prescribed
            by doctors to <b>lower high blood pressure levels and prevent strokes, kidney problems and heart attacks</b>.
            As minoxidil is known to <b>relax blood vessels and help better blood circulation it has the properties of
            hair growth stimulation</b>. Hence, Minoxidil as a topical treatment is also prescribed as a hair loss
            remedy for men suffering from male pattern baldness.
            <br/>
            <br/>
            Minoxidil slows balding and stimulates hair growth and is seen to be most effective in men under 40 years of
            age. Minoxidil is not a permanent solution for baldness and does not have any effect on the front portion of
            the scalp, also referred to as the receding hairline.
        </p>
    </AccordionCard>,
    <AccordionCard key={'7'} title="How should Minoxidil be used?">
        <p>
            Minoxidil is available in the market in the form of liquid and foam. Minoxidil as the foam is only
            beneficial for women with hair thinning issues. The liquid form of minoxidil is a topical hair loss
            treatment that needs to be <b>directly applied to the scalp</b>. For better results minoxidil should be
            applied twice a day on a regular basis. Use minoxidil strictly as directed on the package or by the
            physician. Excessive use of the product does not help in faster or greater hair growth. Exceeding the dosage
            recommended can cause side effects. To correctly apply the solution on to the scalp, use the applicators
            provided with the product, such as <b>metered-spray applicator for large areas, extender spray applicator
            for smaller areas and rub-on applicator for under the hair areas. Do not forget to close the bottle tightly
            after use</b>.
        </p>
    </AccordionCard>
]

export const accordionList4 = [
    <AccordionCard key={'0'} title="What are the side effects of minoxidil?">
        <p>
            One of the most pertinent side effects of minoxidil is that it is not a permanent solution and when the
            regular application stops, the hair maintained starts falling off again. Other than that, minoxidil is
            approved by the FDA for hair loss treatment and can be used without prescription. Having said this, however,
            there are certain side effects that may or may not occur.
            <br/>
            <br/>
            However, incase of any such symptoms consult a doctor immediately. These symptoms include a <b>continuous
            skin rash or itching (rare effects), acne in the place where Minoxidil has been applied, increased hair
            loss, burning of the entire scalp, flushed skin, swelling of the face, swelling and pain in the root of the
            hair (rare effects), blurred vision or changes in visibility, rapid and irregular heartbeat, chest pain,
            decreased sexual capacity or interest in sex, headache, hot flashes, rapid weight gain, numbness or tingling
            of feet, hands or face, swelling of hands, feet, face or part of the legs</b>.
            <br/>
            <br/>
            Other possible side effects not mentioned may arise in the patient. If you notice any possible effect one
            should consult an expert.
        </p>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What precautions are to be considered while using minoxidil?">
        <p>
            In case of irritated scalp or sunburned patches, usage of minoxidil should be avoided. Also after applying
            minoxidil, avoid exposing the skin to direct sunlight. Minoxidil should always be kept <b>away from the
            reach of children</b> and <b>should not be applied to body parts other than the scalp</b>.
            <br/>
            <br/>
            If you are on medication for any other problems like <b>high blood pressure, or allergic to some drugs do
            consult a doctor before using minoxidil</b>. People who have a <b>history of scalp, kidney, heart or liver
            disease should not use minoxidil without consulting a medical professional</b>.
            <br/>
            <br/>
            Before using the product <b>carefully check the expiry date</b> and dispose of any unneeded medication.
            Minoxidil, as a hair growth solution is only prescribed for topical use, hence, if you use your hands to
            apply the solution on the scalp, <b>wash your hands thoroughly before and after use</b>. Inhaling the mist
            and <b>applying minoxidil on the wet scalp is strictly prohibited</b>.
            <br/>
            <br/>
            If you miss a dose or a day of application, do not double dose the next time you apply minoxidil. Continue
            the regular dose and skip the missed dose. Excess moisture and heat can damage the product. Hence, store
            minoxidil in a dry area with normal room temperature.
        </p>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What must one do in case of an overdose of minoxidil?">
        <p>
            Overdose of topical minoxidil is not looked upon as dangerous, but in case someone swallows the medication
            or any signs of allergic reaction are seen, <b>immediate medical help should be taken</b>. Some other
            serious symptoms of overdose could be fainting, dizziness, passing out or drowsiness.
        </p>
    </AccordionCard>,
    <AccordionCard key={'3'} title="How does minoxidil act inside the body?">
        <p>
            Androgenetic alopecia or male pattern baldness is produced by genetic causes and by the action of male
            hormones (androgens). Although it affects both men and women it is more common in men and for this type of
            alopecia, the use of Minoxidil is recommended.
            <br/>
            <br/>
            This treatment causes <b>vasodilation of the capillaries</b>, which <b>favours blood flow in the hair
            follicle</b>. With increased blood flow minoxidil helps human dermal papillary cells (DPCs) survive and
            activate both Akt and ERK (extracellular signal-regulated kinase). This prevents cell deaths and increases
            the ratio of BCl-2/Bax, which in return causes the new follicles to be born with more force and better
            strength.
            <br/>
            <br/>
            With its anti-apoptotic and proliferative effects on DPCs, topical minoxidil not only stimulates hair growth
            but also improves the viability of the hair follicles. During minoxidil application, the medication gets 90%
            absorbed in the scalp and opens up adenosine triphosphate-sensitive potassium channels and helps to treat
            androgenetic alopecia topically.
        </p>

        <ImageBreaker image={Images.categories.minoxidil} to={buyNowUrl} buttonText="BUY_NOW"/>
    </AccordionCard>,
    <AccordionCard key={'4'} title="What is finasteride?">
        <p>
            Finasteride is a drug that helps reduce the action of testosterone. It is used in the treatment of benign
            prostatic hyperplasia, a non-malignant growth of the prostate that affects many men from 50 years of age. As
            testosterone is directly related to the activity of hair follicles, in lower doses, <b>finasteride is one of
            the best-known pharmacological treatments to stop hair loss</b>. It has proven to be of great help against
            moderate hair loss in men between the ages of 18 and 60. It is a non-hormonal, steroid derived,
            antiandrogenic drug which acts as an inhibitor of the enzyme 5-alpha-reductase type II. This enzyme
            transforms testosterone into dihydrotestosterone (DHT).
            <br/>
            <br/>
            In individuals with androgenetic alopecia, DHT acts upon the hair follicles adversely, accelerating its
            growth phase and prematurely depleting its vital cycles, which progressively appears as alopecia.
            Finasteride is approved by the F.D.A. (US Food and Drug Administration) for male androgenetic alopecia at a
            dose of 1 mgr, which is 5 times lower than that prescribed for BPH (Benign Prostatic Hypertrophy).
        </p>
    </AccordionCard>,
    <AccordionCard key={'5'} title="What are some popular brand names that sell Finasteride?">
        <p>
            Finasteride for the treatment of enlarged prostate and male pattern baldness is available in India for
            several years but in different dosages. If used under a doctor’s prescription, finasteride consumption even
            for a long period of time, is completely safe. This medication is marketed and sold under several trade
            names, the most recommended being <b>Aga - Nidus, Contiflo-OD-F - Sun, Finabald - East West, Misters, Finalo
            - Intas, Finara - Dr. Reddy’s, Finaride - Psycorem, Finastat - Active HC, Fincar - Cipla and Finpecia -
            Cipla</b>.
        </p>
    </AccordionCard>,
    <AccordionCard key={'6'} title="Why is finasteride prescribed?">
        <p>
            Finasteride is the first drug of its kind prescribed orally. The efficacy and tolerability profile of this
            medicine has been proven through multiple clinical studies following rigorous controls and for long periods,
            up to 5 years. However, the effectiveness of finasteride has only been seen in men (should not be used in
            women or children), and the results may depend on the degree of progress in which androgenic alopecia is
            found, so it is essential that it be prescribed by a doctor after them having made an adequate diagnosis of
            the patient’s baldness condition.
            <br/>
            <br/>
            Androgenic alopecia is a condition that develops over a long period, thus using finasteride on a daily basis
            for at least three to four months is necessary to observe appropriate results and the <b>prevention of
            increased hair loss or an increase in hair growth</b>.
        </p>
    </AccordionCard>,
    <AccordionCard key={'7'} title="How should finasteride be taken?">
        <p>
            This medicine should be taken exclusively <b>by medical prescription</b>. Follow exactly the instructions of
            the administration of this medicine indicated by your doctor. Read all the instructions on the package
            carefully before consumption. Do not forget to check the expiry date as well. After reading the details if
            you have any queries it is better to ask your doctor or pharmacist immediately. This medicine should only be
            consumed by the person, to whom it has been prescribed. Do not share it with other people, even if they have
            the same symptoms.
            <br/>
            <br/>
            Unless the doctor prescribes otherwise, the usual dosage is <b>one tablet per day</b>. The tablets should be
            swallowed whole with a little water. It should not be broken or crushed. You can take the tablets with food
            or on an empty stomach.
        </p>
    </AccordionCard>
]

export const accordionList5 = [
    <AccordionCard key={'0'} title="What are the side effects of finasteride?">
        <p>
            The most frequent side effects are especially at the level of the sexual sphere <b>(impotence, decreased
            libido, abnormal ejaculation and more) although in some cases testicular pain, gynecomastia, swelling in
            hands and feet, and dizziness</b> have also been described. Usually, these alterations are reversible after
            a short span of discontinuing the medication. Although, in some patients they can continue for some time
            even after leaving it. It is contraindicated in women (in case of pregnancy it could produce feminization
            characters if the fetus were male) and also in children. Hence, this medication is only suitable for men
            above 18 years of age.
            <br/>
            <br/>
            Also, Finasteride is a long-term maintenance medical treatment and the beneficial effects achieved are lost
            within 12 months of leaving the medication. Most of the above-mentioned side effects are more common in
            people who are under the prescription of 5mg finasteride on a daily basis. Cases of side effects associated
            with 1mg finasteride consumption per day are rarely seen. However, in case of serious concerns or allergies,
            talk to your doctor immediately.
        </p>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What precautions are to be considered while taking finasteride?">
        <p>
            Finasteride does not act more quickly or effectively by taking more doses than indicated daily. To achieve
            greater hair thickness or decrease hair loss, you should <b>take this medication every day for 3 to 6 months
            or more</b>. You do not need to make any changes to your usual hair care during finasteride treatment. <b>Do
            not take a double dose to make up for forgotten doses</b>. Just take the next tablet at the usual time and
            continue your treatment normally.
            <br/>
            <br/>
            In case of allergies or other side effects immediately consult your doctor. Do not change the dose or stop
            taking this medication without checking with your doctor beforehand. In order to maintain the benefit of the
            treatment, the continued use of this medication is recommended. If you discontinue the treatment with
            finasteride, it is likely that any increase in the thickness of the hair you have experienced will be lost
            in the next 9 or 12 months.
        </p>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What to do in case of an overdose of Finasteride?">
        <p>
            If you have consumed more finasteride tablets than you should immediately consult your doctor or pharmacist,
            indicating the medication and the amount used. Also, keep a check for 24-48 hours for the symptoms like <b>swelling
            of the face, tongue or throat; rash and itching, difficulty to swallow; hives and difficulty in
            breathing</b>.
        </p>
    </AccordionCard>,
    <AccordionCard key={'3'} title="How does finasteride act inside the body?">
        <p>
            Finasteride is a type 2 5-alpha-reductase inhibitor which when taken as lower doses, slows down the
            evolution of androgenetic alopecia. Type 2 5-alpha-reductase is an intracellular enzyme with nuclear-bound
            that is basically located inside the prostatic stromal cell. Its action of transforming testosterone into
            5α-dihydrotestosterone (DHT), activates the hair follicles for a new life cycle. DHT generated by this
            medication is a higher receptor of androgen which helps modulate cell proliferation and has some positive
            effect on type 1 5-alpha-reductase found in sebaceous glands around the scalp area.
            <br/>
            <br/>
            Approximately 2/3 of all circulating dihydrotestosterone in the blood are produced by 5-a-reductase. In the
            prostate, 5- dihydrotestosterone acts to stimulate the growth of prostate tissue, while in patients with
            baldness the hair follicles contain much greater amounts of this androgen than those present in normal hair.
            To reduce the plasma levels of 5-dihydrotestosterone, finasteride reduces the size of the prostate and
            disrupts the deposition of this product in the hair follicles of individuals genetically predisposed to
            baldness.
            <br/>
            <br/>
            Finasteride does not affect the circulating concentrations of cortisone, estradiol, prolactin,
            growth-stimulating hormone or cholesterol. Finasteride just <b>deactivates this 5-alpha-reductase enzyme and
            decreases the amount of active testosterone near the follicle, thereby preventing alopecia</b>.
        </p>

        <ImageBreaker image={Images.categories.finasteride} to={buyNowUrl} buttonText="BUY_NOW"/>
    </AccordionCard>
]
