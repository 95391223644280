import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { getDiscount, getLanguageTranslation, getPrice, truncChars } from '../../../utils'
import { withRouter } from 'react-router'
import LText from '../../core/LText'
import TherapyPrice from '../../../screens/Therapy/Components/TherapyPrice'
import TherapyRating from '../../../screens/Therapy/Components/TherapyRating'
import { EMI_COEFFICIENT, Images } from '../../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import Carousel from 'react-bootstrap/Carousel'
import Image from '../../core/Image'
import { NavLink } from 'react-router-dom'
import ImageService from '../../../services/ImageService'

const FeaturedProductCard = function ({ product, handleAddToCart, handleOpenProductDetails, autoScrollImages, match }) {
  const [direction, setDirection] = useState(null)
  const [index, setIndex] = useState(0)
  let price, salePrice, discount

  const language = match.params.language

  if (product.is_single_product || product.available_packs.length === 0) {
    price = getPrice(product.price)
    salePrice = product.sale_price ? getPrice(product.sale_price) : null
    discount = getDiscount(price, salePrice)
  } else {
    const { price: productPrice, sale_price: productSalePrice } = product.available_packs[product.available_packs.length - 1].product
    price = getPrice(productPrice)
    salePrice = getPrice(productSalePrice)
    salePrice = salePrice || price
    discount = getDiscount(price, salePrice)
  }

  const productImages = [...product.images]

  const handleSelect = (selectedIndex, e) => {
    if (e) setDirection(e.direction)
    setIndex(selectedIndex)
  }

  const finalProductPrice = salePrice || price
  const emiPrice = parseInt(finalProductPrice / EMI_COEFFICIENT)

  return (
    <div className="featured-product-card">
      <div className="row">
        <div className="col-md-6">
          <Carousel
            activeIndex={index}
            direction={direction}
            onSelect={handleSelect}
            interval={autoScrollImages ? 5000 : 99999999}
            controls={productImages.length > 1}
            indicators={productImages.length > 1}
            nextIcon={<FontAwesomeIcon icon={faAngleRight} color={'#888'} size="lg"/>}
            prevIcon={<FontAwesomeIcon icon={faAngleLeft} color={'#888'} size="lg"/>}
          >
            {
              productImages.map(
                (img, index) => (
                  <Carousel.Item key={index}>
                    <div className="featured-product-card-image" onClick={handleOpenProductDetails}>
                      <Image src={img} alt={product.title}/>
                    </div>
                  </Carousel.Item>
                )
              )
            }
          </Carousel>
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <div className="shop-product-card-content">
            <div className="shop-product-card-head" onClick={handleOpenProductDetails}>
              <h4 className="shop-product-card-title">{getLanguageTranslation(product, 'title', language)}</h4>
              <p className="shop-product-card-text py-3">
                {getLanguageTranslation(product, 'short_description', language)}
              </p>
              <div className="d-flex align-items-center mb-2">
                {!product.is_single_product && <small>Starts from</small>}
                <TherapyPrice discount={discount} price={price} salePrice={salePrice}/>
              </div>
              <div className="mb-2"><small><strong>EMI</strong> starts at <strong>₹{emiPrice}</strong>/month</small></div>
            </div>
            {
              !!product.ratings_count &&
              <TherapyRating rating={product.ratings_star_count / product.ratings_count}
                             ratingCount={product.ratings_count}/>
            }
            <div className="solution-page-section-trust-marker">
              <img src={ImageService.home.badge} alt="Badge"/> Trusted by <strong>25,000+ Indian Men</strong>
            </div>
            <div className="solution-page-section-trust-marker">
              <img src={ImageService.home.badge} alt="Badge"/> Recommended by doctors
            </div>
            {
              !!product.trust_marker &&
              <div className="solution-page-section-trust-marker">
                <img src={ImageService.home.badge} alt="Badge"/> {product.trust_marker}
              </div>
            }
            <div className="d-flex mt-5">
              <NavLink className="btn-m shop-product-card-button shop-product-card-detail-button"
                       to={product.is_single_product ? `${language}/therapy/${product.product_id}` : `${language}/solutions/${product.product_id}`}>
                <LText k="SHOW_DETAILS"/>
              </NavLink>
              <button className="btn-m btn-m-dark shop-product-card-button" onClick={handleAddToCart}>
                <LText k="ADD_TO_CART"/>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

FeaturedProductCard.propTypes = {
  match: PropTypes.object,
  product: PropTypes.object,
  handleAddToCart: PropTypes.func,
  handleOpenProductDetails: PropTypes.func,
  autoScrollImages: PropTypes.bool
}

FeaturedProductCard.defaultProps = {
  product: {}
}
export default withRouter(FeaturedProductCard)
