import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../components/core/Page'
import { withRouter } from 'react-router'
import api from '../../actions/api'
import { bindActionCreators } from 'redux'
import { getPhoneNumberWithCountryCode } from '../../utils'
import { userLogin, userLoginOtp } from '../../actions/accounts/profile'
import { Images, PHONE_NUMBER, RAZORPAY_APP_KEY } from '../../constants'

const handleRazorpayPayment = ({ name, email, contact, onSuccess }) => {
  const options = {
    key: RAZORPAY_APP_KEY,
    amount: '100000', // Amount is in currency subunits. Default currency is INR. Hence, 100000 refers to 100000 paise
    currency: 'INR',
    name: 'Misters.in',
    description: '',
    image: `https://misters.in${Images.logo.x2}`,
    handler: (response) => {
      if (response.razorpay_payment_id) {
        onSuccess(response)
      }
    },
    prefill: {
      name: name,
      contact: getPhoneNumberWithCountryCode(contact),
      email: email
    },
    notes: {},
    theme: {
      color: '#FF4B53'
    }
  }
  const rzp1 = new Razorpay(options)
  rzp1.open()
}

const ConferenceRegistration = ({ match, history, profile, ...props }) => {
  const [showOTPField, setShowOTPField] = useState(false)
  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  console.warn(RAZORPAY_APP_KEY)
  const [data, setData] = useState({
    user_type: 'contributor',
    name: '',
    phone_number: '',
    email_id: '',
    name_of_practice: '',
    contribution: '',
    otp: '',
    contribution_file: undefined
  })
  const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email

  const sendOTP = () => {
    props.userLoginOtp({ phone_number: getPhoneNumberWithCountryCode(data.phone_number) })
  }

  const handleSubmit = async () => {
    if (!isAuthenticated && !showOTPField) {
      setShowOTPField(true)
      sendOTP()
    } else {
      if (!isAuthenticated) {
        await props.userLogin({ phone_number: getPhoneNumberWithCountryCode(data.phone_number), otp: data.otp })
      }
      handleRazorpayPayment({
        contact: data.phone_number,
        name: data.name,
        email: data.email_id,
        onSuccess: ({ razorpay_payment_id, razorpay_order_id = '', razorpay_signature = '' }) => {
          api.campaigns.conferenceRegistration({
            ...data,
            razorpay_payment_id: razorpay_payment_id,
            razorpay_order_id: razorpay_order_id,
            razorpay_signature: razorpay_signature
          }).then(
            res => {
              if (res.status === 201) {
                setRegistrationSuccess(true)
              }
            }
          )
        }
      })
    }
  }

  return (
    <Page showNavbar={false} showFooter={false} meta={{
      path: `${match.params.language}/pages/misters-conference/`,
      title: 'Misters Registration',
      description: 'Join India\'s leading men\'s sexual health business'
    }}>
      <div className="container">
        <h1 className="text-center">All India Sexologists Conference</h1>

        <div className="row">
          <div className="col-sm-10 offset-sm-2 col-md-8 offset-md-2 col-12 py-5">
            {
              registrationSuccess &&
              <p className="text-center">
                Thank you! You have registered for the conference. Details will be sent to you on WhatsApp. And you will
                be receiving the free products from Misters within the next two weeks.
              </p>
            }
            {
              !registrationSuccess &&
                <div>
                <div className="row">
                  <div className="col-sm-6 pb-2">
                    Participate as
                  </div>
                  <div className="col-sm-6 pb-2">
                    <select name="type" id="type" value={data.user_type}
                            onChange={e => setData({ ...data, user_type: e.target.value })}>
                      <option value="participant">participant</option>
                      <option value="contributor">contributor</option>
                    </select>
                    <div style={{ fontSize: 12, paddingTop: 10 }}>
                      Choose contributor if you would like to share an entry that is featured in the AIMSWC Monthly, get a certificate of contribution and a copy of AIMSWC Monthly.
                      <br/>
                      <br/>
                      Choose participant if you would like to attend conference and get a certificate of participation and a copy of AIMSWC Monthly.
                    </div>
                  </div>
                </div>
                  <div className="row">
                    <div className="col-sm-6 pb-2">
                      Name of participant/contributor
                    </div>
                    <div className="col-sm-6 pb-2">
                      <input type="text" className="form-control" placeholder="Name" value={data.name}
                             onChange={e => setData({ ...data, name: e.target.value })}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 pb-2">
                      Email ID
                    </div>
                    <div className="col-sm-6 pb-2">
                      <input type="email" className="form-control" placeholder="Email Id"
                             value={data.email_id}
                             onChange={e => setData({ ...data, email_id: e.target.value })}/>
                    </div>
                  </div>
                  {
                    data.user_type === 'contributor' &&
                    <>
                      <div className="row">
                        <div className="col-sm-6 pb-2">Name of practice/clinic</div>
                        <div className="col-sm-6 pb-2">
                          <input type="text" className="form-control" placeholder="Name of practice/clinic"
                                 value={data.name_of_practice}
                                 onChange={e => setData({ ...data, name_of_practice: e.target.value })}/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 pb-2">Contribution</div>
                        <div className="col-sm-6 pb-2">
                          <textarea name="contribution" id="" cols="30" rows="10" value={data.contribution}
                                    placeholder="you can enter text here, or upload a file"
                                    onChange={e => setData({ ...data, contribution: e.target.value })}/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 pb-2">Contribution file</div>
                        <div className="col-sm-6 pb-2">
                          <input type="file" className="form-control" placeholder="Upload file (video)"
                                 onChange={e => setData({ ...data, contribution_file: e.target.files[0] })}/>
                        </div>
                      </div>
                    </>
                  }
                  <div className="row">
                    <div className="col-sm-6 pb-2">Registration fee</div>
                    <div className="col-sm-6 pb-2">
                      <strong style={{ fontSize: 14 }}>Rs.1,000</strong> <br/>
                      <span style={{ fontSize: 11 }}>
                      (you will receive 5 units of Misters Josh, equivalent to Rs.1,000 in trade
                    price, and worth Rs. 3,995 in MRP, making your registration practically free!)
                      </span>
                    </div>
                    <div className="col-12 pb-2">
                      <span style={{ fontSize: 11 }}>
                        Call us at <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a> for any questions.
                      </span>
                    </div>
                  </div>
                  {
                    !isAuthenticated &&
                    <div className="row">
                      <div className="col-sm-6 pb-2">Phone Number</div>
                      <div className="col-sm-6 pb-2">
                        <input type="text" className="form-control" placeholder="Your 10 digit Phone Number"
                               value={data.phone_number}
                               onChange={e => setData({ ...data, phone_number: e.target.value })}/>
                      </div>
                    </div>
                  }
                  {
                    showOTPField &&
                    <div className="row">
                      <div className="col-sm-6 pb-2">OTP</div>
                      <div className="col-sm-6 pb-2">
                        <input type="text" className="form-control" placeholder="Enter OTP"
                               value={data.otp}
                               onChange={e => setData({ ...data, otp: e.target.value })}/>
                      </div>
                    </div>
                  }
                  <div className="row">
                    <div className="col-12 text-center">
                      <button className="btn btn-primary btn-lg" onClick={handleSubmit}>
                        {(showOTPField || isAuthenticated) ? 'Submit' : 'Send OTP'}
                      </button>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </Page>
  )
}

ConferenceRegistration.propTypes = {
  match: PropTypes.object
}

const mapStateToProps = (state) => ({
  profile: state.accounts.profile
})

const matchDispatchToProps = dispatch => bindActionCreators({
  userLoginOtp,
  userLogin
}, dispatch)

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(ConferenceRegistration))
