import React from 'react'
import { otherAssets } from '../../../constants'

export default class Consent extends React.PureComponent {
  render () {
    return (
            <div className="consent-message">
                By continuing, you are agreeing to our
                <br/><a href={otherAssets.termsAndCondition} target="_blank">Terms of Use</a> and
                <a href={otherAssets.privacyPolicy} target="_blank">Privacy Policy</a>
            </div>
    )
  }
}
