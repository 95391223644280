import {

  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_RESULT,
  CREATE_SUBSCRIPTION_ERROR,
  CREATE_SUBSCRIPTION_RETRY

} from '../../actions/subscriptions/subscriptions'

const initialState = {
  list: [],
  detail: {},
  isFetching: false,
  isCreating: false,
  isDeleting: false,
  isUpdating: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SUBSCRIPTION:
      return { ...state, isCreating: true, error: false, message: null, retry: false }
    case CREATE_SUBSCRIPTION_RESULT:
      return { ...state, isCreating: false, detail: action.data }
    case CREATE_SUBSCRIPTION_ERROR:
      return { ...state, isCreating: false, error: true, message: action.message }
    case CREATE_SUBSCRIPTION_RETRY:
      return { ...state, isCreating: false, retry: true, message: action.message }

    default:
      return { ...state }
  }
}

export default reducer
