import { applyMiddleware, createStore } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'

import reducers from './reducers'
import { DEBUG, CONFIG } from './constants'

let middlewares

if (DEBUG !== 'true' || CONFIG === 'server') {
  middlewares = applyMiddleware(thunk)
} else {
  // middlewares = applyMiddleware(thunk);
  middlewares = applyMiddleware(logger, thunk)
}

let preloadedState = {}

if (typeof window !== 'undefined') {
  preloadedState = window.__INITIAL_STATE__
}

export default createStore(reducers, preloadedState, middlewares)
