import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../components/core/Page'
import { fetchFeedback } from '../../actions/report/feedback'
import { getSearchParams, interpolate, scrollToTop } from '../../utils'
import {
  FEEDBACK_BOT_FORM_ID, FEEDBACK_FIELD_ID, Images
} from '../../constants'
import AuthenticatedContainer from '../../components/containers/AuthenticatedContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import ProgressGraph from '../../components/graphs/ProgressGraph'
import HomeCard from '../../components/cards/HomeCard'
import { assignBotFieldUser, fetchBot, fetchBotBack, reassignBotFieldUser, updateBot } from '../../actions/bot/bot'
import { anonymousLogin } from '../../actions/accounts/profile'
import Button from '../../components/buttons/Button'
import { languageUrls, names } from '../../languages'

class Feedback extends PureComponent {
    static propTypes = {
      fetchFeedback: PropTypes.func,
      bot: PropTypes.object,
      profile: PropTypes.object,
      fetchBot: PropTypes.func,
      updateBot: PropTypes.func,
      fetchBotBack: PropTypes.func,
      reassignBotFieldUser: PropTypes.func,
      assignBotFieldUser: PropTypes.func,
      anonymousLogin: PropTypes.func
    };

    state = {
      quizStarted: false,
      quizEnding: false,
      language: ''
    };

    componentDidMount () {
      const { history, profile } = this.props
      const params = getSearchParams(history.location.search)

      if (params.id) {
        this.props.fetchFeedback({ feedback_id: params.id })
      }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { history, match, bot } = this.props

      if (!bot.isUpdating && prevProps.bot.isUpdating && !bot.error && !bot.retry) {
        const data = this.getItem()
        const { extra_data = {} } = data
        if (extra_data.report_id) {
          history.push(`${match.params.language}/reports/?id=${extra_data.report_id}&step_name=report`)
        } else if (extra_data.feedback_id) {
          history.push(`${match.params.language}/feedback/?id=${extra_data.feedback_id}`)
        }
      }
    }

    handleLoginSuccess = () => {
      this.handleStartQuiz()
    };

    handleStartQuiz = () => {
      const { match } = this.props
      const { type } = match.params
      const querySearch = getSearchParams(this.props.location.search)

      const feedbackFormId = querySearch.bot_form_id || FEEDBACK_BOT_FORM_ID
      const fieldId = querySearch.field_id || FEEDBACK_FIELD_ID

      this.props.assignBotFieldUser({ field_id: fieldId, bot_form_id: feedbackFormId })
    };

    getItem = () => {
      const { data } = this.props.bot
      let item = null
      if (Object.keys(data).length > 0) {
        item = data
      }
      return item
    };

    getProgress = () => {
      const item = this.getItem()
      if (item && [FEEDBACK_FIELD_ID].indexOf(item.bot_field.field_id) !== -1) {
        return 0
      }
      return item ? item.bot_field.progress : 0
    };

    handleSendClick = async (responseId, data) => {
      setTimeout(() => {
        const { match } = this.props
        const { type } = match.params
        const item = this.getItem()
        const dataLayerData = {
          event: `quiz_${type}`,
          step_name: item.bot_field.field_id,
          value: JSON.stringify(data)
        }
        this.props.updateBot({ id: responseId, ...data })
        try { dataLayer.push(dataLayerData) } catch (e) {}
        sessionStorage.setItem('ga_quiz_event', `quiz_${type}`)
        scrollToTop(700)
      }, 50)
    };

    handleFetchBotBack = (e) => {
      e.preventDefault()
      this.props.fetchBotBack()
    };

    renderQuiz = () => {
      const { match } = this.props
      const item = this.getItem()
      const progress = interpolate(this.getProgress(), 0, 100, 10, 100)

      return (
            <AuthenticatedContainer handleLoginSuccess={this.handleLoginSuccess}>
                <Page showFooter={false} showNavbar={false} meta={{
                  path: `${match.params.language}/feedback/`,
                  title: `${parseInt(progress)}% completed | Misters.in - Men's sexual health`,
                  description: 'Misters is a Gurgaon-based health and confidence start-up catering to men’s sexual health and wellness using ayurvedic, allopathic and plant-based methods. In collaboration with medical experts, reliable information on erections, ejaculation, sexual desire and hair care awaits you here. Simple, personalized and result-oriented care amalgamating traditional knowledge and the best science has to offer is Misters’ forte. More than a website and company, Misters is a community. Let your journey commence, bon voyage!'
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center pt-3">
                                {
                                    item &&
                                    progress !== 0 &&
                                    <a href="#" className='quiz-go-back' onClick={this.handleFetchBotBack}>
                                        <FontAwesomeIcon icon={faChevronLeft} size={'sm'}/>
                                    </a>
                                }
                                <img className="quiz-logo" src={Images.logo.big1x} alt=""/>
                            </div>
                        </div>
                        {
                            item &&
                            <div className='quiz-progress-container'>
                                <div className="quiz-progress-text">{parseInt(progress)}% completed</div>
                                <div className="quiz-progress">
                                    <ProgressGraph progress={progress}/>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-12">
                                <div className='d-flex flex-column pt-2 quiz'
                                     style={{ minHeight: '70vh' }}>
                                    {
                                        item &&
                                        <HomeCard key={item.id}
                                                  data={item}
                                                  progress={progress}
                                                  handleSendClick={this.handleSendClick}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
            </AuthenticatedContainer>
      )
    };

    renderStart = () => {
      const { match } = this.props
      return (
            <Page meta={{
              path: `${match.params.language}/feedback/`,
              title: 'Start-up Misiters, Erectile dysfunction, P.E. causes and treatments',
              description: 'Misters is a Gurgaon-based health and confidence start-up catering to men’s sexual health and wellness using ayurvedic, allopathic and plant-based methods. In collaboration with medical experts, reliable information on erections, ejaculation, sexual desire and hair care awaits you here. Simple, personalized and result-oriented care amalgamating traditional knowledge and the best science has to offer is Misters’ forte. More than a website and company, Misters is a community. Let your journey commence, bon voyage!'
            }}>
                <div className="container min-vh-100">
                    <div className="py-3 py-md-5">
                        <div className="text-center mb-3">
                            <img src={Images.feedback.feedbackStart} alt="" style={{ width: '100%', maxWidth: 400 }}/>
                        </div>
                        <h4 className="text-center mb-3 mb-md-5">
                            Hello Mister! Please select your preferred language to proceed
                            <br/>
                            <br/>
                            हेलो मिस्टर! आगे बढ़ने के लिए कृपया अपनी भाषा चुनें
                        </h4>
                        <div className="text-center">
                            <Button className="mr-4" onClick={() => this.handleLanguage(names.english)}>English</Button>
                            <Button onClick={() => this.handleLanguage(names.hindi)}>हिन्दी</Button>
                        </div>
                    </div>
                </div>
            </Page>
      )
    };

    renderEnd = () => {
      const { match } = this.props
      const languageName = languageUrls[this.props.match.params.language]
      return (
            <Page meta={{
              path: `${match.params.language}/feedback/`,
              title: 'Start-up Misiters, Erectile dysfunction, P.E. causes and treatments',
              description: 'Misters is a Gurgaon-based health and confidence start-up catering to men’s sexual health and wellness using ayurvedic, allopathic and plant-based methods. In collaboration with medical experts, reliable information on erections, ejaculation, sexual desire and hair care awaits you here. Simple, personalized and result-oriented care amalgamating traditional knowledge and the best science has to offer is Misters’ forte. More than a website and company, Misters is a community. Let your journey commence, bon voyage!'
            }}>
                <div className="container min-vh-100">
                    <div className="py-3 py-md-5">
                        {
                            languageName === names.hindi
                              ? <div className="text-center">
                                    <h3>
                                        हमारे फेसबुक पेज पर हमें रेट करने के लिए कृपया यहां क्लिक करें
                                    </h3>
                                    <a className="btn-m btn-m-primary" target="_blank" rel="noreferrer"
                                       href="https://www.facebook.com/mistersdotin/">
                                        फेसबुक
                                    </a>
                                </div>
                              : <div className="text-center">
                                    <h3>
                                        Please click here to rate us on our Facebook page
                                    </h3>

                                    <a className="btn-m btn-m-primary" target="_blank" rel="noreferrer"
                                       href="https://www.facebook.com/mistersdotin/">
                                        Facebook
                                    </a>
                                </div>
                        }
                        <div className="text-center my-3">
                            <img src={Images.feedback.feedbackEnd} alt="" style={{ width: '100%', maxWidth: 400 }}/>
                        </div>
                        <div className="text-center">
                            {
                                languageName === names.hindi
                                  ? <h4>धन्यवाद् मिस्टर!</h4>
                                  : <h4>Thank you Mister!</h4>
                            }
                        </div>
                    </div>
                </div>
            </Page>
      )
    };

    handleLanguage = (lang) => {
      const { history, match } = this.props
      this.setState({ quizStarted: true })
      const url = `/${lang.slice(0, 2)}${match.url.slice(3, match.url.length)}${history.location.search}`
      // console.warn(url);
      history.push(url)
    };

    render () {
      const { history } = this.props
      const params = getSearchParams(history.location.search)
      const { quizStarted } = this.state

      if (!quizStarted && !params.id) {
        return this.renderStart()
      }

      if (quizStarted && !params.id) {
        return this.renderQuiz()
      }

      if (params.id) {
        return this.renderEnd()
      }
    }
}

const mapStateToProps = (state) => ({
  feedback: state.report.feedback,
  profile: state.accounts.profile,
  bot: state.bot.bot
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchFeedback,
  fetchBot,
  fetchBotBack,
  updateBot,
  reassignBotFieldUser,
  assignBotFieldUser,
  anonymousLogin
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Feedback)
