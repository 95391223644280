// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../../Category/common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import LText from '../../../components/core/LText/LText'
import Button from '../../../components/buttons/Button'
import { openLinkOnNewTab } from '../../../utils'

const shopUrl = '/therapy/ayurhairpack'
const buttonText = 'BUY_NOW'

export const accordionList1 = [
    <AccordionCard key={'0'} title="What is Androgenic Alopecia?">
        <p>
            Male Pattern Baldness or Androgenic Alopecia is a progressive hair loss disorder found in men. It is a
            polygenic condition with varying severity, age of onset, and scalp location of hair loss. Having a family
            history of baldness is a definitive marker that you’re susceptible to male pattern hair loss/baldness.
            <br/>
            <br/>
            Androgenetic alopecia is the most common form of hair loss in men which affects 30%, 40% and 50% of men at
            around 30, 40, and 50 years of age, respectively. Hair fall or alopecia itself isn’t life-threatening but it
            significantly affects the quality of life of patients, mostly those who are in their youthful years.
            <br/>
            <br/>
            In androgenetic alopecia, the duration of anagen decreases with each cycle, while the length of telogen
            remains constant or is prolonged; this results in a reduction of the anagen to telogen ratio. Balding
            patients often describe periods of excessive hair shedding, most noticeable while combing or washing. This
            is due to the relative increase in numbers of follicles in telogen. As the hair growth rate remains
            relatively constant, the duration of anagen growth determines hair length. Thus, with each successively
            foreshortened hair cycle, the length of each hair shaft is reduced. Ultimately, anagen duration becomes so
            short that the growing hair fails to achieve sufficient length to reach the surface of the skin, leaving an
            empty follicular pore. Prolongation of the catagen phase, the lag phase or the delayed replacement of
            telogen hair, seems to last longer in male pattern baldness leaving a higher percentage of empty hair
            follicles contributing to balding. Further, the catagen (latent phase) is prolonged in male pattern
            baldness, reducing hair numbers and contributing to the balding process.
            <br/>
            <br/>
            Androgenetic alopecia in men usually has a predisposing family history of this condition. Hair has always
            been an important part of self-image. Studies show that men suffering from male pattern hair loss have a 75%
            lower self-esteem while socializing compared to men who are not affected by this condition. Another study
            reported that young men with male pattern hair loss have a higher anxiety level and lower self-esteem when
            compared with men without male pattern hair loss. This psychology also impacts the general nature of an
            individual, making them more introverted and harbouring a feeling of unattractiveness inside them.
            <br/>
            <br/>
            Early diagnosis and seeking medical health, especially for patients with family history reported having
            psychological benefits with improvement in self-esteem and personal attractiveness.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={buttonText}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the causes for Androgenic Alopecia?">
        <p>
            <strong>Hair miniaturization:</strong> Miniaturization is the hormone-driven biological process in which
            hair shrinks in size over time, eventually leaving a bald scalp. In genetically-susceptible hair follicles,
            a hormone called dihydrotestosterone (DHT) can cause the growth phase of the hair cycle to become
            progressively shorter. The individual hair produced by these follicles are unable to grow to full size due
            to this shorter growth window so they decrease in size (diameter and length) over time until they eventually
            disappear. This process of “miniaturization” is the main mechanism in androgenetic alopecia (genetic
            balding).
            <br/>
            <br/>
            Normal scalp hair grows in follicular units made up of one to four terminal (full thickness) hairs. The
            terminal hairs in any single DHT-susceptible follicular unit might be in varying stages of miniaturization,
            and thus be of varying diameters. When enough terminal hair is in a state of miniaturization, there appears
            to be a visible thinning of the hair in affected areas. As more hair in each follicular unit becomes
            miniaturized, the process may lead to complete baldness in that area of the scalp.
            <br/>
            <br/>
            Since it is a genetic condition and cannot be dealt with, yet, taking into consideration the family history,
            men should start to take care of their hair by the age of about 20-30 years. As this period is also
            considered to be stressful due to the change in the dynamics of life, achieving adulthood.
        </p>
        <ImageBreaker image={Images.ingredient.hair_growth_cycle_2} to={shopUrl} buttonText={buttonText}/>
        <p>
            <strong>Stress</strong> is a major factor contributing to hair loss and can progressively increase baldness
            among men.
            <br/>
            <br/>
            <strong>Scalp inflammation:</strong> Excessive oil secretion, dandruff and not keeping the scalp clean leads
            to the growth of bacteria and fungi on the scalp which causes an inflammatory reaction against the microbe.
            In the process, the killer cells of the body also affect the hair follicle. Scalp inflammation due to fungal
            or bacterial infection can also cause hair fall and should be dealt with in men with susceptible male
            pattern baldness. The scalp is more susceptible to bacterial and fungal infections. Major bacterial species
            found on scalp that pose an infection includes- Streptococcal species, P. acnes and S. epidermidis and
            fungal profiling lead to an identification of M. restricta as a major species identified. Also taking into
            consideration the adulterated, processed food and eating habits in combination with the polluted
            environment. These contribute to bad skin and scalp health making it more susceptible to scalp infections.
            <br/>
            <br/>
            <strong>Nutritional deficiency:</strong> A major factor that affects both hair structure and hair growth.
            Studies have suggested a potential association in nutritional deficiency and androgenic alopecia. Major
            nutrients that are involved in good hair health include:
        </p>
        <ul>
            <li>Iron</li>
            <li>Sulphur</li>
            <li>Zinc</li>
            <li>Niacin (Vitamin B3)</li>
            <li>Fatty Acids</li>
            <li>Selenium</li>
            <li>Vitamin D, A, E</li>
            <li>Folic Acid</li>
            <li>Biotin</li>
            <li>Amino Acids and proteins</li>
        </ul>
        <p>
            Nutritional deficiency generally arises from other genetic factors, other co-morbidities and medical
            conditions or bad dietary practices.
            <br/>
            <br/>
            Oxidative stress also affects scalp health. Oxidative stress is the inability of the body to sufficiently
            counteract sources of oxidative damage. Specific dermatological conditions, such as dandruff and seborrheic
            dermatitis, psoriasis, and atopic dermatitis are sources for oxidative stress on skin and scalp. Oxidative
            stress affects hair before its emergence and affects premature hair loss.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={buttonText}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'2'} title="How does the Misters Hair Growth Pack work?">
        <p>
            There are various plant extracts used in a different part of the world for hair care and numbers of herbal
            products acclaimed with hair growth-promoting activity. All over the world, many polyherbal mixtures are
            employed as a hair tonic, hair growth promoter, hair conditioner, hair cleansing agent, anti-dandruff
            agents, as well as for the treatment of alopecia and lice infection. Mister’s hair growth pack is such a
            product that features an amazing blend of 17 medicinal plants that aid in hair loss, promotes hair growth
            and slows the progression of male pattern baldness by-
        </p>
        <ol type="I">
            <li>Providing appropriate nutrition</li>
            <li>Antimicrobial, Anti-dandruff and cleansing affect the scalp</li>
            <li>Hair growth-promoting effect</li>
            <li>Anti-oxidation effect</li>
            <li>Effect on anagen phase</li>
        </ol>
        <p>
            Taking into consideration the years of knowledge from Ayurveda, Mister’s formulated this hair mask that
            contains; Arnica, Amla, Reetha, Neem, Hibiscus, Bhringraj, Brahmi, Ginger, Nagarmoth, Fenugreek, Curry
            Leaves, Shikakai, Kapur Kachi, Mentha, Lemongrass, Seena and Shilajit. Each of these medicinal plants is a
            rich source of vitamins, minerals and certain metals. Thus, in combination, they become a major source with
            high nutritive value for the scalp conditioning.
            <br/>
            <br/>
            Amongst these Shilajit, is a major source of fulvic acid, zinc, sulphur, magnesium, iron and vitamins.
            Another major source of these nutrients is Brahmi. Brahmi is loaded with Calcium, Zinc, Potassium,
            Magnesium, Selenium and Vitamins. Curry leaf is also an abundant source of Vitamin C, Phosphorus, Iron,
            Calcium, Nicotinic Acid, Beta Carotene, Vitamin B6 and proteins. Pudina is another major source of carotene,
            which is an important component of hair. Reetha is a major source of iron. Vitamin E which is important for
            skin and scalp health comes from neem as neem is a major source for it. Reetha is also a major source of
            Vitamin A, D, K, E, fatty acids, mucilage and saponins.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={buttonText}/></Button>
        </div>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="Which individual therapeutic actions do these ingredients possess?">
        <p>
            <strong>Antimicrobial Effect-</strong> Arnica, especially thymol derivatives present in the plant, has been
            reported to be active against Streptococcus species of bacteria and various fungal species. Thymol
            derivatives mainly include 10-isobutyryl oxy-8, 9-dihydro-thymol and Thymol methyl ether, which were found
            to have an antimicrobial effect. Amla is another major ingredient which is abundant in polar and non-polar
            flavonoids, and tannins which show significant antibacterial activity against S. aureus, E. coli, S. typhi
            and other strains.
        </p>
        <ImageBreaker image={Images.ingredient.hair_mask_arnica} to={shopUrl} buttonText={buttonText}/>
        <p>
            Bhringraj extracts were found to be active against on a variety of strains of bacteria that include
            Staphylococcus aureus, Escherichia coli, Staphylococcus epidermidis, Pseudomonas aeruginosa, Klebsiella
            pneumoniae, Proteus mirabilis, and Proteus vulgaris and funguses like Candida albicans and Aspergillus
            fumigatus. Neem extract has antibacterial activity against strain of foodborne bacteria, suggesting that
            constituents of neem have significant antibacterial activity. Neem extract has also shown an antifungal
            effect on aspergillus, Rhizopus, C. lunata, H. pennisetti, and C. gloeosporioides f. sp. Mangiferae fungus.
            Extracts of hibiscus were found to be active against bacteria pathogenic to human [Gram positive
            (Corynebacterium diphtheria, staphylococcus aureus, staphylococcus capitis), and Gram-negative (Pseudomonas
            aeruginosa and proteus mirabilis) and the antifungal effect was evaluated on candida albicans and
            Aspergillus niger.
            <br/>
            <br/>
            Lemongrass oil was evaluated against 22 strains of bacteria and 12 types of fungus. Lemongrass inhibited the
            growth of all bacteria and fungi. Furthermore, eugenol, a constituent of lemongrass, was found to be the
            most potent antifungal agent. Significant antimicrobial activity of Brahmi was observed on acillus
            amyloliquef aciens (MTCC 1270), Streptococcus pyogenes (MTCC 1923), Vulgarica, Bacillus megaterium (MTCC
            3353), Aspergillus niger (MTCC 281), Bacillus pumilus, Salmonella typhi, Bacillus subtilis, and Micrococcus
            luteus. Essential oils from ginger, including oleoresin, were found to have significant antibacterial and
            antifungal activity on various strains when analyzed in-vitro.
            <br/>
            <br/>
            <strong>Antifungal Effect-</strong> Nagarmoth essential oil, α and β pinene being the active constituent, at
            0.2% was reported to have significant antimicrobial activity against Bacillus subtilis, Escherichia coli,
            Pseudomonas aeruginosa and Staphylococcus aureus, Candida parapsilosis, Aspergillus flavus, Aspergillus
            fumigatus and Fusarium oxysporum. Fenugreek was found to be a potent antimicrobial against two most
            pathogenic bacteria S. aureus and Pseudomonas aeruginosa. It has also been reported to have significant
            antifungal effects.
            <br/>
            <br/>
            Bioactive compounds like girinimbine, murrayanine, marmesin-1 -O-beta-D’galactopyranoside, mahanine,
            murrayacine, mukoeic acid, murrayazolinine, girinimbilol, pyrafoline-D, and murrafoline-I are present in
            Curry plant. Girinimbine, murrayanine, and marmesin-1 -O-beta-D’galactopyranoside have notable antifungal
            activity. Koenine, koenigine, and mahanine extracted from curry leaves exhibited activities with MIC values
            of 3.12–12.5 μg/mL against bacterial strains S. aureus and K. pneumonia. Shikakai is found to be active
            against many strains of both bacteria and fungi using modified agar diffusion cup method.
            <br/>
            <br/>
            Essential oil extracts from the rhizome of Kapur Kachi showed antimicrobial activity. Petroleum ether and
            chloroform extracts showed inhibitory activity against gram (+), gram (-) bacterial cultures, including a
            strain of methicillin and vancomycin-resistant Staphylococcus aureus and fungal cultures. Terpenoid
            compositions of the rhizome of Kapur Kachi also showed significant antimicrobial activity against
            Staphylococcus aureus, Shigella flexneri, Pasteurella multocida and Escherichia coli. Ethanol extract of
            fruits of Kapur Kachi was reported to possess antibacterial and antifungal properties against Salmonella
            species. Escherichia coli and filamentous fungi.
            <br/>
            <br/>
            Studies have shown that plants of the genus Mentha possess significant antimicrobial activities, mainly due
            to the presence of oxygenated monoterpenes in their chemical composition. The essential oil of M. longifolia
            has shown interesting antimicrobial activity against Escherichia coli, Salmonella typhimurium, Listeria
            monocytogenes, Aspergillus flavus, Botrytis cinerea, Fusarium oxysporum, Pseudomonas aeruginosa, Aspergillus
            niger, Trichophyton longifusus, Microsporum canis, and Mucor ramamnianus. One study on five flavonoids
            separated from M. longifolia extract showed that the quercetin-3-O-glucoside had the maximum antibacterial
            activity among the flavonoids tested. The essential oil of the plant showed fungistatic and fungicidal
            activity that was significantly higher than that of the costlier fungicide bifonazole. Menthol has been
            reported to be an antimicrobial and antifungal agent against ringworm and other fungal infestations of
            different kinds.
            <br/>
            <br/>
            Extracts from the dried pericarp of Reetha fruits were investigated for their antifungal activity against
            clinical isolates of yeasts Candida albicans and C. non-albicans from vaginal secretions of women with
            Vulvovaginal Candidiasis. Four clinical isolates of C. albicans, a single clinical isolated of each of the
            species C. parapsilosis, C. glabrata, C. tropicalis, and the strain of C. albicans ATCC 90028 were used. The
            hydroalcoholic extract was bioactivity-directed against a clinical isolate of C. parapsilosis and showed
            strong activity. The n-BuOH extract and one fraction showed strong activity against all isolates tested.
            <br/>
            <br/>
            Senna leaf extract was subjected to a variety of strains of bacteria and fungi and found to have a
            significant Antimicrobial activity. extract of Shilajit at the concentration of 5000µg/ml was having
            excellent inhibitory activity against Alternaria cajani (95.12 % spore inhibition)
            <br/>
            <br/>
            <strong>Anti Dandruff Effect-</strong> A randomized, double-blind, placebo-controlled, split-head evaluation
            of lemongrass essential oil was conducted on 30 individuals with level 3 dandruff on D-Squame® Scale. An
            application of 10% essential oil was found to be the most effective (81% reduction) by the 14th day.
            Fenugreek has lecithin as a major constituent which acts as a natural emollient, aids in strengthening and
            moisturization of hair, and it also has an anti-dandruff effect. Leaf and pods of Shikakai have a cleansing
            and anti-dandruff action on the scalp.
            <br/>
            <br/>
            <strong>Antioxidant Effect-</strong> DPPH (2,20 -diphenyl-1-picrylhydrazyl radical) free radical scavenging
            method and phosphomolybdate the method has been utilized to determine the antioxidant potential of the
            plant. At concentration of 5 mg/ml, A. montana shows 71.52% DPPH scavenging potential and 63.68% total
            antioxidant activity (phosphomolybdate method) which is mainly attributed due to the presence of ﬂavonoids
            and phenolic compounds DPPH (2,20 -diphenyl-1-picrylhydrazyl radical) free radical scavenging method and
            phosphomolybdate the method has been utilized to determine the antioxidant the potential of the plant.
        </p>
        <ImageBreaker image={Images.ingredient.hair_mask_antioxidant} to={shopUrl} buttonText={buttonText}/>
        <p>
            At concentration of 5 mg/ml, A. montana shows 71.52% DPPH scavenging potential and 63.68% total antioxidant
            activity (phosphomolybdate method) which is mainly attributed due to the presence of ﬂavonoids and phenolic
            compounds DPPH (2,20-diphenyl-1-picrylhydrazyl radical) free radical scavenging method and phosphomolybdate
            the method has been utilized to determine the antioxidant the potential of the plant. At concentration of 5
            mg/ml, A. montana shows 71.52% DPPH scavenging potential and 63.68% total antioxidant activity
            (phosphomolybdate method) which is mainly attributed due to the presence of ﬂavonoids and phenolic compounds
            DPPH ‬(2,2’-diphenyl-1-picrylhydrazyl ‬radical) free ‬radical ‬scavenging ‬method ‬and ‬phosphomolybdate
            method ‬have ‬been ‬utilized ‬to ‬determine ‬the ‬antioxidant potential ‬of ‬Arnica. At ‬a concentration ‬of
            ‬5 ‬mg/ml, Arnica ‬shows 71.52% ‬DPPH ‬scavenging ‬potential ‬and‬ 63.68% ‬total ‬antioxidant ‬activity
            ‬(phosphomolybdate method) ‬which ‬is ‬mainly ‬attributed due ‬to ‬the ‬presence ‬of ﬂavonoids ‬and
            ‬phenolic ‬compounds. Emblicanin A & B, Chebulinic acid, punigluconin and pedunculagin isolated from Amla
            were found to have significant antioxidant activity.
            <br/>
            <br/>
            Bhringraj extract was evaluated for its antioxidant activity both on in-vitro and ex-vivo models. It was
            found that extract was a powerful scavenger of DPPH and nitric oxidase radical and was found to inhibit
            lipid peroxidation when tested on rat’s liver. Luteolin, a constituent of Bhringraj, was found to have an
            effective antioxidant, anti-inflammatory and antiallergic effect.
            <br/>
            <br/>
            Neem has many antioxidant molecules making it a rich source of natural antioxidants. Azadirachtin and
            Nimbolide, isolated from neem, showed concentration-dependent antiradical scavenging activity and reductive
            potential in the following order: nimbolide > azadirachtin > ascorbate (standard).
            <br/>
            <br/>
            Lemongrass is also an abundant source of antioxidants. These include swertia japonica, isoorientin, and
            chlorogenic acid. In a study, lemongrass oil was found to demonstrate high antioxidant capabilities using
            the TEAC (Trolox Equivalent Antioxidant Capacity) assay technique at 44.06 mg Trolox per 100mL of essential
            oil. Bacosides isolated from Brahmi, is a major source of antioxidants. Based on animal studies, bacosides
            have significant antioxidant activity on the hippocampus, frontal cortex and striatum of the brain.
            <br/>
            <br/>
            Ginger extract as a whole and isolated 6-gingerol reported to have significant antioxidant activity on lipid
            peroxidation assay and UVB induced intracellular ROS in mice assay. Various natural bioactive compounds,
            such as mahanine, mahanimbine, isolongifolene, koenimbine, girinimbine, isomahanine, koenoline, and O-methyl
            murrayamine, are present in curry leaves and exhibit remarkable antioxidant properties. Terpenoid
            compositions of the rhizome of Kapur Kachi were found to possess antioxidant activity. The rhizome essential
            oils of Kapur Kachi were studied for their antioxidant activity by DPPH radical scavenging activity,
            reducing power, and effect on the chelating properties of Fe2+. The rhizome essential oil exhibited moderate
            to good Fe2+ chelating activity whereas the essential oil exhibited a completely different DPPH radical
            scavenging profile.
            <br/>
            <br/>
            Essential oils from pudina and pudina extract both have been reported to be a great source for antioxidants,
            using inhibition of free radical 2, 2-diphenyl-1-picrylhydrazyl (DPPH) and β carotene/linoleic acid systems.
            Phenolic compounds in pudina are correlated with antioxidant activity. Shilajit showed free radical
            scavenging & antioxidant effect against SO3-, OH radical and paramagnetic nitric oxide (NO) depending on the
            concentration of Shilajit. The antioxidant effects were concentration-dependent. Higher concentrations of
            processed Shilajit provided greater free radical protection.
            <br/>
            <br/>
            <strong>Anti-inflammatory Effect-</strong> Sesquiterpene lactones especially helenalin, phytoconstituent of
            arnica, when compared marketed anti-inflammatory drugs indomethacin and acetylsalicylic acid. These lactones
            were observed to significantly decrease NFKappaB- medication inflammation and also found to penetrate the
            skin easily. Arnica 6c, a formulation of arnica extract, is reported to significantly decrease inflammation
            and pain in animal models, rat, utilizing carrageenan and paw oedema techniques. A randomized, double-blind
            reported significant effect of arnica topical gel, rich in helenalin, 11,13-dihydrohelenalin and its esters,
            on pain and inflammation on osteoarthritis patients when compared to NSAIDs. This study indicated both
            anti-inflammatory and skin penetration of arnica and its compounds. Amla extract was evaluated for their
            anti-inflammatory effects on Sprague-Dawley rats by using cotton pellet and carrageenan methods. They were
            to significantly decrease inflammation and inflammatory factors involved.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={buttonText}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What is the role of Bhringraj in promoting hair health?">
        <p>
            Bhringraj extract was evaluated for its anti-inflammatory activity on paw oedema and carrageenan models of
            animal study, they were reported to have significantly reduced inflammation and inflammatory factors.
            Furthermore, α-amyrin from Bhringraj extract was isolated and was reported to have significant
            anti-inflammatory activity. Neem leaf extract (200mg/kg, p.o) was found to have a significant
            anti-inflammatory effect in cotton pellet granuloma assay in rats. Nimbin, a constituent of neem, was found
            to suppress the function of macrophages and neutrophil, an important component of the inflammatory process.
            Essential oil isolated from hibiscus exhibited excellent anti-inflammatory activity Lipopolysaccharide
            stimulated macrophage RAW 264.7 cells. 200 ug/mL of dose leads to 67.46% inhibition of nitric oxide and
            pro-inflammatory cytokine production.
        </p>
        <ImageBreaker image={Images.ingredient.hair_mask_anti} to={shopUrl} buttonText={buttonText}/>
        <p>
            Citral and geraniol are the two primary compounds found in lemongrass which are responsible for the
            anti-inflammatory effects by modulating cell activities. A study which supports this found that lemongrass
            can inhibit the release of an inflammation-inducing compound, myeloperoxidase, from white blood cells. In
            mice, it was found to suppress the production of two compounds in white blood cells macrophages that
            contribute to inflammatory reactions. Oleoresin, 6-gingerol, 8-gingerol, 10-gingerol, 6-shogaol, 6-hydro
            shogaol; isolated from ginger were analyzed for their anti-inflammatory activity. They were found to
            decrease the production of nitric oxide, an important inflammatory mediator. An anti-inflammatory effect was
            observed in an Animal study which shows that when rats treated with a single dose of fenugreek extract (100
            or 200 mg/kg). Inhibition of inflammatory swelling was 45% and 62% in the lower and higher dose groups,
            respectively, compared with 100% in untreated animals.
            <br/>
            <br/>
            A study evaluated the in vitro and in vivo efficacy of an extract of curry leaves rich in carbazole
            alkaloids against lipopolysaccharide (LPS)-induced inflammation in RAW 264.7 cells. The activity of
            inflammatory cytokines interleukin 1 beta (IL-1β), interleukin-6 (IL-6), tumour necrosis factor (TNF-α), and
            p65-NFκB was significantly reduced by the hydroalcoholic extract of M. koenigii. Also, the hydroalcoholic
            extract of curry leaves reduced the expression of nitrotyrosine (NT), myeloperoxidase (MPO), IL-1β,
            intercellular adhesion molecule 1 (ICAM-1), and cyclooxygenase (COX-2), and increased the expression of
            Nrf2. Another study evaluated the anti-inflammatory activity of an extract of curry leaves and its bioactive
            compound girinimbine against lipopolysaccharide/interferon-gamma-induced RAW 264.7 cells.
            <br/>
            <br/>
            The girinimbine showed reduced levels of NO overproduction and pro-inflammatory cytokine levels IL-1β and
            TNF-α in the peritoneal fluid. These findings strongly suggest that girinimbine could act as an
            anti-inflammatory agent by suppressing inflammation. Another study also confirmed the anti-inflammatory
            activity of curry leaf extract. Bioactive compounds like murrayakonine A, O-methyl murrayamine A, and
            mukolidine were reported for their efficiency in inhibiting TNF-α and IL-6 release in LPS-induced
            inflammation in human peripheral blood mononuclear cells (PBMCs).
            <br/>
            <br/>
            The benzene extract of rhizome of Kapur Kachi possessed significant analgesic activity in acetic
            acid-induced writhing in mice whereas 50% ethanol and hexane extracts of rhizome were found to possess
            significant anti-inflammatory activity in carrageenan-induced hind paw oedema in mice.
        </p>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What is the role of Reetha in promoting hair health?">
        <p>
            The anti-inflammatory activities of hederagenin and crude saponin isolated from Reetha were investigated
            utilizing carrageenan-induced oedema, granuloma pouch and adjuvant arthritis in rats. The effects of these
            agents on vascular permeability and acetic acid-induced writhing in mice were also examined. In some
            experiments, the results were compared with those obtained with saikogenin A, crude platycodin,
            platycodigenin and oleanolic acid. While hederagenin and the other agents used showed activity only when
            administered i. p. Hederagenin, 100 and 200 mg/kg p. o. per day for 7 days, showed no significant inhibitory
            effect on granuloma and exudate formations in rats, while crude saponin, 100 and 200 mg/kg p. o. showed
            significant effects. Crude saponin, 200 mg/kg p. o. per day for 21 days, significantly inhibited the
            development of hind paw oedema associated with adjuvant arthritis in rats, but hederagenin, 50-200 mg/kg p.
            o. did not. Crude saponin, 400 mg/kg p. o. inhibited the increase in vascular permeability and the number of
            writhing induced by acetic acid in mice. The results suggest that hederagenin and crude saponin, as well as
            the other agents used, show some degree of anti-inflammatory activity, especially in the case of saponin.
            <br/>
            <br/>
            Shilajit is a very powerful anti-inflammatory agent. It has been shown to reduce acute chemically induced
            oedema by 77%. The antioxidant properties also help to prevent inflammation. Orally administered Shilajit
            (50 mg/kg) induced significant anti-inflammatory activity against carrageenan-induced pedal oedema.
            <br/>
            <br/>
            <strong>Hair-Growth Promoting Effect-</strong> A study was conducted on 6 men, with male pattern baldness,
            for the efficacy of Arnica in combination with Aqua ammonia in the formulation for 2-6 months. The study
            reported a significant increase in hair growth, promoted hair follicle, reduced hair fall and strengthened
            hair. Amla oil was investigated for its beneficial effects on hair loss, it is reported that amla oil
            nourishes and conditions the scalp and promotes strong healthy and shiny hair. It was found to be working by
            inhibiting 5-α reductase. 1% leaf and flower extract of hibiscus were evaluated in vivo and in vitro for its
            hair growth-promoting activity on albino rat. The study concluded that hibiscus has potential for hair
            regrowth by acting on phases of growth cycle with leaf extract found to be more potent when compared to
            flower extract. Shikakai extract has been found to promote hair growth, also it’s used as a natural dye.
            <br/>
            <br/>
            The hexane extract of the rhizomes of Kapur Kachi yielded two known compounds, pentadecane, and ethyl
            p-methoxycinnamate. The crude extract, fractions and isolated compounds showed hair growth property. study
            investigated the effect of peppermint oil on hair growth in C57BL/6 mice. The animals were randomized into 4
            groups based on different topical applications: saline (SA), jojoba oil (JO), 3% minoxidil (MXD), and 3%
            peppermint oil (PEO). The hair growth effects of the 4-week topical applications were evaluated in terms of
            hair growth, histological analysis, the enzymatic activity of alkaline phosphatase (ALP), and gene
            expression of insulin-like growth factor-1 (IGF-1), known bio-markers for the enhanced hair growth. Of the 4
            experimental groups, the PEO group showed the most prominent hair growth effects; a significant increase in
            dermal thickness, follicle number, and follicle depth. ALP activity and IGF-1 expression also significantly
            increased in the PEO group.
            <br/>
            <br/>
            Reetha fruit is rich in vitamin A, D, E, K, saponin, sugars¸ fatty acids and
            mucilage. Reetha extract is useful for the promotion of hair growth and reduced dandruff. Extract of fruit
            coat acts as a natural shampoo, therefore is used in herbal shampoos in the form of hair cleanser. Reetha as
            soap nuts or washing nuts plays an important role as natural hair care products since olden times. This
            plant is enriched with saponins, which makes the hair healthy, shiny, and lustrous when used regularly.
            Senna is also called natural henna. Sennosides A and B are the principal constituents of Senna,
            traditionally used as a laxative. Seena is usually given with henna when used as a hair product. Henna seems
            to be enhancing Senna penetration in the skin. Senna has a cleansing and nourishing effect on hair thus
            promoting hair growth.
        </p>
        <p>
            <strong>Effect on Anagen Phase-</strong> Bhringraj in combination with hibiscus and jatamansi is a
            polyherbal oil was found to have excellent hair growth activity in Wister albino rat and a greater number of
            hair were recorded to be in anagen phase. Bhringraj extract was also tested on rats, that was incorporated
            in an oleaginous cream. The cream was found to be effective in promoting hair growth and it was found that a
            greater number of hair follicles were in anagen phase. Brahmi in combination with amla and Nagarmoth was
            formulated in herbal oil and when tested on rats, in comparison with standard 2% MINOXIDIL. It was reported
            that the formulation gave best results by showing an enlargement of follicular size and prolongation to
            anagen phase.
            <br/>
            <br/>
            A strong list of evidence for individual ingredients present in Misters.in Hair Pack for men and how they
            aid in hair loss and promote hair growth strengthen the claims of the products.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={buttonText}/></Button>
        </div>
    </AccordionCard>
]
