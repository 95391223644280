// External Imports
import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router'

import AccordionCard from '../Category/common/AccordionCard'
import { PHONE_NUMBER } from '../../constants'

const Section7 = ({ match }) => {
  return (
    <div className="home-page-section7">
      <div className="container">
        <div className="row py-5">
          <div className="col-12">
              <h2 className="home-page-subtitle text-center mb-5">
                <a href={`https://misters.in${match.params.language}/qa/`}>
                  FAQs
                </a>
              </h2>
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2">
                <AccordionCard title="What are your customer service hours" key="home-page-faq-1" arrow>
                  <p className="faqs-description">
                    We are available on call between 9AM to 8PM Everyday. Our customer care number is {PHONE_NUMBER}.
                  </p>
                </AccordionCard>
                <AccordionCard title="Are Misters.in products safe to consume?" key="home-page-faq-2" arrow>
                  <p className="faqs-description">
                    Absolutely! All our products are made with the highest quality ingredients at a GMP certified
                    facility.
                  </p>
                </AccordionCard>
                <AccordionCard title="Are there any side effects of the products?" key="home-page-faq-3" arrow>
                  <p className="faqs-description">
                    None, there are no side effects of all the Misters.in products. It is completely safe to consume.
                  </p>
                </AccordionCard>
                <AccordionCard title="What does Misters Shilajit Pro for Men do?" key="home-page-faq-4" arrow>
                  <p className="faqs-description">
                    Misters Shilajit Pro for Men is designed to significantly improve your strength and stamina, made
                    with the purest Himalayan Shilajit and standardised Ashwagandha, it is designed for high efficacy
                    and value for money.
                  </p>
                </AccordionCard>
                <AccordionCard title="Is it safe to consume Misters Shilajit Pro for Men with other medicines?"
                               key="home-page-faq-5" arrow>
                  <p className="faqs-description">
                    While Misters Shilajit Pro for Men is safe to consume and isn’t known to interact with other
                    medicines, if you have any medical conditions like diabetes, cardiovascular illness, blood pressure
                    issue and such, please consult your doctor before consuming this product.
                  </p>
                </AccordionCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Section7.propTypes = {
  match: PropTypes.object
}
export default withRouter(Section7)
