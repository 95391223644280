import React from 'react'
import * as PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

export default class Button extends React.Component {
    static propTypes = {
      variant: PropTypes.oneOf(['primary', 'secondary', 'transparent', 'dark', 'red', 'secondary-outline']),
      to: PropTypes.string,
      className: PropTypes.string,
      active: PropTypes.bool,
      block: PropTypes.bool
    };

    static defaultProps = {
      variant: 'primary',
      active: false,
      className: '',
      block: false
    };

    componentWillUnmount () {
    }

    render () {
      const { variant, to, imgSrc, active, className, block, ...props } = this.props

      if (to) {
        return (
                <NavLink className={`btn-m btn-m-${variant} ${active ? 'active' : ''} ${block ? 'd-block w-100' : ''} ${className}`} to={to}
                         {...props}>
                    {this.props.children}
                </NavLink>
        )
      }
      return (
            <button className={`btn-m btn-m-${variant} ${active ? 'active' : ''} ${block ? 'd-block w-100' : ''} ${className}`}
                    {...props}>{this.props.children}</button>
      )
    }
}
