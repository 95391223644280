import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import { NavLink } from 'react-router-dom'

class KaunchBeej extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props

    return (
            <Page meta={{
              title: 'Kaunch Beej, libido, benefits, dosage | Misters',
              path: `${match.params.language}/ingredient/kaunch-beej/`,
              description: 'Learn about Kaunch Beej, a high quality Ayurvedic herb, on the Misters Ayurveda page. Your complete Misters Ayurvedic ingredient guide has collated expert information on Kaunch Beej’s usage, dosage, benefits, description, alternative names and constituents. Kaunch Beej has vast, scientifically proven health benefits. Increase in testosterone levels, men’s beard growth and reduction in stress are some results of its usage. It manages arthritis, has analgesic properties, boosts libido and controls cholesterol. Rich in vitamins, it is found in parts of Asia, America and many more areas. Know your Ayurvedic ingredients to understand Misters products better.'
            }}>
                <div className="ingredient-header-img" style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>Kaunch Beej- for your testosterone troubles</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What is Kaunch Beej?</h2>
                            <p>
                                Kaunch Beej or Mucuna pruriens (Latin) is the <b>seed of the Kapikacchu herb</b>, known
                                for its high medicinal values. It is also known as an aphrodisiac which raises sperm
                                count and quality, boosts sexual desire with its antidepressant properties and increases
                                testosterone levels in the blood. Almost all parts of the Kapikacchu herb possess
                                precious medicinal qualities, of which the beej or seeds are the most well known. Like
                                other herbal medicinal substances Kaunchbeej too is known by numerous other names like
                                that of Atmagupta or Kapikacchu in Sanskrit, Kivacc, Kevanch or Kaunch in Hindi as well
                                as cow hedge, cow itch, cowage, velvet bean, lacuna bean, Lyon bean, Donkey eye, monkey
                                tamarind and Buffalo beans in English.
                            </p>
                            <p className="text-center font-weight-bold">
                                <span>To know more about Kaunch Beej, </span>
                                <a className="ingredient-blog-links" target="_blank"
                                         href={`https://misters.in${match.params.language}/blog/What-is-Kaunchbeej`}>click here</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <iframe className="youtubeIframe"
                                        src="https://www.youtube.com/embed/Gi77MbvbmLo?loop=1&playlist=Gi77MbvbmLo&rel=0"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen frameBorder="0"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What are some other names for Kaunch Beej?</h2>
                            <p>
                                Some other names of Kaunch Beej include, <b>Alkushi in Bengali, Khaajkuiri in Marathi,
                                Poonaikkaali in Tamil, Kapikachhu Durada Gondi in Telugu, Naykuruna in Malayalam,
                                Nasagunnikaayi in Kannada, Bieh in Madurese and Baidanka in Oriya</b>. In India, the
                                fully grown seeds of Kapikacchu have been conventionally eaten by the Kanikkars, a South
                                Indian hilly tribe, which consumed them only after repeatedly boiling the seeds in order
                                to eliminate its anti-nutritional parts.
                                <br/>
                                <br/>
                                However, it cannot withstand frost and survives poorly in cold and moist soils.The
                                Kapikachhu herb is a leguminous plant which makes Kaunchbeej a legume, rich in
                                tocopherol, or Vitamin E, a highly valued element in the larger medical discourse.
                            </p>
                            <br/>
                            <h2>What is the description of a Kaunch Beej plant?</h2>
                            <p>
                                The branches of the Kaunch Beej plant are <b>long and slender</b> with alternate,
                                lanceolate leaves. White <b>flowers with a bluish-purple tinge and butterfly-shaped
                                corolla grow on the plant</b>. The plant or <b>legumes are intensely dark brown in
                                colour, thick, leathery, as well as hairy growing up to 4 inches in length</b>. They
                                have the looks of violin sound holes and each pod has <b>four to six seeds</b>.The plant
                                grows best in warm, moist conditions with abundant rain and on an altitude of about 1500
                                m or less. The genus can also grow in severe climatic conditions similar to drought-like
                                soil, less-fertile soil, and also in soil with high acidity.
                                <br/>
                                <br/>
                                Mucuna pruriens plants are characterised by profuse, long stinging hairs on their young
                                foliage, and the seed pods which if touched, can cause severe skin irritation, burning
                                and rashes. The non-prickling types, called ‘velvet beans’, grow silky hair which looks
                                as if it is pressed together.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker image={Images.ingredient.kaunchBeejDescription} to={shopUrl}
                                          buttonText={'SHOP'}/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <iframe className="youtubeIframe"
                                        src="https://www.youtube.com/embed/5DLnAcNwZ20?loop=1&list=PLylNn-FPIdEm58qwHU5QfYIrO3XRS--Tw&rel=0"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen frameBorder="0"/>
                            </div>
                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>

            </Page>
    )
  }
}

export default KaunchBeej
