import english from './english'
import hindi from './hindi'
import kannada from './kannada'
import telugu from './telugu'

const names = {
  hindi: 'hindi',
  english: 'english',
  kannada: 'kannada',
  telugu: 'telugu'
}

const languageUrls = {
  '/en': 'english',
  '/hi': 'hindi',
  '/kn': 'kannada',
  '/te': 'telugu'
}

const languageNameToUrl = {
  english: '/en',
  hindi: '/hi',
  kannada: '/kn',
  telugu: '/te'
}

const languageToShortName = {
  english: 'en',
  hindi: 'hi',
  kannada: 'kn',
  telugu: 'te'
}

const getLanguageFromName = (value) => {
  let language = english
  let name = names.english
  switch (value) {
    case names.hindi:
      language = hindi
      name = names.hindi
      break

    case names.english:
      language = english
      name = names.english
      break

    case names.kannada:
      language = kannada
      name = names.kannada
      break

    case names.telugu:
      language = telugu
      name = names.telugu
      break
  }
  return [language, name]
}

export {
  names,
  languageUrls,
  languageNameToUrl,
  languageToShortName,
  english,
  hindi,
  kannada,
  telugu,
  getLanguageFromName
}
