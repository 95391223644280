import React, { PureComponent } from 'react'
import Ripple from './Ripple'
import PropTypes from 'prop-types'

export default class OrderLoading extends PureComponent {
    static propTypes = {
      show: PropTypes.bool
    };

    static defaultProps = {
      show: true
    };

    render () {
      if (!this.props.show) return null

      return (
            <div className="order-loading">
                <Ripple show={this.props.show}/>
                <h4>Please do not press back or refresh the page, we are confirming your order...</h4>
            </div>
      )
    }
}
