// External Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
// My Imports
import ImageService from '../../services/ImageService'
import { getDiscount, getHttpsImageUrl, getLanguageTranslation, getPrice } from '../../utils'
import { EMI_COEFFICIENT, PHONE_NUMBER } from '../../constants'
import Button from '../../components/buttons/Button'
import LText from '../../components/core/LText'
import Ecommerce from '../../services/Ecommerce'
import { createCartItem } from '../../actions/ecommerce/cart'
import TherapyPrice from '../Therapy/Components/TherapyPrice'
import TherapyRating from '../Therapy/Components/TherapyRating'
import Spinner from 'react-bootstrap/Spinner'
import { updateWishlist } from '../../actions/ecommerce/wishlist'

const Section1 = ({ history, profile, location, match, product, cart, wishlist, createCartItem, updateWishlist, isCallNowProductPage }) => {
  const language = match.params.language
  const [selectedProduct, setSelectedProduct] = useState(product)
  const isAddedToWishlist = !!wishlist.detail && wishlist.detail.products &&
    wishlist.detail.products.filter(obj => obj.id === product.id).length > 0

  const handleAddItemToCart = async (product) => {
    Ecommerce.addToCart({ products: [product] })
    createCartItem({ id: product.id, quantity: 1 })
  }

  const handleAddItemToWishlist = async (product) => {
    const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email
    if (!isAuthenticated) {
      history.push(`${match.params.language}/login/?next=${match.url}?wishlist=true`)
    } else {
      updateWishlist({ product_id: product.id, remove: false })
    }
  }

  useEffect(() => {
    const querySearch = location.search
    const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email
    if (querySearch.includes('wishlist=true') && isAuthenticated && !isAddedToWishlist) {
      updateWishlist({ product_id: product.id, remove: false })
    }
  }, [])

  const price = getPrice(selectedProduct.price)
  const salePrice = selectedProduct.sale_price ? getPrice(selectedProduct.sale_price) : null
  const discount = getDiscount(price, salePrice)

  const ratingsStarCount = product.ratings_star_count
  const ratingsCount = product.ratings_count

  return (
    <div className='solution-page-section'>
      <div className="container">
        <div className="solution-page-section-bg-image">
          <img src={getHttpsImageUrl(product.image)} alt="Man facing front"/>
        </div>
        <div className="row py-md-5 pb-4">
          <div
            className="col-md-6 col-12 d-flex order-2 order-md-1 align-items-center justify-content-center">
            <div className='pr-3'>
              <h1 className="solution-page-title">
                {getLanguageTranslation(product, 'title', language)}
              </h1>
              <p className="solution-page-subtitle">+ free expert consultation</p>
              <p className="solution-page-short-description">
                {getLanguageTranslation(product, 'short_description', language)}
              </p>
              {
                !!product.available_packs &&
                product.available_packs.sort((a, b) => a.months - b.months).map(
                  (availablePack, index) => {
                    const buttonText = `${availablePack.months} ${availablePack.months > 1 ? 'months' : 'month'}`
                    const productPrice = getPrice(availablePack.product.sale_price)
                    const productMrp = getPrice(availablePack.product.price)
                    const discount = getDiscount(productMrp, productPrice)
                    const emiPrice = parseInt(productPrice / EMI_COEFFICIENT)
                    const extraClassName = availablePack.product.id === selectedProduct.id ? 'active' : ''
                    return (
                      <div className={`solution-page-pack-button ${extraClassName}`}
                           key={availablePack.id}
                           onClick={() => setSelectedProduct(availablePack.product)}>
                        <span className="title">{buttonText}</span>
                        {
                          !!discount &&
                          <span className="discount">{discount}% off</span>
                        }
                        <span className="text">
                          <strong>EMI</strong> starts at <strong>₹{emiPrice}</strong>/mo
                        </span>
                      </div>
                    )
                  }
                )
              }
              <TherapyPrice discount={discount} price={price} salePrice={salePrice}/>
              <TherapyRating rating={ratingsStarCount / ratingsCount}
                             ratingCount={ratingsCount}/>
              <div className="product-pricing">
                {/* <TherapyPrice discount={discount} price={price} salePrice={salePrice}/> */}
                <div className="py-1 d-flex">
                  {
                    isCallNowProductPage
                      ? <a href={`tel:${PHONE_NUMBER}`}
                           className="btn-m btn-m-red mr-sm-3 mb-sm-1 solution-page-add-to-cart">
                        {
                          cart.isCreatingCartItem
                            ? <Spinner animation="border" size="sm" variant="light"/>
                            : 'Call Now'
                        }
                        <div className="solution-page-add-to-cart-bottom-number">{PHONE_NUMBER}</div>
                      </a>
                      : <Button variant="red" onClick={() => handleAddItemToCart(selectedProduct)}
                              className="mr-sm-3 mb-sm-1 solution-page-add-to-cart">
                        {
                          cart.isCreatingCartItem
                            ? <Spinner animation="border" size="sm" variant="light"/>
                            : <LText k="ADD_TO_CART"/>
                        }
                      </Button>
                  }
                </div>
              </div>
              <button className="wishlist-button"
                      onClick={() => handleAddItemToWishlist(selectedProduct)}>
                {isAddedToWishlist ? 'Added to Wishlist' : '+ Add to wishlist'}
              </button>
              <div className="solution-page-section-trust-marker">
                <img src={ImageService.home.badge} alt="Badge"/> Trusted by <strong>25,000+ Indian Men</strong>
              </div>
              <div className="solution-page-section-trust-marker">
                <img src={ImageService.home.badge} alt="Badge"/> Recommended by doctors
              </div>
              {
                !!product.trust_marker &&
                <div className="solution-page-section-trust-marker">
                  <img src={ImageService.home.badge} alt="Badge"/> {product.trust_marker}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Section1.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  profile: PropTypes.object,
  product: PropTypes.object,
  wishlist: PropTypes.object,
  cart: PropTypes.object,
  isCallNowProductPage: PropTypes.bool,
  createCartItem: PropTypes.func,
  updateWishlist: PropTypes.func
}

Section1.defaultProps = {
  isCallNowProductPage: false
}

const mapStateToProps = state => ({
  profile: state.accounts.profile,
  cart: state.ecommerce.cart,
  wishlist: state.ecommerce.wishlist
})

const matchDispatchToProps = dispatch => bindActionCreators({
  createCartItem,
  updateWishlist
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Section1))
