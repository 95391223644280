import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Page from '../../components/core/Page'
import { Images } from '../../constants'
import TeamCard from '../../components/cards/TeamCard'
import CrossFadeImage from '../../components/others/CrossFadeImage'
import ImageService from '../../services/ImageService'

class About extends Component {
  componentDidMount () {}

  render () {
    const { match } = this.props

    const team = [
      {
        name: 'Suhas Misra',
        image: Images.team.suhas,
        description: 'The archetypal insider-outsider of India’s entrepreneurship world. Co-founder of Hector Beverages (Paper Boat) and co-founder of ChannelPlay (arguably India’s largest retail marketing company), but also quixotic challenger of the depressing status quo in rural healthcare (Tardigrade), and a retiree (well, no longer) at 35! Alumnus of IIM Calcutta (class of 2003), and a brief visitor to the corporate world (Coca Cola, Nokia, 2003-06).'
      },
      {
        name: 'Gaurav Gupta',
        image: Images.team.gaurav,
        description: 'The one who ensures there’s method to the madness. Seasoned veteran of the corporate world (Coca Cola, Nokia, Citibank, Tata Docomo). Started BookBhook (a byte-sized book summary business), before heading to Pearson’s on his way to co-founding Misters. Alumnus of IIM Ahmedabad (class of 2001).'
      },
      {
        name: 'Saurabh Kumar',
        image: Images.team.saurabh,
        description: 'The operations man, and another veteran of the corporate world (Berger Paints, ICICI, Reliance Money). Started 360 Yards, a real estate startup, before taking on the windmills with Tardigrade. Alumnus of Narsee Monjee (class of 2003).'
      },
      {
        name: 'Kumar Aditya Mohta',
        image: Images.team.aditya,
        description: 'The appropriately nerdy techie. Worked on the very complex backend of the telemedicine app at Tardigrade and has put together the absolutely glorious misters.in website.'
      },
      {
        name: 'Sabena Nadar',
        image: Images.team.sabena,
        description: 'One of the two vegans. Responsible for keeping things running smoothly here, apart from working on partnerships that matter.'
      },
      {
        name: 'Krishna',
        image: Images.team.krishna,
        description: 'Seasoned RTM expert, worked at Eveready, Shell, Becton Dickinson, before turning entrepreneur. And seasoned players with an entrepreneurial streak is just about the ideal mix here at Misters'
      }
    ]

    return (
            <Page meta={{
              path: `${match.params.language}/about/`,
              title: 'Misters- men\'s sexual health platform',
              description: 'Misters is a Gurgaon-based health and confidence start-up catering to men’s sexual health and wellness using ayurvedic, allopathic and plant-based methods. In collaboration with medical experts, reliable information on erections, ejaculation, sexual desire and hair care awaits you here. Simple, personalized and result-oriented care amalgamating traditional knowledge and the best science has to offer is Misters’ forte. More than a website and company, Misters is a community. Let your journey commence, bon voyage!'
            }}>
                <div className="about-page-section1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                <CrossFadeImage
                                    src={ImageService.logos.brandSmallLight}
                                    alt="About Us"
                                    duration={700}
                                    timingFunction={'ease-out'}
                                />
                            </div>
                            <div className="col-12 col-md-6 d-flex flex-column justify-content-center py-3 text-center text-md-left py-5">
                                <h2 className="about-page-subtitle">
                                    About Us
                                </h2>
                                <p className="about-page-description">
                                    “So, where do you work?”<br/>
                                    “At a startup...”<br/>
                                    “Well, what does your startup do?”<br/>
                                    “Erm...well, uncle, it...oh, lovely weather today, no?”<br/><br/>
                                    So, there are lots of reasons to not put names and faces here, because we work in a
                                    space shaped by stigma. But what would be the point of that? If it is stigma that we are
                                    fighting, we can’t turn to the weather. If we stand for confidence in the face of doubt,
                                    then the first step is surely for you to see who we are. So, here we go:
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-12 col-lg-10 offset-lg-1">
                            <div className="row">
                                {
                                    team.map(
                                      (member, index) => (
                                            <TeamCard className="col-12" key={index} image={member.image}
                                                      name={member.name} description={member.description}
                                                      data-aos="zoom-in" data-aos-offset="100"
                                                      data-aos-delay="50" data-aos-duration="500" data-aos-once="true"/>
                                      )
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

const mapStateToProps = (state) => ({})

const matchDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(About)
