import api from '../api'

export const FETCH_WISHLIST = 'FETCH_WISHLIST'
export const FETCH_WISHLIST_RESULT = 'FETCH_WISHLIST_RESULT'
export const FETCH_WISHLIST_ERROR = 'FETCH_WISHLIST_ERROR'
export const FETCH_WISHLIST_RETRY = 'FETCH_WISHLIST_RETRY'

export const UPDATE_WISHLIST = 'UPDATE_WISHLIST'
export const UPDATE_WISHLIST_RESULT = 'UPDATE_WISHLIST_RESULT'
export const UPDATE_WISHLIST_ERROR = 'UPDATE_WISHLIST_ERROR'
export const UPDATE_WISHLIST_RETRY = 'UPDATE_WISHLIST_RETRY'

export const fetchWishlist = () => async dispatch => {
  try {
    dispatch({ type: FETCH_WISHLIST })
    const res = await api.ecommerce.wishlist.get()
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_WISHLIST_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_WISHLIST_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_WISHLIST_RETRY, message: e.message })
  }
}

export const updateWishlist = (data) => async dispatch => {
  try {
    dispatch({ type: UPDATE_WISHLIST })
    const res = await api.ecommerce.wishlist.update(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: UPDATE_WISHLIST_RESULT, data: result })
    } else {
      dispatch({ type: UPDATE_WISHLIST_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: UPDATE_WISHLIST_RETRY, message: e.message })
  }
}
