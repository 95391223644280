import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import * as PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router'

import LText from '../../core/LText'

class ExitQuizModal extends Component {
    static propTypes = {
      show: PropTypes.bool,
      handleExit: PropTypes.func,
      handleContinue: PropTypes.func
    };

    static defaultProps = {
      show: false
    };

    constructor (props) {
      super(props)
      this.state = {}
    }

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      // history.push(`${match.params.language}/checkout${querySearch}`);
    }

    render () {
      const { show, handleExit, handleContinue } = this.props

      return (
            <Modal className="duplicate-order-modal" show={show} onHide={handleContinue} centered>
                <Modal.Body className="p-0">
                    <p className="duplicate-order-modal-title">
                        Are you sure you want to exit your wellness test?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn-m btn-m-exit-modal" onClick={handleExit}>
                        Exit
                    </button>
                    <button className="btn-m btn-m-red" onClick={handleContinue}>
                        Continue Assessment
                    </button>
                </Modal.Footer>
            </Modal>
      )
    }
}

export default withRouter(ExitQuizModal)
