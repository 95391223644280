import React from 'react'
import { withRouter } from 'react-router'

const AnalysisCard = function ({ title, imgSrc, text, type = 'small' }) {
  return (
        <div className={`analysis-card ${type === 'large' ? 'analysis-card-large' : ''}`}>
            <div className="analysis-icon">
                <img src={imgSrc} alt=""/>
            </div>
            <div className="analysis-card-content">
                {!!title && <h4 className="analysis-card-title">{title}</h4>}
                <div className="analysis-content" dangerouslySetInnerHTML={{ __html: text }}/>
            </div>
        </div>
  )
}

export default withRouter(AnalysisCard)
