import api from '../api'
export const FETCH_USER_REPORT = 'FETCH_USER_REPORT'
export const FETCH_USER_REPORT_RESULT = 'FETCH_USER_REPORT_RESULT'
export const FETCH_USER_REPORT_ERROR = 'FETCH_USER_REPORT_ERROR'
export const FETCH_USER_REPORT_RETRY = 'FETCH_USER_REPORT_RETRY'

export const FETCH_USER_REPORTS = 'FETCH_USER_REPORTS'
export const FETCH_USER_REPORTS_RESULT = 'FETCH_USER_REPORTS_RESULT'
export const FETCH_USER_REPORTS_ERROR = 'FETCH_USER_REPORTS_ERROR'
export const FETCH_USER_REPORTS_RETRY = 'FETCH_USER_REPORTS_RETRY'

export const fetchUserReport = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_USER_REPORT })
    const res = await api.report.report.get(data)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_USER_REPORT_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_USER_REPORT_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_USER_REPORT_RETRY, message: e.message })
  }
}

export const fetchUserReports = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_USER_REPORTS })
    const res = await api.report.report.list()
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_USER_REPORTS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_USER_REPORTS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_USER_REPORTS_RETRY, message: e.message })
  }
}
