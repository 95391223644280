import {
  FETCH_REPORT,
  FETCH_REPORT_RESULT,
  FETCH_REPORT_ERROR,
  FETCH_REPORT_RETRY
} from '../../actions/bot/report'

const initialState = {
  detail: {},
  isFetching: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORT:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_REPORT_RESULT:
      return { ...state, isFetching: false, detail: action.data }
    case FETCH_REPORT_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_REPORT_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    default:
      return state
  }
}

export default reducer
