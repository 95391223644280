import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'

import { getPrice } from '../../../utils'
import { updateCartCoupon } from '../../../actions/ecommerce/cart'
import LText from '../../core/LText'
import { Images } from '../../../constants'

class OrderSummaryCard extends Component {
    static propTypes = {
      showPaymentType: PropTypes.bool,
      showOfferDiscount: PropTypes.bool,
      showProducts: PropTypes.bool,
      showApplyCoupon: PropTypes.bool
    };

    static defaultProps = {
      showPaymentType: false,
      showOfferDiscount: false,
      showProducts: true,
      showTitle: true,
      showApplyCoupon: true
    };

    state = {
      couponModalOpen: false,
      message: ''
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { cart } = this.props

      if (!cart.isCouponUpdating && prevProps.cart.isCouponUpdating && cart.error && !cart.retry) {
        this.setState({ message: 'Coupon not found' })
      }
      if (!cart.isCouponUpdating && prevProps.cart.isCouponUpdating && !cart.error && !cart.retry) {
        this.setState({ message: '', couponModalOpen: false })
      }
    }

    handleUpdateCartCoupon = (coupon_code) => {
      this.props.updateCartCoupon({ coupon_code })
    };

    handleRemoveCoupon = () => {
      this.props.updateCartCoupon({ coupon_code: null })
    };

    getTotalDiscountPrice = () => {
      const { cart: { detail } } = this.props
      let total = 0
      detail.items && detail.items.map(item => (total += getPrice(item.line_item_total)))

      return Math.round(total - getPrice(detail.subtotal || 0))
    };

    render () {
      const { cart: { detail }, showPaymentType, showOfferDiscount, showProducts, showTitle, showApplyCoupon } = this.props

      let order_total = parseFloat(detail.subtotal)
      const offer_discount = parseFloat(detail.offer_discount)
      const coupon_discount = parseFloat(detail.coupon_discount)
      const total_discount = parseFloat(detail.total_mrp) - parseFloat(detail.subtotal)
      if (!showOfferDiscount) {
        order_total += offer_discount
      }

      return (
            <div className='order-summary'>
                {
                    showProducts &&
                    showTitle &&
                    <h4 className='order-summary-title'>
                        <LText k="SUMMARY"/>
                    </h4>
                }
                <div className='items-container'>
                    {
                        !!detail.items &&
                        showProducts &&
                        detail.items.map(
                          (item, index) => (
                                <div className='item-container' key={item.id}>
                                    <div className="item-left">
                                        <span className='item-title'>{item.item.title}</span>
                                    </div>
                                    <span className='item-price'>
                                        x {item.quantity}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        ₹{getPrice(item.total_mrp)}</span>
                                </div>
                          )
                        )
                    }
                    <div className='item-container'>
                        <div className="item-left">
                            <span className='item-title'>Delivery Charges</span>
                        </div>
                        <span className='item-price text-primary-o-1 font-weight-normal'>
                            <span className='strike-price'>50</span>
                            {'  FREE'}
                        </span>
                    </div>
                    {
                        total_discount > 0 &&
                        <div className='item-container'>
                            <div className="item-left">
                                <span className='item-title'>Discount</span>
                            </div>
                            <span className='item-price'>- ₹{getPrice(total_discount).toFixed(0)}</span>
                        </div>
                    }
                </div>
                {/* <div> */}
                {/*    { */}
                {/*        !!detail.coupon && */}
                {/*        showApplyCoupon && */}
                {/*        <div className="checkout-coupon"> */}
                {/*            <span>Coupon <strong>{detail.coupon.code}</strong> is applied.</span> */}
                {/*            <button onClick={this.handleRemoveCoupon}>Remove</button> */}
                {/*        </div> */}
                {/*    } */}
                {/*    { */}
                {/*        !detail.coupon && */}
                {/*        showApplyCoupon && */}
                {/*        <div className="checkout-coupon"> */}
                {/*            { */}
                {/*                !this.state.couponModalOpen ? */}
                {/*                    <span className="d-flex align-items-center"> */}
                {/*                            <FontAwesomeIcon icon={faTag} size={"1x"} className="mr-2"/> */}
                {/*                            Have a coupon code? */}
                {/*                        </span> : */}
                {/*                    <CouponModal show={this.state.couponModalOpen} */}
                {/*                                 handleClose={() => this.setState({couponModalOpen: false})} */}
                {/*                                 handleUpdateCartCoupon={this.handleUpdateCartCoupon} */}
                {/*                                 message={this.state.message}/> */}
                {/*            } */}
                {/*            <button onClick={() => this.setState({couponModalOpen: true, message: ''})}> */}
                {/*                Apply */}
                {/*            </button> */}
                {/*        </div> */}
                {/*    } */}
                {/* </div> */}
                <hr className="checkout-hr"/>
                <div className="checkout-summary-total">
                    <div className="checkout-summary-total-title">Total</div>
                    <span className='checkout-summary-total-price'>
                        {
                            total_discount > 0
                              ? <React.Fragment>
                                    <span className="strike-price">
                                        {getPrice(order_total + total_discount).toFixed(0)}
                                    </span>
                                    <span className="currency-price">{getPrice(order_total).toFixed(0)}</span>
                                </React.Fragment>
                              : <span className="currency-price">{getPrice(order_total).toFixed(0)}</span>
                        }
                    </span>
                </div>
                {
                    !!total_discount &&
                    <div className="checkout-summary-savings">
                        <img src={Images.logo.x1} alt="M"/>
                        Savings of ₹{getPrice(total_discount).toFixed(0)} on the bill
                    </div>
                }
            </div>
      )
    }
}

const mapStateToProps = state => ({
  cart: state.ecommerce.cart
})

const matchDispatchToProps = dispatch => bindActionCreators({
  updateCartCoupon
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(OrderSummaryCard)
