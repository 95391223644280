import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../../components/core/Page'
import LText from '../../../components/core/LText/LText'
import { Images } from '../../../constants'
import api from '../../../actions/api'
import Button from '../../../components/buttons/Button'
import { NavLink } from 'react-router-dom'
import PostSmallCard from '../../../components/cards/PostSmallCard'
import { getSearchParams } from '../../../utils'
import { fetchRecentPosts } from '../../../actions/blog/posts'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { languageToShortName, languageUrls } from '../../../languages'

class OrderPlaced extends PureComponent {
    static propTypes = {
      profile: PropTypes.object,
      newOrder: PropTypes.object,
      updateNewOrder: PropTypes.func,
      fetchRecentPosts: PropTypes.func
    };

    componentDidMount () {
      // let {orders, history, match} = this.props;
      // if (!Object.keys(orders.detail).length) {
      //     history.push(`${match.params.language}/`);
      // }
      api.ecommerce.cart.empty()
      const { match, posts: { ghostRecentList: { posts = [] } } } = this.props
      const langParam = match.params.language || '/en'
      const lang = languageToShortName[languageUrls[langParam]]
      if (!posts.length) {
        this.props.fetchRecentPosts({ lang })
      }
    }

    handleQuizClick = () => {
      const { history, match } = this.props
      history.push(`${match.params.language}/quiz/custom/?bot_form_id=ayurvedic&field_id=start`)
    };

    render () {
      const { match, location, posts: { ghostRecentList } } = this.props
      const queryParams = getSearchParams(location.search)

      return (
            <Page meta={{
              title: 'Shop quality Misters health products',
              path: `${match.params.language}/checkout/success/`,
              description: 'This space houses tried, trusted and tested Misters products formulated by experts and specialists of the field. Ideal recourse to boost immunity, manage hair loss, maintain erections and control ejaculation can be found here. Discover scientifically enriching, natural, ancient Ayurvedic herbs like Ashwagandha, Shilajit, Gokshura, Shatavari, Kaunch Beej Kesar and more, moulded for modern-day usage. Some among their vast range of benefits include an enhancement of libido, improved performance in bed, strong penile tissue and healthy testosterone levels.'
            }} showNotification={true} showNavbar={false} showFooter={false}>
                <div className="container">
                    <div className="placed">
                        <div className="order-place-nav">
                            <img className='order-place-nav-logo' src={Images.logo.big1x} alt="Man!"/>
                        </div>
                        <div className="row justify-content-center">
                            <div
                                className="col-10 col-md-8 col-lg-5 order-confirmed-container">
                                <img className="confetti" src={Images.checkout.confetti} alt="confetti"/>
                                <img className="cart-icon" src={Images.checkout.cart} alt="cart"/>
                                <img className="fill_19" src={Images.checkout.fill_19} alt="cart"/>
                                <div className="order-confirmed-box">
                                    <img className="success-icon" src={Images.checkout.success_icon}
                                         alt="success icon"/>
                                    <h1 className="success-heading">
                                        <LText k="ORDER_THANKS"/>
                                    </h1>
                                    <p className="success-text">
                                        <LText k="COD_ORDER_DISPATCHED_AFTER_CONFIRMATION_CALL"/>
                                    </p>
                                    <Button variant="red" className="checkout-success-button"
                                            to={`${match.params.language}/profile/orders/${queryParams.cid}`}>
                                        <LText k="SHOW_DETAILS"/>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3 mt-5 mb-3">
                                <div className="row take-quiz-container">
                                    <div className="col-6 col-sm-5 pr-0">
                                        <img className="take-quiz-img" src={Images.thankyou}
                                             alt="thinking man"/>
                                    </div>
                                    <div className="col-6 col-sm-7 take-quiz-text-container">
                                        <h4 className="take-quiz-text">
                                            <LText k="FIND_OUT_YOUR_SEX_SCORE"/>
                                        </h4>
                                        <Button variant="red" className="checkout-success-button"
                                                to={`${match.params.language}/quiz/sex/`}>
                                            <LText k="TAKE_THE_SEX_QUIZ"/>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-12 d-flex justify-content-center align-items-center">
                                <NavLink className="continue-shopping" to={`${match.params.language}/shop/`}>
                                    <LText k="CONTINUE_SHOPPING"/>
                                    &nbsp;<FontAwesomeIcon icon={faArrowRight} size="lg" color="#E30A17"/>
                                </NavLink>
                            </div> */}
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3 my-5">
                                <h5 className="text-center pt-3 pb-4">
                                    <LText k="LEARN_MORE"/>
                                </h5>
                                <div className="row">
                                    {!!ghostRecentList.posts && ghostRecentList.posts.map((post) => (
                                        <div key={post.id} className="col-10 col-md-6 offset-1 offset-md-0">
                                            <PostSmallCard onWhiteBackground key={post.id} {...post}/>
                                        </div>
                                    ))}

                                    <div className="col-12 text-center py-5">
                                        <a href={`https://misters.in${match.params.language}/blog/`}
                                                className="checkout-success-button text-uppercase btn-m btn-m-red"
                                                style={{ minWidth: '150px' }}>
                                            <LText k="BLOG"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = state => ({
  orders: state.ecommerce.orders,
  posts: state.blog.posts
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchRecentPosts
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(OrderPlaced)
