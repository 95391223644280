// External Imports
import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../buttons/Button'
import { withRouter } from 'react-router'
import { PHONE_NUMBER } from '../../../constants'
import LText from '../../core/LText'

const TakeQuiz = ({ match, buttonText, buttonLink }) => {
  if (!buttonLink) {
    buttonLink = `${match.params.language}/quiz/sex/`
  }
  return (
    <div className="take-quiz py-5">
        <h2 className="home-page-subtitle text-center text-white">Still not sure what you are looking for?</h2>
        <div className="text-center">
          <Button to={buttonLink}
                  variant="red"
                  style={{ width: 200 }}>{buttonText}</Button> <br/>
          <a href={`tel:${PHONE_NUMBER}`}
             className="btn-m btn-m-red mt-2">
            <LText k="CALL_US"/> <br/>
            <small>for a free Ayurveda consultation</small>
          </a>
        </div>
    </div>
  )
}

TakeQuiz.propTypes = {
  match: PropTypes.object,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string
}

TakeQuiz.defaultProps = {
  buttonText: 'Take the Man Quiz',
  buttonLink: ''
}

export default withRouter(TakeQuiz)
