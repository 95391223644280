import React, { Component } from 'react'

import Page from '../../components/core/Page'
import { Images } from '../../constants'
import Modal from '../../components/modals/Modal'
import { getInnerText, removeLinks, truncChars } from '../../utils'
import { rohanArticles, niharikaArticles, contributors } from './data'
import TeamCard from '../../components/cards/TeamCard'

class ThinkingSex extends Component {
  componentDidMount () {
  }

  componentWillUnmount () {

  }

    state = {
      article: {}
    };

    render () {
      const { match } = this.props

      return (
            <Page meta={{
              title: 'Thinking Sex | Misters',
              path: `${match.params.language}/thinking-sex/`,
              description: 'THINKING SEX... is a channel of perspective, information and opinion regarding the male condition, in all this complex beauty. Expand your mind, think ctirically and boundlessly, without a fear in the world. Let your journey of exploration, begin!'
            }}>
                <div className="ingredient-header-img"
                     style={{ backgroundImage: `url('${Images.thinkingsex.thinkingSexBanner}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>THINKING SEX</h1>
                    </div>
                </div>
                <div className="therapist">
                    <div className="container">
                        <div className="row py-5 pt-md-3">
                            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                                <p>
                                    When you get what you want in your struggle for self, <br/>
                                    And the world makes you king for a day, <br/>
                                    Then go to the mirror and look at yourself, <br/>
                                    And see what that man has to say. <br/> <br/>

                                    Whose judgement upon him must pass, <br/>
                                    The fellow whose verdict counts most in life, <br/>
                                    Is the man staring back from the glass. <br/><br/>

                                    For he’s with you clear to the end, <br/>
                                    And you’ve passed your most dangerous, difficult test, <br/>
                                    If the man in the glass is your friend.
                                </p>
                                <p className="text-right">-Anonymous</p>
                            </div>
                            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                                <img src={Images.thinkingsex.thinkingSexHead} alt="thinkingSexHead"
                                     className="therapist-image p-0"/>
                            </div>
                            <div className="col-12 mt-4">
                                <p>
                                    If you're wondering about the name of the page- Thinking and sex. Sex and thinking.
                                    It is an odd juxtaposition because quite often it is assumed that one doesn’t lend
                                    itself to the other. However, we think that thinking is a good idea in general. So,
                                    here we deploy our inner Descartes and look at sex. Closely.
                                    <br/>
                                    <br/>
                                    Thinking Sex is a channel of contemplation of sex, from multiple points of view, and
                                    hopefully helps one understand sex, not completely, nor in most part but still quite
                                    substantially.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="bg-light py-5">
                        <div className="container">
                            <h2 className="text-center">CONTRIBUTORS</h2>
                            <div className="row">
                                {
                                    contributors.map(
                                      (member, index) => (
                                            <TeamCard className="col-12" key={index} image={member.image}
                                                      name={member.name} description={member.description}/>
                                      )
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="container py-5">
                        <h2 className="text-center mb-5">Rohan Pasricha's Articles</h2>
                        <div className="row">
                            {rohanArticles(match.params.language).map((article, index) => (
                                <div key={index} className="col-12 col-md-6 col-lg-4 d-flex"
                                     onClick={() => this.setState({ article })}>
                                    <div className="post-card">
                                        {
                                            article.image &&
                                            <div className="post-card-image">
                                                <img src={article.image} alt={article.title}/>
                                            </div>
                                        }
                                        <div className="post-card-content">
                                            <div className="post-card-head">
                                                <h4 className="post-card-title">{article.title}</h4>
                                                <p className="post-card-text">{truncChars(removeLinks(getInnerText(article.content)), 100)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="bg-light py-5">
                        <div className="container">
                            <h2 className="text-center mb-5">Niharika Naik's Articles</h2>
                            <div className="row">
                                {niharikaArticles(match.params.language).map((article, index) => (
                                    <div key={index} className="col-12 col-md-6 col-lg-4 d-flex"
                                         onClick={() => this.setState({ article })}>
                                        <div className="post-card">
                                            {
                                                article.image &&
                                                <div className="post-card-image">
                                                    <img src={article.image} alt={article.title}/>
                                                </div>
                                            }
                                            <div className="post-card-content">
                                                <div className="post-card-head">
                                                    <h4 className="post-card-title">{article.title}</h4>
                                                    <p className="post-card-text">{truncChars(removeLinks(getInnerText(article.content)), 100)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <Modal show={!!this.state.article.title}
                           handleCloseClick={() => this.setState({ article: {} })}>
                        <div className="container pb-5">
                            <h2 className="mb-5 text-center">{this.state.article.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: this.state.article.content }}/>

                            {/* <div className="row justify-content-center">
                                <div className="col-12 col-md-6 p-3 text-center">
                                    <img src={this.state.article.image} alt={this.state.article.title}
                                         className="therapist-image"/>
                                </div>
                            </div> */}
                        </div>
                    </Modal>
                </div>
            </Page>
      )
    }
}

export default ThinkingSex
