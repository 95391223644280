// External Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router'

import AliceCarousel from 'react-alice-carousel'
import Image from '../../components/core/Image'
import { truncChars } from '../../utils'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt } from '@fortawesome/free-solid-svg-icons/faUserAlt'

const Section8 = ({ match, posts: { ghostRecentList } }) => {
  const [blogCarouselIndex, setBlogCarouselIndex] = useState(0)
  const [blogCarouselItems, setBlogCarouselItems] = useState([])

  const getBlogCarouselItems = () => {
    if (ghostRecentList.posts) {
      return ghostRecentList.posts.map((post) => (
        <a className="post-card mx-3" key={post.id}
           href={`https://misters.in${match.params.language}/blog/${post.slug}/`}>
          <Image className="post-card-image" src={post.feature_image} alt=""/>
          <div className="post-card-content">
            <div className="post-card-head">
              <h3 className="post-card-title">{post.title}</h3>
              {/* <p>{post.primary_tag}</p> */}
              <p className="post-card-text">{truncChars(post.excerpt, 160)}</p>
            </div>
            <div className="post-card-footer">
              {
                post.primary_author.profile_image
                  ? <Image src={post.primary_author.profile_image} alt={post.primary_author.name}/>
                  : <span className="post-card-footer-icon"><FontAwesomeIcon icon={faUserAlt} size={'sm'}/></span>
              }

              <div className="post-card-footer-content">
                <h5 className="post-card-author-name">{post.primary_author.name}</h5>
                <p className="post-card-timestamp">
                  Posted on:
                  <strong>{moment(post.published_at).format('MMM DD, YYYY')}</strong>
                </p>
              </div>
            </div>
          </div>
        </a>
      ))
    }
    return []
  }

  const onBlogSlideChanged = (e) => {
    setBlogCarouselIndex(e.item)
  }

  useEffect(() => {
    setBlogCarouselItems(getBlogCarouselItems())
  }, [ghostRecentList])

  return (
    <div className="home-page-section8">
      <div className="container">
        <div className="row py-5">
          <div className="col-12">
            <h2 className="text-center home-page-subtitle">
              <a href={`https://misters.in${match.params.language}/blog/`}>
                Blog
              </a>
            </h2>
          </div>
          <div className="col-12">
            <AliceCarousel touchTrackingEnabled
                           autoPlayInterval={6000}
                           responsive={{ 0: { items: 1 }, 768: { items: 2 }, 992: { items: 3 } }}
                           autoPlay={true}
                           items={blogCarouselItems}
                           startIndex={blogCarouselIndex}
                           stopAutoPlayOnHover={true}
                           buttonsDisabled={true}
                           dotsDisabled={false}
                           onSlideChanged={onBlogSlideChanged}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Section8.propTypes = {
  match: PropTypes.object,
  posts: PropTypes.object
}

const mapStateToProps = state => ({
  posts: state.blog.posts
})

const matchDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Section8))
