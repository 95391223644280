import api from '../api'

export const FETCH_QUIZ_TAKERS_COUNT = 'FETCH_QUIZ_TAKERS_COUNT'
export const FETCH_QUIZ_TAKERS_COUNT_RESULT = 'FETCH_QUIZ_TAKERS_COUNT_RESULT'
export const FETCH_QUIZ_TAKERS_COUNT_ERROR = 'FETCH_QUIZ_TAKERS_COUNT_ERROR'
export const FETCH_QUIZ_TAKERS_COUNT_RETRY = 'FETCH_QUIZ_TAKERS_COUNT_RETRY'

export const FETCH_BOT = 'FETCH_BOT'
export const FETCH_BOT_RESULT = 'FETCH_BOT_RESULT'
export const FETCH_BOT_ERROR = 'FETCH_BOT_ERROR'
export const FETCH_BOT_RETRY = 'FETCH_BOT_RETRY'

export const FETCH_BOT_LIST = 'FETCH_BOT_LIST'
export const FETCH_BOT_LIST_RESULT = 'FETCH_BOT_LIST_RESULT'
export const FETCH_BOT_LIST_ERROR = 'FETCH_BOT_LIST_ERROR'
export const FETCH_BOT_LIST_RETRY = 'FETCH_BOT_LIST_RETRY'

export const UPDATE_BOT = 'UPDATE_BOT'
export const UPDATE_BOT_RESULT = 'UPDATE_BOT_RESULT'
export const UPDATE_BOT_ERROR = 'UPDATE_BOT_ERROR'
export const UPDATE_BOT_RETRY = 'UPDATE_BOT_RETRY'

export const FETCH_BOT_BACK = 'FETCH_BOT_BACK'
export const FETCH_BOT_BACK_RESULT = 'FETCH_BOT_BACK_RESULT'
export const FETCH_BOT_BACK_ERROR = 'FETCH_BOT_BACK_ERROR'
export const FETCH_BOT_BACK_RETRY = 'FETCH_BOT_BACK_RETRY'

export const REASSIGN_BOT_FIELD_USER = 'REASSIGN_BOT_FIELD_USER'
export const REASSIGN_BOT_FIELD_USER_RESULT = 'REASSIGN_BOT_FIELD_USER_RESULT'
export const REASSIGN_BOT_FIELD_USER_ERROR = 'REASSIGN_BOT_FIELD_USER_ERROR'
export const REASSIGN_BOT_FIELD_USER_RETRY = 'REASSIGN_BOT_FIELD_USER_RETRY'

export const ASSIGN_BOT_FIELD_USER = 'ASSIGN_BOT_FIELD_USER'
export const ASSIGN_BOT_FIELD_USER_RESULT = 'ASSIGN_BOT_FIELD_USER_RESULT'
export const ASSIGN_BOT_FIELD_USER_ERROR = 'ASSIGN_BOT_FIELD_USER_ERROR'
export const ASSIGN_BOT_FIELD_USER_RETRY = 'ASSIGN_BOT_FIELD_USER_RETRY'

export const BOT_FORM_META_DATA = 'BOT_FORM_META_DATA'
export const BOT_FORM_META_DATA_RESULT = 'BOT_FORM_META_DATA_RESULT'
export const BOT_FORM_META_DATA_ERROR = 'BOT_FORM_META_DATA_ERROR'
export const BOT_FORM_META_DATA_RETRY = 'BOT_FORM_META_DATA_RETRY'

export const fetchQuizTakersCount = () => async dispatch => {
  try {
    dispatch({ type: FETCH_QUIZ_TAKERS_COUNT })
    const res = await api.bot.getQuizTakersCount()
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_QUIZ_TAKERS_COUNT_RESULT, data: result.quiz_takers })
    } else {
      dispatch({ type: FETCH_QUIZ_TAKERS_COUNT_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_QUIZ_TAKERS_COUNT_RETRY, message: e.message })
  }
}

export const fetchBot = () => async dispatch => {
  try {
    dispatch({ type: FETCH_BOT })
    const res = await api.bot.getBot()
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_BOT_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_BOT_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_BOT_RETRY, message: e.message })
  }
}

export const updateBot = (data) => async dispatch => {
  try {
    dispatch({ type: UPDATE_BOT })
    const res = await api.bot.patchBot(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: UPDATE_BOT_RESULT, data: result })
    } else {
      dispatch({ type: UPDATE_BOT_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: UPDATE_BOT_RETRY, message: e.message })
  }
}

export const fetchBotBack = () => async dispatch => {
  try {
    dispatch({ type: FETCH_BOT_BACK })
    const res = await api.bot.getBotBack()
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_BOT_BACK_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_BOT_BACK_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_BOT_BACK_RETRY, message: e.message })
  }
}

export const reassignBotFieldUser = () => async dispatch => {
  try {
    dispatch({ type: REASSIGN_BOT_FIELD_USER })
    const res = await api.bot.postReassignBot()
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: REASSIGN_BOT_FIELD_USER_RESULT, data: result })
    } else {
      dispatch({ type: REASSIGN_BOT_FIELD_USER_ERROR })
    }
  } catch (e) {
    dispatch({ type: REASSIGN_BOT_FIELD_USER_RETRY, message: e.message })
  }
}

export const assignBotFieldUser = (data) => async dispatch => {
  try {
    dispatch({ type: ASSIGN_BOT_FIELD_USER })
    const res = await api.bot.postAssignBot(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: ASSIGN_BOT_FIELD_USER_RESULT, data: result })
    } else {
      dispatch({ type: ASSIGN_BOT_FIELD_USER_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: ASSIGN_BOT_FIELD_USER_RETRY, message: e.message })
  }
}

export const getBotFormMetaData = (data) => async dispatch => {
  try {
    dispatch({ type: BOT_FORM_META_DATA })
    const res = await api.bot.postMetaDataBot(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: BOT_FORM_META_DATA_RESULT, data: result })
    } else {
      dispatch({ type: BOT_FORM_META_DATA_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: BOT_FORM_META_DATA_RETRY, message: e.message })
  }
}

export const getBotFormList = () => async dispatch => {
  try {
    dispatch({ type: FETCH_BOT_LIST })
    const res = await api.bot.getBotFormList()
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_BOT_LIST_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_BOT_LIST_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_BOT_LIST_RETRY, message: e.message })
  }
}
