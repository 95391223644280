import staticRoutes from './staticRoutes'
import dynamicRoutes from './dynamicRoutes'
import routes from '../routes'

export default [
  ...staticRoutes,
  ...dynamicRoutes
]

export const isRouteEqual = (path, name) => {
  return routes.filter(x => (x.name === name && x.path === path)).length > 0
}
