import { Images } from '../../constants'

export const ayurvedaList = [
  {
    id: 1,
    title: 'Ayurvedic Ingredient Potency Score (AIPS) and Scientific Research Quotient (SRQ)',
    to: '/ingredient/aips-and-srq',
    img: Images.ingredient.aips_srq,
    content: 'Ayurvedic Ingredient Potency Score (AIPS) and Scientific Research Quotient (SRQ) are novel concepts developed by Misters.in to measure the potency of an ayurvedic product and the scientific relevance of the ingredients used.'
  },
  {
    id: 2,
    title: 'All you need to know about living the Bold life',
    to: '/ingredient/bold-with-ashwagandha-gokshura-benefits',
    img: Images.bold.bold,
    content: 'Bold is a Misters proprietary blend capsule of 100% natural herbal ingredients containing 19 Ayurvedic herbs consisting of Gokshura, Shilajit, Ashwagandha and more that have a positive influence on male function for a more satisfying love life proving helpful for conditions like Erectile Dysfunction (ED). Frequently asked questions (FAQ’s) regarding this capsule such as its effects, how it functions, it’s constituents and why you should choose it are answered below.'
  },
  {
    id: 3,
    title: 'Hair care, the Ayurvedic way',
    to: '/ingredient/hair-bhringraj',
    img: Images.ingredient.hair_mask,
    content: 'An individual sheds and regrows hair cyclically, throughout their lives. Although, hair care can be challenging given conditions like Androgenic Alopecia or Male Pattern Baldness that are now becoming increasingly common among men across all ages. The Misters.in Hair Growth Pack for Men paves a way for natural, long-lasting hair health achieved through a proprietary medicinal blend of 17 plants such as Neem, Bhringraj, Shilajit, Amla, Lemongrass and more, each having scientifically proven benefits for your scalp, roots, follicles and overall hair. The frequently asked questions (FAQ’s) pertaining to this topic are mentioned below. Stay informed about your health by reading further Mister!'
  },
  {
    id: 4,
    title: 'Be bold in bed with Bhringraj',
    to: '/ingredient/bhringraj',
    img: Images.ingredient.bhringrajDescription,
    content: 'Conventional medicines play a vital role in maintaining the good health of any individual. According to recent studies, about three quarters of the world\'s population is dependent upon different parts of medicinal plants for their physical and mental well-being. In India, one such well known plant is Bhringraj that is also referred to as the “King of Hair”. Bhringraj is widely famous by the name ‘false daisy’ as well. The frequently asked questions (FAQ’s) around Bhringraj are its uses, benefits, alternative names, description, where it can be found, its ayurvedic properties and the like. At Misters, we aim to answer all this and more…'
  },
  {
    id: 5,
    title: 'Jaiphal- the super spice for sexual health',
    to: '/ingredient/jaiphal',
    img: Images.ingredient.jaiphalDescription,
    content: 'Jaiphal, also referred to as nutmeg, is considered as an important Ayurvedic spice in the kitchen. The best quality Jaiphal is the one that emits a fragrance because it is known to have a strong aromatic odor. Another way to identify the quality of Jaiphal is one with a shallow covering and nutmeg that is easily breakable. The frequently asked questions (FAQ’s) around Jaiphal often include its description, benefits, properties, alternative names, grades and the like. At Misters, we aim to answer all this and more…'
  },
  {
    id: 6,
    title: 'Safed Musli- to treat low potency',
    to: '/ingredient/safed-musli',
    img: Images.ingredient.safedDescription,
    content: 'Safed Musli is considered as one of the important herbs in the Indian Ayurvedic system of medicine. It is known for improving the overall immunity of an individual. In addition, it has a plethora of benefits in the field of sexual health. The Vajikarana (aphrodisiac) property of Safed Musli has proven to be beneficial for various problems like erectile dysfunction, impotence and low potency among males. The frequently asked questions (FAQ’s) around Safed Musli often include its alternative  names, where it is found, how it helps bodybuilders and its benefits, to name a few. At Misters, we aim to answer all of this and more…'
  },
  {
    id: 7,
    title: 'Shatavari - for a steady sperm count',
    to: '/ingredient/shatavari',
    img: Images.ingredient.shatavariDescription,
    content: 'Shatavari has numerous health benefits and is extensively used to keep male sexual problems in check as well. These include the management of erectile dysfunction, oligospermia, spermatogenic irregularities and painful micturition, to name a few. The frequently asked questions (FAQ’s) around Shatavari, often entail its benefits, price, where it is found, its description, its alternative names, and the like.  At Misters, we aim to answer all these questions and more…'
  },
  {
    id: 8,
    title: '‘S’ for sex, stamina and Shilajit',
    to: '/ingredient/shilajit',
    img: Images.ingredient.shilajitDescription,
    content: 'Shilajit, a popular rasayana compound of long-surviving Ayurvedic medicine is gifted with effective medicinal properties that are painstakingly formed over hundreds of years. It is known to enhance testosterone levels in men, boost overall immunity, decrease overall body fat and reduce fatigue, exponentially. The frequently asked questions (FAQ’s) on Shilajit are often centered around its multiple names, benefits, prices, its rich history and where it is found etc. At Misters, we hope to answer all these and more…'
  },
  {
    id: 9,
    title: 'Ashwagandha- a trusted sexual stimulant',
    to: '/ingredient/ashwagandha',
    img: Images.ingredient.ashwagandhaDescription,
    content: 'Ashwagandha, the evergreen Indian plant is not an unfamiliar name, especially in Indian households. It is known to provide power and vitality like that of a strong and active horse. Moreover, it is recognized for improving overall sexual functions and even boosting the rate of fertility among men.The frequently asked questions (FAQ’s) on Ashwagandha are often centered around its multiple names, benefits, prices, its rich history and where it is found etc. At Misters, we hope to answer all these and more…'
  },
  {
    id: 10,
    title: 'Amla- your mantra for a long-lasting sex life',
    to: '/ingredient/amla',
    img: Images.ingredient.amlaDescription,
    content: 'Amla, popularly known as Indian gooseberry is a fruit of a deciduous tree of the Phyllanthaceae family. The scientific name of Amla is Phyllanthus emblica. It is known to enhance an individual’s overall mood, boost their sexual stamina and desire and also strengthens immunity. The frequently asked questions (FAQ’s) on Amla are often centered around its multiple names, benefits, prices, its rich history and where it is found etc. At Misters, we hope to answer all these and more…'
  },
  {
    id: 11,
    title: 'Kaunch Beej- for your testosterone troubles',
    to: '/ingredient/kaunch-beej',
    img: Images.ingredient.kaunchBeejDescription,
    content: 'Kaunch Beej is also referred to in Latin, as Mucuna pruriens. Derived from the Kapikacchu herb, it is known to heal sexual weakness in men, boost their testosterone levels and also keep impotency in check.The frequently asked questions (FAQ’s) on Kaunch Beej are often centered around its multiple names, benefits, prices, its rich history and where it is found etc. At Misters, we hope to answer all these and more…'
  },
  {
    id: 12,
    title: 'Gokshura- The testosterone booster',
    to: '/ingredient/gokshura',
    img: Images.ingredient.gokshuraDescription,
    content: 'Gokshura, an exceedingly well known health supplement, is also one of the most potent Ayurvedic extracts. The scientific name of Gokshura is called Tribulus terrestris. It is known to effectively increase sperm production and testosterone levels in males, and even improve kidney and urinary functions. The frequently asked questions (FAQ’s) on Gokshura are often centered around its multiple names, benefits, prices, its rich history and where it is found etc. At Misters, we hope to answer all these and more…'
  },
  {
    id: 13,
    title: 'Kesar- an alluring aphrodisiac',
    to: '/ingredient/kesar',
    img: Images.ingredient.kesarDescription,
    content: 'Kesar, a popular member of the Crocus sativus family, is a wonder spice with indispensable qualities that improve overall health. It is known to significantly improve the health of men suffering from erectile dysfunction and also serves as a popular aphrodisiac, aiding males who suffer from depression.The frequently asked questions (FAQ’s) on Kesar are often centered around its multiple names, benefits, prices, its rich history and where it is found etc. At Misters, we hope to answer all these and more…'
  }
]
