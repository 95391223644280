import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { Images } from '../../../constants'

const SummaryFrame2Card = function ({ title, text }) {
  return (
        <div className="summary-frame2-card-container">
            <h4 className="mb-5 font-weight-light text-center">
                Aahar and Vihaar <span className="text-danger">exclusions</span>
            </h4>
            <div className="summary-frame2-card">
                <img className="summary-frame2-image-top-left" src={Images.report.orange} alt=""/>
                <img className="summary-frame2-image-top-right" src={Images.report.cycle} alt=""/>
                <img className="summary-frame2-image-bottom-left" src={Images.report.watch} alt=""/>
                <img className="summary-frame2-image-bottom-right" src={Images.report.wineGlass} alt=""/>
                <div dangerouslySetInnerHTML={{ __html: text }}/>
            </div>
        </div>
  )
}

SummaryFrame2Card.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
}

SummaryFrame2Card.defaultProps = {
  title: '',
  text: ''
}

export default withRouter(SummaryFrame2Card)
