// External Imports
import React from 'react'
import { Route, Switch } from 'react-router'
import { ToastContainer } from 'react-toastify'
// My Imports
import routes from './routes'
import { getStorageItem } from './utils'
import store from './store'
import { setUserProfile } from './actions/accounts/profile'
import { USER } from './constants'

class App extends React.Component {
  async componentDidMount () {
    store.dispatch(setUserProfile(JSON.parse(getStorageItem(USER, '{}'))))
  }

  render () {
    return (
            <div>
                <Switch>
                    {
                        routes.map(
                          (route, index) => <Route key={index} {...route} />
                        )
                    }
                </Switch>
                <ToastContainer position="top-right"
                                autoClose={4000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover/>
            </div>
    )
  }
}

export default App
