import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2, accordionList3 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'

class Jaiphal extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props

    return (
            <Page meta={{
              title: 'Jaiphal- the super spice for sexual health | Misters',
              path: `${match.params.language}/ingredient/jaiphal/`,
              description: 'Jaiphal, also referred to as nutmeg, is considered as an important Ayurvedic spice in the kitchen. The best quality Jaiphal is the one that emits a fragrance because it is known to have a strong aromatic odor. Another way to identify the quality of Jaiphal is one with a shallow covering and nutmeg that is easily breakable. The frequently asked questions (FAQ’s) around Jaiphal often include its description, benefits, properties, alternative names, grades and the like. At Misters, we aim to answer all this and more…'
            }}>
                <div className="ingredient-header-img"
                     style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>Jaiphal- the super spice for sexual health</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What is Jaiphal?</h2>
                            <p>
                                Jaiphal, also referred to as <b>Nutmeg</b>, is considered as an essential spice
                                according to ancient Indian ayurvedic medicine. Jaiphal is easily accessible in the
                                Indian kitchen in the form of a spice. It is known to have <b>multiple health
                                benefits</b> which enable individuals to maintain their health both internally and
                                externally. It has been proven to help individuals to <b>lead healthy sexual
                                lives</b> as well.
                            </p>
                            <br/>
                            <h2>What are the different grades of Jaiphal?</h2>
                            <p>
                                East India nutmeg or Jaiphal is available in <b>three grades</b>. The <b>first
                                grade</b> is called <b>Banda Nutmeg</b> which is considered to be the <b>finest</b> to
                                use. It contains upto <b>8% of essential oil</b>. The <b>second grade</b> is <b>Siauw
                                Nutmeg</b>. It is almost as good as Banda nutmeg and contains <b>6.5% of essential
                                oil</b>. The <b>third</b> and final grade is <b>Penang Nutmeg</b> that is generally <b>wormy,
                                moldy</b> and is <b>suitable only for distillation purposes</b>.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker image={Images.ingredient.jaiphalDescription} to={shopUrl}
                                          buttonText={'SHOP'}/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList3}
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

export default Jaiphal
