// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../common/AccordionCard'
import CategoryBreaker from '../common/CategoryBreaker'
import { Images } from '../../../constants'
import Card from 'react-bootstrap/Card'

const shopUrl = '/en/shop'

export const accordionList1 = [
    <AccordionCard key={'0'} title="What are the causes of Premature Ejaculation?">
        <p>Premature Ejaculation (PE) or the condition under which a man releases
            semen before or immediately after penetration is a sexual dysfunction
            having both physiological and psychological causes.</p>
        <p>Earlier Premature Ejaculation (PE) was considered to have solely
            psychological roots, but, today it has been scientifically proved that a
            multifarious interface of biological and psychological factors lead to
            sexual dysfunction.</p>
        <p>It is now globally accepted that serotonin or a natural substance
            produced by nerves, plays a critical role in Premature Ejaculation (PE).
            While greater levels of this element in the brain raise the time for
            ejaculation, lower levels diminish it and cause Premature Ejaculation
            (PE).</p>
        <p>However, the precise cause of Premature Ejaculation (PE) is yet to be
            discovered.</p>
        <p>Premature Ejaculation (PE) can be a lifelong condition or an acquired
            one. In the first case, Premature Ejaculation (PE) will happen right
            from the first time and will continue to recur in all subsequent sexual
            acts. In the latter, untimely or premature ejaculation occurs in a later
            phase, after experiencing normal and timely ejaculation for a certain
            period of time.</p>
        <p>In both cases, a number of factors are instrumental, but psychological
            causes are particularly responsible for lifelong PE.</p>

        <h3>What causes lifelong premature ejaculation?</h3>
        <ul>
            <li>
                <strong>Self-conditioning</strong> - During adolescence, guilt often
                makes boys ejaculate quickly to avoid embarrassment and getting
                caught by family members. This can lead to lifelong premature
                ejaculation syndrome.
            </li>
            <li>
                <strong>Trauma</strong> - Traumatic experiences, abuse or violence,
                during childhood can leave a man permanently anxiety-prone, which in
                turn can lead to lifelong PE.
            </li>
            <li>
                <strong>Socio-cultural causes</strong> - At times, cultural
                practises and beliefs also work behind lifelong PE. Societies where
                sex is considered inappropriate and shameful or is a taboo, makes
                men uncomfortable in the act. The indoctrinated mind withholds easy
                participation and can potentially lead to lifelong PE.
            </li>
            <li>
                <strong>Genetic causes</strong> - Sometimes, lifelong PE is
                genetically inherited.
            </li>
        </ul>

        <h3>What causes acquired premature ejaculation?</h3>
        <p>Both biological and emotional issues lead to acquired PE among men. The
            chief causes are -</p>
        <ul>
            <li>
                <strong>Biological causes</strong> - Underlying diseases including
                high blood pressure and sugar, thyroid issues and ailments of the
                prostrate are the most common biological causes of acquired PE.
                Besides, abnormal levels of hormone or neurotransmitters,
                inflammation and infection of the prostate or urethra also lead to
                PE. At times serious sicknesses like that of multiple sclerosis
                which damage the nerves can lead to acquired premature ejaculation.
            </li>
            <li>
                <strong>Emotional causes</strong> – Such causes include stress,
                depression, early sexual experiences or abuse, poor body image,
                performance anxiety, and guilt while involved in clandestine sex.
                Nervousness with a new partner, long periods of celibacy are other
                common causes of acquired PE.
            </li>
            <li>
                <strong>Relationship issues</strong> – Issues in couples like
                conflicts, resentment, different sexual needs, high expectations,
                and lack of communication or preparation for intercourse majorly
                contribute to PE.
            </li>
            <li>
                <strong>Addiction</strong> - Binge drinking and other addictions are
                also responsible for the condition.
            </li>
        </ul>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the symptoms of PE?">
        <p>Ejaculation or the release of semen from the penis during the sexual act
            is a process controlled by the central nervous system. Once aroused, the
            brain of a man sends signals to his genitals to release semen.</p>
        <p>Though there isn’t any definite timing for ejaculation, one that happens
            before intercourse or within a minute of arousal, is largely known as
            Premature Ejaculation (PE).</p>
        <p>Ejaculation happening before it is desired can get frustrating not only
            for the man but also for his partner as it takes away pleasure from the
            sexual act. With untimely ejaculation, the man loses his erection and
            the sexual act comes to an abrupt halt. This, when continues for long,
            gets bothersome and in turn, affects relationships.</p>
        <p>Occasional or intermittent Premature Ejaculation (PE) is not a cause of
            concern as it happens to 30 to 40 per cent of men and gets cured
            automatically after some weeks or in a couple of months.</p>
        <p>Only when Premature Ejaculation (PE) continues consistently for more than
            three months, that one needs medical attention. Premature Ejaculation
            (PE) or the inability to control semen release is primarily associated
            with intercourse, but the condition may occur in any sexual situation,
            including masturbation.</p>
        <p>Premature Ejaculation (PE) - be it, lifelong (primary), i.e. occurring
            every time since the beginning, or Acquired (secondary) i.e. happening
            later in life after experiencing a period of normal ejaculation -
            exhibit almost similar kinds of symptoms.</p>
        <h3>Key symptoms of Premature Ejaculation (PE)</h3>
        <p>A lot of misconceptions exist regarding Premature Ejaculation (PE). Men
            at times wrongly consider certain signs as symptoms of this sexual
            dysfunction which in reality do not meet the medical criteria.</p>
        <p>It is also possible to have variable ejaculatory patterns in which
            periods of fast or untimely ejaculation is followed by normal and timely
            ones. Such patterns are not symptoms of Premature Ejaculation (PE). Some
            key symptoms of PE include:</p>
        <ul>
            <li>Ejaculation or release of semen by the penis with very little sexual
                trigger
            </li>
            <li>Inability to control the release of semen during intercourse</li>
            <li>Diminished sexual pleasure both in the man and his partner because
                of untimely ejaculation
            </li>
            <li>Guilt, discomfiture or irritation due to incomplete or rushed sex
            </li>
        </ul>
    </AccordionCard>,
    <AccordionCard key={'2'} title="When should you seek medical attention for PE?">
        <p>If the symptoms continue unabated for more than three months, medical
            care is a must. While talking to a doctor, the following things are
            important to consider - </p>
        <ul>
            <li>Talk openly about the exact symptoms, including the time of
                ejaculation, nature of erection and time taken to get one.
            </li>
            <li>The feeling of embarrassment only hampers treatment as it stops
                proper disclosure of the condition and its exact symptoms to the
                doctor. Without proper understanding, proper diagnosis becomes
                difficult.
            </li>
        </ul>
        <p>If the identified symptoms are not convincing enough for the doctor, a
            medical examination of the prostate and neurological tests are
            prescribed in order to reach a definite conclusion about Premature
            Ejaculation (PE).</p>
    </AccordionCard>,
    <AccordionCard key={'3'} title="What are the risks of Premature Ejaculation?">
        <p>Premature Ejaculation (PE), a common and medically manageable disease, if
            not addressed timely, can involve a number of risks and complications.
            Outcomes of Premature Ejaculation (PE) not only affects the personal
            life of the man but also disrupts the life of his partner, and if
            married, that of the entire family.</p>
        <p>
            <strong><em>Most common risks associated with Premature Ejaculation (PE)
                are:</em></strong>
        </p>
        <p><strong>Stress and relationship problems</strong> - Relationship stress
            is an inevitable consequence of long term Premature Ejaculation (PE). A
            number of factors resulting from Premature Ejaculation (PE) lead to
            relationship issues, including- </p>
        <ul>
            <li>Lack of Satisfaction in sex which affects the quality of sexual
                intimacy
            </li>
            <li>Frustration and distress experienced by both the man and the women
            </li>
            <li>Lack of confidence in the man’s sexual potency</li>
        </ul>
        <p>All these have a negative effect on the overall quality of a man-woman
            relationship. Premature Ejaculation (PE) often takes a toll on the
            self-esteem of a man and causes marital conflicts. Men afflicted with
            this condition find it difficult to relax during the sexual act and tend
            to avoid discussing the issue with partners. This significantly
            decreases the frequency of sexual intimacy between couples. Severe and
            prolonged Premature Ejaculation (PE) can also result in constant
            conflict between partners, estrangement, separation or divorce.</p>

        <p><strong>Fertility problems</strong> - Premature Ejaculation (PE) can have
            more serious consequences as it thwarts intravaginal ejaculation without
            which fertilization and pregnancy become difficult if not impossible.
            Hence, couples planning a family can be greatly impacted if the man is
            afflicted with Premature Ejaculation (PE).</p>
        <p><strong>Risks associated with single men</strong> – Identifying Premature
            Ejaculation (PE) can create distress in the lives of single men and his
            parents; as such a man will be reluctant to take partners in his life or
            to get married. Existing social taboo often makes men hide such
            afflictions from family or doctors, which only aggravates the already
            distressing situation.</p>
        <p><strong>Side effects of treatment</strong> – Though Premature Ejaculation
            (PE) is not known to have caused direct morbidity or mortality,
            treatment of this condition can have side effects. Drug treatment of
            Premature Ejaculation (PE), even if prescribed by doctors, is often
            associated with adverse effects.</p>
        <p>Dapoxetine, a commonly used drug for treating both primary and secondary
            PE, is known to have side-effects like headache, queasiness, diarrhoea,
            and giddiness. Also, treatment of Premature Ejaculation (PE) may be a
            very long term process just like lifelong therapies needed for
            depression, high blood pressure and diabetes.</p>
        <p>Risks associated with Premature Ejaculation (PE) are not only limited to
            sexual health but also affects all aspects of life with both direct as
            well as collateral damage. Time has come to recognise sexual
            dysfunctions like Premature Ejaculation (PE) as serious public health
            issues.</p>
        <CategoryBreaker to={shopUrl} buttonText={'SHOP'} image={Images.cgPme2}
                         text={'Did you know that it is a myth that alcohol can control premature ejaculation?'}/>
    </AccordionCard>,
    <AccordionCard key={'4'} title="What is the treatment of PE?">
        <p>Treatments of Premature Ejaculation (PE) exist in many forms and are
            suggested by doctors either in a single way approach or through combined
            applications of two or more techniques.</p>
        <p>The options available for Premature Ejaculation (PE) cure include sexual
            or behavioural techniques, topical anaesthetics, medications through
            prescribed drugs and psychological counselling.</p>

        <h3>Pharmacologic or drug therapy</h3>
        <p>Topical anaesthetics creams and sprays having numbing agents like
            benzocaine, lidocaine or prilocaine, are often prescribed for Premature
            Ejaculation (PE) cure. These are administered on the penis 15 minutes
            prior to having sex in order to diminish penile sensation, which helps
            in delaying ejaculation.</p>
        <p>Prescribed oral drugs for treating Premature Ejaculation (PE), available
            both on-demand or daily use, include –</p>
        <ul>
            <li>Selective serotonin reuptake inhibitor (SSRI)s like - sertraline,
                paroxetine, fluoxetine, citalopram, or dapoxetine
            </li>
            <li>Phosphodiesterase type 5 (PDE5) inhibitors like- sildenafil,
                tadalafil, or possibly vardenafil
            </li>
            <li>Other agents like - pindolol or tramadol</li>
        </ul>

        <h3>Behavioural techniques to cure PE</h3>
        <p>Sexual or behavioural techniques are a very common remedy and involve
            some very simple measures like that of masturbating an hour or 45
            minutes prior to any sexual encounter with a partner which apparently
            helps in delaying ejaculation during intercourse.</p>
        <p>Doctors often recommend a break from sexual activities and suggest
            engagement in different sorts of playful sexual acts which remove
            performance anxiety in men.</p>
        <p>Frail or infirm pelvic floor muscles thwart the ability to delay
            ejaculation; hence exercises like pelvic floor exercises and
            pause-squeeze technique are prescribed to build these muscles</p>

        <h3>Do condoms help in managing PE?</h3>
        <p>Condoms, especially the Climax control variety, are known to diminish the
            sensitivity of the penis, which helps in delaying ejaculation. Climax
            control condoms come with numbing agents like benzocaine or lidocaine or
            are manufactured with thicker latex in order to delay ejaculation.</p>

        <h3>How does sex therapy or counselling help in managing PE?</h3>
        <p>Psychological counselling sessions with therapists are also very
            effective in Premature Ejaculation (PE) cure. The disorder often leads
            to distress, anxiety, and even emotional distance from the partner.</p>
        <p>Sharing the symptoms, anxieties and exact nature of the disorder helps
            immensely in curbing performance anxiety and finding solutions for
            managing stress. Counselling is often used alongside drug therapy.</p>

        <h3>Does Ayurveda cure PE?</h3>
        <p>Various other remedies like Ayurveda, yoga, meditation and acupuncture
            are available for treating Premature Ejaculation (PE). The efficacy of
            these treatments is yet to be scientifically established.</p>

        <h3>How can your partner help in curing PE?</h3>
        <p>The available treatments work best when the partner is equally involved
            in the process, including being present in the counselling sessions.</p>
        <p>The effort is taken by the partner in relieving underlying performance
            anxiety from the man also goes a long way in improving the
            condition.</p>
        <p>Additionally, a second attempt at coitus –after an initial experience of
            premature ejaculation, is generally better as the ejaculatory control
            improves subsequently.</p>
    </AccordionCard>,
    <AccordionCard key={'5'} title="What is the science behind ejaculation?">
        <p>All-natural actions of the human body are scientific and occur in a
            thoroughly logical and coordinated manner, primarily controlled by the
            brain. Ejaculation is no different.</p>
        <p>In order to understand the science behind ejaculation, it is important to
            understand the anatomy of the penis and the science behind erection.</p>
        <p><strong>The penis has a very complex structure and is made of -</strong>
        </p>
        <ul>
            <li>Corpora cavernosa, which is a twin-chamber running along the entire
                length of the penis and consisting of a network of blood vessels
                having a sponge-like texture.
            </li>
            <li>The urethra, or the conduit for urine and sperm, runs alongside the
                base of corpora cavernosa
            </li>
            <li>Erectile tissues enclosing the urethra, both the primary arteries
                and a number of nerves and veins
            </li>
            <li>The shaft, which is the longest component of the male sex organ</li>
            <li>The glans located at the head of the shaft</li>
            <li>The meatus, or cavity at the tip of the glans from which urine/semen
                are discharged or ejaculated
            </li>
        </ul>

        <h3>How does an erection happen?</h3>
        <p>Erection is initiated from stimulation, either mental or sensory, which
            then leads to arousal. Once aroused, nerve messages start exciting the
            male sex organ or penis, whereas signals from the brain make the muscles
            of the corpora cavernosa relax and open up.

            <br/> <br/> This helps in rapid blood flow into the opened up spaces.
            The blood fills up the cavernous arteries and exerts pressure on the
            corpora cavernosa, which inflates the penis and makes it erect.

            <br/> <br/> The membrane surrounding the corpora cavernosa, known as
            tunica albuginea, locks in the blood in the corpora cavernosa and helps
            in sustaining an erection. Once the muscles in the penis contract, the
            blood flows into the corpora cavernosa stops, outflow channels open up,
            which in its process reverses the erection.
        </p>

        <h3>How does ejaculation happen?</h3>
        <p>Sexual excitement or act on reaching its optimum level through
            stimulation and friction triggers ejaculation - a reflex action
            controlled by the central nervous system. The shaft and the glans have a
            skin covering which is stuffed with nerve endings highly responsive to
            pressure and pulsation.
            <br/><br/>
            Fondling the skin creates impulses which are sent to the brain by the
            nerves. On receiving the message from the skin as well as from other
            body parts of an aroused man, the brain reacts by making the skin
            further sensitive to stimulation.
            <br/><br/>
            With enough stimulation, arousal culminates into orgasm through
            ejaculation of semen. Here too, a flurry of activities are involved in
            order to coordinate the discharge of sperm from testes, production of
            semen, and the last few contractions that eject semen out through
            orgasm.
        </p>

        <h3>What are the stages of ejaculation?</h3>
        <p>To start with, the sperms are taken out from their storage space and are
            aptly positioned for movement. The muscles in the walls of the penis
            contact and create a conveyor-belt like a process which move the thick
            masses of sperm away from the epididymis, or the place of their growth.
            <br/><br/>
            Peristaltic wave-like contractions of the pipe-like structures storing
            and transporting sperms from testes (vas deferens), keeps squeezing and
            pushing the sperms towards the tip of the penis.
            <br/><br/>
            Meanwhile, the prostate gland and seminal vesicles discharge secretions
            to produce the semen which is a complex mix of sperm and secretions.
            Further contraction forces the semen into the urethra.
            <br/><br/>
            The urethral bulb swells up with the inflow of semen and gets inflated
            up to 2 to3 times of its normal size. Ejaculation is now inescapable.
            <br/><br/>
            The muscles that encircle the urethral bulb together with the muscles
            surround the urethra proper create a muscular pump like force. As a
            result of this, muscle contractions at the tip of the penis occur every
            0.8 seconds which toss semen out with an astounding amount of force.
            <br/><br/>
            This propelling can happen in about five spurts. In the initial spurts,
            semen can dash through 1-2 feet in the air or more. After the first few
            strong contractions, the muscles continue to contract with lesser vigour
            for many more seconds, ejecting 2 to 5 millilitres of semen out of the
            male body.
            <br/><br/>
            Meanwhile, muscles located at the bladder constrict and shut the bladder
            opening, preventing not only the backward flow of semen but also
            stooping urination during orgasm. The entire process of ejaculation
            takes nearly three seconds and is accompanied by a sense of
            inevitability and pleasure called orgasm.
        </p>
        <CategoryBreaker to={shopUrl} buttonText={'SHOP'} image={Images.cgPme3}
                         text={'Did you know that the STOP-START method helps in managing PE?'}/>
    </AccordionCard>,
    <AccordionCard key={'6'} title="What is the science of Premature Ejaculation?">
        <p>
            The first evidence‐based definition for Premature Ejaculation (PE) was
            developed by The International Society for Sexual Medicine (ISSM) Ad Hoc
            Committee, in 2007.
            <br/> <br/>
            Dr Serefoglu and his colleagues, who were members of the Committee,
            provided an integrated definition of Premature Ejaculation (PE) both
            lifelong and acquired. The society was established in 1978 for the
            purpose of promoting research and exchange of knowledge for the clinical
            entity “impotence” throughout the international scientific community.
            <br/> <br/>
            According to the committee, Premature Ejaculation (PE) is a male sexual
            dysfunction characterized by -
        </p>
        <ol>
            <li>Ejaculation that always or nearly always occurs prior to or within
                about 1 minute of vaginal penetration from the first sexual
                experience (lifelong) or a clinically significant and bothersome
                reduction in latency time, often to about 3 minutes or less
                (acquired);
            </li>
            <li>The inability to delay ejaculation on all or nearly all vaginal
                penetrations; and
            </li>
            <li>Negative personal consequences, such as distress, frustration,
                and/or the avoidance of sexual intimacy.
            </li>
        </ol>
        <p>
            Ejaculation is a reflex action monitored by the central nervous system
            of the human body. Only aroused or sensually stimulated messages from
            the male sex organs are sent to the spinal cord and brain.
            <br/> <br/>
            When the level of excitement reaches its climactic stage, instructions
            are sent back by the brain to the male genitals, making ejaculation, or
            tossing out of semen from the penis, possible. Any disruption in the
            process can lead to untimely messages to the reproductive organs and
            cause early ejaculation or what is known as, Premature Ejaculation (PE).
            <br/> <br/>
            Earlier, Premature Ejaculation (PE) was considered to have solely
            psychological causes like stress, depression and relationship issues.
            However, today medical science has established that factors pertaining
            to both the body and the mind can cause Premature Ejaculation (PE).
            <br/> <br/>
            If premature ejaculation becomes a recurrent phenomenon and constantly
            interferes with pleasure, it should be considered as a medical problem,
            and it is important to talk to an expert and find the root cause.
        </p>
    </AccordionCard>,
    <AccordionCard key={'7'} title="What factors affect premature ejaculation (PE)?">
        <p>A number of body constituents and parts play important roles in causing
            Premature Ejaculation (PE). Here are the most important ones -
            <br/><br/>
            <strong>Serotonin</strong>
            <br/><br/>
            The constituency of the human brain is believed to have a role in
            Premature Ejaculation (PE). Men with relatively lower levels of
            serotonin, a chemical substance, are likely to take less time to
            ejaculate.
            <br/><br/>Serotonin, when less active between nerves located in that
            part of the brain which controls ejaculation, causes early ejaculation
            and can lead to the medical condition of primary Premature Ejaculation
            (PE).
            <br/><br/>Serotonin also plays a critical role in the entire sexual
            activity and also in desire for sex. It also enables the passage of
            signals from one neuron to another. Lack of active serotonin hampers the
            signal transfer process in men during the sexual act.
            <br/><br/>The level of serotonin along with its state of activeness is
            determined by a gene, named 5-HTTLPR <em>(serotonin-transporter-linked
                polymorphic region, or- the gene that codes for the serotonin
                transporter)</em>.
            <br/><br/>Of the three types of Carriers present in HTTLPR - LL, SL and
            SS - LL results in fast ejaculation. Hence, men with LL will ejaculate
            twice as faster than those with SS or SL genes.
            <br/><br/>According to medical science, Premature Ejaculation (PE) can
            occur in men having problems with erection, i.e. those with Erectile
            Dysfunction (ED). When a man is afflicted with Erectile Dysfunction (ED)
            the penis will not be able to achieve or hold on to erection firm enough
            for sex.
            <br/><br/>This results in the anxiety of losing an erection too soon and
            leads to a pattern of rushing to ejaculate, thereby causing Premature
            Ejaculation (PE), eventually. It often becomes a deeply rooted habit,
            extremely hard to give up.
            <br/><br/>
            <strong>Pelvic floor muscles</strong>
            <br/><br/>Pelvic floor muscles can also contribute to Premature
            Ejaculation (PE). Strong pelvic floor muscles increase penile strength
            and hardness and help in penetration and thrust.
            <br/><br/>Hence Pelvic floor muscle dysfunction can cause a weak
            erection and in the long run, Premature Ejaculation (PE).
            <br/><br/><strong>Age</strong>
            <br/><br/>Premature ejaculation is mostly age agnostic. However, age
            does affect erections and the pattern of ejaculation. Erection in aged
            men is not as firm or as long as that of the young. Age also increases
            the apprehension of rapid ejaculation.
            <br/><br/>All these can lead to a Premature Ejaculation (PE) syndrome.
        </p>
        <CategoryBreaker to={shopUrl} buttonText={'SHOP'} image={Images.cgPme4}
                         text={'Did you know women can also have premature ejaculation?'}/>
    </AccordionCard>,
    <AccordionCard key={'8'} title="What treatments are available for PE?">
        <p>
            An array of cure exists for treating Premature Ejaculation (PE)
            including behavioural and sexual techniques, topical anaesthetics,
            medications and psychological therapy. While a single pronged treatment
            works for some men, others need a combined approach.
            <br/><br/>
            Medical science has not yet found a medication dedicated solely for
            Premature Ejaculation (PE) cure. Doctors mostly suggest medication used
            in treating other syndromes but having the potential to cure Premature
            Ejaculation (PE).
            <br/><br/>Prescription medication has always been a very effective
            procedure in Premature Ejaculation (PE) cure. These drugs delay orgasm
            up to several minutes as a common side effect and work well for treating
            Premature Ejaculation (PE).
            <br/><br/>The commonly prescribed drugs used for the treatment of
            Premature Ejaculation (PE) are –
            <br/><br/><strong>Selective serotonin reuptake inhibitors
            (SSRI)s</strong>
        </p>
        <ul>
            <li>Fluoxetine (Prozac),</li>
            <li>Paroxetine (Paxil) or sertraline (Zoloft);</li>
            <li>Tricyclic antidepressants, like clomipramine (Anafranil).</li>
        </ul>
        <p>
            <strong>Phosphodiesterase inhibitors </strong>
            <br/><br/>
            These drugs also work extremely well for men with premature ejaculation
            and can be taken alone or with an SSRI. Prescribed A mixture in this
            category include –
        </p>
        <ul>
            <li>Sildenafil (Viagra)</li>
            <li>Vardenafil (Levitra)</li>
            <li>Tadalafil (Cialis)</li>
        </ul>

        <p>
            <strong>Topical anaesthetics</strong>
            <br/><br/>
            The numbing agents of anaesthetic creams and sprays are very effective
            in Premature Ejaculation (PE) cure. Drugs in this category are –
        </p>
        <ul>
            <li>Benzocaine</li>
            <li>Lidocaine or prilocaine</li>
        </ul>
        <p><strong><em>** Advice given here should not be considered as medical
            advice, and the reader should always take doctor's
            opinion.</em></strong></p>
        <CategoryBreaker to={shopUrl} buttonText={'SHOP'} image={Images.cgPme5}
                         text={'Did you know PE was a \'strength\' in the evolution of Homo sapiens?'}/>
    </AccordionCard>,
    <AccordionCard key={'9'} title="How to manage premature ejaculation (PE)?">
        <p>
            A number of factors, both emotional and biological, lead to Premature
            Ejaculation (PE). Addressing the root causes of these factors can help
            prevent Premature Ejaculation (PE) to a large extent.
            <br/><br/>Here are some of the effective ways to prevent Premature
            Ejaculation (PE):
            <br/><br/><strong>Dealing with social taboos - </strong>Socio-cultural
            practises and taboo often lead adolescent boys towards self-conditioning
            for quick ejaculation. This, in turn, can lead to Premature Ejaculation
            (PE) in future.
            <br/><br/>Care should be taken to provide an open and healthy atmosphere
            in the family, so that young boys are not forced into such unhealthy
            self-conditioning.
            <br/><br/>The pervasive misconception about sex in Indian society, which
            considers sex a shameful act also needs to be addressed to prevent
            Premature Ejaculation (PE).
            <br/><br/>Many indoctrinated adult men feel uncomfortable in the sexual
            act as they consider it inappropriate; this also leads to Premature
            Ejaculation (PE).
            <br/><br/><strong>Trauma – </strong>
            Any childhood trauma can have ramifications in adulthood in the form of
            Premature Ejaculation (PE). Timely treatment of such traumas through
            counselling and medication is essential to in order to prevent the
            disorder.
            <br/><br/><strong>Health issues - </strong>
            Diseases like high blood pressure and sugar, thyroid issues, ailments of
            the prostrate, hormonal imbalance, inflammation and infection of the
            urethra can cause Premature Ejaculation (PE). Addressing health issues
            on time can prevent the disorder.
            <br/><br/><strong>Stress management – </strong>
            Depression, early sexual experiences or abuse, poor body image,
            performance anxiety, and guilt greatly contribute to Premature
            Ejaculation (PE). Proper management of these issues through
            communication and counselling helps in preventing the affliction
            considerably.
            <br/><br/><strong>Relationship management – </strong>
            Tackling relationships with effective communication, understanding,
            empathy and love, keeps away conflicts, resentment and dissatisfaction –
            all of which lead to relationship issues and can cause Premature
            Ejaculation (PE).
            <br/><br/><strong>Sex education - </strong>
            Sex education is extremely important as it prepares a man for
            intercourse, in the absence of which, he can face anxiety and stress,
            which might lead to Premature Ejaculation (PE).
            <br/><br/><strong>≈Addiction - </strong>
            Binge drinking and other addictions often result in Premature
            Ejaculation (PE). Staying away from both can prevent the disorder.
            <br/><br/><strong>Erectile Dysfunction cure - </strong>
            Most men having erectile dysfunction or ED get afflicted with Premature
            Ejaculation (PE) eventually. Treating ED is a prerequisite for
            prevention of Premature Ejaculation (PE).
        </p>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="How to cope with Premature Ejaculation (PE)?">
        <p>
            Premature Ejaculation (PE) is a very common sexual disorder and is
            completely curable. However, given that it affects the sexual act and
            takes away pleasure from it dealing or living with Premature Ejaculation
            (PE) has its share of challenges.
            <br/><br/>Taking care of certain things helps in living with Premature
            Ejaculation (PE) in a better way.
            <br/><br/>
            <em>Here are some ways to deal with Premature Ejaculation (PE) -</em>
            <br/><br/><strong>Communicate well -</strong> Premature Ejaculation (PE)
            often leaves both partners frustrated and distressed. It also creates
            misconception regarding the man’s feelings for his partner and his
            interest in sex.
            <br/><br/>Talking freely with the partner clears confusion and draws her
            towards addressing the issue with empathy and concern. It will also help
            the partner in taking efforts to relieve the underlying performance
            pressure off the man.
            <br/><br/>Communicating with the doctor is also of equal importance in
            order to deal with the disorder effectively.
            <br/><br/><strong>Sex therapy –</strong> Following sex therapy
            techniques like stop-start or squeeze-pause methods is of immense help
            in dealing with Premature Ejaculation (PE).
            <br/><br/><strong>Second attempt at coitus –</strong> Trying for another
            erection after witnessing an incident of premature ejaculation, is also
            a great way as ejaculatory control improves the second time.
            <br/><br/><strong>Manage stress –</strong> To remain stress-free during
            intercourse is the game-changer for many men afflicted with Premature
            Ejaculation (PE). It is also important not to have any misconception or
            taboo about sex in order to be free of anxiety and guilt during
            intercourse.
            <br/><br/><strong>Reduced stimulation –</strong> Less stimulation also
            helps men deal with Premature Ejaculation (PE) better. Using creams and
            lotions, which partially anesthetize the penis, reduces stimulation
            leading to orgasm. Wearing condoms also help in controlling ejaculation.
            <br/><br/><strong>Strengthening of muscles -</strong> Premature
            Ejaculation (PE) can be a consequence of infirm pelvic floor muscles.
            Kegel and other exercises help in strengthening the muscles and in
            dealing with the disorder in a better way.
            <br/><br/><strong>Stimulating the body-</strong> A whole-body massage or
            stimulation before the sexual moment helps men with Premature
            Ejaculation (PE) enjoy the pleasure throughout his body instead of
            solely on sex organs. This decentralization of sensation keeps away
            performance anxiety and helps in relaxing.
            <br/><br/>For some men, masturbating a few hours before having sex with
            a partner is an effective way to deal with Premature Ejaculation (PE).
            For many others, thinking of something else during intercourse helps
            them last longer.
            <br/><br/><strong>Maintaining good health –</strong> A healthy body is a
            prerequisite for a happy and stress free sexual life. Eating a balanced
            diet and drinking adequate fluids, immensely benefits in maintaining
            good health.
            <br/><br/>Premature Ejaculation (PE) is a common disorder and has an
            array of remedies which confirm complete cure. There is no point in
            blaming oneself or in feeling inadequate and incompetent. Learning to
            live with Premature Ejaculation (PE) in the best ways possible is the
            way forward.
        </p>
    </AccordionCard>,
    <AccordionCard key={'1'} title="Can PE be managed with the right diet?">
        <p>
            New age medical researchers have established that certain changes in
            dietary routines can contribute towards Premature Ejaculation (PE) cure.
            The change is primarily done by eating a balanced diet rich in zinc,
            selenium, calcium, iron, carbohydrate and low protein, and by keeping
            away from regular intake of chocolate, chillies and caffeinated
            beverages.
        </p>
        <h3>What are the various food ingredients that aid in managing PE?</h3>
        <p>
            <strong>Ashwagandha</strong>
            <br/><br/>A widely popular medicinal herb of India, Aswagandha is highly
            effective in Premature Ejaculation (PE) cure as it improves brain power
            and libido. It also helps in better control of ejaculation by enhancing
            stamina.
            <br/><br/><strong>Garlic and seeds of green onion</strong>
            <br/><br/>Endowed with aphrodisiac attributes, both, helps in delaying
            ejaculation and increasing blood circulation and stamina. Chewing garlic
            cloves raw or fried in ghee every morning, and drinking crushed green
            onion seeds mixed in water thrice before meals - improve the disorder to
            a great deal.
            <br/><br/><strong>Ginger and honey</strong>
            <br/><br/>Another aphrodisiac – the mixture of ginger and honey - is
            known to increase libido and improve sexual performance.
            <br/><br/><strong>Asparagus</strong>
            <br/><br/>Asparagus roots contribute immensely in keeping premature
            ejaculation away. Taking the roots boiled in milk, twice a day increases
            control over penile muscles.
            <br/><br/><strong>Carrots</strong>
            <br/><br/>Having libido-enhancing traits which delay ejaculation,
            carrots are widely recommended for Premature Ejaculation (PE) cure.
            Eating boiled carrots together with egg and honey improves the condition
            considerably.
            <br/><br/><strong>Oatmeal</strong>
            <br/><br/>Oatmeal boosts testosterone levels in the blood and is
            instrumental in relaxing penile muscles and providing energy.
            <br/><br/><strong>Watermelon</strong>
            <br/><br/>The phytonutrient known as citrulline, present in watermelons,
            improves vasodilation and increases blood supply to the penis. It also
            helps in enhancing libido and sexual desire. Watermelon can be eaten in
            slices or as a fruit salad.
            <br/><br/><strong>Spinach</strong>
            <br/><br/>Magnesium deficiency often leads to Premature Ejaculation
            (PE). Hence spinach – the foods with the highest magnesium content - is
            highly beneficial in treating the condition.
            <br/><br/><strong>Turkey</strong>
            <br/><br/>Depression, one of the root causes of Premature Ejaculation
            (PE), decreases serotonin which plays a critical role in ejaculation.
            The depletion of serotonin levels actually happens due to decrease in
            tryptophan, an amino acid that helps in increasing serotonin in the
            brain. Turkey being one of the common sources of tryptophan, helps in
            increasing serotonin levels, thus addresses Premature Ejaculation (PE).
            <br/><br/><strong>Oysters</strong>
            <br/><br/>Zinc contents in oysters help increase zinc levels and in turn
            relieve some Premature Ejaculation (PE) symptoms. Oysters also rapidly
            enhance sex drive.
            <br/><br/><strong>Almonds</strong>
            <br/><br/>Free radicals regularly produced in the body lead to the
            infirmity of pelvic muscles and Premature Ejaculation (PE). Vitamin E, a
            powerful antioxidant, is the most effective material to combat free
            radical damage. Almonds are rich in vitamin E, hence are effective in
            Premature Ejaculation (PE) cure.
            <br/><br/><strong>Other foods</strong>
            <br/><br/>Many other foods also help in addressing Premature Ejaculation
            (PE). These include - ladyfinger, cooked or in powder form, pumpkin
            seeds, soybeans, yoghurt, wheat germ cereal, kidney beans, chickpeas,
            sesame seeds, beef and lamb, dark chocolate and peas.
            <br/><br/>Some dietary routines can also prevent Premature Ejaculation
            (PE). To start with a balanced diet rich in zinc, selenium, calcium,
            iron, carbohydrate and low protein is a great way to maintain overall
            wellbeing and keep sexual dysfunctions including Premature Ejaculation
            (PE) away.
            <br/><br/>Avoiding chocolate, chillies and caffeinated beverages and
            including foods like ashwagandha, almonds, asparagus, carrots, garlic,
            ginger and honey, seeds of green onion, oatmeal, oysters, spinach,
            turkey, and watermelon, can also prevent and control Premature
            Ejaculation (PE).
        </p>
        <h3>What are the Ayurvedic treatments for premature ejaculation (PE)?</h3>
        <p>
            According to Ayurveda shukra (semen / reproductive system) is the
            ultimate body tissue as it has regenerative qualities. The branch of
            Ayurveda that deals with sexual dysfunctions in male and female is
            called Vajikarana.
            <br/><br/>Various vajikarana drugs are effective in strengthening and
            invigorating men with sexual issues and improves their reproductive
            ability. A number of ayurvedic oils including, Chandanadi tailam and
            Maha sugandhi tailamare when applied all over the body can also cure
            early ejaculation.
            <br/><br/>Some Ayurvedic drugs and procedures which help in the
            management of Premature Ejaculation (PE) are -
        </p>
        <ul>
            <li>Praharsha anna (exciting food items)</li>
            <li>balya (tonics)</li>
            <li>Shukrakara drugs (aphrodisiacs)</li>
            <li>Erandamoola (Ricinus communis Linn.)</li>
            <li>Brahmi (Bacopa monnieri Linn)</li>
            <li>Mandukaparni (Centella asciatica Linn)</li>
            <li>Amlaki (Emblica officinalis Garten)</li>
            <li>Swarna bhasma (gold)</li>
            <li>Rasa sindhura (mercury-based formulations)</li>
            <li>Ashwagandha Churna</li>
            <li>Jatiphala (Myristica fragrans)</li>
            <li>Speman tablet</li>
        </ul>

        <p>
            Panchakarma procedures of Ayurvedic (five distinct cleansing procedures
            for the human body) including vasti (oil or decoction enemas) and
            shirodhara (constant oil drip on forehead) are very effective in
            Premature Ejaculation (PE) cure.
            <br/><br/>Some home remedies for Premature Ejaculation (PE) cure also
            exist in Ayurveda. These include –
        </p>
        <ul>
            <li>Narasimha choorna, Bhallataka phala majjadi avaleha, Musalyadi
                choorna, Atmagupta and Ashwagandha - improve duration sex
            </li>
            <li>Akarakarabhadi yoga, a homemade preparation made with ten herbs -
                increases the time taken for ejaculation
            </li>
            <li>Brahmi, kaunch beej, kamini vidrawan ras, and yauvanamrit vati and
                Mandukaparni -reduce anxiety and stress
            </li>
        </ul>
        <h3>Ayurvedic dietary suggestions for managing PE</h3>
        <p>
            Ayurvedic cure of Premature Ejaculation (PE) also includes some dietary
            suggestions like intake of fresh fruits, nuts, cereals, fish, vegetables
            (especially fennel, celery, ginger, garlic, asparagus, drumstick
            lettuce, onions) and honey.
            <br/><br/>The treatment also recommends reduction or avoiding of
            alcohol, beverages like tea and coffee, processed food and food with a
            high quantity of sugar or flour.
            <br/><br/>Over the years, Ayurvedic cures have been successful in
            significantly increasing the time taken to ejaculate. However, the
            procedures have some side effects like stomach pain, dizziness, mild and
            pain.
            <br/><br/>It is important to keep in mind that Ayurvedic cure is a long
            term process. Though a lot of research is regarding the efficacy of this
            cure has produced positive results, final and definitive conclusions are
            yet to be drawn.
        </p>
    </AccordionCard>,
    <AccordionCard key={'2'} title="How does Yoga cure premature ejaculation (PE)?">
        <p>
            It is an established fact that the most common sexual disorder in men is
            Premature Ejaculation (PE). Though men often tend to shy away from the
            condition due to social taboo, and shame - an array of remedies exist
            for complete cure of the syndrome.
            <br/><br/>With awareness, the reality has now started changing, and more
            and more men and women are prioritising health and wellbeing before
            shame and social sanctions.
            <br/><br/>Given the fact that medicinal drugs have side effects, many
            now look for alternative cares like including Ayurvedic cure, natural
            cure and yoga, for diseases and disorders. Same is also true for
            treating Premature Ejaculation (PE).
            <br/><br/>Being a well-tolerated, secured and useful non-pharmacological
            cure for Premature Ejaculation (PE), Yoga has gained a lot of popularity
            now. Recent research findings also established the benefits of mind-body
            interventions in relieving stress which often leads to sexual disorders.
            <br/><br/>Yogic asanas are actually very effective in increasing blood
            circulation throughout the body, which distinctly decreases tension and
            anxiety and builds sexual vigour.
            <br/><br/>This alternative form of treatment is totally free of side
            effects and has increased its acceptability in both the developed and
            developing countries.
            <br/><br/>As a natural solution to the disorder, yoga works in a
            two-pronged approach in Premature Ejaculation (PE) cure. It relieves the
            symptoms and improves the condition without any serious ramification
            while bringing back vitality and virility in men afflicted with the
            syndrome.
            <br/><br/>Of late, Yoga protagonists claim that the treatment also
            enables men with Premature Ejaculation (PE) have better control over
            their ejaculation.
        </p>
        <h3>Which yoga positions help in managing premature ejaculation (PE)?</h3>
        <p>
            <strong>Paschimottansana –</strong> Most effective for Premature
            Ejaculation (PE) cure; adds potency to sperms; those with spinal or back
            injury should avoid.
            <br/><br/><strong>Plow Pose -</strong> Cures Premature Ejaculation (PE)
            and improves sexual and reproductive health.
            <br/><br/><strong>Sarvanga Asana -</strong> Shoulder pose ensuring
            sexual vitality, the potency of sperms and strengthening reproductive
            organs; those with spinal or neck injury or hypertension should avoid.
            <br/><br/><strong>Gomukhasana -</strong> Very simple yet extremely
            effective, not only in Erectile Dysfunction (ED) and Premature
            Ejaculation (PE) but also in providing energy to the body and alertness
            to the mind
            <br/><br/><strong>Utthanapadasana –</strong> Multiple benefits,
            including controlling ejaculation, addressing constipation and digestive
            issues and strengthening intestines; those with a spinal or back injury,
            should avoid.
            <br/><br/><strong>Bhujangasana –</strong> Very useful in Premature
            Ejaculation (PE) and in controlling ejaculation; those with wrist
            injuries must not perform this asana.
            <br/><br/><strong>Vajrasana –</strong> Works well for the abdominal
            region, sustains sexual health and addresses digestion issues.
            <br/><br/><strong>Kandasana –</strong> Cures all sexual disorder, builds
            flexibility of lower body parts and cures joint pains; also increases
            sexual desire and addresses premature ejaculation.
            <br/><br/><strong>Brahmacharyasana -</strong> Highly effective for those
            with nightfall issues; it boosts libido and strengthens the testis.
            <br/><br/><strong>Mandukasana –</strong> Resembling the posture of a
            frog, this asana works well for the abdominal and pelvic regions and
            sustains sexual health.
            While it is true that the Yoga postures, when rightly done, can be
            highly beneficial in sustaining an erection and controlling ejaculation
            during sex, wrong postures or techniques can cause harm or remain
            ineffective.
            The best way is to do them under the supervision of an expert or tutor,
            at least in the early stages of treatment.
        </p>
    </AccordionCard>
]
