import { CHANGE_LANGUAGE } from '../actions/languages'
import { getStorageItem, setStorageItem } from '../utils'
import { LANGUAGE } from '../constants'
import { getLanguageFromName } from '../languages'

let initialState, language, name

if (typeof window !== 'undefined' && window.__LANGUAGES_STATE__) {
  initialState = window.__LANGUAGES_STATE__
} else {
  [language, name] = getLanguageFromName(getStorageItem(LANGUAGE, 'english'))
  initialState = {
    language,
    name
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      setStorageItem(LANGUAGE, action.name)
      return { ...state, language: action.language, name: action.name }
    default:
      return state
  }
}

export default reducer
