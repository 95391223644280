import english from '../english'
import React from 'react'

const language = {
  ...english,
  LANGUAGE_NAME: 'తెలుగు',
  // HOME: 'హోమ్‌',
  // CART: 'కార్ట్‌',
  // SHOP: 'షాప్‌',
  // LEARN: 'और जानिये',
  // LOGIN: 'लॉगइन',
  // ACCOUNT: 'अकाउंट',
  // PROFILE: 'ప్రొఫైల్‌',
  // ORDERS: 'ऑर्डर',
  // LOGOUT: 'लॉग आउट',
  // BLOG: 'ब्लॉग',
  // QA: 'सामान्य प्रश्न',
  // ABOUT: 'हमारे बारे में',
  // EDIT: 'एडिट',
  // SAVE_ADDRESS: 'एड्रेस सेव करें',
  // SAVE_AND_CONTINUE: 'पेमेंट करें',
  // CANCEL: 'रद्द करें',
  // DELETE: 'हटाएँ',
  // DELIVERY_ADDRESS: 'डिलिवरी का पता',
  // SUMMARY: 'सारांश',
  // CONFIRM_ORDER: 'कन्फर्म आर्डर',
  // PAYMENT_OPTIONS: 'पेमेंट ओप्शन्स',
  // MAKE_PAYMENT: 'पेमेंट करें',
  // CREDIT_CARD: 'क्रेडिट कार्ड',
  // DEBIT_CARD: 'डेबिट कार्ड',
  // E_WALLETS: 'ई-वॉलेट',
  // CONTACT_US: 'మమ్మల్ని సంప్రదించండి',
  // UPDATES: 'अपडेट',
  // INFORMATION: 'जानकारी',
  // PURPOSE: 'उद्देश्य',
  // PHILOSOPHY: 'हमारी सोच',
  // PREMATURE_EJACULATION: 'शीघ्रपतन',
  // ERECTILE_DYSFUNCTION: 'इरेक्शन प्रॉब्लम',
  // SIZE: 'साइज़',
  // HAIRFALL: 'बालों का झड़ना',
  // FOOD_SUPPLEMENTS: 'खाने के सपलिमेंट',
  // CART_PRICE_INCLUDES_GST: 'इस कीमत में GST शामिल है',
  // CART_FOOTER_LINE_ONE: 'बिना किसी सवाल, 15 दिन में पैसे वापस की गैरंटी।',
  // CART_FOOTER_LINE_TWO: 'सामान भेजने से पहले हमारे डॉक्टर आपसे बात करेंगे जिस से यह पक्का हो जाए की आपके लिए यह दवा बिलकुल सही है। ',
  // CART_FOOTER_LINE_THREE: 'हमसे बात करने के लिए आप support@hfactorial.com पर ईमेल या 1800-121-9555 (सोम- शनि 10am-7pm) पर बात कर सकते हैं।',
  // CART_IS_EMPTY: 'आपकी कार्ट खाली है!',
  // DUPLICATE_ORDER_TITLE: 'लगता है कि आप इन आइटम को पहले ही ऑर्डर कर चुके है। 👇',
  // DUPLICATE_ORDER_MESSAGE: 'ऑर्डर कन्फ़र्म करने से पहले क्या आप अपनी कार्ट में कुछ बदलना चाहते है?',
  // OOPS_YOU_HAVE_NOTHING_HERE: 'ओहो! यहां तो कुछ नहीं है।',
  // ORDER_PLACED_SUCCESSFULLY: 'आपका ऑर्डर हमें मिल गया है।',
  // INPUT_NAME: 'अपना नाम दर्ज करें',
  // NAME: 'नाम',
  // DATE_OF_BIRTH: 'जन्म की तारीख',
  // CONTINUE: 'आगे बढ़ें',
  // LOGIN_SUCCESSFUL: 'लॉगइन हो गया',
  // WELCOME_BACK: 'फिर से स्वागत है!',
  // PLEASE_LOGIN: 'कृपया अकाउंट में लॉग इन करें।',
  // PLEASE_VERIFY_PHONE: 'कृपया अपना फ़ोन नंबर वेरीफाई करें।',
  // PHONE_NUMBER: 'फ़ोन नम्बर',
  // ENTER_OTP: 'OTP एंटर करिए',
  // RESEND_OTP: 'OTP फिर से भेजें',
  // GET_OTP_ON_CALL: 'कॉल पर OTP प्राप्त करें',
  // SEND_OTP: 'OTP भेजें',
  // RESEND_OTP_TEXT: 'OTP फिर से भेजने के लिए कृपया {time} सेकोंड प्रतीक्षा करें।',
  // SUB_TOTAL: 'Sub Total',
  // GST: 'GST',
  // TOTAL: 'Total',
  // NEXT: 'आगे बढ़ें',
  // PLACE_ORDER: 'आर्डर करें',
  // SHIPPING_ADDRESS: 'पता',
  // ADDRESS: 'पता',
  // CITY: 'शहर',
  // STATE: 'राज्य',
  // ZIPCODE: 'पिन कोड',
  // INSTRUCTIONS: 'Instructions (घंटी नहीं बजाना / फोन करने का सही समय)',
  // PROCEED_TO_BUY: 'पता बताएं',
  // YOUR_10_DIGIT_MOBILE_NUMBER: 'आपका 10 अंकों का मोबाइल नंबर',
  // MY_PHONE_NUMBER_IS: 'मेरा फ़ोन नंबर है...',
  // YOUR_OTP: 'आपका OTP',
  // MY_OTP_IS: 'मेरा OTP है ...',
  // DELIVER_TO_THIS_ADDRESS: 'यहां डिलीवर करें',
  // ADD_NEW_ADDRESS: 'नया पता जोड़ें',
  // PLEASE_COMPLETE_YOUR_PROFILE: 'कृपया अपनी प्रोफ़ाइल पूरा करें।',
  // INCORRECT_OTP: 'OTP ग़लत!',
  // ADDRESS_LINE_ONE: 'आपका पता और मोबाइल नंबर कभी भी किसी के साथ शेयर नहीं किया जाएगा और केवल डिलीवरी के उद्देश्य के लिए उपयोग किया जाएगा।',
  // ADDRESS_LINE_TWO: 'पैकेजिंग के ऊपर कोई ऐसा विवरण नहीं होगा, जिससे की बाहर से देख के कोई यह नहीं जान सके की अंदर क्या है।',
  // ADDRESS_LINE_THREE: 'आपके सभी व्यक्तिगत विवरण हमारे सर्वर पर एन्क्रिप्ट कर के रखे जाते हैं।',
  // SELECT_ADDRESS: 'पता चुनें',
  // CASH_ON_DELIVERY: 'डिलीवरी वाले को नकद दे कर (COD)',
  // PAY_ONLINE: 'नैट बैंकिंग / क्रेडिट कार्ड / डेबिट कार्ड / वॉलेट',
  // MEDICAL_CONDITIONS: 'मेडिकल प्रोब्लेम्स',
  // THE_MAN_GUARANTEE: 'Misters गैरंटी',
  // GUARANTEE_TEXT: `Misters की दवाइयाँ और सप्लमेंट श्रेष्ठ स्पेशलिस्ट डॉक्टर और अन्य एक्स्पर्ट्स की देखरेख में बनाए गए हैं। देश की उत्तम दवा फ़ैक्टरी में से एक में इनका उत्पादन किया गया है।`,
  // MONEY_BACK_GUARANTEE: 'पंद्रह दिन की मनी बैक गारंटी',
  // MONEY_BACK_GUARANTEE_TEXT: 'बिना किसी सवाल जवाब के',
  // MEDICALLY_VERIFIED: 'दवा और सप्लमेंट विशेषज्ञों द्वारा ',
  // MEDICALLY_VERIFIED_TEXT: 'शीर्ष सेक्सोलोजिस्ट` द्वारा डिज़ाइंड',
  // FREE_DELIVERY: 'नि:शुल्क डिलिवरी',
  // FREE_DELIVERY_TEXT: 'सात दिनों के भीतर भारत भर में',
  // DISCREET_PACKAGING: 'पैकिजिंग ऐसी जिसको देख कर कोई यह ना जान पाए की अंदर क्या है ',
  // DISCREET_PACKAGING_TEXT: 'हम आपकी निजी जानकारी का ख़याल रखते हैं।',
  // CONSTITUENTS: 'हमारे उत्पाद',
  // CONSTITUENTS_TEXT: `जब भी आप Misters का कोई भी प्रोडक्ट ख़रीदते हैं तो आप विश्वस्त रह सकते हैं की उसका उत्पादन श्रेष्ठ क्वालिटी नियमों के अधीन किया गया है, और इन में से किसी से भी यदि आप संतुष्ट नहीं हैं तो Misters गैरंटी के तहत आप इनको लौटा सकते हैं ख़रीदने के पंद्रह दिन के अंदर। `,
  // ITEM_IS_OUT_OF_STOCK: 'क्षमा करें, यह आइटम अभी स्टॉक में नहीं है, कृपया कुछ दिनों के बाद फिर से ट्राई करें।',
  // BUY_NOW: 'खरीदें',
  // GO_BACK: 'वापस',
  // ADDRESS_SKIP_TEXT: `यहाँ क्लिक करिए अगर आप चाहते हैं कि यह बॉक्स आपके घर या ऑफ़िस पर ना भेजा जाए।
  // हमारी टीम आप को कॉल कर के समझ लेगी कि बॉक्स कैसे भेजना है।`,
  // THERAPY_FOOTER_TEXT: `किसी भी प्रश्न के लिए, कृपया 1800-121-9555 पर कॉल करें।`,
  // THERAPY_FOOTER_TEXT_SMALL: `कॉल करें।`,
  // ERECTILE_DYSFUNCTION_TITLE: `इरेक्शन प्रॉब्लम? (Erectile Dysfunction)`,
  // ERECTILE_DYSFUNCTION_TEXT: `अब इरेक्शन ज़रूरी हैं, यह बात तो ज़ाहिर है। और जब ज़रूरत हो तब इरेक्शन नहीं होना, यह तो हम सभी के लिए
  //  निराशाजनक होता है। मगर अब, इस निराशा की कोई बात नहीं। सही साइयन्स, सही डॉक्टर की सलाह, सही प्रोडक्ट, यह सब आप को यहाँ मिलेगा।`,
  // ERECTILE_DYSFUNCTION_BUTTON: `तो क्लिक करिए और इरेक्शन प्राब्लम को अलविदा कहिए`,
  // PREMATURE_EJACULATION_TITLE: `शीघ्रपतन? (Premature Ejaculation)`,
  // PREMATURE_EJACULATION_TEXT: `सेक्स में तो दो लोग शामिल होते हैं, तो मज़ा तो तब है जब दोनो क्लाइमैक्स तक साथ में ही पहुँचें। मगर कई बार पुरुष
  //  बहुत जल्दी ही पहुँच जाते हैं, और सेक्स का मज़ा कुछ हल्का पढ़ जाता है। आपका यहाँ होना यह बताता है कि आप उन चुनिंदा लोगों में से हैं जो यह सब समझते हैं।
  //  अब रही बात इस प्रॉब्लम की, तो उसका जवाब तो आसान है।`,
  // PREMATURE_EJACULATION_BUTTON: `यहाँ क्लिक करिए और शीघ्रपतन को कहिए अलविदा`,
  // SEX: 'सैक्स!',
  // SEX_TEXT: `प्राचीन रचना हो, आधुनिक ख़याल कोई।
  //     पुरानी याद हो, कल का ही क़िस्सा कोई।
  //
  //     हो पहेली मनुष्य के जटिल अस्तित्व की।
  //     इक खोज एकमात्र शाश्वत आनंद की।
  //
  //     सैक्स तो महान है।
  //     हाँ, सैक्स तो महान है।
  // `,
  // SEX_BUTTON: `पढ़िए सैक्स पर विश्व का सर्वश्रेष्ठ ब्लॉग (हमारी राय में!)`,
  // QUESTION: 'है कोई सवाल?',
  // QUESTION_TEXT: `सवाल कई उठते हैं, पर हमेशा पता नहीं होता की किससे पूछें। हो सकता है आप के मन में कोई सवाल हो सेक्स को ले कर,
  //  कोई प्रॉब्लम जिसका हल नहीं मिल रहा हो। कुछ भी। हम से पूछिए, और हम पूरी कोशिश करेंगे आप के साथ उस प्रॉब्लम का हल निकालने की।`,
  // QUESTION_BUTTON: 'अपना सवाल पूछने के लिए क्लिक करिए',
  // QA_FOOTER_TEXT: '{city} शहर से {name} द्वारा पूछा गया प्रश्न',
  // ORDER_THANKS: 'धन्यवाद! आपका ऑर्डर कन्फर्म हो गया है।',
  // WE_CARE_ABOUT_YOUR_SAFETY: `आपकी सुरक्षा के लिए हम सतर्क हैं`,
  // COD_ORDER_DISPATCHED_AFTER_CONFIRMATION_CALL: 'कृपया ध्यान दें कि COD ऑर्डर्स को कंफ़र्मेशन कॉल के बाद ही भेजा जाएगा। कृपया हमारी कॉल को उठा लें ताकि हम आपका ऑर्डर भेज सकें।',
  // GET_A_FREE_AYURVEDIC_ANALYSIS: 'आयुर्वेद के अनुसार आपके लिए सही आहार (diet) <br/> और विहार (lifestyle) सलाह जानें',
  // MANDATORY_SANITIZATION: 'अनिवार्य सैनीटाइज़ेशन',
  // SAFE_SHIPPING: 'सुरक्षित शिपिंग',
  // CONTACTLESS_DELIVERY: 'संपर्क रहित डिलीवरी',
  // ORDER_FAILED: 'सॉरी हम आपका आर्डर पूरा नहीं कर सके',
  // CONTINUE_FROM_WHERE_YOU_LEFT: 'जहां से आपने छोड़ा था वहीं जारी रखें ?',
  // YES: 'हाँ',
  // NO: 'नहीं',
  // END_REACHED: 'लगता है, आप अंत तक पहुँच चुके हैं।',
  // START_AGAIN: 'फिर से शुरू करें',
  // KNOW_MORE: 'और जानिए',
  // CALL_US: 'कॉल करें',
  // TAKE_QUIZ: 'सवाल जवाब शुरू करें',
  // TAKE_THE_SEX_QUIZ: 'जानिए अपना सैक्स स्कोर',
  // TAKE_THE_AYURVEDIC_QUIZ: 'ख़ास आपके लिए आयुर्वेदिक सलाह',
  // ERECTION_ISSUES: 'इरेक्शन प्रॉब्लम',
  // START: 'शुरू करें',
  // HELLO_MAN: 'हैलो, दोस्त!',
  // HELLO_MAN_TEXT: 'बताओ, कैसे आना हुआ?',
  // ADD_NEW_ADDRESS_TO_CONTINUE: 'कृपया जारी रखने के लिए हमें अपना पता बताएं',
  // CURRENTLY_NOT_SERVING_ON_SELECTED_AREA: 'हमें खेद है कि हम इस क्षेत्र में डिलीवरी नहीं कर पाएंगे।',
  // ADD_TO_CART: 'कार्ट में डालें',
  // SUBSCRIPTION: 'सब्सक्रिप्शन',
  // SUBSCRIBE: 'सब्सक्राइब',
  // PLEASE_ENTER_THE_OTP_RECEIVED_ON_YOUR_MOBILE_TO_COMPLETE_THIS_ORDER: 'कृपया इस ऑर्डर को पूरा करने के लिए अपने मोबाइल पर प्राप्त otp दर्ज करें',
  // AYURVEDA: 'आयुर्वेद',
  // MEDIA: 'मीडिया',
  // WATCH_US_ON_YOUTUBE: 'YOUTUBE पर हमें देखें',
  // SHOP_NOW: 'शॉप पर जाएँ',
  // COMING_SOON: 'जल्द आ रहा है',
  // CONTINUE_SHOPPING: 'खरीदारी जारी रखें',
  // LEARN_MORE: 'और अधिक जानें',
  // SHOW_DETAILS: 'डिटेल में देखें',
  // WONDERING_HOWS_YOUR_SEXUAL_HEALTH: 'जानिए अपना सैक्स पर्फ़ॉर्मन्स स्कोर!',
  // ONLINE_PAYMENT_ONLY_MESSAGE: 'आपके क्षेत्र में कैश ऑन डिलीवरी (COD) अभी उपलब्ध नहीं है। आपके सहयोग के लिए शुक्रिया अदा करने के लिए हम आपको बाकी किसी भी पेमेंट ऑप्शन पर ₹200 ऑफ़ दे रहे हैं।',
  HOW_DOES_AYURVEDA_WORK: 'ఆయుర్వేదం ఎలా పనిచేస్తుంది',
  KEY_INGREDIENTS: 'ముఖ్య పదార్థాలు'
}

export default language
