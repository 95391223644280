import { getHttpsImageUrl } from '../../utils'

export default ({ clinic, url }) => {
  const schemaTagInnerHTML = ({
    '@context': 'https://schema.org',
    '@type': 'MedicalClinic',
    name: clinic.name,
    image: getHttpsImageUrl(clinic.banner_image),
    '@id': '',
    url: url,
    telephone: clinic.phone_number,
    address: {
      '@type': 'PostalAddress',
      streetAddress: clinic.address,
      addressLocality: clinic.city,
      postalCode: clinic.zipcode,
      addressCountry: 'IN'
    },
    currenciesAccepted: '₹',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: parseFloat(clinic.latitude),
      longitude: parseFloat(clinic.longitude)
    },
    openingHoursSpecification: (clinic.clinic_opening_hours_specifications || []).map(
      clinic_opening_hours_specification => ({
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: clinic_opening_hours_specification.days,
        opens: clinic_opening_hours_specification.opens,
        closes: clinic_opening_hours_specification.closes
      })
    ),
    photo: (clinic.clinic_images || []).map(
      clinic_image => ({
        '@type': 'CreativeWork',
        url: clinic_image.image
      })
    )
  })

  return {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(schemaTagInnerHTML)
  }
}
