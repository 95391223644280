import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2, accordionList3 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import { NavLink } from 'react-router-dom'

class Shatavari extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props

    return (
            <Page meta={{
              title: 'Shatavari - for a steady sperm count | Misters',
              path: `${match.params.language}/ingredient/shatavari/`,
              description: 'Shatavari has numerous health benefits and is extensively used to keep male sexual problems in check as well. These include the management of erectile dysfunction, oligospermia, spermatogenic irregularities and painful micturition, to name a few. The frequently asked questions (FAQ’s) around Shatavari, often entail its benefits, price, where it is found, its description, its alternative names, and the like.  At Misters, we aim to answer all these questions and more…'
            }}>
                <div className="ingredient-header-img" style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>Shatavari - for a steady sperm count</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What is Shatavari?</h2>
                            <p>
                                Shatavari is one of the <b>essential herbs used in Ayurvedic medicine</b>. It is
                                commonly used for <b>female reproductive health</b>. For example, it aids in the act of
                                conceiving, prevention of miscarriages and also improves libido. In Sanskrit, the term
                                Shatavari means “having one hundred roots.” It can also be associated with another
                                meaning- “having one hundred husbands.” Shatavari is used in <b>rejuvenating tonics</b>,
                                which help to develop and maintain good health and the general well-being of an
                                individual.
                                <br/>
                                <br/>
                                The traditional use of Shatavari has been mentioned in ancient Indian texts of Ayurveda
                                such as “Charak Samhita.” This describes its use for conditions such as the treatment of
                                epilepsy, for <b>brain tonics</b>, managing Vata disorders, cardiac disorders and
                                hypertension.
                                <br/>
                                <br/>
                                Most sexual problems are generally governed by the Vata Dosha. Any kind of imbalance in
                                the Vata Dosha might lead to sexual disability and since Shatavari helps to manage Vata
                                Dosha, it <b>keeps sexual disorders at bay</b>.
                            </p>
                            <p className="text-center font-weight-bold">
                                <span>To know more about Shatavari, </span>
                                <a className="ingredient-blog-links" target="_blank"
                                         href={`https://misters.in${match.params.language}/blog/What-is-Shatavari`}>click here</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker image={Images.ingredient.shatavariDescription} to={shopUrl}
                                          buttonText={'SHOP'}/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList3}
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

export default Shatavari
