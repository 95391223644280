import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_RESULT,
  FETCH_PRODUCTS_ERROR,
  FETCH_PRODUCTS_RETRY, FETCH_PRODUCT, FETCH_PRODUCT_RESULT, FETCH_PRODUCT_ERROR, FETCH_PRODUCT_RETRY
} from '../../actions/ecommerce/products'

const initialState = {
  list: [],
  detail: {},
  isFetchingList: false,
  isFetching: false,
  error: false,
  message: null,
  retry: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return { ...state, isFetchingList: true, error: false, message: null, retry: false }
    case FETCH_PRODUCTS_RESULT:
      return { ...state, isFetchingList: false, list: action.data }
    case FETCH_PRODUCTS_ERROR:
      return { ...state, isFetchingList: false, error: true, message: action.message }
    case FETCH_PRODUCTS_RETRY:
      return { ...state, isFetchingList: false, retry: true, message: action.message }

    case FETCH_PRODUCT:
      return { ...state, detail: {}, isFetching: true, error: false, message: null, retry: false }
    case FETCH_PRODUCT_RESULT:
      return { ...state, isFetching: false, detail: action.data }
    case FETCH_PRODUCT_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_PRODUCT_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }
    default:
      return state
  }
}
