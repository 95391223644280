import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Page from '../../components/core/Page'
import { Images, PHONE_NUMBER } from '../../constants'

class PartnerProgram extends Component {
    static propTypes = {}

    state = {}

    componentDidMount () {
    }

    render () {
      const { match } = this.props

      const typeFormInnerHTML = `
        <div class="typeform-widget" data-url="https://form.typeform.com/to/Wg9nIAkd?typeform-medium=embed-snippet" style="width: 100%; height: 600px;">
        </div> 
        <script> (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })() </script>
        `
      return (
            <Page showNavbar={false} showFooter={false} meta={{
              path: `${match.params.language}/pages/misters-partnership-program/`,
              title: 'Misters Partnership Program',
              description: 'Join India\'s leading men\'s sexual health website to grow your online presence, increase clinic traffic and earn more profits.'
            }}>
                <h3 className="text-center pt-4 pb-3">Grow your clinic business with Misters Partnership Program</h3>
                <div dangerouslySetInnerHTML={{ __html: typeFormInnerHTML }}/>
            </Page>
      )
    }
}

const mapStateToProps = (state) => ({})

const matchDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(PartnerProgram)
