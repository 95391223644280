import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle'

class PaymentMethodCard extends React.Component {
    static propTypes = {
      className: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      onClick: PropTypes.func,
      value: PropTypes.bool,
      ListStyleComponent: PropTypes.any
    };

    static defaultProps = {
      className: ''
    };

    componentDidMount () {

    }

    render () {
      const { title, text, value, onClick, className, ListStyleComponent } = this.props

      return (
            <li className={`payment-methods-item ${className} ${value ? 'active' : ''}`} onClick={onClick}>
                <ListStyleComponent/>
                <div className="payment-methods-item-content">
                    <h6>{title}</h6>
                    <span>{text}</span>
                </div>
                <div className={`payment-methods-icon ${value ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={value ? faCheckCircle : faDotCircle} size="2x"/>
                </div>
            </li>
      )
    }
}

const mapStateToProps = (state) => ({
  offers: state.ecommerce.offers,
  offer: state.ecommerce.cart.detail.offer,
  config: state.ecommerce.cart.config
})

const matchDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(PaymentMethodCard)
