import React from 'react'
import Card from 'react-bootstrap/Card'
import PropTypes from 'prop-types'
import { Images } from '../../../constants'

export default class Testimonials extends React.PureComponent {
    static propTypes = {
      data: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
          text: PropTypes.string,
          comment: PropTypes.string
        })
      ),
      bgLight: PropTypes.bool,
      className: PropTypes.string
    };

    static defaultProps = {
      bgLight: true,
      className: '',
      data: [
        {
          image: Images.testimonial.actor1,
          text: 'I got to know of misters.in while searching for solution to premature ejaculation. I then took the quiz and ordered the PE therapy kit, which was delivered to my home. It was a reassuring and discreet process, something which I liked, as I did not want to discuss such personal issues with too many people.',
          comment: 'A satisfied repeat buyer of Misters PE therapy'
        },
        {
          image: Images.testimonial.actor2,
          text: 'I did not like the experience of visiting the local sex clinic. I did not feel comfortable there and the discussion with the ‘expert’ was innuendo ridden. The quiz on misters.in is very straight forward and helped me get the best solution for my PE problem. Without beating around the bush!',
          comment: 'A first-time buyer of Misters.in PE therapy'
        },
        {
          image: Images.testimonial.actor3,
          text: 'The detailed information on premature ejaculation that I got on misters.in helped me tremendously. I then decided to take the quiz and a PE therapy box was recommended basis my answers in the quiz. I think I will go for regular repeat orders now!',
          comment: 'A Misters.in customer'
        }
      ]
    };

    render () {
      const { data, bgLight, className } = this.props

      return (
            <div className={`${className} ${bgLight ? 'bg-light' : ''}`}>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center">
                            <h2 className="home-page-subtitle mb-4">
                                What our customers are saying about us?
                            </h2>
                            <em>
                                <small>At misters.in, we understand that privacy is of supreme concern to our customers. A deeply personal
                                    problem like erectile dysfunction (ED) should stay private and confidential. The testimonials from
                                    our customers are identity masked, and some of them translated from vernacular languages while
                                    retaining the essence of the testimonial.</small>
                            </em>
                        </div>
                    </div>

                    <div className="row">
                        {
                            data.map(
                              (obj, index) => (
                                    <div className="col-md-4 col-12 py-2 category-testimonial-card d-flex" key={index}>
                                        <Card>
                                            <Card.Img variant="top" data-src={obj.image} className="lazyload" alt={obj.comment}/>
                                            <Card.Body>{obj.text}</Card.Body>
                                            <Card.Footer className="border-0 bg-white">
                                                <small><em>- {obj.comment}</em></small>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                              )
                            )
                        }
                    </div>
                </div>
            </div>
      )
    }
}
