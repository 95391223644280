// External Imports
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
// My Imports
import { fetchDoctor } from '../../../actions/community/doctors'
import Page from '../../../components/core/Page'
import { getHttpsImageUrl, truncChars } from '../../../utils'
import CommunityPostCommentCard from '../../../components/cards/CommunityPostCommentCard'
import { Images, PHONE_NUMBER } from '../../../constants'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons/faSuitcase'
import { faUserAlt } from '@fortawesome/free-solid-svg-icons/faUserAlt'

class Doctor extends React.Component {
  componentDidMount () {
    const { slug } = this.props.match.params
    this.props.fetchDoctor({ slug })
  }

  render () {
    const { doctors: { detail }, match } = this.props

    const doctorPronoun = detail.gender === 'f' ? 'She' : 'He'
    return (
            <Page meta={{
              title: 'Misters.in Doctor\'s Community',
              path: `${match.params.language}/community/doctor/${match.params.slug}/`,
              description: 'Get your answers to all of your questions from the misters community'
            }}>
                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-10 offset-sm-1 col-12 pb-5">
                                <div className="doctor-profile">
                                    <div className="doctor-profile-author-image">
                                        {
                                            detail.image
                                              ? <img src={getHttpsImageUrl(detail.image)} alt=""/>
                                              : <FontAwesomeIcon icon={faUserAlt} color={'#ccc'} style={{ fontSize: 50 }}/>
                                        }
                                    </div>
                                    <NavLink to={`${match.params.language}/community/doctors/${detail.slug}`}
                                             className="doctor-profile-author-details">
                                        <span className="doctor-profile-author-name">
                                            {detail.name}
                                        </span>
                                        <span className="doctor-profile-author-creds">
                                            {detail.speciality}
                                        </span>
                                        <span className="doctor-profile-author-creds">
                                            {detail.education}
                                        </span>
                                    </NavLink>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="doctor-about">
                                            <h6>About</h6>
                                            <p>
                                                {detail.about}
                                            </p>
                                            <h6>More about {detail.name}</h6>
                                            <p>
                                                <span className="capitalize">{doctorPronoun} </span>
                                                has been a successful {detail.speciality} for the last {detail.total_experience} years.
                                                <span> {doctorPronoun} is a qualified {detail.education}. Book an appointment
                                                online with {detail.name} on Misters.in.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="doctor-about">
                                            <h6>
                                                <span className="doctor-about-icon">
                                                    <FontAwesomeIcon icon={faUser} size="1x"/>
                                                </span>
                                                <span> Speciality</span>
                                            </h6>
                                            <p>{detail.speciality}</p>
                                            <h6>
                                                <span className="doctor-about-icon">
                                                    <FontAwesomeIcon icon={faGraduationCap} size="1x"/>
                                                </span>
                                                <span> Education</span>
                                            </h6>
                                            <p>{detail.education}</p>
                                            <h6>
                                                <span className="doctor-about-icon">
                                                    <FontAwesomeIcon icon={faSuitcase} size="1x"/>
                                                </span>
                                                <span> Experience</span>
                                            </h6>
                                            <p>
                                                {
                                                    detail.total_experience > 0 &&
                                                    <>{detail.total_experience} Years <br/></>
                                                }
                                                {detail.past_experience}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !!detail.comments && detail.comments.length > 0 &&
                                    <div>
                                        <h4 className="community-post-title">Comments</h4>
                                        {detail.comments.map(
                                          comment => <CommunityPostCommentCard key={comment.id} comment={comment}
                                                                                 showPost={true} post={comment.post}/>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row py-5">
                        <div
                            className="col-12 col-md-6 order-md-1 order-2 d-flex flex-column justify-content-center text-center text-md-left">
                            <h2 className="home-page-subtitle">
                                Schedule a free consultation with an ayurvedic doctor.
                            </h2>
                            <p className="home-page-description">
                                Call us at <strong>{PHONE_NUMBER}</strong> for a free Ayurveda Consultation
                            </p>
                            <div>
                                <a href={`tel:${PHONE_NUMBER}`} className="btn-m btn-m-red mb-3"
                                   style={{ width: 250 }}>
                                    Call Now
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 order-md-2 order-1">
                            <img data-src={Images.home8}
                                 className="lazyload section-image" alt="A man controlling his car"/>
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

const mapStateToProps = state => ({
  doctors: state.community.doctors
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchDoctor
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Doctor)
