// External Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// My Imports
import { fieldTypes } from '../../../constants'
import Button from '../../buttons/Button'
import ReactSlider from 'react-slider'

const { HEIGHT } = fieldTypes

export default class HeightInput extends Component {
    static propTypes = {
      handleSendClick: PropTypes.func
    };

    static defaultProps = {
      edit: true
    };

    state = {
      cms: 170
    };

    componentDidMount () {
    };

    getFeetFromCms () {
      return parseInt(this.state.cms / 30.48)
    }

    getInchesFromCms () {
      const inchesInFeet = (this.state.cms / 30.48) - parseInt(this.state.cms / 30.48)
      return Math.round(inchesInFeet * 12)
    }

    handleSend = () => {
      const { cms } = this.state
      this.props.handleSendClick({ text: cms })
    };

    render () {
      return (
            <div className='height-input-container'>
                <div>
                    <ReactSlider
                        className="input-slider"
                        thumbClassName="input-thumb"
                        trackClassName="input-track"
                        defaultValue={160}
                        min={140} max={230}
                        value={this.state.cms}
                        onChange={(value) => this.setState({ cms: value })}
                        renderThumb={(props, state) => <div {...props}/>}
                    />
                    <p className="height-input-display">
                        {this.getFeetFromCms()} Feet {this.getInchesFromCms()} Inches ({this.state.cms}cm)
                    </p>
                    <div className="field-footer">
                        <div className="container d-flex justify-content-center">
                            <Button onClick={this.handleSend}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
      )
    }
}
