import React from 'react'
import PropTypes from 'prop-types'
import { getPrice } from '../../../utils'
import Button from '../../buttons/Button'

const OrderDetailCard = function ({ order, handleRazorpayPayment }) {
  return (
        <div className="order-detail-card">
            <h4>Shipping Address</h4>
            {!!order.shipping_address &&
            <p className="text-muted">
                {order.shipping_address.name} <br/>
                {order.shipping_address.street}, {order.shipping_address.city} <br/>
                {order.shipping_address.state}, ({order.shipping_address.zipcode}) <br/>
                {order.shipping_address.phone}
            </p>
            }
            <h4>Payment Method</h4>
            <p className="text-muted">
                {order.checkout.razorpay_order_id ? 'Online' : 'Cash on Delivery'}
            </p>
            <h4>Order Summary</h4>
            <ul className="list-group order-detail-summary">
                {
                    !!order.cart &&
                    <React.Fragment>
                        {
                            order.cart.items.map(
                              (item, index) => (
                                    <li key={item.id}
                                        className="list-group-item d-flex justify-content-between align-items-center">
                                        {item.item.title}
                                        <span className="badge badge-primary badge-pill">
                                            x {item.quantity}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            ₹{getPrice(item.line_item_total)}
                                        </span>
                                    </li>
                              )
                            )
                        }
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            Item(s) Subtotal:
                            <span className="badge badge-primary badge-pill">
                                ₹{getPrice(order.cart.subtotal)}
                            </span>
                        </li>
                    </React.Fragment>
                }
                <li className="list-group-item d-flex justify-content-between align-items-center">
                    Shipping:
                    <span className="badge badge-primary badge-pill">₹{getPrice(order.shipping_total_price)}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                    <b>Total:</b>
                    <span className="badge badge-primary badge-pill">₹{getPrice(order.order_total)}</span>
                </li>
            </ul>
            {
                order.status === 'pending' && order.checkout.razorpay_order_id &&
                <Button variant="red" className="complete-payment-button"
                        onClick={() => handleRazorpayPayment({ order })}>
                    Complete Payment
                </Button>
            }
        </div>
  )
}

OrderDetailCard.propTypes = {
  order: PropTypes.object,
  handleRazorpayPayment: PropTypes.func
}

OrderDetailCard.defaultProps = {
  order: {}
}

export default OrderDetailCard
