export default ({ author, title, description, url, images, keywords = [] }) => {
  const authorData = {
    '@type': 'Organization',
    name: 'Misters',
    url: 'https://misters.in/',
    sameAs: []
  }
  if (author) {
    authorData['@type'] = 'Person'
    authorData.name = author.name
  }

  const schemaTagInnerHTML = ({
    '@context': 'https://schema.org',
    '@type': 'Article',
    publisher: {
      '@type': 'Organization',
      name: 'Misters',
      url: 'https://misters.in/',
      logo: {
        '@type': 'ImageObject',
        url: 'https://s3-ap-south-1.amazonaws.com/mistersblog/2020/09/misters-logo-1.png'
      }
    },
    author: authorData,
    headline: title,
    url: url,
    datePublished: '2021-03-12T12:39:48.000Z',
    dateModified: '2021-03-12T12:39:48.000Z',
    image: images,
    keywords: keywords,
    description,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://misters.in/'
    }
  })

  return {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(schemaTagInnerHTML)
  }
}
