// External Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// My Imports
import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2, accordionList3 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import getArticleSchema from '../../../services/SchemaService/getArticleSchema'
import getFaqSchema from '../../../services/SchemaService/getFaqSchema'

const faqs = [
  {
    question: 'What are some other names for Safed Musli?',
    answer: 'Although Safed Musli is well known across India by the same name, it is also recognised by alternative names in varied languages. Its botanical name is Chlorophytum arundinaceum and its synonyms are Chlorophytum borivilianum and Asparagus adscendens. It is known as Shweta Musli in Sanskrit; Taniravi Thang in Tamil; Khiruva in Hindi (especially in the state of Uttar Pradesh); Safed Musli in both Hindi and Marathi; Dholi Musli in Gujrati; Tella Nela Tadi Gaddalu in Telugu and Shedheveli in Malayalam.'
  },
  {
    question: 'What is the description of a Safed Musli plant?',
    answer: 'Safed Musli refers to the annual herb that grows in both tropical and sub – tropical climates at an altitude of about 1500 meters. It can grow upto a <b>maximum height of about 1.5 feet</b> with its tubers having a <b>depth of about 10 inches</b>. Its <b>roots are pale brown or white in color</b>, with a rather characteristic odor and are tasteless. Its <b>root tubers are fleshy and cylindrical in shape</b> with a thickness of about 0.9 cm and a length of about 8 cm. The number of tubers Safed Musli has varies from plant to plant but on an average, around 5-30 tubers can be observed on every plant. <br/>  <br/>  It has <b>6 – 13 radical leaves which are spirally imbricated, slightly narrow at the base and are sessile  in nature</b>. The <b>leaves are about 30 cm long</b>. They are spread horizontally with a smooth surface,  wavy margins and a parallel venation. <br/> <br/> <b>Flowers</b> of the Safed Musli plant are <b>small and white, bracteates, pedicellate, and generally arranged beautifully in a cluster of 3</b>. These flower clusters are dense on the upper part. Bracts are linear, papery <b>and purplish in color</b> and are about <b>1 – 10.5 cm long. Pedicles</b> are whitish and are about <b>6 – 10 mm long</b>. The plant <b>carries a green or yellow colored fruit</b> which is almost equal in length and breadth. Its seeds are tiny and black in color and are enclosed in a hole. One of these holes is said to contain about <b>10 – 12 seeds</b> in it.'
  },
  {
    question: 'What are the Ayurvedic properties of Safed Musli?',
    answer: 'The ayurvedic properties of Safed Musli make it efficient in the management of health problems. Safed Musli is <b>Guru (heavy)</b> and <b>Snigdha (oily)</b> in <b>nature</b>. This property makes it a complete food which provides the fullness in the body. It is <b>Madhura (sweet)</b> in <b>taste</b>. It is <b>Sheeta (cold)</b> in its <b>potency</b>. It has the ability to balance the Vata and Pitta Dosha, due to which it is used for the treatment of disorders that occur because of an imbalance in these two Doshas.'
  },
  {
    question: 'What are the benefits of Safed Musli?',
    answer: 'Safed Musli is considered as one of the important herbs in the Indian Ayurvedic system of medicine. It is known for <b>improving the overall immunity</b> of an individual. In addition, it has a plethora of benefits in the field of sexual health. The <b>Vajikarana (aphrodisiac) property</b> of Safed Musli has proven t  be <b>beneficial for</b> various problems like <b>erectile dysfunction, impotence and low potency among males</b>.'
  },
  {
    question: 'How does Safed Musli help in bodybuilding?',
    answer: 'Bodybuilding is an extremely coveted practice. All over the world, individuals perform this activity for their mental and physical well being, and also for competitive sport. This practice is not only limited to one’s personality development, but also aids individuals to lead a disease free life. By <b>improving one’s diet, practising regular yoga, exercising and taking help of Ayurvedic herbs like Safed Musli</b>, one can naturally improve their personal health.'
  }
]

class SafedMusli extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props
    const path = `${match.params.language}/ingredient/safed-musli/`
    const scripts = [
      getArticleSchema({
        title: 'Safed Musli- to treat low potency | Misters',
        description: 'Safed Musli is considered as one of the important herbs in the Indian Ayurvedic system of medicine. It is known for improving the overall immunity of an individual. In addition, it has a plethora of benefits in the field of sexual health. The Vajikarana (aphrodisiac) property of Safed Musli has proven to be beneficial for various problems like erectile dysfunction, impotence and low potency among males. The frequently asked questions (FAQ’s) around Safed Musli often include its alternative  names, where it is found, how it helps bodybuilders and its benefits, to name a few. At Misters, we aim to answer all of this and more…',
        images: ['https://misters.in/web-assets/images/ingredients/shilajit-description.jpg'],
        keywords: 'shilajit, shilajit benefits, fulvic acid, shilajit supplements, shilajit best results, tau protein,natural medicine',
        url: path
      }),

      getFaqSchema({
        data: faqs,
        url: path
      })
    ]
    return (
      <Page meta={{
        title: 'Safed Musli- to treat low potency | Misters',
        path: path,
        description: 'Safed Musli is considered as one of the important herbs in the Indian Ayurvedic system of medicine. It is known for improving the overall immunity of an individual. In addition, it has a plethora of benefits in the field of sexual health. The Vajikarana (aphrodisiac) property of Safed Musli has proven to be beneficial for various problems like erectile dysfunction, impotence and low potency among males. The frequently asked questions (FAQ’s) around Safed Musli often include its alternative  names, where it is found, how it helps bodybuilders and its benefits, to name a few. At Misters, we aim to answer all of this and more…'
      }} script={scripts}>
        <div className="ingredient-header-img"
             style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
          <div className="ingredient-header-bg-drop"/>
          <div className="ingredient-header-content">
            <h1>Safed Musli- to treat low potency</h1>
          </div>
        </div>
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>What is Safed Musli?</h2>
              <p>
                Safed Musli is a <b>herb</b> which is cultivated and eaten as a leafy vegetable in
                India. Its roots are used for medicinal purposes, to <b>manage sexual disorders</b>.
                Safed Musli is Shukrala (spermatogenic) in nature due to which it is useful in improving
                potency among males. Moreover, it has also helped to <b>treat conditions like arthritis
                and diabetes, diarrhea, dysentery or general debility</b> in numerous cases.
              </p>
              <p className="text-center font-weight-bold">
                <span>To know more about Safed Musli, </span>
                <a className="ingredient-blog-links" target="_blank"
                   href={`https://misters.in${match.params.language}/blog/What-is-Safed-Musli`}>click here</a>
              </p>
              <br/>
              <h2>Where is Safed Musli found?</h2>
              <p>
                Safed Musli is cultivated in the <b>western region of India</b>, mainly in the rainy
                season. It often grows wild across the Indian subcontinent as well.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-md-10 offset-md-1 col-12">
              {accordionList1}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-md-10 offset-md-1 col-12">
              <ImageBreaker image={Images.ingredient.safedDescription} to={shopUrl}
                            buttonText={'SHOP'}/>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              {accordionList2}
            </div>
          </div>
        </div>

        <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              {accordionList3}
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

SafedMusli.propTypes = {
  match: PropTypes.object
}

export default SafedMusli
