import {
  CREATE_REVIEW,
  CREATE_REVIEW_RESULT,
  CREATE_REVIEW_ERROR,
  CREATE_REVIEW_RETRY,

  FETCH_REVIEWS,
  FETCH_REVIEWS_RESULT,
  FETCH_REVIEWS_ERROR,
  FETCH_REVIEWS_RETRY
} from '../../actions/ecommerce/reviews'

const initialState = {
  detail: {},
  list: {},
  isFetching: false,
  isFetchingList: false,
  isUpdating: false,
  isCreating: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REVIEW:
      return { ...state, isCreating: true, error: false, message: null, retry: false }
    case CREATE_REVIEW_RESULT:
      return { ...state, isCreating: false, detail: action.data }
    case CREATE_REVIEW_ERROR:
      return { ...state, isCreating: false, error: true, message: action.message }
    case CREATE_REVIEW_RETRY:
      return { ...state, isCreating: false, retry: true, message: action.message }

    case FETCH_REVIEWS:
      return { ...state, isFetchingList: true, error: false, message: null, retry: false }
    case FETCH_REVIEWS_RESULT:
      return { ...state, isFetchingList: false, list: action.data }
    case FETCH_REVIEWS_ERROR:
      return { ...state, isFetchingList: false, error: true, message: action.message }
    case FETCH_REVIEWS_RETRY:
      return { ...state, isFetchingList: false, retry: true, message: action.message }

    default:
      return { ...state }
  }
}

export default reducer
