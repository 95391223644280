// External Imports
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Spinner from 'react-bootstrap/Spinner'
// My Imports
import ImageService from '../../../services/ImageService'
import { getDiscount, getLanguageTranslation, getPrice } from '../../../utils'
import Button from '../../../components/buttons/Button'
import LText from '../../../components/core/LText'
import Ecommerce from '../../../services/Ecommerce'
import { createCartItem } from '../../../actions/ecommerce/cart'
import TherapyPrice from '../../Therapy/Components/TherapyPrice'
import TherapyRating from '../../Therapy/Components/TherapyRating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import Carousel from 'react-bootstrap/Carousel'
import Image from '../../../components/core/Image'
import { updateWishlist } from '../../../actions/ecommerce/wishlist'
import ReferProduct from '../../../components/others/ReferProduct/ReferProduct'
import { PHONE_NUMBER } from '../../../constants'

const Section1 = ({ location, match, history, product, profile, wishlist, cart, createCartItem, updateWishlist, isCallNowProductPage }) => {
  const [direction, setDirection] = useState(null)
  const [index, setIndex] = useState(0)
  const isAddedToWishlist = !!wishlist.detail && wishlist.detail.products &&
    wishlist.detail.products.filter(obj => obj.id === product.id).length > 0

  const language = match.params.language

  const price = getPrice(product.price)
  const salePrice = product.sale_price ? getPrice(product.sale_price) : null
  const discount = getDiscount(price, salePrice)

  const ratingsStarCount = product.ratings_star_count
  const ratingsCount = product.ratings_count

  const productImages = product.images

  const handleAddItemToCart = async (product) => {
    Ecommerce.addToCart({ products: [product] })
    createCartItem({ id: product.id, quantity: 1 })
  }

  const handleAddItemToWishlist = async (product) => {
    const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email
    if (!isAuthenticated) {
      history.push(`${match.params.language}/login/?next=${match.url}?wishlist=true`)
    } else {
      updateWishlist({ product_id: product.id, remove: false })
    }
  }

  useEffect(() => {
    const querySearch = location.search
    const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email
    if (querySearch.includes('wishlist=true') && isAuthenticated && !isAddedToWishlist) {
      updateWishlist({ product_id: product.id, remove: false })
    }
  }, [])

  const handleSelect = (selectedIndex, e) => {
    setDirection(e.direction)
    setIndex(selectedIndex)
  }

  return (
    <div className='solution-page-section'>
      <div className="container">
        <div className="solution-page-section-bg-image">
          <Carousel
            activeIndex={index}
            direction={direction}
            onSelect={handleSelect}
            interval={9999999}
            controls={productImages.length > 1}
            indicators={productImages.length > 1}
            nextIcon={<FontAwesomeIcon icon={faAngleRight} color={'#888'} size="lg"/>}
            prevIcon={<FontAwesomeIcon icon={faAngleLeft} color={'#888'} size="lg"/>}
          >
            {
              productImages.map(
                (img, index) => (
                  <Carousel.Item key={index}>
                    <div className="solution-page-carousel-image">
                      <Image src={img} alt={product.title}/>
                    </div>
                  </Carousel.Item>
                )
              )
            }
          </Carousel>
          {/* <img src={getHttpsImageUrl(product.image)} alt={product.title}/> */}
        </div>
        <div className="row py-md-5 pb-4">
          <div
            className="col-md-6 col-12 d-flex order-2 order-md-1 align-items-center justify-content-center">
            <div className='text-left pr-3'>
              <h1 className="solution-page-title">
                {getLanguageTranslation(product, 'title', language)}
              </h1>
              <p className="solution-page-subtitle">+ free expert consultation</p>
              {
                product.product_id === 'fssaijosh' &&
                <ReferProduct product={product}/>
              }
              <p className="solution-page-short-description">
                {getLanguageTranslation(product, 'short_description', language)}
              </p>
              <TherapyPrice discount={discount} price={price} salePrice={salePrice}/>
              <TherapyRating rating={ratingsStarCount / ratingsCount}
                             ratingCount={ratingsCount}/>
              <div className="product-pricing">
                {/* <TherapyPrice discount={discount} price={price} salePrice={salePrice}/> */}
                <div className="pt-0 pt-sm-2">
              {
                !!product.international_shipment &&
                  <p className="pt-2 mb-0" style={{ fontSize: 13 }}>
                    <img style={{ width: 35, height: 35, filter: 'invert(48%) sepia(76%) saturate(3688%) hue-rotate(328deg) brightness(100%) contrast(104%)' }}
                         src={ImageService.product.international_shipment}
                         alt=""/> For shipping outside india, <a style={{ fontWeight: 'bold' }} href={product.international_shipment}>click here</a>
                  </p>
              }
                  {
                    isCallNowProductPage
                      ? <a href={`tel:${PHONE_NUMBER}`} className="btn-m btn-m-red mr-sm-3 mb-sm-1 solution-page-add-to-cart">
                        {
                          cart.isCreatingCartItem
                            ? <Spinner animation="border" size="sm" variant="light"/>
                            : 'Call Now'
                        }
                        <div className="solution-page-add-to-cart-bottom-number">{PHONE_NUMBER}</div>
                      </a>
                      : <Button variant="red" onClick={() => handleAddItemToCart(product)}
                              className="mr-sm-3 mb-sm-1 solution-page-add-to-cart">
                        {
                          cart.isCreatingCartItem
                            ? <Spinner animation="border" size="sm" variant="light"/>
                            : <LText k="ADD_TO_CART"/>
                        }
                      </Button>
                  }
                </div>
              </div>
              <button className="wishlist-button"
                      onClick={() => handleAddItemToWishlist(product)}>
                {isAddedToWishlist ? 'Added to Wishlist' : '+ Add to wishlist'}
              </button>
              <div className="solution-page-section-trust-marker">
                <img src={ImageService.home.badge} alt="Badge"/> Trusted by <strong>25,000+ Indian Men</strong>
              </div>
              <div className="solution-page-section-trust-marker">
                <img src={ImageService.home.badge} alt="Badge"/> Recommended by doctors
              </div>
              {
                !!product.trust_marker &&
                <div className="solution-page-section-trust-marker">
                  <img src={ImageService.home.badge} alt="Badge"/> {product.trust_marker}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Section1.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  profile: PropTypes.object,
  wishlist: PropTypes.object,
  product: PropTypes.object,
  isCallNowProductPage: PropTypes.bool,
  cart: PropTypes.object,
  createCartItem: PropTypes.func,
  updateWishlist: PropTypes.func
}

Section1.defaultProps = {
  isCallNowProductPage: false
}

const mapStateToProps = state => ({
  profile: state.accounts.profile,
  cart: state.ecommerce.cart,
  wishlist: state.ecommerce.wishlist
})

const matchDispatchToProps = dispatch => bindActionCreators({
  createCartItem,
  updateWishlist
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Section1))
