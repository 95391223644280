import React from 'react'
import * as PropTypes from 'prop-types'

export default class Card extends React.Component {
    static propTypes = {
      className: PropTypes.string
    };

    static defaultProps = {
      className: ''
    };

    componentWillUnmount () {
    }

    render () {
      const { className, ...props } = this.props

      return (
            <div className={`default-card ${className}`} {...props}>
                {this.props.children}
            </div>
      )
    }
}
