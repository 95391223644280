import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import StarRatings from 'react-star-ratings'

import { Images } from '../../../constants'

const ReviewCard = function ({ review }) {
  return (
        <div className="review-card-container mb-3">
            <div className="review-card-user">
                <img className="review-profile" src={Images.review_profile} alt="review profile"/>
                <div className="flex-fill d-flex flex-column flex-md-row justify-content-md-between">
                    <div>
                        <div className="review-card-user-name">
                            {review.name}
                            {review.verified_purchase && <span className="review-verified">Verified Buyer</span>}
                        </div>
                        <StarRatings
                            rating={review.star}
                            starDimension="12px"
                            starSpacing="3px"
                            starRatedColor="#FF0000"
                            isSelectable={false}
                            numberOfStars={5}
                            name='rating'
                        />
                    </div>
                    <div className="review-card-timestamp">
                        {moment(review.created_at).format('MMM DD, YYYY')}
                    </div>
                </div>
            </div>
            {review.star >= 4 &&
            <div className="review-card-recommend">
                <img src={Images.like} alt=""/>
                <h5>I recommend this product</h5>
            </div>
            }
            <div className="review-card-description">
                <p>{review.text}</p>
            </div>
        </div>
  )
}

ReviewCard.propTypes = {
  review: PropTypes.object
}
export default withRouter(ReviewCard)
