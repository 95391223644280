import React, { Component } from 'react'
import PropTypes from 'prop-types'

class AnalysisLifeStyleScoreCard extends Component {
    static propTypes = {
      className: PropTypes.string,
      score: PropTypes.number,
      duration: PropTypes.number,
      percentile: PropTypes.number,
      delay: PropTypes.number,
      offset: PropTypes.number
    };

    static defaultProps = {
      className: '',
      score: 0,
      duration: 1000,
      delay: 100,
      offset: 200
    };

    constructor (props) {
      super(props)

      this.state = {}
    }

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState, snapshot) {

    }

    componentWillUnmount () {
    }

    render () {
      const { className, duration, delay, score, offset, percentile } = this.props

      return (
            <div className={`analysis-life-style-score-card ${className}`} data-aos-offset={offset}
                 data-aos="zoom-in" data-aos-duration={duration} data-aos-delay={delay} data-aos-once="true">
                <h1 className="life-style-score-count">{score}</h1>
                <h5 className="life-style-score-title">
                    Lifestyle score
                    <br/>
                    <small>is healthy</small>
                </h5>
              {
                !!percentile &&
                <p className="report-percentile">Your score is more than {percentile}% of all users who has taken this
                  quiz</p>
              }
            </div>
      )
    }
}

export default AnalysisLifeStyleScoreCard
