import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import * as PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FormInput from '../../inputs/FormInput'
import { InputGroup, ListGroup } from 'react-bootstrap'
import Button from '../../buttons/Button'
import { fetchCoupons } from '../../../actions/ecommerce/coupons'
import moment from 'moment'

class CouponModal extends Component {
    static propTypes = {
      show: PropTypes.bool,
      handleClose: PropTypes.func,
      fetchCoupons: PropTypes.func,
      handleUpdateCartCoupon: PropTypes.func
    };

    static defaultProps = {};

    constructor (props) {
      super(props)

      this.state = {
        coupon_code: ''
      }
    }

    componentDidMount () {
      this.props.fetchCoupons()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {

    }

    render () {
      const { show, handleClose, coupons: { list = [] }, handleUpdateCartCoupon, message } = this.props
      const { coupon_code } = this.state

      return (
            <Modal className="coupon-modal" size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    APPLY COUPON
                </Modal.Header>

                <Modal.Body className="p-0">
                    <div className="p-4">
                        <InputGroup className="align-items-start">
                            <div className="flex-fill">
                                <FormInput placeholder='Enter Coupon Code'
                                           autoFocus={true} value={coupon_code || ''}
                                           onChange={(e) => this.setState({ coupon_code: e.target.value })}
                                           error={!!message && [message]}/>
                            </div>
                            <InputGroup.Append>
                                <Button className="apply-button" onClick={() => handleUpdateCartCoupon(coupon_code)}
                                >APPLY</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    <div className="coupon-list-container">
                        <ListGroup>
                            {!list.length &&
                            <ListGroup.Item className="coupon-not-available border-0 bg-transparent">
                                <small className="text-muted d-block mt-1 text-center">
                                    No other coupons available
                                </small>
                            </ListGroup.Item>
                            }

                            {list.map(coupon => (
                                <ListGroup.Item key={coupon.id} className="coupon-item" action
                                                onClick={() => handleUpdateCartCoupon(coupon.code)}>
                                    <span className="coupon-code">{coupon.code}</span>
                                    {
                                        coupon.expiry_date &&
                                        <small className="text-muted d-block mt-1">Valid till
                                            {moment(coupon.expiry_date).format('MMM DD, YYYY')}</small>
                                    }
                                    <small className="text-muted d-block mt-1">{coupon.text}</small>
                                </ListGroup.Item>
                            ))}

                        </ListGroup>
                    </div>
                </Modal.Body>
            </Modal>
      )
    }
}

const mapStateToProps = state => ({
  coupons: state.ecommerce.coupons
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchCoupons
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(CouponModal)
