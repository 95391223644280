// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../common/AccordionCard'
import Card from 'react-bootstrap/Card'
import { Images } from '../../../constants'
import CategoryBreaker from '../common/CategoryBreaker'

const shopUrl = '/en/solutions/'

export const accordionList1 = [
    <AccordionCard key={'0'} title="What are the causes of Erectile Dysfunction (ED)?">
            <p>Most men think that erection problems are age-related and are
                unavoidable. But in reality, erectile dysfunction is not inevitable and
                can be treated. Many factors favour the onset of erectile dysfunction:
                physical problems, possibly related to a disease, or side effects of the
                medications you take. Personal problems can also play a role. But
                erectile dysfunction can be cured with the right treatment, natural
                remedies, alternative therapies and exercise.</p>
            <p>The nature of the problems encountered during erection helps to define
                their cause:</p>
            <ul>
                <li>if erection is no longer possible, the disorders are probably
                    related to a physical
                    condition;
                </li>
                <li>when erections still occur at night, in the morning or during
                    masturbation, the
                    problems are often of psychological origin.
                </li>
            </ul>

            <h3>What are the physical causes of erectile dysfunction (ED)?</h3>
            <p><strong>Vascular problems:</strong> In this situation, erectile
                dysfunction is due to the narrowing of the blood vessels. The erections
                become inadequate as the penile tubes do not receive enough blood. This
                problem is called arteriosclerosis (narrowing of the blood vessels) and
                is often associated with smoking, diabetes, high blood pressure and
                increased cholesterol (unhealthy lifestyle).</p>
            <p><strong>Neurological problems:</strong> The brain (where the centre of
                desire is) and the genitals (where the stimulation comes from) are
                linked by the spinal cord. If this connection fails, the erection will
                be disrupted. Damage to the nervous system can be caused by diseases
                such as diabetes, multiple sclerosis, stroke, spinal cord injury (spinal
                fracture) or prostate surgery.</p>
            <p><strong>Hormonal disorders:</strong> Low levels of testosterone is one of
                the primary reasons for erectile dysfunction. Diseases related to the
                thyroid gland, which produces a hormone that controls metabolism and
                energy levels can also be a cause of erectile dysfunction in some men.
                Testosterone levels that are too low are very common in men with
                HIV/AIDS and especially those taking anti-HIV medications.</p>
            <p><strong>Legal and illegal drugs:</strong> If your problems have started
                after starting a new treatment, speak to your doctor. Several classes of
                medications can cause erectile dysfunction. It may also occur as a
                result of the use of recreational drugs or alcohol. Remember that
                alcohol in its initial phase has a stimulating effect leading to a
                decrease in inhibition. This is followed quickly by a depressive phase
                during which alcohol interferes with erectile function. Tobacco also has
                a direct influence on erectile function. One of the first steps in
                treating erectile dysfunction should be stopping smoking.</p>

            <h3>What are the psychological causes of erectile dysfunction (ED)?</h3>

            <p>In men under 40, erection problems are usually due to psychological
                factors. The following psychological factors may increase erectile
                dysfunction of physical origin.</p>
            <p><strong>Performance anxiety</strong> is the first psychological cause of
                erection problems. When the man is afraid of not being able to have an
                erection and not being able to satisfy his partner, his anticipatory
                fears prevent the appearance of a normal erection;</p>
            <p><strong>Depression</strong> can make men uncomfortable in intimate
                situations. The physical and psychological effects of depression can
                impact erections.&nbsp;</p>
            <p><strong>Relationship problems</strong> that crop up due to trust issues
                or other indifferences can lead to a loss of interest in sex.&nbsp;</p>
            <p><strong>Difficulties</strong> encountered in previous relationships or
                perceptions created due to previous partners&rsquo; criticism can also
                be one of the causes.&nbsp;</p>
        </AccordionCard>,
    <AccordionCard key={'1'} title="What are the symptoms of Erectile Dysfunction (ED) or impotence?">
            <p>Erection problems and erectile dysfunction are two terms that refer to
                the same sexual concern in men. However, it is important to note that,
                despite an erection problem, a man can totally feel desire, have an
                orgasm and even ejaculate.</p>
            <p>Erectile dysfunction can be of the following nature:</p>
            <ul>
                <li>The absence of erection during intercourse;</li>
                <li>An erection present but insufficient;</li>
                <li>An erection that fails during the act;</li>
                <li>Absence of erection during intercourse but comfortable voluntary
                    erection at night and in the morning;
                </li>
                <li>Absolute erection failure in all situations.</li>
            </ul>
            <p>These problems need attention and intervention only when:</p>
            <ul>
                <li>If you observe a recurring problem in achieving an erection during
                    sexual intercourse;
                </li>
                <li>the problem lasts for more than 90 days.</li>
            </ul>
        </AccordionCard>,
    <AccordionCard key={'2'} title="How to identify erectile dysfunction?">
        <p>The International Index of Erectile Function (IIEF) helps identify
            erectile dysfunction by answering certain questions based on experience
            over the previous four weeks.</p>
        <p><strong>Duration:</strong> In general, men have an erection that lasts an
            average of twenty minutes. Thus, the duration of maintaining a good
            erection is a determining factor to recognise the signs or symptoms of
            erectile dysfunction. If you have difficulty maintaining an erection for
            more than 5 minutes, then you are likely to suffer from erectile
            dysfunction.</p>
        <p><strong>Rigidity:</strong> This factor is the one that determines whether
            the erection is soft or hard. When the cavernous bodies in the penis do
            not fill up, it results in a soft erection. This makes it difficult to
            enjoy satisfactory sexual intercourse.</p>
        <p>Erectile dysfunction is, therefore, related to the firmness of your
            erections too.</p>
    </AccordionCard>,
    <AccordionCard key={'3'} title="What are the risks of ED or impotence?">
        <p>Erectile dysfunction is caused by several chronic diseases. However, if
            you have not been diagnosed with any cardiovascular or chronic diseases
            but still suffer from erectile dysfunction, you are at risk of
            developing them. Here are a few health risks associated with erectile
            dysfunction:</p>
        <p><strong>Here are a few health risks associated with erectile
            dysfunction:</strong></p>
        <p><strong>Hypertension</strong>: As per a study by Nunes and Labazi, it has
            been determined that erectile dysfunction can be one of the signs and
            symptoms of hypertension.</p>
        <p><strong>Prostate Cancer</strong>: Patients with erectile dysfunction have
            a 1.27 times higher risk of developing prostate cancer, as per a study
            by urologists and doctors in Taiwan.</p>
        <p><strong>Cardiovascular Risk</strong>: An erection problem can be an early
            sign or the first sign of cardiovascular disease such as a myocardial
            infarction.</p>
        <p><strong>Depression</strong>: Erectile dysfunction can often lead to a
            feeling of rejection and low self-esteem. The insecurity, anger and
            frustration associated with the condition can lead to depression.</p>
        <p><strong>Diabetes</strong>: If men below the age of 45 are diagnosed with
            erectile dysfunction, it can be a sign of type 2 diabetes. Since type 2
            diabetes is often the result of obesity or lack of physical activity,
            its link with erectile dysfunction can be established.&nbsp;&nbsp;</p>
        <p><strong>Troubled sex life</strong>: The life of a couple takes the first
            hit in case of erectile dysfunction. Unless proper communication is
            established, erectile dysfunction can disrupt personal life and have a
            lasting impact.</p>
        <p><strong>Psychological stress</strong>: Men with erectile dysfunction
            often question their manhood. While there is no connection, yet the
            pressure of not being able to satisfy their partner or lead a normal and
            active sexual life can lead to psychological stress.&nbsp;</p>
        <CategoryBreaker to={shopUrl} buttonText={'SHOP'} image={Images.cgEd1}
                         text={'Did you know Ed can be early warning sign of diabetes or heart problems?'}/>
    </AccordionCard>,
    <AccordionCard key={'4'} title="What is the treatment of erectile dysfunction (ED)?">
        <p><span>Erectile dysfunction is far from inevitable. However, there are many treatments to find a fulfilling sex life. Speaking to your doctor to identify the right treatment is recommended.&nbsp;</span>
        </p>
        <p><strong>Oral medication</strong><span>: The primary solution that you may be advised to take is oral treatment. Also known as PDE5 inhibitors, some drugs increase penile blood flow if taken one hour before intercourse, to get a harder and longer erection. Some of the commonly used drugs are Vardenafil (Levitra), sildenafil (Viagra), and tadalafil (Cialis). Be careful, though because these medications only work if you are sexually stimulated. Ask your doctor about the benefits and risks of using these drugs.</span>
        </p>
        <p><strong>Hormonal treatment</strong><span>: If the problem comes from low testosterone production (or hypogonadism), it is recommended to take a hormone treatment that will allow you to regain normal levels of testosterone. However, this treatment is found to be effective for one in three men only.</span>
        </p>
        <p><strong>Intra urethral treatments</strong><span>: If you cannot take oral therapy, or if they are ineffective in treating your erectile dysfunction, your doctor may recommend vasoactive substances such as Alprostadil. It can be injected into the penis or inserted as suppositories inside the penile duct. It allows you to have an erection after 5 to 30 minutes. This solution would be effective on more than 30% of men who could not take a pill to bandage. This approach is called MUSE (Medicated Urethral System for Erection). Prostaglandins E1 can also be administered intra-urethra.</span>
        </p>
        <p><strong>Penis pumps: </strong><span>This device helps boost the blood supply to the penis to induce an erection. This is favourable for men with nerve damage wherein the lack of blood flow is one of the causes of erectile dysfunction. The penis pump also consists of a ring that helps keep the blood in the penis, which is beneficial in cases where the severity of damage to the blood vessels and nerves is higher. The penis is placed in a plastic tube connected to the pump in order to activate the mechanism. The suction of the air contained in the tube then generates an influx of blood towards the penis, causing an erection which is maintained thanks to the insertion of a constriction ring at the base of the penis. This ring also prevents blood from flowing back, but should not be used for more than 30 minutes. Penis pumps are one of the most affordable and effective devices to treat erectile dysfunction.</span>
        </p>
        <p><strong>Penile implants or surgery</strong><span>: Finally, if these solutions are ineffective, a doctor may recommend surgery or penile implants. The procedure consists of implanting a device in the penis, which ensures timely and quick erections. Effective in most cases, penile surgery for erectile dysfunction has a complication rate of less than 5%. It is, however, a solution of last resort.</span>
        </p>
        <p><strong>Alternative medicine</strong><span>: Some men with erectile dysfunction find relief in traditional medicines, such as acupuncture, and herbal or Ayurvedic remedies. In alternative medicine or Ayurveda, there are many plants that exist to treat erectile dysfunction. Ginseng, for instance, helps increase vitality and sexual stamina. It also helps to better manage stress and performance anxiety. The saw palmetto (cabbage palm) has been used to increase libido while Yohimbe is a natural dilator of blood vessels.</span>
        </p>
        <p><strong>Exercise</strong><span>: The muscles of the pelvic floor support the bladder and support urination and ejaculation. A 2010 study says that pelvic floor exercises can help diabetic men get and maintain erections. Yoga is known to address both physical and psychological causes of erectile dysfunction. Practising yoga regularly can also have a positive impact on men&rsquo;s sexual health. A Harvard study suggests that walking 30 minutes daily also minimizes the risk of erectile dysfunction. Thus, exercises that help strengthen the pelvic muscles can help in minimising the symptoms of erectile dysfunction.&nbsp;</span>
        </p>
        <p><strong>Change of lifestyle: </strong><span>It is possible that lifestyle is a cause of erectile dysfunction, especially in men below the age of 40. It is, therefore, advisable to discuss the different options with your doctor. Depending on the state of health, the doctor may advise to carry out relaxation exercises to better manage stress and blood pressure, to lose weight, to lower blood pressure and improve cholesterol and testosterone levels. For men with diabetes or cardiovascular disease, a change of diet is recommended. Quitting smoking, alcohol and tobacco is also advised.&nbsp;</span>
        </p>
        <p><strong>Counselling</strong><span>: Erectile dysfunction can have a negative impact on your self-esteem and can, in some cases, cause depression or anxiety. Visiting a psychologist or psychiatrist can help to understand and eliminate the cause of the problem. Couple therapy can also be considered in order to find healthy and constructive ways to communicate about erectile dysfunction with partners.</span>
        </p>
        <CategoryBreaker to={shopUrl} buttonText={'SHOP'} image={Images.cgEd2}
                         text={'Did you know Kegel exercise reduces chances of ED?'}/>
    </AccordionCard>,
    <AccordionCard key={'5'} title="Why does erection happen?">
        <p>
            The penis is not provided with bones to stay erect, like that of some
            animals. An erection is a physiological phenomenon in which the penis
            hardens due to a large blood supply in the vessels that constitute it.
            <br/> <br/> Achieving an erection is simple but reaching this stage is a
            complex process. It involves the synchronization of physical and
            psychological events.
            <br/> <br/> The penis has two paired chambers inside it, called the
            corpora cavernosa. The interior of these chambers is made of spongy
            tissue with cavernous spaces, like tiny caves, which are lined with
            smooth muscle.
            <br/> <br/> These muscles, when relaxed due to sexual stimulation, can
            promote better blood flow through the arteries to fill the spaces.
            <br/> <br/> This blood then creates pressure on the corpora cavernosa
            resulting in an erection. Since the blood that enters fast in the
            corpora cavernosa cannot leave the veins as fast, it is trapped in the
            penis.
            <br/> <br/> This process helps maintain an erection. In a healthy man,
            an erect penis can support up to 8 times normal blood flow.
        </p>
        <h3>How does the penis become erect?</h3>
        <p>
            It is necessary to remember that for there to be an erection; there has
            to be arousal or some sexual stimulation. It is after this that the
            brain will order the penis to erect.
            <br/> <br/>Sexual arousal helps release a neurotransmitter in the brain
            commonly known as nitric oxide.
            <br/> <br/>This nitric oxide (NO) produces a messenger called cyclic
            guanine monophosphate (cGMP). This plays a dual role by increasing the
            size of the blood vessels that carry blood to the penis and decreasing
            the size of blood vessels which carry blood out.
            <br/> <br/>However, it is still important to note that a man's penis can
            get erect even without prior stimulation. This is, for example, the case
            at night when an individual is asleep.
            <br/> <br/>Generally called nocturnal erection, these can occur 3 to 6
            times a night. In all, men experience an erection 25% of the time at
            night. When entering deep REM sleep, an erection is triggered for a
            duration of 20 to 25 minutes.
        </p>
        <h3>What is morning wood?</h3>
        <p>There are reflex erections that occur very early in the morning. The
            morning erection in men is one of the three main types of penis erection
            that a man can have. The morning erection, or morning wood, is actually
            the last of the nocturnal erections. Contrary to the myth, morning
            erection has nothing to do with the urge to urinate.

            <br/> <br/> The morning erection is normal and healthy. It is a part of
            the body functions in males. Your body is calibrated to achieve an
            erection every morning.

            <br/> <br/> In fact, lack or absence of morning erections for a longer
            period of time can be a sign of other serious problems and diseases like
            heart disease, irregular hormone levels, disturbed sleep patterns or
            poor blood circulation.

            <br/> <br/> More broadly, the absence of nocturnal erections or morning
            erections is a particularly important indicator of systemic health
            problems, precisely because these erections are involuntary and not
            psychological or unrelated to sexual arousal.

            <br/> <br/> This, therefore, means that erection can occur at any time
            of the day or night for a man. And in many situations, this erection of
            penis can be both voluntary and involuntary. Involuntary erections are,
            however, perfectly normal in adolescents and adults.

            <br/> <br/> In addition, an erection, although triggered by a stimulant
            in most cases, does not necessarily mean that there is desire. In some
            men, there can be an erection without desire, especially on waking.

        </p>
    </AccordionCard>,
    <AccordionCard key={'6'} title="Why does Erectile Dysfunction, or impotence, happen?">
        Erectile Dysfunction could mean trouble and is very likely to have
        repercussions both in the patient's sexual life and in that of the couple.
        <br/> <br/>Erectile dysfunction is not limited to the ability to get an
        erection. It also includes cases where the degree of firmness of an erection
        is insufficient to have sex.
        <br/> <br/>In order to achieve an erection, there is a very precise balance
        to be maintained among the organs involved: the heart, lungs, your hormones,
        nerves, blood vessels and even the brain.
        <br/> <br/>All of these organs must coordinate in a complex sequence of
        events. This is not the case when there is erectile dysfunction, which can
        be defined as the impossibility for an individual to obtain or maintain an
        erection sufficient to have satisfactory sexual intercourse.
        <br/> <br/>The erectile problems, also known as Erectile Dysfunction (ED) or
        impotence, is caused by a multitude of factors.
        <br/> <br/>The firmness of your erections is also an important indicator of
        your general health.
        <br/> <br/>The blood vessels in the penis are smaller than the arteries and
        veins in other parts of your body, so any problems like blockages, dilated
        blood vessels, or hormonal imbalances often manifest as erectile dysfunction
        or soft erections.
        <br/> <br/>A problem with achieving an erection may also be the first
        warning sign of more serious underlying causes such as diabetes, heart
        disease, high blood pressure or hypo-testosterone.
        <h3>What is the source of impotence occurrence?</h3>
        <p><strong>Natural Origin</strong></p>
        <p>Age: This is one of the major factors that can cause a soft erection. In
            men over 40, achieving and maintaining an erection is difficult. At over
            50, the body gradually weakens, and it becomes difficult for men to have
            an erection or even maintain it. However, this does not in any way mean
            that this phenomenon does not affect younger people. </p>
        <p><strong>Psychological Origin</strong></p>
        <p>It is one of the main sources of erectile dysfunction in individuals
            under the age of 40. This includes:</p>
        <ul>
            <li>Performance anxiety</li>
            <li>Stress</li>
            <li>Libido</li>
        </ul>
        <p><strong>Organic causes</strong></p>
        <ul>
            <li>Hypertension</li>
            <li>Diabetes</li>
            <li>Tobacco</li>
            <li>Certain conditions of haematological types such as sickle cell
                anaemia, hemochromatosis or even thalassemia
            </li>
            <li>Hormonal diseases like Parkinson's disease, or multiple sclerosis
            </li>
            <li>Obesity and overweight</li>
            <li>The malformation of the reproductive system due to diseases like
                Peyronie's disease or even hypospadias.
            </li>
        </ul>
    </AccordionCard>,
    <AccordionCard key={'7'} title="What are the medicines available for curing erectile dysfunction (ED)?">
        <p>In the event of erectile dysfunction, the patients would naturally turn
            to their general practitioner, even before consulting their
            partner/friend or sexologist. Hence, oral medication is offered as the
            first line of treatment for erectile dysfunction.
            Some of the popular medicines for erectile dysfunction (ED) are:</p>
        <ul>
            <li>Sildenafil (Viagra)</li>
            <li>Vardenafil (Levitra)</li>
            <li>Tadalafil (Cialis)</li>
            <li>Avanafil (Stendra)</li>
            <li>Alprostadil (Self-injection, urethral suppository)</li>
        </ul>
        <p>
            It is important to note that these oral medications are not aphrodisiacs
            and cannot boost sexual desire. Sexual stimulation will be necessary to
            experience the effects of the medicines. None of these medicines should
            be taken without consulting your doctor.
            <br/><br/>The other option that most men turn to include arginine, omega
            3 and multivitamins. These medicines address one of the underlying
            causes of erectile dysfunction – chronic diseases.
            <br/><br/>Omega 3 and multivitamins keep hypertension and cardiovascular
            problems at bay, thus reducing the effects of erectile dysfunction.
            L-arginine helps relax the blood vessels and improves circulation to
            improve the quality of erections.
            <br/><br/>Another holistic method of treating erectile dysfunction
            involves Ayurveda. The herbal remedies work as natural aphrodisiacs.
            <br/><br/>Consult your doctor before you take any over-the-counter
            medicines for erectile dysfunction. Based on your condition, a doctor
            can prescribe you the right medication.
        </p>
    </AccordionCard>,
    <AccordionCard key={'8'} title="What foods help in impotence or Erectile Dysfunction?">
        <p>
            A diet too rich in carbohydrates, sugars and fats has an impact on the
            blood circulation, which can result in erection problems. In addition to
            the prescribed medical treatments for erectile dysfunction, a healthy
            diet may be recommended for better sexual health.
            <br/><br/>
            Certain foods are known to enhance testosterone and nitric oxide levels
            in the body which can promote better erection and help treat erectile
            dysfunction.
        </p>
        <ul>
            <li><strong>Fruits</strong>: Berries, apples and pears are rich in
                flavonoids, an antioxidant and blood thinning compound.&nbsp; A high
                intake of foods rich in flavonoids is associated with better
                erectile function according to a Harvard study of 50,000 middle-aged
                men, published in January 2016 in the<strong>American Journal of
                    Clinical Nutrition</strong></li>
        </ul>
        <ul>
            <li><strong>Beetroots</strong>: This vegetable boosts nitric oxide
                levels in the body, which promotes the blood flow to the penis by
                dilating the blood vessels.&nbsp;</li>
        </ul>
        <ul>
            <li><strong>Olive oil</strong>: This can help the body produce more
                testosterone. Olive oil is also full of healthy monounsaturated
                fats, which can help remove bad cholesterol from the body.&nbsp;
            </li>
        </ul>
        <ul>
            <li><strong>Nuts</strong>: These contain arginine, an amino acid that
                the body uses to produce nitric oxide.&nbsp;</li>
        </ul>
        <ul>
            <li><strong>Dark chocolate</strong>: Chocolate is rich in flavanols,
                plant nutrients that can increase blood flow and lower blood
                pressure. It also helps the body to produce more nitric oxide, which
                can help with erection and is present in many erectile dysfunction
                medications.
            </li>
            <li><strong>Fish</strong>: Salmon and other fatty fish are great sources
                of heart-healthy omega-3 fatty acids, which can stimulate nitric
                oxide in the body.
            </li>
        </ul>
        <CategoryBreaker to={shopUrl} buttonText={'SHOP'} image={Images.cgEd3}
                         text={'Did you know high sugar intake can lead to ED?'}/>
    </AccordionCard>,
    <AccordionCard key={'9'} title="What foods can be avoided in impotence or Erectile Dysfunction?">
        <p>There are certain foods that may increase the risk of erection problems
            in men. These include:</p>
        <p><strong>Alcohol</strong>: These beverages can affect sexual functioning
            and lower testosterone levels.</p>
        <p><strong>Refined carbohydrates</strong>: Pasta, bread or any other
            products made from white flour can lead to a drop in the testosterone
            levels. They also affect the sex drive.</p>
        <p><strong>Soy products</strong>: Consuming soya in high quantities will not
            only affect libido but also lower testosterone levels. Hence, keep the
            intake of soy milk, tofu and other soy products to a minimum.</p>
        <p><strong>Fatty foods</strong>: Foods high in cholesterol can lead to
            cardiovascular problems which in turn cause erectile dysfunction.</p>
        <p><strong>Sugary drinks and foods</strong>: These foods can spike sugar
            levels but lead to a drop in the libido. These must be avoided in order
            to prevent obesity and diabetes, which are directly linked with erectile
            dysfunction.</p>
    </AccordionCard>,
    <AccordionCard key={'10'} title="How to prevent erectile dysfunction (ED)?">
        <p>In the majority of cases (approximately 80%), erectile dysfunction or
            impotence, is caused by an underlying disease. Taking steps to limit the
            risk factors for these diseases is the first step to prevent erectile
            dysfunction.</p>
        <p>Among the diseases that can cause erectile dysfunction because of their
            harmful effect on the vascular system, are diabetes, hypertension, and
            obesity. In order to take care of your vascular system, it is necessary
            to change certain unhealthy habits and adopt a healthier lifestyle. The
            following measures can help in preventing erection related problems:</p>
        <p><strong>Eat healthy</strong>: To reduce the risk factors related to
            obesity and diabetes or high cholesterol, eat healthily! Banning fatty
            and salty meals is a good start. Choose whole-grain foods, fresh
            vegetables and fruits, lean meats, fish and low-fat dairy products.</p>
        <p><strong>Quit smoking</strong>: Regular smoking leads to plaque build-up
            in the arteries and results in atherosclerosis. Smoking is also
            considered as one of the major risk factors of erectile dysfunction as
            it restricts the blood flow to the penis. In fact, men who smoke at a
            30% higher risk of erectile dysfunction.</p>
        <p><strong>Limit alcohol intake</strong>: Heavy use of alcohol increases the
            risk of erectile dysfunction in 60-70 % of men. Hence, reducing the
            consumption of alcohol will not only prevent erectile dysfunction but
            other cardiovascular and liver diseases.</p>
        <p><strong>Reduce weight</strong>: Maintaining a healthy weight can prevent
            diseases like obesity, diabetes and hypertension. If you are overweight,
            choose healthy weight loss plans instead of crash diets. This will help
            prevent complications and reduce the risk of erectile dysfunction too.
        </p>
        <p><strong>Manage stress</strong>: One of the common lifestyle problems that
            lead to erectile dysfunction in younger men is stress. Stress can
            decrease the vigour of your erections. Try to manage your stress
            constructively.</p>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="How to cope with Erectile Dysfunction (ED)?">
        <p>Erectile dysfunction profoundly alters confidence at all levels, both
            towards oneself and in relationships with others.
            <br/><br/>The impact of sexual disorders extends far beyond the sphere
            of just manhood and transfers to a problem of identity and self-esteem.
            Men are often invaded by feelings of worthlessness, humiliation and
            embarrassment.</p>
        <h3>Meet your doctor</h3>
        <p>
            Men suffering from erectile dysfunction still delay talking to a doctor
            about it. The taboo around the subject is a roadblock to finding the
            right course of treatment. Most men are not sure of the doctor's
            reaction and don't talk about it spontaneously.
            <br/><br/>Various surveys have shown that men expect the doctor to ask
            the question. A clear communication without the fear of being judged can
            help men cope with erectile dysfunction better.
        </p>
        <h3>Believe in your doctor’s therapy</h3>
        <p>Most men question the efficacy of the treatment and its side effects. The
            management of ED is never just physical or psychological. In most cases,
            it is the integration of addressing both the issues to rebuild the
            confidence of the patient. If you are undergoing treatment, you must
            understand that the treatment takes time and patience. </p>
        <h3>Talk to your partner</h3>
        <p>
            In a couple, sex has a major role, and the relationship flourishes when
            the two partners share quality moments of intimacy. Erectile dysfunction
            can put a temporary stop to the sexual life of a couple.
            <br/><br/>Lack of communication or constant performance pressure leads
            to anxiety, sometimes resentment, or withdrawal for fear of
            disappointment.
            <br/><br/>This state can sometimes lead to separations. It is,
            therefore, very important to discuss and accept the problem. This helps
            in restoring intimacy and confidence within a couple which is essential
            for harmonious and anxiety-free stimulation.
        </p>
        <h3>Go for counselling</h3>
        <p>
            What can work in your favour is clear communication with your partner.
            You can practice Yoga and meditation to reduce stress. Exercises and
            yoga asanas can act as an amplifier to your treatment. A positive
            mindset will also ensure that you address performance anxiety.
            <br/><br/>Speak to your partner to find a plan to enhance your sex life
            using different methods. Sex therapy can also work as an aid when living
            with erectile dysfunction.
            <br/><br/>Visit the clinic or counselling sessions with your partner (if
            you have one) so that both of you understand the effects of the
            treatment.
            <br/><br/>Remember, erectile dysfunction does not affect or lower your
            manhood. You are the same person with changed sex life.
            <br/><br/> Also, these changes may not be permanent, and with confidence
            in the treatment, you can live a happier and fulfilling life with
            erectile dysfunction (ED).
        </p>
        <CategoryBreaker to={shopUrl} buttonText={'SHOP'} image={Images.cgEd4}
                         text={'Did you know ED might be the side-effect of some hypertension medicines?'}/>
    </AccordionCard>,
    <AccordionCard key={'1'} title="Does sedentary lifestyle lead to impotence or ED?">
        <p>
            A sedentary lifestyle can harm your pelvic region and affect male sexual
            function.
            <br/><br/>
            <strong>Sedentary lifestyle</strong>: Sitting for long hours in the
            office with the same posture affects our entire body. When you are
            seated, the pelvic region is oppressed, which is not harmful, unless it
            is for long periods.
            <br/><br/>
            However, it can be one of the main causes of the onset of erectile
            dysfunction in men. On the other hand, the cervical and lumbar spine
            also tend to tighten and cause states of stress and general stiffness.
            <br/><br/>
            <strong>Stress</strong>: It is one of the main causes of many sexual
            dysfunctions. It causes mental blockages which affect the physical parts
            too. This stress is one of the psychological reasons for erectile
            dysfunction.
            <br/><br/>
            <strong>Back injury</strong>: Poor posture or activities that strain
            certain parts of our body will affect it negatively in the long run.
            <br/><br/>
            A clear example is weight lifting or running exercises, which strengthen
            our muscles, but deteriorate joints and overloaded muscle tissue if we
            don't stretch well before and after workouts.
            <br/><br/>
            <strong>Diet and fatigue</strong>: An imbalanced diet means that the
            body does not receive the nutrients necessary to function optimally.
            Also, lack of quality sleep and complete rest are among the causes of
            constant or chronic fatigue.
            <br/><br/>This contributes to the loss of desire and gradually leads to
            erectile dysfunction as you age.
        </p>
    </AccordionCard>,
    <AccordionCard key={'2'} title="Does Yoga help cure Erectile Dysfunction (ED)?">
        <p>
            Yoga is popular across the world due to its remarkable benefits on
            physical, psychological and emotional health.
            <br/><br/>To understand how Yoga can help and what its health benefits
            are, first analyse the daily routines and habits that harm your pelvic
            region and affect male sexual function.
            <br/><br/>Practising Yoga can help increase muscle tone, self-esteem and
            improve male sexual performance and even prevent erection problems.
            <br/><br/>Numerous studies have scientifically demonstrated that male
            sexuality is directly linked to health in general and, more
            specifically, to the health of the pelvic region.
            <br/><br/>Yoga is an ancient discipline, the basis of which is
            exercise-breathing-balance. The practice of these techniques increases
            the quality of your breathing, which will lead to an increase in blood
            flow upon to the penis, resulting in firmer and longer-lasting
            erections.
            <br/><br/>Yoga will improve the overall sexual health by relieving the
            unnecessary stress from the body, and helping you enjoy greater serenity
            and calm in everyday life.
            <br/><br/>Combating stress is one of the main objectives for solving and
            curing impotence or erectile dysfunction.
        </p>
        <h3>Yoga is a natural treatment for erectile dysfunction</h3>
        <p>
            Yoga exercises help strengthen and exercise the area of ​​the male
            reproductive system.
            <br/><br/>Yoga asanas that involve lumbar and pelvic region will result
            in decongestion and muscle blockages of the nervous system of the area,
            providing an increase and improvement in erections, as well as libido.
            <br/><br/>Practising these asanas also helps to create intervertebral
            space and greater flexibility in the muscles that hold our spine.
            <br/><br/>When you have an agile, flexible and toned muscle structure,
            and there is a continuous course of oxygen through methodical and
            controlled breathing, the body begins to show beneficial results which
            promotes a healthy and active sex life.
        </p>
    </AccordionCard>,
    <AccordionCard key={'3'} title="How does Ayurveda cure Erectile Dysfunction (ED)?">
        <p>
            Among the many natural methods to treat erectile dysfunction, the most
            common and popular method followed in India finds its roots to Ayurveda.
            <br/><br/>Knowing what erectile dysfunction is and the factors that
            cause it, there are a variety of remedies in Ayurveda that can help to
            get rid of the problem permanently.
            <br/><br/>Numerous natural herbs and ingredients are used in the
            preparation of ayurvedic medicines, so the medicines not only aid in
            erection problems but have many other positive effects.
            <br/><br/>These remedies have prevailed for generations and have been
            treating people from this problem. They help improve immunity,
            digestion, reduce stress and make one more energetic and youthful.
            <br/><br/>The causes of erectile dysfunction can be different and are
            usually associated with disorders in different systems of the body.
            <br/><br/>This, in Ayurveda, means that the three Doshas - Vata, Pitta,
            responsible for digestion and metabolism, and Kapha, which control
            fluids, can have disturbed equilibria.
            <br/><br/>In Ayurveda, diet and nutrition play an important role in the
            treatment of diseases. The diet and procedures to cure erectile
            dysfunction with Ayurveda involve getting rid of the toxins and thorough
            cleansing to balance the doshas.
            <br/><br/>Consumption of herbs, spices, and nutritional supplements
            along with oil-based massages, yoga and meditation practices can help in
            treating the condition.
            <br/><br/>Natural foods like garlic, onions, dairy products, shellfish,
            mussels, figs, nuts and honey are recommended. Almond milk is also
            useful, which can be easily prepared at home.
        </p>
        <h3>Ayurvedic massages to treat erectile dysfunction</h3>
        <p>Massages like Shirodhara to relieve stress-related to erectile
            dysfunction, or a Panchakarma detox to balance Vata dosha are
            beneficial. Abhyangam or a full body massage is also considered as one
            of the best aphrodisiacs and can help address the problem of erectile
            dysfunction. Essnetially, a regular massage is known to improve blood
            circulation and hence promote better erections.
            <br/><br/>Along with massages, the following herbs and foods are known
            to boost libido and treat erectile dysfunction as per Ayurveda:
        </p>
        <ul>
            <li>Shatavari</li>
            <li>Ashwagandha</li>
            <li>Safed Musli</li>
            <li>Dalchini</li>
            <li>Gokulakanta</li>
            <li>Jaiphal</li>
            <li>Tulsi seeds</li>
            <li>Shilajeet</li>
            <li>Garlic</li>
            <li>Jwatri</li>
            <li>Kaunch beej</li>
        </ul>
    </AccordionCard>
]

export const accordionList3 = [
    <AccordionCard key={'0'} title="All you want to know about Viagra (Sildenafil citrate)">
        <h3>What is Viagra?</h3>
        <p>Viagra is the trade name for Sildenafil Citrate. Viagra is an oral
            treatment for erectile dysfunction or impotence in men. It helps to
            treat erectile dysfunction by increasing blood flow to the penis. Sexual
            stimulation is necessary for the use and effectiveness of Viagra. It is
            a treatment reserved for men.</p>

        <h3>What are the common brand names?</h3>
        <p>Sildenafil is sold under the generic name Viagra. In the Indian market,
            Sildenafil is sold under various brand names which include Silagra
            (Cipla), Kamagra (Ajanta Pharma), Penegra (Zydus Cadila), Edegra (Sun
            Pharmaceutical), Manly (Cooper Pharma) and Zenegra (Alkem
            Laboratories).</p>

        <h3>Why is Viagra prescribed?</h3>
        <p>This medication is a treatment for adult men with erectile dysfunction,
            sometimes called impotence; that is when a man cannot achieve or
            maintain an erection sufficient for sexual activity.</p>

        <h3>How does Viagra act inside the body?</h3>
        <p>
            Viagra is one of the powerful and selective phosphodiesterase type 5
            (PDE5) inhibitors. This medication is a vasodilator, so it increases
            blood flow to the penis by dilating the blood vessels. It allows the
            penis to swell by the accumulation of blood in the corpora cavernosa
            after the release of nitric oxide (NO) following initial sexual arousal.
            <br/><br/>Nitric oxide leads to an increase in the level of cyclic
            guanosine monophosphate (cGMP) which in turn leads to relaxation of the
            muscles of the corpora cavernosa, which ultimately allows an increase in
            blood flow to the penis.
            <br/><br/>By reducing the breakdown of cGMP, Viagra potentiates the
            effects of nitric oxide by concentrating cGMP in the corpora cavernosa.
            This, in turn, results in an erection and helps you maintain it.
            <br/><br/>Viagra only works if you are sexually aroused. Its effect can
            be up to 4 hours. That is to say; it can facilitate an erection during
            this period; however, you will not have an erection that will last 4
            hours.
        </p>

        <h3>How should Viagra be taken?</h3>
        <p>
            Before using Viagra, it is very important to read the package leaflet in
            the box of the medicine. If you have any questions, contact your doctor
            or pharmacist.
            <br/><br/>To treat erectile dysfunction, take this medication orally as
            directed by your doctor. Viagra must be taken at least 30 minutes before
            sexual activity and not more than 4 hours before.
            <br/><br/>It is most effective when taken 1 hour before intercourse with
            a glass of water. One tablet per day is recommended, ideally.
            <br/><br/>A meal rich in fat can delay the effect of the drug. Hence, it
            is suggested that you take Viagra after a light meal or on an empty
            stomach.
            <br/><br/>Your doctor will prescribe the dosage based on your medical
            condition, and the effect of the drug on your body. It is imperative to
            inform your doctor about any other prescription or over-the-counter
            drugs you are using.
        </p>

        <h3>What are the side effects of Viagra?</h3>
        <p>
            Like most medicines, Viagra may also have side effects in some cases.
            The side effects reported with this medicine are generally mild to
            moderate and short-lived. Headache is one of the most common side
            effects.
            <br/><br/>Other fairly common side effects that can occur are:
        </p>
        <ul>
            <li>Redness in the face</li>
            <li>Digestive problems</li>
            <li>Vision disturbances such as sensitivity to light, disturbed colour
                perception, reduced visual acuity
            </li>
            <li>Dizzying sensations</li>
            <li>Stuffy nose</li>
            <li>Swelling of face, lips or throat</li>
            <li>Sudden wheezing</li>
        </ul>
        <p>
            Viagra, like all medicines, can cause allergic reactions. It can also
            cause prolonged and painful erections. In this case, you must consult
            the doctor immediately.
            <br/><br/>Other serious but rare side effects reported include:
        </p>
        <ul>
            <li>Hypertension or hypotension with fainting</li>
            <li>A cerebral vascular accident</li>
            <li>Hearing loss</li>
            <li>Cardiac arrhythmias</li>
        </ul>

        <h3>What precautions should be considered while using Viagra?</h3>
        <p>
            Before using this medicine, inform your doctor or pharmacist about your
            medical history, in particular of heart problems (such as heart attack
            or irregular heartbeat in the past six months, chest pain, heart
            failure), stroke in the past six months, kidney disease, liver disease,
            high or low blood pressure, significant loss of body water
            (dehydration), history of painful or prolonged erection (priapism).
            <br/><br/>Viagra or its equivalent may cause dizziness or vision
            problems. Alcohol or marijuana (cannabis) can make you dizzier. Do not
            drive, use machinery or do anything that requires alertness or clear
            vision until you can do it safely. Limit your consumption of alcoholic
            beverages.
            <br/><br/>Talk to your doctor, pharmacist or nurse before taking Viagra:
        </p>
        <ul>
            <li>If you have sickle cell anaemia (an abnormality of red blood cells),
                leukaemia (cancer of blood cells), multiple myeloma (bone marrow
                cancer).
            </li>
            <li>If you have a deformity of your penis or Peyronie's disease.</li>
            <li>If you have heart problems. Your doctor should carefully assess
                whether your heart can tolerate the extra effort caused by sexual
                activity.
            </li>
            <li>If you currently have a stomach ulcer or bleeding disorder (such as
                haemophilia).
            </li>
            <li>If you experience sudden decrease or loss of vision, stop taking the
                drug and contact your doctor immediately.
            </li>
            <li>You should not use this medication at the same time as any other
                oral or local treatment for erectile dysfunction.
            </li>
            <li>You should not take Viagra if you do not have erectile
                dysfunction.
            </li>
        </ul>

        <h3>What to do in case of an overdose of Viagra?</h3>
        <p>An overdose of Viagra will not help you with better or long-lasting
            erections. However, you may experience an increase in the number of side
            effects and their severity. Higher doses do not increase the
            effectiveness of the product. Contact your doctor if you take more
            tablets than you should.</p>
    </AccordionCard>,
    <AccordionCard key={'1'} title="All you want to know about Tadalafil">
        <h3>What is Tadalafil?</h3>
        <p>
            Tadalafil is marketed under the name of Cialis. It is also available as
            a generic drug, under the name tadalafil. Cialis is used for the
            treatment of erectile dysfunction.
            <br/><br/>It increases blood flow to the penis following sexual
            stimulation and allows men to keep an erection hard enough to have sex.
        </p>
        <h3>What are the common brand names?</h3>
        <p>Tadalafil is sold under the generic name Cialis. In the Indian market,
            Tadalafil is sold under various brand names which includes Manforce More
            (Mankind), Pulmopres and Tadacip (Cipla), Tadalis (Ajanta Pharma),
            Tazzle (Dr Reddy’s), Zydalis (Zydus Cadila), Megalis (Macleods), Golmal
            (Jpee Drugs) and TD-36 (Shreya).</p>
        <h3>Why is Tadalafil prescribed?</h3>
        <p>
            Tadalafil is used in the treatment of Erectile Dysfunction. It works
            towards the cause of erection, that is, by increasing the blood flow
            towards the penis and helps in maintaining the erection.
            <br/><br/>
            This drug does not offer any treatment or does not protect against
            sexually transmitted diseases, like HIV, syphilis, Hepatitis-B.
        </p>
        <h3>How does Tadalafil act inside the body?</h3>
        <p>
            When there is sexual stimulation and proper circulation of blood, the
            body releases nitric oxide into the cavernous bodies of the penis,
            which, elevates levels of cyclic guanosine monophosphate (cGMP).
            <br/><br/>This mechanism can be slowed down or deteriorated by a bad
            blood flow, resulting from the action of the enzyme phosphodiesterase
            type 5 (PDE5), which develops in the body and degrades the cGMP
            molecule.
            <br/>The active substance in tadalafil allows the blood vessels of the
            penis to dilate and minimize the effect of the enzyme PDE5, thereby
            increasing the influx blood and promoting the appearance of a firm
            erection, and maintained when there is sexual stimulation. This tablet
            has an effect of about 36 hours.
            <br/><br/>This erectile inducer facilitates a natural physiological
            mechanism. The drug does not provide an erection without sexual
            stimulation.
        </p>
        <h3>How should Tadalafil be taken?</h3>
        <p>
            Before any new use, it is important to read the information sheet
            provided by your pharmacist. It is best recommended that you consult
            your doctor or pharmacist, in case you have any doubts.
            <br/><br/>
            The amount of dose depends on your condition, medical history of other
            drugs you are taking and your response to the drug or any allergies.
            <br/><br/>
            To treat erectile dysfunction, tadalafil can be prescribed in two ways.
            Always follow your Doctor’s or pharmacist’s advice on the best way to
            consume the drug. Whatever the dose may be, can be taken with or without
            a meal. Even a large meal has no influence on its effectiveness.
            <br/><br/>
            One of the prescribed ways to take the Tadalafil is 30 minutes prior to
            sexual activity. The effect of tadalafil on sexual ability can last up
            to 36 hours.
            <br/><br/>
            The second prescribed method for treatment of Erectile Dysfunction is to
            take tadalafil regularly, once daily. If you take it this way, you can
            have sexual activity anytime between your doses.
            <br/><br/>
            If your condition does not improve or if it worsens, it is absolutely
            necessary to notify your doctor so that he can determine with you the
            progress of your treatment.
            <br/><br/>
            Tadalafil acts for more than 24 hours and up to 36 hours, which means
            that the substance remains present in the blood and active in the body
            for a prolonged period until its elimination.
        </p>
        <h3>What are the side effects of Tadalafil?</h3>
        <p>
            A few side effects of Cialis, which are commonly found, include
            headache, stomach ache, back pain, muscle pain, nasal congestion, hot
            flashes or dizziness.
            <br/><br/>
            In addition, sexual activity can strain your heart, especially if you
            have heart problems. So, if you have a heart condition and you
            experience any of the above serious side effects during sex, see a
            doctor straightaway.
            <br/><br/>
            One of the other side effects sometimes observed, is a sudden decrease
            or loss of hearing, sometimes accompanied by ringing in the ears and
            dizziness. If this serious problem occurs, stop taking Cialis and seek
            medical advice.
            <br/>In rare cases, you may have a painful or prolonged erection of 4
            hours or more; this particular condition is known as priapism and can
            have a permanent effect and damage the penis. In such cases, seek
            immediate medical attention.
            <br/><br/>
            A very serious allergic reaction to this drug is rare. However, seek
            immediate medical attention if you notice symptoms of a severe allergic
            reaction, including rash, itching or swelling (especially on the face,
            tongue, or throat), severe dizziness, difficulty breathing.
        </p>
        <h3>What precautions to be considered while using Tadalafil?</h3>
        <p>
            Be sure to tell your doctor and pharmacist about all the products you
            use (including prescription drugs, over-the-counter drugs, and herbal
            products) and any particular medical history, of heart attack or
            irregular heartbeat in the past 6 months, chest pain, heart failure,
            stroke in the past 6 months, high or low blood pressure.
            <br/><br/>
            A sudden decrease in vision is rarely seen. You may have a slightly
            higher risk of developing this syndrome if you have heart disease,
            diabetes, high cholesterol, high blood pressure, if you are over 50, or
            if you smoke.
            <br/><br/>
            It is not recommended to eat grapefruit or grapefruit products while
            taking Cialis. Consumption of alcohol after taking Cialis, can lower
            blood pressure and decrease sexual performance.
            <br/><br/>
            <br/>Alcohol or marijuana (cannabis) can make you dizzy when consumed
            with this medicine. Do not drive, use machinery, or do anything that
            requires mental alertness.
            <br/>Talk to your doctor, pharmacist or nurse before taking Tadalafil.
            <br/><br/>You should avoid taking tadalafil
        </p>
        <ul>
            <li>If you have penile deformation such as angulation, fibrosis/scars,
                Peyronie's disease
            </li>
            <li>If you have a history of a painful or prolonged erection (priapism)
                or a condition that may increase the risk of priapism such as sickle
                cell anaemia (a defect of red blood cells), leukaemia (cancer of
                blood cells), multiple myeloma (bone marrow cancer)
            </li>
            <li>If you have eye problems such as retinitis pigmentosa (loss of cells
                in the retina), or a sudden drop in vision.
            </li>
            <li>If you currently have active gastric ulcers or a bleeding disorder
                (such as haemophilia).
            </li>
            <li>If you have kidney disease, liver disease or significant loss of
                body water (dehydration).
            </li>
        </ul>
        <p>
            Tadalafil sold as brand Cialis contains lactose. Its use is not
            recommended in patients with intolerance to lactose. As an added
            precaution, do not flush medicines down the toilet or pour them down a
            drain unless instructed to do so.
            <br/><br/>
            Dispose of this product properly when it has expired or is no longer
            needed. Keep it in a safe place and out of the reach of children.
        </p>
        <h3>What to do in case of an overdose of Tadalafil?</h3>
        <p>An overdose of Tadalafil will increase the severity of the side effects.
            It is not extremely harmful. However, if your erection lasts for almost
            4 hours or more and is painful, get in touch with your doctor
            immediately.</p>
    </AccordionCard>,
    <AccordionCard key={'2'} title="FAQs on Arginine">
        <h3>What is Arginine?</h3>
        <p>
            Arginine is an amino acid, which plays a vital role in cell division,
            healing process of wounds, and elimination of ammonia by the body.
            <br/><br/>From Arginine, the body makes nitric oxide, a substance that
            helps dilate blood vessels and hence, facilitates the flow of blood
            necessary for the erection of the penis. This plays a major role in the
            fight against erectile dysfunction.
        </p>
        <h3>What are the common brand names for Arginine?</h3>
        <p>
            Arginine is available and sold as a medicine and supplement under
            various brand names which includes Argipreg (Mankind), Argiprime (Sun
            Pharma), Arnine (Glenmark), Celemin Hepa (Claris), Complete-PD (Dabur),
            Conape (Lupin), Enduranz (Elder), Santevini Ace (Novartis Pharma) and
            Misters.
        </p>
        <h3>Why is Arginine prescribed?</h3>
        <p>Arginine is prescribed to help prevent erectile dysfunction or the
            symptoms causing it. Arginine is a natural product and is considered as
            partly essential since the body synthesizes sufficient volumes to fulfil
            its needs.
            <br/><br/>
            But it can happen, under certain conditions, following major trauma,
            major surgery or any serious infection, that the endogenous intake is
            not ample and, hence, necessary supplements and drugs containing
            Arginine are prescribed by a doctor.
        </p>
        <h3>How does Arginine act inside the body?</h3>
        <p>
            Arginine is an amino acid that acts on our body, as a precursor to
            nitric oxide. A definite intake of a few amino acids has shown a
            positive impact on the prevention of erectile dysfunction and its
            symptoms.
            <br/><br/>
            Nitric oxide levels increase in people who take a lot of Arginine. The
            action of nitric oxide during sexual stimulation is to dilate the
            vascular walls and improve blood circulation through corpora cavernosa
            of the penis.
            <br/><br/>
            The increased levels of nitric oxide, by Arginine, makes the arteries
            more elastic, which lowers the blood pressure and improves erection of
            the penis.
            <br/><br/>
            As a further advantage, vital nutrients and oxygen are transported at a
            much faster rate through the blood, which has an added effect on
            erection strength and sexual performance.
        </p>
        <h3>How should Arginine be taken?</h3>
        <p>
            Arginine or L-arginine is available in the market in the form of
            tablets, powder, oral solution or capsule and can be administered in the
            way it is easier to use.
            <br/><br/>
            The compound is also sold pure or mixed with some other known active
            ingredients like multi-vitamins and health supplements, depending on the
            type of treatment to be performed.
            <br/><br/>
            This amino acid is also found in many food sources such as legumes,
            certain grains, such as brown rice, and oats as well as red meat,
            chicken, fish, dairy products, and nuts.
        </p>
        <h3>What are the side effects of Arginine?</h3>
        <p>
            Being a natural product, Arginine has very rare and mild side-effects,
            like gastrointestinal discomfort, headache, diarrhoea.
            <br/><br/>
            You should tell your doctor or pharmacist if you are taking other
            medications or health supplements, as Arginine is itself a food
            supplement and other supplements may increase or decrease its action.
        </p>
        <h3>What precautions to be considered while using Arginine?</h3>
        <p>
            Its use is prohibited in case you have had a history of myocardial
            infarction (heart attack) as it may result in death. Consult your doctor
            or pharmacist before you consume Arginine, if you have or in the past
            had any:
        </p>
        <ul>
            <li>Allergies</li>
            <li>Asthma problems</li>
            <li>Kidney failure</li>
            <li>Liver disease or failure of the liver.</li>
        </ul>
        <h3>What to do in case of an overdose of Arginine?</h3>
        <p>While Arginine is safe for consumption in moderate doses, an excess of it
            can be harmful or prove fatal in a few cases. It is necessary to speak
            to your doctor regarding its dosage and possible interactions with
            medications or drugs that you may be using. </p>
    </AccordionCard>,
    <AccordionCard key={'3'} title="All you want to know about Omega-3 supplements">
        <h3>What is Omega-3?</h3>
        <p>
            Omega-3 are essential polyunsaturated fatty acids, which are found in
            varieties of food products like fish and flaxseeds. Fatty acids are the
            main constituents of fats or lipids and
            <br/><br/>Omega-3, in general, has many beneficial properties. It helps
            in the reduction of bad cholesterol levels in the blood, promotes proper
            functioning of the brain, reduces hypertension, which are all symptoms
            of erectile dysfunction or contributes towards it.
        </p>

        <h3>What are the common brand names for Omega-3?</h3>
        <p>Omega-3 is not available as a special medicine and is sold in the form of
            supplements with multi-vitamin brands by renowned companies like
            Veegy-4G (Reckitt and Benckiser), Omacor-O and Prosure (Abbott), Omega
            red (Alembic), Thrive-CV (Elder), Harty (Lupin), Evion-Omega (Merck) and
            Misters. </p>

        <h3>Why is Omega-3 prescribed?</h3>
        <p>
            Omega-3 are basically a family of essential fatty acids and are
            necessary for the proper functioning of our body.
            <br/><br/>
            The term "omega-3" includes primarily three polyunsaturated fatty acids
            out of several in the family, which are - Alpha-linolenic acid (ALA),
            Eicosapentaenoic Acid (EPA), Docosahexaenoic Acid (DHA).
            <br/><br/>
            However, ALA, which is a precursor to the other omega-3 fatty acids,
            cannot be synthesized by our body, and from ALA our body synthesizes EPA
            and DHA. Thus, it is strongly recommended to consume foods and
            supplements containing these Omega-3 fatty acids directly.
            <br/><br/>
            Omega-3 carries multitudes of health benefits, especially
            anti-inflammatory properties. These fatty acids contribute to good
            cardiovascular functioning by decreasing the levels of bad cholesterol,
            improving blood flow, and curtail hypertension. These are also essential
            for the proper functioning of our brain.
            <br/><br/>
            All of these benefits help in preventing erectile dysfunction and
            symptoms. Hence, Omega-3 is often prescribed to those suffering from
            erectile dysfunction.
        </p>

        <h3>How does Omega-3 act inside the body?</h3>
        <p>
            Short chains of Omega-3 fatty acids known as ALA are converted to
            long-chain EPA and DHA Omega-3 fatty acids by the enzymatic action in
            our body. The rate of conversion depends on gender, age and dietary
            habits.
            <br/><br/>
            The absorption of these fatty acids helps regulate cholesterol levels to
            normal. The action also keeps the blood pressure levels as well as the
            heart rate in check, which is essential to prevent erectile dysfunction
            and its symptoms.
        </p>

        <h3>How should Omega-3 be taken?</h3>
        <p>
            Apart from supplements, sources of plant origin and sources of animal
            origin are the two types of omega-3 fatty acids sources. Fish is not the
            only sources to contain omega-3. Dairy products, eggs, and poultry meat
            also contain it.
            <br/><br/>
            Consumption of foods from both the sources is considered essential,
            since omega-3 fatty acids of plant origin are of the ALA form only, and
            do not carry all the virtues of EPA and DHA.
            <br/><br/>
            Hence, for the other part, Omega-3 of animal origin, which mainly
            contain EPA and DHA and small amounts of ALA, are advised to be included
            in the diet.
            <br/><br/>
            However, for vegans, fatty acids from seaweed and algae are the only
            source of long-chain Omega-3. These are only found in the form of
            supplements.
            <br/><br/>
            Before the consumption of any sorts of supplements or artificial forms
            of Omega-3 fatty acids, it is always advisable to consult your doctor or
            pharmacist.
        </p>

        <h3>What are the side effects of Omega-3?</h3>
        <p>
            Apart from the taste, which many find unpleasant, and fishy hints, side
            effects are rare at the dosages usually recommended and generally
            limited to mild gastrointestinal disorders.
            <br/><br/>To reduce fish hints, take supplements at the start of the
            meal or get enteric-coated supplements.
            <br/><br/>In large doses, fish oil can cause nausea, softened stools and
            nosebleed.
        </p>

        <h3>What are the precautions to be considered while using Omega-3?</h3>
        <p>
            Even though Omega-3 is an essential nutrient, however, consultation from
            a doctor or a pharmacist is still necessary especially if you are
            consuming it for treatment of heart-related ailments.
            <br/><br/>Supplements available over-the-counter require intervention
            and proper advisory of a health professional.
            <br/><br/>Fish liver oils, also known as Cod or halibut liver oil, are
            not recommended as a source of Omega-3 since they have a high content of
            vitamin A, and if taken regularly may cause toxicity of vitamin A.
            <br/><br/>Before taking the supplement, it is always best to consult a
            doctor and check on the labels for the levels of vitamin A.
            <br/><br/>High doses of fish oil should be avoided if you are already
            consuming blood thinners and, otherwise, susceptible to bruising or have
            any related blood problems. Also, if haemophilia runs in your family or
            you already have acquired it, then its best to consult your doctor or
            pharmacist before taking fish oil supplements.
        </p>

        <h3>What to do in case of an overdose of Omega-3?</h3>
        <p>
            An increasing number of food products available on the shelf, such as
            eggs, bread, butter, other oils and fruit juices are enriched with
            Omega-3 fatty acids.
            <br/><br/>Added fortification of foods with fish oil supplements
            increases the risk of elevated levels of Omega-3.
            <br/><br/>Research and studies show elevated risks of advanced prostate
            cancer and atrial fibrillation (irregular heartbeat leading to a blood
            clot and heart problems) with high levels of omega-3.
            <br/><br/>Excess amounts of Omega-3 levels can also lead to impairment
            of immune function, resulting in the dysfunctional immune response to a
            viral or bacterial infection.
            <br/><br/>Fortification of foods coupled with fish oil supplements more
            or less increases the risk of achieving these high levels.
        </p>
    </AccordionCard>,
    <AccordionCard key={'4'} title="FAQs on multi-vitamin supplements">
        <h3>What are multi-vitamin supplements?</h3>
        <p>
            Multi-vitamins are supplements that include a variety of vitamins,
            minerals, and other vital nutrients that are required to maintain
            optimal health.
            <br/><br/>Since multi-vitamins are not considered drugs, their side
            effects are not so severe.
            <br/><br/>A good quality multivitamin can help maintain overall
            wellbeing, reduce the likelihood of certain illnesses and in all other
            ways, improve sexual health too by preventing the symptoms, in
            particular, those causing erectile dysfunction.
        </p>
        <h3>What are the common brand names?</h3>
        <p>NutriLife Multivitamin For Men, GNC Mega Men, Vitamin D3 by Life
            Extension and Misters are some of the common multivitamins available in
            India to address erectile dysfunction.</p>

        <h3>Why are multi-vitamins prescribed?</h3>
        <p>
            Vitamins and minerals are important for the daily functioning of the
            body. These are prescribed or advised by a health professional only when
            your diet is deficient in them, or you are facing health problems.
            <br/><br/>
            Multi-vitamins are not like regular drugs and have no therapeutic value.
            <br/><br/>
            Particularly for erectile dysfunction among young and older men,
            multi-vitamins rich in Group B vitamins, vitamin C, Zinc, Selenium,
            Omega-3, L-arginine, Vitamin D, and Vitamin K are prescribed.
            Zinc helps in regulating the testosterone levels in the body. Similarly,
            Vitamin D and B3 are known to treat symptoms of erectile dysfunction.
            Vitamin D supplements are also called natural Viagra.
        </p>

        <h3>How do multi-vitamins act inside the body?</h3>
        <p>
            Multivitamins, like most natural alternatives, treat the root cause of a
            disease. Some vitamins help boost the hormone levels in the body while
            others can increase the nitric oxide levels for better blood flow.
            <br/><br/>L-arginine, for instance, can help in relaxing the blood
            vessels to promote better erection.
            <br/><br/>There are endless combinations of essential nutrients that
            combine to create a wide variety of supplements.
            <br/><br/>Your age, gender, state of health and level of activity will
            determine the specific nutritional needs which will be supplemented by
            the most appropriate multivitamin.
            <br/><br/>If you are a very active person, your needs for vitamin B,
            vitamin D, antioxidants, iron and magnesium may increase, and a
            supplement may be possible according to the recommendations of a health
            professional.
        </p>

        <h3>How should multi-vitamins be taken?</h3>
        <p>
            If you decide to take a supplement, then take the time to choose your
            product carefully. It is always best recommended to take the advice of
            any health professional, since just consuming a multivitamin may not
            just mean that it will provide all the benefits hoped for.
            <br/><br/>
            Although multivitamin supplements are very popular, many people do not
            know how to use these products to get the most out of them.
            <br/><br/>
            If you want to supplement yourself effectively and use your multivitamin
            intelligently, following few points may be of some help:
        </p>
        <ul>
            <li>The time you should take your multivitamins: Certain nutrients, like
                vitamin C and vitamins of group B (except B3) and vitamin D, have an
                energizing effect and must be taken in the morning. Whereas vitamin
                B3 and magnesium have relaxing effects and should be taken in the
                evening.
            </li>
            <li>How to consume multivitamins: The water-soluble vitamins (groups B
                and C) are dissolved in water and can be absorbed effectively with
                or without food. However, the fat-soluble vitamins (A, E and K) need
                fat to be absorbed and must, therefore, be consumed during a meal.
            </li>
            <li>Dosage of multivitamin supplement - Studies advise choosing a
                supplement that provides 100% of the recommended daily intake of the
                selected nutrients. Supplements with a lower RDA may be ineffective
                while exceeding this setting can lead to dangerous overdoses of
                vitamins and minerals.
            </li>
        </ul>
        <p>
            Based on your individual habits, more specific recommendations should be
            considered. If you want to increase the absorption of iron, for example,
            take your supplement with orange juice.
            <br/><br/>Avoid coffee as it decreases the absorption of most nutrients;
            take it within an hour or two of your multivitamins.
        </p>

        <h3>What are the side effects of multivitamins?</h3>
        <p>
            Though vitamins are not like regular drugs, but are still microscopic
            chemical substances and may have many side-effects based on the
            composition.
            <br/><br/>
            Excessive intake of a calcium-rich multivitamin can cause constipation,
            change the heart rate, increase the likelihood of kidney stones, and
            build up in the artery walls (increasing the risk of cardiovascular
            events)
            <br/><br/>
            Similarly, iron, which is a very popular addition to many multivitamins,
            should not be consumed in excess. Its abuse can accelerate ageing and
            harm the health of the whole body.
            <br/><br/>
            Vitamin D increases the absorption of calcium; hence, studies show that
            its excessive consumption could promote the calcification of blood
            vessels, deteriorating the state of the main arteries.
            <br/><br/>
            It can further result in decreased blood flow, which is the prime cause
            of erectile dysfunction. Combining it with vitamin K can help oppose the
            deposition of arterial calcium.
        </p>

        <h3>What precautions to be considered while using multi-vitamins?</h3>
        <p>
            If you are taking an iron supplement, do not take it with dairy or
            calcium-rich products. These two minerals interact with each other,
            making absorption of the minerals difficult. Choose supplements that do
            not combine these molecules.
            <br/><br/>
            Always choose the active forms of the nutrients, by looking for
            supplements that are labelled as "active forms" of the vitamins. Such
            multivitamins are effective immediately once absorbed, and your body
            will not need to metabolize it by chemical processes that could degrade
            the nutrients ingested.
            <br/><br/>
            Vitamin E is a complex of substances since it is formed by a group of
            molecules called tocopherols. To get most of the benefit from the
            protective effect of this compound, it is best to select the type of
            supplement which includes all types of tocopherols (alpha, beta, delta
            and gamma).
        </p>

        <h3>What to do in case of an overdose of multi-vitamins?</h3>
        <p>Nausea, diarrhoea and vomiting are some of the common side effects of an
            overdose. Gastric trouble and stomach cramps may be observed in a few
            cases. It is, thus, essential to take only a regulated amount of
            multivitamins even though they are not drugs.</p>
    </AccordionCard>,
    <AccordionCard key={'5'} title="All about Ayurvedic medicines for impotence">
        <h3>What are Ayurveda medicines?</h3>
        <p>
            One of the oldest holistic treatments, Ayurveda products have been used
            in India since centuries to address physical and mental well-being
            issues. These products work towards creating a balance between the mind
            and body.
            <br/><br/>The products are a combination of natural herbs that aim to
            energise the body and mind while eliminating the root causes of various
            diseases and conditions.
        </p>

        <h3>Which ayurvedic medicines are used in impotence?</h3>
        <p>
            Ayurveda medicines or products that help in erectile dysfunction include
            Ashwagandha, Safed Musli, Kauncha seeds, Shilajeet and many other herbs.
            <br/><br/>Few of the commonly available brands are Bio India Ginseng
            Tablets, Kotakkal H.T.Kot Tablet, Dhootapapeshwar Vasant Kusumakar Ras
            and Shilajit Gold.
        </p>

        <h3>Why are Ayurveda products prescribed?</h3>
        <p>
            Ayurveda products are prescribed to treat the root cause of erectile
            dysfunction. Some of them address physical problems like hypertension,
            diabetes or prostate gland issues while others relieve stress and boost
            sexual desire.
            <br/><br/>For instance, natural herbs like Kauncha seeds helps cure
            hypertension, which is one of the leading causes of erectile
            dysfunction.
            <br/><br/>These seeds have proven to be a great substitute for
            hypertension, by reducing any amount of anxiety, depression or even
            stress.
        </p>

        <h3>How do Ayurveda products act inside the body?</h3>
        <p>
            Certain herbs increase the level of nitric oxide in the blood and help
            stimulate the blood flow in the penis.
            <br/><br/>This makes sure that you have harder erections and have a
            Viagra-like experience, proving as a great cure for erectile
            dysfunction.
            <br/><br/>Products like Jaiphal (nutmeg) helps in stimulating the brain
            for erection, right where the process in the body starts. Shilajit is
            actually a mineral which is collected from the Himalayan rocks and is
            included actively in many medicines. It widely acknowledged to be
            effective in preventing impotency caused by ageing and weakness.
            Ayurveda herbs also work by strengthening the muscles around the penis
            and helps in maintaining an erection.
        </p>

        <h3>How should Ayurveda medication be taken?</h3>
        <p>Ayurveda medicines or products are usually prescribed as supplements to
            address erectile dysfunction issues.
            <br/><br/>These can be taken as per the instructions mentioned on the
            medication. The supplements are usually consumed with milk or honey.
        </p>

        <h3>What are the side effects of Ayurveda products?</h3>
        <p>
            As compared to other drugs, Ayurveda products do not have harsh side
            effects. However, long-term use of these herbs must be done under proper
            guidance.
            <br/><br/>Few studies indicate heavy metal poisoning and some toxic
            effects of the products. This, however, depends on the brand of products
            and the additional ingredients that are a part of Ayurveda supplements.
        </p>

        <h3>What precautions to be considered while using Ayurveda products?</h3>
        <p>
            A few dietary precautions may be required while using Ayurveda products
            to prevent any unwanted interactions with the ingredients.
            <br/><br/>
            Following a healthy and balanced diet ensures that you get the maximum
            benefits from these herbs and supplements. You will also need to discuss
            with your doctor if you are on allopathy medication.
        </p>

        <h3>What to do in case of an overdose of Ayurveda products?</h3>
        <p>
            There are no known cases of any harmful effects due to an overdose of
            Ayurveda products. However, these must be consumed in the prescribed
            amounts to prevent any discomfort
        </p>
    </AccordionCard>
]
