import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../../components/core/Page'
import CrossFadeImage from '../../../components/others/CrossFadeImage'
import Button from '../../../components/buttons/Button'
import { fetchCourses, purchaseLibrary } from '../../../actions/library/courses'
import { getHttpsImageUrl } from '../../../utils'

class Course extends Component {
    static propTypes = {
      purchaseLibrary: PropTypes.func,
      fetchCourses: PropTypes.func
    }

    componentDidMount () {
      this.props.fetchCourses()
    }

    getCourse = () => {
      const { match, courses } = this.props
      const courseId = match.params.courseId

      const filteredCourses = courses.list.filter(course => course.id == courseId)
      if (filteredCourses.length > 0) return filteredCourses[0]
      return null
    };

    render () {
      const { match, user } = this.props
      const course = this.getCourse()

      if (!course) {
        return null
      }

      return (
            <Page meta={{
              path: `${match.params.language}/library/courses/${match.params.courseId}`
            }}>
                <div className="course-page-section1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 py-5">
                                <CrossFadeImage
                                    src={getHttpsImageUrl(course.featured_image)}
                                    alt={course.title}
                                    duration={700}
                                    timingFunction={'ease-out'}
                                />
                            </div>
                            <div
                                className="col-12 col-md-6 d-flex flex-column justify-content-center py-5 text-center text-md-left">
                                <h2 className="library-page-subtitle text-white">
                                    {course.title}
                                </h2>
                                <p className="library-page-description text-white">
                                    {course.meta_description}
                                </p>
                                <div>
                                    <h3 className="text-white">Chapters</h3>
                                    {
                                        course.chapters.map(
                                          (chapter, index) => (
                                                <div className="chapter-container">
                                                    <h5 className="chapter-title">{chapter.title}</h5>
                                                    <p className="chapter-description">{chapter.meta_description}</p>
                                                    {
                                                        (user.is_premium_library_member || !chapter.is_premium)
                                                          ? <audio className="chapter-audio" controls controlsList="nodownload">
                                                                <source src={getHttpsImageUrl(chapter.file)} />
                                                            </audio>
                                                          : <button className="btn-m chapter-premium-button" onClick={() => this.props.history.push(`${match.params.language}/library/purchase`)}>
                                                                Get premium & unlock full library
                                                            </button>
                                                    }
                                                </div>
                                          )
                                        )
                                    }
                                    {/* <Button variant={'secondary'} style={{width: 250}}> */}
                                    {/*    Get Premium */}
                                    {/* </Button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = (state) => ({
  courses: state.library.courses,
  user: state.accounts.profile.detail
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchCourses,
  purchaseLibrary
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Course)
