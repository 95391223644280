// External Imports
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
// My Imports
import { fetchPost } from '../../../actions/community/posts'
import Page from '../../../components/core/Page'
import CommunityPostCommentCard from '../../../components/cards/CommunityPostCommentCard'
import { Images, PHONE_NUMBER } from '../../../constants'

class Post extends React.Component {
  static fetching ({ dispatch, path, routePath, match }) {
    const { slug } = match.params
    return [dispatch(fetchPost({ slug }))]
  };

  componentDidMount () {
    const { slug } = this.props.match.params
    this.props.fetchPost({ slug })
  }

  render () {
    const { posts: { detail }, match } = this.props

    return (
            <Page meta={{
              title: 'Misters.in Doctor\'s Community',
              path: `${match.params.language}/community/${match.params.slug}`,
              description: 'Get your answers to all of your questions from the misters community'
            }}>
                <div className="bg-light py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12 pb-5">
                                {
                                    !!detail.post &&
                                    <div>
                                        <div className="community-post-card">
                                            <h4 className="community-post-title">{detail.post.title}</h4>
                                            {detail.post.tags.map(
                                              tag => <NavLink key={tag.id}
                                                                className="community-post-tag" to="#">
                                                    {tag.title}
                                                </NavLink>
                                            )}
                                        </div>
                                        {
                                            (detail.results || []).map(
                                              comment => {
                                                return (
                                                        <CommunityPostCommentCard key={comment.id} comment={comment}
                                                                                  post={detail.post}/>
                                                )
                                              }
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row py-5">
                        <div
                            className="col-12 col-md-6 order-md-1 order-2 d-flex flex-column justify-content-center text-center text-md-left">
                            <h2 className="home-page-subtitle">
                                Schedule a free consultation with an ayurvedic doctor.
                            </h2>
                            <p className="home-page-description">
                                Call us at <strong>{PHONE_NUMBER}</strong> for a free Ayurveda Consultation
                            </p>
                            <div>
                                <a href={`tel:${PHONE_NUMBER}`} className="btn-m btn-m-red mb-3"
                                   style={{ width: 250 }}>
                                    Call Now
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 order-md-2 order-1">
                            <img data-src={Images.home8}
                                 className="lazyload section-image" alt="A man controlling his car"/>
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

const mapStateToProps = state => ({
  posts: state.community.posts
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchPost
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Post)
