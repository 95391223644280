import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'

const CartQuantityInput = function ({ className, quantity, onIncreaseClick, onDecreaseClick, minValue, maxValue, showPlaceholder = false, ...props }) {
  return (
        <div className={`cart-quantity-input ${className}`}>
            {showPlaceholder && <span className="cart-quantity-placeholder">Quantity</span>}
            <button className={'cart-quantity-input-button'}
                    onClick={onDecreaseClick} {...props} disabled={quantity <= minValue}>
                {
                    quantity === 1 ? <FontAwesomeIcon icon={faTrash} size="1x"/> : '-'
                }
            </button>
            <div className='cart-quantity-title'>{quantity}</div>
            <button className={`cart-quantity-input-button ${className}`}
                    onClick={onIncreaseClick} {...props} disabled={quantity >= maxValue}>+
            </button>
        </div>
  )
}

CartQuantityInput.propTypes = {
  className: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number
}

CartQuantityInput.defaultProps = {
  className: '',
  minValue: 1,
  maxValue: 10
}

export default CartQuantityInput
