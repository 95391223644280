// External Imports
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
// My Imports
import { fetchPosts } from '../../../actions/community/posts'
import Page from '../../../components/core/Page'
import { Images, PHONE_NUMBER } from '../../../constants'
import CommunityPostCommentCard from '../../../components/cards/CommunityPostCommentCard'

class Posts extends React.Component {
  static fetching ({ dispatch, path, routePath, match }) {
    return [dispatch(fetchPosts())]
  };

  componentDidMount () {
    this.props.fetchPosts()
  }

  render () {
    const { posts, match } = this.props

    return (
            <Page meta={{
              title: 'Misters.in Doctor\'s Community',
              path: `${match.params.language}/community/`,
              description: 'Get your answers to all of your questions from the misters community'
            }}>
                <div className="bg-light">
                    <div className="page-header-img"
                         style={{ backgroundImage: `url('${Images.community}')` }}>
                        <div className="page-header-bg-drop"/>
                        <div className="page-header-content">
                            <h1>Misters.in doctor's community</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {
                                (posts.list.results || []).map(
                                  post => {
                                    const first_comment = post.first_comment
                                    return (
                                            <div key={post.id}
                                                 className="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12 py-2">
                                                <div className="community-post">
                                                    <NavLink to={`${match.params.language}/community/${post.slug}`}>
                                                        <h4 className="community-post-title">{post.title}</h4>
                                                    </NavLink>
                                                    {
                                                        !!first_comment &&
                                                        <CommunityPostCommentCard key={first_comment.id}
                                                                                  comment={first_comment}
                                                                                  variant={'secondary'}
                                                                                  post={post}/>
                                                    }
                                                </div>
                                            </div>
                                    )
                                  }
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row py-5">
                        <div
                            className="col-12 col-md-6 order-md-1 order-2 d-flex flex-column justify-content-center text-center text-md-left">
                            <h2 className="home-page-subtitle">
                                Schedule a free consultation with an ayurvedic doctor.
                            </h2>
                            <p className="home-page-description">
                                Call us at <strong>{PHONE_NUMBER}</strong> for a free Ayurveda Consultation
                            </p>
                            <div>
                                <a href={`tel:${PHONE_NUMBER}`} className="btn-m btn-m-red mb-3"
                                   style={{ width: 250 }}>
                                    Call Now
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 order-md-2 order-1">
                            <img data-src={Images.home8}
                                 className="lazyload section-image" alt="A man controlling his car"/>
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

const mapStateToProps = state => ({
  posts: state.community.posts
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchPosts
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Posts)
