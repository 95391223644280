import {
  FETCH_ADDRESS,
  FETCH_ADDRESS_RESULT,
  FETCH_ADDRESS_ERROR,
  FETCH_ADDRESS_RETRY,

  CREATE_ADDRESS,
  CREATE_ADDRESS_RESULT,
  CREATE_ADDRESS_ERROR,
  CREATE_ADDRESS_RETRY,

  UPDATE_ADDRESS,
  UPDATE_ADDRESS_RESULT,
  UPDATE_ADDRESS_ERROR,
  UPDATE_ADDRESS_RETRY,

  DELETE_ADDRESS,
  DELETE_ADDRESS_ERROR,
  DELETE_ADDRESS_RESULT,
  DELETE_ADDRESS_RETRY
} from '../../actions/ecommerce/address'

const initialState = {
  list: [],
  detail: {},
  createdData: {},
  isFetching: false,
  isCreating: false,
  isDeleting: false,
  isUpdating: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  // console.warn(action, state);
  switch (action.type) {
    case FETCH_ADDRESS:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_ADDRESS_RESULT:
      return { ...state, isFetching: false, list: action.data }
    case FETCH_ADDRESS_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_ADDRESS_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case CREATE_ADDRESS:
      return { ...state, isCreating: true, error: false, message: null, retry: false }
    case CREATE_ADDRESS_RESULT:
      return { ...state, isCreating: false, detail: action.data, createdData: action.data }
    case CREATE_ADDRESS_ERROR:
      return { ...state, isCreating: false, error: true, message: action.message }
    case CREATE_ADDRESS_RETRY:
      return { ...state, isCreating: false, retry: true, message: action.message }

    case UPDATE_ADDRESS:
      return { ...state, isUpdating: true, error: false, message: null, retry: false }
    case UPDATE_ADDRESS_RESULT:
      return { ...state, isUpdating: false, detail: action.data }
    case UPDATE_ADDRESS_ERROR:
      return { ...state, isUpdating: false, error: true, message: action.message }
    case UPDATE_ADDRESS_RETRY:
      return { ...state, isUpdating: false, retry: true, message: action.message }

    case DELETE_ADDRESS:
      return { ...state, isDeleting: true, error: false, message: null, retry: false }
    case DELETE_ADDRESS_RESULT:
      return { ...state, isDeleting: false }
    case DELETE_ADDRESS_ERROR:
      return { ...state, isDeleting: false, error: true, message: action.message }
    case DELETE_ADDRESS_RETRY:
      return { ...state, isDeleting: false, retry: true, message: action.message }
    default:
      return { ...state }
  }
}

export default reducer
