// eslint-disable-next-line no-unused-vars
/* global require */
import { DEBUG, HOST, languageOptions, WEBSITE_HOST } from './constants'
import * as htmlToText from 'html-to-text'
import * as queryString from 'query-string'
import moment from 'moment'
import { languageUrls } from './languages'

export const jsonToForm = (data) => {
  const form = new FormData()
  const keys = Object.keys(data)
  const values = Object.values(data)

  // eslint-disable-next-line array-callback-return
  keys.map((key, i) => {
    form.append(key, values[i])
  })
  return form
}

export const addFileToForm = ({ form, key, file }) => {
  form.append(key, file, file.name)
  return form
}

export const getMatchParams = ({ path, routePath }) => {
  const { query } = queryString.parseUrl(path)
  path = path.split('?')[0]
  const params = { query }
  // console.warn(path, routePath, query);

  if (path.length && routePath.length) {
    if (path[0] === '/') {
      path = path.slice(1)
    }
    if (path[path.length - 1] === '/') {
      path = path.slice(0, path.length - 1)
    }

    if (routePath[0] === '/') {
      routePath = routePath.slice(1)
    }
    if (routePath[routePath.length - 1] === '/') {
      routePath = routePath.slice(0, routePath.length - 1)
    }

    // console.warn(path, routePath);
    const pathList = path.split('/')
    const routePathList = routePath.split('/')

    console.warn(pathList, routePathList)

    routePathList.map(
      // eslint-disable-next-line array-callback-return
      (obj, index) => {
        if (obj !== pathList[index] && obj[0] === ':') {
          params[obj.slice(1)] = pathList[index]
        }
      }
    )
  }
  return params
}

export const getInnerText = (text) => {
  return htmlToText.fromString(text, { wordwrap: 30 })
}

export const getTextFromHtml = (text) => {
  if (text) {
    text = text.replace(/<(?:.|\s)*?>/g, '')
    return htmlToText.fromString(text, { wordwrap: 30 })
  }
}

export const getHttpsImageUrl = (url = '') => {
  if (!url) {
    url = ''
  }
  if (url.startsWith('https')) {
    return url
  } else if (url.startsWith('http')) {
    if (DEBUG === 'true') {
      return url.replace('http', 'http')
    } else {
      return url.replace('http', 'https')
    }
  } else {
    return HOST + url
  }
}

export const getHttpsWebsiteUrl = (url = '') => {
  if (!url) {
    url = ''
  }
  if (url.startsWith('https')) {
    return url
  } else if (url.startsWith('http')) {
    if (DEBUG === 'true') {
      return url.replace('http', 'http')
    } else {
      return url.replace('http', 'https')
    }
  } else {
    return WEBSITE_HOST + url
  }
}

export const truncChars = (text, length) => {
  if (!text) text = ''
  if (text.length > length) {
    text = `${text.slice(0, length - 3)}...`
  }
  return text
}

export const getStorageItem = (key, defaultValue = null) => {
  try {
    return localStorage.getItem(key) || defaultValue
  } catch (e) {
    return defaultValue
  }
}

export const setStorageItem = (key, value) => {
  try {
    return localStorage.setItem(key, value)
  } catch (e) {
    return null
  }
}

export const removeStorageItem = (key) => {
  try {
    return localStorage.removeItem(key)
  } catch (e) {
    return null
  }
}

export const getPhoneNumberWithoutCountryCode = (number) => {
  if (!number) number = ''
  if (number.startsWith('+91')) {
    return number.slice(3, number.length)
  }
  return number
}

export const getPhoneNumberWithCountryCode = (number) => {
  if (!number) number = ''
  if (number.startsWith('+91')) {
    return number
  }
  return `+91${number}`
}

export const removeLinks = (text) => {
  return text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')
}

export const changeTwitterLinksToCards = async (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  const urlList = []
  text.replace(urlRegex, async (url) => {
    if (url.startsWith('https://twitter.com/')) {
      urlList.push(url)
    }
    return url
  })
  for (let i = 0; i < urlList.length; i++) {
    const href = urlList[i]
    const res = await fetch(`https://publish.twitter.com/oembed?url=${href}`)
    const result = await res.json()
    text.replace(href, result.html)
  }
  return text
}

export const scrollToTop = (scrollDuration) => {
  try {
    const scrollHeight = window.scrollY
    const scrollStep = Math.PI / (scrollDuration / 15)
    const cosParameter = scrollHeight / 2
    let scrollCount = 0
    let scrollMargin
    const scrollInterval = setInterval(function () {
      if (window.scrollY !== 0) {
        scrollCount = scrollCount + 1
        scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep)
        window.scrollTo(0, (scrollHeight - scrollMargin))
      } else clearInterval(scrollInterval)
    }, 15)
  } catch (e) {

  }
}

export const getPrice = (price) => parseFloat(price) / 100

export const getDiscount = (price, salePrice) => parseInt(salePrice ? ((price - salePrice) / price) * 100 : 0)
export const getDiscountAmount = (price, salePrice) => {
  return parseInt(price - salePrice)
}

export const getMomentObject = (isoDateString = undefined) => {
  if (!isoDateString) {
    const isoDate = new Date().toISOString()
    return moment(isoDate)
  }
  return moment(isoDateString)
}

export const getDateFromAge = (age) => {
  return getMomentObject().subtract(age, 'years').format()
}

export const getAgeFromDate = (date) => {
  const days = getMomentObject().diff(moment(date), 'days')

  return Math.round(days / 365)
}

export const get10DigitNumber = (phone) => {
  if (phone.startsWith('+91')) {
    return phone.slice(3, phone.length)
  }
  return phone
}

export const getPhoneNumber = (phone = '') => {
  if (!phone.startsWith('+91')) {
    return `+91${phone}`
  }
  return phone
}

export const getMessages = (message) => {
  let messages = []
  if (typeof message === 'string' && message.length > 0) {
    messages.push(message)
  }
  if (typeof message === 'object' && message) {
    const objectMessages = Object.values(message)
    objectMessages.map(
      // eslint-disable-next-line array-callback-return
      msg => {
        if (typeof msg === 'string') {
          messages = [...messages, msg]
        } else {
          messages = [...messages, ...msg]
        }
      }
    )
  }
  return messages
}

export const getLanguagePath = (path, allowedLanguages = ['en', 'hi', 'kn', 'te']) => {
  return (`:language(${allowedLanguages.map(l => `/${l}`).join('|')}|)` + path)
}

export const getSearchParams = (path) => {
  const values = path.split('?')
  return queryString.parse(`?${values[values.length - 1]}`)
}

export const getQueryString = (params = {}) => {
  return Object.keys(params).map(key => key + '=' + params[key]).join('&')
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const GDPR_COUNTRIES = [
  'Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Republic of Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland',
  'France', 'Germany', 'Greece', 'Hungary', 'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta',
  'Netherlands', 'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain', 'Sweden', 'United Kingdom'
]

export const mergeLists = ({ list1 = [], list2 = [], key = 'timestamp', reverse = false }) => {
  // append list1 with list2
  const newList = [...list1, ...list2]
  // remove duplicates with same id and different updated_at or same updated_at
  const dict = {}

  for (let i = 0; i < newList.length; i++) {
    const obj = newList[i]
    if (!dict[obj.id] || (moment(dict[obj.id].updated_at) < moment(obj.updated_at))) {
      dict[obj.id] = obj
    }
  }
  const unsortedResults = Object.values(dict)

  if (reverse) {
    return unsortedResults.sort((a, b) => moment(a[key]) > moment(b[key]))
  }
  return unsortedResults.sort((a, b) => moment(a[key]) < moment(b[key]))
}

export const hideChatButton = () => {
  window.fcWidget.hide()
}

export const showChatButton = () => {
  window.fcWidget.show()
}

export const getLanguageTranslation = (object, key, language) => {
  if (!object) {
    return ''
  }
  const lang = languageOptions.filter(lang => lang.value === languageUrls[language])[0]
  const langKey = key + (lang ? lang.db_label : '')

  return object[langKey] || object[key]
}

export const openLinkOnNewTab = (url) => {
  let language = location.pathname
  language = language ? language.slice(0, 3) : '/en'
  window.open(`https://misters.in${language}${url}`)
}

export const interpolate = (x, x1, x2, y1, y2) => {
  const y = (((y2 - y1) / (x2 - x1)) * (x - x1)) + y1
  return y
}

export const mergeNestedComments = ({ comments = [], comment }) => {
  const newCommentList = [...comments]
  const flag = false

  for (let i = 0; i < newCommentList.length; i++) {
    const obj = newCommentList[i]
    if (obj.id === comment.id) {
      newCommentList[i] = comment
      break
    }
    if (flag) {
      break
    }
  }
  return newCommentList
}
