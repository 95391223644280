// External Imports
import React, { useState } from 'react'

import Image from '../../components/core/Image'
// import ImageService from '../../services/ImageService'
import { Images } from '../../constants'
import AliceCarousel from 'react-alice-carousel'
import moment from 'moment'
import StarRatings from 'react-star-ratings'

const getReviewCarouselItems = () => {
  const reviews = [
    {
      id: 0,
      name: 'Ashok',
      image: Images.testimonial.actor3,
      text: "Misters products have no side effects because I am a regular customer of this company. You'll get the results if you use it. Very good product 👌",
      rating: 5,
      date_joined: '2020-08-28T12:03:57+05:30',
      age: 35,
      city: 'Delhi',
      occupation: 'Bank Manager'
    },
    {
      id: 1,
      name: 'Kishan',
      image: Images.testimonial.actor1,
      text: 'I will give 5 star to this company because they are doing great job in this field. This company needs to be recognised at high level. All products are amazing and will give you what you are lacking.',
      rating: 5,
      date_joined: '2020-09-18T12:03:57+05:30',
      age: 29,
      city: 'Mumbai',
      occupation: 'Engineer'
    },
    {
      id: 2,
      name: 'Sanket',
      image: Images.testimonial.actor2,
      text: 'Best products for any sexual problem for any age of people. Product have a wonderful combination and formulation based on herbs.\nResults are very good.',
      rating: 5,
      date_joined: '2020-11-10T12:03:57+05:30',
      age: 48,
      city: 'Agra',
      occupation: 'Business'
    }
  ]
  return reviews.map(review => (
    <div key={review.id} className="px-2">
      <div className="homepage-review-card">
        <div className="homepage-review-card-header">
          <div className="homepage-review-card-image">
            <Image src={review.image} alt={review.name}/>
          </div>
          <div className="homepage-review-card-content">
            <div className="homepage-review-card-title">
              {review.name}
            </div>
            <div className="homepage-review-card-subtitle">
              {review.occupation} | {review.city} | {review.age} years
            </div>
            <div className="homepage-review-card-timestamp">
              {`Using since ${moment(review.date_joined).format('MMM, YYYY')}`}
            </div>
            <StarRatings
              rating={review.rating}
              starDimension="15px"
              starSpacing="3px"
              starRatedColor="#FF7200"
              isSelectable={false}
              numberOfStars={5}
              name='rating'
            />
          </div>
        </div>
        <div className="homepage-review-card-description">
          {review.text}
        </div>
      </div>
    </div>
  ))
}

const Section5 = () => {
  const [reviewCarouselIndex, setReviewCarouselIndex] = useState(0)
  const reviewCarouselItems = getReviewCarouselItems()

  const onReviewSlideChanged = (e) => {
    setReviewCarouselIndex(e.item)
  }

  return (
    <div className="home-page-section5">
      <div className="container pb-5">
        <div className="row pt-5">
          <div className="col-12">
            <h2 className="home-page-subtitle text-center">Customer Reviews</h2>
          </div>
          <div className="col-12 text-center">
            <Image className="homepage-rating-icon" src={Images.homeRatingIcon} alt="rating 4.8"/>
            <p className="text-center">Based on 1000+ Ratings</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <AliceCarousel mouseTrackingEnabled touchTrackingEnabled
                           autoPlayInterval={6000}
                           responsive={{ 0: { items: 1 } }}
                           autoPlay={true}
                           items={reviewCarouselItems}
                           startIndex={reviewCarouselIndex}
                           stopAutoPlayOnHover={true}
                           buttonsDisabled={true}
                           dotsDisabled={false}
                           onSlideChanged={onReviewSlideChanged}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section5
