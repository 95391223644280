// External Imports
import React from 'react'
import PropTypes from 'prop-types'
// My Imports
import LText from '../../core/LText/LText'
import Button from '../../buttons/Button'

export default class ImageBreaker extends React.Component {
    static propTypes = {
      image: PropTypes.string,
      imageTitle: PropTypes.string,
      text: PropTypes.string,
      buttonText: PropTypes.string,
      to: PropTypes.string,
      isBackgroundWhite: PropTypes.bool
    };

    static defaultProps = {
      isBackgroundWhite: false
    };

    render () {
      const { image, text, buttonText, to, imageTitle, isBackgroundWhite, ...props } = this.props

      return (
            <div className={`img-breaker-container ${isBackgroundWhite ? 'white-background' : ''}`}>
                <div className={'img-breaker'}>
                    <img src={image} alt={image}/>
                    {!!text && <h2>{text}</h2>}
                </div>
                {!!imageTitle && <p className="mt-2 mb-0 font-weight-bold">{imageTitle}</p>}
                {(!!buttonText) &&
                <Button to={to} className="img-breaker-btn">
                    <LText k={buttonText}/></Button>}
            </div>
      )
    }
}
