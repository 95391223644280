import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { Images } from '../../../constants'

const SummaryFrame1Card = function ({ title, text }) {
  return (
        <div className="summary-frame1-card-container">
            <h4 className="mb-5 font-weight-light text-center">
                Aahar and Vihaar <span className="text-success">inclusions</span>
            </h4>
            <div className="summary-frame1-card">
                <img className="summary-frame1-image-top-left" src={Images.report.apple} alt=""/>
                <img className="summary-frame1-image-top-right" src={Images.report.body} alt=""/>
                <img className="summary-frame1-image-bottom-left" src={Images.report.dumbbell} alt=""/>
                <img className="summary-frame1-image-bottom-right" src={Images.report.caret} alt=""/>
                <div dangerouslySetInnerHTML={{ __html: text }}/>
            </div>
        </div>
  )
}

SummaryFrame1Card.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
}

SummaryFrame1Card.defaultProps = {
  title: '',
  text: ''
}

export default withRouter(SummaryFrame1Card)
