import { combineReducers } from 'redux'
import ecommerce from './ecommerce'
import blog from './blog'
import bot from './bot'
import accounts from './accounts'
import languages from './languages'
import report from './report'
import subscriptions from './subscriptions'
import library from './library'
import clinic from './clinic'
import community from './community'

export default combineReducers({
  ecommerce,
  blog,
  bot,
  accounts,
  languages,
  report,
  subscriptions,
  library,
  clinic,
  community
})
