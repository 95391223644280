// External Imports
import React, { useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import Image from '../../components/core/Image'
import ImageService from '../../services/ImageService'
import { PHONE_NUMBER } from '../../constants'

const doctors = [
  {
    name: 'Dr. Abhishek Yadav',
    image: ImageService.home.doctor1,
    description: 'Dr Abhishek passed out from Ch Bhram Prakash University and has a deep understanding of the ayurvedic pharmacology. He treatments are based on understanding the patient history and lifestyle to reach the best possible recommendation.'
  },
  {
    name: 'Dr. Aditya Mohan Avasthi',
    image: ImageService.home.doctor2,
    description: 'Certified doctor with a BAMS degree & experience of treating more than 50,000 patients for sexual disorders. You can find him at seminars or conducting research on medicinal herbs.'
  },
  {
    name: 'Dr. Nikita Chaudhary',
    image: ImageService.home.doctor3,
    description: 'Ayurvedic doctor certified by the Central Council of Indian Medicine holding a Bachelor\'s degree in Ayurvedic medicine and surgery. She motivates her patients, regularly. '
  },
  {
    name: 'Dr. Ravneet',
    image: ImageService.home.doctor4,
    description: 'A certified medical doctor currently practicing in Gurgaon. A strong follower of the work and work-out mantra, she views writing as a form of meditation. '
  },
  {
    name: 'Dr. Simran Khalsa',
    image: ImageService.home.doctor5,
    description: 'Dr Simran Khalsa was earlier associated with Medanta Hospital, Gurgaon. She is passionate about spreading knowledge and currently engages with online content creation'
  }
]

const Section4 = ({ match, history }) => {
  const [doctorCarouselIndex, setDoctorCarouselIndex] = useState(0)
  const doctorCarouselItems = doctors.map(doctor => (
    <div className="doctor-network-item" key={doctor.name}>
      <Image src={doctor.image} alt="misters confidence formula 1"/>
      <h6>{doctor.name}</h6>
      <p>
        {doctor.description}
      </p>
    </div>
  ))
  const onDoctorSlideChanged = (e) => {
    setDoctorCarouselIndex(e.item)
  }
  return (
    <div className="home-page-section4">
      <div className="container pb-4">
        <div className="row py-5">
          <div className="col-12">
            <h2 className="home-page-subtitle text-center">Our doctor network</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <AliceCarousel mouseTrackingEnabled touchTrackingEnabled
                           autoPlayInterval={6000}
                           responsive={{ 0: { items: 1 }, 768: { items: 2 }, 992: { items: 3 }, 1200: { items: 4 } }}
                           autoPlay={true}
                           items={doctorCarouselItems}
                           startIndex={doctorCarouselIndex}
                           stopAutoPlayOnHover={true}
                           buttonsDisabled={true}
                           dotsDisabled={false}
                           onSlideChanged={onDoctorSlideChanged}
            />
          </div>
        </div>
      </div>
      <div className="text-center">
        <p className="home-page-description">
          Call us at <strong>{PHONE_NUMBER}</strong> for a free Consultation
        </p>
        <div>
          <a href={`tel:${PHONE_NUMBER}`} className="btn-m btn-m-dark mb-3"
             style={{ width: 250 }}>
            Call Now
          </a>
        </div>
      </div>
    </div>
  )
}

Section4.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

export default withRouter(Section4)
