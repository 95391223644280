import {
  FETCH_QUIZ_TAKERS_COUNT,
  FETCH_QUIZ_TAKERS_COUNT_RESULT,
  FETCH_QUIZ_TAKERS_COUNT_ERROR,
  FETCH_QUIZ_TAKERS_COUNT_RETRY,

  FETCH_BOT,
  FETCH_BOT_RESULT,
  FETCH_BOT_ERROR,
  FETCH_BOT_RETRY,

  FETCH_BOT_BACK,
  FETCH_BOT_BACK_RESULT,
  FETCH_BOT_BACK_ERROR,
  FETCH_BOT_BACK_RETRY,

  UPDATE_BOT,
  UPDATE_BOT_RESULT,
  UPDATE_BOT_ERROR,
  UPDATE_BOT_RETRY,

  REASSIGN_BOT_FIELD_USER,
  REASSIGN_BOT_FIELD_USER_RESULT,
  REASSIGN_BOT_FIELD_USER_ERROR,
  REASSIGN_BOT_FIELD_USER_RETRY,

  ASSIGN_BOT_FIELD_USER,
  ASSIGN_BOT_FIELD_USER_RESULT,
  ASSIGN_BOT_FIELD_USER_ERROR,
  ASSIGN_BOT_FIELD_USER_RETRY,

  BOT_FORM_META_DATA,
  BOT_FORM_META_DATA_RESULT,
  BOT_FORM_META_DATA_ERROR,
  BOT_FORM_META_DATA_RETRY,

  FETCH_BOT_LIST,
  FETCH_BOT_LIST_ERROR,
  FETCH_BOT_LIST_RETRY,
  FETCH_BOT_LIST_RESULT
} from '../../actions/bot/bot'

const initialState = {
  metaData: {},
  data: {},
  list: [],
  quizTakersCount: 0,
  isFetching: false,
  isReassigning: false,
  isUpdating: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUIZ_TAKERS_COUNT:
      return { ...state, isFetchingCount: true, error: false, message: null, retry: false }
    case FETCH_QUIZ_TAKERS_COUNT_RESULT:
      return { ...state, isFetchingCount: false, quizTakersCount: action.data }
    case FETCH_QUIZ_TAKERS_COUNT_ERROR:
      return { ...state, isFetchingCount: false, error: true, message: action.message }
    case FETCH_QUIZ_TAKERS_COUNT_RETRY:
      return { ...state, isFetchingCount: false, retry: true, message: action.message }

    case FETCH_BOT:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_BOT_RESULT:
      return { ...state, isFetching: false, data: action.data }
    case FETCH_BOT_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_BOT_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case FETCH_BOT_BACK:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_BOT_BACK_RESULT:
      return { ...state, isFetching: false, data: action.data }
    case FETCH_BOT_BACK_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_BOT_BACK_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case UPDATE_BOT:
      return { ...state, isUpdating: true, error: false, retry: false }
    case UPDATE_BOT_RESULT:
      return { ...state, isUpdating: false, data: action.data }
    case UPDATE_BOT_ERROR:
      return { ...state, isUpdating: false, error: true }
    case UPDATE_BOT_RETRY:
      return { ...state, isUpdating: false, retry: true }

    case REASSIGN_BOT_FIELD_USER:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case REASSIGN_BOT_FIELD_USER_RESULT:
      return { ...state, isFetching: false, data: action.data }
    case REASSIGN_BOT_FIELD_USER_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case REASSIGN_BOT_FIELD_USER_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case ASSIGN_BOT_FIELD_USER:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case ASSIGN_BOT_FIELD_USER_RESULT:
      return { ...state, isFetching: false, data: action.data }
    case ASSIGN_BOT_FIELD_USER_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case ASSIGN_BOT_FIELD_USER_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case BOT_FORM_META_DATA:
      return { ...state, error: false, message: null, retry: false }
    case BOT_FORM_META_DATA_RESULT:
      return { ...state, metaData: action.data }
    case BOT_FORM_META_DATA_ERROR:
      return { ...state, error: true, message: action.message }
    case BOT_FORM_META_DATA_RETRY:
      return { ...state, retry: true, message: action.message }

    case FETCH_BOT_LIST:
      return { ...state, error: false, message: null, retry: false }
    case FETCH_BOT_LIST_RESULT:
      return { ...state, list: action.data }
    case FETCH_BOT_LIST_ERROR:
      return { ...state, error: true, message: action.message }
    case FETCH_BOT_LIST_RETRY:
      return { ...state, retry: true, message: action.message }

    // case REASSIGN_BOT_FIELD_PATIENTS:
      //     return {...state, isReassigning: true};
      // case REASSIGN_BOT_FIELD_PATIENTS_RESULT:
      //     return {...state, isReassigning: false};
      // case REASSIGN_BOT_FIELD_PATIENTS_ERROR:
      //     return {...state, isReassigning: false};
      // case REASSIGN_BOT_FIELD_PATIENTS_RETRY:
      //     return {...state, isReassigning: false};
    default:
      return state
  }
}

export default reducer
