
import {
  FETCH_COURSES,
  FETCH_COURSES_RESULT,
  FETCH_COURSES_ERROR,
  FETCH_COURSES_RETRY,

  PURCHASE_LIBRARY,
  PURCHASE_LIBRARY_RESULT,
  PURCHASE_LIBRARY_ERROR,
  PURCHASE_LIBRARY_RETRY
} from '../../actions/library/courses'

const initialState = {
  list: [],
  isFetching: false,
  isPurchasing: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURSES:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_COURSES_RESULT:
      return { ...state, isFetching: false, list: action.data }
    case FETCH_COURSES_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_COURSES_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case PURCHASE_LIBRARY:
      return { ...state, isPurchasing: true, error: false, message: null, retry: false }
    case PURCHASE_LIBRARY_RESULT:
      return { ...state, isPurchasing: false }
    case PURCHASE_LIBRARY_ERROR:
      return { ...state, isPurchasing: false, error: true, message: action.message }
    case PURCHASE_LIBRARY_RETRY:
      return { ...state, isPurchasing: false, retry: true, message: action.message }

    default:
      return state
  }
}

export default reducer
