import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../components/core/Page'
import { Images, PHONE_NUMBER } from '../../constants'
import FormInput from '../../components/inputs/FormInput'
import { get10DigitNumber, getPhoneNumberWithCountryCode } from '../../utils'
import AccordionCard from '../Category/common/AccordionCard'
import { createLead } from '../../actions/clinic/lead'
import { toast } from 'react-toastify'

class ClinicPage extends Component {
    static propTypes = {
      createLead: PropTypes.func,
      lead: PropTypes.object
    }

    state = {
      name: '',
      phone_number: '',
      email: '',
      showModal: false,
      registered: false
    }

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { lead } = this.props

      if (!lead.isCreating && prevProps.lead.isCreating && lead.error && !lead.retry && !!lead.message) {
        this.setState({ message: lead.message })
      }
      if (!lead.isCreating && prevProps.lead.isCreating && !lead.error && !lead.retry) {
        this.setState({ name: '', phone_number: '', email: '', showModal: false, registered: true })
      }
    }

    handleRegisterClick = () => {
      const { name, email, phone_number } = this.state
      this.props.createLead({ name, email, phone_number: getPhoneNumberWithCountryCode(phone_number) })
    }

    render () {
      const { match } = this.props
      const { name, phone_number, email, showModal, registered, message } = this.state

      return (
            <Page showNavbar={false} showFooter={false} meta={{
              path: `${match.params.language}/pages/sexologists-sex-clinic-location-gurgaon-ad/`,
              title: 'Sexologist in Gurgaon & NCR - Misters.in Ayurvedic Sex Clinic',
              description: 'Misters Ayurvedic Sex Clinic in Gurgaon provides  walk-in consultations for screening, diagnosis and solutions of all sexual health issues and problems.',
              image: Images.pages.clinic
            }}>
                <div>
                    <div className="container">
                        <div className="row py-3">
                            <div className="col-12">
                                <div className="clinic-page-header">
                                    <img className='brand' src={Images.logo.big1x}
                                         alt="Misters"/>
                                    <a href={`tel:${PHONE_NUMBER}`}>Call: {PHONE_NUMBER}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row py-5">
                            <div className="col-lg-9 col-md-8 col-12">
                                <img className="clinic-page-banner-image img-fluid"
                                     src={Images.pages.clinic} alt="Bold"/>
                                <div className="clinic-page-content">
                                    <h1 className="clinic-page-title">Gurgaon Sex Clinic</h1>
                                    <h6>Sex problems addressed at Gurgaon sex hospital</h6>
                                    <ul>
                                        <li>Premature or rapid ejaculation</li>
                                        <li>Erection problems</li>
                                        <li>Pre-orgasmia (problem reaching orgasm)</li>
                                        <li>Delayed Ejaculation</li>
                                        <li>Painful Intercourse</li>
                                        <li>Low or Lack of Sexual Desire</li>
                                        <li>Sexually transmitted diseases (STD/STV)</li>
                                    </ul>
                                    <p>
                                        A sexologist and sex hospital in the heart of Gurgaon
                                        <br/><br/>
                                        Sexuality fascinates, intrigues, and raises a lot of questions, sometimes even
                                        concerns. Most men, however, believe that ayurvedic sex doctors are less
                                        accessible.
                                        They assume that sex health clinics in Gurgaon charge high consultation fees and
                                        may
                                        not be safe.
                                        <br/><br/>
                                        Our sex hospital in Gurgaon is here to break that myth. So, if you have any
                                        problems
                                        related to your sex life or are seeking sex treatment, a sexologist may be the
                                        best
                                        person to help you.
                                    </p>

                                    <div className="clinic-page-contact-details">
                                        <div className="row">
                                            <div className="col-md-8 col-12">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <th>Name:</th>
                                                        <td>Misters Ayurvedic Sex Clinic</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Address:</th>
                                                        <td>1st Floor, Shop no. 13-14, Goshala Market, Opposite Bus, Gurgaon - 122001</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone No.:</th>
                                                        <td><a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-12">
                                <div className="clinic-page-contact-form d-md-block d-none">
                                    {
                                        registered &&
                                        <div className="row">
                                            <div className="col-12 pb-2 text-center text-white">
                                                Thank you for the registration. We will get in touch with you soon.
                                            </div>
                                        </div>
                                    }
                                    {
                                        !registered &&
                                        <>
                                            <h4 className="clinic-page-contact-form-title">
                                                Consult Ayurvedic Doctor now!
                                            </h4>
                                            <div className="row">
                                                <div className="col-12 pb-2">
                                                    <FormInput placeholder='Name*'
                                                               value={name || ''}
                                                               error={message && message.name}
                                                               autoComplete="name"
                                                               onChange={(e) => this.setState({
                                                                 name: e.target.value
                                                               })}/>
                                                </div>
                                                <div className="col-12 pb-2">
                                                    <FormInput placeholder='10-digit mobile number*'
                                                               value={get10DigitNumber(phone_number || '')}
                                                               error={message && message.phone_number}
                                                               autoComplete="tel"
                                                               onChange={(e) => this.setState({
                                                                 phone_number: e.target.value
                                                               })}/>
                                                </div>
                                                <div className="col-12 pb-2">
                                                    <FormInput placeholder='Email*'
                                                               value={email}
                                                               error={message && message.email}
                                                               autoComplete="email"
                                                               onChange={(e) => this.setState({
                                                                 email: e.target.value
                                                               })}/>
                                                </div>
                                                <div className="col-12 pb-2 text-center">
                                                    <button className='btn-m btn-m-secondary'
                                                            onClick={this.handleRegisterClick}>
                                                        Book an appointment
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>

                                <div className="clinic-page-opening-hours">
                                    <h4 className="clinic-page-opening-hours-title">
                                        Opening Hours
                                    </h4>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th>Monday:</th>
                                            <td>09:00 - 18:00</td>
                                        </tr>
                                        <tr>
                                            <th>Tuesday:</th>
                                            <td>09:00 - 18:00</td>
                                        </tr>
                                        <tr>
                                            <th>Wednesday:</th>
                                            <td>09:00 - 18:00</td>
                                        </tr>
                                        <tr>
                                            <th>Thursday:</th>
                                            <td>09:00 - 18:00</td>
                                        </tr>
                                        <tr>
                                            <th>Friday:</th>
                                            <td>09:00 - 18:00</td>
                                        </tr>
                                        <tr>
                                            <th>Saturday:</th>
                                            <td>09:00 - 18:00</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-block d-md-none">
                    {
                        showModal && !registered &&
                        <div className="clinic-page-contact-form">
                            <h4 className="clinic-page-contact-form-title">Get a free consultation. Register now!</h4>
                            <div className="row">
                                <div className="col-12 pb-2">
                                    <FormInput placeholder='Name*'
                                               value={name || ''}
                                               error={message && message.name}
                                               autoComplete="name"
                                               onChange={(e) => this.setState({
                                                 name: e.target.value
                                               })}/>
                                </div>
                                <div className="col-12 pb-2">
                                    <FormInput placeholder='10-digit mobile number*'
                                               value={get10DigitNumber(phone_number || '')}
                                               error={message && message.phone_number}
                                               autoComplete="tel"
                                               onChange={(e) => this.setState({
                                                 phone_number: e.target.value
                                               })}/>
                                </div>
                                <div className="col-12 pb-2">
                                    <FormInput placeholder='Email*'
                                               value={email}
                                               error={message && message.email}
                                               autoComplete="email"
                                               onChange={(e) => this.setState({
                                                 email: e.target.value
                                               })}/>
                                </div>
                                <div className="col-12 pb-2 text-center">
                                    <button className='btn-m btn-m-secondary'
                                            onClick={this.handleRegisterClick}>
                                        Book an appointment
                                    </button>
                                    <button className="clinic-page-modal-btn-close"
                                            onClick={() => this.setState({ showModal: false })}>
                                        close
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        !showModal && !registered &&
                        <div className="clinic-page-contact-form">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn-m btn-m-secondary m-0'
                                            onClick={() => this.setState({ showModal: true })}>
                                        Book an appointment
                                    </button>
                                    <p className="text-center m-0">
                                        <small className="text-white">
                                            Consult Ayurvedic Doctor now!
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        registered &&
                        <div className="clinic-page-contact-form">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p className="text-center m-0">
                                        <small className="text-white">
                                            Thank you for the registration. We will get in touch with you soon.
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Page>
      )
    }
}

const mapStateToProps = (state) => ({
  lead: state.clinic.lead
})

const matchDispatchToProps = dispatch => bindActionCreators({
  createLead
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(ClinicPage)
