import React from 'react'
import * as PropTypes from 'prop-types'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class NewRedFlagButton extends React.Component {
    static propTypes = {
      name: PropTypes.string,
      style: PropTypes.object
    };

    static defaultProps = {
      style: {}
    };

    componentWillUnmount () {
    }

    render () {
      const { name, value, ...props } = this.props

      return (
            <div className={`new-red-flag-button ${value ? 'active' : ''}`} {...props}>
                {!value && <FontAwesomeIcon className="red-flag-icon" icon={faTimes}/>}
                <span>{name}</span>
            </div>
      )
    }
}
