import api from '../api'

export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_ORDER_RESULT = 'CREATE_ORDER_RESULT'
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR'
export const CREATE_ORDER_RETRY = 'CREATE_ORDER_RETRY'

export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDERS_RESULT = 'FETCH_ORDERS_RESULT'
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR'
export const FETCH_ORDERS_RETRY = 'FETCH_ORDERS_RETRY'

export const FETCH_ORDER = 'FETCH_ORDER'
export const FETCH_ORDER_RESULT = 'FETCH_ORDER_RESULT'
export const FETCH_ORDER_ERROR = 'FETCH_ORDER_ERROR'
export const FETCH_ORDER_RETRY = 'FETCH_ORDER_RETRY'

export const UPDATE_ORDER_PRODUCT = 'UPDATE_ORDER_PRODUCT'
export const UPDATE_ORDER_PRODUCT_RESULT = 'UPDATE_ORDER_PRODUCT_RESULT'
export const UPDATE_ORDER_PRODUCT_ERROR = 'UPDATE_ORDER_PRODUCT_ERROR'
export const UPDATE_ORDER_PRODUCT_RETRY = 'UPDATE_ORDER_PRODUCT_RETRY'

export const UPDATE_NEW_ORDER = 'UPDATE_NEW_ORDER'

export const createOrder = (data) => async dispatch => {
  try {
    dispatch({ type: CREATE_ORDER })
    const res = await api.ecommerce.orders.create(data)
    const result = await res.json()

    if (res.status === 201) {
      dispatch({ type: CREATE_ORDER_RESULT, data: result })
    } else {
      dispatch({ type: CREATE_ORDER_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: CREATE_ORDER_RETRY, message: e.message })
  }
}

export const fetchOrders = () => async dispatch => {
  try {
    dispatch({ type: FETCH_ORDERS })
    const res = await api.ecommerce.orders.list()
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_ORDERS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_ORDERS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_ORDERS_RETRY, message: e.message })
  }
}

export const fetchOrder = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_ORDER })
    const res = await api.ecommerce.orders.get(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_ORDER_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_ORDER_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_ORDER_RETRY, message: e.message })
  }
}

export const updateOrderProduct = (data) => async dispatch => {
  try {
    dispatch({ type: UPDATE_ORDER_PRODUCT })
    const res = await api.ecommerce.orders.updateProduct(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: UPDATE_ORDER_PRODUCT_RESULT, data: result })
    } else {
      dispatch({ type: UPDATE_ORDER_PRODUCT_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: UPDATE_ORDER_PRODUCT_RETRY, message: e.message })
  }
}

export const updateNewOrder = (data) => ({ type: UPDATE_NEW_ORDER, data })
