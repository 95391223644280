import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Carousel from 'react-bootstrap/Carousel'

import LoadingCard from '../LoadingCard'
import { fieldTypes } from '../../../constants'
import PDGraph from '../../others/PDGraph'
import HeightInput from '../../inputs/HeightInput'
import SingleInput from '../../inputs/SingleInput'
import ChoiceInput from '../../inputs/ChoiceInput'
import { getHttpsImageUrl } from '../../../utils'
import { languageUrls } from '../../../languages'
import { withRouter } from 'react-router'
import StarInput from '../../inputs/StarInput'
import RatingInput from '../../inputs/RatingInput'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Ripple from '../../loadings/Ripple'

const { INTEGER, FLOAT, TEXT, EMAIL, READ_ONLY_TEXT, CHOICE, MULTIPLE_CHOICE, BOOL, DATE, FILE, LOADING, HEIGHT, STAR, RATING } = fieldTypes

class HomeCard extends Component {
    static propTypes = {
      handleSendClick: PropTypes.func,
      data: PropTypes.object,
      progress: PropTypes.any
    };

    static defaultProps = {};

    state = {
      edit: true,
      index: 0,
      direction: null,
      show: false,
      loading: null
    };

    toggleEdit = () => {
      this.setState(prevState => ({ edit: !prevState.edit }))
    };

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState) {
      const { bot } = this.props

      if (bot.isUpdating === false &&
            (bot.error || bot.retry) &&
            bot.isUpdating !== prevProps.bot.isUpdating) {
        this.toggleEdit()
      }
    }

    renderField = () => {
      const { edit } = this.state
      const { data } = this.props
      const languageName = languageUrls[this.props.match.params.language]
      switch (data.bot_field.type) {
        case HEIGHT:
          return <HeightInput handleSendClick={(value) => this.props.handleSendClick(data.id, value)}/>

        case INTEGER:
        case TEXT:
        case EMAIL:
        case FLOAT:
        case DATE:
          return <SingleInput language={languageName}
                                    toggleEdit={this.toggleEdit}
                                    inputType={data.bot_field.type}
                                    handleSendClick={(value) => this.props.handleSendClick(data.id, value)}/>
        case CHOICE:
          return <ChoiceInput language={languageName}
                                    toggleEdit={this.toggleEdit} choices={data.field_response_children}
                                    handleSendClick={(value) => this.props.handleSendClick(data.id, value)}/>
        case MULTIPLE_CHOICE:
          return <ChoiceInput multipleChoice={true}
                                    language={languageName}
                                    toggleEdit={this.toggleEdit} choices={data.field_response_children}
                                    handleSendClick={(value) => this.props.handleSendClick(data.id, value)}/>
        case STAR:
          return <StarInput hideNextButton={true}
                                  handleSendClick={(value) => this.props.handleSendClick(data.id, value)}/>
        case RATING:
          return <RatingInput hideNextButton={true}
                                    handleSendClick={(value) => this.props.handleSendClick(data.id, value)}/>
          // case FILE:
          //     return <FileInputCard edit={edit}
          //                           toggleEdit={this.toggleEdit}
          //                           handleSendClick={(value) => this.props.handleSendClick(data.id, value)}/>;
        default:
          return null
      }
    };

    renderGraphTitle = (titles) => {
      if (!titles) { return null }
      const title = titles[0]
      let value = title.match(/\(.+\)/g)[0]
      // console.warn(value);
      value = value.slice(1, value.length - 1)
      // console.warn(value);
      return <p className='pd-graph-title'>{value}</p>
    };

    renderGraph = (graphs) => {
      if (!graphs) { return null }

      const graph = graphs[0]

      let value = graph.match(/\(.+\)/g)[0]
      value = value.slice(1, value.length)

      return <PDGraph value={parseFloat(value)}/>
    };

    handleSelect = (selectedIndex, e) => {
      this.setState({
        index: selectedIndex,
        direction: e.direction
      })
    };

    render () {
      const { data, bot } = this.props
      const languageName = languageUrls[this.props.match.params.language]
      if (data.text.length > 0) { return null }

      const { [languageName]: htmlData = data.bot_field.text } = data.bot_field.languages
      const { index, direction, show, loading } = this.state

      if (loading) {
        return (
                <LoadingCard text={loading[languageName]}/>
        )
      }

      return (
            <div className='home-card-wrapper'>
                {
                    data.bot_field.bot_field_files.length > 0 &&
                    <Carousel
                        activeIndex={index}
                        direction={direction}
                        onSelect={this.handleSelect}
                        controls={data.bot_field.bot_field_files.length > 1}
                        indicators={data.bot_field.bot_field_files.length > 1}
                        nextIcon={<FontAwesomeIcon icon={faAngleRight} color={'#333'} size="lg"/>}
                        prevIcon={<FontAwesomeIcon icon={faAngleLeft} color={'#333'} size="lg"/>}
                    >
                        {
                            data.bot_field.bot_field_files.map(
                              (file) => (
                                    <Carousel.Item key={file.id}>
                                        <img className="home-card-img" src={getHttpsImageUrl(file.file)}/>
                                    </Carousel.Item>
                              )
                            )
                        }
                    </Carousel>
                }
                <div className="home-card">
                    {data.bot_field.type === LOADING ? '' : <div dangerouslySetInnerHTML={{ __html: htmlData }}/>}
                </div>

                <div className={`home-btn-container ${this.props.progress ? '' : 'starting-page-btn-container'}`}>
                    {this.renderField()}
                </div>
                {
                    data.bot_field.help_text &&
                    <div className="quiz-help-text">{data.bot_field.help_text}</div>
                }
                {!!bot.isUpdating &&
                <div className="quiz-loader">
                    <Ripple/><h6>Calculating...</h6>
                </div>
                }
            </div>
      )
    }
}

const mapStateToProps = state => ({
  bot: state.bot.bot,
  products: state.ecommerce.products.products
})

export default connect(mapStateToProps)(withRouter(HomeCard))
