import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import ImageViewer from 'react-simple-image-viewer'

import Page from '../../components/core/Page'
import { Images } from '../../constants'
import FormInput from '../../components/inputs/FormInput'
import {
  get10DigitNumber,
  getDiscount,
  getHttpsImageUrl,
  getPhoneNumberWithCountryCode,
  getPrice,
  truncChars
} from '../../utils'
import AccordionCard from '../Category/common/AccordionCard'
import { createLead } from '../../actions/clinic/lead'
import { fetchClinic } from '../../actions/clinic/clinic'
import ImageService from '../../services/ImageService'
import TherapyPrice from '../Therapy/Components/TherapyPrice'
import LText from '../../components/core/LText'
import SchemaService from '../../services/SchemaService'

class CommunityClinicPage extends Component {
    static propTypes = {
      createLead: PropTypes.func,
      fetchClinic: PropTypes.func,
      clinic: PropTypes.object,
      lead: PropTypes.object
    }

    static fetching ({ dispatch, path, routePath, match }) {
      const { slug } = match.params
      return [dispatch(fetchClinic({ slug }))]
    };

    state = {
      name: '',
      phone_number: '',
      email: '',
      showModal: false,
      registered: false,
      currentImage: 0,
      isViewerOpen: false
    }

    componentDidMount () {
      this.props.fetchClinic({ slug: this.props.match.params.slug })
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { lead, clinic } = this.props

      if (!lead.isCreating && prevProps.lead.isCreating && lead.error && !lead.retry && !!lead.message) {
        this.setState({ message: lead.message })
      }
      if (!lead.isCreating && prevProps.lead.isCreating && !lead.error && !lead.retry) {
        this.setState({ name: '', phone_number: '', email: '', showModal: false, registered: true })
      }
    }

    handleRegisterClick = () => {
      const { name, email, phone_number } = this.state
      const { clinic: { detail } } = this.props
      if (detail.id) {
        this.props.createLead({
          clinic: detail.id,
          name,
          email,
          phone_number: getPhoneNumberWithCountryCode(phone_number)
        })
      }
    }

    getCanonicalURL = () => {
      const { clinic: { detail }, match } = this.props
      return `${match.params.language}/clinic-community/${detail.slug}/`
    }

    getImages = () => {
      const { clinic: { detail } } = this.props
      let images = []

      if (detail.clinic_images) {
        images = detail.clinic_images.map(clinic_image => clinic_image.image)
      }
      return images
    }

    handleChangeImage = (index) => {
      this.setState({ currentImage: index })
    };

    handleCloseImageViewer = () => {
      this.setState({ currentImage: 0, isViewerOpen: false })
    }

    openImageViewer = (index) => {
      this.handleChangeImage(index)
      this.setState({ isViewerOpen: true })
    }

    render () {
      const { clinic: { detail }, match } = this.props
      const { name, phone_number, email, showModal, registered, message, currentImage, isViewerOpen } = this.state
      const canonicalURL = this.getCanonicalURL()
      const schemaScriptTags = [
        SchemaService.getClinicSchema({ clinic: detail, url: canonicalURL }),
        SchemaService.getFaqSchema({ data: (detail.clinic_faqs || []), url: canonicalURL }),
        SchemaService.getItemSchema({
          items: (detail.clinic_products || []).map(
            clinic_product => {
              const product = clinic_product.product
              return {
                title: product.title,
                image: product.image,
                url: `${match.params.language}/checkout/?pid=${product.product_id}&utm_source=clinic-${detail.slug}`
              }
            }
          )
        })
      ]
      // console.warn(schemaScriptTags);
      return (
            <Page meta={{
              path: canonicalURL,
              title: detail.meta_title,
              description: detail.meta_description,
              image: getHttpsImageUrl(detail.banner_image)
            }} script={schemaScriptTags}>
                <div>
                    <div className="container">
                        <div className="row pt-5 pb-2">
                            <div className="col-lg-9 col-md-8 col-12">
                                <img className="clinic-page-banner-image img-fluid"
                                     src={getHttpsImageUrl(detail.banner_image)} alt="Bold"/>
                                <div className="clinic-page-content">
                                    <h1 className="clinic-page-title">{detail.title}
                                    </h1>
                                    <div dangerouslySetInnerHTML={{ __html: detail.content_section_1 }}/>
                                    <div className="text-center">
                                        <a className="btn-m btn-m-red"
                                           href={`tel:${detail.phone_number}`}>
                                            Call now: <small>{detail.phone_number}</small>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-12">
                                <div className="clinic-page-contact-form d-md-block d-none">
                                    {
                                        registered &&
                                        <div className="row">
                                            <div className="col-12 pb-2 text-center text-white">
                                                Thank you for the registration. We will get in touch with you soon.
                                            </div>
                                        </div>
                                    }
                                    {
                                        !registered &&
                                        <>
                                            <h4 className="clinic-page-contact-form-title">
                                                Consult Doctor now!
                                            </h4>
                                            <div className="row">
                                                <div className="col-12 pb-2">
                                                    <FormInput placeholder='Name*'
                                                               value={name || ''}
                                                               error={message && message.name}
                                                               autoComplete="name"
                                                               onChange={(e) => this.setState({
                                                                 name: e.target.value
                                                               })}/>
                                                </div>
                                                <div className="col-12 pb-2">
                                                    <FormInput placeholder='10-digit mobile number*'
                                                               value={get10DigitNumber(phone_number || '')}
                                                               error={message && message.phone_number}
                                                               autoComplete="tel"
                                                               onChange={(e) => this.setState({
                                                                 phone_number: e.target.value
                                                               })}/>
                                                </div>
                                                <div className="col-12 pb-2">
                                                    <FormInput placeholder='Email*'
                                                               value={email}
                                                               error={message && message.email}
                                                               autoComplete="email"
                                                               onChange={(e) => this.setState({
                                                                 email: e.target.value
                                                               })}/>
                                                </div>
                                                <div className="col-12 pb-2 text-center">
                                                    <button className='btn-m btn-m-secondary'
                                                            onClick={this.handleRegisterClick}>
                                                        Book an appointment
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    !!detail.clinic_opening_hours_specifications &&
                                    !!detail.clinic_opening_hours_specifications.length > 0 &&
                                    <>
                                        <div className="clinic-page-opening-hours">
                                            <h4 className="clinic-page-opening-hours-title">
                                                Opening Hours
                                            </h4>
                                            <table>
                                                <tbody>
                                                {
                                                    detail.clinic_opening_hours_specifications.map(
                                                      ({ days, opens, closes }) => {
                                                        return days.map(
                                                          day => (
                                                                    <tr key={day}>
                                                                        <th>{day}:</th>
                                                                        <td>{opens} - {closes}</td>
                                                                    </tr>
                                                          )
                                                        )
                                                      }
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        !!detail.clinic_products &&
                        !!detail.clinic_products.length &&
                        <div className="clinic-page-products">
                            <div className="container">
                                <h3 className="mb-3">Products</h3>
                                {
                                    detail.clinic_products.map(
                                      clinic_product => {
                                        const product = clinic_product.product
                                        const price = getPrice(product.price)
                                        const salePrice = product.sale_price ? getPrice(product.sale_price) : null
                                        const discount = getDiscount(price, salePrice)
                                        return (
                                                <div key={clinic_product.id}
                                                     className="clinic-page-product">
                                                    <img src={product.image}
                                                         alt={product.title}/>
                                                    <h5>{product.title}</h5>
                                                    <p>{truncChars(product.short_description, 150)}</p>
                                                    <TherapyPrice discount={discount} price={price}
                                                                  salePrice={salePrice}/>
                                                    <div className="text-center">
                                                        <a className="btn-m btn-m-red shop-product-card-button"
                                                           href={`${match.params.language}/checkout/?pid=${product.product_id}&utm_source=clinic-${detail.slug}`}>
                                                            <LText k="ADD_TO_CART"/>
                                                        </a>
                                                    </div>
                                                </div>
                                        )
                                      }
                                    )
                                }
                            </div>
                        </div>
                    }
                    <div className="container">
                        <div className="row pb-5">
                            <div className="col-12">
                                <div className="clinic-page-content">
                                    <div className="clinic-page-contact-details mb-3">
                                        <div className="row">
                                            <div className="col-md-8 col-12">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <th>Name:</th>
                                                        <td>{detail.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Address:</th>
                                                        <td>{detail.address}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone No.:</th>
                                                        <td><a
                                                            href={`tel:${detail.phone_number}`}>{detail.phone_number}</a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="clinic-page-social-media-handles">
                                                    {
                                                        !!detail.facebook_url &&
                                                        <a target="_blank" title="facebook" rel="noreferrer"
                                                           href={detail.facebook_url}>
                                                            <img src={Images.logo.facebook} alt="facebook"/>
                                                        </a>
                                                    }
                                                    {
                                                        !!detail.linkedin_url &&
                                                        <a target="_blank" title="linkedin" rel="noreferrer"
                                                           href={detail.linkedin_url}>
                                                            <img src={Images.logo.linkedin} alt="linkedin"/>
                                                        </a>
                                                    }
                                                    {
                                                        !!detail.youtube_url &&
                                                        <a target="_blank" title="linkedin" rel="noreferrer"
                                                           href={detail.youtube_url}>
                                                            <img src={Images.logo.youtube} alt="youtube"/>
                                                        </a>
                                                    }
                                                    {
                                                        !!detail.sulekha_url &&
                                                        <a target="_blank" title="Sulekha" rel="noreferrer"
                                                           href={detail.sulekha_url}>
                                                            <img src={ImageService.logos.sulekha} alt=""/>
                                                        </a>
                                                    }
                                                    {
                                                        !!detail.justdial_url &&
                                                        <a target="_blank" title="Justdial" rel="noreferrer"
                                                           href={detail.justdial_url}>
                                                            <img src={ImageService.logos.justdial} alt=""/>
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: detail.content_section_2 }}/>
                                </div>
                                {
                                    !!detail.clinic_faqs &&
                                    !!detail.clinic_faqs.length &&
                                    <>
                                        <h3 className="mt-3">FAQs</h3>
                                        {
                                            detail.clinic_faqs.map(
                                              (clinic_faq) => (
                                                    <div className="clinic-page-faqs" key={clinic_faq.id}>
                                                        <AccordionCard title={clinic_faq.question}
                                                                       key={'faq-1'} arrow>
                                                            <div className="product-faqs-description"
                                                                 dangerouslySetInnerHTML={{ __html: clinic_faq.answer }}/>
                                                        </AccordionCard>
                                                    </div>
                                              )
                                            )
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !!detail.clinic_images &&
                    !!detail.clinic_images.length &&
                    <div className="clinic-page-gallery">
                        <div className="container">
                            <h3 className="mb-3">Gallery</h3>
                            <div>
                                {detail.clinic_images.map((clinic_image, index) => (
                                    <img className="clinic-page-gallery-image"
                                         src={clinic_image.image}
                                         onClick={() => this.openImageViewer(index)}
                                         key={clinic_image.id}
                                         alt=""/>
                                ))}
                                {this.state.isViewerOpen && (
                                    <ImageViewer
                                        backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.3)', zIndex: 1000 }}
                                        src={this.getImages()}
                                        currentIndex={currentImage}
                                        onClose={this.handleCloseImageViewer}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                }
                {
                    !!detail.clinic_youtube_videos &&
                    !!detail.clinic_youtube_videos.length &&
                    <div className="clinic-page-videos">
                        <div className="container">
                            <h3 className="mb-3">Videos</h3>
                            {
                                detail.clinic_youtube_videos.map(
                                  clinic_youtube_video => (
                                        <iframe key={clinic_youtube_video.id} width="300" height="200"
                                                src={clinic_youtube_video.url}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen/>
                                  )
                                )
                            }
                        </div>
                    </div>
                }
                {
                    !!detail.map_location_url &&
                    <iframe
                        src={detail.map_location_url}
                        width="100%" height="400" frameBorder="0" style={{ border: 0 }} allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"/>
                }
                <div className="d-block d-md-none">
                    {
                        showModal && !registered &&
                        <div className="clinic-page-contact-form">
                            <h4 className="clinic-page-contact-form-title">Get a free consultation. Register now!</h4>
                            <div className="row">
                                <div className="col-12 pb-2">
                                    <FormInput placeholder='Name*'
                                               value={name || ''}
                                               error={message && message.name}
                                               autoComplete="name"
                                               onChange={(e) => this.setState({
                                                 name: e.target.value
                                               })}/>
                                </div>
                                <div className="col-12 pb-2">
                                    <FormInput placeholder='10-digit mobile number*'
                                               value={get10DigitNumber(phone_number || '')}
                                               error={message && message.phone_number}
                                               autoComplete="tel"
                                               onChange={(e) => this.setState({
                                                 phone_number: e.target.value
                                               })}/>
                                </div>
                                <div className="col-12 pb-2">
                                    <FormInput placeholder='Email*'
                                               value={email}
                                               error={message && message.email}
                                               autoComplete="email"
                                               onChange={(e) => this.setState({
                                                 email: e.target.value
                                               })}/>
                                </div>
                                <div className="col-12 pb-2 text-center">
                                    <button className='btn-m btn-m-secondary'
                                            onClick={this.handleRegisterClick}>
                                        Book an appointment
                                    </button>
                                    <button className="clinic-page-modal-btn-close"
                                            onClick={() => this.setState({ showModal: false })}>
                                        close
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        !showModal && !registered &&
                        <div className="clinic-page-contact-form">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn-m btn-m-secondary m-0'
                                            onClick={() => this.setState({ showModal: true })}>
                                        Book an appointment
                                    </button>
                                    <p className="text-center m-0">
                                        <small className="text-white">
                                            Consult Doctor now!
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        registered &&
                        <div className="clinic-page-contact-form">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p className="text-center m-0">
                                        <small className="text-white">
                                            Thank you for the registration. We will get in touch with you soon.
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Page>
      )
    }
}

const mapStateToProps = (state) => ({
  lead: state.clinic.lead,
  clinic: state.clinic.clinic
})

const matchDispatchToProps = dispatch => bindActionCreators({
  createLead,
  fetchClinic
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(CommunityClinicPage)
