import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'

import useScrollDirection from '../../../hooks/useScrollDirection'
import { changeLanguage } from '../../../actions/languages'
import { languageToShortName, languageUrls } from '../../../languages'
import { isRouteEqual } from '../../../routes'
import { Images } from '../../../constants'
import ImageService from '../../../services/ImageService'
import LText from '../../core/LText'
import { fetchCart } from '../../../actions/ecommerce/cart'
import { getLanguage } from '../../../store_utils'

const Navbar = ({ profile, match, cart: { detail }, history, ...props }) => {
  const [active, setActive] = useState('')
  const scrollDirection = useScrollDirection()
  const showNavbar = scrollDirection === 'up'

  const name = languageUrls[match.params.language]
  const langParam = match.params.language || '/en'
  const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email

  const isCart = isRouteEqual(match.path, 'Cart') || isRouteEqual(match.path, 'DeliveryAddress') || isRouteEqual(match.path, 'Payment')

  const isHome = isRouteEqual(match.path, 'Home')
  const isProfile = isRouteEqual(match.path, 'Profile')
  const isSolutions = isRouteEqual(match.path, 'Solutions')
  const isLogin = isRouteEqual(match.path, 'Login')
  const isAbout = isRouteEqual(match.path, 'About')
  const isAyurveda = isRouteEqual(match.path, 'Ayurveda')

  useEffect(() => {
    if (!detail.items && !!profile.detail.id && !isCart) {
      props.fetchCart()
    }
  }, [])

  const handleLanguageChange = (language) => {
    const url = '/' + languageToShortName[language] + match.url.slice(3, match.url.length)
    props.changeLanguage({ language })
    history.push(url)
  }

  return (
    <>
      <div className={`navbar-container ${showNavbar ? 'active' : ''}`}>
        <div className={`navbar ${showNavbar ? 'active' : ''}`}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <button className="d-flex d-lg-none main-navbar-hamburger"
                          type="button" onClick={() => setActive(!active)}>
                    <FontAwesomeIcon icon={faBars}/>
                  </button>
                  <div className="d-flex flex-fill align-items-center">
                    <NavLink to={`${langParam}/`} className='text-decoration-none'
                             onClick={props.handleLogoClick}>
                      <img className='brand' src={ImageService.logos.brand}
                           alt="Misters"/>
                    </NavLink>
                  </div>
                  <div className={`nav-links nav-links-collapse ${active ? 'is-active' : ''}`}>
                    <NavLink to={`${langParam}`} activeClassName={`${isHome ? 'active' : ''}`}
                             className='text-decoration-none nav-link'>
                      <LText k="HOME"/>
                    </NavLink>
                    {/* <NavLink to={`${langParam}/blog/`} activeClassName={`${isBlog ? 'active' : ''}`} */}
                    {/*         className='text-decoration-none nav-link'> */}
                    {/*  <LText k="BLOG"/> */}
                    {/* </NavLink> */}

                    <NavLink to={`${langParam}/solutions/`} activeClassName={`${isSolutions ? 'active' : ''}`}
                             className='text-decoration-none nav-link'>
                      <LText k="SHOP"/>
                    </NavLink>
                    <NavLink to={`${langParam}/ayurveda/`} activeClassName={`${isAyurveda ? 'active' : ''}`}
                             className='text-decoration-none nav-link'>
                      <LText k="AYURVEDA"/>
                    </NavLink>
                    <a href={`https://misters.in${langParam}/blog/`}
                             className='text-decoration-none nav-link'>
                      <LText k="BLOG"/>
                    </a>
                    <a href={`https://misters.in${langParam}/qa/`}
                       className='text-decoration-none nav-link'>
                      <LText k="QA"/>
                    </a>
                    <NavLink to={`${langParam}/about/`} activeClassName={`${isAbout ? 'active' : ''}`}
                             className='text-decoration-none nav-link'>
                      <LText k="ABOUT"/>
                    </NavLink>
                    <select className='text-decoration-none nav-link' name="language" value={name}
                            onChange={e => handleLanguageChange(e.target.value)}>
                      <option value="english">English</option>
                      <option value="hindi">हिंदी</option>
                      <option value="kannada">ಕನ್ನಡ</option>
                      <option value="telugu">తెలుగు</option>
                    </select>
                  </div>
                  <div className="nav-links navbar-icons d-flex">
                    <a href="https://api.whatsapp.com/send?phone=919319294990&text=Hello" target="_blank"
                       className='text-decoration-none nav-link whatsapp-icon' rel="noreferrer">
                      <FontAwesomeIcon icon={faWhatsapp} className="float-icon" color={'#fff'}/>
                    </a>

                    <NavLink to={`${langParam}/wishlist`}
                             className='text-decoration-none nav-link'>
                      <img className="navbar-icon wishlist-icon" src={Images.icons.wishlist} alt="wishlist"
                           title={getLanguage('WISHLIST')}/>
                    </NavLink>

                    <NavLink to={`${langParam}/checkout`} activeClassName={`${isCart ? 'active' : ''}`}
                             className='text-decoration-none nav-link cart-count'>
                      <img className="navbar-icon cart-icon" src={Images.icons.cart} alt="cart"
                           title={getLanguage('CART')}/>
                      {!!detail.items && !!detail.items.length &&
                      <span>{detail.items.length}</span>
                      }
                    </NavLink>
                    {
                      isAuthenticated &&
                      <NavLink to={`${langParam}/profile`} activeClassName={`${isProfile ? 'active' : ''}`}
                               className='text-decoration-none nav-link'>
                        <img className="navbar-icon profile-icon" src={Images.icons.user} alt="user"
                             title={getLanguage('PROFILE')}/>
                      </NavLink>
                    }
                    {
                      !isAuthenticated &&
                      <NavLink to={`${langParam}/login`} activeClassName={`${isLogin ? 'active' : ''}`}
                               className='text-decoration-none nav-link'>
                        <img className="navbar-icon profile-icon" src={Images.icons.user} alt="user"
                             title={getLanguage('LOGIN')}/>
                      </NavLink>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Navbar.propTypes = {
  profile: PropTypes.object,
  cart: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  changeLanguage: PropTypes.func,
  fetchCart: PropTypes.func,
  handleLogoClick: PropTypes.func
}

Navbar.defaultProps = {
  handleLogoClick: () => {
  }
}

const mapStateToProps = (state) => ({
  profile: state.accounts.profile,
  cart: state.ecommerce.cart
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeLanguage,
  fetchCart
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar))
