import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Images } from '../../../constants'

class AnalysisDesireScoreCard extends Component {
    static propTypes = {
      className: PropTypes.string,
      score: PropTypes.number,
      stepBreaker: PropTypes.number,
      duration: PropTypes.number,
      delay: PropTypes.number,
      offset: PropTypes.number
    };

    static defaultProps = {
      className: '',
      score: 0,
      stepBreaker: 10,
      duration: 1000,
      delay: 100,
      offset: 200
    };

    constructor (props) {
      super(props)

      this.state = {}
    }

    componentDidMount () {}

    componentDidUpdate (prevProps, prevState, snapshot) {

    }

    componentWillUnmount () {
    }

    render () {
      const { className, score, duration, delay, stepBreaker, percentile, offset } = this.props

      const steps = []
      for (let i = 1; i <= 10; i++) {
        steps.push(i * stepBreaker)
      }

      return (
            <div className={`analysis-desire-score-card ${className}`} data-aos-offset={offset}
                 data-aos="zoom-in" data-aos-duration={duration} data-aos-delay={delay} data-aos-once="true">
                <div className="desire-score-flame-container">
                    {steps.map((step, index) => {
                      const active = ((index + 1) * stepBreaker) <= score
                      const halfActive = (((index * stepBreaker) + 4) < score && score < ((index + 1) * stepBreaker))
                      return (
                                <div className="desire-score-flame" key={step}>
                                    {active
                                      ? <span className="d-inline-block">
                                            <img src={Images.report.desireScoreActiveFlame}
                                                 alt="desireScoreActiveFlame"/>
                                        </span>
                                      : halfActive
                                        ? <span className="d-inline-block">
                                                <img src={Images.report.desireScoreSemiFlame}
                                                     alt="desireScoreSemiFlame"/>
                                            </span>
                                        : <img src={Images.report.desireScoreFlame} alt="desireScoreFlame"/>
                                    }
                                </div>
                      )
                    }
                    )}
                </div>
                <h5 className="desire-score-title">Desire score: &nbsp; {score}</h5>
              {
                !!percentile && <p className="report-percentile">Your score is more than {percentile}% of all users who have taken this quiz</p>
              }
            </div>
      )
    }
}

export default AnalysisDesireScoreCard
