// External Imports
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
// My Imports
import { getHttpsImageUrl } from '../../utils'

const getUsageCount = (count, productType) => {
  if (count) {
    switch (productType) {
      case 'capsule':
        return `${count} capsule${count > 1 ? 's' : ''}`
      case 'drop':
        return `Apply ${count} time${count > 1 ? 's' : ''}`
    }
  }
  return ''
}

const getHowToUseTextFromItem = (item) => {
  const morningCount = item.morning_dose
  const afternoonCount = item.afternoon_dose
  const nightCount = item.night_dose
  const anytimeCount = item.anytime_dose

  const productType = item.item.dose_type
  const howToUseInstructions = []
  if (morningCount) {
    const usageText = getUsageCount(morningCount, productType)
    if (usageText) {
      howToUseInstructions.push(`${usageText} after breakfast`)
    }
  }
  if (afternoonCount) {
    const usageText = getUsageCount(afternoonCount, productType)
    if (usageText) {
      howToUseInstructions.push(`${usageText} after lunch`)
    }
  }
  if (nightCount) {
    const usageText = getUsageCount(nightCount, productType)
    if (usageText) {
      howToUseInstructions.push(`${usageText} after dinner`)
    }
  }
  if (anytimeCount) {
    const usageText = getUsageCount(anytimeCount, productType)
    if (usageText) {
      howToUseInstructions.push(`${usageText} in a day`)
    }
  }
  return howToUseInstructions.join(', ')
}

const Section4 = ({ match, product }) => {
  return (
    <div className='solution-page-section4 py-5'>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="solution-page-title text-center mb-3">
              How to use for best results
            </h2>
            <table className="m-auto">
              <tbody>
              {
                !!product.item_connections && product.item_connections.map(
                  (item) => {
                    return (
                      <tr key={item.id} className="solution-how-to-use-item">
                        <td>
                          {
                            !!item.item.image &&
                            <img src={getHttpsImageUrl(item.item.image)}
                                 alt={item.item.title}/>
                          }
                        </td>
                        <td>
                          <h6>{item.item.title}</h6>
                        </td>
                        <td><p>{getHowToUseTextFromItem(item)}</p></td>
                      </tr>
                    )
                  }
                )
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

Section4.propTypes = {
  match: PropTypes.object,
  product: PropTypes.object
}

const mapStateToProps = state => ({})

const matchDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Section4))
