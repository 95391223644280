import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { fetchProducts } from '../../actions/ecommerce/products'
import { scrollToTop } from '../../utils'
import Page from '../../components/core/Page'
import ShopProductCard from '../../components/cards/ShopProductCard'
import WhyMisters from '../../components/others/WhyMisters'
import { createCartItem } from '../../actions/ecommerce/cart'
import AuthenticatedContainer from '../../components/containers/AuthenticatedContainer'
import Ecommerce from '../../services/Ecommerce'

class Shop extends Component {
  static fetching ({ dispatch }) {
    return [dispatch(fetchProducts())]
  };

    static propTypes = {
      products: PropTypes.object,
      fetchProducts: PropTypes.func
    };

    constructor (props) {
      super(props)

      this.state = {}
    }

    componentDidMount () {
      this.props.fetchProducts()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { products, cart, match, history } = this.props
      const { language } = match.params
      const prevLanguage = prevProps.match.params.language

      if (!this.props.products.isFetchingList &&
            prevProps.products.isFetchingList &&
            !this.props.products.error &&
            !this.props.products.retry) {
        scrollToTop()
        Ecommerce.shopPageView({ products: products.list })
      }

      if (!cart.isCreatingCartItem && prevProps.cart.isCreatingCartItem && !cart.error && !cart.retry) {
        history.push(`${language}/checkout`)
      }

      if (language !== prevLanguage) {
        this.props.fetchProducts()
      }
    }

    handleAddItemToCart = async (product) => {
      Ecommerce.addToCart({ products: [product] })
      this.props.createCartItem({ id: product.id, quantity: 1 })
    };

    handleOpenProductDetails = (product) => {
      const { match, history } = this.props
      Ecommerce.productClick({
        product,
        eventCallback: () => {
          history.push(`${match.params.language}/therapy/${product.product_id}`)
        }
      })
    };

    render () {
      const { products, match } = this.props

      return (
            <AuthenticatedContainer>
                <Page meta={{
                  title: 'Shop quality Misters health products',
                  path: `${match.params.language}/shop/`,
                  description: 'This space houses tried, trusted and tested Misters products formulated by experts and specialists of the field. Ideal recourse to boost immunity, manage hair loss, and happiness. Discover scientifically enriching, natural, ancient Ayurvedic herbs like Ashwagandha, Shilajit, Gokshura, Shatavari, Kaunch Beej Kesar and more, moulded for modern-day usage.'
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <h1 className="product-title">Make the right move</h1>
                                <p>The greatest portfolio ever assembled for the happiness of men</p>
                            </div>
                        </div>
                        <div className="row">
                            {
                                (products.list || []).filter(product => product.is_single_product).map(
                                  product => (
                                        <div key={product.id} className="col-lg-4 col-md-6 col-12 d-flex flex-column">
                                          <ShopProductCard product={product}
                                                           handleAddToCart={() => this.handleAddItemToCart(product)}
                                                           handleOpenProductDetails={() => this.handleOpenProductDetails(product)} />
                                        </div>
                                  )
                                )
                            }
                        </div>
                    </div>
                    <WhyMisters />
                    <div className="text-center p-5">Give a missed call to our Doctors at 011-41169222 for a Free Ayurvedic Consultation</div>
                </Page>
            </AuthenticatedContainer>
      )
    }
}

const mapStateToProps = (state) => ({
  products: state.ecommerce.products,
  cart: state.ecommerce.cart
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  fetchProducts,
  createCartItem
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Shop)
