import React from 'react'
import PropTypes from 'prop-types'
import * as queryString from 'query-string'
import { getPhoneNumberWithCountryCode } from '../../../utils'
import { bindActionCreators } from 'redux'
import { userLogin, userLoginOtp } from '../../../actions/accounts/profile'
import { connect } from 'react-redux'

import Button from '../../buttons/Button'
import { withRouter } from 'react-router'
import { getLanguage } from '../../../store_utils'
import LText from '../../core/LText'
import FormInput from '../../inputs/FormInput'
import Consent from '../../others/Consent'

class LoginBlurModal extends React.Component {
    static propTypes = {
      show: PropTypes.bool,
      handleCloseClick: PropTypes.func,
      login: PropTypes.object,
      userLogin: PropTypes.func,
      title: PropTypes.string,
      subtitle: PropTypes.string
    };

    static defaultProps = {
      title: 'Your Report is ready!',
      subtitle: 'Please Login to see your detailed report'
    }

    OTP_TIMEOUT = 30;

    state = {
      phone_number: '',
      otp: '',
      message: null,
      showOtpField: false,
      otpTimer: null
    };

    componentDidMount () {
    }

    componentWillUnmount () {
      clearInterval(this.intervalId)
    }

    otpTimerStart = () => {
      clearInterval(this.intervalId)
      this.setState({ otpTimer: this.OTP_TIMEOUT })
      this.intervalId = setInterval(() => {
        this.setState(
          prevState => ({ otpTimer: prevState.otpTimer - 1 }),
          () => {
            if (this.state.otpTimer <= 0) {
              clearInterval(this.intervalId)
            }
          }
        )
      }, 1000)
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { profile, history, history: { location: { search } }, match } = this.props

      if (!profile.isFetchingLogin && prevProps.profile.isFetchingLogin &&
            profile.error && !profile.retry && profile.message) {
        this.setState({ message: profile.message })
      }
      if (!profile.isFetchingLogin && prevProps.profile.isFetchingLogin && !profile.error && !profile.retry) {
        // this.props.handleLogin
      }

      if (!profile.isFetchingLoginOTP &&
            prevProps.profile.isFetchingLoginOTP &&
            profile.error &&
            !profile.retry &&
            profile.message) {
        this.setState({ message: profile.message })
      }
      if (!profile.isFetchingLoginOTP &&
            prevProps.profile.isFetchingLoginOTP &&
            !profile.error &&
            !profile.retry) {
        this.setState({ message: null, showOtpField: true })
        this.otpTimerStart()
      }
    }

    sendOTP = () => {
      this.props.userLoginOtp({ phone_number: getPhoneNumberWithCountryCode(this.state.phone_number) })
    };

    sendOTPOnCall = () => {
      this.props.userLoginOtp({
        phone_number: getPhoneNumberWithCountryCode(this.state.phone_number),
        is_otp_on_call: true
      })
    };

    login = () => {
      const { phone_number, otp } = this.state
      this.setState({ message: null })
      this.props.userLogin({ phone_number: getPhoneNumberWithCountryCode(phone_number), otp })
    };

    render () {
      const { phone_number, otp, message, showOtpField, otpTimer } = this.state

      if (this.props.show) {
        return (
                <div className='login-blur-modal-container'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 col-12 offset-lg-4 offset-md-3 offset-sm-2 py-5">
                                <h3 className="mb-2">{this.props.title}</h3>
                                <p className="mb-4 text-muted">{this.props.subtitle}</p>
                                <div className="pb-3">
                                    <FormInput type="number"
                                               placeholder={getLanguage('YOUR_10_DIGIT_MOBILE_NUMBER')}
                                               maxLength="10"
                                               autoFocus={true}
                                               error={message ? message.phone_number : ''}
                                               value={phone_number}
                                               onChange={(e) => this.setState({ phone_number: e.target.value })}
                                               onKeyPress={event => {
                                                 if (event.key === 'Enter') {
                                                   this.sendOTP()
                                                 }
                                               }}/>
                                </div>
                                {
                                    showOtpField &&
                                    <div className="pb-3">
                                        <FormInput type="password"
                                                   placeholder={getLanguage('YOUR_OTP')}
                                                   value={otp}
                                                   autoFocus={true}
                                                   error={message ? message.otp : ''}
                                                   onChange={(e) => this.setState({ otp: e.target.value })}
                                                   onKeyPress={event => {
                                                     if (event.key === 'Enter' && !!otp) {
                                                       this.login()
                                                     }
                                                   }}/>
                                    </div>
                                }
                                {!!message && !!message.message && <div className="error-text">{message.message}</div>}
                                {
                                    showOtpField && !!otpTimer &&
                                    <div className="otp-resend-text">
                                        <LText k="RESEND_OTP_TEXT" replaceKeys={[{ key: 'time', value: otpTimer }]}/>
                                    </div>
                                }
                                <Consent/>
                                <div className="login-form-footer">
                                    {
                                        !otp &&
                                        !showOtpField &&
                                        <Button onClick={this.sendOTP}
                                                block
                                                style={{ marginRight: 20, marginBottom: 20 }}>
                                            <LText k="SEND_OTP"/>
                                        </Button>
                                    }
                                    {
                                        !!otp &&
                                        <Button onClick={this.login}
                                                block
                                                style={{ marginRight: 20, marginBottom: 20 }}>
                                            <LText k="LOGIN"/>
                                        </Button>
                                    }
                                    {
                                        showOtpField && !otpTimer &&
                                        <div className="d-flex flex-column">
                                            <Button onClick={this.sendOTP} block
                                                    style={{ marginBottom: 15 }}>
                                                <LText k="RESEND_OTP"/>
                                            </Button>
                                            <Button variant="secondary" block
                                                    className="btn-get-otp-on-call"
                                                    onClick={this.sendOTPOnCall}>
                                                <LText k="GET_OTP_ON_CALL"/>
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
      }
      return null
    }
}

const mapStateToProps = (state) => ({
  profile: state.accounts.profile
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  userLogin,
  userLoginOtp
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(LoginBlurModal))
