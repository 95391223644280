import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Page from '../../components/core/Page'
import { Images } from '../../constants'
import TeamCard from '../../components/cards/TeamCard'
import CrossFadeImage from '../../components/others/CrossFadeImage'
import Button from '../../components/buttons/Button'
import WhyMisters from '../Category/common/WhyMisters'
import Testimonials from '../Category/common/Testimonials'

class ProductPage extends Component {
  componentDidMount () {
  }

  render () {
    const { match } = this.props

    return (
            <Page meta={{
              path: `${match.params.language}/about/`,
              title: 'Misters- men\'s sexual health platform',
              description: 'Misters is a Gurgaon-based health and confidence start-up catering to men’s sexual health and wellness using ayurvedic, allopathic and plant-based methods. In collaboration with medical experts, reliable information on erections, ejaculation, sexual desire and hair care awaits you here. Simple, personalized and result-oriented care amalgamating traditional knowledge and the best science has to offer is Misters’ forte. More than a website and company, Misters is a community. Let your journey commence, bon voyage!'
            }}>
                <div>
                    <div className="pages-container">
                        <div className="container">
                            <div className="row pt-5">
                                <div className="col-12 col-lg-10 offset-lg-1">
                                    <h1 className="text-center">सफ़ेद मूसली के फ़ायदे</h1>
                                </div>
                            </div>
                            <div className="row py-5">
                                <div className="col-md-9 col-12">
                                    <p>
                                        सफ़ेद मूसली को कई नामों से जाना जाता है। इसे संस्कृत में श्वेत मूसली के रूप में
                                        जाना
                                        जाता है; तमिल में तेनिरवी थांग; खिरुवा हिंदी में (विशेषकर उत्तर प्रदेश में);
                                        सफ़ेद
                                        मूसली हिंदी और मराठी दोनों में; गुजराती में ढोली मूसली और तेलुगु में तेदेला तदी
                                        गड्डालु।
                                        <br/>
                                        सफ़ेद मूसली एक जड़ी बूटी है जो भारत में एक पत्तेदार सब्ज़ी के रूप में उगाई और खाई
                                        जाती है। इसकी जड़ों का उपयोग औषधीय प्रयोजनों के लिए किया जाता है, सैक्स से
                                        सम्बंधित
                                        दिक्कतों को ठीक करने में। आइए जानते हैं कि सफ़ेद मूसली कैसे पुरुषों के लिए
                                        लाभदायक
                                        है:
                                    </p>
                                </div>
                                <div className="col-md-3 col-12">
                                    <img className="img-fluid" src="/web-assets/images/pages/step-1.png" alt="step 1"/>
                                </div>
                                <div className="col-12 text-center">
                                    <Button variant="red" onClick={() => {}}>
                                        Buy Now
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pages-bg-red text-white">
                        <div className="pages-container">
                            <div className="container">
                                <div className="row py-5">
                                    <div className="col-md-3 col-12">
                                        <img className="img-fluid" src="/web-assets/images/pages/step-3.png"
                                             alt="step 1"/>
                                    </div>
                                    <div className="col-md-9 col-12">
                                        <p>
                                            सफ़ेद मूसली की वाजीकरण (कामोद्दीपक) संपत्ति से स्तंभन दोष (इरेक्टाइल
                                            डिसफंक्शन)
                                            और
                                            नपुंसकता (इम्पोटेंस) या कम शक्ति (लो पोटेंसी) जैसी कुछ यौन समस्याओं को
                                            मिटाया जा
                                            सकता है।
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pages-bg-light-red">
                        <div className="pages-container">
                            <div className="container">
                                <div className="row py-5">
                                    <div className="col-md-9 col-12">
                                        <p>
                                            सफ़ेद मूसली प्रकृति में शुक्राल (स्पर्माटोजेनिक) है जिसकी वजह से इसे पुरुषों में ताकत बढ़ाने के लिए उपयोग किया जाता है।
                                        </p>
                                    </div>
                                    <div className="col-md-3 col-12">
                                        <img className="img-fluid" src="/web-assets/images/pages/step-3.png"
                                             alt="step 1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pages-bg-red text-white">
                        <div className="pages-container">
                            <div className="container">
                                <div className="row py-5">
                                    <div className="col-12">
                                        <h2 className="text-center">सफ़ेद मूसली का प्रॉडक्ट चुनते हुए ध्यान रखें</h2>
                                    </div>
                                    <div className="col-12">
                                        <p>
                                            सफ़ेद मूसली के गुणों का लाभ उठाने के लिए इसे कई प्रकार से लिया जा सकता है। वैसे तो पूरे पौधे में रोगनिवारक गुण होते हैं, लेकिन सफ़ेद मूसली के जड़ और बीज में पोषक तत्वों की अधिक मात्रा होती है। कई आयुर्वेदिक डॉक्टर 2 से 4 ग्राम सफ़ेद मूसली की जडों के चूर्ण के सेवन की सलाह देते हैं। समय बचाने के लिए सफ़ेद मूसली का सेवन कैप्सूल, या सिरप के रूप में भी किया जा सकता है।
                                        </p>
                                        <p>यदि आप इसके साथ साथ और भी अन्य जड़ीबूटियों के मिश्रण का लाभ उठाना चाहते हैं तो मिस्टर्स परफ़ॉर्म खरीद सकते हैं। इसकी केवल एक कैप्सूल प्रतिदिन रात के खाने के बाद लेने से आपकी ताकत में सुधार आएगा।</p>
                                    </div>
                                    <div className="col-12 text-center">
                                        <Button variant="secondary" onClick={() => {}}>
                                            Buy Now
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pages-bg-light-red">
                        <div className="pages-container">
                            <div className="container">
                                <div className="row pt-5">
                                    <div className="col-12">
                                        <h2 className="text-center">परफ़ॉर्म के फ़ायदे</h2>
                                    </div>
                                </div>
                                <div className="row py-5">
                                    <div className="col-12 text-center">
                                        <iframe width="570" height="300" src="https://www.youtube.com/embed/xwKTRMjigdk"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <WhyMisters showQuizButton={false}/>
                    <div className="pages-bg-red">
                        <div className="pages-container">
                            <div className="container">
                                <div className="row py-5">
                                    <div className="col-12">
                                        <img className="img-fluid"
                                             src={Images.bold.bold} alt="Bold"/>
                                    </div>
                                    <div className="col-12 text-center py-3">
                                        <h2 className="text-white">₹799</h2>
                                        <Button variant="secondary" onClick={() => {}}
                                                className="mr-sm-3 product-add-to-cart">
                                            Buy Now
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pages-bg-light-red">
                        <div className="pages-container">
                            <div className="container py-5">
                                <div className="row pb-5">
                                    <div className="col-12">
                                        <h2 className="text-center">आयुर्वेद क्यों ?</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12 text-center">
                                        <img className="w-100" src={Images.therapy.ayurvedaWork}
                                             alt="how does ayurveda work"/>
                                    </div>
                                    <div className="col-md-6 col-12 text-center">
                                        <p>
                                            आयुर्वेदिक विज्ञान शरीर और मन के साथ काम करता है और समग्र शारीरिक कार्यों को पूर्णता से देखता है। यह बीमारी के लक्षणों को ठीक करने के बजाय मूल कारण की रोकथाम और उपचार द्वारा काम करता है। आयुर्वेदिक जड़ी-बूटियों का सेवन आहर (भोजन की आदतें) और विहार (व्यायाम और योग) के साथ किया जाता है।
                                        </p>
                                        <p>
                                            हम बेहतर परिणाम के लिए स्वस्थ भोजन और शारीरिक व्यायाम के साथ संयुक्त आयुर्वेदिक उपचार के 45-60 दिनों की सलाह देते हैं। परफ़ॉर्म की 1 बोतल में 30 कैप्सूल होते हैं। 2 का पैक आपको जीवन के प्राकृतिक आयुर्वेदिक तरीके और स्वास्थ्य को आगे ले जाने में मदद करेगा।
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Testimonials className="py-4" bgLight={true}/>
                </div>
            </Page>
    )
  }
}

const mapStateToProps = (state) => ({})

const matchDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(ProductPage)
