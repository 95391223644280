import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  createCartItem,
  deleteCartItem,
  updateCart,
  fetchCart,
  fetchConfig,
  updateCartCoupon,
  updateCartPaymentMethod
} from '../../../actions/ecommerce/cart'
import { createOrder, updateNewOrder } from '../../../actions/ecommerce/orders'
import CartItemCard from '../../../components/cards/CartItemCard'
import OrderSummaryCard from '../../../components/cards/OrderSummaryCard'
import Page from '../../../components/core/Page'
import LText from '../../../components/core/LText'
import AuthenticatedContainer from '../../../components/containers/AuthenticatedContainer'
import Ripple from '../../../components/loadings/Ripple'
import { getMessages, getPrice, getSearchParams } from '../../../utils'
import CartState from '../../../components/others/CartState'
import { Images, paymentMethods, paymentTypes } from '../../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag'
import CouponModal from '../../../components/modals/CouponModal'
import CheckoutHelp from '../../../components/others/CheckoutHelp'
import FormInput from '../../../components/inputs/FormInput'
import Ecommerce from '../../../services/Ecommerce'

class Cart extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    cart: PropTypes.object,
    updateNewOrder: PropTypes.func,
    createCartItem: PropTypes.func,
    fetchCart: PropTypes.func,
    deleteCartItem: PropTypes.func,
    updateCart: PropTypes.func,
    updateCartCoupon: PropTypes.func,
    updateCartPaymentMethod: PropTypes.func
  };

  componentDidMount () {
  }

  constructor (props) {
    super(props)

    this.state = {
      isFetchingCart: true,
      coupon_code: '',
      coupon_message: ''
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { cart: { detail, isFetching, isUpdatingCart, isCouponUpdating, message, error, retry } } = this.props

    const querySearch = getSearchParams(this.props.location.search)

    if (!isFetching && prevProps.cart.isFetching && !error && !retry) {
      this.setState({ isFetchingCart: false })
    }

    if (!isFetching && prevProps.cart.isFetching && error && !retry) {
      this.setState({ isFetchingCart: false })
    }

    if (!isFetching && prevProps.cart.isFetching && !error && !retry && !!detail.items &&
      !!detail.items.length && querySearch.skp === '1') {
      this.handleProceedClick()
    }

    if (!isCouponUpdating && prevProps.cart.isCouponUpdating && error && !retry) {
      this.setState({ coupon_message: getMessages(message)[0] })
    }
  }

  handleLoginSuccess = () => {
    const { search } = this.props.history.location
    this.props.fetchCart(search)
  };

  deleteCartItem = itemId => {
    this.props.deleteCartItem(itemId)
  };

  createCartItem = (item, quantity) => {
    this.props.createCartItem({ id: item.id, quantity })
  };

  handleProceedClick = () => {
    const { history, match, updateNewOrder, cart } = this.props
    const querySearch = this.props.location.search

    updateNewOrder({ cart: cart.detail })

    Ecommerce.onCheckout({
      items: cart.detail.items,
      step: 1,
      option: 'proceed',
      eventCallback: () => {
        const url = `${match.params.language}/checkout/address${querySearch}`
        history.push(url)
      }
    })
  };

  handleRemoveCoupon = () => {
    this.props.updateCartCoupon({ coupon_code: null })
    this.setState({ couponModalOpen: false, coupon_code: '' })
  };

  handleUpdateCartCoupon = () => {
    const { couponModalOpen, coupon_code } = this.state
    this.props.updateCartCoupon({ coupon_code })
    // if (!couponModalOpen) {
    //   this.setState({ couponModalOpen: true })
    // } else {
    //   this.props.updateCartCoupon({ coupon_code })
    // }
  };

  handleOffer2Click = () => {
    this.props.updateCart({ coupon_code: 'NEW5' })
  }

  handleChangePaymentMethod = (value) => {
    // console.warn(payment_type);
    if (value === paymentTypes.cod) {
      this.props.updateCart({ payment_type: value, payment_method: value })
    } else if (Object.keys(paymentMethods).includes(value)) {
      this.props.updateCart({ payment_type: paymentTypes.online, payment_method: value })
    }
  }

  render () {
    const { cart: { detail, isFetching }, match, history } = this.props
    const { isFetchingCart } = this.state

    const order_total = parseFloat(detail.subtotal)
    const total_discount = parseFloat(detail.total_mrp) - parseFloat(detail.subtotal)

    return (
      <AuthenticatedContainer handleLoginSuccess={this.handleLoginSuccess}>
        <Page meta={{
          title: 'Shop quality Misters health products',
          path: `${match.params.language}/checkout/`,
          description: 'This space houses tried, trusted and tested Misters products formulated by experts and specialists of the field. Ideal recourse to boost immunity, manage hair loss, maintain erections and control ejaculation can be found here. Discover scientifically enriching, natural, ancient Ayurvedic herbs like Ashwagandha, Shilajit, Gokshura, Shatavari, Kaunch Beej Kesar and more, moulded for modern-day usage. Some among their vast range of benefits include an enhancement of libido, improved performance in bed, strong penile tissue and healthy testosterone levels.'
        }} footerVariant={'checkout'} showNavbar={false} showWhatsapp={false}>
          <div className="container">
            <div className="cart">
              <CartState step={1}/>
              <div className="py-sm-5 py-2"/>
              {
                isFetchingCart &&
                <div className="text-center"><Ripple show={true}/></div>
              }
              {
                !isFetchingCart && (!detail.items || (!!detail.items && !detail.items.length)) &&
                <div className="empty-cart">
                  <img src={Images.emptyCart} alt="Empty Cart"/>
                  <p className="empty-message"><LText k="CART_IS_EMPTY"/></p>
                  <button className='btn-m-red btn-m'
                          onClick={() => history.push(`${match.params.language}/solutions/`)}>
                    <LText k="SHOP_NOW"/>
                  </button>
                </div>
              }
              {
                !isFetchingCart && !!detail.items &&
                !!detail.items.length &&
                <div className="row">
                  <div className="col-lg-8 order-lg-1 col-12">
                    <ul className="checkout-offers-cart">
                      <li>
                        <img src={Images.logo.x1} alt="M"/>
                        <span>
                            OFFER: Get Extra 5% off on your first order
                        </span>
                        {
                          !!detail.coupon && detail.coupon.code === 'NEW5'
                            ? <button className="btn-m"
                                    disabled={true}
                                    onClick={this.handleOffer2Click}>Applied</button>
                            : <button className="btn-m btn-m-secondary"
                                    onClick={this.handleOffer2Click}>Avail Now</button>
                        }
                      </li>
                    </ul>
                    {
                      detail.items.map(
                        item => <CartItemCard key={item.id} item={item}
                                              is_multiple_quantity_discount_allowed={detail.is_multiple_quantity_discount_allowed}
                                              createCartItem={this.createCartItem}
                                              deleteCartItem={this.deleteCartItem}
                        />
                      )
                    }
                  </div>
                  <div className="col-lg-4 order-lg-2 col-12">
                    <div className="cart-summary">
                      <ul className='cart-summary-items-container'>
                        <li>
                          <span className='item-title'>Delivery Charges</span>
                          <span className='item-price text-primary-o-1 font-weight-normal'>
                                                        <span className='strike-price'>50</span>
                            {'  FREE'}
                                                    </span>
                        </li>
                        {
                          !!total_discount &&
                          <li>
                            <div className="item-left">
                              <span className='item-title'>Discount</span>
                            </div>
                            <span
                              className='item-price'>- ₹{getPrice(total_discount).toFixed(0)}</span>
                          </li>
                        }
                        <li>
                          {/* <div className="cart-payment-select-container"> */}
                          {/*  <span className='item-title'>Payment Method</span> */}
                          {/*  <div className='cart-payment-select'> */}
                          {/*    <button className={`${detail.payment_type === 'online' ? 'active' : ''}`} */}
                          {/*            onClick={(e) => this.handleChangePaymentMethod(paymentMethods.card)}> */}
                          {/*      Prepaid */}
                          {/*    </button> */}
                          {/*    <button className={`${detail.payment_type === 'cod' ? 'active' : ''}`} */}
                          {/*            onClick={(e) => this.handleChangePaymentMethod(paymentTypes.cod)}> */}
                          {/*      Others */}
                          {/*    </button> */}
                          {/*  </div> */}
                          {/* </div> */}
                          {/* <select name="payment_type" id="payment_type" */}
                          {/*        value={detail.payment_type === 'cod' ? detail.payment_type : (detail.payment_method || paymentMethods.wallet)} */}
                          {/*        onChange={(e) => this.handleChangePaymentMethod(e.target.value)}> */}
                          {/*  <option value={paymentTypes.cod}>COD</option> */}
                          {/*  <option value={paymentMethods.wallet}>Wallet</option> */}
                          {/*  <option value={paymentMethods.card}>Card</option> */}
                          {/*  <option value={paymentMethods.upi}>UPI/QR</option> */}
                          {/*  <option value={paymentMethods.netbanking}>Netbanking</option> */}
                          {/*  <option value={paymentMethods.emi}>EMI</option> */}
                          {/* </select> */}
                        </li>
                        <li>
                          <ul className="checkout-offers-cart">
                            <li>
                              <img src={Images.logo.x1} alt="M"/>
                              <span>
                                  Additional 5% off on all prepaid orders
                              </span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <div>
                        {
                          !!detail.coupon &&
                          <div className="checkout-coupon">
                            <span>Coupon <strong>{detail.coupon.code}</strong> is applied.</span>
                            <button onClick={this.handleRemoveCoupon}>Remove</button>
                          </div>
                        }
                        {
                          !detail.coupon &&
                          <div className="checkout-coupon">
                            {/* { */}
                            {/*  !this.state.couponModalOpen && */}
                            {/*  <span className="d-flex align-items-center"> */}
                            {/*                                    <FontAwesomeIcon icon={faTag} size={'1x'} */}
                            {/*                                                     className="mr-2"/> */}
                            {/*                                    Have a coupon code? */}
                            {/*                                </span> */}
                            {/* } */}
                            {/* { */}
                            {/*  !!this.state.couponModalOpen && */}
                              <FormInput type="text"
                                         placeholder="Apply discount coupon here."
                                         value={this.state.coupon_code}
                                         autoFocus={true}
                                         onChange={(e) => this.setState({
                                           coupon_message: '',
                                           coupon_code: e.target.value
                                         })}/>
                            {/* } */}
                            {
                              !!this.state.coupon_code &&
                              <button onClick={this.handleUpdateCartCoupon}>Apply</button>
                            }
                          </div>
                        }
                        {
                          !!this.state.coupon_message &&
                          <div className="checkout-coupon"><small
                            style={{ color: 'red' }}>{this.state.coupon_message}</small></div>
                        }
                      </div>
                      <div className="checkout-hr"/>
                      <div className="checkout-summary-total">
                        <div className="checkout-summary-total-title">Total</div>
                        <span className='checkout-summary-total-price'>
                                                    {
                                                      total_discount > 0
                                                        ? <React.Fragment>
                                                                <span className="strike-price">
                                                                    {getPrice(order_total + total_discount).toFixed(0)}
                                                                </span>
                                                          <span
                                                            className="currency-price">{getPrice(order_total).toFixed(0)}</span>
                                                        </React.Fragment>
                                                        : <span
                                                          className="currency-price">{getPrice(order_total).toFixed(0)}</span>
                                                    }
                                                </span>
                      </div>
                      {
                        !!total_discount &&
                        <div className="checkout-summary-savings">
                          <img src={Images.logo.x1} alt="M"/>
                          Savings of ₹{getPrice(total_discount).toFixed(0)} on the bill
                        </div>
                      }
                      <button className='checkout-button btn-m-red btn-m d-sm-block d-none'
                              onClick={this.handleProceedClick}>
                        <LText k="PROCEED_TO_BUY"/>
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
            {
              !isFetchingCart && !!detail.items &&
              !!detail.items.length &&
              <div className="checkout-footer-sticky d-sm-none d-block">
                <button className='checkout-button btn-m-red btn-m'
                        onClick={this.handleProceedClick}>
                  <LText k="PROCEED_TO_BUY"/>
                </button>
                <CheckoutHelp/>
              </div>
            }
          </div>
        </Page>
      </AuthenticatedContainer>
    )
  }
}

const mapStateToProps = state => ({
  cart: state.ecommerce.cart

})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchCart,
  createCartItem,
  deleteCartItem,
  updateCart,
  createOrder,
  fetchConfig,
  updateCartCoupon,
  updateNewOrder,
  updateCartPaymentMethod
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Cart)
