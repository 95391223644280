import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import AccordionCard from '../../../screens/Category/common/AccordionCard'
import { getLanguageTranslation } from '../../../utils'

const ProductFaq = ({ faqs, match }) => {
  if (!faqs.length) return null
  return (
    <div className="bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 col-12 offset-lg-2 offset-md-1 py-5">
            <div className="product-faqs">
              <h2 className="home-page-subtitle text-center mb-4">FAQs</h2>
              {
                faqs.map(
                  (faq, index) => (
                    <AccordionCard title={getLanguageTranslation(faq, 'title', match.params.language)}
                                   key={`faq-${index}`} arrow>
                      <p className="product-faqs-description">
                        {getLanguageTranslation(faq, 'description', match.params.language)}
                      </p>
                    </AccordionCard>
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProductFaq.propTypes = {
  match: PropTypes.object,
  faqs: PropTypes.array
}

ProductFaq.defaultProps = {
  faqs: []
}

export default withRouter(ProductFaq)
