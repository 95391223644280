import React from 'react'
import moment from 'moment'

import { truncChars } from '../../../utils'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

class PostSmallCard extends React.Component {
    static propTypes = {
      className: PropTypes.string,
      onWhiteBackground: PropTypes.bool
    };

    static defaultProps = {
      className: '',
      onWhiteBackground: false
    };

    componentDidMount () {

    }

    render () {
      const { title, feature_image, authors, published_at, match, slug, className, onWhiteBackground } = this.props
      const langParam = match.params.language || '/en'
      return (
            <a className={`post-small-card ${onWhiteBackground ? 'post-on-white-background' : ''} `}
               target="_blank" hrefLang={langParam}
               href={`https://misters.in${langParam}/blog/${slug}`}>
                {
                    feature_image &&
                    <div className="post-small-card-image">
                        <img data-src={feature_image} className="lazyload" alt={title}/>
                    </div>
                }
                <div className="post-small-card-content">
                    <h5 className="post-small-card-title">{truncChars(title, 40)}</h5>
                    <p className="post-small-card-footer">
                        {moment(published_at).format('MMM DD, YYYY')} · {authors[0].name}
                    </p>
                </div>
            </a>
      )
    }
}

export default withRouter(PostSmallCard)
