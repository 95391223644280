import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { createAddress, deleteAddress, updateAddress } from '../../../actions/ecommerce/address'
import { connect } from 'react-redux'
import FormInput from '../../inputs/FormInput'
import {
  get10DigitNumber,
  getPhoneNumber,
  getPhoneNumberWithoutCountryCode
} from '../../../utils'
import LText from '../../core/LText'
import api from '../../../actions/api'

class AddressItemCard extends Component {
    static propTypes = {
      data: PropTypes.object,
      onClick: PropTypes.func,
      active: PropTypes.bool,
      showCancel: PropTypes.bool,
      saveAndContinue: PropTypes.bool,
      updateAddress: PropTypes.func,
      createAddress: PropTypes.func,
      deleteAddress: PropTypes.func,
      handleDeleteClick: PropTypes.func,
      handleCancelClick: PropTypes.func,
      handleEditClick: PropTypes.func,
      showDeliverHere: PropTypes.bool,
      handleAddressCreate: PropTypes.func,
      handleDeliverClick: PropTypes.func
    };

    static defaultProps = {
      active: false,
      showDeliverHere: true,
      saveAndContinue: false,
      showCancel: true
    };

    constructor (props) {
      super(props)
      const { phone_number } = props.profile.detail
      this.state = {
        address: props.data || { phone: getPhoneNumberWithoutCountryCode(phone_number) },
        isCreateCard: !props.data
      }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { detail, isUpdating, isCreating, error, retry, message } = this.props.address
      const { data = {}, editAddressId, saveAndContinue } = this.props
      const { isCreateCard } = this.state

      if (editAddressId === data.id && editAddressId !== prevProps.editAddressId) {
        this.setState({
          address: data,
          isCreateCard: !data
        })
      }

      if (!isUpdating && prevProps.address.isUpdating && !error && !retry && (data.id === editAddressId)) {
        if (saveAndContinue) {
          this.props.handleDeliverClick(detail)
        }
        this.props.handleCancelClick()
      }
      if (!isUpdating && prevProps.address.isUpdating && error && !retry && (data.id === editAddressId)) {
        this.setState({ message })
      }
      if (!isCreating && prevProps.address.isCreating && !error && !retry && isCreateCard) {
        if (saveAndContinue) {
          this.props.handleDeliverClick(detail)
        }
        this.props.handleAddressCreate(detail)
      }
      if (!isCreating && prevProps.address.isCreating && error && !retry && isCreateCard) {
        this.setState({ message })
      }
    }

    handleDeliverClick = () => {
      this.props.handleDeliverClick(this.props.data.id)
    };

    handleSaveClick = () => {
      const { address } = this.state
      address.phone = getPhoneNumber(address.phone)
      if (!this.props.data) {
        this.props.createAddress(address)
      } else {
        this.props.updateAddress(address)
      }
    };

    handleChangePinCode = async (e) => {
      const pincode = e.target.value

      this.setState({
        address: {
          ...this.state.address,
          zipcode: pincode
        }
      })

      if (pincode.length === 6) {
        try {
          const res = await api.ecommerce.address.pincode({ pincode })
          const result = await res.json()
          if (res.status === 200) {
            this.setState({
              address: {
                ...this.state.address,
                zipcode: result.pincode,
                city: result.district,
                state: result.state
              },
              message: {}
            })
          } else {
            this.setState({
              address: {
                ...this.state.address,
                zipcode: pincode,
                city: '',
                state: ''
              },
              message: {
                zipcode: [result.detail]
              }
            })
          }
        } catch (e) {
          this.setState({
            address: {
              ...this.state.address,
              zipcode: pincode,
              city: '',
              state: ''
            }
          })
        }
      }
    };

    render () {
      const { data, showDeliverHere, active, editAddressId, saveAndContinue, showCancel } = this.props
      const { isCreateCard, message, address } = this.state

      if (isCreateCard || (editAddressId === data.id)) {
        return (
                <div className="address-item-card">
                    <div className="row">
                        <div className="col-sm-6 col-12 pb-2 pr-sm-1">
                            <FormInput placeholder='Name*'
                                       value={address.name || ''}
                                       error={message && message.name}
                                       autoComplete="name"
                                       onChange={(e) => this.setState({
                                         address: {
                                           ...address,
                                           name: e.target.value
                                         }
                                       })}/>
                        </div>
                        <div className="col-sm-6 col-12 pb-2 pl-sm-1">
                            <FormInput placeholder='10-digit mobile number*'
                                       value={get10DigitNumber(address.phone || '')}
                                       error={message && message.phone}
                                       autoComplete="tel"
                                       onChange={(e) => this.setState({
                                         address: {
                                           ...address,
                                           phone: e.target.value
                                         }
                                       })}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 pb-2 pr-1">
                            <FormInput placeholder='Pincode*' maxLength="6"
                                       value={address.zipcode || ''}
                                       error={message && message.zipcode}
                                       autoComplete="shipping postal-code"
                                       onChange={(e) => this.handleChangePinCode(e)}/>
                        </div>
                        <div className="col-6 pb-2 pl-1">
                            <FormInput placeholder='State' value={address.state || ''} disabled={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 pb-2">
                            <FormInput placeholder='City*'
                                       value={address.city || ''}
                                       error={message && message.city}
                                       onChange={(e) => this.setState({
                                         address: {
                                           ...address,
                                           city: e.target.value
                                         }
                                       })}/>
                        </div>
                    </div>
                    <div className="row pb-2">
                        <div className="col-12">
                            <FormInput placeholder='Flat, House no., Building, Company, Apartment*'
                                       value={address.street || ''}
                                       error={message && message.street}
                                       autoComplete="shipping address-line1"
                                       onChange={(e) => this.setState({
                                         address: {
                                           ...address,
                                           street: e.target.value
                                         }
                                       })}/>
                        </div>
                    </div>
                    <div className="row pb-2">
                        <div className="col-12">
                            <FormInput placeholder='Area, Colony, Street, Sector, Village'
                                       value={address.street2 || ''}
                                       error={message && message.street2}
                                       autoComplete="shipping address-line2"
                                       onChange={(e) => this.setState({
                                         address: {
                                           ...address,
                                           street2: e.target.value
                                         }
                                       })}/>
                        </div>
                    </div>
                    <div className="row pb-2">
                        <div className="col-12">
                            <FormInput placeholder='Landmark e.g. Near AIIMS Fliover, etc.'
                                       value={address.landmark || ''}
                                       error={message && message.landmark}
                                       onChange={(e) => this.setState({
                                         address: {
                                           ...address,
                                           landmark: e.target.value
                                         }
                                       })}/>
                        </div>
                    </div>
                    <div className="row pb-2">
                        <div className="col-12">
                            <FormInput placeholder='Email Address'
                                       type="email"
                                       value={address.email || ''}
                                       error={message && message.email}
                                       autoComplete="email"
                                       onChange={(e) => this.setState({
                                         address: {
                                           ...address,
                                           email: e.target.value
                                         }
                                       })}/>
                        </div>
                    </div>
                    <div>
                        <button className='save-button btn-m btn-m-red'
                                onClick={this.handleSaveClick}>
                            {!saveAndContinue
                              ? <LText k="SAVE_ADDRESS"/>
                              : <LText k="SAVE_AND_CONTINUE"/>
                            }
                        </button>
                        {
                            showCancel &&
                            <button className='deliver-cancel-button' onClick={this.props.handleCancelClick}>
                                <LText k="CANCEL"/>
                            </button>
                        }
                        {
                            !isCreateCard &&
                            <button className='deliver-cancel-button'
                                    onClick={this.props.handleDeleteClick}>
                                <LText k="DELETE"/>
                            </button>
                        }
                    </div>
                </div>
        )
      }
      return (
            <div className={`address-item-card ${active ? 'active' : ''}`}>
                <button className='edit-button btn-m btn-m-red' onClick={this.props.handleEditClick}>
                    <LText k="EDIT"/>
                </button>
                <h5 className='address-item-title'>{data.name}, {data.phone}</h5>
                <p className='address-item-description'>
                    {data.street}, {!!data.street2 && data.street2 + ', '}
                    {!!data.landmark && data.landmark + ', '}
                    {data.city}, {data.state}, {data.zipcode}
                </p>
                {
                    showDeliverHere &&
                    <button className='deliver-button btn-m btn-m-red'
                            onClick={this.handleDeliverClick}><LText k="DELIVER_TO_THIS_ADDRESS"/></button>
                }
            </div>
      )
    }
}

const mapStateToProps = state => ({
  address: state.ecommerce.address,
  profile: state.accounts.profile
})

const matchDispatchToProps = dispatch => bindActionCreators({
  updateAddress,
  createAddress,
  deleteAddress
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(AddressItemCard)
