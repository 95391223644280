import React from 'react'
import PropTypes from 'prop-types'
import { getRatingColor } from '../../../constants'

const ProgressGraph = function ({ progress = 0, className = '' }) {
  return (
        <div className={`progress-bar-custom ${className}`}>
            <div className="progress-bar-custom-fill"
                 style={{ width: `${progress}%`, backgroundColor: getRatingColor(progress / 10) }}/>
        </div>
  )
}

ProgressGraph.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}

ProgressGraph.defaultProps = {
  className: ''
}

export default ProgressGraph
