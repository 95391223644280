// External Imports
import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { withRouter } from 'react-router'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// My Imports
import {
  SEX_QUIZ_FIELD_ID,
  ED_FIELD_ID,
  HAIRFALL_FIELD_ID,
  PME_FIELD_ID,
  Images, USER, SEX_QUIZ_BOT_FORM_ID, PORN_QUIZ_BOT_FORM_ID, PORN_QUIZ_FIELD_ID
} from '../../constants'
import {
  assignBotFieldUser,
  fetchBot,
  fetchBotBack, getBotFormMetaData,
  reassignBotFieldUser,
  updateBot
} from '../../actions/bot/bot'
import HomeCard from '../../components/cards/HomeCard'
import Page from '../../components/core/Page'
import { getSearchParams, scrollToTop } from '../../utils'
import ProgressGraph from '../../components/graphs/ProgressGraph'
import { anonymousLogin } from '../../actions/accounts/profile'
import AuthenticatedContainer from '../../components/containers/AuthenticatedContainer'
import LoginBlurModal from '../../components/modals/LoginBlurModal'
import ExitQuizModal from '../../components/modals/ExitQuizModal'

class Quiz extends Component {
    static propTypes = {
      bot: PropTypes.object,
      profile: PropTypes.object,
      location: PropTypes.object,
      fetchBot: PropTypes.func,
      updateBot: PropTypes.func,
      reassignBotFieldUser: PropTypes.func,
      getBotFormMetaData: PropTypes.func,
      anonymousLogin: PropTypes.func
    };

    state = {
      quizStarted: false,
      quizEnding: false,
      show: false
    };

    static fetching = ({ match, querySearch, dispatch }) => {
      const { type } = match.params
      let botFormId = querySearch.bot_form_id
      switch (type) {
        case 'sex':
          botFormId = SEX_QUIZ_BOT_FORM_ID
          break
        case 'porn':
          botFormId = PORN_QUIZ_BOT_FORM_ID
          break
      }
      return [
        dispatch(getBotFormMetaData({ bot_form_id: botFormId }))
      ]
    }

    componentDidMount () {
      const queryParams = getSearchParams(this.props.location.search)
      if (queryParams.campaignId) {
        sessionStorage.setItem('campaignId', queryParams.campaignId)
      }
      this.props.getBotFormMetaData({ bot_form_id: queryParams.bot_form_id })
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { history, match, bot } = this.props
      if (!bot.isUpdating && prevProps.bot.isUpdating && !bot.error && !bot.retry) {
        const data = this.getItem()
        const { extra_data = {} } = data
        const { data: prevData = {} } = prevProps.bot
        if (extra_data.report_id) {
          history.push(`${match.params.language}/reports/?id=${extra_data.report_id}&step_name=report`)
        } else if (extra_data.feedback_id) {
          history.push(`${match.params.language}/feedback/?id=${extra_data.feedback_id}`)
        }
        if (prevData.bot_field) {
          const { web_actions = '' } = prevData.bot_field
          if (web_actions.startsWith('LAUNCHPAGE')) {
            const launchPath = web_actions.slice(11, web_actions.length - 1)
            history.push(`${match.params.language}${launchPath}`)
          }
        }
      }
    }

    handleLoginSuccess = () => {
      this.handleStartQuiz()
    };

    handleStartQuiz = () => {
      const { match, location } = this.props
      const { type } = match.params
      const querySearch = getSearchParams(location.search)

      switch (type) {
        case 'sex':
          this.props.assignBotFieldUser({ field_id: SEX_QUIZ_FIELD_ID, bot_form_id: SEX_QUIZ_BOT_FORM_ID })
          break
        case 'porn':
          this.props.assignBotFieldUser({ field_id: PORN_QUIZ_FIELD_ID, bot_form_id: PORN_QUIZ_BOT_FORM_ID })
          break
        case 'custom':
          if (querySearch.field_id && querySearch.bot_form_id) {
            this.props.assignBotFieldUser({
              field_id: querySearch.field_id,
              bot_form_id: querySearch.bot_form_id
            })
          }
          break
      }
      this.setState({ quizStarted: true })
    };

    getItem = () => {
      const { data } = this.props.bot
      let item = null
      if (Object.keys(data).length > 0) {
        item = data
      }
      return item
    };

    getProgress = () => {
      const item = this.getItem()
      if (item && [HAIRFALL_FIELD_ID, ED_FIELD_ID, PME_FIELD_ID].indexOf(item.bot_field.field_id) !== -1) {
        return 0
      }
      return item ? item.bot_field.progress : 0
    };

    handleSendClick = (responseId, data) => {
      const { match } = this.props
      const { type } = match.params
      const item = this.getItem()
      const dataLayerData = {
        event: `quiz_${type}`,
        step_name: item.bot_field.field_id,
        value: JSON.stringify(data)
      }
      this.props.updateBot({ id: responseId, ...data })
      try {
        dataLayer.push(dataLayerData)
      } catch (e) {
      }
      sessionStorage.setItem('ga_quiz_event', `quiz_${type}`)
      scrollToTop(700)
    };

    handleFetchBotBack = (e) => {
      e.preventDefault()
      this.props.fetchBotBack()
    };

    handleExitQuiz = () => {
      const { match, history } = this.props
      // console.warn(this.props);
      history.push(`${match.params.language}/solutions/`)
    };

    renderQuiz = () => {
      const { match, profile, history } = this.props
      const { metaData } = this.props.bot
      const { type } = match.params
      const item = this.getItem()
      const progress = this.getProgress()
      const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email

      return (
        <Page showNavbar={false} showFooter={false}
              meta={{
                title: metaData.meta_title,
                path: `${match.params.language}/quiz/${type}`,
                description: metaData.meta_description,
                image: metaData.image_url
              }}>
            <AuthenticatedContainer handleLoginSuccess={this.handleLoginSuccess}>
                    <div className="quiz-container">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center pt-3">
                                    {
                                        item &&
                                        progress !== 0 &&
                                        <a href="#" className='quiz-go-back' onClick={this.handleFetchBotBack}>
                                            <FontAwesomeIcon icon={faChevronLeft} size={'sm'}/>
                                            {/* <span>back</span> */}
                                        </a>
                                    }
                                    <img className="quiz-logo" src={Images.logo.big1x} alt=""/>
                                    <button className="quiz-exit-button"
                                            onClick={() => this.setState({ show: true })}>
                                        <FontAwesomeIcon icon={faTimes} size="sm"/>
                                    </button>
                                </div>
                            </div>
                            {
                                item &&
                                <div className='quiz-progress-container'>
                                    <div className="quiz-progress-text">{parseInt(progress)}% completed</div>
                                    <div className="quiz-progress">
                                        <ProgressGraph progress={progress}/>
                                        {/* <ProgressBar striped animated now={progress} color={'red'}/> */}
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-12">
                                    <div className='d-flex flex-column pt-2 quiz'
                                         style={{ minHeight: '70vh' }}>
                                        {
                                            item &&
                                            <HomeCard key={item.id}
                                                      data={item}
                                                      progress={progress}
                                                      handleSendClick={this.handleSendClick}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            !isAuthenticated &&
                            <LoginBlurModal show={true} title={'Please login to continue to the quiz'} subtitle={''}/>
                        }
                        <ExitQuizModal show={this.state.show}
                                       handleContinue={() => this.setState({ show: false })}
                                       handleExit={this.handleExitQuiz} />
                    </div>
            </AuthenticatedContainer>
        </Page>
      )
    };

    render () {
      return this.renderQuiz()
    }
}

const mapStateToProps = (state) => ({
  bot: state.bot.bot,
  profile: state.accounts.profile
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  fetchBot,
  fetchBotBack,
  updateBot,
  reassignBotFieldUser,
  assignBotFieldUser,
  getBotFormMetaData,
  anonymousLogin
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Quiz))
