import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class Ripple extends PureComponent {
    static propTypes = {
      show: PropTypes.bool
    };

    static defaultProps = {
      show: true
    };

    state = {
      className: 'appear',
      show: true
    };

    componentDidMount () {
      this.setState({ show: this.props.show })
    };

    render () {
      if (!this.state.show) return null

      return (
            <div className="lds-ripple">
                <div/>
                <div/>
            </div>
      )
    }
}
