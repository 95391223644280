import api from '../api'
export const FETCH_FEEDBACK = 'FETCH_FEEDBACK'
export const FETCH_FEEDBACK_RESULT = 'FETCH_FEEDBACK_RESULT'
export const FETCH_FEEDBACK_ERROR = 'FETCH_FEEDBACK_ERROR'
export const FETCH_FEEDBACK_RETRY = 'FETCH_FEEDBACK_RETRY'

export const fetchFeedback = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_FEEDBACK })
    const res = await api.report.feedback.get(data)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_FEEDBACK_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_FEEDBACK_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_FEEDBACK_RETRY, message: e.message })
  }
}
