import {
  FETCH_WISHLIST,
  FETCH_WISHLIST_RESULT,
  FETCH_WISHLIST_ERROR,
  FETCH_WISHLIST_RETRY,

  UPDATE_WISHLIST,
  UPDATE_WISHLIST_RESULT,
  UPDATE_WISHLIST_ERROR,
  UPDATE_WISHLIST_RETRY
} from '../../actions/ecommerce/wishlist'

const initialState = {
  detail: {},
  isFetching: false,
  isUpdating: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WISHLIST:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_WISHLIST_RESULT:
      return { ...state, isFetching: false, detail: action.data }
    case FETCH_WISHLIST_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_WISHLIST_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case UPDATE_WISHLIST:
      return { ...state, isUpdating: true, error: false, retry: false, message: null }
    case UPDATE_WISHLIST_RESULT:
      return { ...state, isUpdating: false, detail: action.data }
    case UPDATE_WISHLIST_ERROR:
      return { ...state, isUpdating: false, error: true, message: action.message }
    case UPDATE_WISHLIST_RETRY:
      return { ...state, isUpdating: false, retry: true, message: action.message }

    default:
      return { ...state }
  }
}

export default reducer
