import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../../components/core/Page'
import { updateUserProfile } from '../../../actions/accounts/profile'
import { fetchOrders } from '../../../actions/ecommerce/orders'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { Images, ORDER_STATUS } from '../../../constants'
import { getPrice } from '../../../utils'

class Orders extends PureComponent {
    static propTypes = {
      profile: PropTypes.object,
      orders: PropTypes.object,
      updateUserProfile: PropTypes.func,
      fetchOrders: PropTypes.func
    };

    constructor (props) {
      super(props)
    }

    componentDidMount () {
      this.props.fetchOrders()
    }

    render () {
      const { orders, match } = this.props

      return (
            <Page meta={{ path: `${match.params.language}/profile/orders` }}>
                <div className='profile-container'>
                    <div className="py-3"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="py-4 text-center">Your Orders</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    orders.list.map(
                                      order => {
                                        const statusKey = (order.status || '').toUpperCase()
                                        const statusColor = ORDER_STATUS[statusKey] ? ORDER_STATUS[statusKey].color : '#0E7909'
                                        const statusStyle = { color: statusColor }
                                        return (
                                                <NavLink to={`${match.params.language}/profile/orders/${order.cid}`}
                                                         className="orders-list-item-card" key={order.id}>
                                                    <div className="orders-list-item-card-icon">
                                                        <img src={Images.orders.order} alt=""/>
                                                    </div>
                                                    <div className="orders-list-item-card-content">
                                                        {/* <h4>{order.cart.items.map(i => i.item.title).join(', ')}</h4> */}
                                                        <h4>{order.title}</h4>
                                                        <p>{moment(order.created_at).format('DD MMM YYYY')}</p>
                                                        <p>#{order.cid}</p>
                                                        <div className="orders-list-item-status"
                                                             style={statusStyle}>
                                                            {order.status}
                                                        </div>
                                                        <div className="orders-list-item-price">
                                                            ₹{getPrice(order.order_total)}
                                                        </div>
                                                    </div>
                                                </NavLink>
                                        )
                                      }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = state => ({
  orders: state.ecommerce.orders
})

const matchDispatchToProps = dispatch => bindActionCreators({
  updateUserProfile,
  fetchOrders
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Orders)
