// External Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '../../buttons/Button'
import Pagination from 'react-bootstrap/Pagination'

export default class RatingInput extends Component {
    static propTypes = {
      handleSendClick: PropTypes.func.isRequired,
      maxRating: PropTypes.number,
      hideNextButton: PropTypes.bool
    };

    static defaultProps = {
      maxRating: 9,
      hideNextButton: false
    };

    state = { rating: 7 };

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
    }

    handleSend = (rating) => {
      this.setState({ rating: rating })
      this.props.handleSendClick({ text: rating })
    };

    render () {
      const { rating } = this.state
      const { maxRating } = this.props

      const ratingArray = []
      for (let i = 1; i <= maxRating; i++) {
        ratingArray.push(i)
      }

      return (
            <div className='choice-item-container'>
                <small className="text-center">(select a rating)</small>
                <div className="choice-item-content">
                    <Pagination className="justify-content-center">
                        {
                            ratingArray.map(
                              r => (
                                    <Pagination.Item key={r} active={r === rating}
                                                     onClick={() => this.handleSend(r)}>
                                        {r}
                                    </Pagination.Item>
                              )
                            )
                        }
                    </Pagination>
                </div>
                {!this.props.hideNextButton && <div className="field-footer">
                    <div className="container d-flex justify-content-center">
                        <Button onClick={() => this.handleSend(rating)}>Next</Button>
                    </div>
                </div>}
            </div>
      )
    }
}
