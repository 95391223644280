// External Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { getHttpsImageUrl } from '../../../utils'
import Button from '../../buttons/Button'

export default class ChoiceInput extends Component {
    static propTypes = {
      choices: PropTypes.array,
      handleSendClick: PropTypes.func.isRequired,
      multipleChoice: PropTypes.bool,
      toggleEdit: PropTypes.func
    };

    static defaultProps = {
      choices: [],
      multipleChoice: false
    };

    state = {
      choices: [],
      selectedChoices: {},
      showPercentile: false
    };

    componentDidMount () {
      const { choices } = this.props
      this.setState({ choices })
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      // if (this.props.choices)
      if (JSON.stringify(this.props.choices) !== JSON.stringify(prevProps.choices)) {
        this.setState({ choices: this.props.choices })
      }
    }

    handleChoiceClick = (choice) => {
      const choiceId = choice.id.toString()
      const { selectedChoices } = this.state

      this.setState(prevState => {
        let text = 'true'

        if (this.props.multipleChoice) {
          if (selectedChoices[choiceId] && selectedChoices[choiceId].text === 'true') {
            text = 'false'
          } else {
            const singleCondition = choice.bot_field.text.toLowerCase() === 'none of these'
            if (singleCondition) {
              return {
                selectedChoices: {
                  [choiceId]: { id: choiceId, text, singleChoiceForced: true }
                }
              }
            }
          }
          const selectedChoicesArray = Object.values(selectedChoices).filter(c => !c.singleChoiceForced)
          const newSelectedChoices = {}
          for (let i = 0; i < selectedChoicesArray.length; i++) {
            newSelectedChoices[selectedChoicesArray[i].id] = selectedChoicesArray[i]
          }

          return {
            selectedChoices: {
              ...newSelectedChoices,
              [choiceId]: { id: choiceId, text }
            }
          }
        } else {
          if (selectedChoices[choiceId] && selectedChoices[choiceId].text === 'true') { return { selectedChoices: {} } }
        }
        return {
          selectedChoices: {
            [choiceId]: { id: choiceId, text }
          }
        }
      }, () => {
        if (!this.props.multipleChoice) {
          const { choices, selectedChoices } = this.state
          const textArray = []
          choices.map(
            choice => {
              if (selectedChoices[choice.id] && selectedChoices[choice.id].text === 'true') {
                textArray.push(choice.bot_field.field_id)
              }
            }
          )

          const text = textArray.join(',')
          this.handleSend(text)
        }
      })
    };

    handleSend = (text) => {
      const { choices, selectedChoices } = this.state
      const selectedData = []
      const unselectedData = []

      choices.map(
        choice => {
          if (selectedChoices[choice.id] && selectedChoices[choice.id].text === 'true') {
            selectedData.push(selectedChoices[choice.id])
          } else {
            unselectedData.push({ id: choice.id, text: 'false' })
          }
        }
      )
      const data = [...selectedData, ...unselectedData]

      if (selectedData.length) {
        this.props.toggleEdit()
        this.setState({ showPercentile: true })

        if (choices.filter(choice => choice.bot_field.show_selection_percentage).length > 0) {
          setTimeout(() => this.props.handleSendClick({ children: JSON.stringify(data), text }), 1000)
        } else {
          this.props.handleSendClick({ children: JSON.stringify(data), text })
        }
      }
    };

    render () {
      let { choices, selectedChoices, showPercentile } = this.state
      const { multipleChoice } = this.props
      const textArray = []

      choices = choices.sort((a, b) => (a.bot_field.priority - b.bot_field.priority))

      choices.map(
        choice => {
          if (selectedChoices[choice.id] && selectedChoices[choice.id].text === 'true') {
            textArray.push(choice.bot_field.field_id)
          }
        }
      )

      const text = textArray.join(',')

      const sendButtonStyle = {
        opacity: Object.keys(selectedChoices).length > 0 ? 1 : 0
      }

      // console.warn(selectedChoices);

      return (
            <div className='choice-item-container'>
                {
                    multipleChoice &&
                    <small className="text-center">(select all that apply)</small>
                }
                <div className="choice-item-content">
                    {
                        choices.map(
                          (choice, index) => {
                            let tempValue = false
                            const files = choice.bot_field.bot_field_files
                            const { [this.props.language]: placeholder = choice.bot_field.text } = choice.bot_field.languages
                            const selectionPercentage = choice.bot_field.selection_percentage
                            const showSelectionPercentage = choice.bot_field.show_selection_percentage

                            if (selectedChoices[choice.id]) { tempValue = selectedChoices[choice.id].text }
                            // let containerClassName = tempValue === 'true' ? 'choice-item active' : 'choice-item';

                            return (
                                    <Button key={index}
                                            variant={'red'}
                                            style={{ margin: '5px 20px 5px 20px', width: 250, position: 'relative' }}
                                            active={tempValue === 'true'}
                                            onClick={() => this.handleChoiceClick(choice)}>
                                        {
                                            !!files &&
                                            !!files.length &&
                                            <div className="choice-item-image-container">
                                                <img style={{ width: '100%' }} src={getHttpsImageUrl(files[0].file)}
                                                     alt={placeholder}/>
                                            </div>
                                        }
                                        {placeholder}
                                        {
                                            !!showPercentile && !!showSelectionPercentage && !!selectionPercentage &&
                                            <div className='choice-item-percentage'>
                                              {`${selectionPercentage}% users`}
                                            </div>
                                        }
                                    </Button>
                            )
                          }
                        )
                    }
                </div>
                {
                    multipleChoice &&
                    Object.keys(selectedChoices).length > 0 &&
                    <div className="field-footer">
                        <div className="container d-flex justify-content-center">
                            <Button onClick={() => this.handleSend(text)}>Next</Button>
                        </div>
                    </div>
                }
            </div>
      )
    }
}
