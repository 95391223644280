import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import Page from '../../../components/core/Page'
import LText from '../../../components/core/LText'
import { deleteAddress, fetchAddress } from '../../../actions/ecommerce/address'
import AddressItemCard from '../../../components/cards/AddressItemCard'

class Addresses extends PureComponent {
    static propTypes = {
      address: PropTypes.object,
      fetchAddress: PropTypes.func,
      deleteAddress: PropTypes.func
    };

    constructor (props) {
      super(props)

      this.state = {
        showAddressCreateCard: false,
        editAddressId: -1
      }
    }

    componentDidMount () {
      this.props.fetchAddress()
    }

    handleDeleteClick = (id) => {
      this.props.deleteAddress({ id })
    };

    handleEditClick = (id) => {
      this.setState({ editAddressId: id, showAddressCreateCard: false })
    };

    handleCancelClick = (id) => {
      this.setState({ editAddressId: -1, showAddressCreateCard: false })
    };

    render () {
      const { address, match } = this.props
      const { editAddressId } = this.state

      return (
            <Page meta={{ path: `${match.params.language}/profile/reports/` }}>
                <div className='profile-container'>
                    <div className="py-3"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="py-4 text-center">Your Addresses</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                                {
                                    !!address.list &&
                                    !!address.list.length &&
                                    address.list.map((item) => (
                                        <AddressItemCard key={item.id} data={item}
                                                         editAddressId={editAddressId}
                                                         handleCancelClick={() => this.handleCancelClick(item.id)}
                                                         handleEditClick={() => this.handleEditClick(item.id)}
                                                         handleDeleteClick={() => this.handleDeleteClick(item.id)}
                                                         showDeliverHere={false}/>
                                    ))
                                }
                                {
                                    !this.state.showAddressCreateCard &&
                                    <button className='add-new-button btn-m btn-m-primary'
                                            onClick={() => this.setState({
                                              showAddressCreateCard: true,
                                              editAddressId: -1
                                            })}>
                                        <LText k="ADD_NEW_ADDRESS"/></button>
                                }
                                {
                                    this.state.showAddressCreateCard &&
                                    <AddressItemCard handleCancelClick={() => this.handleCancelClick()}
                                                     handleAddressCreate={(item) => this.setState({ showAddressCreateCard: false })}/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="py-3"/>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = state => ({
  address: state.ecommerce.address
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchAddress,
  deleteAddress
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Addresses)
