import api from '../api'

export const FETCH_COURSES = 'FETCH_COURSES'
export const FETCH_COURSES_RESULT = 'FETCH_COURSES_RESULT'
export const FETCH_COURSES_ERROR = 'FETCH_COURSES_ERROR'
export const FETCH_COURSES_RETRY = 'FETCH_COURSES_RETRY'

export const PURCHASE_LIBRARY = 'PURCHASE_LIBRARY'
export const PURCHASE_LIBRARY_RESULT = 'PURCHASE_LIBRARY_RESULT'
export const PURCHASE_LIBRARY_ERROR = 'PURCHASE_LIBRARY_ERROR'
export const PURCHASE_LIBRARY_RETRY = 'PURCHASE_LIBRARY_RETRY'

export const fetchCourses = () => async dispatch => {
  try {
    dispatch({ type: FETCH_COURSES })
    const res = await api.library.courses()
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_COURSES_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_COURSES_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_COURSES_RETRY, message: e.message })
  }
}

export const purchaseLibrary = (data) => async dispatch => {
  try {
    dispatch({ type: PURCHASE_LIBRARY })
    const res = await api.library.purchase(data)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: PURCHASE_LIBRARY_RESULT, data: result })
    } else {
      dispatch({ type: PURCHASE_LIBRARY_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: PURCHASE_LIBRARY_RETRY, message: e.message })
  }
}
