import {
  CREATE_ORDER,
  CREATE_ORDER_RESULT,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_RETRY,
  FETCH_ORDERS,
  FETCH_ORDERS_RESULT,
  FETCH_ORDERS_ERROR,
  FETCH_ORDERS_RETRY,
  UPDATE_ORDER_PRODUCT,
  UPDATE_ORDER_PRODUCT_RESULT,
  UPDATE_ORDER_PRODUCT_ERROR,
  UPDATE_ORDER_PRODUCT_RETRY,
  UPDATE_NEW_ORDER, FETCH_ORDER, FETCH_ORDER_RESULT, FETCH_ORDER_ERROR, FETCH_ORDER_RETRY
} from '../../actions/ecommerce/orders'

const initialState = {
  detail: {},
  list: [],
  newOrder: {},
  isFetching: false,
  isFetchingList: false,
  isUpdatingProduct: false,
  isCreating: false,
  error: false,
  message: null,
  retry: false
}

const reducer = (state = initialState, action) => {
  // console.warn(action, state);
  switch (action.type) {
    case FETCH_ORDER:
      return { ...state, isFetching: true, error: false, message: null, retry: false }
    case FETCH_ORDER_RESULT:
      return { ...state, isFetching: false, detail: action.data }
    case FETCH_ORDER_ERROR:
      return { ...state, isFetching: false, error: true, message: action.message }
    case FETCH_ORDER_RETRY:
      return { ...state, isFetching: false, retry: true, message: action.message }

    case CREATE_ORDER:
      return { ...state, isCreating: true, error: false, message: null, retry: false }
    case CREATE_ORDER_RESULT:
      return { ...state, isCreating: false, detail: action.data }
    case CREATE_ORDER_ERROR:
      return { ...state, isCreating: false, error: true, message: action.message }
    case CREATE_ORDER_RETRY:
      return { ...state, isCreating: false, retry: true, message: action.message }

    case FETCH_ORDERS:
      return { ...state, isFetchingList: true, error: false, message: null, retry: false }
    case FETCH_ORDERS_RESULT:
      return { ...state, isFetchingList: false, list: action.data }
    case FETCH_ORDERS_ERROR:
      return { ...state, isFetchingList: false, error: true, message: action.message }
    case FETCH_ORDERS_RETRY:
      return { ...state, isFetchingList: false, retry: true, message: action.message }

    case UPDATE_ORDER_PRODUCT:
      return { ...state, isUpdatingProduct: true, error: false, message: null, retry: false }
    case UPDATE_ORDER_PRODUCT_RESULT:
      return { ...state, isUpdatingProduct: false, list: action.data }
    case UPDATE_ORDER_PRODUCT_ERROR:
      return { ...state, isUpdatingProduct: false, error: true, message: action.message }
    case UPDATE_ORDER_PRODUCT_RETRY:
      return { ...state, isUpdatingProduct: false, retry: true, message: action.message }

    case UPDATE_NEW_ORDER:
      return { ...state, newOrder: action.data }

    default:
      return { ...state }
  }
}

export default reducer
