// External Imports
import React from 'react'
// My Imports
import AccordionCard from '../../Category/common/AccordionCard'
import { Images } from '../../../constants'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import LText from '../../../components/core/LText/LText'
import Button from '../../../components/buttons/Button'
import { openLinkOnNewTab } from '../../../utils'

const shopUrl = '/en/solutions/'

export const accordionList1 = [
    <AccordionCard key={'0'} title="Where is Amla found?">
        <p>
            Amla grows all over India and each tree lives and bears fruits for nearly seventy years. The land under Amla
            cultivation in the country has grown exponentially and has gone up from 3,000 hectares in the 1980’s to more
            than one lakh hectares in 2005. The highest Amla producing state in India is <b>Uttar Pradesh</b>,
            followed by <b>Gujarat</b>. It is also cultivated in <b>Tamil Nadu, Rajasthan and Madhya Pradesh</b>.
            Outside India it is grown in the <b>Middle East</b>, and in some other countries
            like <b>Pakistan, Uzbekistan, Sri Lanka, South East Asia, China and Malaysia</b>.
            <br/>
            <br/>
            The berry is a critical ingredient of the household Indian herbal tonics like Chyawanprash, Triphala churna,
            Brahma Rasayana and the Madhumega churna. One Amla fruit has more vitamin C than 16 bananas or three oranges
            and is known for its valuable oil. This oil is extensively used in hair care and in curing scalp related
            issues.
            <br/>
            <br/>
            The <b>subtropical</b> plant of Amla prefers a dry climate and being hardy, it can also grow on wasteland
            and in various other soil conditions. It also has tolerance for salinity and alkalinity. A fully grown Amla
            tree can tolerate freezing as well as a high temperature of about 46OC. Seedlings, grafts or buddings of the
            tree are planted during the months of July to August. Different varieties of Amla are available, which
            include - banarasi, NA-4 (Krishna), Chakaiya, Francis, NA-5 (Kanchan), NA-6, NA-7, NA-10, and BSR-1
            (Bhavanisagar), bansi red, pink-tinged, desi, chakaiya, and hathi jhool.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What is the history of Amla?">
        <p>
            Amla is a fruit of antiquity in India and traditional Indian medicine of <b>Ayurveda</b> has used dried
            and fresh fruits of the tree since a time unknown. Sushruta, the father of <b>ancient Indian medicine</b>,
            has written about the rejuvenating health benefits of Amla during his lifetime, circa <b>1500-3000 BC</b>.
            <br/>
            <br/>
            Every part of the Amla tree is considered of immense value in Ayurveda, the Indian
            indigenous system of medicine, which makes pills or herbal preparations from fruit, seeds, flowers, leaves,
            barks and roots.Traditionally Amla is known as a storehouse of antioxidants and numerous early medical
            practices recognise its preventive actions against a host of diseases.
            <br/>
            <br/>
            Since ages the fruit has also found its place in Indian cooking and has been used by Ayurvedic experts as a
            cure against imbalanced Pitta. According to the available <b>Sanskrit Buddhist traditional texts</b>,
            the great Indian emperor Ashoka gave half an Amla to the Buddhist Sangha as his final gift.
            Verses from Ashokavadana describes this fact thus, “A great donor, the lord of men, the eminent
            Maurya Ashoka, has gone from being lord of Jambudvipa [the continent] to be lord of half a myrobalan.”
            <br/>
            <br/>
            <b>Theravada Buddhism</b> says it was under an Amla tree that twenty first Buddha named Phussa Buddha,
            attained his enlightenment, or Bodhi. Additionally, this berry also holds immense religious importance in
            Hinduism, and is particularly needed in the rituals held in the month of Kartik, i.e. between October and
            November.
            <br/>
            <br/>
            Some states of India, have a tradition of offering the fruit as a Naivedya (religious offering) to
            Lord Shiva and then consume it to stay safe from different respiratory infections, common colds, flu and
            other health issues caused by vata, kapha and pitta imbalance.The tree is highly venerated in many other
            Hindu religious contexts. One such example of it is its sacredness to the goddess Lakshimi. Hindus often
            offer the fruit to the goddess while worshipping her.
            <br/>
            <br/>
            Also, according to Hindu mythology, Brahma was once
            deeply engrossed in invoking Lord Vishnu and tears rolled down his eyes. As the tears touched the ground, an
            Amla tree was born.
            <br/>
            <br/>
            Another story of Amla’s origin is that once, after the ksheera sagar manthan, there
            ensued a terrible fight between the gods and demons. As a result, some drops of ksheera or Amrit got
            inadvertently spilled on earth. The drops are popularly believed to have created the fruit of Amla.
            <br/>
            <br/>
            Apart from its religious significance in numerous Hindu rites and ceremonies, Amla has connections to World
            War II as well. During the war candies, powder, and tablets made of dried Amla were distributed among the
            Indian soldiers as vitamin C supplements.
            <br/>
            <br/>
            Additionally, <b>Ayurvedic texts, Indian myths and Sanskrit scriptures</b> are replete with the reference of
            this fruit. The celebrated Tamil poet, Avvayar, maintains that a heavenly Amla was offered to Prince
            Adhiyaman for the advancement of his long life, so that the prince could continue with his good and kind
            deeds which greatly helped his subjects.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'2'} title="What is Amla made of?">
        <p>
            Composition of Amla varies according to the different varieties of the fruit and its geography. However, the
            average compositions of Amla fruits are <b>Protein - 0.5 %, Ca - 0.05 %, K-0.02%, Fe - 1.2 mg/100g, Moisture
            - 81.2 %, Fat - 0.1%, Nicotinic acid - 0.2 mg/g, Carbohydrates - 14.1 %, Mineral matter - 0.7 % and Fibre -
            3.4%</b>.
            <br/>
            <br/>
            Some other components are, <b>phyllemblin, phyllemblin acid, putranjiva A, emblicol, quercetin,
            hydroxymethyl furfural, ellagic acid, gallic acid, pectin 10-11, hydrolysable tannins called emblicanin A
            and B, puni gluconate and pedunculagin</b>.
            <br/>
            <br/>
            Amla is known for its low calories and for being packed with numerous phenolic
            phytochemicals like flavonoids, anthocyanins and for being a powerful source of vitamin C and A. According
            to research, the vitamin C content in Amla is 430 grams. A distinct type of oil, some amount of essential
            oil and phosphatides are present in the seed of the Amla fruit. The seed oil is also packed with <b>unsaturated
            fatty acids like linoleic acid and oleic acid</b>. The leaves contain acids like <b>gallic,
            ellagic, chebulic, chebulagic, chebulinic and malic acid, and also contain alkaloids,
            phyllatidine and phyllantine</b>.
            <br/>
            <br/>
            The bark of the Amla tree contains <b>Leuco Delphinidin, tannin and proanthocyanidin</b>. These compounds
            provide numerous healing benefits including the delaying of the aging process, fighting inflammation and
            boosting memory.
            <br/>
            <br/>
            The nutrition value of per <b>100 grams of Amla (as per USDA) is Energy 44 Kcal, Carbohydrates 10.18 g,
            Protein 0.88 g, Total Fat 0.58 g, Dietary Fibre 4.3 g, Folates 6 mcg, Niacin 0.300 mg, Pantothenic acid
            0.286 mg, Pyridoxine 0.080 mg, Riboflavin 0.030 mg, Thiamine 0.040 mg, Vitamin A 290 IU, Vitamin C 27.7 mg,
            Minerals, Potassium 198 mg,Calcium 25 mg, Copper 0.070 mg, Iron 0.31 mg, Magnesium 10 mg, Manganese 0.144
            mg, Phosphorus 27 mg and Zinc 0.12 mg</b>.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'3'} title="What are the dosages of Amla?">
        <p>
            Amla is recommended by Ayurvedic doctors as a regular dietary supplement. Most people use Amla daily, for
            their health benefits. Amla products like dried, fresh, or frozen Amla, Amla powders and juices are
            available at health stores, natural pharmacies, grocery stores and also online.
            <br/>
            <br/>
            However, making Amla supplements at home makes them more potent and effective while also making it possible
            to customise the product according to individual choices. Homemade edible Amla products include Amla
            chutney, murabba, candy, dried amla, pickle, juice or Amla added in dal (lentil soup). Besides, Amla oil can
            also be made at home by soaking dried amla fruits in coconut, or sesame oil for some days.
            <br/>
            <br/>
            Just like an apple a day, one Amla a day is also highly effective in maintaining health. One Alma is equal
            to <b>10 to 20 ml</b> of its juice or four grams of Amla powder. However, the safe dosage of Amla varies
            according to age. <b>Adults</b> consuming <b>10-15 ml</b> of Amla juice anytime of the day is considered
            safe while for children in the age group of <b>2-10 years, 3-10 ml</b> Amla juice is safe and can be
            consumed anytime of the day. For children <b>below 2 years of age 1-3 ml</b> is considered safe when
            consumed after meals.
            <br/>
            <br/>
            Though Amla is apparently safe, it can cause side effects when taken in larger quantities. Besides, those
            having some kind of medical conditions must take advice from their doctors before taking Amla in any form.
            It is also advised to get the dosage prescribed by a doctor or an Ayurveda practitioner.
            <br/>
            <br/>
            Amla juice, widely known as tridoshic for its ability to clear Vata, Pitta and Kapha doshas in the body, is
            used for detoxifying the body and works best when taken early in the morning, on an empty stomach. The juice
            instantly energises one’s body and can be stored safely in the fridge for a couple of days.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]

export const accordionList2 = [
    <AccordionCard key={'0'} title="What are the benefits of Amla?">
        <p>
            <strong>Immunity:</strong> As a storehouse of vitamin C Amla acts as an amazing immune booster. The other
            components of Amla like vitamin A, polyphenols, alkaloids and flavonoids, quercetin and Kaempferol also help
            in building immunity. Besides, Amla also increases the quantity of white blood cells, which help the body to
            fight against diseases, allergies and infections helping the body to purge away toxins. Eating Amla everyday
            defends the body against harmful bacteria and swelling.
            <br/>
            <br/>
            <strong>Diabetes:</strong> Amla is greatly beneficial for diabetics, as it prevents pancreatitis and also
            controls blood sugar. Vitamin C is a potential antioxidant and hence annuls the impacts of oxidative stress,
            which causes diabetes. Amla fruit, juice, candy or dried Amla powder mixed with water can reduce sugar
            levels.
            <br/>
            <br/>
            <strong>Hypertension:</strong> Amla helps in reducing blood pressure and also in decreasing the harmful
            impacts of cardiovascular disorders. The high content of antioxidants, vitamins and minerals like potassium
            in Amla makes it a highly useful diet for patients with high blood pressure. Potassium helps dilate the
            blood vessels which lowers the blood pressure and addresses the signs of hypertension. Amla juice mixed with
            one tablespoon of honey can lower blood pressure naturally and can control systolic and diastolic blood
            pressure.
            <br/>
            <br/>
            <strong>Sore throat and cold:</strong> Amla, a rich source of Vitamin C, helps in curing a sore throat and a
            common cold. A mixture of two teaspoons of Amla powder along with two teaspoons of honey is a great fix for
            both these conditions. The mixture has to be taken three to four times a day for the best results.
            <br/>
            <br/>
            <strong>PCOS:</strong> As the fruit is capable of purging toxins, regulating the menstrual cycle and
            balancing hormones, it is believed to improve fertility in women. It can also manage the negative effects of
            PCOS like obesity and unwanted hair growth.
            <br/>
            <br/>
            <strong>Liver function:</strong> Juice made from the fruit has great quantities of phytochemicals like
            quercetin, gallic acid, corilagin and ellagic acids. These constituents help the body in detoxification and
            also in fighting free radicals. It also enhances the liver function, improves vitality and supplies the body
            with energy.
            <br/>
            <br/>
            <strong>Insulin:</strong> Amla is rich in Chromium, the mineral helps in moderating carbohydrate metabolism
            and beta cells for insulin production. Taking Amla every day repairs the pancreatic tissues while protecting
            the insulin-producing cells from damage. Raw Amla is also eaten in the morning on a daily basis, in order to
            make the body more receptive to insulin.
            <br/>
            <br/>
            <strong>Gut Health:</strong> Being rich in fibre Amla helps in bowel movements and cures IBS and
            constipation, enhances digestive juice secretions and also helps in better assimilation of nutrients. It is
            often recommended to start the day’s meal with an Amla dish in order to detoxify and purge body wastes.
            <br/>
            <br/>
            <strong>Weight Loss:</strong> By promoting metabolic activities, Amla can check fat formation in undesired
            parts of the body and also help in draining out toxins. Obesity is primarily caused by slow metabolism, fat
            accumulation and toxin build-up and Amla helps in controlling all these conditions.
            <br/>
            <br/>
            <strong>Hair:</strong> Amla juice is highly useful in promoting hair growth. Taking the juice every day
            gives immunity, makes hair follicles strong and adds volume to hair. It also helps in getting rid of
            dandruff. Amla oil is indispensable in all herbal hair care products. Amla hair oil is immensely helpful in
            preventing hair fall because of the constituent carotene, iron and antioxidants. Amla oil penetrates the
            roots of hair easily and stimulates the follicles for hair growth. Ayurveda also says that untimely greying
            is a disorder caused by too much pitta and Amla being a natural cooling agent checks Pitta and in turn, the
            greying process. One can add Amla powder to henna and apply it as a hair pack or consume Amla juice, powder
            or candy to stop early greying.
            <br/>
            <br/>
            <strong>Skin:</strong> As a natural blood purifier Amla as a fruit or juice can lead to a glowing skin.
            Those who have acne, blemishes, pimples or freckles can use Amla based face packs to get back good skin. The
            fruit is also capable of boosting collagen and in maintaining youthful skin.
            <br/>
            <br/>
            <strong>Nutrition:</strong> Other than Vitamin C, the fruit is also rich in iron, calcium, phosphorus,
            therefore it can be used as a wholesome nutritional drink.
            <br/>
            <br/>
            <strong>Blood cholesterol:</strong> Amla juice helps in lowering cholesterol levels and the amino acids and
            antioxidants promote overall functioning of the heart.
            <br/>
            <br/>
            <strong>Asthma:</strong> Amla is also useful in controlling respiratory ailments like asthma.
            <br/>
            <br/>
            <strong>Arthritis:</strong> Amla is an anti-inflammatory plant and plays a role in addressing
            arthritis-related pain. A dose of three times a day, consumed for 24 weeks is effective as the glucosamine
            sulphate helps in lowering pain in people with knee osteoarthritis.
            <br/>
            <br/>
            <strong>Heartburn:</strong> Taking Amla fruit extracts for four weeks can lower heartburn frequencies as
            well as their severity.
        </p>
        <h3>Some other benefits:</h3>
        <p>
            Some other benefits of Amla include the controlling of bloody diarrhoea or dysentery, managing eye problems,
            checking the hardening of the arteries (atherosclerosis), alleviating swelling of the pancreas, treatment of
            mouth ulcers and the improvement of the eyesight and various other cognitive functions.
        </p>

        <ImageBreaker image={Images.ingredient.amlaBenefits} to={shopUrl} buttonText={'SHOP'}/>

        <h3>Popular Ayurvedic Amla formulations available in the market:</h3>
        <p>
            Some popular Amla formulations available are Triphala (a combination of fruits like amalaki, bibhitaki and
            haritaki), Chyawanprash (a blend of various ingredients including Amla, ghee, sesame oil and honey),
            Ashokarishta (a mixture of Amla, with dhataki, musta, haritaki, jeera, jaggery, mango seeds together with
            the decoction made from Ashoka tree) and Kanchanar Guggulu (a formulation made from amalaki with the bark of
            Kachnar tree, ginger, black pepper, long pepper, haritaki, bibhitaki, cardamom, cinnamon).
        </p>

        <p className="text-center font-weight-bold">
            <span>To know more about the benefits of Amla, </span>
            <span className="ingredient-blog-links"
                  onClick={() => openLinkOnNewTab('/blog/What-are-the-benefits-of-Amla')}>click here</span>
        </p>
    </AccordionCard>,
    <AccordionCard key={'1'} title="What are the side effects of Amla?">
        <p>
            As a food Amla is mostly safe. However, when taken in large quantities it can cause side effects. Some
            common threats associated with Amla are that Amla can increase risk of bleeding or bruising, hence those
            having bleeding disorders must
            use it with caution.
            <br/>
            <br/>
            Safety of eating Amla by pregnant women and lactating mothers is still not very clear. It is better to stay
            away from the fruit during these periods.
            <br/>
            <br/>
            Amla can <b>reduce blood sugar levels;</b> hence diabetes medications need to be tweaked by healthcare
            providers if the patient is known to consume an Amla supplement.
            <br/>
            <br/>
            Amla, taken with ginger, Boswellia and Tinospora cordifolia, <b>can damage liver</b> function in people
            afflicted with liver diseases.
            <br/>
            <br/>
            Amla can raise the possibilities of <b>bleeding during and after surgery</b>. It is better to stop taking
            the fruit at least two weeks before undergoing a surgical procedure.
            <br/>
            <br/>
            If consumed in excess, Amla juice can <b>increase liver enzymes</b> called serum glutamic, pyruvic
            transaminase or SGPT. Once in excess, these enzymes can <b>disrupt the digestive pattern</b> of an
            individual. Excess intake of the juice can also cause hypoglycemia that is harmful for both normal people
            and diabetic patients. Being extremely rich in vitamin C, the excess of Amla might lead to digestive
            disorders and many believe that too much vitamin C can cause diarrhoea and queasiness.
            <br/>
            <br/>
            Too much consumption of vitamin C through Amla can also cause <b>acid reflux</b> and other gastrointestinal
            issues, especially in those pre-afflicted with similar conditions.
            <br/>
            <br/>
            Amla being a diuretic in nature, its juice, if taken in large quantities, can further lead
            to <b>dehydration</b>.
            <br/>
            <br/>
            It is better not to take Amla juice along with alcohol, because of the fact that they are different kinds of
            drinks, they can lead to some uneasiness. However serious side effects are generally not a possibility.
            <br/>
            <br/>
            Fibre which when taken in right amounts can actually cure constipation, when taken in excess might be the
            cause of constipation. Alma being highly rich in fibre might also lead to Constipation, if taken in excess
            amounts. Besides, too much fibre might create <b>negative impacts on one’s colonic transit</b>. Amla might
            get fermented very fast in the colon, resulting in a rush of microbial activity which in turn can cause
            abdominal cramps and bloating.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'2'} title="How long does it take for the Amla to work?">
        <p>
            Charaka Samhita Sutrasthana says Amla which means, ‘nurse’ in Sanskrit is an ingredient or pathya that can
            be consumed every day. Amla is a regular dietary supplement according to today’s doctors as well, who advise
            the consumption of one Amla every day, for its numerous health benefits.
            <br/>
            <br/>
            However, the time period taken for Amla in treating definite disorders can vary from one medical condition
            to another and also from one individual to another. The user’s body weight and type, food habits and
            lifestyle choices are also factors on which the impact of the drug will depend. Time taken by Amla along
            with its dosage will also vary according to age.
            <br/>
            <br/>
            In general doctors and other healthcare experts recommend the intake of Amla for a period of <b>three months
            at a stretch</b> in order to get best results. In fact, to see the results of consuming or eating Amla for
            treating any kind of health condition, one has to consume Alma, or its products for at least a period of two
            to three months.
            <br/>
            <br/>
            In case of <b>haircare</b>, applying Amla oil and shampoo along with fresh cut onions for about <b>three to
            four months</b> yields good results.
            <br/>
            <br/>
            In case of <b>dental problems</b>, the roots of the plant are grinded and consumed <b>twice a day for one
            just a day after meals</b>, to cure a toothache.
            <br/>
            <br/>
            Another option is, making a juice from the leaf extracts, and putting a few drops of it in the ear to find
            relief. One can also grind the node of the Amla tree, mix it with water and stir the mixture energetically.
            Once done, the solution is filtered through a cloth or is finely sieved and a few drops are put in the
            opposite ear. This therapy has to be continued for three days.
        </p>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>,
    <AccordionCard key={'3'} title="References">
        <ul>
            <li>
                <a href="https://www.tarladalal.com/glossary-amla-49i" target="_blank">
                    https://www.tarladalal.com/glossary-amla-49i
                </a>
            </li>
            <li>
                <a href="https://shodhganga.inflibnet.ac.in/bitstream/10603/39981/9/09_chapter%201.pdf" target="_blank">
                    https://shodhganga.inflibnet.ac.in/bitstream/10603/39981/9/09_chapter%201.pdf
                </a>
            </li>
            <li>
                <a href="http://www.ecoindia.com/flora/trees/amla-plant.html"
                   target="_blank">
                    http://www.ecoindia.com/flora/trees/amla-plant.html
                </a>
            </li>
            <li>
                <a href="https://www.dabur.com/in/en-us/about/science-of-ayurveda/herbal-medicinal-plants/amla-Benefits-and-uses"
                   target="_blank">
                    https://www.dabur.com/in/en-us/about/science-of-ayurveda/herbal-medicinal-plants/amla-Benefits-and-uses
                </a>
            </li>
            <li>
                <a href="http://www.jorr.org/article.asp?issn=2249-4987;year=2015;volume=7;issue=2;spage=65;epage=68;aulast=Grover"
                   target="_blank">
                    http://www.jorr.org/article.asp?issn=2249-4987;year=2015;volume=7;issue=2;spage=65;epage=68;aulast=Grover
                </a>
            </li>
            <li>
                <a href="https://www.sciencedirect.com/topics/agricultural-and-biological-sciences/phyllanthus-emblia"
                   target="_blank">
                    https://www.sciencedirect.com/topics/agricultural-and-biological-sciences/phyllanthus-emblia
                </a>
            </li>
            <li>
                <a href="https://www.nhp.gov.in/Amla_mtl" target="_blank">
                    https://www.nhp.gov.in/Amla_mtl
                </a>
            </li>
            <li>
                <a href="http://theindianvegan.blogspot.com/2012/09/all-about-amla.html"
                   target="_blank">
                    http://theindianvegan.blogspot.com/2012/09/all-about-amla.html
                </a>
            </li>
            <li>
                <a href="https://www.downtoearth.org.in/coverage/an-amla-a-day-12081"
                   target="_blank">
                    https://www.downtoearth.org.in/coverage/an-amla-a-day-12081
                </a>
            </li>
            <li>
                <a href="https://easyayurveda.com/2013/01/17/amla-benefits-dose-usage-side-effects-complete-ayurveda-details/"
                   target="_blank">
                    https://easyayurveda.com/2013/01/17/amla-benefits-dose-usage-side-effects-complete-ayurveda-details/
                </a>
            </li>
        </ul>
        <div className="text-center">
            <Button style={{ minWidth: '200px' }} variant={'primary'} to={shopUrl}><LText k={'SHOP'}/></Button>
        </div>
    </AccordionCard>
]
