import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import OtpInput from 'react-otp-input'

import {
  deleteCartItem,
  fetchCart,
  fetchConfig,
  updateCartCoupon,
  updateCart
} from '../../../actions/ecommerce/cart'
import { userLogin, userLoginOtp } from '../../../actions/accounts/profile'
import { fetchOffers } from '../../../actions/ecommerce/offers'
import { createOrder, updateNewOrder } from '../../../actions/ecommerce/orders'
import OrderSummaryCard from '../../../components/cards/OrderSummaryCard'
import Page from '../../../components/core/Page'

import { Images, paymentMethods, paymentTypes, trackPurchase } from '../../../constants'
import OrderLoading from '../../../components/loadings/OrderLoading'
import LText from '../../../components/core/LText'
import api from '../../../actions/api'
import Ripple from '../../../components/loadings/Ripple'
import {
  getMessages,
  getPhoneNumberWithCountryCode,
  getPhoneNumberWithoutCountryCode, getPrice,
  getSearchParams
} from '../../../utils'
import Consent from '../../../components/others/Consent'
import Button from '../../../components/buttons/Button'
import FormInput from '../../../components/inputs/FormInput'
import DuplicateOrderModal from '../../../components/modals/DuplicateOrderModal'
import CartState from '../../../components/others/CartState'
import Netbanking from '../../../components/icons/Netbanking'
import Cards from '../../../components/icons/Cards'
import Upi from '../../../components/icons/Upi'
import Wallet from '../../../components/icons/Wallet'
import PaymentMethodCard from '../../../components/cards/PaymentMethodCard/PaymentMethodCard'
import Ecommerce from '../../../services/Ecommerce'
import EMI from '../../../components/icons/EMI'

class Payment extends PureComponent {
    static propTypes = {
      history: PropTypes.object,
      match: PropTypes.object,
      location: PropTypes.object,
      cart: PropTypes.object,
      orders: PropTypes.object,
      profile: PropTypes.object,
      newOrder: PropTypes.object,
      updateNewOrder: PropTypes.func,
      updateCart: PropTypes.func,
      fetchOffers: PropTypes.func,
      fetchConfig: PropTypes.func,
      userLogin: PropTypes.func,
      userLoginOtp: PropTypes.func
    };

    OTP_TIMEOUT = 30;

    constructor (props) {
      super(props)
      const { email = '' } = props.profile.detail
      const { address = {}, cart = {} } = props.newOrder

      const querySearch = getSearchParams(props.location.search)
      // let paymentType = paymentTypes.cod;
      let paymentType = cart.payment_type
      let method = cart.payment_method || paymentMethods.card
      if (querySearch.pt && querySearch.pt === paymentTypes.online) {
        paymentType = paymentTypes.online
        method = paymentMethods.card
      } else if (querySearch.pm && querySearch.pm === 'o') {
        paymentType = paymentTypes.online
        method = paymentMethods.card
      } else if (querySearch.pm && querySearch.pm === 'c') {
        paymentType = paymentTypes.cod
      }

      this.state = {
        allowedPaymentMethod: null,
        method,
        paymentType,
        otp: '',
        message: null,
        ordersMessage: null,
        showOtpField: false,
        otpTimer: null,
        orderquery: { phone: getPhoneNumberWithoutCountryCode(address.phone), name: '' },
        showDuplicateOrderModal: false
      }
    }

    componentDidMount () {
      const { newOrder, history, match } = this.props
      const { paymentType } = this.state
      // this.props.fetchCart();
      this.props.fetchOffers()
      this.props.fetchConfig()
      const querySearch = this.props.location.search
      if (!newOrder.cart) {
        history.push(`${match.params.language}/checkout${querySearch}`)
      } else if (!!newOrder.cart && !newOrder.address) {
        history.push(`${match.params.language}/checkout/address${querySearch}`)
      } else if (!!newOrder.cart && !!newOrder.address) {
        this.handlePaymentMethodsAvailable()
      }
    }

    componentWillUnmount () {
      clearInterval(this.intervalId)
    }

    otpTimerStart = () => {
      clearInterval(this.intervalId)
      this.setState({ otpTimer: this.OTP_TIMEOUT })
      this.intervalId = setInterval(() => {
        this.setState(
          prevState => ({ otpTimer: prevState.otpTimer - 1 }),
          () => {
            if (this.state.otpTimer <= 0) {
              clearInterval(this.intervalId)
            }
          }
        )
      }, 1000)
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { orders, orders: { detail }, history, updateNewOrder, match, profile, history: { location: { search } }, cart } = this.props
      const { method } = this.state

      if (!orders.isCreating && prevProps.orders.isCreating && !orders.error && !orders.retry) {
        this.props.fetchCart()
        this.props.fetchConfig()
        const { checkout, cid, cart, order_total } = detail
        this.setState({ ordersMessage: null })
        if (checkout.razorpay_order_id) {
          history.push(`${match.params.language}/profile/orders/${cid}/?payment=true&method=${method}`)
        } else {
          updateNewOrder({})
          Ecommerce.purchase({ order: detail })
          trackPurchase(detail)
          history.push(`${match.params.language}/checkout/success/?cid=${cid}`)
        }
      }

      if (!orders.isCreating && prevProps.orders.isCreating && (orders.error || orders.retry)) {
        this.props.fetchConfig()
        this.setState({ ordersMessage: orders.message, showDuplicateOrderModal: false })
      }

      if (!profile.isFetchingLoginOTP && prevProps.profile.isFetchingLoginOTP && !profile.error && !profile.retry) {
        // remove showOtpField from setState after corona
        this.setState({ message: null, showOtpField: true })
        this.otpTimerStart()
      }

      if (!profile.isFetchingLoginOTP && prevProps.profile.isFetchingLoginOTP && profile.error && !profile.retry) {
        this.setState({ message: profile.message })
      }

      if (!profile.isFetchingLogin && prevProps.profile.isFetchingLogin && !profile.error && !profile.retry) {
        this.setState({ message: null })
        this.handleOrderCheckout()
        // this.handleSubmitOrderQuery();
      }

      if (!profile.isFetchingLogin && prevProps.profile.isFetchingLogin && profile.error && !profile.retry) {
        this.setState({ message: profile.message })
      }
    }

    handleCartUpdate = (data) => {
      this.props.updateCart(data)
    };

    handleOrderCheckout = () => {
      const { address: { id: address_id }, cart: { id: cart_id, detail } } = this.props.newOrder
      const { cart, profile, config: { is_otp_enabled_on_checkout } } = this.props
      const { paymentType } = this.state

      const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email

      Ecommerce.onCheckout({
        items: cart.detail.items,
        step: 3,
        option: paymentType,
        eventCallback: () => {
          if (!!isAuthenticated || !is_otp_enabled_on_checkout) {
            const data = { cart_id, address_id, payment_type: paymentType }
            this.props.createOrder(data)
          } else {
            this.sendOTP()
          }
        }
      })
    };

    handleCheckPreviousOrder = () => {
      const { cart: { detail: { previous_order } } } = this.props

      if (!!previous_order && !!previous_order.items.length) {
        this.setState({ showDuplicateOrderModal: true })
      } else {
        this.handleOrderCheckout()
      }
    };

    handleOrderQuerySubmit = () => {
      const { profile, cart } = this.props
      const isAuthenticated = !!profile.detail.phone_number || !!profile.detail.email

      if (isAuthenticated) {
        this.handleSubmitOrderQuery()
      } else {
        this.sendOTP()
      }
    };

    sendOTP = () => {
      const { newOrder: { address } } = this.props
      this.setState({ otp: '' })
      // this.setState({showOtpField: true, otp: ""});
      // this.props.userLoginOtp({phone_number: getPhoneNumberWithCountryCode(address.phone)});
      this.props.userLoginOtp({ phone_number: getPhoneNumberWithCountryCode(this.state.orderquery.phone) })
    };

    sendOTPOnCall = () => {
      const { newOrder: { address } } = this.props
      this.setState({ otp: '' })

      this.props.userLoginOtp({
        phone_number: getPhoneNumberWithCountryCode(this.state.orderquery.phone),
        is_otp_on_call: true
      })
    };

    login = () => {
      const { newOrder: { address } } = this.props
      const { otp } = this.state
      this.props.userLogin({ phone_number: getPhoneNumberWithCountryCode(this.state.orderquery.phone), otp, email: address.email })
    };

    handlePaymentMethodsAvailable = async () => {
      const { newOrder: { address, cart } } = this.props
      let { paymentType } = this.state
      const querySearch = getSearchParams(this.props.location.search)
      let allowedPaymentMethod = { cod: false, online: false }
      const allow_all_pincodes = querySearch.utm_source === 'google' || querySearch.aa === 'true'
      const data = {
        address_id: address.id,
        cart_id: cart.id,
        allow_all_pincodes
      }
      try {
        const res = await api.ecommerce.addressavailablemethods.get(data)
        const result = await res.json()
        if (res.status === 200) {
          allowedPaymentMethod = result
        }
      } catch (e) {
      }
      if (!allowedPaymentMethod.cod && paymentType === paymentTypes.cod) {
        paymentType = paymentTypes.online
      }
      this.setState({ allowedPaymentMethod, paymentType })
      this.handleCartUpdate({ payment_type: paymentType })
    };

    handleOTPChange = otp => {
      this.setState({ otp: otp }, () => {
        if (otp && otp.length === 4) {
          this.login()
        }
      })
    };

    handleSubmitOrderQuery = async () => {
      try {
        const res = await api.support.orderquery({
          name: this.state.orderquery.name,
          phone: getPhoneNumberWithCountryCode(this.state.orderquery.phone)
        })
        const result = await res.json()
        if (res.status === 201) {
          this.setState({ receivedOrderQuery: true, showOtpField: false })
        } else {
          this.setState({ message: result })
        }
      } catch (e) {
        console.warn(e.message)
      }
    };

    handleCODClick = () => {
      this.setState({ paymentType: paymentTypes.cod })
      this.handleCartUpdate({ payment_type: paymentTypes.cod })
    };

    handleOnlineClick = ({ method }) => {
      this.setState({ paymentType: paymentTypes.online, method })
      this.handleCartUpdate({ payment_type: paymentTypes.online, payment_method: method })
    };

    isCODVisible = () => {
      const { allowedPaymentMethod } = this.state
      const querySearch = getSearchParams(this.props.location.search)
      return !!allowedPaymentMethod && allowedPaymentMethod.cod && (querySearch.pm ? querySearch.pm === 'c' : true)
    }

    isOnlineVisible = () => {
      const { allowedPaymentMethod } = this.state
      const querySearch = getSearchParams(this.props.location.search)
      return !!allowedPaymentMethod && allowedPaymentMethod.online && (querySearch.pm ? querySearch.pm === 'o' : true)
    }

    showAmazonProducts = () => {
      const { cart: { detail, isFetching }, match, history } = this.props
      const amazonProducts = {
        ayurperform: {
          url: 'https://amzn.to/3meVcGu'
        }
      }
      if (!this.isCODVisible()) {
        return detail.items.map(
          (item) => {
            const product_id = item.item.product_id
            if (amazonProducts[product_id]) {
              return (
                            <div key={product_id}>
                                <a href={amazonProducts[product_id].url}
                                   className="btn-amazon"
                                   target="_blank" rel="noopener noreferrer">
                                    <img src={Images.amazonLogo}
                                         alt="Amazon"/>
                                    <span>Buy from amazon</span>
                                </a>
                            </div>
              )
            }
            return null
          }
        )
      }
      return null
    }

    render () {
      const { offer, offers, match, orders: { isCreating }, cart } = this.props
      const { allowedPaymentMethod, otp, message, showOtpField, otpTimer, orderquery, showDuplicateOrderModal, paymentType, method } = this.state

      const ordersMessage = getMessages(this.state.ordersMessage)

      const isOnlineVisible = this.isOnlineVisible()
      const isCODVisible = this.isCODVisible()

      let offerList
      if (offer) {
        offerList = [offer]
      } else {
        offerList = offers.list
      }

      const orderTotal = getPrice(parseFloat(cart.detail.subtotal || 0))

      return (
            <Page meta={{
              title: 'Shop quality Misters health products',
              path: `${match.params.language}/checkout/payment/`,
              description: 'This space houses tried, trusted and tested Misters products formulated by experts and specialists of the field. Ideal recourse to boost immunity, manage hair loss, maintain erections and control ejaculation can be found here. Discover scientifically enriching, natural, ancient Ayurvedic herbs like Ashwagandha, Shilajit, Gokshura, Shatavari, Kaunch Beej Kesar and more, moulded for modern-day usage. Some among their vast range of benefits include an enhancement of libido, improved performance in bed, strong penile tissue and healthy testosterone levels.'
            }}
                  footerVariant={'checkout'} showNavbar={false} showWhatsapp={false}>

                {showDuplicateOrderModal &&
                <DuplicateOrderModal show={showDuplicateOrderModal}
                                     handleOrderCheckout={this.handleOrderCheckout}
                                     handleClose={() => this.setState({ showDuplicateOrderModal: false })}/>}
                <OrderLoading show={isCreating}/>
                <div className="container">
                    <div className="payment">
                        <CartState step={3}/>
                        <h3 className='title'>
                            {!showOtpField && <LText k="PAYMENT_OPTIONS"/>}
                        </h3>
                        {
                            !paymentType && (
                                <p>Please select a payment method</p>
                            )
                        }
                        <div className="row">
                            {
                                !showOtpField && !allowedPaymentMethod &&
                                <div className="col-lg-8 col-12 text-center mt-3">
                                    <Ripple show={true}/>
                                </div>
                            }
                            <div className="col-lg-8 col-12">
                                {/* {!!offerList.length && !showOtpField && ( */}
                                {/*    <ul className="checkout-offers"> */}
                                {/*        {offerList.map( */}
                                {/*            (offer) => ( */}
                                {/*                <li key={offer.id}> */}
                                {/*                    <img src={Images.logo.x1} alt="M"/> */}
                                {/*                    {offer.text} */}
                                {/*                </li> */}
                                {/*            ) */}
                                {/*        )} */}
                                {/*    </ul> */}
                                {/* )} */}
                                {!showOtpField && false && <p className="text-danger">
                                    We are not accepting any orders owing to Covid-19 lockdown.
                                    <br/>
                                    Please leave your name and contact number in this form, and we will reach out to you
                                    once we start shipping.
                                </p>}
                                {
                                    !showOtpField && !this.state.receivedOrderQuery && false &&
                                    <div className="text-center">
                                        <FormInput placeholder='Name'
                                                   value={orderquery.name}
                                                   error={message && message.name}
                                                   onChange={(e) => this.setState({
                                                     orderquery: { ...orderquery, name: e.target.value }
                                                   })}/>
                                        <br/>
                                        <FormInput placeholder='Phone'
                                                   value={orderquery.phone}
                                                   error={message && message.phone_number}
                                                   onChange={(e) => this.setState({
                                                     orderquery: { ...orderquery, phone: e.target.value }
                                                   })}/>
                                        <br/>
                                        <Button onClick={this.handleOrderQuerySubmit}>Submit</Button>
                                    </div>
                                }
                                {
                                    !showOtpField && !!this.state.receivedOrderQuery &&
                                    <p className="text-success">
                                        Thank you, we will reach out to you once we start shipping.
                                    </p>
                                }
                            </div>

                            {!showOtpField && !!allowedPaymentMethod &&
                            <div className="col-lg-8 col-12">
                                {
                                    !isCODVisible &&
                                    isOnlineVisible &&
                                    <small className="text-danger">
                                        <LText k="ONLINE_PAYMENT_ONLY_MESSAGE"/>
                                    </small>
                                }
                              {
                                paymentType === paymentTypes.cod && orderTotal > 1598 &&
                                <small className="text-danger">
                                  Our customer support staff will call to confirm your order.
                                </small>
                              }
                                <ul className="payment-methods">
                                    {
                                        isOnlineVisible &&
                                        <React.Fragment>
                                          <PaymentMethodCard title="Card" text="Visa, MasterCard, RuPay & More"
                                                             onClick={() => this.handleOnlineClick({ method: paymentMethods.card })}
                                                             value={paymentType === paymentTypes.online && method === paymentMethods.card}
                                                             ListStyleComponent={Cards}/>
                                            <PaymentMethodCard title="Wallet" text="PhonePe, Freecharge & more"
                                                               onClick={() => this.handleOnlineClick({ method: paymentMethods.wallet })}
                                                               ListStyleComponent={Wallet}
                                                               value={paymentType === paymentTypes.online && method === paymentMethods.wallet}/>
                                            <PaymentMethodCard title="UPI/QR" text="Instant payment using UPI App"
                                                               onClick={() => this.handleOnlineClick({ method: paymentMethods.upi })}
                                                               ListStyleComponent={Upi}
                                                               value={paymentType === paymentTypes.online && method === paymentMethods.upi}/>
                                            <PaymentMethodCard title="Netbanking" text="All Indian banks"
                                                               onClick={() => this.handleOnlineClick({ method: paymentMethods.netbanking })}
                                                               ListStyleComponent={Netbanking}
                                                               value={paymentType === paymentTypes.online && method === paymentMethods.netbanking}/>

                                            <PaymentMethodCard title="EMI" text="EMI via Credit & Debit Cards"
                                                               onClick={() => this.handleOnlineClick({ method: paymentMethods.emi })}
                                                               ListStyleComponent={EMI}
                                                               value={paymentType === paymentTypes.online && method === paymentMethods.emi}/>
                                            {/* <PaymentMethodCard title="PayLater" text="Buy Now Pay Later" */}
                                            {/*                   onClick={() => this.handleOnlineClick({method: paymentMethods.paylater})} */}
                                            {/*                   ListStyleComponent={Netbanking} */}
                                            {/*                   value={paymentType === paymentTypes.online && method === paymentMethods.paylater}/> */}

                                            {/* <PaymentMethodCard title="Cardless EMI" text="" */}
                                            {/*                   onClick={() => this.handleOnlineClick({method: paymentMethods.cardless_emi})} */}
                                            {/*                   ListStyleComponent={Netbanking} */}
                                            {/*                   value={paymentType === paymentTypes.online && method === paymentMethods.cardless_emi}/> */}
                                        </React.Fragment>
                                    }
                                  {
                                    isCODVisible &&
                                    <PaymentMethodCard title="Cash on delivery"
                                                       text="Pay in cash to the delivery agent"
                                                       onClick={this.handleCODClick}
                                                       ListStyleComponent={
                                                         () => <img src={Images.money} alt="cash on delivery"/>
                                                       }
                                                       value={paymentType === paymentTypes.cod}/>
                                  }
                                </ul>

                                {!!ordersMessage && !!ordersMessage.length &&
                                ordersMessage.map((error, index) => (
                                    <p key={index} className="text-danger">{error}</p>))
                                }

                                {!isOnlineVisible && !isCODVisible &&
                                <p className="text-danger">
                                    <LText k="CURRENTLY_NOT_SERVING_ON_SELECTED_AREA"/>
                                </p>
                                }
                                {this.showAmazonProducts()}
                            </div>}

                            {showOtpField &&
                            <div className="col-12 mt-3 text-center">
                                <p>
                                    {/*  <LText k="PLEASE_ENTER_THE_OTP_RECEIVED_ON_YOUR_MOBILE_TO_COMPLETE_THIS_ORDER"/>
                                    <br/>{address.phone} */}
                                    Please enter the OTP received on your mobile
                                    <br/>{orderquery.phone}
                                </p>
                                <div className="pb-3">
                                    <OtpInput onChange={this.handleOTPChange} numInputs={4}
                                              value={otp} shouldAutoFocus
                                              inputStyle={`opt-input ${!!message && !!message.otp && 'error'}`}
                                              containerStyle={'justify-content-center'}/>
                                    {!!message && !!message.otp &&
                                    <div className="text-danger text-center mt-3">{message.otp}</div>}
                                </div>
                                {!!message && !!message.message && <div className="error-text">{message.message}</div>}
                                {
                                    !!otpTimer &&
                                    <div className="otp-resend-text">
                                        <LText k="RESEND_OTP_TEXT"
                                               replaceKeys={[{ key: 'time', value: otpTimer }]}/>
                                    </div>
                                }
                                <Consent/>
                                {
                                    !otpTimer &&
                                    <div className="text-center">
                                        <Button onClick={this.sendOTP}
                                                style={{ marginBottom: 15, minWidth: '270px' }}>
                                            <LText k="RESEND_OTP"/>
                                        </Button>
                                        <br/>
                                        <Button variant="secondary" style={{ minWidth: '270px' }}
                                                className="btn-get-otp-on-call"
                                                onClick={this.sendOTPOnCall}>
                                            <LText k="GET_OTP_ON_CALL"/>
                                        </Button>
                                    </div>
                                }
                            </div>}
                            {
                                !showOtpField &&
                                <div className="col-lg-4 col-12 d-none d-lg-block">
                                    <OrderSummaryCard showPaymentType={true} showOfferDiscount={true}
                                                      showApplyCoupon={false}/>
                                    <button className='checkout-button btn-m btn-m-red'
                                            disabled={!allowedPaymentMethod || (!!allowedPaymentMethod && !isOnlineVisible && !isCODVisible)}
                                            onClick={this.handleCheckPreviousOrder}>
                                        {
                                            paymentType === paymentTypes.online &&
                                            <LText k="MAKE_PAYMENT"/>
                                        }
                                        {
                                            paymentType === paymentTypes.cod &&
                                            <LText k="CONFIRM_ORDER"/>
                                        }
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    !showOtpField &&
                    <div className="payment-footer d-lg-none d-block">
                        <OrderSummaryCard showPaymentType={true} showOfferDiscount={true}
                                          showTitle={false} showApplyCoupon={false}/>
                        <button className='checkout-button btn-m btn-m-red mt-0'
                                disabled={!allowedPaymentMethod || (!!allowedPaymentMethod && !isOnlineVisible && !isCODVisible)}
                                onClick={this.handleCheckPreviousOrder}>
                            {
                                paymentType === paymentTypes.online &&
                                <LText k="MAKE_PAYMENT"/>
                            }
                            {
                                paymentType === paymentTypes.cod &&
                                <LText k="CONFIRM_ORDER"/>
                            }
                        </button>
                    </div>
                }
                <div className="py-5"/>
            </Page>
      )
    }
}

const mapStateToProps = state => ({
  cart: state.ecommerce.cart,
  config: state.ecommerce.cart.config,
  profile: state.accounts.profile,
  orders: state.ecommerce.orders,
  newOrder: state.ecommerce.orders.newOrder,
  offers: state.ecommerce.offers,
  offer: state.ecommerce.cart.detail.offer
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchCart,
  deleteCartItem,
  createOrder,
  fetchConfig,
  updateCartCoupon,
  updateNewOrder,
  updateCart,
  fetchOffers,
  userLogin,
  userLoginOtp
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Payment)
