import React, { Component } from 'react'

import Page from '../../components/core/Page'
import { Images } from '../../constants'
import Button from '../../components/buttons/Button'
import Modal from '../../components/modals/Modal'
import { getInnerText, removeLinks, truncChars } from '../../utils'
import { articleList } from './data'
import script from './script'

class IntimacyTherapy extends Component {
  componentDidMount () {

  }

  componentWillUnmount () {

  }

    state = {
      showAppointmentModal: false,
      article: {}
    };

    render () {
      const { match } = this.props
      // console.warn(match.params.language);

      return (
            <Page meta={{
              title: 'Peronalised COVID-19 expert sessions on intimacy in isolation | Misters',
              path: `${match.params.language}/covidvirus/intimacy-therapy/`,
              description: 'Sign up for an expert led one-on-one workshop on intimacy in the times of the Coronavirus pandemic…'
            }} script={script}>
                <div className="therapist">
                    <div className="container">
                        <div className="row py-5">
                            <div className="col-12 text-md-center">
                                <h1 className="text-center">Intimacy during isolation</h1>
                                <p className="mb-0">
                                    Sign up for an expert led one-on-one workshop on intimacy in the times of the
                                    Coronavirus pandemic…
                                </p>
                            </div>
                            <div className="col-12 col-md-6 offset-md-3">
                                <img src={Images.intimacy.intimacy_main} alt="intimacy_main"
                                     className="therapist-image"/>
                            </div>
                        </div>
                        <div className="row pb-5">
                            <div className="col-12">
                                <h2>Feeling lockdown loneliness is completely normal</h2>
                                <p>
                                    The <b>anxiety induced by the recent nation-wide lockdown</b> is real. With an <b>increased
                                    amount of time</b> on our hands, one has an <b>inherent tendency to
                                    communicate with another individual</b>. Man is a social animal, after all.
                                    <br/>
                                    <br/>
                                    This is a space that offers you the chance to mindfully explore human relationships
                                    when you actually have the time. To be intimate in the times of isolation is hard
                                    but it does help if you have a <b>discreet, licensed expert to speak to</b>.
                                    Somebody who specializes in the field of listening to others.
                                    <br/>
                                    <br/>
                                    While all of us dote on our loved ones who serve as great support systems, they may
                                    not be the best candidates to offer advice on intimate matters.
                                </p>

                                <br/>
                                <h2>Meet Rohan Pasricha - the man of the hour:</h2>

                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6 p-3 text-center">
                                        <img src={Images.therapist} alt="Therapist"
                                             className="therapist-image"/>
                                    </div>
                                </div>

                                <p>
                                    Rohan has a <b>postgraduate diploma in Counselling Psychology from the Xavier
                                    Institute of Counselling Psychology (XICP)</b>, accompanied by an advanced
                                    diploma in Integrative Counselling from The Institute of Human Technology,
                                    Mumbai.
                                    <br/>
                                    <br/>
                                    He is certified in the Robert Carkhuff model of counselling, Rogerian therapy,
                                    Rational Emotive Behaviour Therapy (REBT) and Transactional Analysis (TA).
                                    <br/>
                                    <br/>
                                    Rohan currently runs a private practice in Mumbai, where he successfully
                                    assists clients from all walks of life, to cope with myriad concerns.
                                    <br/>
                                    <br/>
                                    In addition, he also counsels at St. Catherine of Siena School and Orphanage, in
                                    Bandra, Mumbai mainly working with children and young adults.
                                </p>
                            </div>
                        </div>

                        <div className="row pb-5">
                            <div className="col-12 pt-3 pb-1 d-flex flex-column justify-content-center">
                                <h3>Here’s what Rohan has to say about it:</h3>
                                <p>
                                    <i>
                                        “I am the one you can have all those seemingly “awkward” conversations with –
                                        except that there’s nothing awkward about it after all. I will be a confidante
                                        and guide to you – but most importantly, I’ll help you realise that all the
                                        solutions lie within you.
                                        <br/>
                                        <br/>
                                        If you are skeptical about whether these sessions really work, I can understand
                                        you. But if you trust me, I’ll show you.
                                        <br/>
                                        <br/>
                                        So just click on an available date and time slot of your choice from the
                                        calendar below, and I will call you then.”
                                    </i>
                                </p>
                            </div>
                        </div>

                        <div className="row pb-5">
                            <div className="col-12">
                                <h2>What’s so comforting about this process? </h2>
                                <ul>
                                    <li>Misters offers you confidential expert consultations completely free of cost,
                                        for a limited time period.
                                    </li>
                                    <br/>
                                    <li>The frequency of multiple sessions will be limited to no more than once a week.
                                        Two sessions a week will only be considered under exceptional circumstances.
                                        These sessions are conducted as per the global therapy guidelines framework
                                    </li>
                                    <br/>
                                    <li>Click on book an appointment to finalize the timing of your personalized
                                        session.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <h5>Click below, to read our short and informative research piece on
                            intimacy in the times of isolation and remember to book an appointment with our expert.</h5>
                        <div className="row mb-5">
                            {articleList.map((article, index) => (
                                <div key={index} className="col-12 col-md-6 col-lg-4"
                                     onClick={() => this.setState({ article })}>
                                    <div className="post-card">
                                        {
                                            article.image &&
                                            <div className="post-card-image">
                                                <img src={article.image} alt=""/>
                                            </div>
                                        }
                                        <div className="post-card-content">
                                            <div className="post-card-head">
                                                <h4 className="post-card-title">{article.title}</h4>
                                                <p className="post-card-text">{truncChars(removeLinks(getInnerText(article.content)), 100)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <p>
                            These one-on-one sessions will surely make you feel less lonely in an uncertain time. All of
                            us like to be listened to. So give an expert the privilege to do so!
                        </p>

                        <div className="row">
                            <div className="col-12 text-center py-5">
                                <iframe className="youtubeIframe"
                                        src="https://www.youtube.com/embed/FWBoC5af4n0?loop=1&playlist=FWBoC5af4n0&rel=0"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen frameBorder="0"/>
                            </div>
                        </div>
                    </div>

                    <Modal className="h-100 overflow-hidden px-2" show={true}
                           showModal={this.state.showAppointmentModal}
                           style={this.state.showAppointmentModal
                             ? {
                                 zIndex: '10002', paddingTop: '50px'
                               }
                             : { transform: 'translate(100%, 0)' }}
                           closeButtonStyle={{ top: 0 }}
                           handleCloseClick={() => this.setState({ showAppointmentModal: false })}>
                        <div className="pb-5 h-100">
                            <a id="appointment" href="#"/>
                            <h2 className="text-center px-3">Book an appointment</h2>
                            <div className="calendly-inline-widget h-100"
                                 data-url="https://calendly.com/mistersdotin/intimacy-consulting-with-rohan-pasricha"/>
                        </div>
                    </Modal>

                    <Modal show={!!this.state.article.title}
                           handleCloseClick={() => this.setState({ article: {} })}>
                        <div className="container pb-5">
                            <h2 className="mb-5 text-center">{this.state.article.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: this.state.article.content }}/>

                            <div className="row justify-content-center">
                                <div className="col-12 col-md-6 p-3 text-center">
                                    <img src={this.state.article.innerImage} alt={this.state.article.title}
                                         className="therapist-image"/>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Button style={{
                      position: 'fixed',
                      bottom: 20,
                      right: '50%',
                      transform: 'translate(50%, 0)',
                      zIndex: '10001',
                      whiteSpace: 'nowrap'
                    }}
                            onClick={() => this.setState({ showAppointmentModal: true })}>Book an appointment</Button>
                </div>
            </Page>
      )
    }
}

export default IntimacyTherapy
