import { getPrice } from '../../utils'

export default ({ product, url }) => {
  const rating = product.ratings_star_count / (product.ratings_count || 1)
  const productPrice = getPrice(product.price)

  const schemaTagInnerHTML = ({
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.title,
    image: product.images,
    description: product.short_description,
    sku: product.product_id,
    mpn: product.hsn_code,
    brand: {
      '@type': 'Brand',
      name: 'Misters'
    },
    review: product.reviews.length
      ? product.reviews.map(
        review => ({
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: review.star.toString(),
            bestRating: '5'
          },
          author: {
            '@type': 'Person',
            name: review.name
          }
        })
      )
      : undefined,
    aggregateRating: rating
      ? {
          '@type': 'AggregateRating',
          ratingValue: rating.toFixed(1),
          reviewCount: product.ratings_count
        }
      : undefined,
    offers: {
      '@type': 'Offer',
      url: url,
      priceCurrency: 'INR',
      price: productPrice.toFixed(2),
      // "priceValidUntil": "2020-11-20",
      itemCondition: 'https://schema.org/NewCondition',
      availability: 'https://schema.org/InStock'
    }
  })

  return {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(schemaTagInnerHTML)
  }
}
