import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import ImageService from '../../../services/ImageService'

class HISScore extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props

    return (
            <Page meta={{
              title: 'HIS(Herbal Ingredient Stack) Score | Misters',
              path: `${match.params.language}/pages/herbal-ingredient-stack-score/`,
              description: 'Herbal Ingredient Stack Score or HIS Score is a novel concept developed by Misters to measure the efficacy of any product and help customers choose the best that suits their needs.'
            }}>
                <div className="ingredient-header-img"
                     style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>HIS(Herbal Ingredient Stack) Score</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What is Herbal Ingredient Stack Score?</h2>
                            <p>
                                Herbal Ingredient Stack Score or HIS Score is a novel concept developed by Misters to
                                measure the efficacy of any product and help customers choose the best that suits their
                                needs. HIS Score is calculated by looking at three objective parameters for any product
                                – The Therapeutic Score, the Scientific Research Score and the Stack Value. The method
                                of calculation is described below.
                            </p>

                            <h2>Why HIS Score?</h2>
                            <p>
                                The idea of HIS Score was born when our team was researching for ways to bring
                                scientific validation to traditional knowledge systems like Ayurveda, Unani and others.
                                As a company that believes in bringing the best of both the worlds – modern science and
                                ancient knowledge, we believe it is important to build products that has scientific
                                relevance while adhering to the process described by ancient texts.
                                <br/>
                                <br/>
                                HIS Score also enables customers to better understand the quality of any product,
                                compare it with others in the market and thereby choose the product that suits
                                their needs the best.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>How to calculate it?</h2>
                            <p>
                                HISS is the sum of the average of <strong>Therapeutic score and the Scientific Research
                                Score</strong> of
                                the individual ingredients and the overall <strong>Stack Value</strong>.
                                <br/>
                                <br/>
                                HIS Score = Avg (Ingredient 1(Therapeutic Score + Scientific Research Score) +
                                Ingredient 2(Therapeutic Score + Scientific Research Score) +… + Ingredient
                                n(Therapeutic Score + Scientific Research Score) )+ Stack Value
                                <br/>
                                <br/>
                                Therapeutic Score is given 50% precedence, 30% to Scientific Research Score and Stack
                                value is given the other 20% precedence.
                            </p>

                            <h6>1. Therapeutic score:</h6>
                            <p>
                                To calculate the therapeutic score. First, a therapeutic window is set for each
                                ingredient. This window is made after doing extensive literature research. Clinical
                                studies are given first preference to set it.
                                <br/>
                                <br/>
                                The therapeutic window (or pharmaceutical window) of a drug is the range of drug dosages
                                which can treat disease effectively without having toxic effects.
                            </p>
                            <div className="text-center"><img src={ImageService.pages.hisScoreFigure1} className="img-fluid"
                                                              alt="his-score-figure-1"/></div>
                            <p>Every ingredient has a set of two doses; minimum effective dose (low dose) and Maximum
                                effective safe dose (High dose).</p>
                            <div className="text-center"><img src={ImageService.pages.hisScoreFigure2} className="img-fluid"
                                                              alt="his-score-figure-2"/></div>

                            <p>
                                <p>
                                    <span>The score is calculated by looking at the dosage of the ingredient
                                        per serving of the product and comparing it with the Maximum Effective
                                        Dose of the Therapeutic window, as follows.&nbsp;</span>
                                </p>
                                <p><strong><em>Therapeutic Score</em></strong><em><span> = (Dosage/</span></em><span> Maximum Effective Dose</span><em><span>) * 50</span></em>
                                </p>
                                <p><span><br/></span><span>If the product has a dosage lower than the Minimum Effective Dose or higher than the Maximum Effective Dose, it gets a zero score.</span><span><br/></span><span>Eg:</span><span><br/></span><span>For Shilajit, the therapeutic window is between 200mg to 500mg,</span><span><br/></span><span>A product that has 400mg of Shilajit will get a score of (400/500) * 50, i.e 40. </span>
                                </p>
                            </p>

                            <h6>2. Scientific Research Score</h6>
                            <p>
                                The Scientific Research Score is a measure of the quality of evidence available for the
                                ingredients used in the product for the relevant use case. For example, a product
                                claiming to help reduce stress will have a high research score if it uses a
                                well-researched ingredient like Ashwagandha.
                                <br/>
                                <br/>
                                The value of the score is determined as follows,

                                <ol>
                                    <li>If there’s clinical evidence for the claims made on the ingredient, a score of 30 is
                                        given.</li>
                                    <li>If there’s non-clinical evidence for the claims made on the ingredient, a score of 15 is
                                        given.</li>
                                    <li>If there’s no evidence, a score of 0 is given.</li>
                                </ol>

                            </p>

                            <h6>3. Stack Value</h6>

                            <p>
                                <p><span>The stack value of the score is an important indicator that communicates the value of the ingredient stack used, in other words it determines whether the stacking is scientifically relevant or not.&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <p><span>For example, stacking Shilajit and Ashwagandha for men&rsquo;s sexual wellness will have a high stack value as compared to stacking Shilajit with Shatavari, as Shatavari doesn&rsquo;t have scientific evidence for the men&rsquo;s wellness use case.</span></p>
                                <p>&nbsp;</p>
                                <p><span>Stack value is calculated as follows,</span></p>
                                <p><span><br /></span><strong><em>Stack Value </em></strong><em><span>= (m/n)*20</span></em></p>
                                <p><span>m is the total number of ingredients with a positive scientific research score</span></p>
                                <p><span>n is the total number of ingredients in the stack.</span></p>
                                <p><br /><strong>Scores:</strong><span><br /></span><span>Here&rsquo;s the list of Misters products with HISS Score compiled.</span></p>
                            </p>
                            <div className="text-center"><img src={ImageService.pages.hisScoreFigure3} className="img-fluid"
                                                              alt="his-score-figure-3"/></div>

                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={'SHOP'}/>
            </Page>
    )
  }
}

export default HISScore
