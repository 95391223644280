import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { fetchProduct } from '../../../actions/ecommerce/products'
import { createCartItem } from '../../../actions/ecommerce/cart'
import Page from '../../../components/core/Page'
import AuthenticatedContainer from '../../../components/containers/AuthenticatedContainer'
import { WEBSITE_HOST } from '../../../constants'
import ProductHowToUse from '../../../components/cards/ProductHowToUse'

import { getLanguageTranslation } from '../../../utils'
import WhyMisters from '../../../components/others/WhyMisters'
import TherapyReviews from '../Components/TherapyReviews'
import ProductFaq from './ProductFaq'
import Ecommerce from '../../../services/Ecommerce'
import SchemaService from '../../../services/SchemaService'
import Ingredients from '../../../components/others/Ingredients'
import HowAyurvedaWorks from '../../../components/others/HowAyurvedaWorks'
import Section3 from '../../Solution/Section3'
import Section1 from './Section1'
import ProductReviews from '../../../components/others/ProductReviews/ProductReviews'
import { isRouteEqual } from '../../../routes'

class Product extends PureComponent {
  static fetching ({ dispatch, match }) {
    const { productId } = match.params
    return [dispatch(fetchProduct({ product_id: productId }))]
  };

  static propTypes = {
    fetchProduct: PropTypes.func,
    createCartItem: PropTypes.func,
    products: PropTypes.object,
    cart: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object
  }

  constructor (props) {
    super(props)

    this.state = {
      quantity: 1
    }
  }

  componentDidMount () {
    this.handleFetchProduct()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { products, cart, history, match } = this.props

    if (!cart.isCreatingCartItem && prevProps.cart.isCreatingCartItem && !cart.error && !cart.retry) {
      history.push(`${match.params.language}/checkout`)
    }

    if (!products.isFetching && prevProps.products.isFetching && !products.error && !products.retry) {
      Ecommerce.productPageView({ products: [products.detail] })
    }
  }

  handleFetchProduct = () => {
    const { productId } = this.props.match.params
    this.props.fetchProduct({ product_id: productId })
  };

  getCanonicalUrl = () => {
    const { match } = this.props
    return `${WEBSITE_HOST}${match.params.language}/therapy/${match.params.productId}`
  }

  render () {
    const { products: { detail: product }, match } = this.props
    const language = match.params.language
    if (Object.keys(product).length === 0) {
      return null
    }
    const pageScripts = [SchemaService.getProductSchema({ product, url: this.getCanonicalUrl() })]

    const title = getLanguageTranslation(product, 'title', language) || ''
    const description = getLanguageTranslation(product, 'short_description', language) || ''

    const metaTitle = getLanguageTranslation(product, 'meta_title', language) || title
    const metaDescription = getLanguageTranslation(product, 'meta_description', language) || description

    const isCallNowProductPage = isRouteEqual(match.path, 'Product2')

    return (
      <AuthenticatedContainer>
        <Page meta={{
          title: metaTitle,
          path: this.getCanonicalUrl(),
          description: metaDescription,
          image: product.image || undefined
        }} script={pageScripts} isScrollToTop={false}>
          <div className="product-container">
            <Section1 product={product} isCallNowProductPage={isCallNowProductPage}/>
            <Section3 product={product}/>
            {
              !!product.featured_video_url &&
              <div className="bg-light py-5">
                <div className="container">
                  <div className="row">
                    {/* <div className="col-12"> */}
                    {/*  <p className="product-short-description"> */}
                    {/*    {getLanguageTranslation(product, 'description', language)} */}
                    {/*  </p> */}
                    {/* </div> */}
                    <div className="col-12 d-flex justify-content-center pt-2">
                      <iframe className="product-video"
                              src={`${product.featured_video_url}?loop=1&rel=0`}
                              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen frameBorder="0"/>
                    </div>
                  </div>
                </div>
              </div>
            }
            <ProductReviews reviews={product.featured_reviews}/>
            {
              product.is_ayurvedic &&
              <HowAyurvedaWorks/>
            }
            <Ingredients ingredients={product.ingredients} />
            <ProductHowToUse product={product}/>
            <ProductFaq faqs={product.faqs}/>
            <WhyMisters />
            <TherapyReviews productId={product.product_id}/>
          </div>
        </Page>
      </AuthenticatedContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  products: state.ecommerce.products,
  cart: state.ecommerce.cart
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchProduct,
  createCartItem
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Product)
