import {
  FETCH_RECENT_POSTS,
  FETCH_RECENT_POSTS_RESULT,
  FETCH_RECENT_POSTS_ERROR,
  FETCH_RECENT_POSTS_RETRY

} from '../../actions/blog/posts'

import {
  FETCH_RECENT_FAQS,
  FETCH_RECENT_FAQS_RESULT,
  FETCH_RECENT_FAQS_ERROR,
  FETCH_RECENT_FAQS_RETRY
} from '../../actions/blog/faqs'

const initialState = {
  ghostRecentList: {},
  isGhostRecentFetching: false,
  error: false,
  message: null,
  retry: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECENT_POSTS:
      return { ...state, isGhostRecentFetching: true, error: false, message: null, retry: false }
    case FETCH_RECENT_POSTS_RESULT:
      return { ...state, isGhostRecentFetching: false, ghostRecentList: action.data }
    case FETCH_RECENT_POSTS_ERROR:
      return { ...state, isGhostRecentFetching: false, error: true, message: action.message }
    case FETCH_RECENT_POSTS_RETRY:
      return { ...state, isGhostRecentFetching: false, retry: true, message: action.message }

    case FETCH_RECENT_FAQS:
      return { ...state, isGhostRecentFaqFetching: true, error: false, message: null, retry: false }
    case FETCH_RECENT_FAQS_RESULT:
      return { ...state, isGhostRecentFaqFetching: false, ghostRecentFaqList: action.data }
    case FETCH_RECENT_FAQS_ERROR:
      return { ...state, isGhostRecentFaqFetching: false, error: true, message: action.message }
    case FETCH_RECENT_FAQS_RETRY:
      return { ...state, isGhostRecentFaqFetching: false, retry: true, message: action.message }

    default:
      return state
  }
}
