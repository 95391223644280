import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Images } from '../../../constants'

class AnalysisEjaculationScoreCard extends Component {
    static propTypes = {
      className: PropTypes.string,
      score: PropTypes.number,
      duration: PropTypes.number,
      delay: PropTypes.number,
      offset: PropTypes.number
    };

    static defaultProps = {
      className: '',
      score: 0,
      duration: 1000,
      delay: 100,
      offset: 200
    };

    constructor (props) {
      super(props)

      this.state = {}
    }

    componentDidMount () {
    }

    componentDidUpdate (prevProps, prevState, snapshot) {

    }

    componentWillUnmount () {

    }

    render () {
      const { className, duration, delay, score, offset, percentile } = this.props

      return (
            <div className={`analysis-ejaculation-score-card ${className}`} data-aos-offset={offset}
                 data-aos="zoom-in" data-aos-duration={duration} data-aos-delay={delay} data-aos-once="true">
                <div className="ejaculation-score-timer">
                    <img src={Images.report.ejaculationScoreTimer} alt="ejaculationScoreTimer"/>
                </div>
                <div className="ejaculation-score-text-container">
                    <h1 className="ejaculation-score-count">{score}</h1>
                    <h5 className="ejaculation-score-title">Ejaculation score</h5>
                  {
                    !!percentile &&
                    <p className="report-percentile">Your score is more than {percentile}% of all users who has taken
                      this quiz</p>
                  }
                </div>
            </div>
      )
    }
}

export default AnalysisEjaculationScoreCard
