import React, { Component } from 'react'

import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import { NavLink } from 'react-router-dom'

class Kesar extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props

    return (
            <Page meta={{
              title: 'Aphrodisiac Kesar, dosage, benefits | Misters',
              path: `${match.params.language}/ingredient/kesar/`,
              description: 'Learn about Kesar, a high quality Ayurvedic herb, on the Misters Ayurveda page. Your complete Misters Ayurvedic ingredient guide has collated expert information on Kesar’s usage, dosage, benefits, description, alternative names and constituents. Kesar has vast, scientifically proven health benefits. Aphrodisiacal properties, enhancement in overall health and antidepressant properties are some results of its usage. It elevates sex life, heals wounds, improves eyesight and improve cognitivev functions. Rich in vitamins, it is found in Kashmir, Greece, Morocco and many more areas. Know your Ayurvedic ingredients to understand Misters products better.'
            }}>
                <div className="ingredient-header-img" style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
                    <div className="ingredient-header-bg-drop"/>
                    <div className="ingredient-header-content">
                        <h1>Kesar- an alluring aphrodisiac</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <h2>What is Kesar?</h2>
                            <p>
                                Kesar or saffron which emanates a gripping, earthy flavour and sports a brightly crimson
                                hue, is a <b>wonder spice</b> known for its <b>rare qualities</b> and <b>health
                                benefits</b>. It is extracted from the purple crocus flower that grows on the small,
                                bulbous, perennial plant named ‘Crocus sativus.’ Kesar is a <b>food</b>, a <b>spice</b>,
                                a <b>seasoning</b> and <b>colouring agent</b> and so much more. The plant Crocus
                                sativus, also called “saffron crocus” in common parlance, is believed to be native to
                                the eastern Mediterranean region.
                                <br/>
                                <br/>
                                Kesar is the vibrantly crimson coloured stigma and style of the flower that is
                                handpicked and sun-dried before being packed. About one pound of Kesar is extracted from
                                some thousand flowers. This makes it very rare and expensive. Once extracted, pure Kesar
                                needs to be processed and made into a product which is then sold in the market as a
                                spice.
                                <br/>
                                <br/>
                                A member of the lily family, Crocus sativus is generally a small plant, growing to a
                                maximum height of 20 to 30 cm. Each plant bears only four flowers, and each blossom
                                grows three delicate crimson stigmas (the flower part of the plant which catches the
                                pollen grains) in the centre, from which saffron or Kesar is produced. The production
                                process of Kesar is also very labour intensive as the stigmas have to be meticulously
                                chosen and separated from the white style.
                                <br/>
                                <br/>
                                Interestingly, 200,000 stigmas are manually picked from 70,000 flowers for preparing
                                just 45 kgs of saffron, and 40 hours of intense labour can collect about 150,000
                                flowers. Eventually the stigmas are cautiously laid on a colander and cured over heat.
                                This is done to intensify the aroma. This painstaking process makes the substance highly
                                expensive, so much so that Kesar is believed to be more expensive (S $5,000 per kg or
                                higher) than any other spice in the world and is often packed in small quantities of 2-5
                                gm. Such is the intensity of the spice that a pinch of it can effectively add aroma to a
                                dish or tinge it with that signature yellowish-orange colour.
                            </p>
                            <p className="text-center font-weight-bold">
                                <span>To know more about how Kesar helps improvement of libido or sex drive, </span>
                                <a className="ingredient-blog-links" target="_blank"
                                         href={`https://misters.in${match.params.language}/blog/Does-Kesar-help-improve-libido-or-sex-drive`}>
                                    click here</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            <ImageBreaker image={Images.ingredient.kesarDescription} to={shopUrl} buttonText={'SHOP'}/>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-10 offset-md-1 col-12">
                            {accordionList1}
                        </div>
                    </div>
                </div>

                <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            {accordionList2}
                        </div>
                    </div>
                </div>
            </Page>
    )
  }
}

export default Kesar
