import api from '../api'

export const FETCH_CART = 'FETCH_CART'
export const FETCH_CART_RESULT = 'FETCH_CART_RESULT'
export const FETCH_CART_ERROR = 'FETCH_CART_ERROR'
export const FETCH_CART_RETRY = 'FETCH_CART_RETRY'

export const DELETE_CART_ITEM = 'DELETE_CART_ITEM'
export const DELETE_CART_ITEM_RESULT = 'DELETE_CART_ITEM_RESULT'
export const DELETE_CART_ITEM_ERROR = 'DELETE_CART_ITEM_ERROR'
export const DELETE_CART_ITEM_RETRY = 'DELETE_CART_ITEM_RETRY'

export const CREATE_CART_ITEM = 'CREATE_CART_ITEM'
export const CREATE_CART_ITEM_RESULT = 'CREATE_CART_ITEM_RESULT'
export const CREATE_CART_ITEM_ERROR = 'CREATE_CART_ITEM_ERROR'
export const CREATE_CART_ITEM_RETRY = 'CREATE_CART_ITEM_RETRY'

export const UPDATE_CART = 'UPDATE_CART'
export const UPDATE_CART_RESULT = 'UPDATE_CART_RESULT'
export const UPDATE_CART_ERROR = 'UPDATE_CART_ERROR'
export const UPDATE_CART_RETRY = 'UPDATE_CART_RETRY'

export const FETCH_CONFIG = 'FETCH_CONFIG'
export const FETCH_CONFIG_RESULT = 'FETCH_CONFIG_RESULT'
export const FETCH_CONFIG_ERROR = 'FETCH_CONFIG_ERROR'
export const FETCH_CONFIG_RETRY = 'FETCH_CONFIG_RETRY'

export const UPDATE_CART_COUPON = 'UPDATE_CART_COUPON'
export const UPDATE_CART_COUPON_RESULT = 'UPDATE_CART_COUPON_RESULT'
export const UPDATE_CART_COUPON_ERROR = 'UPDATE_CART_COUPON_ERROR'
export const UPDATE_CART_COUPON_RETRY = 'UPDATE_CART_COUPON_RETRY'

export const UPDATE_CART_PAYMENT_METHOD = 'UPDATE_CART_PAYMENT_METHOD'
export const UPDATE_CART_PAYMENT_METHOD_RESULT = 'UPDATE_CART_PAYMENT_METHOD_RESULT'
export const UPDATE_CART_PAYMENT_METHOD_ERROR = 'UPDATE_CART_PAYMENT_METHOD_ERROR'
export const UPDATE_CART_PAYMENT_METHOD_RETRY = 'UPDATE_CART_PAYMENT_METHOD_RETRY'

export const fetchCart = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_CART })
    const res = await api.ecommerce.cart.get(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_CART_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_CART_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_CART_RETRY, message: e.message })
  }
}

export const deleteCartItem = (itemId) => async dispatch => {
  try {
    dispatch({ type: DELETE_CART_ITEM })
    const res = await api.ecommerce.cartItem.remove(itemId)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: DELETE_CART_ITEM_RESULT, data: result })
    } else {
      dispatch({ type: DELETE_CART_ITEM_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: DELETE_CART_ITEM_RETRY, message: e.message })
  }
}

export const createCartItem = (data) => async dispatch => {
  try {
    dispatch({ type: CREATE_CART_ITEM })
    const res = await api.ecommerce.cartItem.create(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: CREATE_CART_ITEM_RESULT, data: result })
    } else {
      dispatch({ type: CREATE_CART_ITEM_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: CREATE_CART_ITEM_RETRY, message: e.message })
  }
}

export const updateCart = (data) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CART })
    const res = await api.ecommerce.cartItem.update(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: UPDATE_CART_RESULT, data: result })
    } else {
      dispatch({ type: UPDATE_CART_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: UPDATE_CART_RETRY, message: e.message })
  }
}

export const fetchConfig = () => async dispatch => {
  try {
    dispatch({ type: FETCH_CONFIG })
    const res = await api.ecommerce.cart.config()
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_CONFIG_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_CONFIG_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_CONFIG_RETRY, message: e.message })
  }
}

export const updateCartCoupon = (data) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CART_COUPON })
    const res = await api.ecommerce.cart.coupon(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: UPDATE_CART_COUPON_RESULT, data: result })
    } else {
      dispatch({ type: UPDATE_CART_COUPON_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: UPDATE_CART_COUPON_RETRY, message: e.message })
  }
}

export const updateCartPaymentMethod = (data) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CART_PAYMENT_METHOD })
    const res = await api.ecommerce.cart.paymentMethod(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: UPDATE_CART_PAYMENT_METHOD_RESULT, data: result })
    } else {
      dispatch({ type: UPDATE_CART_PAYMENT_METHOD_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: UPDATE_CART_PAYMENT_METHOD_RETRY, message: e.message })
  }
}
