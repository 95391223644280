import api from '../api'

export const FETCH_FAQ = 'FETCH_FAQ'
export const FETCH_FAQ_RESULT = 'FETCH_FAQ_RESULT'
export const FETCH_FAQ_ERROR = 'FETCH_FAQ_ERROR'
export const FETCH_FAQ_RETRY = 'FETCH_FAQ_RETRY'

export const FETCH_FAQ_LIST = 'FETCH_FAQ_LIST'
export const FETCH_FAQ_LIST_RESULT = 'FETCH_FAQ_LIST_RESULT'
export const FETCH_FAQ_LIST_ERROR = 'FETCH_FAQ_LIST_ERROR'
export const FETCH_FAQ_LIST_RETRY = 'FETCH_FAQ_LIST_RETRY'

export const FETCH_RECENT_FAQS = 'FETCH_RECENT_FAQS'
export const FETCH_RECENT_FAQS_RESULT = 'FETCH_RECENT_FAQS_RESULT'
export const FETCH_RECENT_FAQS_ERROR = 'FETCH_RECENT_FAQS_ERROR'
export const FETCH_RECENT_FAQS_RETRY = 'FETCH_RECENT_FAQS_RETRY'

export const fetchQa = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_FAQ })
    const res = await api.blog.qa.get(data)
    console.warn(res)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_FAQ_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_FAQ_ERROR, message: result })
    }
  } catch (e) {
    console.log(e)
    dispatch({ type: FETCH_FAQ_RETRY, message: e.message })
  }
}

export const fetchQaList = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_FAQ_LIST, data })
    const res = await api.blog.qa.list(data)
    // console.warn(res);
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_FAQ_LIST_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_FAQ_LIST_ERROR, message: result })
    }
  } catch (e) {
    console.log(e)
    dispatch({ type: FETCH_FAQ_LIST_RETRY, message: e.message })
  }
}

export const fetchRecentFaqs = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_RECENT_FAQS })
    const res = await api.blog.posts.ghostrecentfaqs(data)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_RECENT_FAQS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_RECENT_FAQS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_RECENT_FAQS_RETRY, message: e.message })
  }
}
