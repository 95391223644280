import { ACCESS, ACCESS_EXPIRE, ACCESS_EXPIRE_TIME, REFRESH, USER } from '../constants'
import store from '../store'
import { getMomentObject, getStorageItem, removeStorageItem, setStorageItem } from '../utils'
import api from './api'

require('es6-promise').polyfill()
require('isomorphic-fetch')

const removeFromStorage = () => {
  removeStorageItem(USER)
  removeStorageItem(ACCESS)
  removeStorageItem(REFRESH)
  removeStorageItem(ACCESS_EXPIRE)

  location.replace(location.pathname)
}

const accessTokenRefresh = async () => {
  const access_expire = getStorageItem(ACCESS_EXPIRE)
  const access = getStorageItem(ACCESS)
  const refresh = getStorageItem(REFRESH)

  if (access_expire && !!refresh) {
    const isExpired = getMomentObject().isAfter(access_expire)
    if (isExpired) {
      try {
        const res = await api.accounts.tokenRefresh({ refresh })
        const result = await res.json()
        if (res.status === 200) {
          setStorageItem(ACCESS, result.access)
          setStorageItem(ACCESS_EXPIRE, getMomentObject().add(ACCESS_EXPIRE_TIME.value, ACCESS_EXPIRE_TIME.unit).toISOString())
          return result.access
        } else {
          removeFromStorage()
          return null
        }
      } catch (e) {
        return null
      }
    } else {
      return access
    }
  } else {
    if (access) {
      removeFromStorage()
      return null
    } else {
      return null
    }
  }
}

async function makeRequest (path, allParams = {}, authenticated = true) {
  let headers = {}

  const language = store.getState().languages.name
  const { bodyType = 'form', ...params } = allParams

  if (authenticated) {
    const access = await accessTokenRefresh()
    if (access) {
      headers = { ...headers, Authorization: `Bearer ${access}` }
    }
  }

  if (bodyType === 'json') {
    headers = { ...headers, 'Content-Type': 'application/json' }
  } else if (bodyType === 'file') {
    headers = { ...headers, 'Content-Type': 'multipart/form-data' }
  }

  if (params.headers) {
    headers = { ...params.headers, ...headers }
  }
  const fetchParams = {
    ...params,
    headers
  }

  if (path.indexOf('?') > -1) {
    path += `&language=${language}`
  } else {
    path += `?language=${language}`
  }

  return await fetch(path, fetchParams)
}

export default makeRequest
