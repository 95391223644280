
export default ({
  shopPageView: ({ products }) => {
    try {
      const data = {
        ecommerce: {
          currencyCode: 'INR',
          impressions: products.map((product, index) => ({
            id: product.id.toString(),
            name: product.title,
            price: (product.price / 100).toString(),
            brand: 'Misters',
            category: 'Ayurveda',
            list: 'Shop Page',
            position: index + 1
          }))
        }
      }
      // eslint-disable-next-line no-undef
      dataLayer.push(data)
      console.warn('Ecommerce:shopPageView:', data)
    } catch (e) {
      console.warn('Ecommerce:shopPageView:Error', e)
    }
  },
  productClick: ({ product, eventCallback }) => {
    try {
      const data = {
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: { list: 'Search Results' }, // Optional list property.
            products: [{
              id: product.id.toString(),
              name: product.title, // Name or ID is required.
              price: (product.price / 100).toString(),
              brand: 'Misters',
              category: 'Ayurveda'
            }]
          }
        },
        eventCallback: eventCallback
      }
      // eslint-disable-next-line no-undef
      dataLayer.push(data)
      console.warn('Ecommerce:productClick:', data)
    } catch (e) {
      eventCallback()
      console.warn('Ecommerce:productClick:Error', e)
    }
  },
  productPageView: ({ products }) => {
    try {
      const data = {
        ecommerce: {
          detail: {
            actionField: { list: 'Shop Page' },
            products: products.map((product, index) => ({
              id: product.id.toString(),
              name: product.title,
              price: (product.price / 100).toString(),
              brand: 'Misters',
              category: 'Ayurveda'
            }))
          }
        }
      }
      dataLayer.push(data)
      console.warn('Ecommerce:productPageView:', data)
    } catch (e) {
      console.warn('Ecommerce:productPageView:Error', e)
    }
  },
  addToCart: ({ products }) => {
    try {
      const data = {
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'INR',
          add: {
            products: products.map((product, index) => ({
              id: product.id.toString(),
              name: product.title,
              price: (product.price / 100).toString(),
              brand: 'Misters',
              category: 'Ayurveda',
              quantity: 1
            }))
          }
        }
      }
      // eslint-disable-next-line no-undef
      dataLayer.push(data)
      console.warn('Ecommerce:addToCart:', data)
    } catch (e) {
      console.warn('Ecommerce:addToCart:Error', e)
    }
  },
  removeFromCart: ({ products }) => {
    try {
      const data = {
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: 'INR',
          remove: {
            products: products.map((product, index) => ({
              id: product.id.toString(),
              name: product.title,
              price: (product.price / 100).toString(),
              brand: 'Misters',
              category: 'Ayurveda',
              quantity: 1
            }))
          }
        }
      }
      // eslint-disable-next-line no-undef
      dataLayer.push(data)
      console.warn('Ecommerce:removeFromCart:', data)
    } catch (e) {
      console.warn('Ecommerce:removeFromCart:Error', e)
    }
  },
  onCheckout: ({ items, eventCallback, step = 1, option }) => {
    try {
      const data = {
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: { step: step, option: option },
            products: items.map(
              item => ({
                id: item.item.id.toString(),
                name: item.item.title,
                price: ((item.line_item_total / item.quantity) / 100).toString(),
                quantity: item.quantity,
                brand: 'Misters',
                category: 'Ayurveda'
              })
            )
          }
        },
        eventCallback: function () {
          eventCallback()
        }
      }
      // eslint-disable-next-line no-undef
      dataLayer.push(data)
      console.warn('Ecommerce:onCheckout:', data)
    } catch (e) {
      eventCallback()
      console.warn('Ecommerce:onCheckout:', e)
    }
  },
  purchase: ({ order }) => {
    try {
      const data = {
        ecommerce: {
          purchase: {
            actionField: {
              // Transaction ID. Required for purchases and refunds.
              id: order.cid,
              affiliation: 'Online Store',
              revenue: (order.order_total / 100).toString(),
              coupon: order.cart.coupon ? order.cart.coupon.code : ''
            },
            products: order.order_items.map(
              order_item => ({
                id: order_item.product.id.toString(),
                title: order_item.name,
                price: ((order_item.total_price / order_item.quantity) / 100).toString(),
                quantity: order_item.quantity,
                brand: 'Misters',
                category: 'Ayurveda'
              })
            )
          }
        }
      }
      // eslint-disable-next-line no-undef
      dataLayer.push(data)
      console.warn('Ecommerce:purchase:', data)
    } catch (e) {
      console.warn('Ecommerce:purchase:', e)
    }
  }
})
