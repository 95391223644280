import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart'

import { getHttpsImageUrl, truncChars } from '../../../utils'
import { faUserAlt } from '@fortawesome/free-solid-svg-icons/faUserAlt'
import { bindActionCreators } from 'redux'
import { fetchDoctors } from '../../../actions/community/doctors'
import { connect } from 'react-redux'
import { likeComment } from '../../../actions/community/posts'

class CommunityPostCommentCard extends React.Component {
    static propTypes = {
      comment: PropTypes.object,
      post: PropTypes.object,
      variant: PropTypes.oneOf(['primary', 'secondary']),
      showPost: PropTypes.bool
    };

    static defaultProps = {
      variant: 'primary',
      showPost: false
    };

    componentDidMount () {}

    handleCommentLikeClick = () => {
      const { variant, comment, match, post, likeComment } = this.props
      likeComment({ comment_id: comment.id })
    }

    render () {
      const { variant, comment, match, post, showPost } = this.props
      const postUrl = `${match.params.language}/community/${post.slug}/`
      const isPrimary = variant === 'primary'
      const postCommentClassName = isPrimary ? 'community-post-comment-primary' : 'community-post-comment-secondary'

      return (
            <div key={comment.id}
                 className={`community-post-comment ${postCommentClassName}`}>
                {
                    showPost &&
                    <NavLink className="community-post-title"
                             to={`${match.params.language}/community/${comment.post.slug}`}>
                        {post.title}
                    </NavLink>
                }
                <div className="community-post-comment-author">
                    <div className="community-post-comment-author-image">
                        {
                            comment.doctor.image
                              ? <img src={getHttpsImageUrl(comment.doctor.image)} alt=""/>
                              : <FontAwesomeIcon icon={faUserAlt} color={'#999'} size={'sm'}/>
                        }
                    </div>
                    <NavLink to={`${match.params.language}/community/doctors/${comment.doctor.slug}`}
                             className="community-post-comment-author-details">
                        <span className="community-post-comment-author-name">
                            Dr. {comment.doctor.name}
                        </span>
                        <span className="community-post-comment-author-about">
                            {truncChars(comment.doctor.about, 30)}
                        </span>
                    </NavLink>
                </div>
                <p className="community-post-comment-text">
                    {
                        isPrimary
                          ? comment.text
                          : truncChars(comment.text, 150)
                    }
                    {
                        !isPrimary &&
                        <NavLink to={postUrl}>
                            {' Continue Reading...'}
                        </NavLink>
                    }
                </p>
                <div className="community-post-comment-footer">
                    <button className={`community-post-comment-footer-icon ${comment.liked ? 'active' : ''}`}
                            onClick={this.handleCommentLikeClick}>
                        <FontAwesomeIcon icon={faHeart} color={'#800'}
                                         size={'sm'}/>
                        <span>
                            {`${comment.likes_count} ${comment.likes_count > 1 ? 'likes' : 'like'}`}
                        </span>
                    </button>
                </div>
            </div>
      )
    }
}

const mapStateToProps = state => ({
  doctors: state.community.doctors
})

const matchDispatchToProps = dispatch => bindActionCreators({
  likeComment
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(CommunityPostCommentCard))
