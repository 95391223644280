import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Images } from '../../../constants'
import {
  getDiscount, getDiscountAmount,
  getHttpsImageUrl,
  getLanguageTranslation,
  getPrice, getSearchParams,
  truncChars
} from '../../../utils'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import CartQuantityInput from '../../inputs/CartQuantityInput'
import TherapyPrice from '../../../screens/Therapy/Components/TherapyPrice'
import { languageUrls } from '../../../languages'
import CartQuantitySelect from '../../inputs/CartQuantitySelect'
import StarRatings from 'react-star-ratings'

import { data as productData } from '../../../screens/Therapy/Product/data'

class CartItemCard extends Component {
    static propTypes = {
      item: PropTypes.object,
      is_multiple_quantity_discount_allowed: PropTypes.bool,
      deleteCartItem: PropTypes.func,
      createCartItem: PropTypes.func
    };

    static defaultProps = {};

    render () {
      const { item: { id, item, quantity, line_item_total }, match: { params: { language } }, is_multiple_quantity_discount_allowed } = this.props

      const price = getPrice(item.price)
      // let salePrice = item.sale_price ? getPrice(item.sale_price) : null;
      const totalMRPPrice = price * quantity
      const totalPrice = getPrice(line_item_total)
      // let discount = getDiscount(price, salePrice);
      const discountAmount = getDiscountAmount(totalMRPPrice, totalPrice)

      const title = getLanguageTranslation(item, 'title', language)
      const shortDescription = getLanguageTranslation(item, 'short_description', language)

      const minValue = {}
      const querySearch = getSearchParams(this.props.location.search)
      if (!!querySearch.pid && !!querySearch.mq) { minValue[querySearch.pid] = parseInt(querySearch.mq) }

      return (
            <div className='cart-item-card'>
                <div className='image-container'>
                    {
                        !!item.images &&
                        item.images.length > 0
                          ? <img className='image' src={getHttpsImageUrl(item.images[0])} alt=""/>
                          : <img className='image' src={Images.logo.x1} alt=""/>
                    }
                    <StarRatings
                        rating={item.ratings_star_count / (item.ratings_count || 1)}
                        starDimension="14px"
                        starSpacing="1px"
                        starRatedColor="#FF0000"
                        isSelectable={false}
                        numberOfStars={5}
                        name='rating'
                    />
                </div>
                <div className='cart-item-card-content'>
                    <div className="cart-item-header">
                        <div className="cart-item-title">
                            {
                                Object.keys(productData).includes(item.product_id)
                                  ? <NavLink to={`${language}/therapy/${item.product_id}/`}>
                                    {title}
                                </NavLink>
                                  : title
                            }
                        </div>
                        <div className="cart-item-price">
                            <span>₹{totalMRPPrice}</span>
                        </div>
                    </div>
                    <div className='cart-item-description d-sm-block d-none'>
                        {truncChars(shortDescription, 150)}
                    </div>
                    <div className='cart-item-description d-sm-none d-block'>
                        {truncChars(shortDescription, 100)}
                    </div>
                    <CartQuantityInput quantity={quantity}
                                       minValue={minValue[item.product_id] || 0}
                                       onDecreaseClick={() => this.props.deleteCartItem(id)}
                                       onIncreaseClick={() => this.props.createCartItem(item, quantity + 1)}/>
                    {
                        !!item.product_quantity_offers.length &&
                        is_multiple_quantity_discount_allowed &&
                        <ul className="cart-item-offers">
                            {
                                item.product_quantity_offers.map(
                                  (product_quantity_offer, index) => (
                                        <li key={product_quantity_offer.id}>
                                            {getLanguageTranslation(product_quantity_offer, 'text', language)}
                                        </li>
                                  )
                                )
                            }
                        </ul>
                    }
                </div>
            </div>
      )
    }
}

export default withRouter(CartItemCard)
