import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Images } from '../../../constants'
import {
  getHttpsImageUrl, getLanguageTranslation, truncChars
} from '../../../utils'
import { withRouter } from 'react-router'
import { languageUrls } from '../../../languages'
import StarRatings from 'react-star-ratings'
import LText from '../../core/LText/LText'
import { NavLink } from 'react-router-dom'

class OrderItemCard extends Component {
    static propTypes = {
      item: PropTypes.object
    };

    static defaultProps = {};

    render () {
      const { item, match: { params: { language } } } = this.props

      const title = getLanguageTranslation(item, 'title', language)
      const shortDescription = getLanguageTranslation(item, 'short_description', language)

      return (
            <div className='order-item-card'>
                <div className='image-container'>
                    {
                        !!item.images &&
                        item.images.length > 0
                          ? <img className='image' src={getHttpsImageUrl(item.images[0])} alt=""/>
                          : <img className='image' src={Images.logo.x1} alt=""/>
                    }
                    <StarRatings
                        rating={item.ratings_star_count / (item.ratings_count || 1)}
                        starDimension="14px"
                        starSpacing="1px"
                        starRatedColor="#FF0000"
                        isSelectable={false}
                        numberOfStars={5}
                        name='rating'
                    />
                </div>
                <div className='order-item-card-content'>
                    <div className="order-item-header">
                        <div className="order-item-title">
                            {title}
                        </div>
                    </div>
                    <div className='order-item-description d-sm-block d-none'>
                        {truncChars(shortDescription, 150)}
                    </div>
                    <div className='order-item-description d-sm-none d-block'>
                        {truncChars(shortDescription, 100)}
                    </div>
                    <div className="text-right">
                        <NavLink className="order-item-show-detail" to={`${language}/therapy/${item.product_id}`}>
                            <LText k="SHOW_DETAILS"/>
                        </NavLink>
                    </div>
                </div>
            </div>
      )
    }
}

export default withRouter(OrderItemCard)
