import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { updateCart } from '../../actions/ecommerce/cart'
import { fetchWishlist, updateWishlist } from '../../actions/ecommerce/wishlist'
import Page from '../../components/core/Page'
import LText from '../../components/core/LText'
import AuthenticatedContainer from '../../components/containers/AuthenticatedContainer'
import Ripple from '../../components/loadings/Ripple'
import { getHttpsImageUrl, getLanguageTranslation, truncChars } from '../../utils'
import Button from '../../components/buttons/Button'
import Ecommerce from '../../services/Ecommerce'

class Wishlist extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    cart: PropTypes.object,
    wishlist: PropTypes.object,
    fetchWishlist: PropTypes.func,
    updateWishlist: PropTypes.func,
    updateCart: PropTypes.func
  };

  componentDidMount () {
  }

  constructor (props) {
    super(props)

    this.state = {
      isFetchingCart: true,
      coupon_code: '',
      coupon_message: ''
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

  }

  handleLoginSuccess = () => {
    this.props.fetchWishlist()
  };

  addProductToCart = (product) => {
    const { history, match } = this.props
    this.props.updateCart({ product_id: product.product_id, quantity: 1 })
    history.push(`${match.params.language}/checkout/`)
  };

  handleOpenProductDetails = (product) => {
    const { match, history } = this.props
    let url = `${match.params.language}/solutions/${product.product_id}`
    if (product.is_single_product) {
      url = `${match.params.language}/therapy/${product.product_id}`
    }
    Ecommerce.productClick({
      product,
      eventCallback: () => {
        history.push(url)
      }
    })
  };

  removeFromWishlist = (product) => {
    this.props.updateWishlist({ product_id: product.id, remove: true })
  }

  render () {
    const { wishlist: { detail, isFetching }, match, history } = this.props
    const language = match.params.language

    return (
      <AuthenticatedContainer handleLoginSuccess={this.handleLoginSuccess}>
        <Page meta={{
          title: 'Misters products wishlist',
          path: `${match.params.language}/wishlist/`,
          description: ''
        }} showNavbar={true} showWhatsapp={false}>
          <div className="wishlist">
            <div className="container">
              <div className="py-sm-5 py-2"/>
              <h3 className="text-center">Wishlist</h3>
              {
                isFetching &&
                <div className="text-center"><Ripple show={true}/></div>
              }
              {
                !isFetching && (!detail.products || (!!detail.products && !detail.products.length)) &&
                <div className="empty-cart">
                  <p className="empty-message">No items in wishlist.</p>
                  <button className='btn-m-red btn-m'
                          onClick={() => history.push(`${match.params.language}/solutions/`)}>
                    <LText k="SHOP_NOW"/>
                  </button>
                </div>
              }
              {
                !isFetching && !!detail.products &&
                !!detail.products.length &&
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
                    {
                      detail.products.map(
                        product => (
                          <div key={product.id} className="wishlist-item">
                            <img src={getHttpsImageUrl(product.image)}
                                 onClick={() => this.handleOpenProductDetails(product)}
                                 alt={product.title}/>
                            <div className="wishlist-item-content">
                              <h6 onClick={() => this.handleOpenProductDetails(product)}>{product.title}</h6>
                              <p onClick={() => this.handleOpenProductDetails(product)}>
                                {truncChars(getLanguageTranslation(product, 'short_description', language), 150)}</p>
                              <div className="d-flex justify-content-end">
                                <Button variant="dark"
                                        className="mr-2"
                                        onClick={() => this.removeFromWishlist(product)}>
                                  Remove
                                </Button>
                                <Button variant="dark"
                                        onClick={() => this.addProductToCart(product)}>
                                  Add to Cart
                                </Button>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </Page>
      </AuthenticatedContainer>
    )
  }
}

const mapStateToProps = state => ({
  cart: state.ecommerce.cart,
  wishlist: state.ecommerce.wishlist
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchWishlist,
  updateWishlist,
  updateCart
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Wishlist)
