import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom'

import Page from '../../components/core/Page'
import { Images } from '../../constants'
import { truncChars } from '../../utils'
import { ayurvedaList } from './data'

class Ayurveda extends Component {
    static propTypes = {};

    constructor (props) {
      super(props)

      this.state = {}
    }

    componentDidMount () {

    }

    componentDidUpdate (prevProps, prevState, snapshot) {

    }

    render () {
      const { match } = this.props

      return (
            <Page meta={{
              title: 'Ashwagandha, immunity, men\'s health | Misters',
              path: `${match.params.language}/ayurveda/`,
              description: 'Misters has put together your complete Ayurvedic ingredient guide with scientifically proven perks for health. The vigour of Ashwagandha, stamina of Shilajit, immunity of Amla, nutrition of Kaunch Beej, strength of Gokshura, aphrodisiacal Kesar, steady sperm enhancing Shatavari, male potency treating Safed Musli, super spice Jaiphal and the boldness of Bhringraj are all carefully recorded here. An amalgam of ancient Ayurvedic practice with contemporary science makes for a holistic man. Usages, dosage, benefits and additional information on each essential herb is categorised for your benefit. To know your bodily humors better, please take the Misters Ayurvedic quiz.'
            }}>
                <div className="page-header-img"
                     style={{ backgroundImage: `url('${Images.ayurveda}')` }}>
                    <div className="page-header-bg-drop"/>
                    <div className="page-header-content">
                        <h1>Your complete ayurvedic ingredient guide for scientifically proven health benefits</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {
                                    ayurvedaList.map((data, index) => (
                                        <div key={index} className="col-lg-4 col-sm-6 col-12 d-flex">
                                            <NavLink className="post-card"
                                                     to={`${match.params.language}${data.to}`}>
                                                {
                                                    data.img &&
                                                    <div className="post-card-image">
                                                        <img src={data.img} alt=""/>
                                                    </div>
                                                }
                                                <div className="post-card-content">
                                                    <div className="post-card-head">
                                                        <h4 className="post-card-title">{data.title}</h4>
                                                        <p className="post-card-text">{truncChars(data.content, 150)}</p>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-5"/>
            </Page>
      )
    }
}

const mapStateToProps = (state) => ({})

const matchDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Ayurveda)
