import React from 'react'
import PropTypes from 'prop-types'

import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'

export default class AccordionCard extends React.PureComponent {
    static propTypes = {
      title: PropTypes.string,
      arrow: PropTypes.bool
    };

    static defaultProps = {
      title: '',
      arrow: false
    };

    render () {
      const { title, children, arrow } = this.props

      return (
            <Accordion>
                <Card>
                    <Accordion.Toggle className={arrow ? 'd-flex justify-content-between' : ''}
                                      as={Card.Header} eventKey="0">
                        <h2>{title}</h2>
                        {arrow &&
                        <FontAwesomeIcon icon={faAngleDown}/>
                        }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {children}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
      )
    }
}
