import api from '../api'

export const FETCH_POST = 'FETCH_POST'
export const FETCH_POST_RESULT = 'FETCH_POST_RESULT'
export const FETCH_POST_ERROR = 'FETCH_POST_ERROR'
export const FETCH_POST_RETRY = 'FETCH_POST_RETRY'

export const FETCH_POSTS = 'FETCH_POSTS'
export const FETCH_POSTS_RESULT = 'FETCH_POSTS_RESULT'
export const FETCH_POSTS_ERROR = 'FETCH_POSTS_ERROR'
export const FETCH_POSTS_RETRY = 'FETCH_POSTS_RETRY'

export const FETCH_RECENT_POSTS = 'FETCH_RECENT_POSTS'
export const FETCH_RECENT_POSTS_RESULT = 'FETCH_RECENT_POSTS_RESULT'
export const FETCH_RECENT_POSTS_ERROR = 'FETCH_RECENT_POSTS_ERROR'
export const FETCH_RECENT_POSTS_RETRY = 'FETCH_RECENT_POSTS_RETRY'

export const fetchRecentPosts = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_RECENT_POSTS })
    const res = await api.blog.posts.ghostrecentposts(data)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_RECENT_POSTS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_RECENT_POSTS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_RECENT_POSTS_RETRY, message: e.message })
  }
}

export const fetchPost = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_POST })
    const res = await api.blog.posts.get(data)
    console.warn(res)
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_POST_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_POST_ERROR, message: result })
    }
  } catch (e) {
    console.log(e)
    dispatch({ type: FETCH_POST_RETRY, message: e.message })
  }
}

export const fetchPosts = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_POSTS, data })
    const res = await api.blog.posts.list(data)
    // console.warn(res);
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_POSTS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_POSTS_ERROR, message: result })
    }
  } catch (e) {
    console.log(e)
    dispatch({ type: FETCH_POSTS_RETRY, message: e.message })
  }
}
