import api from '../api'

export const FETCH_DOCTORS = 'FETCH_DOCTORS'
export const FETCH_DOCTORS_RESULT = 'FETCH_DOCTORS_RESULT'
export const FETCH_DOCTORS_ERROR = 'FETCH_DOCTORS_ERROR'
export const FETCH_DOCTORS_RETRY = 'FETCH_DOCTORS_RETRY'

export const FETCH_DOCTOR = 'FETCH_DOCTOR'
export const FETCH_DOCTOR_RESULT = 'FETCH_DOCTOR_RESULT'
export const FETCH_DOCTOR_ERROR = 'FETCH_DOCTOR_ERROR'
export const FETCH_DOCTOR_RETRY = 'FETCH_DOCTOR_RETRY'

export const fetchDoctors = () => async dispatch => {
  try {
    dispatch({ type: FETCH_DOCTORS })
    const res = await api.community.doctors.list()
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_DOCTORS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_DOCTORS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_DOCTORS_RETRY, message: e.message })
  }
}

export const fetchDoctor = ({ slug }) => async dispatch => {
  try {
    dispatch({ type: FETCH_DOCTOR })
    const res = await api.community.doctors.get({ slug })
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_DOCTOR_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_DOCTOR_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_DOCTOR_RETRY, message: e.message })
  }
}
