// External Imports
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
// My Imports
import ImageService from '../../services/ImageService'
import { getHttpsImageUrl } from '../../utils'

const Section2 = ({ match, product }) => {
  return (
    <div className='solution-page-section2 py-5'>
      <h2 className="solution-page-title text-center mb-3">
        Offered Products & Services
      </h2>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h6 className="text-center solution-page-item-title">Scientific medicines</h6>
          </div>
          <div className="col-12 d-flex justify-content-center flex-wrap">
            {
              !!product && !!product.item_connections &&
              product.item_connections.map(
                (item, index) => (
                  <div className="solution-page-item" key={item.id}>
                    <img src={getHttpsImageUrl(item.item.image)} alt=""/>
                    <h6>{item.item.title} x {item.quantity}</h6>
                  </div>
                )
              )
            }
          </div>
          <div className="col-12">
            <h6 className="text-center solution-page-item-title">Complementary services</h6>
          </div>
          <div className="col-12 text-center">
            <div className="solution-page-item">
              <img src={ImageService.home.services1} alt="Free Doctor Consultation"/>
              <h6>Free Doctor Consultation</h6>
            </div>
            <div className="solution-page-item">
              <img src={ImageService.home.services2} alt=""/>
              <h6>Personalized diet plan</h6>
            </div>
            <div className="solution-page-item">
              <img src={ImageService.home.services3} alt=""/>
              <h6>Phone call assistance</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Section2.propTypes = {
  match: PropTypes.object,
  product: PropTypes.object
}

const mapStateToProps = state => ({})

const matchDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Section2))
