import api from '../api'

export const FETCH_POSTS = 'FETCH_POSTS'
export const FETCH_POSTS_RESULT = 'FETCH_POSTS_RESULT'
export const FETCH_POSTS_ERROR = 'FETCH_POSTS_ERROR'
export const FETCH_POSTS_RETRY = 'FETCH_POSTS_RETRY'

export const FETCH_POST = 'FETCH_POST'
export const FETCH_POST_RESULT = 'FETCH_POST_RESULT'
export const FETCH_POST_ERROR = 'FETCH_POST_ERROR'
export const FETCH_POST_RETRY = 'FETCH_POST_RETRY'

export const LIKE_COMMENT = 'LIKE_COMMENT'
export const LIKE_COMMENT_RESULT = 'LIKE_COMMENT_RESULT'
export const LIKE_COMMENT_ERROR = 'LIKE_COMMENT_ERROR'
export const LIKE_COMMENT_RETRY = 'LIKE_COMMENT_RETRY'

export const fetchPosts = () => async dispatch => {
  try {
    dispatch({ type: FETCH_POSTS })
    const res = await api.community.posts.list()
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_POSTS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_POSTS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_POSTS_RETRY, message: e.message })
  }
}

export const fetchPost = ({ slug }) => async dispatch => {
  try {
    dispatch({ type: FETCH_POST })
    const res = await api.community.posts.get({ slug })
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: FETCH_POST_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_POST_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_POST_RETRY, message: e.message })
  }
}

export const likeComment = ({ comment_id }) => async dispatch => {
  try {
    dispatch({ type: LIKE_COMMENT })
    const res = await api.community.comments.like({ comment_id })
    const result = await res.json()
    if (res.status === 200) {
      dispatch({ type: LIKE_COMMENT_RESULT, data: result })
    } else {
      dispatch({ type: LIKE_COMMENT_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: LIKE_COMMENT_RETRY, message: e.message })
  }
}
