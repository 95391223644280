import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { deleteAddress, fetchAddress } from '../../../actions/ecommerce/address'
import { deleteCartItem, fetchCart, fetchConfig, updateCartCoupon } from '../../../actions/ecommerce/cart'
import { createOrder, updateNewOrder } from '../../../actions/ecommerce/orders'
import OrderSummaryCard from '../../../components/cards/OrderSummaryCard'
import AddressItemCard from '../../../components/cards/AddressItemCard'
import Page from '../../../components/core/Page'
import LText from '../../../components/core/LText'
import Ripple from '../../../components/loadings/Ripple'
import CartState from '../../../components/others/CartState'
import Ecommerce from '../../../services/Ecommerce'

class DeliveryAddress extends PureComponent {
    static propTypes = {
      profile: PropTypes.object,
      newOrder: PropTypes.object,
      deleteAddress: PropTypes.func,
      updateNewOrder: PropTypes.func
    };

    state = {
      showAddressCreateCard: false,
      editAddressId: -1
    };

    componentDidMount () {
      const { newOrder, history, match } = this.props
      const querySearch = this.props.location.search
      this.props.fetchCart()
      this.props.fetchAddress()

      if (!newOrder.cart) {
        history.push(`${match.params.language}/checkout${querySearch}`)
      }
    }

    handleDeliverClick = (address) => {
      const { cart, newOrder, updateNewOrder, history, match } = this.props
      const querySearch = this.props.location.search
      updateNewOrder({ ...newOrder, address, payment_type: 'cod' })

      Ecommerce.onCheckout({
        items: cart.detail.items,
        step: 2,
        option: 'delivery',
        eventCallback: () => {
          history.push(`${match.params.language}/checkout/payment${querySearch}`)
        }
      })
    };

    handleDeleteClick = (id) => {
      this.props.deleteAddress({ id })
    };

    handleEditClick = (id) => {
      this.setState({ editAddressId: id, showAddressCreateCard: false })
    };

    handleCancelClick = (id) => {
      this.setState({ editAddressId: -1, showAddressCreateCard: false })
    };

    render () {
      const { address, address: { isFetching }, match } = this.props
      const { editAddressId } = this.state
      const hasAddresses = !(!!address.list && !address.list.length)

      return (
            <Page meta={{
              title: 'Shop quality Misters health products',
              path: `${match.params.language}/checkout/address/`,
              description: 'Misters is a Gurgaon-based health and confidence start-up catering to men’s sexual health and wellness using ayurvedic, allopathic and plant-based methods. In collaboration with medical experts, reliable information on erections, ejaculation, sexual desire and hair care awaits you here. Simple, personalized and result-oriented care amalgamating traditional knowledge and the best science has to offer is Misters’ forte. More than a website and company, Misters is a community. Let your journey commence, bon voyage!'
            }} footerVariant={'checkout'} showNavbar={false} showWhatsapp={false}>
                <div className="container">
                    <div className="address">
                        <CartState step={2}/>
                        <div className="py-sm-5 py-2"/>
                        <div className="row">
                            <div className="col-lg-8 order-lg-1 offset-lg-2 col-12">
                                {
                                    isFetching &&
                                    <div className="text-center"><Ripple show={true}/></div>
                                }

                                {!isFetching && !!address.list && !address.list.length &&
                                <p className="pt-3 mb-0 text-primary-1 text-center text-sm-left">
                                    <LText k="ADD_NEW_ADDRESS_TO_CONTINUE"/>
                                </p>
                                }
                                {
                                    !isFetching && !!address.list &&
                                    !!address.list.length &&
                                    address.list.map((item) => (
                                        <AddressItemCard key={item.id} data={item} saveAndContinue={true}
                                                         editAddressId={editAddressId}
                                                         handleCancelClick={() => this.handleCancelClick(item.id)}
                                                         handleEditClick={() => this.handleEditClick(item.id)}
                                                         handleDeleteClick={() => this.handleDeleteClick(item.id)}
                                                         handleDeliverClick={() => this.handleDeliverClick(item)}/>
                                    ))
                                }
                                {
                                    !isFetching && (!this.state.showAddressCreateCard && hasAddresses) &&
                                    <button className='btn-add-new-address btn-m btn-m-red'
                                            onClick={() => this.setState({
                                              showAddressCreateCard: true,
                                              editAddressId: -1
                                            })}>
                                        <LText k="ADD_NEW_ADDRESS"/></button>
                                }
                                {
                                    !isFetching && (this.state.showAddressCreateCard || !hasAddresses) &&
                                    <AddressItemCard saveAndContinue={true}
                                                     showCancel={hasAddresses}
                                                     handleCancelClick={() => this.handleCancelClick()}
                                                     handleDeliverClick={(address) => this.handleDeliverClick(address)}
                                                     handleAddressCreate={(item) => this.setState({ showAddressCreateCard: false })}/>
                                }
                            </div>
                            {/* <div className="col-lg-4 order-lg-2 col-12"> */}
                            {/*    <OrderSummaryCard/> */}
                            {/* </div> */}
                        </div>
                        <div className="py-5"/>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = state => ({
  cart: state.ecommerce.cart,
  address: state.ecommerce.address,
  newOrder: state.ecommerce.orders.newOrder
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchCart,
  fetchAddress,
  deleteAddress,
  deleteCartItem,
  createOrder,
  fetchConfig,
  updateCartCoupon,
  updateNewOrder
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(DeliveryAddress)
