// External Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// My Imports
import Page from '../../../components/core/Page'
import { Images } from '../../../constants'
import WhyMisters from '../../Category/common/WhyMisters'
import { accordionList1, accordionList2, accordionList3 } from './accordions'
import ImageBreaker from '../../../components/breakers/ImageBreaker'
import getArticleSchema from '../../../services/SchemaService/getArticleSchema'
import getFaqSchema from '../../../services/SchemaService/getFaqSchema'

const faqs = [
  {
    question: 'Where is Ashwagandha found?',
    answer: `Considered one of the most vital herbs of Ayurveda, Ashwagandha is also known by a plethora of other names
            including Ajagandha, Amangura, Amukkirag, Asan, Asana, Asgand, Cerise Hiver, Haya Vaya, Kanaje Hindi,
            Kuthmithi, Orovale, Payette, Samm Al Ferakh, Sogade-Beru, Strychnos, Turangi-Ghanda and Vajigandha. This
            shrub also grows in profusion in countries like <b>Nepal, China, Middle East, Africa, and Yemen</b>. Today,
            the increase demand for this herb has led to its cultivation in milder climates of countries like that of
            the <b>United States</b>.
            <br/>
            <br/>
            A part of the nightshade species, Ashwagandha grows well in dry, rocky soil and needs sun as well as partial
            shade. This plant is well suited for the Indian climate and grows well across the country and also in the
            famous forest reserves including the <b>Sariska Tiger Reserve, Ranthambore National Park and Eravikulam
            National Park</b>. Indian families also grow this herb in their kitchen garden.`
  },
  {
    question: 'What is the description of an Ashwagandha plant?',
    answer: `The plant is <b>stout, short and tender</b>, and grows up to <b>35–75 cm in height</b>. For cultivation, the
            seeds of the plant can be sown at the beginning of spring; it can also be grown from greenwood, and its
            cuttings planted towards the end of the spring season. The plant has oval-shaped leaves which are dull green
            in colour and oval in shape, and <b>10–12 cm in length</b>. Flowers are <b>yellow-coloured, small and
            bell-shaped</b> while the fruits are red or orange and are of the size of raisins. For the preparation of
            the supplements, extracts are typically obtained from the roots of the plant which is known for multiple
            medicinal qualities like an aphrodisiac, anthelmintic, astringent, diuretic, narcotic, thermogenic and
            stimulant. However, the berries are also used for the preparation of pills or powder.`
  },
  {
    question: 'What is the history of Ashwagandha?',
    answer: `Ashwagandha is a <b>classical Ayurvedic medical herb</b> which has been in <b>use for more than 5,000 years
            in India</b>. Since long this herb has been used to treat conditions like infertility, physical weakness,
            rheumatism, constipation, sleeplessness, nerve-related issues, stress, thyroid, inflammation, parasite
            attack and hormone imbalances, to name a few conditions.
            <br/>
            <br/>
            Historically, the medical practitioners of India used Ashwagandha root extracts and made them into powder or
            paste to be applied topically to treat ulcers, wounds and other skin infections. According to Charak
            Samhita, ever since the advent of Ayurveda, Ashwagandha has been called <b>‘Sattvic Kapha Rasayana’</b>.
            Rasayana, which is a Sanskrit term coined in the early ayurvedic medical dialect. ‘āyana’ means path and
            ‘rasa’ means essence. It connotes the knowledge of expanding one’s lifespan. Eventually, during the
            8th-century, the term rasayana often referred to Indian alchemy.
            <br/>
            <br/>
            Numerous references to Ashwagandha can be found in <b>ancient, historical, modern Ayurvedic and Unani
            medicinal texts</b>. Over the years this medicinal herb has been recognised and lauded all over the world
            and across cultural spectrums. The first reference of the word Ashwagandha can be traced to the sacred
            Ayurvedic texts of the Charaka and the Sushruta Samhitas, where it is mentioned as one of the most powerful
            medical herbs, particularly known for its restorative and recuperative qualities.
            <br/>
            <br/>
            The name Ashwagandha dates back to its ancient and historical use for promoting a vigorous sex drive, as
            ‘ashwa’ in Ashwagandha means horse which is a very energetic animal. Besides, the powdered extracts of the
            herb are known to have a smell similar to that of a horse. That said, the herb has also been used since ages
            for a calming and restoring effect, especially after illness or surgery. Ashwagandha has been extensively
            used in Indian Ayurveda since the prehistoric era. History is replete with references of Ashwagandha
            prescribed by ancient medical practitioners for its wound healing capacity.
            <br/>
            <br/>
            Conventionally, the freshly plucked leaves of the plant were applied topically to cure joint pains, skin
            wounds and swellings..`
  },
  {
    question: 'What are some famous stories surrounding the Ashwagandha herb?',
    answer: `<p>
            The plant has found mention in the teachings and dictates of one of the most revered Hindu sages, <b>Punarvasu
            Atreya</b>. He was appointed as the principal physician of the ruling king of his time.
            <br/>
            <br/>
            This renowned Rishi’s teachings, later formed the basis of the Charaka Samhita. Legend has it that <b>Alexander
            the Great</b> along with his huge army used to make wine and concoct it with the Ashwagandha plant. This
            made them more energised and valiant during the war.
            <br/>
            <br/>
            Another allegory goes that <b>Apollo the Greek god</b> of healing, medicine and archery and also one of the
            Twelve Olympians, spotted this herb and handed it over to his son Asclepius. Interestingly, Asclepius is
            known as the most illustrious champion of healing, truth, and prophecy in Greek mythology.
        </p>`
  },
  {
    question: 'What is Ashwagandha made of?',
    answer: `<p>
            The active Constituents of Ashwagandha are what makes it such an effective herbal
            medicine. It is rich in <b>steroidal compounds like lactones, Withaferin A, and carbon-27-glycowithanolides,
            which are together grouped as Withanolides</b>. The plant also has a substantial
            quantity of <b>alkaloids; tropine, pseudotropine isopelletrine, anaferine, and saponins</b>. These compounds
            promote antioxidant, anti-inflammatory, and immunomodulatory activities and are administered in the human
            body for treating and preventing conditions like rheumatism, amnesia, stress, depression, impotence and
            neurodegenerative diseases.
            <br/>
            <br/>
            Research findings further revealed other characteristics of Ashwagandha, like that of
            A cardioprotective, anti-microbial, anti-tumour and anti-diabetic herb. These qualities are derived from the
            plant’s potential in reducing reactive oxygen species and inflammation, regulating apoptosis, modulating
            mitochondrial function, and promoting endothelial functions.
            <br/>
            <br/>
            Some of the key phytochemical constituents of Ashwagandha are <b>Withanolides</b> that is the <b>triterpene
            lactones, Withanolides, Withaferin A, Alkaloids, Steroidal lactones, Tropine and Cuscohygrine</b>.
            <br/>
            <br/>
            Researchers have been successful in isolating about <b>40 withanolides, 12 alkaloids, and numerous
            sitoindosides</b> from an Ashwagandha sample. Since the withanolides have structural similarity to the
            ginsenosides of Panax ginseng, the name Indian ginseng was coined for Ashwagandha or W. somnifera.
            <br/>
            <br/>
            Moreover, the chemical ingredients that are biologically active in Ashwagandha are Alkaloids like
            isopelletierine, anaferine, cuscohygrine, anahygrine, Steroidal lactones like withanolides and withaferins)
            and Saponins.
            <br/>
            <br/>
            It is the presence of constituents like <b>Sitoindosides and acyl steryl glucosides</b> which
            makesAshwagandha an anti-stress agent and substance. <b>Sitoindosides VII-X</b> and <b>Withaferin-A</b> are
            particularly responsible for relieving stress. Some of the other constituents of Ashwagandha have the
            potential to promote immunomodulatory actions. The parts of the plant that stay above the ground have
            ingredients like <b>5-dihydroxy, withanolide-R and withasomniferin-A</b>.
            <br/>
            <br/>
            Ashwagandha is a well-known adaptogen and helps in controlling stress that is both physical and mental.
            These qualities have made Ashwagandha an extremely potential rejuvenating herbal medicine which is effective
            in developing cognisance, relieving joint pain and irritation, and controlling the aging process. The plant
            extracts are also used for curing hormone imbalance and treating many other chronic health conditions like
            restlessness and exhaustion.
        </p>`
  },
  {
    question: 'What are the benefits of Ashwagandha?',
    answer: `<p>
            Ayurveda calls the exotic herb, Ashwagandha, ‘balya.’‘Bal’ in Sanskrit means <b>strength</b>. This is
            because Ashwagandha is known to <b>boost energy levels and build overall stamina</b> and <b>staying
            power</b>. The plant is gaining recognition as a gifted alternative method of treatment for an array of
            diseases.
            <br/>
            <br/>
            <strong>Growth of nerve cells:</strong> Researchers have found that the plant extracts have special
            properties to treat mental degenerative diseases by promoting the growth of nerve cells. Ashwagandha is
            known to shield the brain cells from damaging environmental effects.
            <br/>
            <br/>
            <strong>Giving strength:</strong> Since antiquity, Ashwagandha, a natural adaptogen, has been administered
            by physicians as an all-purpose tonic for endowing strength and promoting wellness. Chyawanprash, the widely
            popular mixture present in every Indian household, which contains Ashwagandha. Studies further indicate that
            the herb might enhance swimming performance in humans by preventing and reducing adrenal cortisol and
            ascorbic acid levels resulting from swimming-related body stress.
            <br/>
            <br/>
            <strong>Sexual health:</strong> Another very significant contribution of Ashwagandha is its effectiveness as
            an aphrodisiac and a herb promoting sexual well-being. It also addresses male infertility by improving sperm
            quality in men. However, it is said that this herb does not increase sperm count. However, Ashwagandha
            supplements, when taken regularly for about eight to nine weeks together with counselling sessions, can
            increase sexual desire and satisfaction in women having sexual dysfunctions.
            <br/>
            <br/>
            <strong>Hormonal balance:</strong> Ashwagandha promotes hormone balance and thus influences the endocrine
            system. Studies also prove that the plant can address symptoms like hot flashes and mood swings at the time
            of menopause.
            <br/>
            <br/>
            <strong>Anti-ulcerogenic:</strong> Ashwagandha supplements are effective in checking ulcers of the
            gastrointestinal tract, which are mainly caused by stress. Research results show that the plant can provide
            efficient protection against immobilization and aspirin induced gastric ulcers.
            <br/>
            <br/>
            <strong>Stress relief:</strong> Ashwagandha can significantly bring down stress levels by controlling the
            rise of cortisol levels. Presently, the herb is used in tranquilizers and antidepressants, as it is
            instrumental in alleviating stress that is both physical and mental.
            <br/>
            <br/>
            <strong>Sleep inducing:</strong> The rejuvenating traits of the herb make it highly useful in insomnia
            treatment. Ashwagandha extracts soothe the nerves, relieves stress and addresses
            sleeplessness. Customarily, Ashwagandha powder has been mixed with raw honey and warm goat-milk for relaxing
            the vata dosha and moderating the sleeping and waking process.
            <br/>
            <br/>
            <strong>Beauty, hair and skincare:</strong> Ashwagandha supplements greatly enliven dry, wrinkled and
            sagging skin. Being a strong antioxidant substance, the herbal extracts help defend the skin from free
            radical damage and control the ageing process among adults. This leads to one’s skin looking firm and
            youthful. Ashwagandha fuels-up DHEA, which in turn boosts production of testosterone and estrogen as well as
            the secretion of natural oils. The secretion of vital compounds and proteins like hyaluronan for hydration
            of the skin, elastin for keeping it supple and collagen for providing the skin with strength are also
            promoted by Ashwagandha, when used along with lemon and sun-dried ginger.
            <br/>
            <br/>
            Ashwagandha is liberally used in shampoos as it is known to enhance scalp circulation and give strength to
            the hair while doing away with dandruff. Besides, it also encourages melanin secretion. Melanin being the
            pigment that gives colour to the hair. The use of Ashwagandha products can effectively slow down the greying
            process of one’s hair. Additionally, the herb can also be effective in addressing hair loss.
        </p>`
  }
]

class Ashwagandha extends Component {
  componentDidMount () {

  }

  render () {
    const shopUrl = '/en/solutions/'
    const { match } = this.props

    const path = `${match.params.language}/ingredient/ashwagandha/`
    const scripts = [
      getArticleSchema({
        title: 'Misters Ashwagandha, vitality, immunity, men\'s health and sexual stimulation',
        description: 'Learn about Ashwagandha, a high quality Ayurvedic herb, on the Misters Ayurveda page. Your complete Misters Ayurvedic ingredient guide has collated expert information on Ashwagandha’s usage, dosage, benefits, description, alternative names and constituents. Ashwagandha has vast, scientifically proven health benefits. A boost in immunity and stamina, improvement of sperm quality and stress relief are some results of its usage. It enhances attention span, impulse control and brain functions. Rich in naturally occurring steroids, it is found in parts of Yemen, Nepal, part of China and many more areas. Know your Ayurvedic ingredients to understand Misters products better.',
        images: ['https://misters.in/web-assets/images/ingredients/shilajit-description.jpg'],
        keywords: 'ashwagandha, ashwagandha benefits, ashwagandha supplements, ashwagandha extracts, ashwagandha stress, natural medicine',
        url: path
      }),

      getFaqSchema({
        data: faqs,
        url: path
      })
    ]

    return (
      <Page meta={{
        title: 'Misters Ashwagandha, vitality, immunity, men\'s health and sexual stimulation',
        path: path,
        description: 'Learn about Ashwagandha, a high quality Ayurvedic herb, on the Misters Ayurveda page. Your complete Misters Ayurvedic ingredient guide has collated expert information on Ashwagandha’s usage, dosage, benefits, description, alternative names and constituents. Ashwagandha has vast, scientifically proven health benefits. A boost in immunity and stamina, improvement of sperm quality and stress relief are some results of its usage. It enhances attention span, impulse control and brain functions. Rich in naturally occurring steroids, it is found in parts of Yemen, Nepal, part of China and many more areas. Know your Ayurvedic ingredients to understand Misters products better.'
      }} script={scripts}>
        <div className="ingredient-header-img" style={{ backgroundImage: `url('${Images.ingredient.ingredientsHead}')` }}>
          <div className="ingredient-header-bg-drop"/>
          <div className="ingredient-header-content">
            <h1>Ashwagandha- a trusted sexual stimulant</h1>
          </div>
        </div>
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>What is Ashwagandha?</h2>
              <p>
                For hundreds of years Ashwagandha, an <b>evergreen Indian plant</b> has been recognised
                for its adaptogenic benefits in Ayurvedic practice. Ayurvedic practitioners particularly
                venerate the roots and orange-red fruits of this <b>high-quality herb</b> for their
                restorative and rejuvenating benefits, as well as for having the potential to cure an
                array of health disorders.
                <br/>
                <br/>
                Part of the Solanaceae or nightshade family, Ashwagandha, or <b>Indian ginseng</b>, is
                also known by names like Withania somnifera, winter cherry or poison gooseberry.
                Somnifera is a Latin word meaning ‘sleep-inducing’ while Ashwagandha in Sanskrit refers
                to a different idea as ‘ashwa’ means horse and ‘gandha’ means smell. Thus, in the Indian
                context, the herb connotes the idea of <b>providing horse-like vitality and power</b>.
                According to ancient Indian scriptures, the root of Ashwagandha apparently smells of
                horse sweat. Interestingly, ginseng and ashwagandha are botanically very dissimilar
                species but their rejuvenating qualities have resulted in them being clubbed together.
                <br/>
                <br/>
                These qualities along with the constituent active components like alkaloids, steroidal
                lactones, saponins, and withanolides, have made Ashwagandha a highly acclaimed nerve
                tonic and also an all-purpose stimulant for enhancing thinking capability, reducing
                joint pain and inflammation, and controlling the ageing process. Not only that, but this
                magical herb also contains hormone stabilising and antioxidant properties which enables
                it to check and cure a host of many other ailments. Widely accepted as an adaptogen,
                Ashwagandha can help humans deftly adjust their stress responses to changed settings by
                assisting the body to cope with physical strains. Emotional stress like anxiety,
                restlessness and other stress-induced anomalies can also be managed through it.
              </p>

              <p className="text-center font-weight-bold">
                <span>To know more about Ashwagandha, </span>
                <a className="ingredient-blog-links" target="_blank"
                   href={`https://misters.in${match.params.language}/blog/What-is-Ashwagandha`}>click here</a>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center py-5">
                <iframe className="youtubeIframe"
                        src="https://www.youtube.com/embed/IN7_S07ks9I?loop=1&playlist=IN7_S07ks9I&rel=0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen frameBorder="0"/>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-md-10 offset-md-1 col-12">
              {accordionList1}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-md-10 offset-md-1 col-12">
              <ImageBreaker image={Images.ingredient.ashwagandhaDescription} to={shopUrl}
                            buttonText={'SHOP'}/>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              {accordionList2}
            </div>
          </div>
        </div>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center py-5">
                <iframe className="youtubeIframe"
                        src="https://www.youtube.com/embed/pRRyyrlk6oI?loop=1&playlist=pRRyyrlk6oI&rel=0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen frameBorder="0"/>
              </div>
            </div>
          </div>
        </div>

        <WhyMisters to={shopUrl} buttonText={'SHOP'}/>

        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              {accordionList3}
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

Ashwagandha.propTypes = {
  match: PropTypes.object
}

export default Ashwagandha
