import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { anonymousLogin } from '../../../actions/accounts/profile'
import { USER } from '../../../constants'
import { getStorageItem } from '../../../utils'

class AuthenticatedContainer extends PureComponent {
    static propTypes = {
      anonymousLogin: PropTypes.func,
      handleLoginSuccess: PropTypes.func
    };

    static defaultProps = {
      handleLoginSuccess: () => {}
    };

    componentDidMount () {
      if (!getStorageItem(USER)) {
        this.props.anonymousLogin()
      } else {
        this.props.handleLoginSuccess()
      }
    }

    componentDidUpdate (prevProps, prevState) {
      const { profile: { isFetchingAnonymous, error, retry } } = this.props

      if (!isFetchingAnonymous && prevProps.profile.isFetchingAnonymous && !error && !retry) {
        this.props.handleLoginSuccess()
      }
    }

    render () {
      return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
      )
    }
}

const mapStateToProps = (state) => ({
  profile: state.accounts.profile
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  anonymousLogin
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(AuthenticatedContainer)
