import React from 'react'
import PropTypes from 'prop-types'

const CartQuantitySelect = function ({ className, quantity, handleQuantitySelect }) {
  const options = [
    { label: 'delete', value: 0 }, { label: '1', value: 1 }, { label: '2', value: 2 },
    { label: '3', value: 3 }, { label: '4', value: 4 }
  ]
  return (
        <div className={`cart-quantity-select ${className}`}>
            <select name="quantity" id="" value={quantity} onChange={(value => handleQuantitySelect(value))}>
                {
                    options.map(({ label, value }) => (<option key={value} value={value}>{label}</option>))
                }
            </select>
        </div>
  )
}

CartQuantitySelect.propTypes = {
  className: PropTypes.string,
  quantity: PropTypes.number,
  handleQuantitySelect: PropTypes.func
}

CartQuantitySelect.defaultProps = {
  className: '',
  quantity: PropTypes.number,
  handleQuantitySelect: PropTypes.func
}

export default CartQuantitySelect
