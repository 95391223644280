// External Imports
import { combineReducers } from 'redux'
// My Imports
import cart from './cart'
import address from './address'
import orders from './orders'
import products from './products'
import reviews from './reviews'
import coupons from './coupons'
import offers from './offers'
import wishlist from './wishlist'

export default combineReducers({
  cart,
  address,
  orders,
  products,
  reviews,
  coupons,
  offers,
  wishlist
})
