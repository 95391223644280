import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import { getSearchParams } from '../../../../utils'
import TherapyRating from '../TherapyRating'
import Button from '../../../../components/buttons/Button'
import ReviewForm from '../../../../components/forms/ReviewForm'
import ReviewCard from '../../../../components/cards/ReviewCard'
import PaginationComponent from '../../../../components/others/PaginationComponent'
import { fetchReviews } from '../../../../actions/ecommerce/reviews'

class TherapyReviews extends React.PureComponent {
    static propTypes = {
      productId: PropTypes.string
    };

    static defaultProps = {
      productId: ''
    };

    constructor (props) {
      super(props)
      const { search } = props.history.location
      const { reviewPage = 1 } = getSearchParams(search)

      this.state = {
        reviewPage,
        showReviewForm: false
      }
    }

    componentDidMount () {
      const { productId } = this.props
      const { reviewPage } = this.state

      this.props.fetchReviews({
        product_id: productId,
        page: reviewPage
      })
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const { reviews, productId } = this.props
      const { reviewPage } = this.state
      const prevQueryParams = getSearchParams(prevProps.location.search)
      const queryParams = getSearchParams(this.props.location.search)

      if (!reviews.isCreating && prevProps.reviews.isCreating && !reviews.error && !reviews.retry) {
        if (reviewPage === 1) {
          this.props.fetchReviews({
            product_id: productId,
            page: reviewPage
          })
        } else {
          this.handlePageClick(1)
        }
      }

      if (reviewPage !== prevState.reviewPage) {
        this.props.fetchReviews({
          product_id: productId,
          page: reviewPage
        })
      }

      if (prevQueryParams.reviewPage !== queryParams.reviewPage) {
        this.setState({
          reviewPage: parseInt(queryParams.reviewPage || '1')
        })
      }
    }

    handlePageClick = (reviewPage) => {
      const { match: { params }, productId } = this.props
      this.setState({ reviewPage })
      this.props.history.push(`${params.language}/therapy/${productId}/?reviewPage=${reviewPage}`)
    };

    render () {
      const { products: { detail: product }, reviews: { list: reviewList } } = this.props
      const { reviewPage, showReviewForm } = this.state

      return (
            <div className="container py-5">
                <div className="row">
                    <div
                        className="col-lg-8 col-md-10 col-12 offset-lg-2 offset-md-1 therapy-review-container">
                        <h3 className="mb-3 mb-md-4 home-page-subtitle text-center">RATINGS & REVIEWS</h3>
                        <div className="therapy-overall-review">
                            <TherapyRating showCount
                                           rating={product.ratings_star_count / (product.ratings_count || 1)}
                                           ratingCount={product.ratings_count}/>

                            {
                                !showReviewForm
                                  ? <Button className="float-right font-weight-normal"
                                            variant="secondary-outline"
                                            onClick={() => this.setState({ showReviewForm: true })}>
                                        WRITE A REVIEW
                                    </Button>
                                  : <Button className="float-right"
                                            variant={'dark'}
                                            onClick={() => this.setState({ showReviewForm: false })}>
                                        Cancel
                                    </Button>
                            }
                        </div>
                        {
                            showReviewForm &&
                            <ReviewForm product={product.id}
                                        handleClose={() => this.setState({ showReviewForm: false })}/>
                        }
                        <hr/>
                        {
                            !!reviewList.results &&
                            reviewList.results.map(
                              (review, index) => <ReviewCard key={review.id} review={review}/>
                            )
                        }
                        <div className="row">
                            <div className="col-12">
                                {!!reviewList.count &&
                                <PaginationComponent className="justify-content-center"
                                                     page={parseInt(reviewPage)}
                                                     count={parseInt(reviewList.count)}
                                                     onClick={this.handlePageClick} perPage={10}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      )
    }
}

const mapStateToProps = (state) => ({
  products: state.ecommerce.products,
  reviews: state.ecommerce.reviews
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchReviews
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(TherapyReviews))
