import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Page from '../../components/core/Page'
import { Images } from '../../constants'
import TeamCard from '../../components/cards/TeamCard'
import CrossFadeImage from '../../components/others/CrossFadeImage'
import Button from '../../components/buttons/Button'
import { fetchCourses, purchaseLibrary } from '../../actions/library/courses'
import { getHttpsImageUrl } from '../../utils'
import { NavLink } from 'react-router-dom'

class Library extends Component {
    static propTypes = {
      purchaseLibrary: PropTypes.func,
      fetchCourses: PropTypes.func
    }

    componentDidMount () {
      this.props.fetchCourses()
    }

    render () {
      const { match, courses, is_premium_library_member } = this.props

      return (
            <Page meta={{
              path: `${match.params.language}/library/`,
              title: 'Misters- men\'s sexual health platform',
              description: 'Misters is a Gurgaon-based health and confidence start-up catering to men’s sexual health and wellness using ayurvedic, allopathic and plant-based methods. In collaboration with medical experts, reliable information on erections, ejaculation, sexual desire and hair care awaits you here. Simple, personalized and result-oriented care amalgamating traditional knowledge and the best science has to offer is Misters’ forte. More than a website and company, Misters is a community. Let your journey commence, bon voyage!'
            }}>
                <div className="library-page-section1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <CrossFadeImage
                                    src={Images.library}
                                    alt="Library"
                                    duration={700}
                                    timingFunction={'ease-out'}
                                />
                            </div>
                            <div
                                className="col-12 col-md-6 d-flex flex-column justify-content-center py-3 text-center text-md-left">
                                <h2 className="library-page-subtitle text-white">
                                    Audio Library
                                </h2>
                                <p className="library-page-description text-white">
                                    Listen, Learn, Improve
                                </p>
                                {
                                    !is_premium_library_member &&
                                    <div>
                                        <Button variant={'secondary'} style={{ width: 250 }} to={`${match.params.language}/library/purchase`}>
                                            Get Premium
                                        </Button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-12 col-lg-10 offset-lg-1">
                            <h2 className="library-page-subtitle">Courses</h2>
                            <div className="row">
                                {
                                    courses.list.map(
                                      (course, index) => (
                                            <div className="col-md-6 col-lg-4 col-12" key={course.id}>
                                                <NavLink to={`${match.params.language}/library/courses/${course.id}`}>
                                                    <div className="course-item">
                                                        <div className="course-item-image">
                                                            <img src={getHttpsImageUrl(course.featured_image)}
                                                                 alt={course.title}/>
                                                        </div>
                                                        <div className="course-item-content">
                                                            <h4 className="course-item-title">{course.title}</h4>
                                                            <p className="course-item-description">{course.meta_description}</p>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </div>
                                      )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
      )
    }
}

const mapStateToProps = (state) => ({
  courses: state.library.courses,
  is_premium_library_member: state.accounts.profile.detail.is_premium_library_member
})

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchCourses,
  purchaseLibrary
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Library)
