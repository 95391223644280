import api from '../api'

export const FETCH_COUPONS = 'FETCH_COUPONS'
export const FETCH_COUPONS_RESULT = 'FETCH_COUPONS_RESULT'
export const FETCH_COUPONS_ERROR = 'FETCH_COUPONS_ERROR'
export const FETCH_COUPONS_RETRY = 'FETCH_COUPONS_RETRY'

export const fetchCoupons = (data) => async dispatch => {
  try {
    dispatch({ type: FETCH_COUPONS })
    const res = await api.ecommerce.coupons.list(data)
    const result = await res.json()

    if (res.status === 200) {
      dispatch({ type: FETCH_COUPONS_RESULT, data: result })
    } else {
      dispatch({ type: FETCH_COUPONS_ERROR, message: result })
    }
  } catch (e) {
    dispatch({ type: FETCH_COUPONS_RETRY, message: e.message })
  }
}
